import React, { useEffect, useRef, useState } from 'react'
import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { EVENTS, ThreeJsDrawing } from './threeJsDrawing';
import { setThreeJsData } from './mouseEvents';
import ButtonWithIcon from '../../../ReusableComponents/ButtonWithIcon';
import close from "../../assets/icons/circle-close.svg";
import DropDownWithBUtton from "../../../ReusableComponents/DropDownWithButton"
import { Mention, MentionsInput } from 'react-mentions';
import * as API from "../../../api";
import { ActivityPopup } from '../../../ReusableComponents/ActivityPopup';
import { FLOORIMAGES360 } from '../FloorComponent';
import LoaderComponent from '../../../ReusableComponents/LoaderComponent';



let scene, camera, controls, sphere, drawingTool, sprite, raycaster, xContainerRef, yContainerRef, remarkDiv, hotspotIntersect, receiveMesh;
const mouse = new THREE.Vector2();
const PanoramaViewer = ({ selectedImage, st, callMarkIssue, callback, mentionObj, isDisabled, threeSixtyImagesArr }) => {
    const [renderer, setRenderer] = useState(null)
    const [loading, setLoading] = useState(true)
    const [showDiv, setShowDiv] = useState(false)
    const [message, setMessage] = useState("");
    const [geometry, setGeometry] = useState({});
    const [severity, setSeverity] = useState("");
    const [mentionValue, setMentionValue] = useState();
    const [mentionUser, setMentionUser] = useState();
    const [mentionMessage, setMentionMessage] = useState();
    const [intersect, setIntersect] = useState();
    const [severityNum, setSeverityNum] = useState("");
    const [itemName, setIntemName] = useState("");
    const [showProcessPopup, setShowProcessPopup] = useState(false);
    const [processPopupIcon, setProcessPopupIcon] = useState("");
    const [processAction, setProcessAction] = useState("");
    const [processMessage, setProcessMessage] = useState("");
    const [data, setData] = useState({
        severity: undefined,
        note: undefined
    })
    const [region, setRegion] = useState({ id: "" });
    const containerRef = useRef(null);
    const inputRef = useRef()


    const loadImage = (selectedImage, initCall) => {
        if (remarkDiv) {
            remarkDiv.style.display = "none";
            setShowDiv(false);
        }
        const img = selectedImage
        const [x, y] = [0, 0]
        const loader = new THREE.TextureLoader();
        const texture = loader.load(`${img.url}?${st}`, (texture) => {
            texture.wrapS = THREE.RepeatWrapping;
            sphere.material.map = texture;
            sphere.material.needsUpdate = true; // Ensure material updates
            sphere.material.map.dispose();
            setLoading(false)
        });


        if (initCall) {
            const geometry = new THREE.SphereGeometry(5, 128, 128);
            geometry.scale(-1, 1, 1);
            const material = new THREE.MeshBasicMaterial({ map: texture });
            sphere = new THREE.Mesh(geometry, material);
            sphere.position.set(x, y, 0);
            scene.add(sphere);
            camera.position.set(x, y, 0.1);
            controls.target.set(x, y, 0)
            controls.update();
        }
    }

    const initThreeJs = () => {
        scene = new THREE.Scene();
        camera = new THREE.PerspectiveCamera(
            75,
            containerRef.current.clientWidth / containerRef.current.clientHeight,
            0.1,
            1000
        );
        camera.layers.enableAll();

        raycaster = new THREE.Raycaster();

        const renderer = new THREE.WebGLRenderer();
        setRenderer(renderer)
        renderer.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);
        containerRef.current.appendChild(renderer.domElement);

        controls = new OrbitControls(camera, renderer.domElement);
        controls.enableZoom = false;
        controls.enablePan = false;
        controls.enableDamping = true;
        controls.rotateSpeed = -0.25; // Adjust the value as needed
        loadImage(selectedImage, true)
        setThreeJsData(renderer, scene, camera, controls)

        containerRef.current.addEventListener("mousedown", calculateMouseLocation, false);
        window.addEventListener('resize', () => {
            if (containerRef.current) {
                camera.aspect = containerRef.current.clientWidth / containerRef.current.clientHeight;
                camera.updateProjectionMatrix();
                renderer.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);
            }
        });

        const animate = () => {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);

            const inverseScale = 1 / camera.zoom;

            if (scene.children.length > 1) {
                const meshArr = scene.children.slice(1);
                // eslint-disable-next-line no-unused-expressions
                meshArr.forEach((object) => {
                    object.scale.set(inverseScale, inverseScale, inverseScale)
                })
            }

            camera.updateProjectionMatrix();
            controls.update();
        };
        animate();

        // Clean up
        return () => {
            containerRef.current.removeChild(renderer.domElement);
            controls.dispose();
        };
    }


    const handleZoom = (e) => {
        if (showDiv) {
            return;
        }
        e.preventDefault();
        const container = containerRef.current;
        const mouseX = e.clientX - container.offsetLeft;
        const mouseY = e.clientY - container.offsetTop;

        const delta = e.deltaY || e.detail || e.wheelDelta;
        const scaleFactor = delta > 0 ? 0.9 : 1.1;

        const scaleChange = scaleFactor > 1 ? 0.05 : -0.08; // Adjust the scaling rate
        camera.zoom += scaleChange;
        if (camera.zoom >= 1) {
            controls.update();

            const transformOriginX = `${mouseX}px`;
            const transformOriginY = `${mouseY}px`;

            container.style.transformOrigin = `${transformOriginX} ${transformOriginY}`;
        }
        else {
            camera.zoom = 1;
        }
    }

    const handleResize = () => {
        camera.zoom = 1;
        camera.updateProjectionMatrix();
        controls.update();
    }

    const makeTooltip = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.fillStyle = "green";
        context.font = "20px Arial";
        context.backgroundColor = "black"
        context.fillText(message, 70, 100);


        const texture = new THREE.CanvasTexture(canvas);
        texture.needsUpdate = true;
        const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
        sprite = new THREE.Sprite(spriteMaterial);
        sprite.scale.set(2, 1, 1);
        sprite.renderOrder = 1000;
        sprite.material.transparent = true;
        renderer.sortObjects = true;
        sprite.position.set(geometry.mesh.position.x, geometry.mesh.position.y, geometry.mesh.position.z);
        scene.add(sprite)
        setMessage("");
    }

    const closeActivityPopup = () => {
        remarkDiv.style.display = "none";
        setShowProcessPopup(false);
        setProcessMessage("");
    }

    const onHotspotClick = () => {
        raycaster.setFromCamera(mouse, camera);
        if (scene.children.length > 1) {
            hotspotIntersect = raycaster.intersectObjects(scene.children.slice(1), false);
            if (hotspotIntersect?.length === 1) {
                receiveMesh.find((mesh) => {
                    if (mesh.id == hotspotIntersect[0].object.uuid) {
                        setMentionValue(mesh.remark);
                        switch (mesh.severity) {
                            case "1":
                                setSeverity("Normal")
                                setSeverityNum("1")
                                break;
                            case "2":
                                setSeverity("Medium")
                                setSeverityNum("2")
                                break;
                            case "3":
                                setSeverity("High")
                                setSeverityNum("3")
                                break;
                            case "4":
                                setSeverity("Higher")
                                setSeverityNum("4")
                                break;
                            case "5":
                                setSeverity("Very High")
                                setSeverityNum("5")
                                break;

                            default:
                                break;
                        }
                    }
                })
                setIntersect(hotspotIntersect);
                showRemarkDiv();
                return true;
            }
            else {
                hotspotIntersect.shift();
                return false;
            }
        }
    }

    const calculateMouseLocation = (e) => {
        e.preventDefault();
        const rect = containerRef.current.getBoundingClientRect();
        xContainerRef = e.clientX - rect.left;
        yContainerRef = e.clientY - rect.top;
        // Convert mouse coordinates to normalized device coordinates (-1 to 1)
        mouse.x = (xContainerRef / containerRef.current.clientWidth) * 2 - 1;
        mouse.y = - (yContainerRef / containerRef.current.clientHeight) * 2 + 1;
        onHotspotClick();
    }

    const showRemarkDiv = () => {
        console.log("xContainerRef:", xContainerRef, "yContainerRef:", yContainerRef)
        setShowDiv(true);
        remarkDiv = document.getElementById("remarkDiv");
        remarkDiv.style.display = "block";
        remarkDiv.style.left = (xContainerRef >= 941 && yContainerRef >= 41 && yContainerRef <= 322)
            ? xContainerRef - 230 + 'px'
            : xContainerRef > 745
                ? xContainerRef - 200 + 'px'
                : xContainerRef + 'px';
        remarkDiv.style.top = (xContainerRef >= 457 && xContainerRef <= 1350 && yContainerRef >= 930 && yContainerRef <= 975)
            ? yContainerRef - 240 + 'px'
            : yContainerRef > 400
                ? yContainerRef - 200 + 'px'
                : (xContainerRef >= 5 && xContainerRef <= 460 && yContainerRef >= 480 && yContainerRef <= 680)
                    ? yContainerRef - 200 + 'px'
                    : yContainerRef + 'px';
    }

    const drawHotspot = (res) => {
        res.map((obj) => {
            const geometry = new THREE.SphereGeometry(0.1, 32, 32);
            const material = new THREE.MeshBasicMaterial({ color: 0Xff000 });
            const mesh = new THREE.Mesh(geometry, material)
            const coordinates = obj.coordinates
            mesh.position.set(coordinates.x, coordinates.y, coordinates.z);
            mesh.uuid = obj.id;
            scene.add(mesh);
        })
    }

    const updateHotspot = () => {
        const intersectRegion = {
            id: intersect[0].object.uuid,
            coordinates: intersect[0].object.position,
            remark: mentionValue,
            severity: severityNum,
            image_id: selectedImage.id
        }

        API.updateFloorIssue(intersectRegion).then((res) => {
            receiveMesh.find((mesh) => {
                if (mesh.id == intersectRegion.id) {
                    mesh.severity = intersectRegion.severity;
                    mesh.remark = intersectRegion.remark;
                }
            })
            setMentionValue();
            setSeverity("");
            setSeverityNum("");
        }).catch((err) => {
            console.log(err)
        })
    }

    const deleteHotspot = () => {
        API.deleteFloorIssue(intersect[0].object.uuid).then((res) => {
            scene.remove(intersect[0].object);
        }).catch((err) => {
            console.log(err);
        })
    }

    const closeRemarkDiv = (e) => {
        if (!containerRef.current.contains(e.target)) {
            remarkDiv.style.display = "none";
            setShowDiv(false);
        }
    }

    useEffect(() => {
        initThreeJs()
    }, [])

    useEffect(() => {
        loadImage(selectedImage)
        API.getFloorIssues(selectedImage.id).then((res) => {
            receiveMesh = res;
            scene.children.slice(1).map((mesh) => {
                scene.remove(mesh)
            })
            drawHotspot(res)
        }).catch((err) => {
            console.log(err);
        })
    }, [selectedImage.id]);

    useEffect(() => {
        if (controls) {
            showDiv ? controls.rotateSpeed = 0 : controls.rotateSpeed = -0.25
        }

        //handleZoom
        const container = containerRef.current;
        const handleZoomWrapper = (e) => handleZoom(e, scene);
        container.addEventListener('wheel', showDiv ? () => {
            console.log("show Div true")
        } : handleZoomWrapper);

        return () => {
            container.removeEventListener('wheel', handleZoomWrapper);
            container.removeEventListener('click', closeRemarkDiv);
        };
    }, [showDiv])

    useEffect(() => {
        if (region.id && region.coordinates) {
            API.addFloorIssue({ ...region, image_id: selectedImage.id }).then((res) => {
                setSeverity("");
                setSeverityNum("");
                setMentionValue();
                receiveMesh.push(region);
                console.log(res)
            }).catch((err) => {
                console.error(err)
            })
        }
    }, [region.id])

    //function to handle drawing type
    const markIssue = (type) => {
        callback("MARKISSUE")
        drawingTool = new ThreeJsDrawing(scene, camera, [sphere], false, containerRef.current)
        drawingTool.enableDrawing(type)
        drawingTool.on(EVENTS.GEOMETRY_ADDED, hotspot => {
            const checkOverlap = onHotspotClick();
            if (checkOverlap) {
                const hotspotRegion = {
                    id: hotspot.mesh.uuid,
                    coordinates: hotspot.coordinates[0],
                    remark: "",
                    severity: "",
                }
                setGeometry(hotspot);
                showRemarkDiv();
                setRegion(hotspotRegion)
            }
            else {
                setIntemName("HOTSPOT");
                setProcessPopupIcon("ERROR");
                setProcessAction("DELETE");
                setProcessMessage("Hotspot overlapping is not allowed !")
                setShowProcessPopup(true);
                scene.remove(hotspot.mesh)
            }
        })
    }

    const onChangeTeamMember = (e, value, message, mentions) => {
        const count = (e.target.value.match(new RegExp('@', 'g')) || []).length;
        if (message.length <= 100) {
            if (!(count > 1)) {

                setMentionValue(value)
                setMentionMessage(message)
                setMentionUser(mentions?.[0]?.id)
                setData({ ...data, note: value })
            }
        }
    }

    const handlePaste = (event) => {
        const pastedText = event.clipboardData.getData('text');

        const { current } = this.inputRef;
        if (current) {
            const { selectionStart, selectionEnd } = current;
            const updatedValue =
                mentionMessage.slice(0, selectionStart) +
                pastedText + mentionMessage.slice(selectionEnd);
            setMentionMessage(updatedValue)
            // Move the cursor to the end of the pasted text
            current.selectionStart = selectionStart + pastedText.length;
            current.selectionEnd = selectionStart + pastedText.length;
        }
        event.preventDefault(); // Prevent default paste behavior
    };

    useEffect(() => {
        if (callMarkIssue == "HOTSPOT") {
            markIssue("HOTSPOT")
        }
        else if (callMarkIssue == "POLYGON") {
            markIssue("POLYGON")
        }
        else {
            console.log("insied nothing")
        }
    }, [callMarkIssue])
    const Loader = () => {
        return loading ? <LoaderComponent /> : <></>
    }
    return (
        <>
            <Loader />
            <ActivityPopup
                item={itemName}
                open={showProcessPopup}
                icon={processPopupIcon}
                action={processAction}
                msg={processMessage}
                close={closeActivityPopup}
                onClickOk={closeActivityPopup}
            />
            <div id='remarkDiv' style={{ position: "absolute", display: "none", width: "200px", zIndex: "1", backgroundColor: "white", padding: "15px 8px", borderRadius: "4px", fontSize: "12px" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "10px" }}>
                    <div onClick={() => {
                        remarkDiv.style.display = "none";
                        setShowDiv(false);
                    }} style={{ cursor: "pointer", marginBottom: "10px" }}>
                        <img
                            src={close}
                            alt='close button'
                            width="20px"
                            style={{ position: "absolute", right: "10px" }}
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <p style={{ margin: "0px" }}>Severity Level</p>
                        <DropDownWithBUtton
                            isBorder={true}
                            width={150}
                            selectedItem={severity}
                            height={20}
                            fontSize={10}
                            textFontSize={12}
                            textFontWeight={400}
                            list={[
                                {
                                    name: "Normal",
                                    onClick: () => {
                                        setSeverity("Normal");
                                        setSeverityNum("1");
                                    }
                                },
                                {
                                    name: "Medium",
                                    onClick: () => {
                                        setSeverity("Medium");
                                        setSeverityNum("2")
                                    }
                                },
                                {
                                    name: "High",
                                    onClick: () => {
                                        setSeverity("High");
                                        setSeverityNum("3");
                                    }
                                },
                                {
                                    name: "Higher",
                                    onClick: () => {
                                        setSeverity("Higher");
                                        setSeverityNum("4");
                                    }
                                },
                                {
                                    name: "Very High",
                                    onClick: () => {
                                        setSeverity("Very High");
                                        setSeverityNum("5");
                                    }
                                }
                            ]}
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <p style={{ margin: "0px" }}>Remark</p>
                        <div
                            id="addNoteDiv"
                            style={{
                                border: "1px solid #C2C2C2",
                                borderRadius: "4px",
                                padding: "1px 1px 1px 4px",
                                display: "flex",
                                alignItems: 'center',
                                minWidth: "150px",
                                maxWidth: "150px"
                            }}>
                            {mentionObj ?
                                <MentionsInput
                                    className="mention-input"
                                    singleLine
                                    maxLength={100}
                                    value={mentionValue || ""}
                                    style={{

                                        width: "100%",
                                        color: "#3c3c3c",
                                        fontSize: "12px",
                                        outline: "none",
                                        suggestions: {
                                            list: {
                                                backgroundColor: 'white',
                                                border: '1px solid rgba(0,0,0,0.15)',
                                                fontSize: 14,
                                                maxHeight: "100px",
                                                overflowY: "auto"
                                            },
                                            item: {
                                                padding: '5px 15px',
                                                borderBottom: '1px solid rgba(0,0,0,0.15)',
                                                '&focused': {
                                                    backgroundColor: '#cee4e5',
                                                },
                                            },
                                        },
                                    }}
                                    placeholder={"Mention people using '@'"}
                                    onChange={(e, value, message, mentions) => !isDisabled && onChangeTeamMember(e, value, message, mentions)}
                                    onPaste={handlePaste}
                                    inputRef={inputRef}
                                >
                                    <Mention
                                        trigger="@"
                                        data={mentionObj.teamMembers}
                                        appendSpaceOnAdd={true}
                                        style={{
                                            backgroundColor: '#cee4e5',
                                            border: "none",
                                            outline: "none"
                                        }}
                                    />
                                </MentionsInput> :
                                <input
                                    disabled={isDisabled}
                                    maxLength={100}
                                    type="text"
                                    name="note"
                                    placeholder="Enter note"
                                    onChange={(e) => {
                                        setData({ ...data, note: e.target.value })
                                    }}
                                    value={data.note}
                                    id="addNote"
                                    style={{ width: '80%', border: "none", color: "#3c3c3c", outlineColor: "#2898CF", outlineWidth: '1px !important' }}
                                />}
                            {/* <img style={{ position: "absolute", margin: "5px 0px", right: "10%" }} src={penIcon} /> */}
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "20px", alignItems: "center" }}>
                        <ButtonWithIcon
                            text="Delete"
                            onClick={() => {
                                remarkDiv.style.display = "none";
                                deleteHotspot();
                                setShowDiv(false);
                            }}
                            margin="0px"
                        />
                        <ButtonWithIcon
                            text="Save"
                            isBtnActive={true}
                            margin="0px"
                            onClick={() => {
                                mentionObj.sendAnnotationNotification(mentionUser, mentionMessage, FLOORIMAGES360);
                                updateHotspot();
                                remarkDiv.style.display = "none";
                                setShowDiv(false);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div ref={containerRef} style={{ width: "100%", height: '100%' }} />
        </>
    )
}

export default PanoramaViewer