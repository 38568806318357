
import React from 'react'
import Stepper from 'react-stepper-horizontal'
import { AnimatedModal } from './AnimatedModal'
import closeModal from '../close-button.png'
import completedIcon from '../icons/Complete.png'
import waitingIcon from '../icons/Wait.png'
import warningIcon from '../icons/Warning.png'
import closeIcon from '../icons/Error.png'
import ButtonWithIcon from './ButtonWithIcon'

import Button from './Button/Button'
/**
To use this popup you have to pass following props
  1) close as function to close Popup
  2) onClickOk as a function for warning popup. e.g. for delete popup.
  3) open : to open popup
  4) item : name of the element on which action is performed
  5) action : action name - ADD, DELETE, UPDATE
  6) icon : icon name
  7) msg (optional) : Custom message 

*/
export const ActivityPopup = (props) => {
    return (
        <>
            {/*------------------------------ Info Popup-----------------------------*/}
            <AnimatedModal
                height={props.height ? props.height : "168px"}
                width="445px"
                isOpen={props.open}
            >
                <div>
                    <div style={{ background: "white", padding: "20px 30px", display: "flex", justifyContent: "left", alignItems: "self-end", height: "", borderRadius: "10px 10px 0px 0px" }}>
                        <img className={props.icon == "ERROR" ? "" :
                            props.icon == "WARNING" ? "" :
                                props.icon == "WAIT" ? 'rotate-time' :
                                    props.icon == "COMPLETE" ? "" :
                                        ''}
                            style={{ height: '20px', marginBottom: '5px' }} src={props.icon == "ERROR" ? closeIcon :
                                props.icon == "WARNING" ? warningIcon :
                                    props.icon == "WAIT" ? waitingIcon :
                                        props.icon == "COMPLETE" ? completedIcon :
                                            props.icon == "CUSTOM" ? warningIcon :
                                                warningIcon} />
                        <div className='content-text' style={{ fontFamily: 'Lato', fontSize: "18px", fontWeight: "600", marginLeft: "10px", color: "#081425" }}>
                            {props.title ? props.title :
                                props.icon == "ERROR" ? `Error` :
                                    props.icon == "WARNING" ? `Warning` :
                                        props.icon == "WAIT" ? `Please Wait` :
                                            props.icon == "COMPLETE" ? `Completed` :
                                                props.icon == "CUSTOM" ? `Warning` :
                                                    "Please Wait..."
                            }
                        </div>
                    </div>
                    <div id='message' className='content-text' style={{ fontSize: "13px", fontWeight: "500", textAlign: "left", margin: "0px 30px", color: '#555C67' }}>
                        {props.children && props.msg ? <>
                            {props.children}
                            {props.msg}
                        </> :
                            props.msg ? props.msg :
                                props.action == "ADD" && props.icon == "WAIT" ? `Creating ${props.item} Please Wait...` :
                                    props.action == "DELETE" && props.icon == "WAIT" ? `Deleting ${props.item} Please Wait...` :
                                        props.action == "UPDATE" && props.icon == "WAIT" ? `Updating ${props.item} Please Wait...` :

                                            props.action == "ADD" && props.icon == "WARNING" ? `Are you sure you want to add this ${props.item} ?` :
                                                props.action == "DELETE" && props.icon == "WARNING" ? `Are you sure you want to delete this  ${props.item} ?` :
                                                    props.action == "UPDATE" && props.icon == "WARNING" ? `Are you sure you want to update this  ${props.item} ?` :

                                                        props.action == "ADD" && props.icon == "COMPLETE" ? `${props.item} created successfully.` :
                                                            props.action == "DELETE" && props.icon == "COMPLETE" ? `${props.item} deleted successfully.` :
                                                                props.action == "UPDATE" && props.icon == "COMPLETE" ? `${props.item} updated successfully.` :
                                                                    ""
                        }
                    </div>
                    {props.icon == "WARNING" ? (
                        <div style={{ display: "flex", position: "absolute", bottom: "22px", right: "5%", alignItems: "center" }}>
                            {/* <Button text={"No"} onClick={props.close} /> */}
                            <span onClick={props.close} style={{ fontSize: '11px', marginRight: '25px', color: '#555C67', cursor: 'pointer' }}>No</span>
                            <Button isBtnActive type={props.icon} text={"Yes"} onClick={props.onClickOk} />
                        </div>
                    ) :
                        props.icon == "ERROR" ?
                            (
                                <div style={{ display: "flex", position: "absolute", bottom: "22px", right: "5%" }}>
                                    <Button isBtnActive type={props.icon} text="Ok" width="75px" onClick={props.close} />
                                </div>)
                            : props.icon == "CUSTOM" ? (
                                <div style={{ display: "flex", position: "absolute", bottom: "22px", right: "5%", alignItems: "center" }}>
                                    {/* <Button text={"No"} onClick={props.close} /> */}
                                    {props.customNoText.length > 0 && <span className='new-button' onClick={props.close} style={{ background: "#FFAB00", borderColor: "#FFAB00", color: "white" }}>{props.customNoText}</span>}
                                    <Button isBtnActive type={props.icon} text={props.customYesText} onClick={props.onClickOk} />
                                </div>
                            ) :
                                props.icon != "WAIT" ? (
                                    <div style={{ display: "flex", position: "absolute", bottom: "22px", right: "5%" }}>
                                        <Button isBtnActive type={props.icon} text="Ok" width="75px" onClick={props.close} />
                                    </div>)
                                    : <></>}
                </div>
            </AnimatedModal>
            {/*-------------------------------------------------------------------------------------------- */}
        </>
    )
}
