import React, { useState } from "react";
import { Link } from "react-router-dom";
const ButtonWithIcon = ({
  isDropDownOpen,
  text,
  title,
  icon,
  isIconLeft,
  width,
  height,
  onClick,
  isDisabled,
  isBtnActive,
  linkData,
  margin,
  border,
  fontSize,
  fontWeight,
  borderRadius,
  textColor,
  textFontSize,
  padding,
  iconWidth,
  backgroundColor,
  color,
  display,
  alignItems,
  iconPadding
}) => {

  const jsx = (
    <button
      title={title || text}
      style={{
        border: border || `1px solid #2989cf`,
        borderRadius: borderRadius || "20px",
        color: color ? color : (isBtnActive ? "white" : "#2989cf"),
        backgroundColor: isBtnActive ? "#2989cf" : (backgroundColor ? backgroundColor : "transparent"),
        padding: text ? padding || "4px 16px" : "4px",
        fontSize: fontSize || "11px",
        fontWeight: fontWeight || "600",
        letterSpacing: "0.01em",
        margin: margin || "0px 5px 0px 5px",
        opacity: isDisabled ? "0.5" : "1",
        filter: isDisabled ? "grayscale(100%)" : undefined,
        cursor: isDisabled ? "default" : "pointer",
        height: height,
        textTransform: "capitalize",
        width: width,
        display: display ? display : "block",
        alignItems: alignItems ? alignItems : "center",
      }}
      onClick={(e) => {
        if (e && !linkData) e.preventDefault();
        onClick && !isDisabled && onClick();
      }}
    >
      <div
        style={{
          display: "flex",
          textTransform: "capitalize",
          flexDirection: isIconLeft ? "row-reverse" : "",
          justifyContent: "space-around",
          alignItems: "center",
          lineHeight: "1.4"
        }}
      >
        <span style={{ color: textColor, fontSize: textFontSize }}>{text}</span>
        {icon && text && <div style={{ margin: "0px 5px 0px 5px" }}></div>}
        {icon && (
          <img
            id="icon"
            alt="icon"
            src={icon}
            style={{
              width: iconWidth || "10px",
              transform: isDropDownOpen && !isDisabled ? "rotate(180deg)" : "rotate(0deg)",
              transition: "0.3s all",
              padding: iconPadding ? iconPadding : "0"
            }}
          />
        )}
      </div>
    </button>
  );
  return <>{linkData && !isDisabled ? <Link to={linkData}>{jsx}</Link> : jsx}</>;
};
export default ButtonWithIcon;