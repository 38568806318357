import React, { useEffect, useState } from 'react';
import Search from "../icons/SearchIcon.svg";

const SearchBar = ({ bgColor, height, width, placeHolder, handleSearchString }) => {

    const [serachString, setSearchString] = useState('')

    useEffect(() => { handleSearchString(serachString) }, [serachString])

    return (<>
        <div style={{
            display: "flex",
            backgroundColor: bgColor || "rgba(163, 161, 161, 0.15)",
            border: "1px solid rgba(79, 79, 79, 0.14)",
            borderRadius: "7px",
            gap: "2px",
            justifyContent: "space-between",
            height: height || "32px",
            padding: "0px 10px",
            alignItems: "center",
        }}>
            <div>
                <input type="text" style={{
                    background: "transparent",
                    border: "none",
                    fontSize: "15px",
                    outline: "none",
                    height: "40px",
                    fontWeight: "300",
                    color: "rgb(60, 60, 60)",
                    width: width || "128vh",
                }}
                    placeholder={placeHolder || 'Search'}
                    onChange={(e) => { setSearchString(e.target.value) }}
                />
            </div>
            <div>
                <img src={Search} style={{ height: "15px" }} />
            </div>
        </div >
    </>)
}

export default SearchBar;