// Compulsory props : selectedItem, itemsList, onChangeHandler

import React from 'react'
import Select from 'react-dropdown-select'
import selectIcon from '../icons/selectIcon.png'
import selectIcon1 from '../icons/selectIcon1.png'

export const SelectComponent = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const onchangeHandler = (v) => {
    console.log(v);
  }
  return (
    <div style={{ display: "flex" }} className={props.searchable ? "searchable" : "disableSearchable"}>
      <Select
        disabled={props.disabled}
        options={props.itemsList}
        values={props.selected}
        placeholder={props.placeholder ? props.placeholder : "Select"}
        valueField={props.valueField ? props.valueField : 'value'}
        labelField={props.labelField ? props.labelField : "label"}
        onChange={props.onchangeHandler}
        searchBy={props.searchBy}
        create={props.create}
        onCreateNew={props.onCreateNew}
        itemRenderer={({ item, itemIndex, state, methods }) => (
          <div className='selectItem'
            onClick={() => methods.addItem(item)}
            style={{
              width: props.width ? props.width : "auto",
              padding: "5px 10px", color: methods.isSelected(item) ? 'white' : '#3c3c3c',
              background: methods.isSelected(item) ? '#2989cf' : '',
              fontSize: props.itemFontSize ? props.itemFontSize : '12px',
              fontWeight: props.itemFontWeight ? props.itemFontWeight : '400',
            }}
          >
            {item[props.labelField ? props.labelField : 'label']}
          </div>
        )}
        dropdownHandleRenderer={({ state }) => (
          <img style={{ marginLeft: "10px", height: props.iconSize ? props.iconSize : "14px" }} src={props.selectIcon ? props.selectIcon : selectIcon1}></img>
        )}
        searchable={props.searchable}
        color={"#2989CF"}
        style={{
          padding: props.padding ? props.padding : "none",
          backgroundColor: props.backgroundColor ? props.backgroundColor : "none",
          width: props.width ? props.width : "auto",
          minHeight: props.height ? props.height : "32px",
          minWidth: props.minWidth, display: "flex",
          justifyContent: "space-between",
          borderRadius: props.borderRadius ? props.borderRadius : "5px",
          color: props.selectColor ? props.selectColor : "#3c3c3c",
          border: props.border === true ? '1px solid #ced4da' : (props.border ? props.border : 'none'),
          boxShadow: "none", fontSize: props.fontSize ? props.fontSize : "18px",
          fontWeight: props.fontWeight ? props.fontWeight : 500
        }}
        backspaceDelete={false}
        dropdownGap={props.dropdownGap ? props.dropdownGap : 5}
        dropdownHeight={props.dropdownHeight ? props.dropdownHeight : '300px'}
        dropdownPosition={props.dropdownPosition ? props.dropdownPosition : 'bottom'}
      />
    </div>
  )
}
