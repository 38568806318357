import React, { Component } from 'react';
import * as API from './api.js';

import CustomersInactive from './customers.png';
import Loader from './icons/loader.svg';
import Users from './users.png';
import Analytics from './sidebaricon/Analytics.png'
import UsersStats from './res/stats.png'
import service from "./sidebaricon/Services.png";
import install from './icons/AeroGCSInactive.png'
import TaskIcon from './icons/servers.png'
import logList from './icons/logList.png'
import SupportIcon from './icons/support.png'
import { Link, StaticRouter, Redirect } from 'react-router-dom';
import SideBar from './SideBar';
import AppConstants from './AppConstants.js';
import { SortComponent } from './ReusableComponents/SortComponent';

export default class SupportEmails extends Component {

    state = {
        user: {},
        type: "",
        currentpage: 1,
        userperpage: 10,
        key: " ",
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        createIcon: "",
        popupMessage: "",
        addemailPopup: false,
        record: null,
        status: true,
        analytics: [],
        showAddemailPopup: false,
        statusFilter: "all",
        editTaskPopup: false,
        cancel: false,
        loading: true,
        count: 0
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    onChangeListener = (e) => {
        let t = e.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.value
        }))
    }

    openAddemailPopup = () => {
        this.setState(state => ({
            ...state,
            record: null,
            status: true,
            addemailPopup: true
        }))
    }

    handleSwitchChange = (event) => {
        let t = event.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.checked,
        }));
    };

    componentWillMount() {
        document.title = "User analytics - Admin";
        if (this.props.location.state) {
            let { user, type, user_info } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type,
                user_info
            }), async () => {
                this.getAnalyticsRecord();
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type,
                    analytics: []
                }))
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    sortByPageName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.analytics.sort((a, b) => a.page_name.localeCompare(b.page_name))
        this.setState((state) => ({
            ...state,
            analytics: sort,
        }));
    }

    reverseByPageName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.analytics.sort((a, b) => b.page_name.localeCompare(a.page_name))
        this.setState((state) => ({
            ...state,
            analytics: sort,
        }));
    }

    sortByVisits = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.analytics.sort((a, b) => a.count - b.count)
        this.setState((state) => ({
            ...state,
            analytics: sort,
        }));
    }

    reverseByVisits = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.analytics.sort((a, b) => b.count - a.count)
        this.setState((state) => ({
            ...state,
            analytics: sort,
        }));
    }

    search(key) {
        this.setState((state) => ({
            ...state,
            key,
        }),
            () => this.getAnalyticsRecord());
    }

    getAnalyticsRecord = async () => {
        const { user_info } = this.state;
        let pages = AppConstants.PAGES
        let count = 0
        await API.getUserAnalytics(user_info.userid).then(async (data) => {
            let analytics = data.userAnalytics
            analytics.forEach(element => {
                let data = pages.find((data) => { return data.PAGE_NAME == element.page_name })
                element.route = data ? data.ROUTE : null
                element.name = data ? data.DISPLAY_NAME : null
                count += Number(element.count)
                element.averageTime = Number(element.sum) / Number(element.count)
            });
            this.setState((state) => ({
                ...state,
                analytics: analytics,
                loading: false,
                count
            }))
        }, (e) => {
            console.log(e);
        })
    }

    render() {
        const { id, user, type, user_info } = this.state;
        // const lastIndex = currentpage * userperpage;
        // const firstpage = lastIndex - userperpage;
        // const currentuser = userdisplay.slice(firstpage, lastIndex);
        // const totalpages = userdisplay.length / userperpage;
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },

                {
                    icon: UsersStats,
                    text: "Statistics",
                    path: "/admin/users_stats",
                },
                {
                    icon: CustomersInactive,
                    text: "Add NPNT Customer",
                    path: "/admin/add_npnt_customer"
                },
                {
                    icon: Users,
                    text: "Users",
                    path: "/admin/users",
                },
                {
                    icon: Analytics,
                    text: "Analytics",
                    path: "/admin/user_tracking_logs",
                    selected: true,
                },
                {
                    icon: install,
                    text: "Installations",
                    path: "/admin/gcs-installation",
                },
                {
                    icon: TaskIcon,
                    text: "Tasks",
                    path: "/admin/tasks",
                },
                {
                    icon: logList,
                    text: "Task Logs",
                    path: "/admin/taskLogs",
                },
                {
                    icon: SupportIcon,
                    text: "Support",
                    path: "/admin/supportEmails",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode
        }
        return (
            this.state.user && this.state.user.first_name ? <div className="wrapper">
                <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    style={{ zIndex: 0 }}
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar" style={{ display: "flex" }}><div className="top-bar-text">User Analytics</div>
                    </div>
                    <div className="main-content" style={{ height: "93%" }}>
                        {this.state.loading ? (
                            <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                                <img src={Loader} style={{ height: "20vh", width: "100vw", margin: "auto" }} />
                            </div>) : (
                            <div>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                    <div style={{ margin: "1% 1%" }}>
                                        <div className="services-content-title">{user_info.first_name} {user_info.last_name} <span style={{ fontSize: "12px", color: '#2989cf' }}> - {user_info.username}</span></div>
                                    </div>
                                    <div style={{ margin: "1% 1%" }}>
                                        <div className="services-content-title">Total visits : {this.state.count}</div>
                                    </div>
                                    {/* <div className="add-user-form-text-wrapper">
                                        <img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
                                        <input type="text" className="add-user-form-text" placeholder="Search by record" style={{ height: '30px !important', width: "235px", paddingLeft: '28px' }} onChange={(event) => this.search(event.target.value)} />
                                    </div> */}
                                </div>
                                <div style={{ height: "80vh", overflowY: 'auto' }}>
                                    <table className="customers-table" style={{ marginTop: '0px' }}>
                                        <tr className="customers-table-header-row">
                                            <th>No.</th>
                                            <th>Page Name
                                                <SortComponent
                                                    style={{ marginLeft: '5px' }}
                                                    sortAscending={this.sortByPageName}
                                                    sortDecending={this.reverseByPageName}
                                                    sortASC={false}
                                                />
                                            </th>
                                            <th>Route</th>
                                            <th>Page Visits
                                                <SortComponent
                                                    style={{ marginLeft: '5px' }}
                                                    sortAscending={this.sortByVisits}
                                                    sortDecending={this.reverseByVisits}
                                                    sortASC={false}
                                                />
                                            </th>
                                            <th>Average Time</th>
                                        </tr>
                                        {this.state.analytics.length === 0 ?
                                            <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                                <td colspan="5">No Records Available</td>
                                            </tr> :
                                            this.state.analytics.map((record, i) =>
                                                <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                    <td>{i + 1}.</td>
                                                    <td><Link style={{ color: "#707683" }}
                                                        to={{
                                                            pathname: "/admin/user_screen_logs",
                                                            state: {
                                                                user, type, user_info: this.state.user_info,
                                                                sidebarMode: this.state.sidebarMode,
                                                                page_name: record.page_name
                                                            }
                                                        }}
                                                    >
                                                        {record.name}
                                                    </Link></td>
                                                    <td><Link style={{ color: "#707683" }}
                                                        to={{
                                                            pathname: "/admin/user_screen_logs",
                                                            state: {
                                                                user, type, user_info: this.state.user_info,
                                                                sidebarMode: this.state.sidebarMode,
                                                                page_name: record.page_name
                                                            }
                                                        }}
                                                    >{record.route}</Link></td>
                                                    <td><Link style={{ color: "#707683" }}
                                                        to={{
                                                            pathname: "/admin/user_screen_logs",
                                                            state: {
                                                                user, type, user_info: this.state.user_info,
                                                                sidebarMode: this.state.sidebarMode,
                                                                page_name: record.page_name
                                                            }
                                                        }}
                                                    >{record.count}</Link></td>

                                                    <td>{record.averageTime > 60 ? Math.trunc(record.averageTime / 60) + ' M : ' + Math.trunc(record.averageTime % 60) + ' s' : Math.round(record.averageTime) + ' s'}</td>

                                                </tr>
                                            )}
                                    </table>
                                </div>
                            </div>
                        )
                        }
                    </div>
                </div>
            </div> : <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
            </div>
        );
    }
}
