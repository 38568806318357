import React, { useState, useEffect } from "react";
import LoaderComponent from "../../../ReusableComponents/LoaderComponent";
import DetectionTaskDetails from "../DetectionTaskDetails";
import TrainingTaskDetails from "../TrainingTaskDetails";

const TaskDetails = (props) => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (props.task) {
            setLoading(false)
        }
    }, [props.task])
    return loading ? <LoaderComponent message={" "} /> :
        props.task.tasktype == 2 || props.task.tasktype == 4 ?
            <DetectionTaskDetails
                {...props}

            /> :
            props.task.tasktype == 1 ?
                <TrainingTaskDetails
                    {...props}
                /> : <></>
}

export default TaskDetails; 