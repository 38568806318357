import { Link } from 'react-router-dom';
import React from 'react';
import { Component } from 'react';



export default class TopBar extends Component {
    state = {
       
    }
    componentDidMount() {
        let { user, type, sidebarMode, pageTitle } = this.props;
        if(user.avatar_url && user.avatar_url.length>0){
            this.setState((state)=>({
              ...state,
              avatarURL:`${user.avatar_url}?${user.sas_token}&t${new Date().getTime()}`
            }))
      
          }else{
            let avatarURL= `/images/${user.first_name[0].toLowerCase()}.PNG`
            this.setState((state)=>({
              ...state,
              avatarURL:avatarURL
            }))
          }
        this.setState((state) => ({
            ...state,
            user,
            type,
            sidebarMode,
            pageTitle,
            userName:user.username,
            firstName:user.first_name,
            lastName:user.last_name,
        }))

    }
    componentWillUnmount() {

    }
    render() {
        let { user, type, sidebarMode, pageTitle,userName,firstName,lastName } = this.state;
        return  <div className="top-bar" style={{display:'flex'}}><div className="top-bar-text">{pageTitle}</div>
       {this.state.userName ?<Link to={{
            pathname: "/my_profile",
            state: {
              user,
              type,
              sidebarMode: sidebarMode,
            }
          }} style={{marginLeft:'auto'}}><div className='user-info' id="user-info">
            <div className='user-middle'>
              <div  className="user-info-name-expanded" style={{textAlign:'right'}} >{`${firstName} ${lastName}`}</div>
              <div className="user-info-email-expanded" style={{textAlign:'right'}}>{
                  userName.length > 20 ?
                  `${userName.substring(0, 19)} ...` :
                  `${userName}`
                }</div>
            </div>
                <img className="sidebar-user-picture" style={{marginLeft:'8px'}} src={this.state.avatarURL}></img>
          </div>
          </Link> : <div/>}
        
        
        </div>
    }
}