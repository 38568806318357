import { ADD_SIDEBAR_DETAILS } from "./sidebarTypes";

const initialState = {
  project: '',
  plan: ''
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SIDEBAR_DETAILS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
export default sidebarReducer;
