import React, { Component } from 'react';
import * as API from '../api.js';
import service from "../sidebaricon/Services.png";
import Loader from '../icons/loader.svg';
import SideBar from '../SideBar';
import { Link, Redirect } from 'react-router-dom';
import btnAdd from '../AeroCapture/assets/icons/btnAdd.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import Menu from '../AeroCapture/assets/icons/menu.svg';
import Delete from '../AeroCapture/assets/icons/delete.svg';
import { Modal } from "react-bootstrap";
import waiting from '../res/waiting.png';
import Popup from "reactjs-popup";
import closeButton from '../close-button.png';
import { AnimatedModal } from './Reusable/AnimatedModal'
import dateFormat from 'dateformat';
import success from '../assets/check_circle_24px.png';
import fail from '../assets/error.png';
import install from '../icons/AeroGCSInactive.png'
import TaskIcon from '../icons/servers.png'
import SupportIcon from '../icons/support.png'
import logList from '../icons/logList.png'
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import DropDownWithButton from '../ReusableComponents/DropDownWithButton.js';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={Menu} alt="I am alt" />
    </a>
));

class newSubscription extends Component {

    state = {
        user: {},
        type: "",
        showBackIcon: true,
        loading: true,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        item_id: '',
        limits: 1,
        items: [],
        plan_id: 0,
        plan_name: '',
        plan_status: '',
        plan_cost: 0,
        plan_discount: 0,
        price_id: '',
        plan_created_date: '',
        popupText: '',
        createIcon: '',
        redirect: false,
        deletePopup: false,
        planItems: [],
        itemAddPopup: false,
        isItem: false,
        selectedItem: {},
        editItem: false,
        description: "",
        plan_type: "",
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        ok: false,
        addOnDiscountCoupons: [],
        selectedDiscountCoupon: { name: "Select coupon" }
    }

    onChangeListener = async (e) => {
        let t = e.target;
        if (t.name != 'plan_discount') {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value
            }))
        }
        else if (t.value >= 0 && t.value < 100) {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value
            }))
        }
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
            redirect: !this.state.isItem && this.state.ok,
        }))
    }

    getSubscriptionItem = () => {
        API.getSubscriptionItem().then((data) => {
            this.setState((state) => ({
                ...state,
                items: data.items,
                item_id: data.items[0].id
            }))
        })
    }

    updateSubscriptionPlan = () => {
        this.setState((state) => ({
            itemName: "Subscription Plan",
            processPopupIcon: "WAIT",
            processAction: "UPDATE",
            processMessage: null,
            showProcessPopup: true,
            isItem: false,
            ok: false
        }))
        let { plan_id, plan_status, plan_cost, plan_discount, description, price_id, plan_type, selectedDiscountCoupon } = this.state
        API.updateSubscriptionPlan(plan_id, plan_status, null, plan_cost, plan_discount, description, price_id, plan_type, selectedDiscountCoupon.id).then((data) => {
            if (data.data) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Subscription Plan",
                    processPopupIcon: "COMPLETE",
                    processAction: "UPDATE",
                    processMessage: null,
                    showProcessPopup: true,
                    ok: true
                }))
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Subscription Plan",
                    processPopupIcon: "ERROR",
                    processAction: "UPDATE",
                    processMessage: data.message,
                    showProcessPopup: true,
                }))
            }
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                itemName: "Subscription Plan",
                processPopupIcon: "ERROR",
                processAction: "UPDATE",
                processMessage: e.message,
                showProcessPopup: true,
            }))
        })
    }

    // Update subscription plan item
    updateSubscriptionPlanItem = () => {
        this.setState((state) => ({
            itemName: "Subscription Item",
            processPopupIcon: "WAIT",
            processAction: "UPDATE",
            processMessage: null,
            showProcessPopup: true,
            isItem: true
        }))
        let { item_id, plan_id, limits } = this.state
        API.updateSubscriptionPlanItem(plan_id, item_id, limits).then((data) => {
            if (data.message == "Item updated.") {
                this.setState((state) => ({
                    ...state,
                    itemName: "Subscription Item",
                    processPopupIcon: "COMPLETE",
                    processAction: "UPDATE",
                    processMessage: null,
                    showProcessPopup: true,
                }),
                    () => {
                        this.getSubscriptionPlanItems()
                    })
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Subscription Item",
                    processPopupIcon: "ERROR",
                    processAction: "UPDATE",
                    processMessage: data.message,
                    showProcessPopup: true,
                }))
            }
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                itemName: "Subscription Item",
                processPopupIcon: "ERROR",
                processAction: "UPDATE",
                processMessage: e.message,
                showProcessPopup: true,
            }))
        })
    }
    //Save subscription plan item
    addSubPlanItem = () => {
        this.setState((state) => ({
            itemName: "Subscription Item",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: null,
            showProcessPopup: true,
            isItem: true
        }))
        let { plan_id, limits, item_id } = this.state
        let res = {}
        API.saveSubscriptionPlanItem(plan_id, item_id, limits).then(async (data) => {
            res = await data.json()
            if (data.status == 200) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Subscription Item",
                    processPopupIcon: "COMPLETE",
                    processAction: "ADD",
                    processMessage: null,
                    showProcessPopup: true,
                    isItem: true
                }), () => {
                    this.getSubscriptionPlanItems()
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Subscription Item",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: res.message,
                    showProcessPopup: true,
                    isItem: true
                }))
            }
        }, async (e) => {
            this.setState((state) => ({
                ...state,
                itemName: "Subscription Item",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }
    // Get subscription plan items list unser the plan
    getSubscriptionPlanItems = () => {
        API.getSubscriptionPlanItems(this.state.plan_id).then((data) => {
            this.setState((state) => ({
                ...state,
                planItems: data.items,
                loading: false
            }))
        })
    }
    // Delete subsciption plan
    deleteSubscriptionPlan = () => {
        API.deleteSubscriptionPlan(this.state.plan_id).then((data) => {
            if (data.message == 'Subscription plan deleted.') {
                this.setState((state) => ({
                    ...state,
                    itemName: "Subscription Plan",
                    processPopupIcon: "COMPLETE",
                    processAction: "DELETE",
                    processMessage: null,
                    showProcessPopup: true,
                    ok: true
                }))
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Subscription Plan",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: data.message,
                    showProcessPopup: true,
                }))
            }
        }, (e) => {
            this.setState((state) => ({
                ...state,
                itemName: "Subscription Plan",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: "Something went wrong",
                showProcessPopup: true,
            }))
        })
    }

    // Delete subsciption plan item
    deleteSubscriptionPlanItem = () => {
        API.deleteSubscriptionPlanItem(this.state.plan_id, this.state.item_id).then((data) => {
            if (data.message == 'Subscription item deleted.') {
                this.setState((state) => ({
                    ...state,
                    itemName: "Subscription Item",
                    processPopupIcon: "COMPLETE",
                    processAction: "DELETE",
                    processMessage: null,
                    showProcessPopup: true,
                }),
                    () => {
                        this.getSubscriptionPlanItems()
                    })
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Subscription Item",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: data.message,
                    showProcessPopup: true,
                }))
            }
        }, (e) => {
            this.setState((state) => ({
                ...state,
                itemName: "Subscription Plan",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: "Something went wrong",
                showProcessPopup: true,
            }))
        })
    }
    getDiscountCoupons = () => {
        if (this.state.plan_addon_discount_coupon_id)
            API.getUserSubscriptionDiscount(this.state.plan_addon_discount_coupon_id).then(res => {
                const disc = res.discount[0]
                this.setState({ selectedDiscountCoupon: { ...this.state.selectedDiscountCoupon, ...disc } })
            })
    }
    handleDiscountCouponChange = (e) => {
        this.setState({ selectedDiscountCoupon: this.state.addOnDiscountCoupons.find(c => c.id === e.target.value) })
    }
    successRedirectComponent = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/admin/allsubscription",
                state: {
                    user: this.state.user,
                    type: this.state.type,
                    sidebarMode: this.state.sidebarMode,
                }
            }} />
        }
    }
    componentWillMount() {
        document.title = "AeroMegh - Subscription";
        if (this.props.location.state) {
            let { user, type, sidebarMode, plan } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type,
                sidebarMode,
                plan_id: plan.id,
                plan_name: plan.plan_name,
                plan_type: plan.plan_type,
                plan_status: plan.status,
                price_id: plan.price_id,
                plan_cost: plan.plan_cost ? plan.plan_cost : 0,
                plan_discount: plan.discount ? plan.discount : 0,
                plan_created_date: plan.created_date,
                description: plan.description,
                plan_addon_discount_coupon_id: plan.drone_addon_discount_coupon
            }), () => {
                this.getSubscriptionPlanItems()
                this.getSubscriptionItem()
                this.getDiscountCoupons()
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }))
            }, (e) => {
                window.location.replace("/login");
            })
        }
        API.getAllDiscountCoupons().then(res => {
            this.setState({
                addOnDiscountCoupons: [{
                    id: "No Coupon",
                    coupon_name: "No Coupon",
                    discount: "-"
                }, ...res.coupons]
            })
        }).catch(err => console.log(err))
    }

    render() {
        let { user, type } = this.state
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: install,
                    text: "Installations",
                    path: "/admin/gcs-installation",
                },
                {
                    icon: TaskIcon,
                    text: "Tasks",
                    path: "/admin/tasks",
                },
                {
                    icon: logList,
                    text: "Task Logs",
                    path: "/admin/taskLogs",
                },
                {
                    icon: SupportIcon,
                    text: "Support",
                    path: "/admin/supportEmails",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode,
        }
        return (
            user.username ? <div className="wrapper">
                <SideBar config={sidebarConfigurations} showMainDashboardIcons={this.state.showBackIcon} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    {this.successRedirectComponent()}

                    {/* -------------- Activity Popup ----------------------- */}

                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => { this.state.isItem ? this.deleteSubscriptionPlanItem() : this.deleteSubscriptionPlan() }}
                    />

                    {/* Add item popup */}
                    <AnimatedModal
                        isOpen={this.state.itemAddPopup}
                        onRequestClose={() => {
                            this.setState((state) => ({
                                ...state,
                                inviteModal: false,
                            }))
                        }}
                        height="360px"
                        width="400px"
                    >

                        <div style={{ textAlign: "center", margin: '20px 0px', height: '40px', fontSize: '22px', fontWeight: '500' }}>{this.state.editItem ? "Edit Item Limit" : "Add An Item"}</div>
                        <div style={{ height: '5px', width: '100%' }}><hr /></div>
                        <div style={{ margin: '10px 50px' }}>
                            <div className="add-user-form-label">
                                Item
                            </div>
                            <div className="add-user-form-text-wrapper">
                                <select
                                    disabled={this.state.editItem}
                                    name="item_id"
                                    onChange={this.onChangeListener}
                                    value={this.state.item_id}
                                    className="sub-add-user-form-text"
                                >
                                    {this.state.items.map((item) => {
                                        return <option value={item.id}>{item.item_name}</option>
                                    })
                                    }
                                </select>
                            </div>
                            <div className="add-user-form-label" style={{ marginTop: '15px' }}>
                                Limit
                            </div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    className="sub-add-user-form-text"
                                    type="number"
                                    name="limits"
                                    defaultValue={1}
                                    placeholder="Enter Limit"
                                    onChange={this.onChangeListener}
                                    value={this.state.limits}
                                />
                            </div>
                        </div>
                        <div style={{ margin: '50px 50px 10px 50px', display: 'flex', justifyContent: 'space-between' }}>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        itemAddPopup: false,
                                    }));
                                }}
                            >
                                Cancel
                            </button>
                            {this.state.item_id && this.state.limits ? <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        itemAddPopup: false,
                                        popupText: "Addning Item Please Wait...",
                                    }), () => {
                                        this.state.editItem ? this.updateSubscriptionPlanItem() : this.addSubPlanItem();
                                    })
                                }}
                            >
                                Save
                            </button> : <button style={{ opacity: '0.5' }}
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                            >
                                Save
                            </button>}
                        </div>
                    </AnimatedModal>
                    {/* -------------- */}

                    <div className="top-bar"><div className="top-bar-text">New Subscription</div></div>
                    <div className="flights-box" style={{ marginLeft: "20%", background: 'none', width: "60vw" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "3% 3% 1% 3%" }}>
                            <div className="services-content-title" style={{ fontSize: '18px' }}>{this.state.plan_name} {this.state.plan_type}</div>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <button style={{ marginRight: '20px' }}
                                        className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                                        onClick={() => {
                                            this.setState((state) => ({
                                                ...state,
                                                itemName: "Subscription Plan",
                                                processPopupIcon: "WARNING",
                                                processAction: "DELETE",
                                                processMessage: null,
                                                showProcessPopup: true,
                                                isItem: false,
                                                ok: false
                                            }));
                                        }}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        className="add-user-form-submit-button popup-btn-right btn-ok"
                                        onClick={() => {
                                            this.setState((state) => ({
                                                ...state,
                                            }), () => {
                                                this.updateSubscriptionPlan();
                                            })
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: "0% 2%", display: "flex" }}>
                            <div className="add-user-form-label" style={{ margin: '0px 15px' }}>
                                Date created :
                            </div>
                            <div className="add-user-form-label">
                                {dateFormat(this.state.plan_created_date, "dd mmm , yyyy")}
                            </div>
                        </div>
                        <div style={{ display: "flex", padding: "1% 2%" }}>
                            <div className='add-user-form-label' style={{ margin: '6px 15px' }}>Description</div>
                            <div className="add-user-form-text-wrapper" style={{ width: "87%", marginLeft: '22px' }}>
                                <input
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="description"
                                    placeholder="Enter Description"
                                    onChange={this.onChangeListener}
                                    value={this.state.description}
                                />
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", padding: "0% 2%" }}>
                            <div style={{ display: "flex" }}>
                                <div className="add-user-form-label" style={{ margin: '6px 68px 6px 15px' }}>
                                    Status
                                </div>
                                <div className="add-user-form-text-wrapper">
                                    <select
                                        name="plan_status"
                                        onChange={this.onChangeListener}
                                        value={this.state.plan_status}
                                        className="sub-add-user-form-text"
                                        style={{ width: "200px" }}
                                    >
                                        <option>Active</option>
                                        <option>Inactive</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{ display: "flex", marginRight: "25px" }}>
                                <div className='add-user-form-label' style={{ margin: '6px 20px 6px 15px' }}>Plan Type</div>
                                <input
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="plan_type"
                                    placeholder="e.g Trial"
                                    onChange={this.onChangeListener}
                                    value={this.state.plan_type}
                                    style={{ width: "150px" }}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", margin: "1% 2%" }}>
                            <div style={{ display: "flex", margin: '0px 26px 0px 15px' }}>
                                <div className='add-user-form-label' style={{ margin: '6px 80px 6px 0px' }}>Cost</div>
                                <input
                                    className="sub-add-user-form-text"
                                    type="number"
                                    name="plan_cost"
                                    placeholder="Cost"
                                    onChange={this.onChangeListener}
                                    value={this.state.plan_cost}
                                    style={{ width: "200px" }}
                                />
                                <div style={{ color: "#666666", margin: '6px 2px' }}>{"₹"}</div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", margin: "0% 2%" }}>
                                <div style={{ display: "flex", margin: '' }}>
                                    <div className='add-user-form-label' style={{ margin: '6px 26px 6px 0px' }}>Stripe Price ID</div>
                                    <input
                                        className="sub-add-user-form-text"
                                        type="test"
                                        name="price_id"
                                        placeholder="Stripe Price Id"
                                        onChange={this.onChangeListener}
                                        value={this.state.price_id}
                                        style={{ width: "400px" }}
                                    />
                                </div>
                            </div>

                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", margin: "1% 2%" }}>
                            <div style={{ display: "flex", margin: "0px 26px 0px 15px" }}>
                                <div className='add-user-form-label' style={{ margin: '6px 55px 6px 0px' }}>Discount</div>
                                <input
                                    className="sub-add-user-form-text"
                                    type="number"
                                    name="plan_discount"
                                    placeholder="Discount"
                                    onChange={this.onChangeListener}
                                    value={this.state.plan_discount}
                                    style={{ width: "200px" }}
                                />
                                <div style={{ color: "#666666", margin: '6px 2px' }}>{"%"}</div>
                            </div>
                            <div style={{ display: "flex", marginRight: "20px", alignItems: "center" }}>
                                <div className='add-user-form-label' style={{ margin: '6px 20px 6px 15px' }}>Addon Discount</div>
                              
                               
                                <div className="add-user-form-text-wrapper">
                                    <select
                                        onChange={this.handleDiscountCouponChange}
                                        value={this.state.selectedDiscountCoupon.id}
                                        className="sub-add-user-form-text"
                                        style={{ width: "200px", maxHeight: "220px" }}
                                    >
                                        {this.state.addOnDiscountCoupons.map((coupon) => <option value={coupon.id}>{`${coupon.coupon_name ? `${coupon.coupon_name} (${coupon.discount}%)` : `${coupon.discount}%`}`}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", margin: "3% 4% 1% 4%", justifyContent: 'space-between', }}>
                            <div className='sub-add-user-form-label'>Subscription Items</div>
                            <div style={{ display: 'flex' }}>
                                <div className="add-user-form-text-wrapper" style={{ marginLeft: "25px", marginRight: '5px' }}>
                                    <img src={btnAdd} style={{ height: '30px', width: '34px', cursor: 'pointer' }} onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            editItem: false,
                                            limits: 1,
                                            item_id: this.state.items[0].id,
                                            itemAddPopup: true,
                                        }));
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "92%", marginLeft: "4%" }}>
                            <table className="customers-table">
                                <col style={{ width: '40%' }} />
                                <col style={{ width: '30%' }} />
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '10%' }} />
                                <tr className="customers-table-header-row">
                                    <th >id</th>
                                    <th>Name</th>
                                    <th >Limit</th>
                                    <th ></th>
                                </tr>
                                {this.state.planItems.length === 0 ?
                                    <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                        <td colspan="4">
                                            <p style={{ fontSize: '14px' }}>No Items available</p>
                                        </td>
                                    </tr> :
                                    this.state.planItems.map((item) =>
                                        <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                            <td> {item.item_id} </td>
                                            <td> {item.item_name} </td>
                                            <td > {item.limits} {item.unit != 'count' ? item.unit : ''} </td>
                                            <td>
                                                <Dropdown style={{ margin: "0 20px" }}>
                                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu size="sm" title=""
                                                        className="custom-dropdown-settings"
                                                    >
                                                        <Dropdown.Item eventKey="delete"
                                                            className="dropdown-set-padding"
                                                        >
                                                            <div style={{ display: "flex", cursor: 'pointer' }}
                                                                onClick={(e) => {
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        item_id: item.item_id,
                                                                        limits: item.limits,
                                                                        editItem: true,
                                                                        itemAddPopup: true
                                                                    }))
                                                                }}
                                                            >
                                                                {/* <img src={Delete} /> */}
                                                                <div style={{ marginLeft: '10px' }}>Edit</div>
                                                            </div>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey="delete"
                                                            className="dropdown-set-padding"
                                                        >
                                                            <div style={{ display: "flex", cursor: 'pointer' }}
                                                                onClick={(e) => {
                                                                    // e.preventDefault()
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        // selectedProject: project,
                                                                        // deleteItemType: "project"
                                                                        itemName: "Subscription Item",
                                                                        processPopupIcon: "WARNING",
                                                                        processAction: "DELETE",
                                                                        processMessage: null,
                                                                        showProcessPopup: true,
                                                                        item_id: item.item_id,
                                                                        isItem: true,
                                                                    }))
                                                                }}
                                                            >
                                                                {/* <img src={Delete} /> */}
                                                                <div style={{ marginLeft: '10px' }}>Delete</div>
                                                            </div>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    )}
                            </table>
                        </div>
                    </div>
                </div>
            </div> : <div />
        );
    }
}

export default newSubscription;

