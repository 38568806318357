import React, { Component } from 'react';
import dashboardIcon from '../../icons/AeroCaptureInactive.png';
import service from "../../sidebaricon/Services.png";
import * as API from '../../api.js';
import { Link } from 'react-router-dom';
import { addUserAnalytic } from '../../ReusableComponents/reusableFunctions';
import { connect } from "react-redux";
import AeroMeghLogo from "../../aeromeghLog.png";
import DetectorIcon from '../../assets/Icons/detection.png'
import Loader from '../../icons/loader.svg';
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal';
import closeButton from '../../close-button.png';
import dateFormat from 'dateformat';
import { SortComponent } from '../../ReusableComponents/SortComponent';
import { DeleteComponentTable } from '../../ReusableComponents/Menu';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import Help from "../../sidebaricon/Help.png";
import Search from "../../Icon/Search.png";
import AppConstants from '../../AppConstants';
import { ActiveRole } from '../../Teams';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';


class PicStorkUserDetectors extends Component {

    state = {
        outId: "",
        loading: false,
        detectors: [],
        currentpage: 1,
        counts: 0,
        userperpage: 8,
        showDetectorDetails: false,
        selectedDetectorId: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        itemName: "Detector",
        key: "",
        downloadZip: false,
        downloadZipPopup: false
    }

    getUserDetectors = () => {
        this.setState((state) => ({
            tableLoading: true,
        }))
        const { userperpage, currentpage, key } = this.state
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;

        API.getDetectors('user', userperpage, firstpage, key === "" ? " " : key)
            .then(data => {
                this.setState((state) => ({
                    ...state,
                    detectors: data.detectors,
                    counts: data.count,
                    tableLoading: false
                }))
            })
    }


    deleteDetector = () => {
        this.setState((state) => ({
            itemName: "Detector",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.deleteDetector('user', this.state.selectedDetectorId)
            .then(data => {
                this.setState((state) => ({
                    ...state,
                    itemName: "Detector",
                    processPopupIcon: "COMPLETE",
                    processAction: "DELETE",
                    processMessage: null,
                    showProcessPopup: true,
                    detectors: []
                }), () => {
                    this.getUserDetectors()
                })
            }, (e) => {
                //console.log(e);
                this.setState((state) => ({
                    itemName: "Detector",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: e,
                    showProcessPopup: true,
                }))
            })
    }

    downloadDetectorZip = () => {
        this.setState((state) => ({
            ...state,
            downloadZipPopup: true
        }))

        API.downloadDetectorZip(this.state.selectedDetectorId)
            .then(data => {
                this.setState((state) => ({
                    downloadZipPopup: false,
                }))
            }, (e) => {
                this.setState((state) => ({
                    itemName: "Detector",
                    processPopupIcon: "ERROR",
                    processAction: "DOWNLOAD",
                    processMessage: "An error occured while downloading detector zip.",
                    showProcessPopup: true,
                }))
            })
    }

    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getUserDetectors();
            });
        } else {
            console.log("Reached last page")
        }
    }

    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    this.getUserDetectors();

                });
        }
    }
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getUserDetectors();
            });
        } else {
            console.log("Reached last page")
        }
    }

    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                this.getUserDetectors();
            });
        } else {
            console.log("Reached last page")
        }
    }

    changepage(num) {
        if (isNaN(num) || num < 1 || num > Math.ceil(this.state.counts / this.state.userperpage)) {
            return null;
        } else if (num != 0) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.abs(num),
            }), () => this.getUserDetectors());
        }
    }

    search(key) {
        this.setState((state) => ({
            ...state,
            key,
            currentpage: 1
        }),
            () => {
                this.getUserDetectors()
            }
        );
    }

    getSelectedDetector = () => {
        let dets = this.state.detectors
        let obj = dets.find(o => o.id === this.state.selectedDetectorId)

        this.setState((state) => ({
            selectedDetector: obj,
            showDetectorDetails: true
        }))
    }


    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.detectors.sort((a, b) => a.display_name.localeCompare(b.display_name))
        this.setState((state) => ({
            ...state,
            detectors: sort,
        }));
    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.detectors.sort((a, b) => b.display_name.localeCompare(a.display_name))
        this.setState((state) => ({
            ...state,
            detectors: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.detectors.sort((a, b) => a.date_created.localeCompare(b.date_created))
        this.setState((state) => ({
            ...state,
            detectors: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.detectors.sort((a, b) => b.date_created.localeCompare(a.date_created))
        this.setState((state) => ({
            ...state,
            detectors: sort,
        }));
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
            downloadZipPopup: false
        }))
    }


    componentWillMount() {
        addUserAnalytic(this.props.location.pathname)
            .then(id => {
                console.log("ID : ", id)
                this.setState((state) => ({
                    outId: id
                }))
            })
        document.title = "PicStork - User Detectors";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            console.log(this.props);
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => {
                this.getUserDetectors()
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.getUserDetectors()
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }


    render() {
        let { user, type, detectors, userperpage, currentpage } = this.state

        return (

            user.username ?
                <div className="wrapper">

                    {/* ------------------ Detector Details Popup--------------------- */}
                    <AnimatedModal
                        isOpen={this.state.showDetectorDetails}
                        onRequestClose={() => {
                            this.setState((state) => ({
                                ...state,
                                showDetectorDetails: false,
                            }))
                        }}
                        height="415px"
                        width="630px"
                    >
                        <div style={{ textAlign: "center", margin: '10px 0px', height: '30px', fontSize: '22px', fontWeight: '500' }}>Detector Details</div>
                        <img
                            src={closeButton} style={{ width: "22px", height: "22px", position: "absolute", top: "15px", right: "20px", cursor: "pointer" }} onClick={() => {
                                this.setState((state) => ({
                                    ...state,
                                    showDetectorDetails: false
                                }))
                            }}></img>
                        <div style={{ height: '5px', width: '100%' }}><hr /></div>
                        {this.state.selectedDetector ? <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column", width: "95%", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey' >Detector Name</div>
                                    <div className='objectValue'>{this.state.selectedDetector.display_name.length > 30 ?
                                        this.state.selectedDetector.display_name.substring(0, 30) + "..." : this.state.selectedDetector.display_name}</div>
                                </div>
                                <div style={{ height: "60px", margin: "0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey'>Source task</div>
                                    <div className='objectValue'>{this.state.selectedDetector.task_name?.length > 30 ?
                                        this.state.selectedDetector.task_name.substring(0, 30) + "..." : this.state.selectedDetector.task_name}</div>
                                </div>
                            </div>

                            <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey'>Accuracy</div>
                                    <div className='objectValue'>{this.state.selectedDetector.accuracy}</div>

                                    {/* <div className='objectValue'>{this.state.taskData.plan_name.length > 25 ?
                                    this.state.taskData.plan_name.substring(0, 30) + "..." : this.state.taskData.plan_name}</div> */}
                                </div>
                                <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey'>Date created</div>
                                    <div className='objectValue'>{dateFormat(this.state.selectedDetector.date_created, "dd mmm yyyy")}</div>
                                </div>
                            </div>

                            <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey'>No. of Images</div>
                                    <div className='objectValue'>{this.state.selectedDetector.images}</div>
                                </div>
                                <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                <div style={{ margin: '10px 5px', width: "39%", display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey' >Objects</div>
                                    <div style={{ height: '100px', width: '200px', overflowY: 'scroll', textAlign: 'left' }}>
                                        {this.state.selectedDetector.objects && this.state.selectedDetector.objects.length > 0 ?
                                            this.state.selectedDetector.objects.map((obj, i) => i == this.state.selectedDetector.objects.length - 1 ? <label>{obj.length >= 20 ? obj.substring(0, 17) + "..." : obj}</label>
                                                : <label>{obj.length >= 20 ? obj.substring(0, 17) + "..." : obj},&nbsp;</label>) : <></>}
                                    </div>

                                </div>
                            </div>
                        </div> :
                            <></>
                        }

                    </AnimatedModal>


                    {/* -------------- Activity Popup ----------------------- */}
                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => this.deleteDetector()}
                    />

                    {/* --------------------------------------------------------------- */}


                    {/* -------------- Download detector popup with loader ----------------------- */}
                    <ActivityPopup
                        item={"Detector Zip"}
                        open={this.state.downloadZipPopup}
                        icon={"WAIT"}
                        action={""}
                        title={"Download progress"}
                        msg={' '}
                        close={() => this.closeActivityPopup()}
                    >
                        <div style={{ height: "70%", width: "100%", textAlign: "Left" }}>
                            Please wait while your zip file is being created...
                            <img src={Loader} style={{ height: "70px", width: "100%", marginLeft: "15px" }} />
                        </div>
                    </ActivityPopup>

                    {/* --------------------------------------------------------------- */}

                    <div style={{ display: 'flex' }}>
                        <div className="sidebar-expanded" style={{ paddingTop: "0px" }}>
                            <div style={{ display: "flex", height: "40px" }}>
                                <div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
                                    <img
                                        src={AeroMeghLogo}
                                        style={{ width: "65px", height: "20px", marginTop: "10px" }}
                                    />
                                </div>
                            </div>
                            <ul className="sidebar-nav" id="sidebar-nav">
                                <div className={"nonSelectBorder"} >
                                    <li>
                                        <Link
                                            to={{
                                                pathname: "/",
                                                state: {
                                                    type,
                                                    user,
                                                },
                                            }}
                                        >
                                            <div
                                                className="sidecontent"
                                                id="sidecontent"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="sidebar-icon">
                                                    <img
                                                        className="sidebar-icon"
                                                        src={service}
                                                        alt="serviceIcon"
                                                    />
                                                </div>
                                                <div
                                                    name="service"
                                                    className={"sidebaropt"}
                                                    // style={{ color: "#2989CF" }}
                                                    onClick={this.changeColor}
                                                >
                                                    {"Services"}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </div>
                                <div className={"nonSelectBorder"} >
                                    <li>
                                        <Link
                                            to={{
                                                pathname: "/picstork/dashboard",
                                                state: {
                                                    type,
                                                    user,
                                                },
                                            }}
                                        >
                                            <div
                                                className="sidecontent"
                                                id="sidecontent"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="sidebar-icon">
                                                    <img
                                                        className="sidebar-icon"
                                                        src={dashboardIcon}
                                                        alt="serviceIcon"
                                                    />
                                                </div>
                                                <div
                                                    name="service"
                                                    className={"sidebaropt"}
                                                    // style={{ color: "#2989CF" }}
                                                    onClick={this.changeColor}
                                                >
                                                    {"PicStork"}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </div>

                                <div className={"selectBorder"} >
                                    <li>
                                        <Link
                                            to={{
                                                pathname: "/picstork/userdetectors",
                                                state: {
                                                    type,
                                                    user,
                                                    plan: this.props.location.state.plan,
                                                    project: this.props.location.state.project,
                                                },
                                            }}
                                        >
                                            <div
                                                className="sidecontent"
                                                id="sidecontent"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="sidebar-icon">
                                                    <img
                                                        className="sidebar-icon"
                                                        src={DetectorIcon}
                                                        alt="serviceIcon"
                                                    />
                                                </div>
                                                <div
                                                    name="service"
                                                    className={"sidebaropt"}
                                                    style={{ color: "#2989CF" }}
                                                    onClick={this.changeColor}
                                                >
                                                    {"Detectors"}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </div>

                                <div className={"nonSelectBorder"} >
                                    <li>
                                        <div
                                            className="onhoverext"
                                            onClick={
                                                () => {
                                                    window.open("https://dronenaksha-docs.aeromegh.com/", "_blank")
                                                }
                                            }
                                        >
                                            <div
                                                className="sidecontent"
                                                id="sidecontent"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="sidebar-icon">
                                                    <img
                                                        className="sidebar-icon"
                                                        src={Help}
                                                        alt="serviceIcon"
                                                    />
                                                </div>
                                                <div
                                                    name="service"
                                                    className={"sidebaropt"}
                                                >
                                                    {"Help"}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                    </div>

                    <div className="right-content"
                        style={{ zIndex: 0 }}
                        onClick={
                            () => {
                                this.setState((state) => ({
                                    ...state, sidebarMode: 'shrunk'
                                }))
                            }
                        }
                    >

                        <div className="top-bar">
                            <div className="top-bar-text">
                                DETECTORS <ActiveRole />
                            </div>

                        </div>

                        {
                            this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                                <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                            </div> :
                                <div className="main-content" style={{ background: 'none' }}>

                                    <div style={{ display: "flex" }}>
                                        <div className="services-content-title">{"Detectors"}
                                            <span className='data-count'>({this.state.counts})</span>
                                        </div>
                                        <div className="add-user-form-row-left" style={{ marginLeft: 'auto' }}>
                                            <div className="add-user-form-text-wrapper">
                                                <img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
                                                <input type="text" className="add-user-form-text" placeholder="Search Task" style={{ height: '30px !important', width: "230px", paddingLeft: '28px' }}
                                                    onChange={(event) => this.search(event.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <table className="customers-table scroll-container">
                                        <col style={{ width: "2%" }} />
                                        <col style={{ width: "20%" }} />
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "10%" }} />
                                        <tr className="customers-table-header-row">
                                            <th></th>
                                            <th>Name
                                                <SortComponent
                                                    style={{ marginLeft: '5px' }}

                                                    sortAscending={this.sortByName}
                                                    sortDecending={this.reverseByName}
                                                    sortASC={false}
                                                />
                                            </th>
                                            <th>Objects</th>
                                            <th>Accuracy</th>
                                            <th>Type</th>
                                            <th>Images</th>
                                            <th>Date created
                                                <SortComponent
                                                    style={{ marginLeft: '5px' }}

                                                    sortAscending={this.sortByDate}
                                                    sortDecending={this.reverseByDate}
                                                    sortASC={false}
                                                />
                                            </th>
                                            {/* <th>Download
                                            </th> */}
                                            <th></th>
                                        </tr>
                                        {
                                            this.state.tableLoading ?
                                                <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                                    <td colspan="8">  <img src={Loader} style={{ margin: "auto", height: '6%', width: '6%' }} /></td>
                                                </tr>
                                                :
                                                detectors.length === 0 ?
                                                    <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                                        <td colspan="8">No detectors available</td>
                                                    </tr> :
                                                    detectors.map((detector) => {
                                                        return (
                                                            <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                                <td></td>
                                                                <td onClick={() => {
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        selectedDetectorId: detector.id,
                                                                        showDetectorDetails: true
                                                                    }), () => {
                                                                        this.getSelectedDetector()
                                                                    });
                                                                }} style={{ cursor: "pointer" }}>{detector.display_name.length > 30 ? detector.display_name.substring(0, 30) + '...' : detector.display_name}</td>
                                                                <td>{detector.objects.toString().length > 30 ?
                                                                    detector.objects.toString().substring(0, 30) + '...'
                                                                    :
                                                                    detector.objects.toString()
                                                                }</td>
                                                                <td>{detector.accuracy}</td>
                                                                <td>{detector.name.includes(AppConstants.ORTHODETECTORTYPE) ? 'Orthomosaic' : 'Image'}</td>
                                                                <td style={{ paddingLeft: '30px' }}>{detector.images}</td>
                                                                <td> {
                                                                    (() => {
                                                                        let date = (new Date(detector.date_created)).toGMTString().split(' ').slice(1, 4).join(' ');
                                                                        return `${date}`
                                                                    })()
                                                                }</td>
                                                                <td>
                                                                    <DeleteComponentTable
                                                                        style={{ margin: "0 10px" }}
                                                                        onClickDelete={(e) => {
                                                                            e.preventDefault();
                                                                            this.setState((state) => ({
                                                                                ...state,
                                                                                selectedDetectorId: detector.id,
                                                                                itemName: "Detector",
                                                                                processPopupIcon: "WARNING",
                                                                                processAction: "DELETE",
                                                                                processMessage: null,
                                                                                showProcessPopup: true,
                                                                            }))
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>)
                                                    }
                                                    )}
                                    </table>
                                    {/* This is footer  */}
                                    <footer className="footer" style={{ visibility: (this.state.counts > 8 ? "visible" : "hidden") }} >
                                        <div style={{ float: "left", marginTop: "0.5rem", display: "flex" }}>
                                            <div style={{ color: "#109cf1", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                                Page : {currentpage}
                                            </div>
                                        </div>
                                        <div style={{ float: "right", marginTop: "0.5rem", display: "flex" }}>
                                            <ButtonWithIcon
                                                text="First"
                                                isBtnActive
                                                isDisabled={this.state.currentpage == 1}
                                                onClick={this.state.currentpage == 1 ? false : this.firstpage}
                                            />
                                            <ButtonWithIcon
                                                text="Prev"
                                                isDisabled={(this.state.currentpage == 1)}
                                                onClick={(this.state.currentpage == 1) ? false : this.prevpage}
                                            />
                                            <form>
                                                <div style={{ display: "flex" }}>
                                                    <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                                        Go to page :
                                                    </div>

                                                    <div className="add-user-form-text-wrapper">
                                                        <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                                                            onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                                            onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                                    </div>
                                                </div>
                                            </form>
                                            <ButtonWithIcon
                                                text="Next"
                                                isDisabled={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage))}
                                                onClick={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage)) ? false : this.nextpage}
                                            />
                                            <ButtonWithIcon
                                                text="Last"
                                                isBtnActive
                                                isDisabled={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage))}
                                                onClick={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage)) ? false : this.lastpage}
                                            />
                                        </div>
                                    </footer>
                                </div>
                        }
                    </div>
                </div>
                : <>
                </>
        );
    }

}

const mapStateToProps = state => {
    return {
        SubscriptionData: state.subscription.subscriptionData,
    };
};

export default connect(mapStateToProps)(PicStorkUserDetectors);