import React, { useEffect, useState } from 'react'
import MarkerIcon from '../assets/marker.png'
import Autocomplete from "react-google-autocomplete";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import LocationMap from "../AeroFarm/Components/subComponents/LocationMap"
import closeIcon from "../../src/close-button.png"
import ButtonWithIcon from './ButtonWithIcon';
const LocationSelector = ({ closePopUp, getSelectedLocation }) => {
    // let mapInState = undefined
    // let mapMarker = undefined
    const [center, setCenter] = useState({ lat: 19.993982, lng: 73.790416 })
    const [mapInState, setMapInState] = useState(undefined)
    const [mapMarker, setMapMarker] = useState(undefined)
    const [locationName, setLocationName] = useState('')
    const handleMapClick = (e) => {
        console.log(e, mapMarker)

        let center = { lat: e.latlng.lat, lng: e.latlng.lng }
        console.log("marker in handle map click", mapMarker)

        setCenter(center)

    }
    const changeMarkerPosition = (mark) => {
        mapInState.setView(mark);
        mapMarker.setLatLng(mark);
    }
    const onchangePlace = (e) => {

        let val = e.target.value.trim()
        console.log("val", val)
        let seperator = val.indexOf(",") != -1 ? ',' : val.indexOf(" ") != -1 ? " " : " "
        let latlng = val.trim().split(seperator)

        if (latlng.length == 2) {
            setCenter({ lat: latlng[0], lng: latlng[1] })
        }
        // getSelectedLocation({ lat: latlng[0], lng: latlng[1] })

    }
    const handleKeyDown = (event) => {
        console.log(event);
        if (event.key === 'Enter') {
            mapMarker.setLatLng(center);
        }
    }

    const initMap = (map) => {
        var markerOptions = {
            title: "MyLocation",
            clickable: true,
            draggable: true,
            icon: new L.Icon({ iconUrl: MarkerIcon, iconSize: [20, 28], iconAnchor: [10, 28] })
        }
        let marker1 = new L.Marker(center, markerOptions).on("dragend", (e) => {

            let ltlng = e.target._latlng
            if (ltlng) {
                setCenter({ lat: ltlng.lat, lng: ltlng.lng })
            }
        }).addTo(map)
        // mapMarker = marker1
        setMapMarker(marker1)

        map.setView(center, 17);
    };

    useEffect(() => {
        console.log(center)
        if (center && mapMarker && mapInState) {

            mapMarker.setLatLng(center);
            mapInState.setView(center);
        }
    }, [center])
    return (


        <div style={{ height: '100%', width: '100%' }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "1.5% 0px", backgroundColor: "white" }}>
                <div style={{ width: "100%", fontSize: "15px", padding: "", color: "#3c3c3c", fontWeight: "500" }}>Select Location</div>
            </div>
            <div style={{ position: "absolute", right: "3%", top: "2%", width: "20px", height: "20px", marginLeft: "auto", display: "flex", cursor: "pointer", marginRight: "5px" }} onClick={closePopUp}><img style={{ width: "100%", height: "100%" }} src={closeIcon} /></div>
            <div style={{ backgroundColor: "rgb(224, 224, 224)", padding: "10px 30px", height: "100%" }}>

                <div style={{ position: 'absolute', zIndex: '1', background: '#3c3c3c99', padding: '5px', top: "14.5%", left: "4.5%" }}>
                    <Autocomplete
                        style={{ width: '270px' }}
                        className="input-transparent1"
                        placeholder='Search location'
                        apiKey="AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI"
                        onChange={(e) => { onchangePlace(e) }}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onPlaceSelected={(place) => {
                            try {
                                let lat = place.geometry.location.lat();
                                let lng = place.geometry.location.lng();
                                let cntr = {};
                                cntr.lat = parseFloat(lat);
                                cntr.lng = parseFloat(lng);
                                setCenter(cntr)
                                setLocationName(place.formatted_address)
                                changeMarkerPosition(cntr)
                            } catch { }
                        }}
                        types={[]}
                    />

                </div>
                <LocationMap
                    initCenter={[center.lat, center.lng]}
                    initZoom={22}
                    handleMapClick={handleMapClick}
                    maxZoom={23}
                    setMap={(map) => {
                        setMapInState(map)
                        initMap(map)
                    }}
                />
                <div style={{ position: "absolute", bottom: "15px", right: "25px" }}><ButtonWithIcon isBtnActive onClick={() => getSelectedLocation(center)} text={"Proceed"} /></div>
            </div>
        </div>
    )
}

export default LocationSelector