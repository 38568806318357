import React from 'react';
import * as API from '../api.js';
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';

const getStorageUserByService = async (serviceName) => {

  return new Promise(async (resolve, reject) => {
    try {
      let result = await API.getStorageUserByService(serviceName)
      resolve(result)
    }
    catch (err) {
      reject(err)
    }
  })
};


const StorageLimitExceed = ({ seletedDataSize, totalStorage, onClickOk }) => {
  return <>
    <ActivityPopup
      height="170px"
      title={"Storage Limit Exceeded"}
      open={true}
      icon={"ERROR"}
      // action={this.state.processAction}
      msg={`Your current storage usage has exceeded the limit.`}
      close={onClickOk}
      onClickOk={onClickOk}
    />
  </>

}

export {
  getStorageUserByService,
  StorageLimitExceed
}
