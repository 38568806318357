import React, { useEffect, useState } from 'react';
import DropDownWithButton from './DropDownWithButton';
import AppConstants from '../AppConstants';
import * as API from '../api';
import { Link, } from 'react-router-dom';
import LoaderComponent from '../ReusableComponents/LoaderComponent';

const CreditsDetails = ({ user,updateComponent }) => {

  const [buyCredit, setBuyCredit] = useState(undefined);
  const [serviceDetails, setServiceDetails] = useState(undefined);
  const [showLoader, setShowLoader] = useState(true)
  const [userDroneQunatity, setuserDroneQuantity] = useState(0)


  const getTaskCreditsDetails = async () => {
    try {
      let creditDetails = await API.getTaskCreditsDetails();
      let storageData = await API.getUserStorage();
      let limitDetails = await API.getUserDroneLimits(AppConstants.SERVICES.AEROGCS)
      let storageDetails = storageData.storageDetails;

      setServiceDetails([
        {
          "PicStork": {
            sd: storageDetails.find(ser => {
              if (ser.service == AppConstants.SERVICES.PICSTORK) {
                return { used: ser.limit, limit: ser.used }
              }
            }),
            cd: {
              processing: undefined,
              analytics: {
                used: creditDetails.credits[1].credits_usage, limit: creditDetails.credits[1].credits_limit
              }
            }
          }
        },
        {
          "DroneNaksha": {
            sd: storageDetails.find(ser => {
              if (ser.service == AppConstants.SERVICES.DRONENAKSHA) {
                return { used: ser.limit, limit: ser.used }
              }
            }),
            cd: {
              processing: {
                used: creditDetails.credits[0].credits_usage, limit: creditDetails.credits[0].credits_limit
              },
              analytics: undefined
            }
          }
        },
        // {
        //   "AeroGCS": {
        //     sd: storageDetails.find(ser => {
        //       if (ser.service == AppConstants.SERVICES.AEROGCS) {
        //         return { used: ser.limit, limit: ser.used }
        //       }
        //     }),
        //     cd: { used: undefined, limit: undefined },
        //     ld: {
        //       limits: Number(limitDetails.addOnDronesLimits) + Number(limitDetails.serviceDronesLimits)
        //     }
        //   }
        // }
      ])
      setShowLoader(false)
    }
    catch (err) {
      setShowLoader(false)

    }
  }

  useEffect(() => {
    getTaskCreditsDetails()
  }, [updateComponent])

  useEffect(() => {
    if (buyCredit) document.getElementById("BuyCreditLink").click()
  }, [buyCredit])

  return <div style={{ textAlign: "center" }}>
    <Link to={{
      pathname: "/buy-credit",
      state: {
        user: user,
        creditType: buyCredit?.creditType,
      },
    }}
    > <div id="BuyCreditLink" /> </Link>
    {
      showLoader ? <LoaderComponent
        height={"80%"}
        message="Loading data please wait..."
      /> :
        <>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
            <span style={{fontWeight:"500",fontSize:"15px"}}>Credits</span>
            <DropDownWithButton
              selectedItem={"Buy Credits"} isButton
              left="200px" width={"125px"}
              list={[
                { "name": "Processing", onClick: () => { setBuyCredit({ "creditType": AppConstants.SUBSCRIPTOPTIONS.ITEMS.PROCESSING_CREDITS }); } },
                { "name": "Analytics", onClick: () => { setBuyCredit({ "creditType": AppConstants.SUBSCRIPTOPTIONS.ITEMS.ANALYTICS_CREDITS }); } }
              ]}
            />
          </div>
          {
            <table className="customers-table" style={{ marginTop: "10px" }}>
              <tr className="customers-table-header-row">
                <th>Service</th>
                <th>Analytics (GB)</th>
                <th>Processing (GB)</th>
                <th>Storage (GB)</th>
                <th>Total Drones</th>
              </tr>

              {
                serviceDetails?.map(s => {
                  return <tr className="customers-table-data-row" style={{ background: "white", fontSize: "13px", fontWeight: "300", height: "30px", }}>
                    <td style={{ textTransform: "capitalize" }}>{Object.keys(s)[0]}</td>
                    <td>{Object.values(s)[0].cd?.analytics ?
                      `${(Number(Object.values(s)[0].cd?.analytics?.used) / 1024).toFixed(4)} 
            / ${(Number(Object.values(s)[0].cd?.analytics?.limit) / 1024).toFixed(4)}`
                      : `NA`}</td>
                    <td>{Object.values(s)[0].cd?.processing ?
                      `${(Number(Object.values(s)[0].cd?.processing?.used) / 1024).toFixed(4)} 
             / ${(Number(Object.values(s)[0].cd?.processing?.limit) / 1024).toFixed(4)}`
                      : `NA`}</td>
                    <td>{Object.values(s)[0].sd?.used || Object.values(s)[0].sd?.limit ?
                      `${(Number(Object.values(s)[0].sd?.used) / 1024).toFixed(4)} 
             / ${(Number(Object.values(s)[0].sd?.limit) / 1024).toFixed(4)}`
                      : `NA`}</td>
                    <td>{Object.keys(s)[0] == 'AeroGCS' ? Object.values(s)[0].ld?.limits : "NA"}</td>
                  </tr>
                })
              }

            </table>}
        </>
    }
  </div>
}

export default CreditsDetails;

