import Tippy from '@tippyjs/react'
import React, { useState } from 'react'
import { SketchPicker } from 'react-color'

const LayerColor = ({ color, onChangeColor }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    const [selectedColor, setSelectedColor] = useState({ hex: color })
    const handleChange = (color) => {
        setSelectedColor(color)
        onChangeColor(color.hex)
    }
    return (
        <div>
            <Tippy
                visible={displayColorPicker}
                onClickOutside={() => setDisplayColorPicker(false)}
                animation={false}
                interactive
                onShown={(instance) => {
                    document.querySelector('[data-tippy-root]').addEventListener('click', event => {
                        instance.hide();
                        setDisplayColorPicker(false)
                    })
                }}
                onShow={instance => {
                    const el = document.querySelector(".scroll-container")
                    if (el)
                        el.addEventListener('scroll', event => {
                            instance.hide()
                            setDisplayColorPicker(false)
                        })
                }}
                className='tippy-menu'
                arrow={false}
                placement='bottom-end'
                content={<SketchPicker color={selectedColor} onChange={handleChange} />}
            >
                <div onClick={() => setDisplayColorPicker(prev => !prev)} style={{ position: "relative", backgroundColor: selectedColor?.hex || selectedColor, width: "12px", height: "12px", marginLeft: "10px", borderRadius: "50%", cursor: 'pointer' }}>
                </div>
            </Tippy>
        </div>
    )
}

export default LayerColor