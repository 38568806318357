import React, { Component } from "react";
import * as API from "../../api.js";
import edit from '../../icons/pen.png'
import saveImage from '../../icons/saveImage.png'
import close from '../../assets/Icons/Close.png'
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs.js';
import { getDistance, getArea, get3dPolygonArea, getDistanceIn3d, getCenterOfPolygon, getCenterOfLine } from "../../ReusableComponents/map/leafletMeasure.js";
import Loader from '../../icons/loader.svg';
import { addUserAnalytic, extractMessageAfterMention, updateOutTime } from '../../ReusableComponents/reusableFunctions.js'
import { DRONENAKSHA_FEATURES, getFeaturesPermissions } from "../../Teams/index.js";
import AppConstants, { BLOB_URL } from "../../AppConstants.js";

class measurementReport extends Component {
    state = {
        isOwner: false,
        sharedBy: null,
        user: {},
        type: "",
        orthoUrl: '',
        demUrl: '',
        dtmUrl: '',
        pathDetailUrl: this.props.location.state.orthoImageData.image_url.replace("orthomosaic.", "topview."),
        reportData: [],
        showProcessPopup: false,
        loading: true,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        deleteReport_id: '',
        reportheader: {},
        reportTitle: '',
        reportSubTitle: '',
        company_logo: '',
        company_name: '',
        editHeader: false,
        company_logo1: undefined,
        titleEdit: false,
        subTitleEdit: false,
        showDTM: true,
        linesData: [],
        polygonData: [],
        circleData: [],
        markerData: [],
        outId: "",
        volumePolygonData: []
    };

    handleChange = (e) => {
        let t = e.target;
        if (t.value === ' ') {
            console.log('space not allow');
        } else {
            this.setState((prevState) => ({
                ...prevState,
                [t.name]: t.value,
            }))
        }

    }
    checkedTrue(index) {
        let temp = this.state.reportData
        temp[index].visibleInReport = !temp[index].visibleInReport
        this.setState((state) => ({
            ...state,
            reportData: temp
        }))
    }
    getElevation = (data, unit) => {
        return new Promise((resolve, reject) => {
            let { user, orthoImageData } = this.state
            let task_id = orthoImageData.task_id
            let dataObj = { task_id, blob_container: this.state.container, sas_token: this.state.st, lat: data.lat, lng: data.lng }
            API.getElevationOfPoint(dataObj).then(result => {
                let elevationData = result.elevation && result.elevation != 'NA' ? unit ? `${result.elevation.toFixed(2)} m` : result.elevation.toFixed(2) : 'NA'
                resolve(elevationData)
            })
        })
    }

    getPolylineStatistics = async (coordinates) => {
        return new Promise((resolve, reject) => {
            let { user, orthoImageData } = this.state
            API.getLineStatistics(orthoImageData.task_id, this.state.container, this.state.st, coordinates).then((lineStats) => {
                resolve(lineStats)
            }).catch((err) => {
                console.log(err);
                resolve({})
            })
        })

    }

    getAllDNReport = () => {
        let task_id = this.state.orthoImageData.task_id
        let data = []
        API.getMeasurements(task_id).then(async (reportData) => {
            data = reportData.measurements
            let polygonData = []
            let volumePolygonData = []
            let lineData = []
            let circleData = []
            let markerData = []
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    let report = data[i]
                    console.log(report);
                    if (report.type == "Polygon") {
                        const threedArea = await get3dPolygonArea(report.coordinates, 'm', false)
                        const threedPerimeter = await getDistanceIn3d(report.coordinates)
                        polygonData.push({ ...report, threedArea, threedPerimeter })
                        if (report.volume) {
                            volumePolygonData.push(report)
                        }
                    } else if (report.type == "Polyline") {
                        if (this.state.DSMAvailable)
                            report.stat = await this.getPolylineStatistics(report.coordinates)
                        report.threedLength = await getDistanceIn3d(report.coordinates, "m", false)
                        console.log("Polyline...");
                        lineData.push(report)
                    } else if (report.type == "Circle") {
                        circleData.push(report)
                    } else if (report.type == "Marker") {
                        // if (this.state.DSMAvailable)
                        markerData.push(report)
                    }
                }
                this.setState((state) => ({
                    ...state,
                    linesData: lineData,
                    polygonData: polygonData,
                    circleData: circleData,
                    markerData: markerData,
                    volumePolygonData: volumePolygonData,
                    reportData: data,
                }))
            }
            this.setState((state) => ({
                ...state,
                reportData: data,
                loading: false,
            }))
        }).catch((e) => {
            console.log(data);
            this.setState((state) => ({
                ...state,
                reportData: data,
                loading: false,
            }))
        })
    }

    changeCompanyLogo = (event) => {
        // let companyLogo = event.target.value
        let targetFilesArr = [];
        targetFilesArr = Array.from(event.target.files);
        let companyLogo = targetFilesArr[0]
        this.setState((state) => ({
            ...state,
            company_logo1: companyLogo,
        }), () => {
            this.uploadCompanLogo()
        })
    }
    uploadCompanLogo = () => {
        const data = new FormData()
        data.append("file", this.state.company_logo1)
        API.uploadCompanyLogo(this.state.reportheader.id, data).then((uploadlogo) => {
            console.log(uploadlogo.status)
            if (uploadlogo.status == 200) {
                this.setState((state) => ({
                    ...state,
                    companyLogo1: undefined,
                }), () => {
                    this.getReportHeader()
                })
            } else {
                console.log('error while uploading logo')
            }
        })
    }

    updateReportHeader = () => {
        API.updateReportHeader(this.state.reportTitle, this.state.reportSubTitle, this.state.company_name, this.state.reportheader.id).then((data) => {
            console.log(data);
            if (data.status == 200) {
                this.setState((state) => ({
                    ...state,
                    editHeader: false
                }))
                this.getReportHeader();
            } else {
                this.getReportHeader();
            }
        })
    }

    getReportHeader = () => {
        let task_id = this.state.orthoImageData.task_id
        let report_type = 'measurement'
        API.getReportHeader(task_id, report_type).then((data) => {
            if (data.data == null) {
                console.log("call add report function", data.data)
                this.addReportHeader();
            } else {
                console.log("get report header data", data.data)
                this.setState((state) => ({
                    ...state,
                    reportheader: data.data,
                    reportTitle: data.data.title,
                    reportSubTitle: data.data.subtitle,
                    company_logo: data.data.company_logo ? `${data.data.company_logo}?${this.state.st}&t${new Date().getTime()}` : this.state.avatar_url ? `${this.state.avatar_url}?${this.state.st}&t${new Date().getTime()}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`,
                    company_name: data.data.name,
                }))
            }
        }, (e) => {
            console.log(e);
        })
    }

    addReportHeader = () => {
        let task_id = this.state.orthoImageData.task_id
        let report_type = 'measurement'
        let title = this.state.orthoImageData.task_name
        let subtitle = this.state.plan.plan_name
        let company_logo = null
        // this.state.avatar_url ? `${this.state.avatar_url}?${this.state.st}&t${new Date().getTime()}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`
        API.addReportHeader(task_id, report_type, title, company_logo, subtitle).then((data) => {
            if (data.status == 201) {
                this.getReportHeader();
            } else {
                this.setState((state) => ({
                    ...state,
                    reportTitle: this.state.orthoImageData.task_name,
                    reportSubTitle: this.state.plan.plan_name,
                    company_logo: this.state.avatar_url ? `${this.state.avatar_url}?${this.state.st}&t${new Date().getTime()}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`,
                    company_name: `${this.state.user.first_name} ${this.state.user.last_name}`,
                }))
            }
        })
    }

    isUserOwnerOfCollection = () => {
        const { collection_id } = this.state.orthoImageData
        const { id } = this.state.project
        API.isUserProjectOwner(id, collection_id)
            .then((result) => {
                this.setState({
                    isOwner: result.isShared ? false : true,
                    isAllowToDownload: result.access_level == "DOWNLOAD" ? true : false,
                    sharedBy: result.sharedBy
                })
                console.log(result)
            })
            .catch((err) => {
                window.alert("Something Went Wrong")
            })
    }

    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }

    componentWillMount() {
        document.title = "DroneNaksha Report";
        getFeaturesPermissions([DRONENAKSHA_FEATURES.REPORTS]).then(permissions => {
            this.setState({
                permissions,
                st: permissions?.st,
                container: permissions?.container,
                avatar_url: `${BLOB_URL}/${permissions?.container}/profileImage/${(permissions?.container?.split("user-"))[1]}.jpeg`
            }, () => {
                addUserAnalytic(this.props.location.pathname)
                    .then(id => {
                        console.log("ID : ", id)
                        this.setState((state) => ({
                            outId: id
                        }))
                    })

                if (this.props.location.state) {
                    let { user, type, orthoImageData, task, plan, project, DSMAvailable } = this.props.location.state;
                    this.setState((state) => ({
                        ...state,
                        user,
                        type,
                        task,
                        orthoImageData,
                        project,
                        plan,
                        DSMAvailable
                    }), () => {
                        // this.addDNReport()
                        this.isUserOwnerOfCollection()
                        this.getReportHeader()
                        this.getAllDNReport()
                    })
                } else {
                    API.getUserType().then(
                        ([blah, type, user]) => {
                            this.setState(
                                (state) => ({
                                    ...state,
                                    user,
                                    type,
                                })
                            );
                        },
                        (e) => {
                            window.location.replace("/login");
                        }
                    );
                }
            })
        })
            .catch(e => window.location.replace("/"))
    }
    render() {
        let { user, type, task, orthoUrl, demUrl, dtmUrl } = this.state;
        const handleClick = (event) => {
            document.getElementById("hiddenFileInput").click();
        }; return (
            <>
                <div className="top-bar" style={{ position: 'fixed', width: '100%' }}>
                    <div className="breadCrumbs">
                        <BreadCrumbs data={[
                            {
                                displayName: "projects",
                                pathname: "/dronenaksha/projects",
                                state: {
                                    type,
                                    user,
                                },
                                style: {
                                    color: "black",
                                    fontSize: "12px"
                                }
                            },
                            {
                                displayName: this.props.location.state.project.project_name,

                                pathname: "/dronenaksha/project",
                                state: {
                                    type,
                                    user,
                                    project: this.props.location.state.project,
                                },
                                style: {
                                    color: "black",
                                    fontSize: "12px"
                                }

                            },
                            {
                                displayName: this.props.location.state.plan.plan_name,

                                pathname: "/dronenaksha/plan",
                                state: {
                                    type,
                                    user,
                                    plan: this.props.location.state.plan,
                                    project: this.props.location.state.project
                                },
                                style: {
                                    color: "black",
                                    fontSize: "12px"
                                }
                            },
                            {
                                displayName: this.props.location.state.orthoImageData.task_name
                            }
                        ]} />
                        {this.state.sharedBy == null || !(this.state.sharedBy) ? <></> : <span className='dn-shared-by'>- Shared by {this.state.sharedBy.first_name} {this.state.sharedBy.last_name}</span>}
                    </div>
                </div>
                {this.state.editHeader || !this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].DOWNLOAD ? <button style={{ position: 'fixed', opacity: '0.5', top: '80px', right: '15px', cursor: "default" }} className="add-user-form-submit-button">
                    Download
                </button> : <button style={{ position: 'fixed', top: '80px', right: '15px' }} className="add-user-form-submit-button" onClick={window.print}>
                    Download
                </button>}
                {this.state.loading ? <div style={{ height: "100vh", width: "100vw", display: "flex", textAlign: "center" }}>
                    <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                </div> :
                    <div style={{ display: 'flex', margin: '60px 15px', justifyContent: 'center' }}>
                        <div style={{ width: '100%' }}>
                            <div
                                id="pageprint1"
                                style={{
                                    padding: "20px 30px 20px 30px",
                                    width: "945px",
                                    margin: 'auto',
                                    boxShadow: "0px 6px 18px rgba(0, 0, 0, 18%)",
                                }}
                            >
                                <div>
                                    <input
                                        name="company_logo"
                                        id="hiddenFileInput"
                                        type="file"
                                        className=""
                                        required
                                        onChange={this.changeCompanyLogo}
                                        accept='image/*'
                                        // multiple
                                        style={{ display: "none" }}
                                    />
                                    <div style={{ width: '885px', display: 'flex', justifyContent: 'space-between', marginTop: '20px', }}>
                                        <div style={{}}>
                                            {this.state.titleEdit ? <div style={{ height: '47px' }}>
                                                {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Title</label><br /> */}
                                                <input maxLength={AppConstants.IMAGESET_NAME_LIMIT} type="text" value={this.state.reportTitle} name='reportTitle'
                                                    className="edit-report-header-text" style={{}} onChange={this.handleChange} />
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                                                    this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            titleEdit: false,
                                                            editHeader: false,
                                                        }), () => {
                                                            this.updateReportHeader()
                                                        })
                                                }} />
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                                    this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            titleEdit: false,
                                                            editHeader: false,
                                                        }), () => {
                                                            this.getReportHeader()
                                                        })
                                                }} />
                                            </div> : <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>
                                                <div style={{ fontSize: '30px', fontWight: '500', maxWidth: "610px", overflow: "hidden", wordWrap: "break-word", lineHeight: 0.8 }}>{this.state.reportTitle}</div>
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", cursor: this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? 'pointer' : "default", opacity: this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? "1" : "0.2", display: this.state.isOwner ? undefined : "none" }} src={edit} onClick={() => {
                                                    this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT && this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            titleEdit: true,
                                                            editHeader: true
                                                        }))
                                                }} />
                                            </div>
                                            }

                                            {this.state.subTitleEdit ? <div style={{ display: 'flex', alignItems: 'center', height: '47px', marginTop: '10px' }}>
                                                {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Sub Title</label><br /> */}
                                                <input maxLength={'20'} type="text" value={this.state.reportSubTitle} name='reportSubTitle'
                                                    className="edit-report-header-text" style={{}} onChange={this.handleChange} />
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                                                    this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            subTitleEdit: false,
                                                            editHeader: false,
                                                        }), () => {
                                                            this.updateReportHeader()
                                                        })
                                                }} />
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                                    this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            subTitleEdit: false,
                                                            editHeader: false,
                                                        }), () => {
                                                            this.getReportHeader()
                                                        })
                                                }} />
                                            </div> : <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>
                                                <div style={{ fontSize: '20px', fontWight: '500', maxWidth: "580px", overflow: "hidden", wordWrap: "break-word", lineHeight: 1 }}>{this.state.reportSubTitle}</div>
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", cursor: this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? 'pointer' : "default", opacity: this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? "1" : "0.2", display: this.state.isOwner ? undefined : "none" }} src={edit} onClick={() => {
                                                    this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT && this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            subTitleEdit: true,
                                                            editHeader: true,
                                                        }))
                                                }} />
                                            </div>}
                                        </div>

                                        <div style={{}}>
                                            <div style={{ textAlign: 'end', }}>
                                                <img id="reportToolImage" className="reportToolImage" style={{ position: 'absolute', height: "15px", width: "15px", cursor: this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? 'pointer' : "default", opacity: this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? "1" : "0.2", display: this.state.isOwner ? undefined : "none" }} src={edit} onClick={this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT && handleClick} />
                                                <img style={{ maxHeight: "80px", minHeight: "50px", minWidth: "70px", maxWidth: "100px", borderRadius: '10px', cursor: 'pointer' }} src={this.state.company_logo} onError={(e) => { e.target.onerror = null; e.target.src = `/images/${this.state.user.first_name[0].toLowerCase()}.PNG` }}/>
                                            </div>

                                            {this.state.companyNameEdit ? <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>
                                                {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Company Name</label><br /> */}


                                                <input maxLength={'20'} style={{ width: '200px' }} type="text" value={this.state.company_name} name='company_name'
                                                    className="edit-report-header-text" onChange={this.handleChange} />
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                                                    this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            companyNameEdit: false,
                                                            editHeader: false
                                                        }), () => {
                                                            this.updateReportHeader()
                                                        })
                                                }} />
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                                    this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            companyNameEdit: false,
                                                            editHeader: false,
                                                        }), () => {
                                                            this.getReportHeader()
                                                        })
                                                }} />
                                            </div> :
                                                <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>

                                                    <div style={{ fontSize: '20px' }}>{this.state.company_name}</div>
                                                    <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", cursor: this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? 'pointer' : "default", opacity: this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? "1" : "0.2", display: this.state.isOwner ? undefined : "none" }} src={edit} onClick={() => {
                                                        this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT && this.setState(
                                                            (state) => ({
                                                                ...state,
                                                                companyNameEdit: true,
                                                                editHeader: true,
                                                            }))
                                                    }} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <hr style={{ width: '100%', margin: '10px 0px 20px 0px', height: '1px', padding: '0px' }} />
                                </div>
                                <div>
                                    {this.state.reportData.length > 0 ?
                                        <div className="content-text" style={{ fontSize: "20px", fontWeight: "400" }}>Measurements : </div>
                                        : <div className="content-text" style={{ fontSize: "20px", fontWeight: "400", width: "100%", display: "flex", justifyContent: "center" }}> No measurements available </div>
                                    }
                                    {this.state.linesData && this.state.linesData.length > 0 ?
                                        <div>
                                            <div className="content-text" style={{ fontSize: "16px", margin: "50px 5px 15px 5px" }}>{this.state.DSMAvailable ? 'Lines (Distance, Elevation, Slope)' : 'Lines (Distance)'}</div>
                                            <table style={{ textAlign: "center" }} className="customers-table">
                                                {this.state.DSMAvailable ? (
                                                    <>
                                                        <col style={{ width: '8%' }} />
                                                        <col style={{ width: '8%' }} />
                                                        <col style={{ width: '8%' }} />
                                                        <col style={{ width: '8%' }} />
                                                        <col style={{ width: '8%' }} />
                                                        <col style={{ width: '8%' }} />
                                                        <col style={{ width: '8%' }} />
                                                        <col style={{ width: '8%' }} />
                                                        <col style={{ width: '8%' }} />
                                                        <col style={{ width: '8%' }} />
                                                        <col style={{ width: '8%' }} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <col style={{ width: '25%' }} />
                                                        <col style={{ width: '25%' }} />
                                                        <col style={{ width: '25%' }} />
                                                        <col style={{ width: '25%' }} />
                                                    </>
                                                )}
                                                <tr className="customers-table-header-row" style={{ position: 'inherit', }}>
                                                    <th style={{ textAlign: "left", padding: "0 15PX" }}>Name</th>
                                                    {!this.state.DSMAvailable ? <th>Remark</th> : <></>}
                                                    <th style={{ textAlign: "left", padding: "0 15PX" }}>Location</th>
                                                    <th style={{ paddingLeft: this.state.DSMAvailable && "35px " }}>2D Length(m)</th>
                                                    {this.state.DSMAvailable ? <>
                                                        <th style={{ textAlign: "center", padding: "0 15PX" }}>3D Length(m)</th>
                                                        <th style={{ textAlign: "center", padding: "0 15PX" }}>Min ele(m)</th>
                                                        <th style={{ textAlign: "center", padding: "0 15PX" }}>Max ele(m)</th>
                                                        <th style={{ textAlign: "center", padding: "0 15PX" }}>Start ele(m)</th>
                                                        <th style={{ textAlign: "center", padding: "0 15PX" }}>End ele(m)</th>
                                                        <th style={{ textAlign: "center", padding: "0 15PX" }}>Slope</th>
                                                        <th style={{ textAlign: "center", padding: "0 15PX" }}>Min slope</th>
                                                        <th style={{ textAlign: "center", padding: "0 15PX" }}>Max slope</th>
                                                    </> : <></>}
                                                </tr>
                                                {this.state.linesData.map((report, key) => {
                                                    return <>
                                                        <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                            <td style={{ textAlign: "left", width: "150px", fontSize: '11px' }}><p style={{ width: "100px", wordWrap: "break-word" }}>{report.name}</p></td>
                                                            {!this.state.DSMAvailable ? <td>
                                                                {/* {report.description ? report.description : '-'} */}
                                                                <p style={{ textAlign: "center", width: "195px", wordWrap: "break-word" }}>{report.description ? extractMessageAfterMention(report.description) : "-"}</p>
                                                            </td> : <></>}
                                                            <td style={{ fontSize: '11px', padding: '0px 5px', wordBreak: "break-word", textAlign: this.state.DSMAvailable ? "" : "left" }}>{getCenterOfLine(report.coordinates)}</td>
                                                            <td style={{ fontSize: '11px', padding: '0px 5px' }}>{getDistance(report.coordinates, "m", false, report.coordinates[0]?.x)}</td>
                                                            {this.state.DSMAvailable ? <>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}>{isNaN(report.threedLength) ? "-" : report.threedLength}</td>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}>{report.stat.minElevation ? report.stat.minElevation.toFixed(2) : "-"}</td>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}>{report.stat.maxElevation ? report.stat.maxElevation.toFixed(2) : "-"}</td>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}>{report.stat.startingElevation ? report.stat.startingElevation.toFixed(2) : "-"}</td>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}>{report.stat.endingElevation ? report.stat.endingElevation.toFixed(2) : "-"}</td>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}>{report.stat.slope ? <>{report.stat.slope.toFixed(2)}<sup>o</sup></> : "-"}</td>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}>{report.stat.minSlope ? <>{report.stat.minSlope.toFixed(2)}<sup>o</sup></> : "-"}</td>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}>{report.stat.maxSlope ? <>{report.stat.maxSlope.toFixed(2)}<sup>o</sup></> : "-"}</td>
                                                            </> : <></>}
                                                        </tr>
                                                    </>
                                                })}
                                            </table>
                                        </div>
                                        : ""}
                                    {this.state.polygonData && this.state.polygonData.length > 0 ?
                                        <>
                                            <div>
                                                <div className="content-text" style={{ fontSize: "16px", margin: "50px 5px 15px 5px" }}>{"Polygons (Area & Length)"}</div>
                                                <table style={{ textAlign: "center" }} className="customers-table">
                                                    <col style={{ width: this.props.location.state.DSMAvailable ? "20%" : "25%" }} />
                                                    <col style={{ width: "30%" }} />
                                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "25%" }} />
                                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "20%" }} />
                                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "20%" }} />
                                                    {this.props.location.state.DSMAvailable ? <>
                                                        <col style={{ width: "13%" }} />
                                                        <col style={{ width: "13%" }} />
                                                    </> : <></>}
                                                    <tr className="customers-table-header-row" style={{ position: 'inherit' }}>
                                                        <th style={{ textAlign: "left" }} >Name</th>
                                                        <th>Remark</th>
                                                        <th>Location</th>
                                                        <th>2D Area (m<sup>2</sup>)</th>
                                                        <th>2D Perimeter(m)</th>
                                                        {/* {this.props.location.state.DSMAvailable ? <> */}
                                                        <th>3D Area (m<sup>2</sup>)</th>
                                                        <th>3D Perimeter(m)</th>
                                                        {/* </> : <></>} */}

                                                    </tr>
                                                    {this.state.polygonData.map((report, key) => {
                                                        return <>
                                                            <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                                <td style={{ textAlign: "left", fontSize: '11px', }}><p style={{ width: "115px", wordWrap: "break-word" }}>{report.name}</p></td>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}><p style={{ textAlign: "center", width: "195px", wordWrap: "break-word" }}>{report.description ? extractMessageAfterMention(report.description) : "-"}</p></td>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}>{getCenterOfPolygon(report.coordinates)}</td>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}>{getArea(report.coordinates, "m", false, report.coordinates[0].lat && report.coordinates[0].lng ? false : true)}</td>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}>{getDistance(report.coordinates, "m", false, report.coordinates[0].lat && report.coordinates[0].lng ? false : true)}</td>
                                                                {/* {this.props.location.state.DSMAvailable ? <> */}
                                                                <td>{isNaN(report.threedArea) ? "-" : report.threedArea}</td>
                                                                <td>{isNaN(report.threedPerimeter) ? "-" : report.threedPerimeter}</td>
                                                                {/* </> : <></>} */}
                                                            </tr>
                                                        </>
                                                    })}
                                                </table>
                                            </div>

                                        </> : ""}

                                    {this.state.volumePolygonData && this.state.volumePolygonData.length > 0 ?
                                        <>
                                            <div>
                                                <div className="content-text" style={{ fontSize: "16px", margin: "50px 5px 15px 5px" }}>{"Polygons (Volumetric calculations)"}</div>
                                                <table style={{ textAlign: "center" }} className="customers-table">
                                                    <col style={{ width: "20%" }} />
                                                    <col style={{ width: "20%" }} />
                                                    <col style={{ width: "20%" }} />
                                                    <col style={{ width: "20%" }} />
                                                    <col style={{ width: "20%" }} />


                                                    <tr className="customers-table-header-row" style={{ position: 'inherit' }}>
                                                        <th style={{ textAlign: "left" }} >Name</th>
                                                        <th>Cut volume (m<sup>3</sup>)</th>
                                                        <th>Fill volume (m<sup>3</sup>)</th>
                                                        <th>Volume (m<sup>3</sup>)</th>
                                                        <th>Method</th>
                                                    </tr>
                                                    {this.state.volumePolygonData.map((report, key) => {
                                                        return <>
                                                            {report.cut_volume ? <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                                <td style={{ textAlign: "left", fontSize: '11px', }}> <p style={{ width: "115px", wordWrap: "break-word" }}>{report.name}</p> </td>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}>{(Number(report.cut_volume)).toFixed(2)}</td>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}>{(Number(report.fill_volume)).toFixed(2)}</td>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}>{(Number(report.volume)).toFixed(2)}</td>
                                                                <td style={{ fontSize: '11px', padding: '0px 10px' }}>{(report.calc_method).toUpperCase()}</td>
                                                            </tr> : <></>}
                                                        </>
                                                    })}
                                                </table>
                                            </div>

                                        </> : ""}

                                    {this.state.markerData && this.state.markerData.length > 0 ?
                                        <div>
                                            <div className="content-text" style={{ fontSize: "16px", margin: "50px 5px 15px 5px" }}>{"Markers (Location & Elevation)"}</div>
                                            <table style={{ textAlign: "center" }} className="customers-table">
                                                <col style={{ width: "20%" }} />
                                                <col style={{ width: "25%" }} />
                                                <col style={{ width: "40%" }} />
                                                <col style={{ width: "15%" }} />
                                                <tr className="customers-table-header-row" style={{ position: 'inherit' }}>
                                                    <th style={{ textAlign: "left" }}>Name</th>
                                                    <th>Remark</th>
                                                    <th>Location</th>
                                                    <th>Elevation (m)</th>
                                                </tr>
                                                {this.state.markerData.map((report, key) => {
                                                    return <>
                                                        <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                            <td style={{ textAlign: "left", fontSize: '11px', }}><p style={{ width: "115px", wordWrap: "break-word" }}>{report.name}</p></td>
                                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}><p style={{ textAlign: "center", width: "335px", wordWrap: "break-word" }}>{report.description ? extractMessageAfterMention(report.description) : "-"}</p></td>
                                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}>
                                                                {report.coordinates.lat ? `{ ${report.coordinates.lat.toFixed(5)} ,${report.coordinates.lng.toFixed(5)} }` : "-"}
                                                            </td>
                                                            {report.coordinates && report.coordinates.elevation ? <td style={{ fontSize: '11px', padding: '0px 10px' }}>{report.coordinates.elevation.toFixed(2)}</td> : <td>-</td>}
                                                        </tr>
                                                    </>
                                                })}
                                            </table>
                                        </div> : ""}

                                    {/* {this.state.circleData && this.state.circleData.length > 0 ?
                                        <div>
                                            <div className="content-text" style={{ fontSize: "16px", margin: "50px 5px 15px 5px" }}>{"Circles (Area & Perimeter)"}</div>
                                            <table style={{ textAlign: "center" }} className="customers-table">
                                                <col style={{ width: "25%" }} />
                                                <col style={{ width: "35%" }} />
                                                <col style={{ width: "20%" }} />
                                                <col style={{ width: "15%" }} />

                                                <tr className="customers-table-header-row" style={{ position: 'inherit' }}>
                                                    <th>Name</th>
                                                    <th>Center</th>
                                                    <th>Area (m<sup>2</sup>)</th>
                                                    <th>Perimeter</th>
                                                </tr>
                                                {this.state.circleData.map((report, key) => {
                                                    return <>
                                                        <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                            <td>{report.name}</td>
                                                            <td>{'-'}</td>
                                                            <td>{'-'}</td>
                                                            <td>{"-"}</td>
                                                        </tr>
                                                    </>
                                                })}
                                            </table>
                                        </div> : ""} */}

                                </div>
                            </div>
                        </div>
                    </div>}
            </>
        );
    }
}

export default measurementReport;