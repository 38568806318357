
import React from 'react'
import Stepper from 'react-stepper-horizontal'
import { AnimatedModal } from './AnimatedModal'
import closeModal from '../icons/crossIcon.svg'
/**
To use this popup you have to pass following props
  1) onRequestClose as function
  2) if You want to give width then pass width otherwise it take 41% by default
  3) if You want to give height then pass width otherwise it take 50% by default

*/
export const StepperModalNew = (props) => {

    return (
        <>

            {/*------------------------------ Stepper Modal-----------------------------*/}
            <AnimatedModal
                height={props.height}
                width={props.width}
                onRequestClose={props.closeStepper}
                padding={"3px"}
                isOpen={props.stepperModal}
            >
                <div style={{ background: "#FCFCFC", height: "100%", width: "100%", }}>
                    <div style={{  height: "160px" }}>
                        <div style={{
                            display: "flex",
                            padding: "25px 30px 15px", 
                            alignItems: "center",
                            backgroundColor: "#FCFCFC",
                            justifyContent: "space-between"
                        }}>
                            <span style={{fontSize:"20px",fontFamily:"Lato, sans-serif !important",color:"#081425",fontWeight:400}}>{props.heading}</span>
                          
                                <img src={closeModal} className={"hoverPointer"} style={{ position: "absolute", right: "5%", top: "6%", height: "15px" }} onClick={props.closeStepper}></img>
                            
                        </div>
                        <div className="services-content-title" style={{ height: "100%", paddingTop: "0.2%" }}>
                            <div style={{ marginTop: "-1%", fontWeight: "500" }}>
                                <Stepper defaultTitleColor={"#68717E"} titleTop={props.styles.labelMargin} titleFontWeight={"500"} titleFontSize={14} circleFontSize={2} size={50} steps={props.steps} activeStep={props.currentStep} defaultColor="#868F9C" activeColor="#3988F9" completeColor="#3988F9" activeTitleColor="#68717E" completeTitleColor="#68717E" completeBarColor="#3988F9" />
                            </div>
                        </div>
                    </div>
                    {props.children}
                </div>
            </AnimatedModal>
            {/*-------------------------------------------------------------------------------------------- */}
        </>
    )
}
