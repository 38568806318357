import React, { Component, useEffect } from 'react';
import SideBar from '../../../SideBar';
import Loader from '../../../icons/loader.svg';

// topBar = jsxElement to render in topBar
// sidebarConfigurations = render in sidebar
// left side contains render,style(jsx element which is render in left side and style is provide styling to it.)
// same to right side also.
const ScreenDivider = (props) => {
    return <div className="wrapper">
        <SideBar config={props.sidebarConfigurations} />
        <div className="right-content">
            <div className="top-bar">
                {props.topBar}
            </div>
            {props.steps ? <div style={{ width: "100%", height: `calc(100% - 40px)`, display: 'flex'}}>
                <div style={{width: props.leftSide.style && props.leftSide.style.width ? props.leftSide.style.width : "20%", padding: props.leftSide.style && props.leftSide.style.padding ? props.leftSide.style.padding : "10px 20px", ...(props.leftSide.style || {}) }}>
                    {props.leftSide.render}
                </div>
                <div style={{ width: props.rightSide.style && props.rightSide.style.width ? props.rightSide.style.width : "calc(100vw - 315px)", padding: props.rightSide.style && props.rightSide.style.padding ? props.rightSide.style.padding : "10px 0px 0px 10px", ...(props.rightSide.style || {}) }}>
                    {props.rightSide.render}
                </div>
            </div> :
                <div style={{ width: "100%", height: `calc(100% - 40px)`, justifyContent: 'center', alignItems: 'center', display: 'flex', padding: "0 0 0 5px" }}>
                    <img src={Loader} style={{ height: '20vh' }} />
                </div>}
        </div>
    </div>

}

export default ScreenDivider

