import React, { Component } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom";
import { Profile } from "./Profile"
import Cookies from "universal-cookie";
import "./App.css";
import Services from "./Services";
import F0F from "./F0F";
import Logout from "./Logout";
import MyProfile from "./Profile/MyProfile";
import AeroMeghPlans from './AeroMeghPlans'
import * as API from "./api";
import AdminUsers from "./AdminUsers";
import AdminEditUsers from "./AdminEditUsers";
import AdminUserDetails from "./AdminUserDetails";
import AdminUserTrackingLogs from './AdminUserTrackingLogs'
import AdminUserScreenLog from './AdminUserScreenLog'
import Invoice from './Invoice';
import AdminAssignService from "./AdminAssignService";
import AdminUsersStatistics from './AdminUsersStatistics';
import AdminAddChangeSubscription from './AdminAddChangeSubscription'
import Invoices from './Invoices'
import editUser from "./Profile/editUser";
import ManageSubscription from "./ManageSubscription";
//AeroCapture Routes Start
import AeroCaptureDashborad from './AeroCapture/Components/aeroCaptuerDashboard';
import TrainingTask from './AeroCapture/Components/TrainingTask.js'
import DetectionTask from './AeroCapture/Components/DetectionTask.js'
import AnnotationReportTask from './AeroCapture/Components/AnnotationReportTask.js'
import AeroCaptureAllProjects from "./AeroCapture/Components/aeroCaptureAllProjects";
import AeroCaptureProject from "./AeroCapture/Components/aeroCaptureProject";
import AeroCapturePdfDownload from "./AeroCapture/Components/aeroCaptuerPdfDownload"
import AeroCaptureImageCollectionImages from "./AeroCapture/Components/aerocaptureImageCollectionImages";
import PicStorkUserDetectors from "./AeroCapture/Components/PicStorkUserDetectors";
import PicStorkDefectReport from "./AeroCapture/Components/PicStorkDefectReport";
import PicStorkThermalReport from "./AeroCapture/Components/PicStorkThermalReport";
import PicStorkAllTasks from "./AeroCapture/Components/PicStorkAllTasks";
import LoaderComponent from "./ReusableComponents/LoaderComponent";
// AeroCapture Routes End

//AeroFarm import starts
import AeroFarmImages from "./AeroFarm/Components/aerocaptureImageCollectionImages";
import viewMap from "./AeroFarm/Components/RMAssignDrone";
import AeroFarmDashborad from './AeroFarm/Components/aeroCaptuerDashboard';
import AeroFarmPlan from "./AeroFarm/Components/aeroCapturePlan";
import AeroFarmProject from "./AeroFarm/Components/aeroCaptureProject";
import AeroFarmAllProjects from "./AeroFarm/Components/aeroCaptureAllProjects";
import AeroFarmImageCollections from "./AeroFarm/Components/aeroCaptureImageCollections";
import AeroFarmAllPlans from "./AeroFarm/Components/aeroCaptureAllPlans";
import aeroFarmReport from "./AeroFarm/Components/aeroFarmReport";
import aerofarmGCP from './AeroFarm/Components/aerofarmGCP';
import AeroFarmAllReports from "./AeroFarm/Components/aeroCaptureAllReports";
import DroneNakshaAllTasks from "./AeroFarm/Components/aeroCaptureAllTasks";
import aeroFarmPlantHealthReport from "./AeroFarm/Components/aeroFarmPlantHealthReport";
import aeroFarmMeasurementReport from "./AeroFarm/Components/measurementReport";
import AcceptInvite from './AeroFarm/Components/AcceptInvite'
import MyMaps from './AeroFarm/Components/myMaps'
import ImagesetsOnMap from './AeroFarm/Components/ImagesetsOnMap'
import compareMeasurementReport from "./AeroFarm/Components/compareMeasurementReport";

//AeroFarm import ends
import AeroGCSEnterpriseTeam from "./AeroGCSEnterprise/Components/AeroGCSEnterpriseTeam";
import ProfileSettings from './Profile/ProfileSettings'
import allSubscription from './subscription/allSubscription'
import subscriptiontems from './subscription/subscriptiontems'
import newSubscription from './subscription/newSubscription'
import TaskAndVmList from './TaskAndVmList'
import SupportEmails from './SupportEmails'
import TaskLogs from "./TaskLogs";
import PublicDetectors from "./PublicDetectors"

//userlogin
import userLogin from './userAuth/userLogin'
import ForgotPassword from './userAuth/ForgotPassword'
import ResetPasswordSuccessful from './userAuth/ResetPasswordSuccessful'
import accountActivetionmsg from './userAuth/accountActivetionmsg'
import userSignup from "./userAuth/UserSignUp"

//usage statistics
import UsageStatistics from "./UsageStatistics"

//Analytics Tool
import AnalyticUsers from "./AnalyticTool/Components/Users"
import AnalyticUserDetails from "./AnalyticTool/Components/UserDetails"
import AnalyticUserLogs from "./AnalyticTool/Components/UserTrackingLogs"
import AnalyticScreenLogs from "./AnalyticTool/Components/UserScreenLogs"
import UserStatistics from "./AnalyticTool/Components/UserStatistics"
import CustomStatistics from "./AnalyticTool/Components/CustomStatistics";

import { OnIdleHandlerWrapper } from "./helperComponents/OnIdleHandlerWrapper"

import Planner from "./Teams/Planner";
import ProjectComparison from "./ProjectComparison";
import BuyCredit from "./subscription/Reusable/BuyCredit";
import DiscountCoupons from "./subscription/DiscountCoupons";
import SideBarComponent from "./ReusableComponents/SideBarComponent.js";

class App extends Component {
  state = {
    done: false,
    teamsLoaded: false,
    stateRoutes: [
      {
        path: "/teams",
        componentToMount: AeroGCSEnterpriseTeam,
      },
      {
        path: "/planner",
        componentToMount: Planner,
      },
      {
        path: "/progress-dashboard",
        componentToMount: ProjectComparison,
      },
      {
        path: "/logout",
        componentToMount: Logout,
      },
      {
        path: "/my_profile",
        componentToMount: MyProfile,
      },
      {
        path: "/manage_subscription",
        componentToMount: ManageSubscription,
      },
      {
        path: "/edit_profile",
        componentToMount: editUser,
      },
      {
        path: "/buy-credit",
        componentToMount: BuyCredit,
      },
      {
        path: "/aeromegh_plans",
        componentToMount: AeroMeghPlans,
      },
      {
        path: "/invoice/:invoiceID",
        componentToMount: Invoice,
      },
      {
        path: "/invoices",
        componentToMount: Invoices,
      },
      {
        path: "/usage_statistics",
        componentToMount: UsageStatistics,
      },
    ],
  };
  isUserLoggedIn = () => {
    let cookie = new Cookies();
    let sessionCookie = cookie.get("session_IAMAero");
    return !(sessionCookie == "" ||
      !sessionCookie ||
      window.location.href.includes("/login") ||
      window.location.href.includes("/signup") ||
      window.location.href.includes("/forgotPassword") ||
      window.location.href.includes("/accountActivated") ||
      window.location.href.includes("/resetPasswordSuccessful") ||
      window.location.href.includes("/accept_Inv")
    )
  }
  componentWillMount() {

    let cookie = new Cookies();
    let sessionCookie = cookie.get("session_IAMAero");

    if (window.location.href.includes("/login") || window.location.href.includes("/signup") || window.location.href.includes("/forgotPassword") || window.location.href.includes("/accountActivated") || window.location.href.includes("/resetPasswordSuccessful")) {
      if (sessionCookie == "" || !sessionCookie) {
        let routes = this.state.stateRoutes;
        routes.push({ path: "/login", componentToMount: userLogin })
        routes.push({ path: "/signup", componentToMount: userSignup })
        routes.push({ path: "/forgotPassword", componentToMount: ForgotPassword })
        routes.push({ path: "/accountActivated", componentToMount: accountActivetionmsg })
        routes.push({ path: "/resetPasswordSuccessful", componentToMount: ResetPasswordSuccessful })

        this.setState((state) => ({
          ...state,
          done: true,
          hideProfile: true,
          stateRoutes: routes,
        }));
      } else {
        window.location.replace("/");
      }
    } else if (window.location.href.includes("/accept_Inv")) {
      let routes = this.state.stateRoutes;
      routes.push({ path: "/accept_Inv", componentToMount: AcceptInvite })

      this.setState((state) => ({
        ...state,
        done: true,
        hideProfile: true,
        stateRoutes: routes,
      }));
    } else if (sessionCookie == "") {
      window.location.replace("/login");
      return <div />;
    } else {
      API.getUserSubbedServices().then(
        (response) => {
          let services = response[0];
          this.setState((prev) => ({
            ...prev,
            services
          }), () => {
            let routes = this.state.stateRoutes;
            if (services.length) {
              return services.forEach((service, key) => {
                if (service.title == "Admin Tools") {
                  routes.push({
                    path: "/profile-settings",
                    componentToMount: ProfileSettings,
                  });
                  routes.push({
                    path: "/admin/users",
                    componentToMount: AdminUsers,
                  });
                  routes.push({
                    path: "/admin/taskLogs",
                    componentToMount: TaskLogs,
                  });
                  routes.push({
                    path: "/admin/tasks",
                    componentToMount: TaskAndVmList,
                  });
                  routes.push({
                    path: "/admin/supportEmails",
                    componentToMount: SupportEmails,
                  });
                  routes.push({
                    path: "/admin/allSubscription",
                    componentToMount: allSubscription,
                  });
                  routes.push({
                    path: "/admin/new_subscription",
                    componentToMount: newSubscription,
                  });
                  routes.push({
                    path: "/admin/discount_coupons",
                    componentToMount: DiscountCoupons,
                  });
                  routes.push({
                    path: "/admin/subscription_items",
                    componentToMount: subscriptiontems,
                  });
                  routes.push({
                    path: "/admin/userdetails",
                    componentToMount: AdminUserDetails,
                  });
                  routes.push({
                    path: "/admin/user_tracking_logs",
                    componentToMount: AdminUserTrackingLogs,
                  });
                  routes.push({
                    path: "/admin/user_screen_logs",
                    componentToMount: AdminUserScreenLog,
                  });
                  routes.push({
                    path: "/admin/edituser",
                    componentToMount: AdminEditUsers,
                  });
                  routes.push({
                    path: "/admin/assign_services",
                    componentToMount: AdminAssignService,
                  });
                  routes.push({
                    path: "/admin/user_manage_plan",
                    componentToMount: AdminAddChangeSubscription,
                  });
                  routes.push({
                    path: "/admin/users_stats",
                    componentToMount: AdminUsersStatistics,
                  });
                  routes.push({
                    path: "/admin/detectors",
                    componentToMount: PublicDetectors,
                  });
                }
                else if (service.title == 'Analytic Tool') {
                  routes.push({
                    path: "/analytictool/users",
                    componentToMount: AnalyticUsers
                  });
                  routes.push({
                    path: "/analytictool/userdetails",
                    componentToMount: AnalyticUserDetails
                  });
                  routes.push({
                    path: "/analytictool/user_tracking_logs",
                    componentToMount: AnalyticUserLogs
                  });
                  routes.push({
                    path: "/analytictool/user_screen_logs",
                    componentToMount: AnalyticScreenLogs
                  });
                  routes.push({
                    path: "/analytictool/user_statistics",
                    componentToMount: UserStatistics
                  });
                  routes.push({
                    path: "/analytictool/customStatistics",
                    componentToMount: CustomStatistics
                  });
                }
                else if (service.title == 'PicStork') {
                  routes.push({
                    path: "/picstork/trainingTask",
                    componentToMount: TrainingTask,
                  });
                  routes.push({
                    path: "/picstork/detectionTask",
                    componentToMount: DetectionTask,
                  });
                  routes.push({
                    path: "/picstork/annotationReportTask",
                    componentToMount: AnnotationReportTask,
                  });
                  routes.push({
                    path: "/picstork/dashboard",
                    componentToMount: AeroCaptureDashborad,
                  });
                  routes.push({
                    path: "/profile-settings",
                    componentToMount: ProfileSettings,
                  });
                  routes.push({
                    path: "/picstork/projects",
                    componentToMount: AeroCaptureAllProjects,
                  });
                  routes.push({
                    path: "/picstork/project",
                    componentToMount: AeroCaptureProject,
                  });
                  routes.push({
                    path: "/picstork/images",
                    componentToMount: AeroCaptureImageCollectionImages,
                  });
                  routes.push({
                    path: "/picstork/Pdf_Download",
                    componentToMount: AeroCapturePdfDownload,
                  });
                  routes.push({
                    path: "/picstork/userdetectors",
                    componentToMount: PicStorkUserDetectors,
                  });
                  routes.push({
                    path: "/picstork/thermal_report",
                    componentToMount: PicStorkThermalReport,
                  });
                  routes.push({
                    path: "/picstork/defect_report",
                    componentToMount: PicStorkDefectReport,
                  });
                  routes.push({
                    path: "/picstork/alltasks",
                    componentToMount: PicStorkAllTasks,
                  })

                }
                //AeroFarm starts
                else if (service.title == 'DroneNaksha') {
                  routes.push({
                    path: "/dronenaksha/dashboard",
                    componentToMount: AeroFarmDashborad,
                  });
                  routes.push({
                    path: "/dronenaksha/projects",
                    componentToMount: AeroFarmAllProjects,
                  });
                  routes.push({
                    path: "/dronenaksha/myMaps",
                    componentToMount: MyMaps,
                  });
                  routes.push({
                    path: "/dronenaksha/imagesets-on-map",
                    componentToMount: ImagesetsOnMap,
                  });
                  routes.push({
                    path: "/dronenaksha/project",
                    componentToMount: AeroFarmProject,
                  });
                  routes.push({
                    path: "/dronenaksha/view_plan",
                    componentToMount: viewMap,
                  });
                  routes.push({
                    path: "/dronenaksha/report",
                    componentToMount: aeroFarmReport,
                  });
                  routes.push({
                    path: "/dronenaksha/plantHealth_report",
                    componentToMount: aeroFarmPlantHealthReport,
                  });
                  routes.push({
                    path: "/dronenaksha/measurement_report",
                    componentToMount: aeroFarmMeasurementReport,
                  });
                  routes.push({
                    path: "/dronenaksha/compare_measurement_report",
                    componentToMount: compareMeasurementReport,
                  });
                  routes.push({
                    path: "/dronenaksha/image_collections",
                    componentToMount: AeroFarmImageCollections,
                  });
                  routes.push({
                    path: "/dronenaksha/images",
                    componentToMount: AeroFarmImages,
                  });
                  routes.push({
                    path: "/dronenaksha/create_GCP",
                    componentToMount: aerofarmGCP,
                  });
                  routes.push({
                    path: "/dronenaksha/plans",
                    componentToMount: AeroFarmAllPlans,
                  });
                  routes.push({
                    path: "/dronenaksha/plan",
                    componentToMount: AeroFarmPlan,
                  });
                  routes.push({
                    path: "/dronenaksha/tasks",
                    componentToMount: DroneNakshaAllTasks,
                  });
                  routes.push({
                    path: "/dronenaksha/allreports",
                    componentToMount: AeroFarmAllReports,
                  });

                }
                //AeroFarm Ends

                //usage statistics


                if (key == services.length - 1) {
                  this.setState((state) => ({
                    ...state,
                    done: true,
                    stateRoutes: routes,
                  }));
                }
              });
            } else {
              this.setState((state) => ({
                ...state,
                done: true,
                stateRoutes: routes,
              }));
            }
          })

        },
        (e) => {
          console.error("app.js", e);
          window.location.replace("/login");
        }
      );
    }
  }
  render() {
    let routes = this.state.stateRoutes;
    if (this.state.done) {
      return (
        <>
          <BrowserRouter>
            <OnIdleHandlerWrapper>
              {this.state.hideProfile ? <></> :
                <Profile teamsLoaded={() => this.setState({ teamsLoaded: true })} />}
              {<SideBarComponent />}
              {this.state.teamsLoaded || !this.isUserLoggedIn() ?
                <Switch>
                  <Route exact path="/" component={Services} />
                  {routes.map((route, key) => (
                    <Route exact path={route.path} component={route.componentToMount} />
                  ))}
                  <Route component={F0F} />
                </Switch>
                : <LoaderComponent height={'90vh'} />}
            </OnIdleHandlerWrapper>
          </BrowserRouter>
        </>
      );
    }
    else {
      return <LoaderComponent height={'90vh'} />
    }
  }
}

export default App;