import { tilesServer } from "../../AppConstants"

const exportPolygonTiff = (blobContainer, taskId, sas, polygonCoords, tiffType = "orthomosaic") => {
    return new Promise((resolve, reject) => {
       
        //----------------------------//
        const coords = polygonCoords.map(c => [c.lng, c.lat])
        window.open(`${tilesServer}/droneNaksha/downloadTiffInsidePolygon?coords=${JSON.stringify(coords)}&blobContainer=${blobContainer}&taskId=${taskId}&sas=${encodeURIComponent(sas)}&tiffType=${tiffType}`,"_blank")
        resolve()
        //----------------------------/

        // let done = false
        // const coords = polygonCoords.map(c => [c.lng, c.lat])
        // const data = JSON.stringify({ type: "downloadTiffInsidePolygon", blobContainer, taskId, sas, coords, tiffType })
        // const socket = new WebSocket(tilesServerSocket);

        // socket.onopen = (event) => {
        //     socket.send(JSON.stringify({ type: "downloadTiffInsidePolygon", blobContainer, taskId, sas, coords, tiffType }));
        // };

        // socket.onmessage = (event) => {
        //     const data = JSON.parse(event.data)
        //     if (data.file_path) {
        //         window.open(`${tilesServer}/droneNaksha/downloadTiffInsidePolygon?file_path=${data.file_path}`)
        //         done = true
        //         resolve()
        //     } else {
        //         console.error(data.error)
        //         reject()
        //     }
        //     socket.close()
        // };

        // socket.onclose = (event) => {
        //     if(!done) reject()
        // }

    })

}


export {
    exportPolygonTiff
}


