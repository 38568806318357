import React, { useEffect, useState } from 'react';
import ButtonWithIcon from './ButtonWithIcon';
import Dropdown from './Dropdown';
import NormalSelector from './NormalSelector';
import dropdownLogoBlack from '../assets/Icons/dropdownLogo.png';
import dropdownLogoWhite from '../assets/Icons/dropdownwhite.png';
import dropdownBlack from '../assets/Icons/DropDownBlack.png';

// list  : [{
//   name: name,
//   onClick: fn
// }]

const DropDownWithButton = ({
  padding,
  isBorder,
  blueDropDownIcon,
  listHeight,
  textAlign,
  margin,
  textFontWeight,
  textFontSize,
  listLeft,
  listWidth,
  list,
  isButton,
  top,
  left,
  width,
  height,
  selectedItem,
  isDisabled,
  maxHeight,
  textColor,
  fontSize,
  isBtnList,
  isBtnActive,
  icon,
  iconWidth
}) => {

  let [showDropDown, setShowDropDown] = useState(false);
  return (
    <>
      <div>
        {isButton ?
          <ButtonWithIcon
            fontSize={fontSize}
            isDisabled={isDisabled}
            text={selectedItem}
            textFontSize={textFontSize}
            icon={icon ? icon : (textColor === "white" ? dropdownLogoWhite : dropdownLogoBlack)}
            width={width}
            height={height}
            onClick={() => { if (!isDisabled) setShowDropDown(!showDropDown) }}
            isDropDownOpen={showDropDown}
            textColor={textColor}
            fontWeight={textFontWeight}
            isBtnActive={isBtnActive}
            iconWidth={iconWidth}
            padding={padding}
          />
          :
          <NormalSelector
            width={width}
            height={height}
            margin={margin}
            fontSize={fontSize}
            text={selectedItem}
            isBorder={isBorder}
            textFontSize={textFontSize}
            textFontWeight={textFontWeight}
            icon={textColor === "white" ? dropdownLogoWhite : dropdownBlack}
            onClick={() => { if (!isDisabled) setShowDropDown(!showDropDown) }}
            isDisabled={isDisabled}
            isDropDownOpen={showDropDown}
            textColor={textColor}
            blueDropDownIcon={blueDropDownIcon}
            padding={padding}
          />
        }
        <Dropdown
          textAlign={textAlign}
          show={showDropDown}
          isButton={isButton}
          selectedItem={selectedItem}
          isDisabled={isDisabled}
          list={list}
          maxHeight={maxHeight}
          width={width}
          top='5px'
          listHeight={listHeight}
          listLeft={listLeft}
          listWidth={listWidth}
          left={isButton ? '5px' : '0'}
          isBtnList={isBtnList}
          closeDropdown={() => setShowDropDown(false)}
        />
      </div>
    </>
  );
}

export default DropDownWithButton;