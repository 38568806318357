import { AnimatedModal } from "../../ReusableComponents/AnimatedModal";
import React, { Component } from "react";
import closeButton from '../../assets/Icons/closeWhite.png';
import Next from "../../icons/next1.png";
import Prev from "../../icons/back1.png";
import emptyCircle from "../../assets/Icons/emptyCircle.png"
import filledCircle from "../../assets/Icons/filledCircle.png"
import GR1 from "../assets/DroneNakshaOnboardingGraphics/Graphics_1.png"
import GR2 from "../assets/DroneNakshaOnboardingGraphics/Graphics_2.png"
import GR3 from "../assets/DroneNakshaOnboardingGraphics/Graphics_3.png"
import GR4 from "../assets/DroneNakshaOnboardingGraphics/Graphics_4.png"
import GR5 from "../assets/DroneNakshaOnboardingGraphics/Graphics_5.png"
import GR6 from "../assets/DroneNakshaOnboardingGraphics/Graphics_6.png"
import GR7 from "../assets/DroneNakshaOnboardingGraphics/Graphics_7.png"
import GR8 from "../assets/DroneNakshaOnboardingGraphics/Graphics_8.png"
/**
To use this popup you have to pass following props
  1) close as function
  2) show as flag

*/
export default class OnboardPopup extends Component {
  state = {
    data: [
      {
        img: GR1,
        title: "Welcome to DroneNaksha !",
        info: "Welcome to DroneNaksha , and amazingly simple yet feature rich platform which is much more than just a mapping software. You can start using it by organizing your data in Projects , Plans and Imagesets. Projects and plans are tools to manage your productivity workflows with data."
      },
      {
        img: GR2,
        title: "Organize your image data",
        info: "Create Imagesets inside plans and upload and organize your huge imagery datasets. We won't charge you for that 😋."
      },
      {
        img: GR3,
        title: "Process your data with one click !",
        info: 'Just hit "Process" button once your data is uploaded, and our servers will take charge of your data to process, trust us its that simple. You can have a cup of coffee and move on to next task, we will notify you once the processing is done over a short, simple and less bothering email 😊'
      },
      {
        img: GR4,
        title: "View the results on map.",
        info: "Visualise orthomosaics and different vegetation indices based imagery right in the browser, download what you like to take forward and share it."
      },
      {
        img: GR5,
        title: "Surface models view made easy.",
        info: "Visualise high resolution complex surface model images such as DSM , DTM and take them to next level for processing using application of your choice. "
      },
      {
        img: GR6,
        title: "Comparing output is fun and simple.",
        info: "Compare any kind of imagery with any other form, such as orthomosaics against surface models, NDVI etc using our amazing compare tool, believe us you will not find this anywhere else the way we did it."
      },
      {
        img: GR7,
        title: "GCP editing made simple. ",
        info: "We do GCPs in most intuitive and easy to use manner, our GCP editor is unique, we have tried to simplify hardest part of your workflow, you will agree once you use it and we mean it 😊"
      },
      {
        img: GR8,
        title: "Manage processing tasks ",
        info: "Track and control your processing tasks from a single window, you can do a quick check on tasks running in your account as and when needed , this is especially useful in long running tasks. Get started with your first task NOW !"
      }
    ],
    currentData: {
      img: GR1,
      title: "Welcome to DroneNaksha !",
      info: "Welcome to DroneNaksha , and amazingly simple yet feature rich platform which is much more than just a mapping software. You can start using it by organizing your data in Projects , Plans and Imagesets. Projects and plans are tools to manage your productivity workflows with data."
    },
    currentpage: 0,
    imgLoad: true
  };

  closePopup = () => {
    this.setState(
      (state) => ({
        ...state,
        currentData: this.state.data[0],
        currentpage: 0,
      }))
    this.props.close();
  };

  prevpage = () => {
    console.log("Clicked previous page : ", this.state.currentpage);

    if (this.state.currentpage > 0) {
      this.setState(
        (state) => ({
          ...state,
          imgLoad: true,
          currentpage: this.state.currentpage - 1,
          currentData: this.state.data[this.state.currentpage - 1]
        }))
    } else {
      console.log("Reached first page");
    }
  };

  onChangePage = (i) => {
    this.setState(
      (state) => ({
        ...state,
        imgLoad: true,
        currentpage: i,
        currentData: this.state.data[i]
      }))
  };

  nextpage = () => {
    console.log("Clicked next page: ", this.state.currentpage);

    if (
      this.state.currentpage < this.state.data.length - 1
    ) {
      this.setState(
        (state) => ({
          ...state,
          imgLoad: true,
          currentpage: this.state.currentpage + 1,
          currentData: this.state.data[this.state.currentpage + 1]
        }));
    } else {
      console.log("Reached last page");
    }
  };

  componentWillMount() { }
  render() {
    const { currentpage, data, currentData } = this.state;
    return (
      <div>
        <AnimatedModal
          height="440px"
          width="700px"
          isOpen={this.props.show}
          onRequestClose={() => {
            this.closePopup();
          }}
          background="#999999"
        >
          <div style={{ display: "flex", height: "98%", width: "94%", margin: "0% 3%", alignItems: "center", justifyContent: "center" }}>
            <div onClick={() => { this.prevpage() }} >
              {currentpage > 0 ?
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer"
                  }}
                  src={Prev}
                /> :
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    opacity: "0"
                  }}
                  src={Prev}
                />}
            </div>
            <div>
              <div onClick={() => { this.closePopup(); }} style={{ display: "flex", position: "absolute", top: "20px", right: "20px", cursor: "pointer" }}>
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                  src={closeButton}
                />
              </div>
              <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                {data.map((ele, i) => {
                  return (
                    <div style={{ margin: "1px 10px", cursor: "pointer" }} onClick={() => this.onChangePage(i)}>
                      <img src={i == currentpage ? filledCircle : emptyCircle} style={{ height: "12px", width: "12px" }}></img>
                    </div>
                  )
                })}
              </div>

              <div style={{ display: "flex", flexDirection: "row", height: "100%", color: "white", alignItems: "center", justifyContent: "center" }}>
                <div style={{ display: "flex", height: "100%" }}>
                  <img
                    style={{
                      height: "350px",
                      width: "350px",
                      opacity: this.state.imgLoad ? 0 : 1
                    }}
                    src={currentData.img}
                    loading='lazy'
                    onLoad={() => {
                      this.setState((state) => ({
                        ...state,
                        imgLoad: false
                      }))
                    }}
                  ></img>
                </div>

                <div style={{ display: "flex", flexDirection: "column", height: "70%", margin: "0% 2% 0% 2%", width: "40%" }}>
                  <div className="table-header" style={{ display: "flex", width: "100%", height: "60px", textAlign: "left", fontWeight: 500, color: "white" }}>{currentData.title}</div>
                  <div className="content-text" style={{ fontWeight: 450, marginTop: "5%", fontSize: "13px", color: "white", textAlign: "justify", }}>
                    {currentData.info}<span>  </span>
                  </div>
                </div>
              </div>
            </div>
            <div onClick={() => { this.nextpage() }} >
              {currentpage < data.length - 1 ?
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer"
                  }}
                  src={Next}
                /> :
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    opacity: "0"
                  }}
                  src={Next}
                />}
            </div>
          </div>
        </AnimatedModal>
      </div>
    );
  }
}
