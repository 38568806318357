import React, { useEffect, useState } from 'react'
import * as API from '../../api'
import ShareAssetsPopup from '../../ReusableComponents/ShareAssetsPopup';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup';
import AppConstants from '../../AppConstants';
import { BOTH, ELEVATION, VOLUME } from './ReusableComp/TaskProcessView';
import { PICSTORK_FEATURES } from '../../Teams';
import { getLimitedName } from '../../ReusableComponents/reusableFunctions';
import Plus from '../../assets/Icons/plus.png';
import dateFormat from 'dateformat';
import TrainingDetectionResult from "../Components/ReusableComp/TrainingDetectionResult"
const TrainingTaskDetails = (props) => {
    let isOrtho = props.task.linked_map
    const [accColor, setAccColor] = useState("grey");
    const [detector, setDetector] = useState(null)
    const [noOfDetection, setNoOfDetection] = useState(0);
    const [taskStartPopup, setTaskStartPopup] = useState(props.openStartTaskPopup)
    const taskStatusBGColor = props.task.status == AppConstants.TASK.STATUS.COMPLETED ? '#009900' : props.task.status == AppConstants.TASK.STATUS.PENDING ? '#2989CF1A' : props.task.status == AppConstants.TASK.STATUS.FAILED ? '#F02020' : '#ffcc00'
    const taskStatusColor = props.task.status == AppConstants.TASK.STATUS.COMPLETED ? 'white' : props.task.status == AppConstants.TASK.STATUS.PENDING ? '#2989cf' : props.task.status == AppConstants.TASK.STATUS.FAILED ? 'white' : 'white'
    const getDetectorDetails = () => {
        API.getDetectorDetails(props.task.task_id).then((result) => {
            if (result.detector)
                setDetector(result.detector)
        })
    }

    const [shareTask, setShareTask] = useState(false);
    const [legendDetails, setLegendDetails] = useState({ trainingAreaColor: "", testingAreaColor: "", accuracyAreaColor: "", totalAnnotations: 0, classLabels: [], detectorAccuracy: 0 })
    useEffect(() => {
        if (props.task.tasktype == 1 && props.task.status == AppConstants.TASK.STATUS.COMPLETED)
            getDetectorDetails()
    }, [taskStartPopup])

    useEffect(() => {
        API.getDetections(props.task.task_id, []).then(
            (data) => {
                props.taskImageType(data.image_data[0].file_name)
                let totalDetections = 0;
                data.ret.forEach((item) => {
                    for (const key in item.result.detections) {
                        if (item.result.detections.hasOwnProperty(key)) {
                            totalDetections += item.result.detections[key].count;
                        }
                    }
                })
                setNoOfDetection(totalDetections)
            }
        )
    }, [])
    useEffect(() => {
        const acc = parseInt(("" + legendDetails?.detectorAccuracy).split("%")[0])
        if (isNaN(acc)) setAccColor("grey")
        if (acc <= 25) setAccColor("red")
        else if (acc <= 50) setAccColor("orange")
        else if (acc <= 100) setAccColor("green")
        else return
    }, [legendDetails.detectorAccuracy])
    
    return <>
        {/* -------------- Activity Popup ----------------------- */}
        <ActivityPopup
            open={taskStartPopup}
            icon={'COMPLETED'}
            title={"Started task"}
            height={190}
            msg={'Your task is pending and added to the execution queue. It will be processed as soon as resource will be available.'}
            close={() => {
                setTaskStartPopup(false)
                props.closeStartTaskPopup()
            }}
        />
        {/* -------------------------------Buttons-------------------------------- */}
        <div style={{ display: 'flex', margin: "1%", width: '98%', height: '32px', alignItems: 'center', justifyContent: 'space-between' }}>
            <div className="services-content-title" style={{ display: "flex", width: '20%' }}>
                Task Details
            </div>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                <div style={{ display: 'flex', alignItems: "baseline" }}>
                    {detector ?
                        <div>
                            <div
                                style={{ textAlign: "end", marginRight: "5px" }}
                            >
                                <ButtonWithIcon
                                    icon={Plus}
                                    text="Detection Task"
                                    isDisabled={!props.permissions?.[PICSTORK_FEATURES.TASKS].CREATE}
                                    linkData={
                                        {
                                            pathname: "/picstork/detectionTask",
                                            state: {
                                                models: [detector],
                                                user: props.user,
                                                project: { id: props?.task?.project_id, project_name: props?.task?.project_name },
                                                fromTrainingTask: { isTiff: props.task.linked_map ? props.task.linked_map : undefined },
                                            },
                                        }
                                    }
                                />
                            </div>

                        </div>
                        : <></>}

                </div>
                {detector && props.task.tasktype == 1 && <div style={{ padding: "5px 10px 0 0", fontSize: '10px', }}>
                    You can create detection task using this detector.
                </div>}
            </div>
        </div>

        {/* Main Details */}
        <div style={{ display: "flex", alignItems: "center", height: props.task.linked_map ? "93%" : "90%", width: props.task.linked_map ? "" : "98%", background: "#fff", boxShadow: "0px 0px 10px rgba(0,0,0,0.25)" }}>
            <div style={{ maxWidth: isOrtho ? "500px" : "", width: isOrtho ? "46%" : "", padding: isOrtho ? "20px 30px" : "30px 50px", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: props.task && props.task.linked_map ? "58px" : "" }}>
                <div style={{ width: "100%", rowGap: isOrtho ? "20px" : "30px", columnGap: "50px", background: '#fff', display: 'grid', gridTemplateColumns: isOrtho ? "225px 135px" : "repeat(2,1fr)", justifyContent: "space-between" }}>

                    {shareTask ? <ShareAssetsPopup
                        shareInviteModal={shareTask ? true : false}
                        user={props.user}
                        defaultAccessLevel={'READ'}
                        task={shareTask}
                        linkedMap={props.task.linked_map}
                        setShareAssetsModal={setShareTask} /> : <></>}


                    {/* Detector details */}
                    {detector && <>
                        {/* Detector Name */}
                        <div style={{ width: isOrtho ? "100%" : "400px", display: "flex", flexDirection: "column" }}>
                            <div className='task-key' >Detector Name</div>
                            <div className='task-value'>
                                {getLimitedName(detector.display_name, 30)}
                            </div>
                        </div>

                        {/* Created On */}
                        <div style={{ display: "flex", flexDirection: "column", width: isOrtho ? "100%" : "400px", textAlign: "left" }}>
                            <div className='task-key'>Created On</div>
                            <div className='task-value'>{dateFormat(detector.date_created, "dd mmm yyyy")}</div>
                        </div>
                    </>}


                    {/* Task Name */}
                    <div style={{ width: isOrtho ? "100%" : "400px", display: "flex", flexDirection: "column" }}>
                        <div className='task-key' >Task Name</div>

                        <div className='task-value'>{getLimitedName(props.task.task_name, 30)}
                            {(props.task.sharedBy && props.task.sharedBy.isShared) ?
                                <span style={{ color: '#2989cf', fontSize: '10px' }}>
                                    ( sharedBy, {props.task.sharedBy.sharedBy.first_name + " " + props.task.sharedBy.sharedBy.last_name})
                                </span>
                                : <></>}
                        </div>
                    </div>

                    {/* Created Date */}
                    <div style={{ display: "flex", flexDirection: "column", width: isOrtho ? "100%" : "400px", textAlign: "left" }}>
                        <div className='task-key'>Created Date</div>
                        <div className='task-value'>{dateFormat(props.task.date_created, "dd mmm yyyy")}</div>
                    </div>

                    {/* Imageset */}
                    <div style={{ width: isOrtho ? "100%" : "400px", display: "flex", flexDirection: "column" }}>
                        <div className='task-key'>Imageset</div>
                        <div className='task-value long-text-fix' >{(props.task.collection_name.split("_$")[0])}</div>
                    </div>

                    {/* No. of Images */}
                    <div style={{ display: "flex", flexDirection: "column", width: isOrtho ? "100%" : "400px", textAlign: "left" }}>
                        <div className='task-key'>No. of Images</div>
                        <div className='task-value'>{props.task.images}</div>
                    </div>

                    {/* Task type */}
                    <div style={{ width: isOrtho ? "100%" : "400px", display: "flex", flexDirection: "column" }}>
                        <div className='task-key' >{props.task.tasktype == 1 ? "Training type" : 'Detection type'}</div>
                        <div className='task-value'>{props.task.tasktype == 1 ? props.task.training_type : props.task.task_purpose}</div>
                    </div>


                    {/* Status At */}
                    {
                        (props.task.status == AppConstants.TASK.STATUS.COMPLETED ||
                            props.task.status == AppConstants.TASK.STATUS.FAILED) &&
                        <div style={{ display: "flex", flexDirection: "column", width: isOrtho ? "100%" : "400px", textAlign: "left" }}>
                            <div className='task-key'>{`${props.task.status} at`}</div>
                            <div className='task-value'>{dateFormat(props.task.status_update_timestamp, 'dd mmm yyyy')}</div>
                        </div>
                    }


                    {/* Status */}
                    <div style={{ width: isOrtho ? "100%" : "400px", display: "flex", flexDirection: "column" }}>
                        <div className='task-key'>Status</div>
                        <div className="task-status" style={{ width: 'fit-content', color: taskStatusColor, background: taskStatusBGColor, fontWeight: 600, margin: "" }}>{props.task.status}</div>
                    </div>

                    {/* Accuracy */}
                    {<div style={{ display: "flex", flexDirection: "column", width: isOrtho ? "100%" : "400px", textAlign: "left" }}>
                        <div className='task-key'>Accuracy</div>
                        <div className='task-value'>{props.task.accuracy}</div>
                    </div>}


                    {/* Detector Accuracy */}
                    {props.task && props.task.linked_map && !props.task.models &&
                        <div style={{ display: "flex", flexDirection: "column", width: isOrtho ? "100%" : "400px", textAlign: "left", }}>
                            <div style={{ fontSize: "11px", color: "#666666", marginTop: "5px" }}>Detector Accuracy </div>
                            <div style={{ fontSize: "23px", fontWeight: "600", color: accColor }}>{legendDetails.detectorAccuracy}</div>
                        </div>}
                    {(props.task && props.task.linked_map) && (props.task.models) &&
                        <div style={{ display: "flex", flexDirection: "column", width: isOrtho ? "100%" : "400px", textAlign: "left", }}>
                            <div style={{ fontSize: "11px", color: "#666666" }}>Detector Accuracy </div>
                            <div style={{ fontSize: "23px", fontWeight: "600", color: accColor }}>{legendDetails.detectorAccuracy}</div>
                        </div>}




                </div>
                {/* Class labels */}
                <div style={{ display: "flex", width: "100%", height: "100%", marginTop: isOrtho ? '20px' : "30px" }}>
                    <div style={{ overflow: "", width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                        <div className='task-key'>Class labels</div>
                        <div style={{
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            fontSize: "13px",
                            lineHeight: "20px",
                            color: "#3c3c3c",
                            padding: "0px 0px 4px 0px",
                            overflowY: "scroll",
                            height: "50%",
                            minHeight: "100px",
                            maxHeight: "200px"
                        }}>
                            {props.task.type.map((obj, i) => {
                                const color = legendDetails.classLabels.find(region => region.type === obj)?.color;
                                return (
                                    <button
                                        className="aerocapture-detection-type-list"
                                    // style={{ fontSize: "12px", margin: "2px 4px", backgroundColor: color, color: "white" }}
                                    >
                                        {obj.length >= 20 ? obj.substring(0, 21) : obj}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            {/* Training result map */}
            {props.task && props.task.linked_map &&
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                    <div style={{ fontSize: "12px", padding: "10px", display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>Total Annotations : {legendDetails.totalAnnotations}</div>
                        {legendDetails.trainingAreaColor && <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>Training Area <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: legendDetails.trainingAreaColor, marginLeft: "10px" }}></div></div>}
                        {legendDetails.testingAreaColor && <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>Testing Area  <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: legendDetails.testingAreaColor, marginLeft: "10px" }}></div></div>}
                        {legendDetails.accuracyAreaColor && <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>Accuracy Area  <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: legendDetails.accuracyAreaColor, marginLeft: "10px" }}></div></div>}

                    </div>

                    {props.task && props.task.tasktype === 1 && props.task.linked_map && <TrainingDetectionResult user={props.user} permissions={props.permissions} task={props.task} setLegendDetails={setLegendDetails} />}
                </div>
            }
        </div >
    </>
}

export default TrainingTaskDetails