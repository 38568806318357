import React, { useState, useRef, useEffect, Component } from 'react'
import { loadImage } from 'canvas'
export default function CropAnnotation(props) {
  const [imgSrc, setImgSrc] = useState(
    props.imgSrc,
  )
  const [idx, setIdx] = useState(props.region.innerRegion.id)

  async function onImageLoad() {
    let { region, imgSrc, imgData } = props
    let outerRegion = region.outerRegion
    let annotationRegion = region.annotationRegion

    // var image = new Image();
    // image.src = imgSrc
    // image.height = imgData.height
    // image.width = imgData.width
    // image.crossOrigin = "*"
    const image = await loadImage(imgSrc);
    //Draw image on the canvas
    const dynamicCanvas = document.createElement("canvas");
    const dynamicContext = dynamicCanvas.getContext("2d");
    dynamicCanvas.height = imgData.height;
    dynamicCanvas.width = imgData.width;

    const canvas = document.getElementById(idx);
    if (canvas) {
      const context = canvas.getContext('2d');
      // image.onload = function () { 
      dynamicContext.drawImage(image, 0, 0, imgData.width, imgData.height);
      //Add annotation on the canvas
      dynamicContext.beginPath();
      if (region.innerRegion.type === "polygon") {
        let polygonPoints = region.annotationRegion.points
        dynamicContext.moveTo(polygonPoints[0][0], polygonPoints[0][1]);
        for (let i = 1; i < polygonPoints.length; i++) {
          dynamicContext.lineTo(polygonPoints[i][0], polygonPoints[i][1]);
        }
        dynamicContext.closePath();
        dynamicContext.lineWidth = annotationRegion.boundingbox.w > annotationRegion.boundingbox.h ? parseInt(annotationRegion.boundingbox.w / 120) < 2 ? 2 : parseInt(annotationRegion.boundingbox.w / 120) : parseInt(annotationRegion.boundingbox.h / 120) < 2 ? 2 : parseInt(annotationRegion.boundingbox.h / 120);
        dynamicContext.strokeStyle = 'red';
        dynamicContext.stroke();
      }
      else {

        dynamicContext.rect(annotationRegion.x, annotationRegion.y, annotationRegion.w, annotationRegion.h);
        dynamicContext.lineWidth = annotationRegion.w > annotationRegion.h ? parseInt(annotationRegion.w / 120) < 2 ? 2 : parseInt(annotationRegion.w / 120) : parseInt(annotationRegion.h / 120) < 2 ? 2 : parseInt(annotationRegion.h / 120);
        dynamicContext.strokeStyle = 'red';
        dynamicContext.stroke();
      }

      // Crop image with annotation with required offset
      context.drawImage(dynamicCanvas, outerRegion.x, outerRegion.y, outerRegion.w, outerRegion.h, 0, 0, 350, 350);
    }
    // }
  }
  useEffect(() => {
    onImageLoad()
  }, [])

  return (
    <div style={{ height: "100%", width: "100%", objectFit: 'contain' }}>
      <canvas id={idx} height={350} width={350}></canvas>
    </div>
  )
}
