import React, { Component } from "react";
import Cookies from 'universal-cookie';
const cookie = new Cookies()

class CookiePolicyPopup extends Component {
  state = {
    acceptCookiePopup: true
  }

  closeCookiePopup = () => {
    cookie.set("acceptCookies", 'yes', { path: "/", maxAge: 86400 });
    this.setState({ acceptCookiePopup: false })
  }

  componentWillMount() {
    let acceptCookie = cookie.get('acceptCookies')
    if (acceptCookie == 'yes')
      this.setState({ acceptCookiePopup: false })
  }

  render() {
    return (
      this.state.acceptCookiePopup ?
        <div className='content-text' style={{ bottom: '8px', boxShadow: '0px 0px 12px 5px rgba(0, 0, 0, 0.15)', position: 'absolute', left: '8px', borderRadius: '6px', background: 'white', height: '148px', width: '275px', padding: '10px' }}>
          <div style={{ fontSize: '12px', color: '#3c3c3c', fontWeight: 400 }}>
            We and selected third parties use cookies (or similar technologies) for technical purposes, to enhance and analyze site usage, to support our marketing efforts, and for other purposes described in our
            <span style={{ marginLeft: '4px', color: '#2989cf' }} className="hover" onClick={() => { window.open('https://aeromegh.com/privacy-policy/') }}>Privacy Policy.</span>
          </div>
          <div style={{ position: 'absolute', bottom: '12px', right: '12px' }}>
            <button
              className="add-user-form-submit-button ok-button btn-ok"
              style={{ padding: '6px 18px', fontSize: '12px' }}
              onClick={() => {
                this.closeCookiePopup()
              }}
            >
              Ok
            </button>
          </div>
        </div> :
        <></>
    );
  }
}

export default CookiePolicyPopup
