import React from 'react';
import { Maximize } from '../../../helperComponents/Helpers';
import FloorImageAnnotation from '../FloorImageAnnotation';
import PanoramaViewer from './PanoramaViewer';

const FloorImagesViewer = ({ images360, imagesPlain, st, index, minimizedView, changeView, panning, handleImageAnnotationToolChange, mentionObj, rest, isDisabled, callMarkIssue, callback }) => {
    return <>
        {minimizedView ?
            <Maximize
                onClick={() => changeView(false)}
            />
            : <></>}
        {
            <>
                {images360 ?
                    <PanoramaViewer
                        panning={panning}
                        selectedImage={images360[index]}
                        st={st}
                        callMarkIssue={callMarkIssue}
                        callback={callback}
                        mentionObj={mentionObj}
                        isDisabled={isDisabled}
                    /> :
                    <FloorImageAnnotation
                        panning={panning}
                        selectedImage={imagesPlain[index]}
                        st={st}
                        handleImageAnnotationToolChange={handleImageAnnotationToolChange}
                        mentionObj={mentionObj}
                        isDisabled={isDisabled}
                        rest={rest}
                    />
                }
            </>
        }
    </>


}

export default FloorImagesViewer;