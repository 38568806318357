import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import projectInactive from '../../assets/sideBarIcons/ProjectInactive.png';
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs';
import { StaticRouter, Redirect } from "react-router-dom";
import dashboardIcon from '../../assets/sideBarIcons/dashboard.png';
// import projectActive from '../assets/sidebarIcons/ProjectActive.png';
import imageSetsActive from '../../sidebaricon/ImageSet.png'
import service from "../../sidebaricon/Services.png";
import AeroMeghLogo from "../../aeromeghLog.png";
import * as API from '../../api.js';
import Loader from '../../icons/loader.svg';
import { Link } from 'react-router-dom';
import ListIcon from '../../icons/gridBlue.png'
import GridIcon from '../../icons/listBlue.png'
import { connect } from "react-redux";
import { addUserAnalytic, getUniqueCollectionName, updateOutTime } from '../../ReusableComponents/reusableFunctions.js';
import ProjectThumb from '../../assets/sideBarIcons/ProjectInactive.png'
import ImageSetThumb from '../../icons/ImageSetThumb.png'
import Noimageset from '../../assets/Images/Noimageset.png';
import TaskIcon from '../../icons/servers.png';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import myMaps from "../../assets/sideBarIcons/myMaps.png";
import Help from "../../sidebaricon/Help.png";
import { DeleteComponentGrid, DeleteComponentTable } from '../../ReusableComponents/Menu'
import dateFormat from 'dateformat';
import { SortComponent } from '../../ReusableComponents/SortComponent';
import AppConstants, { BLOB_URL, appConstants } from '../../AppConstants';
import DropDownWithButton from '../../ReusableComponents/DropDownWithButton';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import Plus from '../../icons/addBlue.png';
import { ActiveRole, DRONENAKSHA_FEATURES, getFeaturesPermissions } from '../../Teams';
import EntitySelector from '../../ReusableComponents/EntitySelector';
import whitePlus from "../../assets/Icons/whiteplus.png"

class AeroCaptureImageCollections extends Component {

    state = {
        isOwner: false,
        sharedBy: null,
        user: null,
        type: "",
        outId: "",
        progress: 0,
        showAddImagePopup: false,
        showDownloadPopup: false,
        renameCollection: false,
        loader: true,
        services: [],
        projects: [],
        project: {},
        plan: {},
        redirect: false,
        collection_name: '',
        createCollectionMsg: '',
        collections: [],
        showBackIcon: true,
        cancel: false,
        loading: true,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        imageset: '',
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        oldCollectionId: '',
        showCopyImagePopup: false,
        newCollectionName: '',
        sortType: 'Last Modified',
        filter: "All",
        allImageSets: [],
        itemsList: [{ value: "all", label: "All" }, { value: "processed", label: "Processed" }, { value: "unprocessed", label: "Unprocessed" }],
        gridView: true,
        selectedItem: "All",
    }
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
            showEntitySelector: false
        }))
    }

    getImageCollections = () => {
        if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ) API.getImageCollections(this.state.project.id, this.state.plan.id, 3).then((data) => {
            console.log("data of image collections", data)
            this.setState((state) => ({
                ...state,
                allImageSets: data.collections ? data.collections : [],
                collections: data.collections ? data.collections : [],
                loading: false
            }), () => {
                if (this.state.sortType === 'A-Z') {
                    this.sortByName()
                } else if (this.state.sortType === 'Z-A') {
                    this.reverseByName()
                } else if (this.state.sortType === 'First Modified') {
                    this.sortByDate()
                } else if (this.state.sortType === 'Last Modified') {
                    this.reverseByDate()
                }
            })
            this.loadTiff()
        }, (e) => {
            console.log(e);
            alert('Something went wrong');
            this.setState((state) => ({
                ...state,
                loading: false
            }))
        })
        else {
            this.setState((state) => ({
                ...state,
                loading: false
            }))
        }
    }

    // function to load tiff thumbnails
    loadTiff = async () => {
        let { collections, user } = this.state
        for (let i = 0; i < this.state.collections.length; i++) {
            if (collections[i].file && collections[i].file.file_name && collections[i].file.file_name != undefined) {
                let name = collections[i].file.file_name.toLowerCase()
                let turl = `${BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${collections[i].id}/thumb-${collections[i].file.file_name}?${this.state.permissions.st}`
                collections[i].thumbURL = turl
                this.setState((state) => ({
                    ...state,
                    collections
                }))
            }
        }
    }

    isUserOwnerOfCollection = () => {
        const { id } = this.state.project
        API.isUserProjectOwner(id)
            .then((result) => {
                this.setState({
                    isOwner: result.isShared ? false : true,
                    isAllowToDownload: result.access_level == "DOWNLOAD" ? true : false,
                    sharedBy: result.sharedBy
                })
            })
            .catch((err) => {
                this.setState((state) => ({
                    ...state,
                    itemName: "",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Something went wrong while getting owner information",
                    showProcessPopup: true,
                    loading: false,
                    isOwner: false,
                    isAllowToDownload: false
                }))
            })
    }

    renameCollection = () => {
        let renameData = {
            "componentId": this.state.selectedCollectionId,
            "newName": this.state.collection_name,
            "type": "ImageCollection"
        }

        this.setState((state) => ({
            ...state,
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "UPDATE",
            processMessage: null,
            showProcessPopup: true,
        }))

        if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].EDIT) API.renameCollection(renameData).then((data) => {
            if (data.status == 200) {
                this.setState((state) => ({
                    ...state,
                    collection_name: '',
                    newCollecton: data.collection,
                    itemName: "Imageset",
                    processPopupIcon: "COMPLETE",
                    processAction: "UPDATE",
                    processMessage: null,
                    showProcessPopup: true,
                }), () => {
                    this.getImageCollections();
                })
            } else if (data.status == 409) {
                this.setState((state) => ({
                    ...state,
                    collection_name: '',
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "UPDATE",
                    processMessage: "Imageset already exist with same name.",
                    showProcessPopup: true,
                }))
                // alert("ImageSelt already exist with same name.")
            } else {
                this.setState((state) => ({
                    ...state,
                    collection_name: '',
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "UPDATE",
                    processMessage: "Something went wrong.",
                    showProcessPopup: true,
                }))
                // alert('Something went wrong')
            }
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                itemName: "Imageset",
                processPopupIcon: "ERROR",
                processAction: "UPDATE",
                processMessage: "Something went wrong.",
                showProcessPopup: true,
            }))
        })
    }

    getPlans = () => {
        const { user, type, userdisplay, currentpage, userperpage, key, counts, requestStatus, project } = this.state;
        let state = this.props.location.state
        this.setState(state => ({
            ...state,
            // logsDisplay: [],
            counts: 0,
            // loading: true
        }))
        if (this.state.permissions[DRONENAKSHA_FEATURES.PLANS].READ) API.getPlans(this.props.location.state.project.project_name, this.props.location.state.plan.plan_name, 1, 0, 3, this.props.location.state.project.id).then(async (data) => {
            const plan = data.plans[0]
            // plans.forEach((plan) => {
            //     if (this.props.location.plan.plan_name == plan.plan_name) {
            this.setState((state) => ({
                ...state,
                plan: plan,
                loading: false
            }), () => {
                this.getImageCollections()
                this.setState((state) => ({
                    ...state,
                    showAddImagePopup: true,
                }))
            })

        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                plans: [],
                loading: false
            }))
        })
        else {
            this.setState((state) => ({
                ...state,
                plans: [],
                loading: false
            }))
        }

    }

    createCollectionCopy = () => {
        this.setState((state) => ({
            ...state,
            showCopyImagePopup: false,
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: "Duplicating your image collection please Wait...",
            showProcessPopup: true,
        }))
        console.log("createcopy function call");
        if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].CREATE) API.createImageCollection(this.state.project.id, this.state.plan.id, this.state.newCollectionName).then((data) => {
            if (data.collection) {
                if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].CREATE) API.duplicateImageSet(this.state.permissions.container, this.state.oldCollectionId, data.collection.id, AppConstants.SERVICES.DRONENAKSHA).then((res) => {
                    if (res.status == 201 || res.status == 202) {
                        this.getImageCollections();
                        this.setState((state) => ({
                            ...state,
                            newCollecton: data.collection,
                            itemName: "Imageset",
                            processPopupIcon: "COMPLETE",
                            processAction: "ADD",
                            processMessage: 'Imageset copied successfully.',
                            showProcessPopup: true,
                        }))
                    } else {
                        this.setState((state) => ({
                            ...state,
                            newCollecton: data.collection,
                            itemName: "Imageset",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: 'Something went wrong when copying images.',
                            showProcessPopup: true,
                        }), () => {
                            if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].DELETE) API.deleteAerocaptureImageCollection(data.collection.id, AppConstants.SERVICES.DRONENAKSHA).then((data) => {
                                console.log(data);
                                this.getImageCollections()
                            })
                        })
                    }

                })
            } else if (data.status == 409) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Imageset already exist with same name.",
                    showProcessPopup: true,
                }))
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Something went wrong",
                    showProcessPopup: true,
                }))
            }
        }, (e) => {
            // console.log(e);
            this.setState((state) => ({
                ...state,
                itemName: "Imageset",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: "Something went wrong",
                showProcessPopup: true,
            }))
        })
    }

    createCollection = () => {
        this.setState((state) => ({
            ...state,
            showAddImagePopup: false,
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: null,
            showProcessPopup: true,
        }))

        if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].CREATE) API.createImageCollection(this.state.project.id, this.state.plan.id, this.state.collection_name).then((data) => {
            if (data.collection) {
                this.setState((state) => ({
                    ...state,
                    newCollecton: data.collection,
                    itemName: "Imageset",
                    processPopupIcon: "COMPLETE",
                    processAction: "ADD",
                    processMessage: null,
                    showProcessPopup: true,
                }), () => {
                    this.getImageCollections();
                    setTimeout(() => {
                        this.setState((state) => ({
                            ...state,
                            redirect: true
                        }))
                    }, 2000);
                })
            } else if (data.status == 409) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Imageset already exist with same name.",
                    showProcessPopup: true,
                }))
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Something went wrong",
                    showProcessPopup: true,
                }))
            }
        }, (e) => {
            // console.log(e);
            this.setState((state) => ({
                ...state,
                itemName: "Imageset",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: "Something went wrong",
                showProcessPopup: true,
            }))
        })
    }

    deleteImageCollection = () => {
        this.setState((state) => ({
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].DELETE) API.deleteAerocaptureImageCollection(this.state.selectedCollectionId, AppConstants.SERVICES.DRONENAKSHA).then((data) => {
            console.log(data);
            this.setState((state) => ({
                itemName: "Imageset",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
            }), () => {
                this.getImageCollections()
            })
        }, (e) => {
            //console.log(e);
            this.setState((state) => ({
                itemName: "Imageset",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }

    successRedirectComponent = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/dronenaksha/images",
                state: {
                    user: this.state.user,
                    type: this.state.type,
                    sidebarMode: this.state.sidebarMode,
                    collection: this.state.newCollecton,
                    project: this.state.project,
                    plan: this.state.plan,
                }
            }} />
        }
    }

    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }

    componentWillMount() {
        document.title = "DroneNaksha - Image Collections";
        getFeaturesPermissions([DRONENAKSHA_FEATURES.IMAGECOLLECTIONS, DRONENAKSHA_FEATURES.MAPS])
            .then(permissions => {
                this.setState(state => ({ ...state, permissions, }), () => {

                    addUserAnalytic(this.props.location.pathname)
                        .then(id => {
                            console.log("ID : ", id)
                            this.setState((state) => ({
                                outId: id
                            }))
                        })
                    var style = document.createElement('style');
                    style.type = 'text/css';
                    style.innerHTML = '.popup-content { width: 30% !important; }';
                    document.getElementsByTagName('head')[0].appendChild(style);

                    if (this.props.location.state.user.avatar_url && this.props.location.state.user.avatar_url.length > 0) {
                        this.setState((state) => ({
                            ...state,
                            avatarURL: `${this.props.location.state.user.avatar_url}?${this.state.permissions.st
                                }&t${new Date().getTime()}`,
                        }));
                    } else {
                        let avatarURL = `/images/${this.props.location.state.user.first_name[0].toLowerCase()}.PNG`;
                        this.setState((state) => ({
                            ...state,
                            avatarURL: avatarURL,
                        }));
                    }
                    if (this.props.location.state) {
                        let { user, type, task, project, plan } = this.props.location.state;
                        this.setState((state) => ({
                            ...state,
                            user,
                            type,
                            task,
                            project,

                            plan
                        }), () => {
                            this.isUserOwnerOfCollection()
                            if (plan.id) {
                                this.getImageCollections()
                            } else {
                                this.getPlans();
                            }

                            API.getUserSubbedServices().then(
                                (response) => {
                                    let services = response[0];
                                    // console.log(services);
                                    if (services.length == 1 && services[0].title == 'AeroStream') {
                                        this.setState((state) => ({
                                            ...state,
                                            showBackIcon: false
                                        }))
                                    } else {
                                        this.setState((state) => ({
                                            ...state,
                                            showBackIcon: true
                                        }))
                                    }
                                }
                            )
                        })
                    } else {
                        API.getUserType().then(([blah, type, user]) => {
                            //console.log("ROLE ", type);
                            //console.log("USER ", user)
                            this.setState((state) => ({
                                ...state,
                                user,
                                type
                            }), () => {
                                this.getImageCollections()
                                //  console.log("ASKDJHASK", user);
                            })
                        }, (e) => {
                            window.location.replace("/login");
                        })
                    }
                })
            })
            .catch((e) => window.location.replace("/"))
    }

    onChangeImagesetType = (value) => {
        this.setState((state) => ({
            ...state,
            filter: value[0].label,
            selectedItem: value[0].label
        }), () => {
            if (this.state.filter == "Processed") {
                let urls = []
                let processedImageSets = this.state.allImageSets.filter((data, i) => {
                    return data.isProcessed
                })
                this.setState({ collections: processedImageSets })
            }
            else if (this.state.filter == "Unprocessed") {
                let urls = []
                let unProcessedImageSets = this.state.allImageSets.filter((data, i) => {
                    return !data.isProcessed
                })
                this.setState({ collections: unProcessedImageSets })
            }
            else
                this.setState({ collections: this.state.allImageSets })
        })
    }

    handleChange = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else if (e.target.value.includes(' ')) {
            // setError("Spaces are not allowed.")
        } else {
            // setError(undefined)
            this.setState({ collection_name: e.target.value });
        }
    }

    handleChangeCopy = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else
            if (e.target.value.includes(' ')) {
                // setError("Spaces are not allowed.")
            } else {
                // setError(undefined)
                this.setState({ newCollectionName: e.target.value });
            }
    }



    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.collections.sort((a, b) => a.collection_name.localeCompare(b.collection_name))
        this.setState((state) => ({
            ...state,
            collections: sort,
        }));

    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.collections.sort((a, b) => b.collection_name.localeCompare(a.collection_name))
        this.setState((state) => ({
            ...state,
            collections: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.collections.sort((a, b) => a.created_on.localeCompare(b.created_on))
        this.setState((state) => ({
            ...state,
            collections: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.collections.sort((a, b) => b.created_on.localeCompare(a.created_on))
        this.setState((state) => ({
            ...state,
            collections: sort,
        }));
    }
    handleImportImagesets = async (selectedEntities) => {
        switch (selectedEntities.entityType) {
            case "projects": break;
            case "plans": break;
            case "imagesets":
                // let importedImagesets = []
                let errorOccured = false;
                for (const entity of selectedEntities.entites) {
                    if (errorOccured) break;
                    await new Promise((resolve, reject) => {
                        API.createImageCollection(this.state.project.id, this.state.plan.id, `${getUniqueCollectionName(entity.collection_name)}`).then(data => {
                            // const res = await data.json()
                            if (data.collection) {
                                API.duplicateImageSet(this.state.permissions.container, entity.id, data.collection.id, AppConstants.SERVICES.DRONENAKSHA, [".tif", ".TIF"]).then((res) => {
                                    if (res.status == 201 || res.status == 202) {
                                        resolve()
                                    } else {
                                        errorOccured = true;
                                        API.deleteAerocaptureImageCollection(data.collection.id, AppConstants.SERVICES.DRONENAKSHA).then((data) => {
                                            resolve()
                                        }).catch(err => {
                                            errorOccured = true
                                            resolve()
                                        })
                                    }

                                }).catch(err => {
                                    errorOccured = true
                                    resolve()
                                }
                                )
                            }
                            else if (data?.status === 409) {
                                errorOccured = true;
                                resolve()
                            }
                        }).catch(err => {
                            errorOccured = true
                            resolve()
                        })
                    })
                }
                if (errorOccured) {
                    this.setState({ showProcessPopup: true, processMessage: "Something went wrong while importing imagesets", processPopupIcon: "ERROR" })
                } else {
                    this.setState({
                        processMessage: "Collections imported successfully", processPopupIcon: "COMPLETE",
                    }, () => {
                        this.getImageCollections()
                    })
                }

                break;
            default:
        }
    }
    render() {
        const CustomSortToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
                href=""
                ref={ref}
                onClick={e => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {children}
                <div className='sort-type-style'>{this.state.sortType}</div>
            </a>
        ));

        let { user, type, collections, sidebarMode, showBackIcon, } = this.state

        return (
            user?.username ? <div className="wrapper">
                <div style={{ display: 'flex' }}>
                    <div className="sidebar-expanded" style={{ paddingTop: "0px" }}>
                        <div style={{ display: "flex", height: "40px" }}>
                            <div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
                                <img
                                    src={AeroMeghLogo}
                                    style={{ width: "65px", height: "20px", marginTop: "10px" }}
                                />
                            </div>
                        </div>
                        <ul className="sidebar-nav" id="sidebar-nav">
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={service}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                onClick={this.changeColor}
                                            >
                                                {"Services"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/dashboard",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={dashboardIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"DroneNaksha"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/plan",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={projectInactive}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                onClick={this.changeColor}
                                            >
                                                {"Plan"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/myMaps",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={myMaps}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                onClick={this.changeColor}
                                            >
                                                Maps
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>

                            <div className={"selectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/image_collections",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={imageSetsActive}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Imagesets"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/tasks",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={TaskIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Tasks"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <div
                                        className="onhoverext"
                                        onClick={
                                            () => {
                                                window.open("https://dronenaksha-docs.aeromegh.com/", "_blank")
                                            }
                                        }
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={Help}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                            >
                                                {"Help"}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    {this.successRedirectComponent()}

                    <Modal
                        style={{ marginTop: '150px' }}
                        show={this.state.renameCollection}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                renameCollection: false,
                                collection_name: ''
                            }));
                        }}
                    >
                        <Modal.Body >
                            <div style={{ marginBottom: "30px" }}>
                                <div className="services-content-title" style={{ fontSize: "18px" }}>{"Rename Imageset"}</div>
                                <label className="font-color-secondary" style={{ fontSize: "14px" }}>Enter New Name</label>
                                <input type="text" maxLength={AppConstants.POPUP_INPUT_TEXT_LIMIT} value={this.state.collection_name} className="add-user-form-text" placeholder="eg. Imageset Name"
                                    onChange={this.handleChange} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div>
                                <button
                                    className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            renameCollection: false,
                                            collection_name: ''
                                        }));
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div>
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            renameCollection: false,
                                        }), () => {
                                            this.renameCollection();
                                        })

                                    }}
                                >
                                    Update
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>

                    <div className="top-bar">
                        <div className="breadCrumbs">
                            <BreadCrumbs data={[
                                {
                                    displayName: "projects",
                                    pathname: "/dronenaksha/projects",
                                    state: {
                                        type,
                                        user,
                                    },
                                    style: {
                                        color: "black",
                                        fontSize: "12px",
                                    }
                                },
                                {
                                    displayName: this.props.location.state.project.project_name,
                                    pathname: "/dronenaksha/project",
                                    state: {
                                        type,
                                        user,
                                        plan: this.props.location.state.plan,
                                        project: this.props.location.state.project,
                                    },
                                    style: {
                                        color: "black",
                                        fontSize: "12px"
                                    }
                                },
                                {
                                    displayName: this.props.location.state.plan.plan_name,
                                    pathname: "/dronenaksha/plan",
                                    state: {
                                        type,
                                        user,
                                        plan: this.props.location.state.plan,
                                        project: this.props.location.state.project
                                    },
                                    style: {
                                        color: "black",
                                        fontSize: "12px"
                                    }
                                },
                                {
                                    displayName: "Imagesets",
                                }
                            ]} />
                            {this.state.sharedBy == null || !(this.state.sharedBy) ? <></> : <span className='dn-shared-by'>- Shared by {this.state.sharedBy.first_name} {this.state.sharedBy.last_name}</span>}
                            <ActiveRole />

                        </div>
                    </div>
                    {/*<TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar>*/}
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> : <div className="main-content" style={{ background: 'none' }} onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }>
                        <Modal
                            style={{ marginTop: '150px' }}
                            show={this.state.showAddImagePopup}
                            onHide={() => {
                                this.setState((state) => ({
                                    ...state,
                                    showAddImagePopup: false,
                                    collection_name: ''

                                }));
                            }}
                        >
                            <Modal.Body >
                                <div style={{ marginBottom: "30px" }}>
                                    <div className="services-content-title">{"Create an Imageset"}</div>
                                    <label className="font-color-secondary" style={{ fontSize: "13px" }}>To upload images you need to create an Imageset.</label>
                                    <input type="text" value={this.state.collection_name} className="add-user-form-text" placeholder="eg. Imageset Name" maxLength={AppConstants.IMAGESET_NAME_LIMIT}
                                        onChange={this.handleChange} />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="addcustomer-button-empty-dashboard popup-btn-left"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            showAddImagePopup: false,
                                        }));
                                    }}
                                >
                                    cancel
                                </button>
                                {this.state.collection_name ?
                                    <button
                                        className="add-user-form-submit-button popup-btn-right btn-ok"
                                        onClick={this.createCollection}
                                    >
                                        Ok
                                    </button> :
                                    <button
                                        className="add-user-form-submit-button popup-btn-right btn-ok"
                                        style={{ opacity: '0.5', cursor: 'default' }}
                                    >
                                        Ok
                                    </button>}

                            </Modal.Footer>
                        </Modal>

                        <Modal
                            style={{ marginTop: '150px' }}
                            show={this.state.showCopyImagePopup}
                            onHide={() => {
                                this.setState((state) => ({
                                    ...state,
                                    showCopyImagePopup: false,
                                    newCollectionName: ''

                                }));
                            }}
                        >
                            <Modal.Body >
                                <div style={{ marginBottom: "30px" }}>
                                    <div className="services-content-title" >{"Copy an Imageset"}</div>
                                    {/* <label className="font-color-secondary" style={{ fontSize: "14px" }}>To upload images you need to create an imageset.</label> */}
                                    <input type="text" value={this.state.newCollectionName} className="add-user-form-text" placeholder="eg. Imageset Name" maxLength={AppConstants.POPUP_INPUT_TEXT_LIMIT}
                                        onChange={this.handleChangeCopy} />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="addcustomer-button-empty-dashboard popup-btn-left"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            showCopyImagePopup: false,
                                        }));
                                    }}
                                >
                                    cancel
                                </button>
                                {this.state.newCollectionName ?
                                    <button
                                        className="add-user-form-submit-button popup-btn-right btn-ok"
                                        onClick={() => {
                                            this.createCollectionCopy()
                                        }}
                                    >
                                        Ok
                                    </button> :
                                    <button
                                        className="add-user-form-submit-button popup-btn-right btn-ok"
                                        style={{ opacity: '0.5', cursor: 'default' }}
                                    >
                                        Ok
                                    </button>}

                            </Modal.Footer>
                        </Modal>
                        {/* Entity selector */}
                        {this.state.showEntitySelector &&
                            <EntitySelector
                                allowMultipleSelect
                                serviceList={[AppConstants.SERVICES.PICSTORK]}
                                btnTitle={"Import"}
                                show={this.state.showEntitySelector}
                                entity={"imagesets"}
                                onRequestClose={() => {
                                    this.setState({ showEntitySelector: false })
                                }}
                                callback={async (selectedEntities) => {
                                    this.setState({
                                        showProcessPopup: true,
                                        processPopupIcon: "WAIT",
                                        processMessage: `Importing ${selectedEntities.entityType} please wait...`
                                    }, () => {
                                        this.handleImportImagesets(selectedEntities)
                                    })
                                }}
                            />}
                        {/* -------------- Activity Popup ----------------------- */}
                        <ActivityPopup
                            item={this.state.itemName}
                            open={this.state.showProcessPopup}
                            icon={this.state.processPopupIcon}
                            action={this.state.processAction}
                            msg={this.state.processMessage}
                            close={() => this.closeActivityPopup()}
                            height={this.state.processPopupIcon == "WARNING" ? "180px" : null}
                            onClickOk={() => this.deleteImageCollection()}
                        >
                        </ActivityPopup>
                        {/* --------------------------------------------------------------- */}
                        {/* <SnackBar user={user} sidebarMode={sidebarMode} type={type} /> */}
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "1px", width: "100%", alignItems: 'center' }}>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <div style={{ display: "flex" }}>
                                    <DropDownWithButton
                                        // width="110px"
                                        selectedItem={this.state.selectedItem}
                                        list={this.state.itemsList.map((e) => { return ({ name: e.label, onClick: () => { this.onChangeImagesetType([e]) } }) })}
                                    />
                                    <span className='data-count'>({this.state.collections.length})</span>
                                </div>
                            </div>
                            <div style={{ display: "flex" }}>
                                {this.state.filter == "Processed" && this.state.collections.length > 0 ? <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }}>
                                    <div className="add-user-form-text-wrapper" style={{ marginRight: "20px", filter: this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ ? "" : "grayscale(100%)", opacity: this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ ? "1" : "0.5" }}>
                                        {this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ ? <Link
                                            to={{
                                                pathname: "/dronenaksha/imagesets-on-map",
                                                state: {
                                                    type,
                                                    user,
                                                    plan: this.state.plan,
                                                    project: this.state.project,
                                                },
                                            }}
                                        >
                                            <div className="addcustomer-button-empty-dashboard" style={{ paddingLeft: '20px', paddingRight: '20px' }}>View on Map</div>
                                        </Link> : <div className="addcustomer-button-empty-dashboard" style={{ paddingLeft: '20px', paddingRight: '20px', cursor: "default" }}>View on Map</div>}
                                    </div>
                                </button> : <></>}
                                {this.state.isOwner ?
                                    <div style={{ filter: this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].CREATE ? "" : "grayscale(100%)" }}>
                                        <DropDownWithButton
                                            icon={whitePlus}
                                            isButton
                                            isBtnActive
                                            selectedItem={"Imageset"}
                                            isBtnList
                                            isDisabled={!this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].CREATE}
                                            textColor={"white"}
                                            list={
                                                [
                                                    {
                                                        name: "Import",
                                                        onClick: () => this.setState({ showEntitySelector: true })
                                                    },
                                                    {
                                                        name: "Create",
                                                        onClick: () => {
                                                            this.setState((state) => ({
                                                                ...state,
                                                                showAddImagePopup: true,
                                                            }))
                                                        },

                                                    }
                                                ]
                                            }

                                        />
                                    </div>
                                    :
                                    <></>}
                                <div className="add-user-form-text-wrapper" style={{ display: "flex", alignItems: 'center', }} >
                                    <div
                                        onClick={() => {
                                            this.setState((state) => ({
                                                ...state,
                                                gridView: !state.gridView,
                                            }))
                                        }}>
                                        <img src={this.state.gridView ? GridIcon : ListIcon} style={{ height: '21px', width: '21px', cursor: 'pointer', margin: '0px 4px 0px 14px' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.state.collections.length > 0 && this.state.gridView ? <>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: '25px', width: '100%' }}>
                                <div style={{ width: "99.5%", borderTop: "1px solid rgba(102, 102, 102, 0.2)" }} />
                            </div>
                            {this.state.collections.length > 0 && !this.state.loading ?
                                <div style={{ height: "30px" }}>
                                    <SortComponent
                                        label={'Name'}
                                        sortAscending={this.sortByName}
                                        sortDecending={this.reverseByName}
                                        sortASC={true}
                                    />
                                </div>
                                : <></>}
                        </> : <></>}

                        {collections.length > 0 ? this.state.gridView ?
                            <div className="aerocapture-cards-tray-projects scroll-container" style={{ paddingTop: "10px", gridTemplateColoumn: "repeat(auto-fill, minmax(190px, 200px))" }}>
                                {

                                    collections.map((collection, key) => {
                                        return (
                                            <div>
                                                <Link to={{
                                                    pathname: `/dronenaksha/images`,
                                                    state: {
                                                        user, type, collection,
                                                        sidebarMode: this.state.sidebarMode,
                                                        plan: this.props.location.state.plan,
                                                        project: this.props.location.state.project,
                                                    }
                                                }}>
                                                    {<div key={key} className="customer-cards-dashboard card" style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)', marginBottom: "30px" }}>
                                                        <div className="card-header customer-cards-dashboard-logo project-card-header" style={{
                                                            // backgroundImage=`url(${'./assets/map_placeholder.png'})`
                                                        }}>
                                                            {
                                                                collection && collection.file && collection.file.file_name && collection.thumbURL ?
                                                                    <img id="ProjectImageRPM"
                                                                        onError={(e) => { e.currentTarget.src = ImageSetThumb }}
                                                                        style={{ objectFit: 'cover' }}
                                                                        src={collection.thumbURL} /> :
                                                                    < img id='ProjectImageRPM' src={ImageSetThumb} />
                                                                // <img id="ProjectImage" src={thumb} />
                                                            }
                                                            <div>
                                                                <DeleteComponentGrid
                                                                    style={{ position: "absolute", top: "5px", right: "5px" }}
                                                                    canDownload={this.state.isOwner && this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].DOWNLOAD && collection.file}
                                                                    canRename={this.state.isOwner && this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].EDIT}
                                                                    canDuplicate={this.state.isOwner && this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].CREATE && collection.file}
                                                                    disableDelete={!this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].DELETE}
                                                                    onClickDelete={(e) => {
                                                                        e.preventDefault()
                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            selectedCollectionId: collection.id,
                                                                            deleteItem: "IMAGESET",
                                                                            itemName: "Imageset",
                                                                            processPopupIcon: "WARNING",
                                                                            processAction: "DELETE",
                                                                            processMessage: "All data including orthomosaic images, tasks, annotations, gcp points, images will be deleted." +
                                                                                "\n Are you sure you want to delete this Imageset ?",
                                                                            showProcessPopup: true,
                                                                        }))
                                                                    }}
                                                                    onClickDownload={(e) => {
                                                                        API.donwloadCollectionZip(collection.id, collection.collection_name)
                                                                    }}
                                                                    onClickRename={(e) => {
                                                                        e.preventDefault()
                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            selectedCollectionId: collection.id,
                                                                            renameCollection: true,
                                                                            collection_name: collection.collection_name
                                                                        }))
                                                                    }}
                                                                    onclickDuplicate={(e) => {
                                                                        e.preventDefault()
                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            showCopyImagePopup: true,
                                                                            oldCollectionId: collection.id,
                                                                            newCollectionName: `${collection.collection_name}Copy`,
                                                                        }))
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div style={{ paddingTop: "3px !important" }}
                                                            className="card-body change-color customer-cards-dashboard-text project-card-body"
                                                        >
                                                            <div>
                                                                <span>{
                                                                    collection.collection_name.length > 14 ?
                                                                        `${collection.collection_name.substring(0, 13)}...` :
                                                                        `${collection.collection_name}`
                                                                }</span>
                                                                <div style={{ display: "flex", flexDirection: "row", fontSize: "10px", justifyContent: "space-between", }}>
                                                                    <span>{
                                                                        collection && collection.file ? collection.file.count : '0'
                                                                    }</span>
                                                                    <span>{
                                                                        (() => {
                                                                            let date = (new Date(collection.created_on)).toLocaleDateString('en-US')
                                                                            return `${dateFormat(date, "dd-mmm-yyyy")}`
                                                                        })()
                                                                    }</span>
                                                                </div>
                                                            </div>
                                                            {/*<div style={{ height: "24px", overflow: "hidden",fontSize:'14px' }}>{project.name+".jpg"}</div>*/}
                                                        </div>
                                                    </div>}
                                                </Link>
                                            </div>)
                                    })
                                }
                            </div> :
                            <div id='table' className='scroll-container' style={{ marginTop: '30px', height: "76vh", overflow: 'auto' }}>
                                <table className="customers-table" style={{ marginTop: '0px' }}>
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '30%' }} />
                                    <col style={{ width: '25%' }} />
                                    <col style={{ width: '25%' }} />
                                    <col style={{ width: '5%' }} />
                                    <tr className="customers-table-header-row" style={{ background: "#EAEAEA" }}>
                                        <th></th>
                                        <th>Name
                                            <SortComponent
                                                style={{ marginLeft: '5px' }}
                                                sortAscending={this.sortByName}
                                                sortDecending={this.reverseByName}
                                                sortASC={false}
                                            />
                                        </th>
                                        <th>Images</th>
                                        <th>Date Created
                                            <SortComponent
                                                style={{ marginLeft: '5px' }}
                                                sortAscending={this.sortByDate}
                                                sortDecending={this.reverseByDate}
                                                sortASC={false}
                                            />
                                        </th>
                                        <th></th>
                                    </tr>
                                    {
                                        this.state.collections.map((collection, i) => {
                                            return collection && collection.collection_name ?
                                                <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                    <td>
                                                        {
                                                            collection && collection.thumbURL ?
                                                                <img id="ProjectTableImage"
                                                                    style={{ objectFit: 'cover', padding: 'unset' }}
                                                                    loading="true"
                                                                    src={collection.thumbURL}
                                                                    onError={(e) => { e.target.onerror = null; e.target.style = { undefined }; e.target.src = ImageSetThumb }} /> :
                                                                <img id="ProjectTableImage" src={ProjectThumb} />
                                                            // <img id="ProjectImage" src={thumb} />
                                                        }
                                                    </td>
                                                    <td>
                                                        <Link
                                                            style={{ color: "#707683" }}
                                                            to={{
                                                                pathname: `/dronenaksha/images`,
                                                                state: {
                                                                    user, type, collection,
                                                                    sidebarMode: this.state.sidebarMode,
                                                                    plan: this.props.location.state.plan,
                                                                    project: this.props.location.state.project,
                                                                }
                                                            }}
                                                        >
                                                            {collection.collection_name}<span style={{ color: "#2989cf" }}>{collection && collection.sharedData && collection.sharedData.isShared ? ' (Shared)' : ''}</span>
                                                        </Link>
                                                    </td>
                                                    <td>{collection && collection.file ? collection.file.count : '0'}</td>
                                                    <td>{
                                                        (() => {
                                                            let date = (new Date(collection.created_on)).toLocaleDateString('en-US')
                                                            return `${dateFormat(date, "dd mmm yyyy")}`
                                                        })()
                                                    }</td>
                                                    <td>
                                                        <DeleteComponentTable
                                                            style={{ margin: "0 20px" }}
                                                            canRename={this.state.isOwner && this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].EDIT}
                                                            canDownload={this.state.isOwner && this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].DOWNLOAD && collection.file}
                                                            canDuplicate={this.state.isOwner && this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].CREATE && collection.file}
                                                            disableDelete={!this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].DELETE}
                                                            onClickDelete={(e) => {
                                                                e.preventDefault()
                                                                this.setState((state) => ({
                                                                    ...state,
                                                                    selectedCollectionId: collection.id,
                                                                    deleteItem: "IMAGESET",
                                                                    itemName: "Imageset",
                                                                    processPopupIcon: "WARNING",
                                                                    processAction: "DELETE",
                                                                    processMessage: "All data including orthomosaic images, tasks, annotations, gcp points, images will be deleted." +
                                                                        "\n Are you sure you want to delete this Imageset ?",
                                                                    showProcessPopup: true,
                                                                }))
                                                            }}
                                                            onClickRename={(e) => {
                                                                e.preventDefault()
                                                                this.setState((state) => ({
                                                                    ...state,
                                                                    selectedCollectionId: collection.id,
                                                                    renameCollection: true,
                                                                    collection_name: collection.collection_name
                                                                }))
                                                            }}
                                                            onClickDownload={(e) => {
                                                                API.donwloadCollectionZip(collection.id, collection.collection_name)
                                                            }}
                                                            onclickDuplicate={(e) => {
                                                                e.preventDefault()
                                                                this.setState((state) => ({
                                                                    ...state,
                                                                    showCopyImagePopup: true,
                                                                    oldCollectionId: collection.id,
                                                                    newCollectionName: `${collection.collection_name}Copy`,
                                                                }))
                                                            }}
                                                        />
                                                    </td>
                                                </tr> : ''
                                        }
                                        )}
                                </table>
                            </div> :
                            <div className="rmp-dashboard-empty-data-message" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                                <img style={{ height: '160px', width: '160px' }} src={Noimageset} />
                                <p style={{ marginTop: '10px' }}>No Imagesets available</p>
                            </div>
                        }
                    </div>}
                </div>
            </div > : <div />
        );
    }
}
const mapStateToProps = state => {
    return {
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
        SubscriptionData: state.subscription.subscriptionData,
    };
};

export default connect(mapStateToProps, null)(AeroCaptureImageCollections);

