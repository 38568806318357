import {ADD_PROJECT_DETAILS} from './projectTypes'
import {ADD_PLAN} from './projectTypes'
import{UPDATE_UNIT_CONVERSION} from './projectTypes'
import{ADD_URL} from './projectTypes'
export const addProjectDetails = (payload)=>{
    return{
        type:ADD_PROJECT_DETAILS,
        payload:payload
    }
}
export const addPlan = (payload)=>{
    return{
        type:ADD_PLAN,
        payload:payload
    }
}
export const updateUnit =(payload)=>{
    return{
        type:UPDATE_UNIT_CONVERSION,
        payload:payload
    }
}

export const addURL =(payload)=>{
    return{
        type:ADD_URL,
        payload:payload
    }
}