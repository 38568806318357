import React, { Component } from 'react';
import waiting from '../res/waiting.png';
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import * as API from '../api.js';
import service from "../sidebaricon/Services.png";
import info from "../icons/info1.png"
import MyProfile from "./MyProfile";
import Invoices from "../Invoices";
import EditUser from "./editUser";
import MgmtSettings from "./MgmtSettings";
import { Line, Circle } from 'rc-progress'
import SettingsActive from '../sidebaricon/SettingsActive.png';
import { Link } from 'react-router-dom';
import SideBar from '../SideBar';
import dateFormat from 'dateformat';
import { checkExpiry, addUserAnalytic, updateOutTime } from '../ReusableComponents/reusableFunctions.js';
import { updateProfileDetails, activesubscription, updateSubscriptionData } from '../redux';
import Help from "../sidebaricon/Help.png";
import CreditsDetails from '../ReusableComponents/CreditsDetails';
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon';
import AppConstants from '../AppConstants';
import BuyDrone from '../ReusableComponents/BuyDrone';
import AdjustQuantityPopup from '../subscription/Reusable/AdjustQuantityPopup';
import { ActivityPopup } from '../ReusableComponents/ActivityPopup';
import ChangeMobileNumber from '../userAuth/ChangeMobileNumber.js';
export const BUY_NEW_DRONES = "BUY_NEW_DRONES"
export const BUY_MORE_DRONES = "BUY_MORE_DRONES"
export const CANCEL_DUE_PAYMENT = "CANCEL_DUE_PAYMENT"
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION"
export const CANCEL_DRONE_ADDON = "CANCEL_DRONE_ADDON"
class ProfileSettings extends Component {

    state = {
        subscriptions: [],
        user: {},
        type: "",
        avatarURL: '',
        createIcon: waiting,
        showCancelSubsPopup: false,
        cancelSubsText: "Are you sure you want to cancel subscription ?",
        org: {},
        models: [],
        model: {},
        outId: "",
        loading: false,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        subscriptionTitleMap: {
            "aerogcs_plan_pro": "AeroMegh Pro",
            "aerogcs_plan_free": "AeroMegh Free",
            "aeromegh_plan_pro": "AeroMegh Pro",
            "aeromegh_plan_free": "AeroMegh Free",
            "aerostream_plan_starter": "AeroStream Starter",
            "aerostream_plan_pro": "AeroStream Pro"
        },
        onPage: 1,
        pendingPayments: [],

    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

   
    toggleTwoStepAuth = (isVerified, phone) => {
        if (this.state.user.otp_flag || isVerified || (this.state.user.phone && this.state.user.phone_verified)) API.updateTwoStepAuth(!this.state.user.otp_flag)
            .then(data => {
                this.setState({
                    user: {
                        ...this.state.user,
                        otp_flag: !this.state.user.otp_flag,
                        phone_verified: isVerified || this.state.user.phone_verified,
                        phone: phone || this.state.user.phone
                    }, phoneVerificationPopup: false
                })
            })
            .catch(e => {
                alert("Something went wrong while upating auth settings.")
            })
        else this.setState({
            phoneVerificationPopup: true
        })
    }

    cancelSubscription = () => {
        API.cancelSubscription(this.state.selectedSubs.user_sub_id).then((data) => {
            this.setState((state) => ({
                ...state,
                canceling: false,
                canceled: true,
                cancelSubsText: data.message,
                showQuantityAdjustPopup: false,
            }), () => {

                API.getUserSubscriptionPlan().then((sub) => {
                    if (sub.plans) {
                        this.props.updateSubscriptionData({
                            subscriptionData: sub
                        })
                    } else {
                        this.props.updateSubscriptionData({
                            subscriptionData: undefined
                        })
                    }
                    if (this.state.upgrade) {
                        setTimeout(() => {
                            this.props.history.push("/aeromegh_plans", { user: this.state.user, type: this.state.type, serviceName: this.state.selectedSubs.service_name })
                        }, 3000)
                    }
                    this.getPendingPayments()

                }).catch(err => {
                    // this.props.updateSubscriptionData({
                    //     subscriptionData: undefined
                    // })
                    console.log(err)
                })
            })
        }, (e) => {
            console.log(e);
        })
    }
    handleCancelDuePayment = async () => {
        this.setState({ quantityData: this.handleQuantityData("Cancelled") }, () => {
            fetch(`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_PORT}/cancelDuePayment/${this.state.quantityData.id}/${this.state.quantityData?.price_id}?email=${this.state.user.username}&quantity=${1}`, { method: "POST" }).then(res => {
                this.setState((state) => ({
                    ...state,
                    canceling: false,
                    canceled: true,
                    cancelSubsText: 'Your drone add-on payment has been cancelled successfully'
                    ,
                }), () => {
                    this.getPendingPayments()
                })
            }).catch(err => console.log(err))
        })

    }
    handleQuantityData = (status) => {
        if (this.state.plans.length > 0) {
            const droneAddonList = this.state.plans.filter(pl => pl.service === AppConstants.SERVICES.DRONE_CONTROL_CENTER) //all the list of plans with drone control center as serivce
            for (const drone of droneAddonList) {
                for (const userPlan of this.props.SubscriptionData.plans) { // all the list of user plans
                    if (drone.id === userPlan.plan_id && userPlan.service_name === AppConstants.SERVICES.DRONE_CONTROL_CENTER && userPlan.subscription_status === 'Active') //find plan which is cancelled or activer
                    {
                        return { ...drone, ...userPlan }
                    }
                }
            }

        }
    }
    getAeromeghPlans = () => {
        API.getAeromeghPlans().then(res => {
            if (res.plans.length > 0) {
                this.setState((state) => ({
                    ...state,
                    plans: res.plans
                }))
            } else {
                return 0
            }

        }).catch(e => {
            console.log(e)
        })
    }

    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }


    componentWillMount() {
        addUserAnalytic(this.props.location.pathname)
            .then(id => {
                console.log("ID : ", id)
                this.setState((state) => ({
                    outId: id
                }))
            })
        document.title = "My Profile - AeroMegh";
        this.refreshUser().then(user => {
            this.getPendingPayments(false, true)
            if (this.props.location.state) {
                let { type, organizationID, modelNumber, view } = this.props.location.state;
                if (view) this.changeView(view)
                if (!user.sub) {
                    user.sub = {
                        "plan": "aeromegh_plan_free"
                    }
                }
                if (user.avatar_url && user.avatar_url.length > 0) {
                    this.setState((state) => ({
                        ...state,
                        avatarURL: `${user.avatar_url}?${user.sas_token}&t${new Date().getTime()}`
                    }))

                } else {
                    let avatarURL = `/images/${user.first_name[0].toLowerCase()}.PNG`
                    this.setState((state) => ({
                        ...state,
                        avatarURL: avatarURL
                    }))
                }
                this.setState((state) => ({
                    ...state,
                    type,
                    organizationID,
                    modelNumber
                }), () => {
                    this.getAeromeghPlans();
                })
            } else {
                API.getUserType().then(([blah, type, user]) => {
                    if (!user.sub) {
                        user.sub = {
                            "plan": "aeromegh_plan_free"
                        }
                    }
                    this.setState((state) => ({
                        ...state,
                        type
                    }), () => {
                        this.getAeromeghPlans();
                    })
                }, (e) => {
                    window.location.replace("/login");
                })
            }
        });
    }
    refreshUser = async () => {
        return new Promise((resolve, reject) => {
            API.getUserType().then(([blah, type, user]) => {
                this.props.updateProfileDetails({
                    activeTeamId: '',
                    activeTeam: '',
                    isLoggedInUserOwner: false,
                    user: user,
                    type: type
                })
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.changeView(1)
                    resolve(user)
                })
            }, (e) => {
                window.location.replace("/login");
            })
        })
    }

    changeView = (viewPage) => {
        if (viewPage == 2)
            document.title = "My Subscriptions - AeroMegh";
        this.setState((state) => ({
            ...state,
            onPage: viewPage
        }))
    }
  
    getPendingPayments = (redirectToPaymentPage) => {
        API.getPendingPayments().then(res => {
            this.setState({ pendingPayments: res.pendingPayments, updateComponent: !this.state.updateComponent, loading: false }, () => {
                if (redirectToPaymentPage) {
                    document.getElementById('payment-link').click()

                } else {
                }
            })
        }).catch(err => {
            console.log(err)
        })
    }
    callPendingPaymentsNoRedirection = () => {
        this.getPendingPayments(false)
    }
    callPendingPaymentsRedirection = () => {

        this.getPendingPayments(true)
    }
    callPendingPaymentMultiple = async (times) => {
        if (!times) times = 1
        const arr = [...Array(times)]
        await Promise.all(arr.map(item => {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    this.getPendingPayments()
                    resolve()
                }, 2000)
            })
        }))


    }
    // componentDidUpdate(prevProp, prevState) {
    //     if (prevState.pendingPayments.length != this.state.pendingPayments.length) {
    //         if (this.state.pendingPayments.length > 0 && this.state.quantityModifyPaymentInitiated) this.setState({ quantityModifyPaymentInitiated: false })
    //     }
    // }
    handleTabSwitch = () => {
        if (!this.state.paymentIntiated) return
        if (typeof document.hidden !== "undefined") {
            // Set the name of the hidden property and the change event
            const hiddenProperty = "hidden" in document ? "hidden" : "webkitHidden";
            const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, "visibilitychange");

            // Add an event listener for visibility change
            document.addEventListener(visibilityChangeEvent, async () => {
                if (document[hiddenProperty]) {
                    await this.callPendingPaymentMultiple(3)

                    // Tab is switched or the window is minimized
                } else {
                    await this.callPendingPaymentMultiple(3)
                    // Tab is active or the window is maximized
                }
            });
        } else {
            // Page Visibility API is not supported
        }

    }
    redirectToPayment = (qtyInc) => {
        this.setState({ paymentIntiated: true, }, async () => {
            await this.callPendingPaymentMultiple(5)
            this.callPendingPaymentsRedirection()
            qtyInc && this.setState({ showProcessPopup: false, processMsg: "", processIcon: "" })
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.paymentIntiated !== this.state.paymentIntiated && this.state.paymentIntiated) this.handleTabSwitch()
    }
    componentDidMount() {
        this.handleTabSwitch()

    }

    render() {

        let { user, type, model, sidebarMode } = this.state
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: SettingsActive,
                    text: "Settings",
                    path: "/profile-settings",
                    selected: true,
                },
                {
                    icon: Help,
                    text: "Help",
                    path: "/feedback",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode,
        }
        let viewerMinutesUsagePercent = null
        if (this.state.viewMinutesStats) {

            if (this.state.viewMinutesStats.quota === 0) {
                viewerMinutesUsagePercent = 100
            } else {
                viewerMinutesUsagePercent = (this.state.viewMinutesStats.used / this.state.viewMinutesStats.quota) * 100
            }

        }
        return (
            user.username ? (<div className="wrapper">
                <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    style={{ overflowY: "hidden", height: "100%" }}
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <ActivityPopup
                        title={this.state.processTitle}
                        open={this.state.showProcessPopup}
                        icon={this.state.processIcon}
                        msg={this.state.processMsg}
                        close={() => {
                            switch (this.state.processOkAction) {
                                case CANCEL_DUE_PAYMENT:
                                    this.setState({ showProcessPopup: false })
                                    break;
                                case BUY_MORE_DRONES:
                                    this.redirectToPayment(true)
                                    break;
                                default: this.setState({ showProcessPopup: false })
                                    break;
                            }
                        }}
                        onClickOk={() => {
                            switch (this.state.processOkAction) {
                                case CANCEL_DUE_PAYMENT:
                                    this.handleCancelDuePayment()
                                    this.setState({ showProcessPopup: false })
                                    break;
                                case BUY_MORE_DRONES:
                                    this.redirectToPayment(true)
                                    break;
                                default: break;
                            }
                        }}
                    />
                    {this.state.phoneVerificationPopup && <ChangeMobileNumber
                        onMobileNumberChanged={(phone) => { this.toggleTwoStepAuth(true, phone) }}
                        onClose={() => this.setState({
                            phoneVerificationPopup: false
                        })}
                        user={user} phone={user.phone || ""} />}
                    <a id='payment-link' href={this.state.pendingPayments?.[0]?.payment_link} target='_blank'></a>
                    {this.state.showBuyDronePopup && <BuyDrone
                        user={this.state.user}
                        pendingPayments={this.state.pendingPayments}
                        show={this.state.showBuyDronePopup}
                        firstTime={this.state.buyingDroneFirstTime}
                        onRequestClose={() => this.setState({ showBuyDronePopup: false })}
                        setActivityPopup={(activityPopup) => {
                            this.setState({
                                ...activityPopup
                            })
                        }}
                        callback={async () => {
                            // this.redirectToPayment(true)
                        }} actvityPopup={this.state.activityPopup} />}
                    {this.state.showQuantityAdjustPopup && <AdjustQuantityPopup
                        pendingPayments={this.state.pendingPayments}
                        show={this.state.showQuantityAdjustPopup}
                        onRequestClose={() => this.setState({ showQuantityAdjustPopup: false })}
                        dronePlan={this.state.quantityData}
                        user={this.state.user}
                        callback={() => {
                            this.redirectToPayment()
                        }}
                        setActivityPopup={(activityPopup) => {
                            this.setState({ ...activityPopup })
                        }}
                    />}
                    <Modal
                        style={{ marginTop: '150px' }}
                        show={this.state.showCancelSubsPopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                canceling: false,
                                canceled: false,
                                showCancelSubsPopup: false,
                                cancelSubsText: 'Are you sure you want to cancel subscription ?',
                                upgrade: undefined
                            }));
                        }}
                    >
                        <Modal.Body >
                            <p>{this.state.cancelSubsText}
                                {this.state.upgrade && this.state.canceled ? ", Redirecting to Buy Plan..." : <></>}</p>
                        </Modal.Body>
                        {this.state.canceling ? <></> : <>{this.state.canceled ? (this.state.upgrade ? <></> : <Modal.Footer>
                            <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        canceling: false,
                                        canceled: false,
                                        cancelSubsText: 'Are you sure you want to cancel subscription ?',
                                        showCancelSubsPopup: false,
                                        cancelOkAction: ""
                                    }))
                                }}
                            >
                                Ok
                            </button>
                        </Modal.Footer>) : <Modal.Footer>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        showCancelSubsPopup: false,
                                        canceling: false,
                                        canceled: false,
                                        cancelSubsText: 'Are you sure you want to cancel subscription ?',
                                        upgrade: undefined,
                                        cancelOkAction: ""
                                    }));
                                }}
                                style={{ paddingLeft: "28px", paddingRight: "28px" }}
                            >
                                No
                            </button>
                            <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        canceling: true,
                                        cancelSubsText: this.state.cancellingSubText,
                                    }), () => {
                                        switch (this.state.cancelOkAction) {

                                            case CANCEL_DUE_PAYMENT:
                                                this.handleCancelDuePayment();
                                                break;
                                            case CANCEL_SUBSCRIPTION:
                                                this.cancelSubscription()
                                                break;
                                            case CANCEL_DRONE_ADDON:
                                                this.cancelSubscription()
                                                break;
                                            case BUY_NEW_DRONES:
                                                this.setState({
                                                    showCancelSubsPopup: false,
                                                    canceling: false,
                                                    canceled: false,
                                                    showBuyDronePopup: true,
                                                    buyingDroneFirstTime: this.props.SubscriptionData.plans.find(plan => plan.service_name == AppConstants.SERVICES.DRONE_CONTROL_CENTER && plan.subscription_status == "Active") ? false : true
                                                })
                                            default: break;
                                        }
                                    })
                                }}
                            >
                                Yes
                            </button>
                        </Modal.Footer>}</>
                        }
                    </Modal>
                    <div className="top-bar"><div className="top-bar-text"> PROFILE SETTING </div></div>
                    <div style={{ display: 'flex', height: "100%", justifyContent: "left" }}>
                        <div style={{ width: '15%', marginTop: "5%", marginLeft: "3%" }}>
                            <div style={{ marginBottom: "10px" }}>
                                <div>
                                    <div className={this.state.onPage == 1 || this.state.onPage == 0 ? "active-text" : "inactive-text"} onClick={() => this.changeView(1)}>Profile</div>
                                    <div className={this.state.onPage == 2 ? "active-text" : "inactive-text"} onClick={() => this.changeView(2)}>Subscriptions</div>
                                    <div className={this.state.onPage == 3 ? "active-text" : "inactive-text"} onClick={() => this.changeView(3)}>Invoices</div>
                                    <div className={this.state.onPage == 4 ? "active-text" : "inactive-text"} onClick={() => this.changeView(4)}>Change Password</div>
                                    {/* <div className={this.state.onPage ==5 ? "active-text" :"inactive-text"} onClick={()=>this.changeView(5)}>Streaming Statistics</div> */}
                                </div>
                            </div>
                        </div>
                        <div style={{ borderRadius: "0px", height: "85vh", marginTop: "2%", width: 'max-content', overflow: "auto" }} className="box-shadow-1" >
                            {this.state.onPage == 0 ?
                                <div>
                                    <EditUser
                                        user={user} type={type} first_name={user.first_name}
                                        last_name={user.last_name} email={user.username} address={user.address}
                                        phone={user.phone} userid={user.userid} country={user.country}
                                        avatarURL={user.avatar_url} sas_token={user.sas_token}
                                        cancel={() => this.changeView(1)}
                                        save={() => this.refreshUser()}>
                                    </EditUser>
                                </div>
                                : this.state.onPage == 1 ?
                                    <div>
                                        <MyProfile toggleTwoStepAuth={this.toggleTwoStepAuth} edit={() => this.changeView(0)} user={user} type={type}></MyProfile>
                                    </div>
                                    : this.state.onPage == 2 ?
                                        <div style={{ height: "100%" }}>
                                            <div className="top-bar"><div className="top-bar-text">Subscriptions </div></div>


                                            <div style={{ overflowY: "auto", height: "calc(100% - 40px)" }}>
                                                <div style={{ width: "65vw", padding: "0px 70px 20px 70px" }} className="rpas-model-info-bottom-section">


                                                    {/* PENDING PAMENTS FOR ADDONS */}
                                                    {this.state.pendingPayments.length > 0 && <div style={{ width: "100%", marginTop: "3%" }}>
                                                        <div style={{ fontWeight: "15px", fontWeight: "500" }}>Pending Payments</div>
                                                        <table style={{ marginTop: "1%" }} className="customers-table">
                                                            <col style={{ width: '25%' }} />
                                                            <col style={{ width: '15%' }} />
                                                            <col style={{ width: '15%' }} />
                                                            <col style={{ width: '15%' }} />
                                                            <tr className="customers-table-header-row" style={{ position: "initial" }}>
                                                                <th style={{ position: "initial" }}>Your Plan</th>
                                                                <th style={{ position: "initial" }}>Amount</th>
                                                                <th style={{ position: "initial" }}>Status</th>
                                                                <th style={{ position: "initial" }}></th>
                                                                <th style={{ position: "initial" }}></th>
                                                            </tr>
                                                            {
                                                                this.state.pendingPayments.map((payment, i) => {
                                                                    return <tr className="customers-table-data-row">
                                                                        <td>
                                                                            Aero GCS Drone Add on
                                                                        </td>
                                                                        <td style={{ textTransform: 'capitalize' }}>
                                                                            {`${payment.currency === "inr" ? "₹" : "$"} ${payment.amount}`}
                                                                        </td>
                                                                        <td style={{ color: "red" }}>{payment.payment_status}</td>
                                                                        <td style={{ textTransform: 'capitalize' }}><a target='blank' onClick={() => this.setState({ paymentIntiated: true })} href={payment.payment_link}>
                                                                            Pay
                                                                        </a></td>
                                                                        <td style={{ textTransform: 'capitalize' }} >
                                                                            <span onClick={() => this.setState({
                                                                                showCancelSubsPopup: true,
                                                                                cancelSubsText: "Do you really want to cancel payment for additional drones?",
                                                                                cancelOkAction: CANCEL_DUE_PAYMENT,
                                                                                cancellingSubText: "Cancelling payment,please wait..."
                                                                            })} style={{ cursor: "pointer", color: "#2989cf" }}>Cancel</span>
                                                                        </td>

                                                                    </tr>
                                                                })

                                                            }

                                                        </table>
                                                    </div>}


                                                    {/* SUBSCRIPTIONS  */}
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        marginTop: "20px",
                                                        marginBottom: "0px",
                                                    }}>
                                                        <div style={{ fontWeight: "500", fontSize: "15px" }}>Subscription plans</div>
                                                        {/* if user has any active drone add on dont show buy btn */}
                                                        {/* {this.props.SubscriptionData.plans.find(plan => plan.service_name == AppConstants.SERVICES.DRONE_CONTROL_CENTER && plan.subscription_status == "Active") //if user has purchased drone add on before dont show buy gcs credits btn again
                                                        ? <></>
                                                        : */}
                                                        <div >
                                                        </div>
                                                        {/* } */}
                                                    </div>

                                                    <table style={{ marginTop: "10px" }} className="customers-table">
                                                        <col style={{ width: '25%' }} />
                                                        <col style={{ width: '15%' }} />
                                                        <col style={{ width: '15%' }} />
                                                        <col style={{ width: '15%' }} />
                                                        <col style={{ width: '15%' }} />
                                                        <col style={{ width: '15%' }} />
                                                        <tr className="customers-table-header-row">
                                                            <th>Your Plan</th>
                                                            <th>Billing</th>
                                                            <th>Service</th>
                                                            <th>Status</th>
                                                            <th style={{ minWidth: '130px' }}>Expires On</th>
                                                            <th></th>
                                                        </tr>
                                                        {
                                                            this.props.SubscriptionData.plans && this.props.SubscriptionData.plans.length > 0 ?
                                                                this.props.SubscriptionData.plans.filter(pln => (pln.service_name != AppConstants.SERVICES.AEROGCS && pln.service_name != AppConstants.SERVICES.DRONE_CONTROL_CENTER))?.map((plan, i) => {
                                                                    return <tr className="customers-table-data-row">
                                                                        <td>{plan.plan_name}
                                                                        </td>
                                                                        <td style={{ textTransform: 'capitalize' }}>{plan.plan_type}
                                                                        </td>
                                                                        <td>{plan.service_name}</td>
                                                                        <td style={{
                                                                            textTransform: 'capitalize',
                                                                            color: checkExpiry(plan.expiry_date) ? "#8b8989" : (plan.subscription_status === "Active" ? "#49ad49" : plan.subscription_status === "Cancelled" ? "#ff0000b5" : "#8b8989")
                                                                        }}>
                                                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                                <div>{checkExpiry(plan.expiry_date) ? "Expired" : plan.subscription_status}</div>
                                                                                {/* show eye btn if subscription is cannceled to show info */}
                                                                                {plan.subscription_status === "Cancelled" && !checkExpiry(plan.expiry_date) &&
                                                                                    <div style={{ width: "10px", cursor: "pointer", opacity: "0.6", display: "flex", alignItems: "center" }}>
                                                                                        <img title='Your subscription will not be renewed from next month' style={{ width: "100%" }} src={info} />
                                                                                    </div>}
                                                                            </div>
                                                                        </td>
                                                                        < td style={{ minWidth: '130px', textAlign: 'left' }}>{dateFormat(plan.expiry_date, "dd-mm-yyyy")}</td>
                                                                        <td>
                                                                            {plan.subscription_status === 'Active' && (plan.plan_type == "Yearly" || plan.plan_type == "Monthly") && (plan.service_name !== AppConstants.SERVICES.DRONE_CONTROL_CENTER) ?
                                                                                <> <span style={{ height: '30px', marginRight: "10px", cursor: 'pointer', color: '#2989cf' }}
                                                                                    onClick={() => {
                                                                                        this.setState((state) => ({
                                                                                            ...state,
                                                                                            showCancelSubsPopup: true,
                                                                                            selectedSubs: plan,
                                                                                            cancelSubsText: "If you want to modify, existing active subscription will be cancelled, would you like to continue ?",
                                                                                            cancelOkAction: CANCEL_SUBSCRIPTION,
                                                                                            cancellingSubText: "Cancelling subscription please wait...",
                                                                                            upgrade: true
                                                                                        }))
                                                                                    }}
                                                                                >Modify</span>

                                                                                    <span style={{ height: '30px', cursor: 'pointer', color: '#2989cf' }}
                                                                                        onClick={() => {
                                                                                            this.setState((state) => ({
                                                                                                ...state,
                                                                                                showCancelSubsPopup: true,
                                                                                                cancelSubsText: 'Are you sure you want to cancel subscription ?',
                                                                                                cancelOkAction: CANCEL_SUBSCRIPTION,
                                                                                                cancellingSubText: "Cancelling subscription please wait...",
                                                                                                selectedSubs: plan
                                                                                            }))
                                                                                        }}
                                                                                    >Cancel</span>
                                                                                </>
                                                                                : plan.service_name === AppConstants.SERVICES.DRONE_CONTROL_CENTER ?
                                                                                    <>
                                                                                        {plan.subscription_status === "Active" ? <>
                                                                                            <span style={{ height: '30px', marginRight: "10px", cursor: 'pointer', color: '#2989cf' }} onClick={async () => {
                                                                                                this.setState({ showQuantityAdjustPopup: true, quantityData: this.handleQuantityData("Active"), selectedSubs: plan })
                                                                                                await this.callPendingPaymentMultiple(1)
                                                                                            }}>
                                                                                                Modify
                                                                                            </span>

                                                                                            <span style={{ height: '30px', cursor: 'pointer', color: '#2989cf' }}
                                                                                                onClick={() => {
                                                                                                    this.setState((state) => ({
                                                                                                        ...state,
                                                                                                        showCancelSubsPopup: true,
                                                                                                        cancelOkAction: CANCEL_DRONE_ADDON,
                                                                                                        cancelSubsText: "Do you really want to cancel the drone add-on?",
                                                                                                        cancellingSubText: "Cancelling drone add-on please wait...",
                                                                                                        selectedSubs: plan
                                                                                                    }))
                                                                                                }}
                                                                                            >Cancel</span>
                                                                                        </>
                                                                                            :
                                                                                            <></>
                                                                                            // 
                                                                                        }
                                                                                    </>
                                                                                    : <Link
                                                                                        to={{
                                                                                            pathname: "/aeromegh_plans",
                                                                                            state: {
                                                                                                user: this.state.user,
                                                                                                type: this.state.type,
                                                                                                serviceName: plan.service_name
                                                                                            }
                                                                                        }}>
                                                                                        <span style={{ height: '30px', marginRight: "10px", cursor: 'pointer', color: '#2989cf' }}>
                                                                                            Buy</span>
                                                                                    </Link>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                })

                                                                : <tr className="customers-table-data-row">
                                                                    <td colspan="5" style={{ textAlign: "center" }}>No Subscription Found</td>
                                                                </tr>
                                                        }

                                                    </table>
                                                </div>
                                                <div className="rpas-model-info-bottom-section" style={{ width: "65vw" }}>
                                                    <CreditsDetails user={user} updateComponent={this.state.updateComponent} />
                                                </div>
                                            </div>
                                        </div>
                                        : this.state.onPage == 3 ?
                                            <div style={{ height: "100%" }}>
                                                <Invoices user={user} type={type} />
                                            </div>
                                            : this.state.onPage == 4 ?
                                                <div>
                                                    <MgmtSettings user={user} cancel={() => this.changeView(1)} save={() => this.refreshUser()} type={type} />
                                                </div>
                                                : this.state.onPage == 5 ?
                                                    <div>
                                                        <div className="top-bar"><div className="top-bar-text">Streaming statistics </div></div>
                                                        <div style={{ width: "65vw" }} className="rpas-model-info-bottom-section">
                                                            <div className="rpas-model-info-primary-details-title-row" style={{ marginTop: "23px", padding: "0px 40px 30px 40px" }}>
                                                                <div className="services-content-title">AeroMegh Streaming Viewer Minutes Usage Stats</div>
                                                                {/* <Link className="add-user-form-submit-button" style={{ marginLeft: "auto", "textDecoration": "none" }} to={{
                                            pathname: "/aeromegh_plans",
                                            "state": this.state
                                        }}>
                                            Upgrade
                                        </Link> */}
                                                            </div>
                                                            <div style={{
                                                                marginTop: "20px",
                                                                height: "100px",
                                                                width: "100%",
                                                                display: "flex"
                                                            }}>
                                                                {viewerMinutesUsagePercent !== null ? <div style={{
                                                                    margin: "auto",
                                                                    "width": "min-content",
                                                                    height: "100%"
                                                                }}><Circle strokeColor={viewerMinutesUsagePercent > 80 ? "#dd393f" : "#2989CF"} strokeWidth="4" style={{
                                                                    height: "100%"
                                                                }} percent={viewerMinutesUsagePercent}></Circle><div style={{
                                                                    position: "relative",
                                                                    "top": "-70px",
                                                                    "fontSize": "27px",
                                                                    textAlign: "center",
                                                                    color: viewerMinutesUsagePercent > 80 ? "#dd393f" : "#2989CF"
                                                                }}>{`${parseInt(viewerMinutesUsagePercent)}%`}</div></div> : <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </div >
            ) : ''
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload)),
        activesubscription: (payload) => dispatch(activesubscription(payload)),
        updateSubscriptionData: (payload) => dispatch(updateSubscriptionData(payload))
    };
};

const mapStateToProps = state => {
    return {
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        SubscriptionData: state.subscription.subscriptionData,
        activesubscription: state.activesubscription.activesubscription,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner
    };
};

export default (connect(mapStateToProps, mapDispatchToProps)(ProfileSettings));