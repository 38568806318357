import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Button.css"
const Button = ({
    isDropDownOpen,
    text,
    title,
    icon,
    isIconLeft,
    width,
    height,
    onClick,
    isDisabled,
    isBtnActive,
    linkData,
    type,
    className,
    btnType
}) => {

    const jsx = (
        <button
            className={`new-button ${isBtnActive ? 'new-btn-active' : ""} ${type === "WARNING" ? "warning-btn" : type === "COMPLETE" ? "complete-btn" : type === "ERROR" ? "error-btn" : ""} ${isDisabled ? "new-btn-disabled" : ""} ${className}`}
            title={title || text}
            type={btnType || ""}
            onClick={(e) => {
                if (e && !linkData) e.preventDefault();
                onClick && !isDisabled && onClick();
            }}
        >
            <div
                style={{
                    display: "flex",
                    textTransform: "capitalize",
                    flexDirection: isIconLeft ? "row-reverse" : "",
                    justifyContent: "space-around",
                    alignItems: "center",
                    lineHeight: "1.4",       
                }}
            >
                <span id='new-btn-text' >{text}</span>
                {icon && text && <div id="new-icon-seperator"></div>}
                {icon && (
                    <img
                        id="new-icon"
                        className={isDropDownOpen && !isDisabled ? "rotate-new-icon" : ""}
                        alt="icon"
                        src={icon}
                    />
                )}
            </div>
        </button>
    );
    return <>{linkData && !isDisabled ? <Link to={linkData}>{jsx}</Link> : jsx}</>;
};
export default Button;