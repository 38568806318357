import React, { Component } from 'react';
import * as API from './api.js';
import { addUserAnalytic, convertInMbGB } from './ReusableComponents/reusableFunctions';
import { connect } from "react-redux";
import validator from 'validator';
import closeButton from './close-button.png';
import { SortComponent } from './ReusableComponents/SortComponent';
import { DeleteComponentTable } from './ReusableComponents/Menu';
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import CustomersInactive from './customers.png';
import Loader from './icons/loader.svg';
import UsersActive from './users.png';
import UsersStats from './res/stats.png'
import Search from "./Icon/Search.png";
import service from "./sidebaricon/Services.png";
import dateFormat from 'dateformat';
import SideBar from './SideBar';
import install from './icons/AeroGCSInactive.png'
import TaskIcon from './icons/servers.png'
import SupportIcon from './icons/support.png'
import logList from './icons/logList.png'
import { AnimatedModal } from './ReusableComponents/AnimatedModal';
import detectorIcon from './icons/detectorActive.png'
import btnAdd from './AeroCapture/assets/icons/btnAdd.svg';
import { CircularProgressbar } from 'react-circular-progressbar';
import { v4 as uuid } from "uuid"
import { DETECTOR_TYPES } from './AppConstants.js';
import DropDownWithButton from './ReusableComponents/DropDownWithButton'
import { Modal } from 'react-bootstrap';
import { SetRestrictSesssionExpire } from './helperComponents/OnIdleHandlerWrapper'

let prevLoadedData = 0;
let chunkSize = 10 * 1024 * 1024;
class PublicDetectors extends Component {
    state = {
        outId: "",
        loading: false,
        detectors: [],
        currentpage: 1,
        counts: 0,
        userperpage: 8,
        showDetectorDetails: false,
        selectedDetectorId: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        itemName: "Detector",
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        showAddDetectorPopup: false,
        detector_name: "",
        display_name: "",
        objects: "",
        detector_id: "",
        toShowProgressOf: 0,
        showProgressPopup: false,
        fileNames: [],
        detectorFileUploaded: true,
        uploadFile: false,
        uploadStarted: false,
        key: "",
        newDetectorId: '',
        uploadedCount: 0,
        totalFilesCount: 0,
        detector_type: DETECTOR_TYPES[0],
        targetFilesArr: "",
        currentFileIndex: null,
        lastUploadedFileIndex: null,
        currentChunkIndex: null,
        show: false,
        showCancelPopup: false,
        uploadedData: 0,
        uploadFileSize: 0,
        selectedUser: undefined,
        valUserName: undefined,
    }

    getUserDetectors = () => {
        this.setState((state) => ({
            tableLoading: true,
        }))
        const { userperpage, currentpage, key } = this.state
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        API.getDetectors('public', userperpage, firstpage, key === "" ? " " : key)
            .then(data => {
                this.setState((state) => ({
                    ...state,
                    detectors: data.detectors,
                    counts: data.count,
                    tableLoading: false,

                }), () => {
                    console.log("DETECTORS : ", data)
                })
            })
    }

    deleteDetector = () => {
        this.setState((state) => ({
            ...state,
            addVMPopup: false,
            itemName: "Detector",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))

        API.deleteDetector('public', this.state.selectedDetectorId)
            .then(data => {
                this.setState((state) => ({
                    ...state,
                    itemName: "Detector",
                    processPopupIcon: "COMPLETE",
                    processAction: "DELETE",
                    processMessage: null,
                    showProcessPopup: true,
                    detectors: []
                }), () => {
                    this.getUserDetectors()
                })
            }, (e) => {
                //console.log(e);
                this.setState((state) => ({
                    itemName: "Detector",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: e,
                    showProcessPopup: true,
                }))
            })
    }

    search(key) {
        this.setState((state) => ({
            ...state,
            key,
            currentpage: 1
        }),
            () => {
                this.getUserDetectors()
            }
        );
    }

    onChangeType = (e) => {

    }

    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getUserDetectors();
            });
        } else {
            console.log("Reached last page")
        }
    }

    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    this.getUserDetectors();

                });
        }
    }

    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getUserDetectors();
            });
        } else {
            console.log("Reached last page")
        }
    }

    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                this.getUserDetectors();
            });
        } else {
            console.log("Reached last page")
        }
    }

    changepage(num) {
        if (isNaN(num)) {
            return null;
        }
        else {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => this.getUserDetectors());
        }
    }

    getSelectedDetector = () => {
        let dets = this.state.detectors
        let obj = dets.find(o => o.id === this.state.selectedDetectorId)

        this.setState((state) => ({
            selectedDetector: obj,
            showDetectorDetails: true
        }))
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    openAddDetectorPopup = () => {
        let det_id = uuid()
        this.setState((state) => ({
            ...state,
            newDetectorId: det_id,
            showAddDetectorPopup: true
        }))
    }

    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.detectors.sort((a, b) => a.display_name.localeCompare(b.display_name))
        this.setState((state) => ({
            ...state,
            detectors: sort,
        }));

    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.detectors.sort((a, b) => b.display_name.localeCompare(a.display_name))
        this.setState((state) => ({
            ...state,
            detectors: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.detectors.sort((a, b) => a.date_created.localeCompare(b.date_created))
        this.setState((state) => ({
            ...state,
            detectors: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.detectors.sort((a, b) => b.date_created.localeCompare(a.date_created))
        this.setState((state) => ({
            ...state,
            detectors: sort,
        }));
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,

        }))
    }

    onChangeListener = (e) => {
        let t = e.target;
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
        console.log(t.name)
        if (format.test(e.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else
            if (e.target.value.includes(' ')) {
                // setError("Spaces are not allowed.")
            } else {
                // setError(undefined)
                this.setState({ [t.name]: t.value });
            }
    }

    displayNameHandler = (e) => {
        let t = e.target;
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
        console.log(t.name)
        if (format.test(e.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else {
            this.setState({ [t.name]: t.value });
        }
    }

    userNameHandler = (e) => {
        if (validator.isEmail(e.target.value)) {
            this.setState({
                selectedUser: e.target.value,
                valUserName: e.target.value,
                usernameError: ''
            })
        } else {
            this.setState({
                selectedUser: e.target.value,
                valUserName: undefined,
                usernameError: 'Invalid'
            })
        }
    }

    onObjectChange = (e) => {
        let t = e.target;

        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|.<>\/?]+/;

        if (format.test(e.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else {
            this.setState({ [t.name]: t.value });
        }
    }

    componentWillMount() {
        addUserAnalytic(this.props.location.pathname)
            .then(id => {
                console.log("ID : ", id)
                this.setState((state) => ({
                    outId: id
                }))
            })
        document.title = "PicStork - Public Detectors";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            console.log(this.props);
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => {
                this.getUserDetectors()
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.getUserDetectors()
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    // new code 
    onChangeListenerFile = async (event) => {
        let targetFilesArr = [event.target.files[0]]
        if (targetFilesArr.length > 0) {
            this.setState((state) => ({
                ...state,
                targetFilesArr
            }), () => {
                chunkSize = Math.round(navigator.connection.downlink * 1024 * 1024)
            });
        } else {
            this.setState({
                ...this.state,
                itemName: "Detector",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: "Incorrect File, Please choose valid extention file !",
                showProcessPopup: true,
            });
        }
    }

    onClickListenerFile = (event) => {
        event.target.value = ''
        this.setState({
            uploadedData: 0,
            uploadFileSize: 0,
        })
    }

    uploadFiles = async () => {
        if (this.state.targetFilesArr.length > 0) {
            if (this.state.currentFileIndex === null) {
                SetRestrictSesssionExpire(true)
                this.setState({
                    currentFileIndex: this.state.lastUploadedFileIndex === null ? 0 : this.state.lastUploadedFileIndex + 1,
                    toShowProgressOf: 0,
                    uploadFileSize: this.state.targetFilesArr[0]?.size,
                    show: true,
                    uploadCheckPopup: false,
                    activityPopup: undefined,
                    isCancel: false,
                    uploadedCount: 0
                });
            }
        }
    }


    close() {
        if (this.state.uploadedCount !== this.state.targetFilesArr.length) {
            this.setState((prevState) => ({
                ...prevState,
                showCancelPopup: true
            }));
        } else {
            this.setState((prevState) => ({
                ...prevState,
                show: this.state.toShowProgressOf >= 100 ? false : true,
                targetFilesArr: [],
                toShowProgressOf: 0,
                lastUploadedFileIndex: null,
                uploadedCount: 0,
                currentChunkIndex: null,
                currentFileIndex: null,
                detector_name: "",
                display_name: "",
                objects: "",
                detector_id: "",
                detector_type: DETECTOR_TYPES[0],
                selectedUser: undefined,
                valUserName: undefined,
            }));
        }
    }

    hide() {
        this.setState((prevState) => ({
            ...prevState,
            show: this.state.toShowProgressOf >= 100 ? false : true,
        }));
    }

    setUploadFailedError = (msg) => {
        this.setState({
            itemName: "Detector",
            processPopupIcon: "ERROR",
            processAction: "ADD",
            processMessage: `${msg}`,
            showProcessPopup: true,
            show: false,
            isCancel: true,
        })
    }

    readAndUploadCurrentChunk = () => {
        const file = this.state.targetFilesArr[this.state.currentFileIndex];
        if (!file) {
            return;
        } else {
            chunkSize = chunkSize > file.size ? file.size : chunkSize
            const from = this.state.currentChunkIndex * chunkSize;
            const to = from + chunkSize;
            const blob = file.slice(from, to);
            this.uploadChunk(blob, file.name, file.size, this.state.currentChunkIndex, this.state.currentFileIndex);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentChunkIndex !== this.state.currentChunkIndex && this.state.currentChunkIndex !== null && !this.state.isCancel) {
            this.readAndUploadCurrentChunk();
        }

        if (prevState.currentFileIndex !== this.state.currentFileIndex) {
            if (this.state.currentFileIndex !== null) {
                this.setState({ currentChunkIndex: 0 })
            }
        }

        if (prevState.lastUploadedFileIndex !== this.state.lastUploadedFileIndex) {
            if (this.state.lastUploadedFileIndex === null) {
                return;
            }
            const isLastFile = this.state.lastUploadedFileIndex === this.state.targetFilesArr.length - 1;
            const nextFileIndex = isLastFile ? null : this.state.currentFileIndex + 1;
            this.setState({ currentFileIndex: nextFileIndex })
        }
    }

    uploadChunk = (readerEvent, fileName, fileSize, currentChunkIndex, currentFileIndex) => {
        console.log('uplaod chunk called.');
        const data = new FormData()
        data.append('file', readerEvent)
        data.append('name', fileName)
        data.append('size', fileSize)
        data.append('currentChunkIndex', currentChunkIndex)
        data.append('totalChunks', Math.ceil(fileSize / chunkSize))
        data.append('detector_name', this.state.detector_name)
        data.append('display_name', this.state.display_name)
        data.append('detector_id', this.state.newDetectorId)
        data.append('detector_type', this.state.detector_type.name)
        data.append('objects', this.state.objects)
        if (this.state.valUserName) data.append('userName', this.state.valUserName)

        API.uploadPublicDetector(data).then(response => {
            console.log(response.status);
            response.json().then((res) => {
                const chunks = fileSize / chunkSize;
                if (response.status === 200 || response.status === 201) {
                    if (currentChunkIndex === Math.ceil(chunks - 1)) {
                        prevLoadedData = 0
                        this.setState({
                            uploadedData: fileSize,
                            uploadFileSize: fileSize,
                            lastUploadedFileIndex: currentFileIndex,
                            currentChunkIndex: null,
                            toShowProgressOf: ((currentFileIndex + 1) * 100) / this.state.targetFilesArr.length,
                            uploadedCount: (currentFileIndex + 1)
                        }, () => {
                            if (this.state.uploadedCount === this.state.targetFilesArr.length) {
                                SetRestrictSesssionExpire(false);
                            }
                        })
                    } else {
                        let fileUploadedPercentage = currentChunkIndex / chunks * 100
                        this.setState({
                            uploadedData: this.state.uploadedData + chunkSize,
                            uploadFileSize: fileSize,
                            toShowProgressOf: this.state.toShowProgressOf < 100 ? this.state.toShowProgressOf + 100 / ((this.state.targetFilesArr.length * 100) / (fileUploadedPercentage - prevLoadedData)) : 100//this.state.toShowProgressOf + (100 / ((this.state.targetFilesArr.length * 100) / fileUploadedPercentage))
                        }, () => {
                            this.setState({ currentChunkIndex: currentChunkIndex + 1 })
                            prevLoadedData = fileUploadedPercentage;
                            console.log("Upload % : ", this.state.toShowProgressOf);
                            if (this.state.toShowProgressOf < 0 || this.state.toShowProgressOf === 100) {
                                prevLoadedData = 0
                                this.setState({ toShowProgressOf: 0 });
                            }
                        })
                    }
                } else if (response.status == 500) {
                    this.readAndUploadCurrentChunk();
                } else {
                    this.setUploadFailedError(res.message || "something went wrong while uploading image.")
                }
            })
        }).catch(error => {
            this.setState({
                ...this.state,
                itemName: "Detector",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: `${error}`,
                showProcessPopup: true,
            });
            this.cancelUpload();
        });
    }

    cancelUpload = () => {
        prevLoadedData = 0;
        this.setState({
            isCancel: true,
            showCancelPopup: false,
            show: false,
            targetFilesArr: [],
            toShowProgressOf: 0,
            lastUploadedFileIndex: null,
            uploadedCount: 0,
            currentChunkIndex: null,
            currentFileIndex: null,
            uploadCheckPopup: false,
            detector_name: "",
            display_name: "",
            objects: "",
            detector_id: "",
            detector_type: DETECTOR_TYPES[0],
            selectedUser: undefined,
            valUserName: undefined,
        })
    }

    render() {
        const { user, type, userdisplay, detectors, currentpage, userperpage, counts, detector_name, display_name, objects, detector_type } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },

                {
                    icon: UsersStats,
                    text: "Statistics",
                    path: "/admin/users_stats",
                },

                {
                    icon: CustomersInactive,
                    text: "Add NPNT Customer",
                    path: "/admin/add_npnt_customer"
                },

                {
                    icon: UsersActive,
                    text: "Users",
                    path: "/admin/users",
                },

                {
                    icon: install,
                    text: "Installations",
                    path: "/admin/gcs-installation",
                },
                {
                    icon: TaskIcon,
                    text: "Tasks",
                    path: "/admin/tasks",
                },
                {
                    icon: logList,
                    text: "Task Logs",
                    path: "/admin/taskLogs",
                },
                {
                    icon: detectorIcon,
                    text: "Detectors",
                    path: "/admin/detectors",
                    selected: true
                },
                {
                    icon: SupportIcon,
                    text: "Support",
                    path: "/admin/supportEmails",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode
        }

        return (

            user.username ?
                <div className="wrapper">
                    <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} />

                    {/* ------------------ Detector Details Popup--------------------- */}
                    <AnimatedModal
                        isOpen={this.state.showDetectorDetails}
                        onRequestClose={() => {
                            this.setState((state) => ({
                                ...state,
                                showDetectorDetails: false,
                            }))
                        }}
                        height="350px"
                        width="550px"
                    >
                        <div style={{ textAlign: "center", margin: '10px 0px', height: '30px', fontSize: '22px', fontWeight: '500' }}>Detector Details</div>
                        <img
                            src={closeButton} style={{ width: "22px", height: "22px", position: "absolute", top: "15px", right: "20px", cursor: "pointer" }} onClick={() => {
                                this.setState((state) => ({
                                    ...state,
                                    showDetectorDetails: false
                                }))
                            }}></img>
                        <div style={{ height: '5px', width: '100%' }}><hr /></div>
                        {this.state.selectedDetector ? <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column", width: "95%", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey' >Detector Name</div>
                                    <div className='objectValue'>{this.state.selectedDetector.display_name.length > 30 ?
                                        this.state.selectedDetector.display_name.substring(0, 30) + "..." : this.state.selectedDetector.display_name}</div>
                                </div>
                                <div style={{ height: "60px", margin: "0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey'>Date created</div>
                                    <div className='objectValue'>{dateFormat(this.state.selectedDetector.date_created, "dd mmm yyyy")}</div>
                                </div>
                            </div>
                            <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                <div style={{ margin: '10px 5px', height: "100px", width: "100%", display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey' >Objects</div>
                                    <div className='objectValue1' style={{ width: "100%", height: "80%", textAlign: "left" }}>{this.state.selectedDetector.objects.toString()}</div>
                                </div>
                            </div>
                        </div> :
                            <></>
                        }

                    </AnimatedModal>


                    {/* -------------- Activity Popup ----------------------- */}
                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => this.deleteDetector()}
                    />
                    {/* --------------------------------------------------------------- */}

                    <AnimatedModal
                        isOpen={this.state.show}
                        onRequestClose={() => this.hide()}
                        height="300px"
                        width="450px"
                    >
                        <div>
                            <div style={{ textAlign: 'center', marginTop: '15px' }}>{this.state.toShowProgressOf == 100 ? "Upload Completed !" : "Uploading dectector files..."}</div>
                            <div style={{}}>
                                <div style={{ textAlign: 'center', marginTop: "4%", marginBottom: "2%" }}>
                                    <div style={{ width: 175, height: 175, margin: 'auto', fontSize: "10px" }}>
                                        <CircularProgressbar
                                            value={this.state.toShowProgressOf}
                                            text={`${convertInMbGB(this.state.uploadedData)}/${convertInMbGB(this.state.uploadFileSize)}`}
                                            styles={{
                                                text: {
                                                    fill: '#2989cf',
                                                    fontSize: '9px',
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            //  id="upload-modal-ok"
                            className="add-user-form-submit-button ok-button btn-ok popup-btn-right"
                            // disabled10px
                            style={{ width: "fit-content", marginTop: '4%', marginRight: '6%' }}
                            onClick={() => this.state.toShowProgressOf == 100 ? this.close() : this.cancelUpload()}
                        >{this.state.toShowProgressOf == 100 ? 'Close' : 'Cancel'}</button>

                    </AnimatedModal>

                    {/* //Add detector popup */}
                    <AnimatedModal
                        isOpen={this.state.showAddDetectorPopup}
                        onRequestClose={() => {
                            this.setState((state) => ({
                                ...state,
                                showAddDetectorPopup: false,
                            }))
                        }}
                        height="600px"
                        width="550px"
                    >
                        <div className='content-text' style={{ textAlign: "center", margin: '10px 0px', height: '30px', fontSize: '20px', fontWeight: '500' }}>{"Add New Detector"}</div>
                        <div style={{ height: '5px', width: '100%' }}><hr /></div>
                        <div style={{ margin: '10px 50px' }}>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "1%" }}>Detector Name</div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="detector_name"
                                    placeholder="Enter detector Name"
                                    onChange={this.onChangeListener}
                                    value={detector_name}
                                />
                            </div>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>Display Name</div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="display_name"
                                    placeholder="Enter display name"
                                    onChange={this.displayNameHandler}
                                    value={display_name}
                                />
                            </div>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>Detector Type</div>
                            <div className="add-user-form-text-wrapper" style={{ border: '1px solid #b4b4bb', borderRadius: '6px', paddingLeft: '5px' }}>
                                <DropDownWithButton
                                    list={
                                        DETECTOR_TYPES.map(det => ({
                                            name: det.name,
                                            onClick: () => {
                                                this.setState({
                                                    detector_type: det
                                                })
                                            }
                                        }))}
                                    selectedItem={detector_type.name}
                                    width={'99%'}
                                    textAlign={'left'}
                                />
                            </div>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>Objects</div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="objects"
                                    placeholder="object1, object2, object3..."
                                    onChange={this.onObjectChange}
                                    value={objects}
                                />
                            </div>

                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>Upload detector files</div>
                            <div className="add-user-form-text-wrapper" style={{ display: "flex", justifyContent: "space-between" }}>
                                <input
                                    className="sub-add-user-form-text"
                                    type="file"
                                    accept={detector_type.accepeted_file_type}
                                    name="detector_file"
                                    placeholder="Select detector file to upload"
                                    onClick={(event) => this.onClickListenerFile(event)}
                                    onChange={(e) => {
                                        this.onChangeListenerFile(e);
                                    }}
                                    style={{ padding: "6px 8px" }}
                                />
                            </div>

                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>User Name <span style={{ color: 'red', fontSize: '12px' }}>&nbsp; {this.state.usernameError}</span></div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    className="sub-add-user-form-text"
                                    type='email'
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                    name="userName"
                                    placeholder="Enter user name"
                                    onChange={this.userNameHandler}
                                    value={this.state.selectedUser}
                                />
                            </div>
                        </div>
                        <div style={{ margin: '50px 50px 10px 50px', display: 'flex', justifyContent: 'space-between' }}>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        showAddDetectorPopup: false,
                                        detector_name: "",
                                        display_name: "",
                                        toShowProgressOf: 0,
                                        objects: "",
                                        fileNames: [],
                                        uploadedCount: 0,
                                        totalFilesCount: 0,
                                        usernameError: '',
                                        selectedUser: undefined,
                                        valUserName: undefined,
                                    }));
                                }}
                            >
                                Cancel
                            </button>
                            {detector_name && display_name && detector_type && objects.length > 0 && this.state.targetFilesArr.length > 0 ?
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            showAddDetectorPopup: false,
                                            // showProcessPopup: true,
                                            toShowProgressOf: 0,
                                            itemName: "Detector",
                                        }), () => {
                                            this.uploadFiles()
                                        });
                                    }}
                                >
                                    Save
                                </button>

                                :
                                <button disabled style={{ opacity: '0.5' }}
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                >
                                    Save
                                </button>
                            }

                        </div>
                    </AnimatedModal>

                    <Modal
                        style={{ marginTop: '150px' }} showCancelPopup
                        show={this.state.showCancelPopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                showCancelPopup: false,
                            }));
                        }}
                    >
                        <Modal.Body >
                            <p>Your file upload is still in progress, Are you sure you want to cancel it?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <div>
                                <button
                                    className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            showCancelPopup: false,
                                            show: true
                                        }));
                                    }}
                                >
                                    No
                                </button>
                            </div>
                            <div>
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    onClick={() => {
                                        this.cancelUpload()
                                    }}
                                >
                                    Yes
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>

                    <div className="right-content"
                        style={{ zIndex: 0 }}
                        onClick={
                            () => {
                                this.setState((state) => ({
                                    ...state, sidebarMode: 'shrunk'
                                }))
                            }
                        }
                    >
                        <div className="top-bar">
                            <div className="top-bar-text">
                                PUBLIC DETECTORS
                            </div>
                        </div>

                        {
                            this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                                <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                            </div> :
                                <div className="main-content" style={{ background: 'none' }}>

                                    <div style={{ marginTop: "1%", display: "flex", width: "100%", justifyContent: "space-between" }}>
                                        <div className="services-content-title">{"Detectors"}
                                            <span style={{ fontSize: "16px", color: "#2989cf", marginLeft: "5px" }}>({this.state.counts})</span>
                                        </div>
                                        <div className="add-user-form-row-left" style={{ marginLeft: 'auto' }}>
                                            <div className="add-user-form-text-wrapper">
                                                <img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
                                                <input type="text" className="add-user-form-text" placeholder="Search Detector" style={{ height: '30px !important', width: "230px", paddingLeft: '28px' }}
                                                    onChange={(event) => this.search(event.target.value)} />
                                            </div>
                                        </div>
                                        <div className="add-user-form-text-wrapper" style={{ marginLeft: "25px", marginRight: '5px' }}>
                                            <img src={btnAdd} style={{ height: '30px', width: '34px', cursor: 'pointer' }} onClick={() => this.openAddDetectorPopup()} />
                                        </div>
                                    </div>

                                    <table className="customers-table scroll-container">
                                        <col style={{ width: "20%" }} />
                                        <col style={{ width: "60%" }} />
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "5%" }} />
                                        <tr className="customers-table-header-row">
                                            <th>Name
                                                <SortComponent
                                                    style={{ marginLeft: '5px' }}

                                                    sortAscending={this.sortByName}
                                                    sortDecending={this.reverseByName}
                                                    sortASC={false}
                                                />
                                            </th>
                                            <th>Objects</th>
                                            <th>Date created
                                                <SortComponent
                                                    style={{ marginLeft: '5px' }}

                                                    sortAscending={this.sortByDate}
                                                    sortDecending={this.reverseByDate}
                                                    sortASC={false}
                                                />
                                            </th>
                                            <th>

                                            </th>
                                        </tr>
                                        {
                                            this.state.tableLoading ?
                                                <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                                    <td colspan="7">  <img src={Loader} style={{ margin: "auto", height: '6%', width: '6%' }} /></td>
                                                </tr>
                                                :
                                                detectors.length === 0 ?
                                                    <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                                        <td colspan="6">No detectors available</td>
                                                    </tr> :
                                                    detectors.map((detector) => {
                                                        return (<tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                            <td onClick={() => {
                                                                this.setState((state) => ({
                                                                    ...state,
                                                                    selectedDetectorId: detector.id,
                                                                    showDetectorDetails: true
                                                                }), () => {
                                                                    this.getSelectedDetector()
                                                                });
                                                            }} style={{ cursor: "pointer" }}>{detector.display_name}</td>
                                                            <td>
                                                                {detector.objects.toString().length > 100 ? `${detector.objects.toString().substring(0, 100)}...` : detector.objects.toString()}
                                                            </td>
                                                            <td> {
                                                                (() => {
                                                                    let date = (new Date(detector.date_created)).toGMTString().split(' ').slice(1, 4).join(' ');
                                                                    return `${date}`
                                                                })()
                                                            }</td>
                                                            <td>
                                                                <DeleteComponentTable
                                                                    style={{ margin: "0 10px" }}
                                                                    onClickDelete={(e) => {
                                                                        e.preventDefault();
                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            selectedDetectorId: detector.id,
                                                                            itemName: "Detector",
                                                                            processPopupIcon: "WARNING",
                                                                            processAction: "DELETE",
                                                                            processMessage: null,
                                                                            showProcessPopup: true,
                                                                        }))
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>)
                                                    }
                                                    )}
                                    </table>
                                    {/* This is footer  */}
                                    <footer className="footer" style={{ visibility: (this.state.counts > 8 ? "visible" : "hidden") }} >
                                        <div style={{ float: "left", marginTop: "0.5rem", display: "flex" }}>
                                            <div style={{ color: "#109cf1", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                                Page : {currentpage}
                                            </div>
                                        </div>
                                        <div style={{ float: "right", marginTop: "0.5rem", display: "flex" }}>
                                            {this.state.currentpage == 1 ?
                                                <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", cursor: "default", opacity: '0.5' }}>
                                                    <div className="add-user-form-submit-button" >
                                                        First
                                                    </div>
                                                </button> :
                                                <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", }} onClick={this.firstpage}>
                                                    <div className="add-user-form-submit-button" >
                                                        First
                                                    </div>
                                                </button>}
                                            {this.state.currentpage == 1 ?
                                                <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#1090cf1", marginRight: "10px", marginLeft: "10px", cursor: 'default', opacity: '0.5' }}>
                                                    <div className="addcustomer-button-empty-dashboard" >
                                                        Prev
                                                    </div>
                                                </button> :

                                                <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px" }} onClick={this.prevpage}>
                                                    <div className="addcustomer-button-empty-dashboard" >
                                                        Prev
                                                    </div>
                                                </button>}
                                            <form>
                                                <div style={{ display: "flex" }}>
                                                    <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                                        Go to page :
                                                    </div>

                                                    <div className="add-user-form-text-wrapper">
                                                        <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                                                            onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                                            onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                                    </div>
                                                </div>
                                            </form>
                                            {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                                                <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px", cursor: "default", opacity: '0.5' }}>
                                                    <div className="addcustomer-button-empty-dashboard" >
                                                        Next
                                                    </div>
                                                </button> :
                                                <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px" }} onClick={this.nextpage}>
                                                    <div className="addcustomer-button-empty-dashboard" >
                                                        Next
                                                    </div>
                                                </button>
                                            }
                                            {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                                                <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px", cursor: 'default', opacity: '0.5' }}>
                                                    <div className="add-user-form-submit-button" >
                                                        Last
                                                    </div>
                                                </button> :
                                                <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }} onClick={this.lastpage}>
                                                    <div className="add-user-form-submit-button" >
                                                        Last
                                                    </div>
                                                </button>}
                                        </div>
                                    </footer>
                                </div>
                        }
                    </div>
                </div>
                : <>
                </>
        );
    }

}

const mapStateToProps = state => {
    return {
        SubscriptionData: state.subscription.subscriptionData,
    };
};

export default connect(mapStateToProps)(PublicDetectors);