import React, { Component } from "react";
import { v4 as uuidv4 } from 'uuid';
import * as API from "../api";

import { AnimatedModal } from "./AnimatedModal";
import { SetRestrictSesssionExpire } from "../helperComponents/OnIdleHandlerWrapper";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import Loader from "../icons/loader.svg";
import { setActiveRoleForProject } from "../Teams";
import { ActivityPopup } from "./ActivityPopup";
import Notification from "../../src/assets/Bell_pin.png"
import NewNotification from "../../src/assets/new-notification.png"
import noNotifications from '../assets/Icons/notification.png';
import { isServiceAeroGCS } from "./reusableFunctions";
export default class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            showPanel: false,
            newNotifications: false,
            loader: true,
            notificationTempId: undefined,
        };
    }

    toggleNotificationPanel = () => {
        this.getNotifications()
        this.setState({
            showPanel: !this.state.showPanel
        })
    }

    componentWillUnmount() {
        SetRestrictSesssionExpire(false)
    }

    getNotifications = () => {
        this.setState({
            loder: true
        })
        API.getNotifications().then((result) => {
            console.log("Notifications : ", result)
            this.setState({
                notifications: result.notifications,
                loader: false
            })
        }).catch(e => {
            alert("An internal sevrer erorr has occured")
        })
    }

    changeAndRedirect = async (notification) => {
        if (notification) {
            if (notification.type === 2) {
                API.getTeamsList().then(data => {
                    console.log(data)
                    console.log(notification)
                    if (data.teams.filter(team => team.teamId === notification.team.team_id).length > 0) {
                        this.setState({ acceptInviteItem: "ERROR", acceptInvitePopup: true, acceptInviteIcon: "ERROR", acceptInviteMsg: "Invitation already accepted" })

                    }
                    else window.location.replace(notification.props.inviteLink)
                })
            } else if (notification.type === 1 || notification.type === 4 || notification.type === 0) {
                let currentTeam = await this.props.teams.find(t => t.teamId == notification.team_id)
                await this.props.setActiveTeam(currentTeam, true)
                if (notification.props.state.project) {
                    if (notification.projectRole) {
                        await setActiveRoleForProject(notification.props.state.project.id)
                        document.getElementById(notification.id).click()
                        this.setState({ showPanel: false })
                    }
                    else this.setNoAccessError()
                } else {
                    document.getElementById(notification.id).click()
                    this.setState({ showPanel: false })
                }
            }
        }
    }

    setNoAccessError = () => {
        this.setState({
            error: {
                message: "You dont have access to this project",
                icon: "ERROR"
            }
        })
    }

    render() {

        const { notifications } = this.state

        return (
            <>
                {/* -------------- Activity Popup ----------------------- */}
                <ActivityPopup
                    open={this.state.error}
                    icon={this.state.error?.icon}
                    msg={this.state.error?.message}
                    close={() => this.setState({ error: false })}
                />
                <ActivityPopup
                    item={this.state.acceptInviteItem}
                    open={this.state.acceptInvitePopup}
                    icon={this.state.acceptInviteIcon}
                    msg={this.state.acceptInviteMsg}
                    close={() => this.setState({ acceptInvitePopup: false, })}
                    onClickOk={() => this.setState({ acceptInvitePopup: false, })
                    }
                />
                {/* --------------------------------------------------------------- */}

                {isServiceAeroGCS(window.location.pathname) ? <div
                    className="add-user-form-text-wrapper "
                    style={{ marginTop: "0px" }}
                >
                    <img className="notification-bell" src={this.state.newNotifications ? NewNotification : Notification}
                        style={{  }}
                        onClick={() => {
                            this.toggleNotificationPanel()
                        }}
                    />
                </div> :
                    <div
                        className="add-user-form-text-wrapper "
                        style={{ marginTop: "0px" }}
                    >
                        <img src={this.state.newNotifications ? notifications : noNotifications}
                            style={{ height: '25px', width: '25px', marginTop: '3px', cursor: 'pointer' }}
                            onClick={() => {
                                this.toggleNotificationPanel()
                            }}
                        />
                    </div>
                }
                <AnimatedModal
                    isOpen={this.state.showPanel}
                    onRequestClose={() => {
                        this.setState({ showPanel: false })
                    }}
                    height='500px'
                    width='350px'
                    top='300px'
                    right='1px'
                    left='auto'
                    marginRight='-155px'
                    zIndex="99"
                    timeout={20}
                >
                    <div style={{ display: "flex", textAlign: 'center', alignItems: 'center', padding: '15px 20px', height: '60px', borderBottom: '1px solid #e5e5e5' }}>
                        <span style={{ fontSize: "16px", textAlign: "left", width: "93%" }}>
                            Notifications
                        </span>
                        <img
                            alt={"close"}
                            onClick={() => this.toggleNotificationPanel()}
                            style={{ height: '20px', width: '20px', cursor: "pointer" }}
                            src={require("../icons/closeButton.png")}
                        ></img>
                    </div>
                    <div style={{ height: '85%', overflowY: 'auto', padding: '5px 15px 0px 15px', textAlign: 'left' }}>
                        {
                            this.state.loader ?
                                <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img src={Loader} style={{ height: "35%", width: "35%", marginTop: '-15%', marginRight: '-20%' }} />
                                </div> :
                                this.state.notifications.length > 0 ?
                                    this.state.notifications.map(notification => {
                                        return (
                                            <div style={{ padding: '5px 5px', fontSize: '12px', color: '#3c3c3c', marginBottom: '10px', display: 'flex' }}>
                                                <img src={require("../icons/pointer.png")} style={{ height: '15px', width: '15px', marginTop: '3px', marginRight: '8px' }} ></img>
                                                <div style={{ width: "calc(100% - 15px)" }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ fontWeight: '450', fontSize: '14px' }}> {`${notification.first_name + ' ' + notification.last_name} `}</div>
                                                        <div style={{ fontSize: '11px' }}> {`${dateFormat(notification.created_date, "dd-mm-yyyy h:MM TT")}`}</div>
                                                    </div>
                                                    <div style={{ marginTop: '2px' }}>
                                                        <p style={{ width: '100%', wordWrap: "break-word" }}>
                                                            {`${notification.type === 1 ? 'New message : ' + notification.content : notification.content}`} &nbsp;
                                                            <span style={{ color: '#007bff', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    this.changeAndRedirect(notification)
                                                                }}>{notification.type == 2 ? 'Accept' : notification.props?.pathname ? 'View' : ""}</span>
                                                            <Link id={notification.id}
                                                                style={{ display: 'none' }}
                                                                to={{
                                                                    pathname: notification.props.pathname,
                                                                    state: {
                                                                        ...notification.props.state,
                                                                        user: this.props.user,
                                                                        notificationId: notification.id,
                                                                        notificationTempId: uuidv4(),
                                                                    }
                                                                }} />
                                                        </p>

                                                    </div>
                                                </div>
                                                {/* <hr style={{ width: "95%", margin: '10px 0px', color: "#3c3c3c" }} /> */}
                                            </div>
                                        )
                                    })
                                    :
                                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', textAlign: "center", color: '#3c3c3c' }}> No new notifications </div>
                        }
                    </div>
                </AnimatedModal>
            </>

        );
    }
}
