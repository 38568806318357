// react
import React, { useEffect } from 'react';
import L from "leaflet"
import "leaflet-draw";
import "leaflet-editable"
import "leaflet.path.drag"
import { EXTENDBOUNDS, MAP_URLS } from "../../../AppConstants"

// leaflet

function LocationMap(props) {
    // console.log("single map",props)
    useEffect(() => {
        var map = L.map('map1', {
            maxZoom: props.maxZoom ? props.maxZoom : 28,
            zoomControl: false,
            editable: true,
            center: props.initCenter,
            zoom: 16,

        })
        props.setMap(map)
        console.log(props.initCenter)
        if (props.bounds) {
            let bounds = [[props.bounds[1] - EXTENDBOUNDS, props.bounds[0] - EXTENDBOUNDS], [props.bounds[3] + EXTENDBOUNDS, props.bounds[2] + EXTENDBOUNDS]]
            map.fitBounds(bounds);
        }
        else {
            map.fitBounds([[26.798175480657605, 82.7132375946003], [26.82286941676843, 82.75360145561896]]);
        }
        let baseLayer = L.tileLayer(props.initBaseLayerURL || MAP_URLS.SATELITE, {
            attribution: 'Imagery ©2022 CNES / Airbus, Landsat / Copernicus, Maxar Technologies, Map data ©2022',
            maxZoom: props.maxZoom,
            center: props.initCenter,
            zoom: 18,
            tileSize: 256,
        }).addTo(map);
        if (props.isTaskTraining) {

            var CartoDB_DarkMatter = L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                subdomains: 'abcd',
                maxZoom: 20,
                opacity: 0.7
            }).addTo(map);
        }
        if (props.setBaseLayerToState) props.setBaseLayerToState(baseLayer);
        if (props.handleMapClick) map.on('click', props.handleMapClick);
        if (props.handleMoveStart) map.on('movestart', props.handleMoveStart);

    }, [])


    // render component
    return (
        <>
            {props.children}
            <div id="map1" className='map' style={{height:"415px",width:"100%",position:"initial",border:"0px",marginTop:"20px"}}>
            </div>
        </>
    )

}

export default LocationMap
