
import React from 'react'
import Stepper from 'react-stepper-horizontal'
import { AnimatedModal } from './AnimatedModal'
import closeModal from '../close-button.png'
/**
To use this popup you have to pass following props
  1) onRequestClose as function
  2) if You want to give width then pass width otherwise it take 41% by default
  3) if You want to give height then pass width otherwise it take 50% by default

*/
export const StepperModal = (props) => {

    return (
        <>

            {/*------------------------------ Stepper Modal-----------------------------*/}
            <AnimatedModal
                height={props.height}
                width={props.width}
                onRequestClose={props.closeStepper}
                isOpen={props.stepperModal}
            >
                <div style={{ background: "#E0E0E0", height: "100%", width: "100%" }}>
                    <div style={{ background: "#FFFFFF", height: "140px" }}>
                    <img src={closeModal} className = {"hoverPointer"} style={{ position:"absolute",right:"3%", top: "3%", height: "20px" }} onClick={props.closeStepper}></img>
                        <div className="services-content-title" style={{ height:"100%", paddingTop: "2.5%" }}>
                            <div>{props.heading}</div>
                            <div style={{ marginTop: "-1%" ,fontWeight:"500"}}>
                                <Stepper defaultTitleColor={"#666666"} titleTop ={1} titleFontWeight={"400"} titleFontSize={12} circleFontSize={2} size={32} steps={props.steps} activeStep={props.currentStep} defaultColor="#666666" activeColor="#2989cf" completeColor="#2989cf" activeTitleColor="#2989cf" completeTitleColor="#2989cf" completeBarColor="#2989cf" />
                            </div>
                        </div>
                    </div>
                    {props.children}
                </div>
            </AnimatedModal>
            {/*-------------------------------------------------------------------------------------------- */}
        </>
    )
}