import React, { createRef, useEffect, useState } from 'react'
import MultiFileUploader from "../../AeroCapture/Components/aeroCaptureMultiFileUploader"
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon'
import * as API from "../../api.js";
import whiteMarker from "../../AeroCapture/assets/sidebarIcons/whiteMarker.png"
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup';
import Layers from "./Layers"
import Empty1 from "../../assets/1empty.png"
import Empty2 from "../../assets/2empty.png"
import Empty3 from "../../assets/3empty.png"
import { useRef } from 'react';
import LoaderComponent from '../../ReusableComponents/LoaderComponent';
import ImageCard from '../../ReusableComponents/ImageCard';
import AppConstants from '../../AppConstants';
import L from "leaflet";
import imageMarker from '../assets/icons/imageMarker.svg'
import image360Marker from '../assets/icons/images360Marker.svg'
import { DRONENAKSHA_FEATURES } from '../../Teams';

export const FLOORIMAGES = "floorImages"
export const FLOORIMAGES360 = "floorThreeSixtyImages"
const CADLAYOUT = "cadLayout"
let imagesLayerGroup = L.layerGroup()
let groupBounds = new L.latLngBounds()

const FloorComponent = ({
  cadLayersData,
  subscriptionCheck,
  openSubcheckPopup,
  totalImgCount,
  oldImages,
  user,
  plan,
  selectedFloor,
  cadUpload,
  newUploadedCad,
  openFloorPlanningImageViewer,
  onUnMount,
  map,
  minimizedView,
  permissions,
  tab,
  updatelayer
}) => {
  const imagesRef = useRef();
  const threeSixtyRef = useRef();
  const cadRef = useRef();

  const [loading, setLoading] = useState(true)

  const [allImageSelected, setAllImageSelected] = useState(false)
  const [allThreeSixtyImageSelected, setAllThreeSixtyImageSelected] = useState(false)

  const [selectedImagesArr, setSelectedImagesArr] = useState([])
  const [selectedThreeSixtyImagesArr, setSelectedThreeSixtyImagesArr] = useState([])
  const [fileCount, setFileCount] = useState(0)
  const [images, setImages] = useState([])
  const [imagesThreeSixty, setImagesThreeSixty] = useState([])
  const [importImageType, setImportImageType] = useState(undefined)
  const [cadFiles, setCadFiles] = useState([])
  const [cadAPIFiles, setCadAPIFiles] = useState([])
  const [deletePopup, setDeletePopup] = useState(undefined)

  // Activity Popup States
  const [showProcessPopup, setShowProcessPopup] = useState(undefined);//true
  const [itemName, setItemName] = useState(undefined); //Password  
  const [processPopupIcon, setProcessPopupIcon] = useState(undefined);//WARNING
  const [processAction, setProcessAction] = useState(undefined); //UPDATE
  const [processMessage, setProcessMessage] = useState(undefined);

  // Buttons states
  const [importImgBtn, setImportImgBtn] = useState(true)
  const [importThreeSixtyImgBtn, setImportThreeSixtyImgBtn] = useState(true)

  const [selectImgBtn, setSelectImgBtn] = useState(true)
  const [selectThreeSixtyImgBtn, setSelectThreeSixtyImgBtn] = useState(true)

  const [cancelImgBtn, setCancelImgBtn] = useState(false);
  const [cancelThreeSixtyImgBtn, setCancelThreeSixtyImgBtn] = useState(false);

  const [deleteImgBtn, setDeleteImgBtn] = useState(false);
  const [deleteThreeSixtyImgBtn, setDeleteThreeSixtyImgBtn] = useState(false);

  const [selecetAllImgBtn, setSelectAllImgBtn] = useState(false);
  const [selecetAllThreeSixtyImgBtn, setSelectAllThreeSixtyImgBtn] = useState(false);

  const [selectedTab, setSelectedTab] = useState(tab || FLOORIMAGES)
  const markerOptions = {
    // title: data.name,
    clickable: true,
    draggable: false,
    autoPanOnFocus: true
  }
  const drawMarker = async (location, type) => {
    const marker = new L.marker(location, { ...markerOptions, icon: new L.Icon({ iconUrl: type === FLOORIMAGES ? imageMarker : image360Marker, iconSize: [44, 50], iconAnchor: [22, 50] }) })

    marker.addTo(imagesLayerGroup)
    groupBounds.extend(location)
    return marker
  }


  const showImagesOnMap = (images, imagesThreeSixty) => {
    if (map) {
      images.map(async (i, key) => {
        if (i.location?.lat) {
          const marker = await drawMarker(i.location, FLOORIMAGES)
          marker.on('click', () => {
            openFloorPlanningImageViewer({ images, index: key })

          })
          return
        } else return
      })
      Promise.all(images)
      imagesThreeSixty.map(async (i, key) => {
        if (i.location?.lat) {
          const marker = await drawMarker(i.location, FLOORIMAGES360)
          marker.on('click', () => {
            openFloorPlanningImageViewer({ images360: imagesThreeSixty, index: key })
          })
          return
        } else return

      })

      Promise.all(imagesThreeSixty)
      if (groupBounds.isValid()) map.fitBounds(groupBounds.pad(0.1)).setZoom(20);
    }
  }

  useEffect(() => {
    if (groupBounds?.isValid()) {
      groupBounds = new L.latLngBounds()
      imagesLayerGroup.clearLayers()
      showImagesOnMap(images, imagesThreeSixty)
    }
  }, [images.length, imagesThreeSixty.length])


  useEffect(() => {
    if (map) {
      imagesLayerGroup.addTo(map)
    }
    return () => {
      groupBounds = new L.latLngBounds()
      imagesLayerGroup.remove()
      imagesLayerGroup = L.layerGroup()
      onUnMount()
    }
  }, [map, selectedFloor])

  useEffect(() => {
    setTimeout(() => {
      if (minimizedView !== undefined && map && groupBounds?.isValid()) {
        map.invalidateSize()
        map.fitBounds(groupBounds.pad(0.1)).setZoom(20)
      }
    }, 600)


  }, [minimizedView])



  // Button Click Functions

  const importImgClick = () => {
    setImportImageType(FLOORIMAGES)
    document.getElementById("hiddenFileInput").click()
  }

  const importThreeSixtyImgClick = () => {
    setImportImageType(FLOORIMAGES360)
    document.getElementById("hiddenFileInput").click()
  }

  const selectImgClick = () => {
    setSelectImgBtn(false);
    setImportImgBtn(false);
    setCancelImgBtn(true);
    setDeleteImgBtn(true);
    setSelectAllImgBtn(true);
  }

  const selectThreeSixtyImgClick = () => {
    setSelectThreeSixtyImgBtn(false);
    setImportThreeSixtyImgBtn(false);
    setCancelThreeSixtyImgBtn(true);
    setDeleteThreeSixtyImgBtn(true);
    setSelectAllThreeSixtyImgBtn(true);
  }

  const cancelImgBtnClick = () => {
    setCancelImgBtn(false)
    setImportImgBtn(true)
    setDeleteImgBtn(false)
    setSelectImgBtn(true)
    setSelectAllImgBtn(false)
    setAllImageSelected(false)
  }

  const cancelThreeSixtyImgBtnClick = () => {
    setCancelThreeSixtyImgBtn(false)
    setImportThreeSixtyImgBtn(true)
    setDeleteThreeSixtyImgBtn(false)
    setSelectThreeSixtyImgBtn(true)
    setSelectAllThreeSixtyImgBtn(false)
    setAllThreeSixtyImageSelected(false)
  }

  const deleteImgBtnClick = async () => {
    try {
      await API.deleteFloorImg({ "images": selectedImagesArr }, FLOORIMAGES, plan.id, selectedFloor?.id)
      const selectedImagesIds = selectedImagesArr.map(i => i.id)
      setImages(images.filter(i => !(selectedImagesIds.includes(i.id))))
      cancelImgBtnClick()
      setSelectedImagesArr([])
      openFloorPlanningImageViewer(false)
    } catch (e) {
      console.log(e)
      alert("Something went wrong while deleting...")
    }
  }

  const deleteThreeSixtyImgBtnClick = async () => {
    try {
      await API.deleteFloorImg({ "images": selectedThreeSixtyImagesArr }, FLOORIMAGES360, plan.id, selectedFloor?.id)
      const selectedImagesIds = selectedThreeSixtyImagesArr.map(i => i.id)
      setImagesThreeSixty(imagesThreeSixty.filter(i => !(selectedImagesIds.includes(i.id))))
      cancelThreeSixtyImgBtnClick()
      setSelectedThreeSixtyImagesArr([])
      openFloorPlanningImageViewer(false)
    } catch (e) {
      alert("Something went wrong while deleting 360 Images...")
      // showErrorPopup
    }
  }


  const selectSingleThreeSixtyImg = (img, index) => {
    setImagesThreeSixty(imagesThreeSixty.map(i => img.id === i.id ? { ...i, check: !(i.check) } : i))
  }

  useEffect(() => {
    const selectedImages = imagesThreeSixty.filter(i => i.check)
    setSelectedThreeSixtyImagesArr(selectedImages)
    // if(selectedImages.length > 0) setAllThreeSixtyImageSelected(false)
  }, [imagesThreeSixty])

  useEffect(() => {
    setImagesThreeSixty(imagesThreeSixty.map(i => {
      return ({
        ...i,
        check: allThreeSixtyImageSelected
      })
    }))
  }, [allThreeSixtyImageSelected])

  // image selection use effects
  useEffect(() => {
    const selectedImages = images.filter(i => i.check)
    setSelectedImagesArr(selectedImages)
    // if(selectedImages.length > 0) setAllImageSelected(false)
  }, [images])

  useEffect(() => {
    setImages(images.map(i => {
      return ({
        ...i,
        check: allImageSelected
      })
    }))
  }, [allImageSelected])

  const selectSingleImg = (img, index) => {
    setImages(images.map(i => img.id === i.id ? { ...i, check: !(i.check) } : i))
  }

  const getFloorImages = async (changedEntity) => {
    try {
      const data = await API.getFloorImages(selectedFloor?.id)
      let filterCadData = cadLayersData.layers.filter(lyr => data.cadFiles.filter(cad => cad.id === lyr.id))
      setLoading(false)
      if(changedEntity==="CAD"){

        setCadFiles(filterCadData.length > 0 ? filterCadData : [])
        setCadAPIFiles(data.cadFiles)
      }else{
        setImages(data.images)
        setImagesThreeSixty(data.images360)
        showImagesOnMap(data.images, data.images360)
      }
    } catch (e) {
      console.log("something went wrong while getting floor data")
    }
  }

  useEffect(() => {
    if(selectedFloor){
      getFloorImages()
    }
  }, [selectedFloor, selectedTab])

  useEffect(() => {
    if (newUploadedCad) {
      setCadAPIFiles([...cadAPIFiles, newUploadedCad])
    }
  }, [newUploadedCad])

  useEffect(() => {
    if(selectedFloor){

      // setCadFiles(cadLayersData.layers.filter(lyr => cadAPIFiles.filter(cad => cad.id === lyr.id).length > 0) || [])
      getFloorImages("CAD")
    }
  }, [cadLayersData.layers])

  return (
    loading ? <LoaderComponent
      height={"80%"}
      message="Loading floor data please wait..."
    /> : <>
      {!subscriptionCheck &&
        <MultiFileUploader
          serviceName={AppConstants.SERVICES.DRONENAKSHA}
          hide
          collection={undefined}
          totalImageCount={totalImgCount}
          oldFiles={oldImages}
          user={user.userid}
          imageType={importImageType}
          planId={plan.id}
          floorId={selectedFloor?.id}
          acceptFileType={AppConstants.FILETYPES.PNGJPEG}
          callback={(n, files) => { }}
          onImageUpload={async (uploadPercent) => {
            getFloorImages()
          }}
        />
      }
      {showProcessPopup && <ActivityPopup
        item={showProcessPopup.item}
        open={showProcessPopup}
        icon={showProcessPopup.icon}
        action={showProcessPopup.action}
        msg={showProcessPopup.message}
        close={() => setShowProcessPopup(false)}
        onClickOk={() => {
          if (showProcessPopup.type === FLOORIMAGES) deleteImgBtnClick()
          else deleteThreeSixtyImgBtnClick();
          setShowProcessPopup(false)
        }}
      />}
      <div style={{ height: "calc(100% - 30px)", display: "flex", flexDirection: "column", overflowY: "auto" }}>
        <div style={{ width: "100%", display: "flex", boxShadow: "inset 0px -1px 0px 0px #2989cf", justifyContent: "center", marginTop: "25px", }} className='tab-container'>
          <div onClick={() => setSelectedTab(FLOORIMAGES)} className={`tab ${selectedTab === FLOORIMAGES ? 'selected-tab' : ''}`} style={{ color: "#666666" }}>Images</div>
          <div onClick={() => setSelectedTab(FLOORIMAGES360)} className={`tab ${selectedTab === FLOORIMAGES360 ? 'selected-tab' : ''}`} style={{ color: "#666666" }}>360 Images</div>
          <div onClick={() => setSelectedTab(CADLAYOUT)} className={`tab ${selectedTab === CADLAYOUT ? 'selected-tab' : ''}`} style={{ color: "#666666" }}>CAD layout</div>
        </div>
        {selectedTab === FLOORIMAGES &&
          <div style={{ width: "100%", height: "calc(100% - 60px)", width: "100%", display: "flex", flexDirection: "column", borderBottom: "1px solid rgb(218, 220, 224)", padding: "0px 0px 0px 15px" }}>
            <div style={{ margin: "", padding: "10px 10px 10px 0px", margin: "10px 0px", display: "flex", justifyContent: "space-between ", flexDirection: "column" }}>
              <div style={{ color: "#3c3c3c", margin: "", width: "100%", fontSize: "13px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex" }}>
                  <div>Total</div>
                  <div>{`(${images?.length})`}</div>
                </div>

                <div style={{ display: "flex" }}>
                  <ButtonWithIcon
                    isDisabled={!permissions[DRONENAKSHA_FEATURES.MAPS].CREATE && !permissions[DRONENAKSHA_FEATURES.MAPS].EDIT}
                    text={importImgBtn ? "Import" : cancelImgBtn && "cancel"}
                    height="20px" padding="0px 10px " width="75px"
                    onClick={
                      subscriptionCheck ? () => openSubcheckPopup() : importImgBtn ? () => importImgClick() : cancelImgBtn ? () => cancelImgBtnClick() : () => { }}
                  />
                  {selectImgBtn ?
                    (images.length > 0) ? <ButtonWithIcon isDisabled={!permissions[DRONENAKSHA_FEATURES.MAPS].EDIT || !(images.length > 0)} height="20px" padding="0px 10px " width="75px" text={"Select"} onClick={() => { selectImgClick() }} /> : <></>
                    : deleteImgBtn ?
                      <ButtonWithIcon isDisabled={!(selectedImagesArr.length > 0) || !permissions[DRONENAKSHA_FEATURES.MAPS].DELETE} text={"Delete"}
                        height="20px" padding="0px 10px " width="75px"
                        onClick={() => {
                          setShowProcessPopup({
                            type: FLOORIMAGES,
                            item: "Delete",
                            icon: "WARNING",
                            action: "UPDATE",
                            message: "Are you sure, you want to delete Images ?"
                          });
                        }} />
                      : <></>}
                </div>
              </div>
              {selecetAllImgBtn ? <div style={{ cursor: "pointer", width: "100%", textAlign: "right", paddingRight: "20px", paddingTop: "5px", color: "#3C3C3C", textDecoration: "underline", textDecorationColor: "#3C3C3C" }} onClick={() => setAllImageSelected(!allImageSelected)}> {allImageSelected ? "Deselect all" : "Select all"}</div> : <div style={{ width: "100%", textAlign: "right", paddingRight: "20px", paddingTop: "5px", color: "white", textDecoration: "", textDecorationColor: "white", cursor: "default" }}>Select all</div>}
            </div>
            {images?.length > 0 ? <div ref={imagesRef} style={{ height: "", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "15px", padding: "0px 15px 0px 0px", overflow: "auto" }}>
              {/* <DirectScrollComponent showInitial containerRef={imagesRef} direction={"bottom"} bottom={"1%"} right={"46.5%"} width={"30px"} height={"30px"} padding={"6px"} /> */}
              {
                images.map((e, key) => {
                  return <>
                    <ImageCard onClick={() => openFloorPlanningImageViewer({ images, index: key })}
                      key={key}
                      TR={<>{deleteImgBtn && <input style={{ cursor: "pointer" }} className='' id="" type='checkbox' checked={e.check}
                        onChange={() => { selectSingleImg(e, key) }}
                      />}</>}
                      img={`${e.url}?${permissions.st}`}
                      infoTL={<div>{
                        e.name.length > 10 ?
                          `${e.name.substring(0, 10)}...` :
                          `${e.name}`
                      }</div>}
                      infoTR={<div >{(e.location?.lat && e.location?.lng) ? <img src={whiteMarker} /> : <></>}</div>}
                      infoBL={<div>
                        {
                          `${Number(e.size).toFixed(2)} MB`
                        }
                      </div>}
                      infoBR={<div>{`${e.resolution}MP `}</div>}
                    />
                  </>
                })
              }
            </div> : <>
              <div style={{ height: "calc(100% - 45px)" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  <div style={{ width: "", height: "32%", position: "relative" }}>
                    <img style={{ width: "100%", height: "100%" }} src={Empty1} />
                    <div style={{ position: "absolute", bottom: "11%", left: "50%", transform: "translateX(-50%)", width: "140%", color: "#666", textAlign: "center", fontSize: "", marginTop: "" }} className='empty-image-text'>No images available</div>
                  </div>
                </div>
              </div>
            </>}
          </div>}
        {selectedTab === FLOORIMAGES360 &&
          <div style={{ borderBottom: "", height: "calc(100% - 60px)", width: "100%", display: "flex", flexDirection: "column", borderBottom: "1px solid rgb(218, 220, 224)", padding: "0px 0px 0px 15px" }}>
            <div style={{ margin: "", padding: "10px 10px 10px 0px", margin: "10px 0px", display: "flex", flexDirection: "column" }}>
              <div style={{ color: "#3c3c3c", margin: "", width: "100%", fontSize: "13px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex" }}>
                  <div>Total</div>
                  <div>{`(${imagesThreeSixty?.length})`}</div>
                </div>

                <div style={{ display: "flex" }}>
                  <ButtonWithIcon
                    isDisabled={!permissions[DRONENAKSHA_FEATURES.MAPS].CREATE && !permissions[DRONENAKSHA_FEATURES.MAPS].EDIT}
                    height="20px" padding="0px 10px " width="75px"
                    text={importThreeSixtyImgBtn ? "Import" : cancelThreeSixtyImgBtn && "cancel"}
                    onClick={
                      subscriptionCheck ? () => openSubcheckPopup() : importThreeSixtyImgBtn ? () => importThreeSixtyImgClick() : cancelThreeSixtyImgBtn ? () => cancelThreeSixtyImgBtnClick() : () => { }}
                  />
                  {imagesThreeSixty.length > 0 ?
                    <>
                      {selectThreeSixtyImgBtn ? <ButtonWithIcon isDisabled={!permissions[DRONENAKSHA_FEATURES.MAPS].EDIT} height="20px" padding="0px 10px " width="75px" text={"Select"} onClick={() => { selectThreeSixtyImgClick() }} />
                        : deleteThreeSixtyImgBtn ?
                          <ButtonWithIcon height="20px" padding="0px 10px " width="75px" text={"Delete"} isDisabled={!(selectedThreeSixtyImagesArr.length > 0) || !permissions[DRONENAKSHA_FEATURES.MAPS].DELETE}
                            onClick={() => {
                              setShowProcessPopup({
                                type: FLOORIMAGES360,
                                item: "Delete",
                                icon: "WARNING",
                                action: "UPDATE",
                                message: "Are you sure, you want to delete Images ?"
                              });
                            }} />
                          : <></>}
                    </> : <></>
                  }
                </div>
              </div>
              {selecetAllThreeSixtyImgBtn ? <div style={{ cursor: "pointer", width: "100%", textAlign: "right", paddingRight: "20px", paddingTop: "5px", color: "#3C3C3C", textDecoration: "underline", textDecorationColor: "#3C3C3C" }} onClick={() => setAllThreeSixtyImageSelected(!allThreeSixtyImageSelected)}> {allThreeSixtyImageSelected ? "Deselect all" : "Select all"}</div> : <div style={{ width: "100%", textAlign: "right", paddingRight: "20px", paddingTop: "5px", color: "white", textDecoration: "", textDecorationColor: "white", cursor: "default" }}>Select all</div>}
            </div>
            {imagesThreeSixty?.length > 0 ? <div ref={threeSixtyRef} style={{ height: "", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "15px", overflow: "auto", padding: "0px 15px 0px 0px" }}>
              {/* <DirectScrollComponent showInitial containerRef={threeSixtyRef} direction={"bottom"} bottom={"1%"} right={"46.5%"} width={"30px"} height={"30px"} padding={"6px"} /> */}
              {
                imagesThreeSixty.map((e, key) => {
                  return <>
                    <ImageCard onClick={() => openFloorPlanningImageViewer({ images360: imagesThreeSixty, index: key })}
                      key={key}
                      TR={<>{deleteThreeSixtyImgBtn && <input style={{ cursor: "pointer" }} className='' id="" type='checkbox' checked={e.check}
                        onChange={() => { selectSingleThreeSixtyImg(e, key) }}
                      />}</>}
                      img={`${e.url}?${permissions.st}`}
                      infoTL={<div>{
                        e.name.length > 10 ?
                          `${e.name.substring(0, 10)}...` :
                          `${e.name}`
                      }</div>}
                      infoTR={<div >{(e.location?.lat && e.location?.lng) ? <img src={whiteMarker} /> : <></>}</div>}
                      infoBL={<div>{`${Number(e.size).toFixed(2)} MB`} </div>}
                      infoBR={<div>{`${e.resolution}MP `}</div>}
                    />
                  </>
                })
              }
            </div> : <>
              <div style={{ height: "calc(100% - 45px)" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  <div style={{ width: "", height: "30%", position: "relative" }}>
                    <img style={{ width: "100%", height: "100%" }} src={Empty2} />
                    <div style={{ position: "absolute", bottom: "0px", left: "50%", transform: "translateX(-50%)", width: "140%", color: "#666", textAlign: "center", fontSize: "", marginTop: "" }} className='empty-image-text'>No 360 images available</div>
                  </div>
                </div>
              </div>
            </>}
          </div>}
        {
          selectedTab === CADLAYOUT &&
          <div style={{ borderBottom: "", height: "calc(100% - 60px)", width: "100%", display: "flex", flexDirection: "column", padding: "0px 0px 0px 15px" }}>
            <div style={{ width: "", padding: "10px 10px 10px 0px", margin: "10px 0px", borderBottom: "", display: "flex", justifyContent: "space-between " }}>
              <div style={{ color: "#3c3c3c", width: "100%", fontSize: "13px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>Total{`(${cadFiles.length || 0})`}</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ButtonWithIcon
                    isDisabled={!permissions[DRONENAKSHA_FEATURES.MAPS].CREATE && !permissions[DRONENAKSHA_FEATURES.MAPS].EDIT}
                    height="20px" padding="0px 10px " width="75px"
                    text="Import"
                    // isDisabled={!this.state.permissions[PICSTORK_FEATURES.IMAGECOLLECTIONS].EDIT}
                    onClick={cadUpload}
                  />
                  {cadLayersData.permissions.DELETE ? <ButtonWithIcon text={"Delete"} height="20px" padding="0px 10px " width="75px"
                    isDisabled={cadFiles.filter(lyr => lyr.showLayer).length <= 0 || !permissions[DRONENAKSHA_FEATURES.MAPS].DELETE}
                    onClick={async () => {
                      let deleteCount = cadFiles.filter(lyr => lyr.showLayer).length
                      cadLayersData.handleDeleteMultipleLayers(deleteCount)
                    }} /> : <></>}
                </div>
              </div>
            </div>
            {cadFiles?.length > 0 ? <div className='scroll-container' ref={cadRef} style={{ height: "100%", overflow: "auto", padding: "0px 15px 0px 0px" }}>
              {/* <DirectScrollComponent showInitial containerRef={cadRef} direction={"bottom"} bottom={"1%"} right={"46.5%"} width={"30px"} height={"30px"} padding={"6px"} /> */}
              <Layers
                updatelayer={updatelayer}
                layers={cadFiles}
                handleLayerToggle={cadLayersData.handleLayerToggle}
                toggleLayerOptions={cadLayersData.toggleLayerOptions}
                disableLayers={cadLayersData.disableLayers}
                handleLayerDownload={cadLayersData.handleLayerDownload}
                handleDeleteLayer={cadLayersData.handleDeleteLayer}
                permissions={cadLayersData.permissions} />
            </div> : <>
              <div style={{ height: "calc(100% - 45px)" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  <div style={{ width: "", height: "30%", position: "relative" }}>
                    <img style={{ width: "100%", height: "100%" }} src={Empty3} />
                    <div style={{ position: "absolute", bottom: "0px", left: "50%", transform: "translateX(-50%)", width: "140%", color: "#666", textAlign: "center", fontSize: "", marginTop: "" }} className='empty-image-text'>No CAD layout available</div>
                  </div>
                </div>
              </div>
            </>}
          </div>
        }
      </div >
    </>
  )
}

export default FloorComponent