import { AnimatedModal } from "./ReusableComponents/AnimatedModal";
import React, { Component } from "react";
import * as API from "./api.js";
import welcomeImage from "./assets/Drone_surveillance.png";

/**
To use this popup you have to pass following props
  1) onRequestClose as function
  2) show as flag
  3) if You want to give height then pass width otherwise it take 50% by default

*/
export default class WelcomePopup extends Component {
  state = {
    user: this.props.user,
    imgLoad: true
  };

  componentWillMount() { }
  render() {
    const { user } = this.state;
    return (
      <div>
        <AnimatedModal
          height="425px"
          width="680px"
          isOpen={this.props.show}
        >
          <div className="flex-center" style={{ height: "94%" }}>
            <div className="flex-center" style={{ textAlign: "justify", justifyContent: "space-between", padding: "30px", width: "94%" }}>
              <div>
                <img
                  style={{
                    height: "auto",
                    width: "200px",
                    opacity: this.state.imgLoad ? 0 : 1
                  }}
                  src={welcomeImage}
                  loading='lazy'
                  onLoad={() => {
                    this.setState((state) => ({
                      ...state,
                      imgLoad: false
                    }))
                  }}
                ></img>
              </div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: '5px', height: '100%' }}>
                <div style={{ height: "66%", borderLeft: "2px solid #f1f1f1" }} />
              </div>
              <div>
                <div style={{ fontWeight: 500, fontSize: "25px"}} className="content-text">Hello {user.first_name} !</div>
                <div style={{ fontWeight: 500, fontSize: "24px", marginTop: "12px" }} className="content-text">Welcome to AeroMegh !</div>
                <div style={{ fontWeight: 500, marginTop: "12px", fontSize: "15px" }} className="content-text">To give you a better experience, </div>
                <div style={{ fontWeight: 500, fontSize: "15px" }} className="content-text">we need some information from you.</div>
                <div style={{ fontWeight: 500, fontSize: "15px" }} className="content-text">We promise it will take very less time.</div>
              </div>
            </div>
            <div style={{ display: "flex", position: "absolute", bottom: "32px", right: "5%" }}>
              <button
                style={{ padding: "11px 24px", fontSize: "15px", fontWeight: 500 }}
                className="add-user-form-submit-button btn-ok"
                onClick={this.props.close}
              >
                Ok ! Let's do it
              </button>
            </div>
          </div>
        </AnimatedModal>
      </div>
    );
  }
}
