import React, { Component } from 'react';
import * as API from '../../api.js';
import Loader from '../../icons/loader.svg';
import UsersActive from '../../usersActive.png';
import Search from "../../Icon/Search.png";
import service from "../../sidebaricon/Services.png";
import dateFormat from 'dateformat';
import { Link, StaticRouter, Redirect } from 'react-router-dom';
import SideBar from '../../SideBar.js';
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal';

export default class AnalyticUsers extends Component {

    state = {
        user: {},
        type: "",
        userdisplay: [],
        currentpage: 1,
        userperpage: 8,
        key: " ",
        showDownloadCsvPopup: false,
        cancel: false,
        fromDate: '',
        toDate: '',
        successParams: {
            title: "ADD USER",
            message1: "User has been added successfully",
            message2: "To go back to users, click",
            hereLink: "/admin/users",
            buttonText: "Add new user",
            buttonLink: "/admin/add_npnt_user"
        },
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
    }

    initDate = () => {
        let todaysDate = new Date()
        let currentDate = dateFormat(todaysDate, "yyyy-mm-dd")
        function getLastWeek() {
            var today = new Date();
            var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
            return lastWeek;
        }

        var lastWeek = getLastWeek();
        var lastWeekMonth = lastWeek.getMonth() + 1;
        var lastWeekDay = lastWeek.getDate();
        var lastWeekYear = lastWeek.getFullYear();
        var lastWeekDisplayPadded = ("0000" + lastWeekYear.toString()).slice(-4) + "-" + ("00" + lastWeekMonth.toString()).slice(-2) + "-" + ("00" + lastWeekDay.toString()).slice(-2);

        this.setState((state) => ({
            fromDate: lastWeekDisplayPadded,
            toDate: currentDate
        }))
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    onChangeListener = (e) => {
        let t = e.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.value
        }))
    }

    componentWillMount() {
        document.title = "Users";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.initDate()
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => {
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    successRedirectComponent = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/success",
                state: {
                    ...this.state.successParams,
                    user: this.state.user,
                    type: this.state.type,
                    sidebarMode: this.state.sidebarMode
                }
            }} />
        }
    }
    getUser = () => {
        const { user, type, userdisplay, currentpage, userperpage, key, counts } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;
        API.users(key == "" ? " " : key, firstpage, userperpage).then((result) => {
            let users = result.users;
            let count = result.count;
            this.setState(state => ({
                ...state,
                userdisplay: users,
                counts: count,
                loading: false
            }))

        })

    }
    componentDidMount() {
        this.getUser()
    }
    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getUser();
            });
        } else {
            console.log("Reached last page")
        }
    }

    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    this.getUser();

                });
        }
    }
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getUser();
            });
        } else {
            console.log("Reached last page")
        }
    }

    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                this.getUser();
            });
        } else {
            console.log("Reached last page")
        }
    }

    changepage(num) {
        console.log(isNaN(num))
        if (isNaN(num)) {

            return null;
        }
        else {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => this.getUser());
        }
    }

    getAllSurveyRecord() {
        let fDate = this.state.fromDate
        let tDate = this.state.toDate

        console.log(fDate)
        API.downloadSurveyRecords(fDate, tDate)
            .then(data => {
                this.setState((state) => ({
                    fromDate: '',
                    toDate: '',
                    showDownloadCsvPopup: false
                }))
            })
    }

    search(key) {
        this.setState((state) => ({
            ...state,
            key,
        }),
            () => this.getUser());
        console.log(key)
    }


    render() {
        const { user, type, userdisplay, currentpage, userperpage, counts } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: UsersActive,
                    text: "User Analytics",
                    path: "/analytictool/user_statistics",
                    selected: true,
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode
        }
        return (

            this.state.user && this.state.user.first_name ? <div className="wrapper">
                <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} />

                {/*----------------------- Download user survey data in CSV popup-----------------------  */}
                <AnimatedModal
                    isOpen={this.state.showDownloadCsvPopup}
                    onRequestClose={() => {
                        this.setState((state) => ({
                            ...state,
                            showDownloadCsvPopup: false,
                        }))
                    }}
                    height="260px"
                    width="400px"
                >
                    <div style={{ textAlign: "center", margin: '15px 0px', height: '30px', fontSize: '20px', fontWeight: '500', color: "#3c3c3c" }}>Download user survey data</div>
                    <div style={{ height: '5px', width: '100%' }}><hr /></div>
                    <div style={{ margin: '10px 80px', }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>From</div>
                            <div>
                                <input
                                    id="dateRequired"
                                    name="dateRequired"
                                    className="add-user-form-text"
                                    type="date"
                                    max='9000-01-01'
                                    defaultValue={this.state.fromDate}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        this.setState((state) => ({
                                            ...state,
                                            fromDate: value,
                                            selectedGoStyle: {}
                                        }))
                                    }}
                                    value={this.state.fromDate}
                                    required
                                    style={{
                                        marginLeft: "5px",
                                        height: "auto",
                                        borderRadius: '10px',
                                        paddingLeft: '5px',
                                        width: "auto",
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{ display: "flex", marginTop: "8%", justifyContent: "space-between" }}>
                            <div className='sub-add-user-form-label' style={{ display: "flex" }}>To</div>
                            <div>
                                <input
                                    id="dateRequired"
                                    name="dateRequired"
                                    defaultValue={this.state.toDate}
                                    className="add-user-form-text"
                                    type="date"
                                    max='9000-01-01'
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        this.setState((state) => ({
                                            ...state,
                                            toDate: value,
                                            selectedGoStyle: {}
                                        }))
                                    }}
                                    value={this.state.toDate}
                                    required
                                    style={{
                                        marginLeft: "5px",
                                        height: "auto",
                                        borderRadius: '10px',
                                        paddingLeft: '5px',
                                        width: "auto",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ margin: '30px 50px 10px 50px', display: 'flex', justifyContent: 'space-between' }}>
                        <button
                            className="addcustomer-button-empty-dashboard popup-btn-left"
                            onClick={() => {
                                this.setState((state) => ({
                                    ...state,
                                    fromDate: '',
                                    toDate: '',
                                    showDownloadCsvPopup: false,
                                }), () => {
                                    this.initDate()
                                });
                            }}
                        >
                            Cancel
                        </button>
                        {this.state.fromDate && this.state.toDate ?
                            <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    this.getAllSurveyRecord()
                                }}
                            >
                                Download
                            </button>
                            : <button disabled style={{ opacity: '0.5' }}
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                            >
                                Download
                            </button>}
                    </div>
                </AnimatedModal>




                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar" style={{ display: "flex" }}><div className="top-bar-text">USERS</div>
                    </div>
                    <div className="main-content">


                        <div style={{ display: "flex", justifyContent: 'space-between' }}>

                            <div className="services-content-title">{"Users"}</div>
                            <div className="add-user-form-text-wrapper" style={{ marginLeft: "auto", display: "flex" }}>
                                <Link to={{ pathname: '/analytictool/user_statistics', state: { user, type } }}>
                                    <div className="addcustomer-button-empty-dashboard" style={{ paddingLeft: '30px', paddingRight: '30px', cursor: "pointer", marginRight: "10px" }}>User Statistics</div>
                                </Link>
                                <div className="addcustomer-button-empty-dashboard" style={{ paddingLeft: '30px', paddingRight: '30px', cursor: "pointer" }} onClick={() => {
                                    this.setState((state) => ({
                                        showDownloadCsvPopup: true
                                    }))
                                }}>Download data</div>
                            </div>
                            <div className="add-user-form-row-left" style={{ marginLeft: '20px' }}>
                                <div className="add-user-form-text-wrapper">
                                    <img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
                                    <input type="text" className="add-user-form-text" placeholder="Search User" style={{ height: '30px !important', width: "235px", paddingLeft: '28px' }} onChange={(event) => this.search(event.target.value)} />
                                </div>
                            </div>
                        </div>

                        <table className="customers-table">
                            <tr className="customers-table-header-row">
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Registered date</th>
                            </tr>
                            {userdisplay.length === 0 ?
                                <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                    <td colspan="4">No Users Available</td>
                                </tr> :

                                userdisplay.map((user_info) =>

                                    <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                        <td>
                                            <Link style={{ color: "#707683" }} to={{
                                                pathname: `/analytictool/userdetails`,
                                                state: { user, type, user_info, sidebarMode: this.state.sidebarMode }
                                            }}>
                                                {user_info.first_name + " " + user_info.last_name}
                                            </Link></td>
                                        <td>{user_info.username}</td>
                                        <td>{user_info.phone}</td>
                                        <td>{user_info.created_on ? dateFormat(user_info.created_on, 'dd mmm yyyy hh:MM:ss TT') : "-"}</td>
                                    </tr>
                                )}

                        </table>
                        <footer className="footer" style={{ visibility: (this.state.counts > 8 ? "visible" : "hidden") }}>
                            <div style={{ float: "left", marginTop: "2rem", display: "flex" }}>
                                <div style={{ color: "#109cf1", marginTop: "11px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                    Page : {currentpage}
                                </div>


                            </div>
                            <div style={{ float: "right", marginTop: "2rem", display: "flex" }}>
                                <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", }} onClick={this.firstpage}>
                                    <div className="add-user-form-submit-button" >
                                        First
                                    </div>
                                </button>
                                <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px" }} onClick={this.prevpage}>
                                    <div className="addcustomer-button-empty-dashboard" >
                                        Prev
                                    </div>
                                </button>
                                <form>
                                    <div style={{ display: "flex" }}>
                                        <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                            Go to page :
                                        </div>
                                        {/* <input type="text" style={{height:"40px",width:"40px",textAlign:"center",color: "#109cf1",fontSize:"14px"}} value={currentpage} onChange={(event)=>this.changepage(parseInt(event.target.value))} name="currentpage"/> */}
                                        <div className="add-user-form-text-wrapper">
                                            <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                                                onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                                onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                        </div>
                                    </div>
                                </form>

                                <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px" }} onClick={this.nextpage}>
                                    <div className="addcustomer-button-empty-dashboard" >
                                        Next
                                    </div>
                                </button>
                                <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }} onClick={this.lastpage}>
                                    <div className="add-user-form-submit-button" >
                                        Last
                                    </div>
                                </button>

                            </div>


                        </footer>
                        {/* <footer>
                                    <div style={{float:"right",marginTop:"2rem",padding: "0px 10px 0px 10px"}} className="addcustomer-button-empty-dashboard">
                                    <InputGroup>
                                    <InputGroup.Append>
                                    <button style={{padding: "4px", outline: "none", border: "none", background: "#F5F6F8",color: "#109cf1"}}  onClick={this.prevpage}>
                                        Prev
                                    </button>
                                    <FormControl style={{width:"45px", color:"#109cf1",  border: "none",textAlign:"center",backgroundColor:"#F5F6F8" ,outline:"none",boxShadow:"none",fontSize:"12px"}} onChange={this.changepage} value={currentpage} name="currentpage"/>
                                    <button style={{padding: "4px", outline: "none", border: "none", background: "#F5F6F8",color: "#109cf1"}} onClick={this.nextpage}>
                                        Next
                                     </button>
                                    </InputGroup.Append>
                                    </InputGroup>
                                    </div>
                                </footer> */}
                    </div>
                </div>
            </div> : <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
            </div>
        );
    }
}
