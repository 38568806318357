import React, { useEffect, useState } from 'react'
import { AnimatedModal } from './AnimatedModal'
import AppConstants from '../AppConstants'
import ButtonWithIcon from "../ReusableComponents/ButtonWithIcon"
import * as API from "../api"
import Selector from './Selector'
import DropDownWithButton from './DropDownWithButton'
import closeBtn from "../icons/closeButton.png"
const GoBack = (props) => {
    return props.currentEntity.length > 1 && <button style={{ background: "none", color: "#2989cf", }} onClick={props.onClick}><ButtonWithIcon text={"back"} /></button>

}
const EntitySelector = (props) => {
    const [projects, setProjects] = useState([])
    const [plans, setPlans] = useState([])
    const [imagesets, setImagesets] = useState([])
    const [tasks, setTasks] = useState([])
    const [currentEntity, setCurrentEntity] = useState(["projects"])
    const [selectedEntities, setSelectedEntites] = useState({ entites: [], entityType: "", onClickOk: () => { }, onClickClose: () => { } })
    const [loading, setLoading] = useState({ status: true, message: "Projects" })
    const [selectedService, setSelectedService] = useState("")
    // const [service, setService] = useState("")
    const handleSelectedService = (service) => {
        setSelectedService(service)
        setLoading({ message: "Projects", status: true })
        API.getAllProjects(" ", 10000, 0, null, service).then(res => {
            setProjects(res.projects)
            setLoading({ message: "Projects", status: false })
        }).catch(err => console.log(err))
    }
    const handleOpenProject = (project) => {
        if (selectedService === AppConstants.SERVICES.PICSTORK) {

            if (props.entity === "detections") {
                setLoading({ status: true, message: "Detections" })
                // get all tasks of project
                API.getAllTask(project.id, 1, " ", 0, 100000, "PicStork").then(res => {
                    setTasks(res.tasks.filter(task => task.status === AppConstants.TASK.STATUS.COMPLETED && (task.tasktype == 2 || task.tasktype == 4)))
                    setCurrentEntity(prev => [...prev, "detections"])
                    setLoading({ status: false })
                }).catch(err => console.log(err))
                // get detections of all the tasks present in project
            } else {

                setLoading({ status: true, message: "Imagesets" })
                API.getLatestImageSets("project", 10000, project.id, 0).then(res => {
                    setImagesets(res.imageCollections)
                    setCurrentEntity(prev => [...prev, "imagesets"])
                    setLoading({ status: false })
                }).catch(err => console.log(err))
            }
        } else {
            setLoading({ status: true, message: "Plans" })
            API.getPlans(project.project_name, " ", 1000000, 1, undefined, project.id).then(res => {
                if (res?.plans) {

                    setPlans(res.plans)
                    setCurrentEntity(prev => [...prev, "plans"])
                } else {
                    setCurrentEntity(prev => [...prev, "plans"])
                }
                setLoading({ status: false })
            }).catch(err => console.log(err))
        }
    }
    const handleOpenPlan = (plan) => {
        setLoading({ status: true, message: "Imagesets" })
        API.getLatestImageSets("plan", 1000, plan.id, 0).then(res => {
            setImagesets(res.imageCollections)
            setCurrentEntity(prev => [...prev, "imagesets"])
            setLoading({ status: false })
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        handleSelectedService(props.serviceList[0])
    }, [])
    return (
        <>
            <AnimatedModal
                isOpen={props.show}
                height={"488px"}
                width={"500px"}
                onRequestClose={props.onRequestClose}
            >
                <div style={{ height: "100%", display: "flex", flexDirection: "column", position: "relative" }}>
                    <div style={{ position: "absolute", right: "30px", top: "15px", zIndex: "11", display: "flex", flexDirection: "column", alignItems: "end" }}>

                        <div style={{ width: "20px", cursor: "pointer" }} onClick={props.onRequestClose}><img style={{ width: "100%" }} src={closeBtn} /></div>
                        {!loading.status && <div style={{ marginTop: (currentEntity[currentEntity.length - 1] === "projects") ? "30px" : "0px" }}>
                            {(currentEntity[currentEntity.length - 1] === "projects") && <DropDownWithButton width={"100%"} selectedItem={selectedService} list={props.serviceList.map(service => ({
                                name: service, onClick: () => {
                                    handleSelectedService(service)
                                }
                            }))} />}
                        </div>}
                    </div>
                    {currentEntity[currentEntity.length - 1] === "projects" && <div style={{ height: "calc(100% - 66px)", display: "flex" }}>
                        <Selector
                            allowMultipleSelect={props.allowMultipleSelect}
                            loading={loading}
                            title={"Projects"}
                            data={projects.map(p => ({ ...p, item_name: p.project_name }))}
                            onClickItem={(project) => {
                                (props.entity === "plans" || props.entity === "imagesets" || props.entity === "detections") && handleOpenProject(project)
                            }}
                            isSelectable={props.entity === "projects"}
                            callback={(items) => {
                                setSelectedEntites({ entites: items, entityType: "projects" })
                            }}
                        />
                    </div>}
                    {currentEntity[currentEntity.length - 1] === "plans" && <div style={{ height: "calc(100% - 66px)", display: "flex" }}>
                        <Selector
                            allowMultipleSelect={props.allowMultipleSelect}
                            loading={loading}
                            title={"Plans"}
                            data={plans.map(p => ({ ...p, item_name: p.plan_name }))}
                            onClickItem={(plan) => props.entity === "imagesets" && handleOpenPlan(plan)}
                            isSelectable={props.entity === "plans"}
                            callback={(items) => {
                                setSelectedEntites({ entites: items, entityType: "plans" })
                            }}
                        />
                    </div>}
                    {currentEntity[currentEntity.length - 1] === "imagesets" && <div style={{ height: "calc(100% - 66px)", display: "flex" }}>
                        <Selector
                            allowMultipleSelect={props.allowMultipleSelect}
                            loading={loading}
                            title={"Imagesets"}
                            data={imagesets.map(p => ({ ...p, item_name: p.collection_name }))}
                            onClickItem={() => { }}
                            isSelectable={props.entity === "imagesets"}
                            callback={(items) => {
                                setSelectedEntites({ entites: items, entityType: "imagesets" })
                            }}
                        />
                    </div>}
                    {currentEntity[currentEntity.length - 1] === "detections" && <div style={{ height: "calc(100% - 66px)", display: "flex" }}>
                        <Selector
                            allowMultipleSelect={props.allowMultipleSelect}
                            loading={loading}
                            title={"Detections"}
                            data={tasks.map(p => ({ ...p, id: p.task_id, item_name: p?.task_name, status: p.status }))}
                            onClickItem={() => { }}
                            isSelectable={props.entity === "detections"}
                            callback={(items) => {
                                setSelectedEntites({ entites: items, entityType: "detections" })
                            }}
                        />
                    </div>}
                    <div style={{ display: "flex", justifyContent: 'space-between', padding: "20px 25px 20px 20px", borderTop: "1px solid #8080803d" }}>
                        <GoBack currentEntity={currentEntity} onClick={() => {
                            setCurrentEntity(prev => prev.slice(0, -1))
                            setSelectedEntites({ entites: [], entityType: "" })
                        }} />
                        <div style={{ display: "flex", width: "100%", justifyContent: "right", alignItems: "center" }}>

                            <ButtonWithIcon isBtnActive text={props.btnTitle} onClick={() => props.callback(selectedEntities)} isDisabled={selectedEntities.entites.length < 1} />
                        </div>
                    </div>


                </div>

            </AnimatedModal >
        </>

    )
}

export default EntitySelector