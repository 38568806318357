import React, { useState } from 'react'
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import DropDownWithButton from '../../ReusableComponents/DropDownWithButton';

const FloorSelector = (props) => {
    const [selectedFloor,setSelectedFloor]=useState(props.selectedFloor||props.floors[0])
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", position: "relative" }}>
            <div style={{ color: "#2989cf", textAlign: "left", backgroundColor: "#D9D9D9", padding: "10px 20px", fontWeight: "500", fontSize: "17px" }}>Select Floor</div>
            <div style={{ display: "flex", justifyContent: "right", padding: "20px" }}>
                <DropDownWithButton textColor={"rgb(102, 102, 102)"} maxHeight={"100px"} padding={"5px 10px"} isBorder height={"25px"} width={"100%"} selectedItem={`${selectedFloor.name} ${selectedFloor.number}`} list={props.floors.map((floor) => {

                    return {
                        name: `${floor.name} ${floor.number}`, onClick: () => {
                            setSelectedFloor(floor)
                           
                        }
                    }
                })} />
                <div style={{ marginRight: "10px" }}><ButtonWithIcon text={"Cancel"} onClick={()=>props.closePopUp(true)} /></div>
                <ButtonWithIcon padding={"4px 22px"} text={"Save"} onClick={() => {
                    props.getSelectedFloor(selectedFloor)
                    props.closePopUp();

                }} isBtnActive />
            </div>


        </div>
    )
}

export default FloorSelector