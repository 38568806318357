import React, { useEffect, useState } from "react";
import * as API from "../../../api.js";
import Loader from '../../../icons/loader.svg';
import { DRONENAKSHA_FEATURES } from "../../../Teams/index.js";


const keyStyle = { width: "46%", fontWeight: "400", color: "#666666" }
const valueStyle = { fontWeight: "500", color: "#3c3c3c" }
const mainDivStyle = { display: "flex", fontSize: "10px", marginTop: "15px" }

const Elevation = (props) => {
    return <div style={mainDivStyle}>
        <div style={keyStyle}>{props.tittle}</div>
        <div style={valueStyle}> {props.selectedUnit == 'm' ? `${props.value.toFixed(3)} m` : `${(Number(props.value) * 3.28084).toFixed(3)} ft`}</div>
    </div>
}

const Slope = (props) => {
    return <div style={mainDivStyle}>
        <div style={keyStyle}>{props.tittle}</div>
        <div style={valueStyle}> {props.value?`${props?.value?.toFixed(3)}°`:"-"}</div>
    </div>
}

const LineStatistcs = (props) => {
    const [loader, setLoader] = useState(true);
    const [error, setError] = useState(false);
    var [polylineStats, setLineStats] = useState(undefined)
    const getLineStatistics = () => {
        if (props.DSMAvailable) {
            API.getLineStatistics(props.orthoImageData.task_id, props.permissions?.container, props.permissions?.st, props.polyline.coordinates).then((lineStats) => {
                console.log("line stats", lineStats)
                setLineStats(lineStats)
                setLoader(false)
            }).catch((err) => {
                setLoader(false)
                setError(err.message)
            })
        }
    }
    useEffect(() => {
        getLineStatistics()
        setError(false)
    }, [props.polyline])
    return (
        <>
            <div className='content-text' style={{ height: "40px", display: "flex", alignItems: "center", backgroundColor: 'white', justifyContent: "space-between", padding: '10px 30px', borderBottom: '1px solid #e5e5e5' }}>
                <div style={{ backgroundColor: 'white', fontSize: "14px", fontWeight: "500", color: "#2989cf" }}>Measurements</div>
            </div>
            {props.isRegionInOrtho ?
                !props.DSMAvailable ? <div style={{ backgroundColor: 'white', color: "#3C3C3C", fontWeight: "400", fontSize: "10px", padding: "20px 30px" }}>
                    {'No elevation available.'}
                </div> :
                    error ? <div style={{ backgroundColor: 'white', color: "#3C3C3C", fontWeight: "400", fontSize: "10px", padding: "20px 30px" }}>
                        {error}
                    </div> :
                        <div style={{ color: "black" }}>
                            {loader ? <>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "20px 0px 0px 50px", }}>
                                    <img width="100px" src={Loader} />
                                </div>
                                <div style={{ textAlign: "center", color: "#3C3C3C", margin: "-10px auto 40px 20px" }}>Getting statistics, please wait...</div>
                            </> : polylineStats ?
                                <>
                                    <div style={{ backgroundColor: 'white', padding: "5px 30px 15px" }}>
                                        <Elevation selectedUnit={props.selectedUnit} tittle={"Min elevation"} value={polylineStats.minElevation} />
                                        <Elevation selectedUnit={props.selectedUnit} tittle={"Max elevation"} value={polylineStats.maxElevation} />
                                        <Elevation selectedUnit={props.selectedUnit} tittle={"Elevation difference"} value={polylineStats.elevationDifference} />
                                        <Elevation selectedUnit={props.selectedUnit} tittle={"Start elevation"} value={polylineStats.startingElevation} />
                                        <Elevation selectedUnit={props.selectedUnit} tittle={"End elevation"} value={polylineStats.endingElevation} />
                                        <Elevation selectedUnit={props.selectedUnit} tittle={"Avg elevation"} value={polylineStats.avgElevation} />
                                        {polylineStats.slope ? <Slope tittle={"Slope"} value={polylineStats.slope} /> :
                                            <>
                                                <Slope tittle={"Min slope"} value={polylineStats.minSlope} />
                                                <Slope tittle={"Max slope"} value={polylineStats.maxSlope} />
                                                <Slope tittle={"Avg slope"} value={polylineStats.avgSlope} />
                                            </>}
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", height: "5vh", filter: props.permissions[DRONENAKSHA_FEATURES.MAPS].READ ? "" : "grayscale(100%)", opacity: props.permissions[DRONENAKSHA_FEATURES.MAPS].READ ? "1" : "0.5" }}>
                                        <div className="addcustomer-button-empty-dashboard" style={{ padding: '5px 13px', marginBottom: "10px", cursor: 'pointer', fontSize: "10px" }}
                                            onClick={() => { props.permissions[DRONENAKSHA_FEATURES.MAPS].READ && props.onclickShowGraph() }}
                                        >
                                            {props.showElevationProfile ? 'Hide Elevation Profile' : 'Show Elevation Profile'}
                                        </div>
                                    </div>
                                </> : <></>
                            }
                        </div> : <div style={{ backgroundColor: 'white', color: "#3C3C3C", fontWeight: "400", fontSize: "10px", padding: "20px 30px" }}>
                    Polyline Is Out Of Bounds
                </div>
            }
        </>
    )
}


export default LineStatistcs