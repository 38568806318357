import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import closeButton from '../../../close-button.png';
import fail from '../../../assets/error.png';
import btnAdd from '../../assets/icons/btnAdd.svg'
import * as API from '../../../api.js';
import Menu from '../../assets/icons/menu.svg';
import { Link } from 'react-router-dom';
import ListIcon from '../../../icons/gridBlue.png'
import GridIcon from '../../../icons/listBlue.png'
import detailsIcon from '../../../assets/Icons/setDetails.png'
import classesIcon from '../../../assets/Icons/setClasses.png'
import summaryIcon from '../../../assets/Icons/setSummary.png'
import { AnimatedModal } from '../../../ReusableComponents/AnimatedModal';
import Stepper from 'react-stepper-horizontal'
import { Grid, Box } from '@material-ui/core'
import ImageSetThumb from '../../../icons/ImageSetThumb.png'
import { ActivityPopup } from '../../../ReusableComponents/ActivityPopup.js';
import { DeleteComponentGrid, DeleteComponentTable } from '../../../ReusableComponents/Menu';
import { SortComponent } from '../../../ReusableComponents/SortComponent';
import AppConstants, { BLOB_URL } from '../../../AppConstants.js';
import Noannotationset from '../../../assets/Images/Noannotationset.png';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={Menu} alt="I am alt" />
    </a>
));
export default class AddAndListAnnotsionSet extends Component {
    state = {
        annotationSets: [],
        cancel: "",
        loading: "",
        createIcon: "",
        showAnnotationSetDeletePopup: false,
        confirmDelete: false,
        deletingSetMessage: "",
        selectedAnnotationSetId: "",
        imageSetId: "",
        steps: [{
            title: 'Details',
            icon: detailsIcon,
        }, {
            title: 'Classes',
            icon: classesIcon

        }, {
            title: 'Summary',
            icon: summaryIcon

        }],
        currentStep: 0,
        addAnnotationSetPopup: false,
        createAnnotationSetMsg: "",
        classArray: [],
        annotation_set_name: "",
        image_collection: "",
        imageCollections: [],
        class: "",
        classError: "",
        createAnnotationSetMessage: "",
        closePopup: false,
        totalSets: 0,
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        thumbURLs: [],
        deleteAnnotationSet: false,
        selectedAnnotationSetName: "",
        gridView: true,
        showCopyImagePopup: false,
        annotableCollections: []
    };
    closeAnnotationSetPopup = () => {
        this.setState((state) => ({
            ...state,
            addAnnotationSetPopup: false,
            currentStep: 0,
            classArray: [],
            annotation_set_name: "",
            image_collection: this.state.imageCollections.length > 0 ? this.state.imageCollections[0].collection_name : "",
            classError: ""
        }))
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }

    // getAnnotationSets = () => {
    //     let { plan, limit } = this.props
    //     API.getAnnotationSets(plan.id).then(async (data) => {
    //         let dataTodisplay = data.data

    //         // asets = await this.loadAnnotationTiff(data.data)
    //         if (limit != -1 && data.data.length > limit)
    //             dataTodisplay = dataTodisplay.slice(0, limit)
    //         this.setState((state) => ({
    //             ...state,
    //             totalSets: data.data.length,
    //             annotationSets: dataTodisplay,
    //             loading: false
    //         }))
    //     }, (e) => {
    //         alert('Something went wrong');
    //         this.setState((state) => ({
    //             ...state,
    //             loading: false
    //         }))
    //     })
    // }


    copyAnnotationSet = () => {
        this.setState((state) => ({
            ...state,
            showCopyImagePopup: false,
            itemName: "Annotationset",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: "Duplicating your annotationset please Wait...",
            showProcessPopup: true,
        }))

        API.duplicateAnnotationSet(this.state.selectedAnnotationSetId, this.state.newAnnotationSetName).then((data) => {
            // if (data.collection) {
            console.log("THIS DATA : ", data)
            if (data.status == 200) {
                this.setState((state) => ({
                    ...state,
                    newCollecton: data.collection,
                    itemName: "Annotationset",
                    processPopupIcon: "COMPLETE",
                    processAction: "ADD",
                    processMessage: 'Annotationset copied successfully.',
                    showProcessPopup: true,
                }), () => {
                    this.props.refresh()
                })
            } else if (data.status == 202) {
                this.setState((state) => ({
                    ...state,
                    newCollecton: data.collection,
                    itemName: "Annotationset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: 'Annotationset already exist with same name.',
                    showProcessPopup: true,
                }))
            } else {
                this.setState((state) => ({
                    ...state,
                    newCollecton: data.collection,
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: 'Something went wrong when copying annotationset.',
                    showProcessPopup: true,
                }))
            }
        }, (e) => {
            this.setState((state) => ({
                ...state,
                itemName: "Annotationset",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: "Something went wrong",
                showProcessPopup: true,
            }))
        })
    }


    getImageCollections = () => {
        API.getImageCollections(this.props.project.id, this.props.plan.id, 5).then((data) => {
            let cols = data.collections.filter((col) => {
                if (col.isAnnotable === true && col.file && col.file.count > 0) return col
            })
            this.setState((state) => ({
                ...state,
                imageCollections: cols,
                annotableCollections: cols,
                image_collection: cols.length > 0 ? cols[0].collection_name : "",
            }))
        }, (e) => {
            alert('Something went wrong');
            this.setState((state) => ({
                ...state,
                loading: false
            }))
        })
    }

    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.props.annotationSets.sort((a, b) => a.annotation_set_name.localeCompare(b.collection_name))
        this.setState((state) => ({
            ...state,
            annotationSets: sort,
        }));
    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.props.annotationSets.sort((a, b) => b.annotation_set_name.localeCompare(a.annotation_set_name))
        this.setState((state) => ({
            ...state,
            annotationSets: sort,
        }));
    }
    sortByDate = () => {
        console.log('First Modified');
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.props.annotationSets.sort((a, b) => a.created_at.localeCompare(b.created_at))
        this.setState((state) => ({
            ...state,
            annotationSets: sort,
        }));
    }

    reverseByDate = () => {
        console.log('Last Modified');
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.props.annotationSets.sort((a, b) => b.created_at.localeCompare(a.created_at))
        this.setState((state) => ({
            ...state,
            annotationSets: sort,
        }));
    }

    deleteAnnotationSet = () => {
        this.setState((state) => ({
            itemName: "Annotationset",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.deleteAnnotationSet(this.state.imageSetId, this.state.selectedAnnotationSetId).then((data) => {
            this.setState((state) => ({
                itemName: "Annotationset",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
            }), () => {
                this.props.refresh()
            })
        }, (e) => {
            this.setState((state) => ({
                itemName: "Annotationset",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }
    addAnnotationSet = () => {
        this.closeAnnotationSetPopup()
        this.setState((state) => ({
            ...state,
            itemName: "Annotationset",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: "Creating annotationset please Wait...",
            showProcessPopup: true,
        }))
        let { annotation_set_name, classArray, image_collection } = this.state
        let classes = classArray.toString()
        let image_collection_id = (this.state.annotableCollections.find(data => data.collection_name == image_collection)).id
        API.addAnnotationSet(image_collection_id, annotation_set_name, classes, this.props.plan.id).then((data) => {
            console.log(data.status)
            if (data.status == 201) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Annotationset",
                    processPopupIcon: "COMPLETE",
                    processAction: "ADD",
                    processMessage: 'Annotationset created successfully.',
                    showProcessPopup: true,
                }), () => {
                    this.props.refresh()
                })
            } else if (data.status == 409) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Annotationset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: 'Annotationset already exist with same name.',
                    showProcessPopup: true,
                }))
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Annotationset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: 'Something went wrong.',
                    showProcessPopup: true,
                }))
            }
        }, (e) => {
            // console.log(e);
            this.setState((state) => ({
                ...state,
                createIcon: fail,
                createAnnotationSetMsg: 'Something went wrong',
            }))
        })
    }

    //Export all annotation set data in JSON files zip.
    exportData = () => {
        let annotationObjs = {
            "setName": this.state.selectedAnnotationSetName,
            "annotationSetId": this.state.selectedAnnotationSetId,
            "collectionId": this.state.imageSetId,
        }

        API.exportAnnotationData(annotationObjs)
            .then(
                (res) => {
                    res.blob().then(blob => window.URL.createObjectURL(blob))
                        .then(uril => {
                            var link = document.createElement("a");
                            link.href = uril;
                            link.download = `${this.state.selectedAnnotationSetName}.zip`;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            this.setState((state) => ({
                                ...state,
                                //showPopup: false,
                                savingLoader: false
                            }));
                        })
                }
            ).catch((e) => {
                if (e.code === 202) {
                    this.setState((state) => ({
                        itemName: "Annotationset",
                        processPopupIcon: "ERROR",
                        processAction: "ERROR",
                        processMessage: "No annotated images were found in this dataset.",
                        showProcessPopup: true,
                    }))
                }
            })
    }

    changeImageSet = (e) => {
        let val = e.target.value;
        if (this.state.annotableCollections.length > 0) {
            let collection = this.state.annotableCollections.find(data => data.collection_name == val)
            if (collection) {
                let count = collection.file ? collection.file.count : 0
                this.setState((state) => ({
                    ...state,
                    media_name: val,
                    image_collection: val,
                    collection_id: collection.id,
                    imageCount: count
                }))
            }
        }
    }
    componentWillMount() {
        this.setState((state) => ({
            ...state,
            annotationSets: this.props.annotationSets
        }), () => {
            this.getImageCollections()
        })
    }
    onClickNext = () => {
        let { steps, currentStep } = this.state;
        this.setState((state) => ({
            currentStep: currentStep + 1,
        }))
    }
    handleChangeCopy = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else
            if (e.target.value.includes(' ')) {
                // setError("Spaces are not allowed.")
            } else {
                // setError(undefined)
                this.setState({ annotation_set_name: e.target.value });
            }
    }


    handleCopyName = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else
            if (e.target.value.includes(' ')) {
                // setError("Spaces are not allowed.")
            } else {
                // setError(undefined)
                this.setState({ newAnnotationSetName: e.target.value });
            }
    }

    changeClass = (event) => {
        this.setState({ class: event.target.value, classError: "" });
    }
    addClass = () => {
        let arr = this.state.classArray
        let temp = this.state.class
        temp = temp.toLowerCase().trim().replaceAll(" ", "_");
        temp = temp.replaceAll(",", "_")
        let isExist = arr.findIndex(data => data == temp)
        if (arr.length == 10)
            this.setState({ classError: "Maximum 10 classes can be added", class: "" });
        else if (isExist != -1)
            this.setState({ classError: "Class already added", class: "" });
        else if (this.state.class && temp.length > 0) {
            arr.push(temp)
            this.setState({ classArray: arr, class: "" });
        }
    }
    removeClass = (i) => {
        let arr = this.state.classArray
        arr.splice(i, 1)
        this.setState({ classArray: arr, class: "" });
    }
    onClickPrevious = () => {
        let { steps, currentStep } = this.state;
        this.setState((state) => ({
            currentStep: currentStep - 1,
        }))
    }
    getImageCollectionName = (id) => {
        let { imageCollections } = this.state
        let data = imageCollections.find(coll => coll.id == id)
        return data ? data.collection_name : ''
    }
    render() {
        let { addAnnotationSetPopup, steps, currentStep, annotation_set_name, image_collection, imageCollections, classArray, classError, annotableCollections } = this.state
        let { annotationSets, user, type, project, plan, fromPage } = this.props;
        return (
            <div>

                <ActivityPopup
                    item={this.state.itemName}
                    open={this.state.showProcessPopup}
                    icon={this.state.processPopupIcon}
                    action={this.state.processAction}
                    msg={this.state.processMessage}
                    close={() => this.closeActivityPopup()}
                    onClickOk={() => { this.state.deleteAnnotationSet ? this.deleteAnnotationSet() : this.closeActivityPopup() }}
                />

                {/* Copy annotation set popup */}
                <Modal
                    style={{ marginTop: '150px' }}
                    show={this.state.showCopyImagePopup}
                    onHide={() => {
                        this.setState((state) => ({
                            ...state,
                            showCopyImagePopup: false,
                            newAnnotationSetName: ''

                        }));
                    }}
                >
                    <Modal.Body >
                        <div style={{ marginBottom: "30px" }}>
                            <div className="services-content-title" style={{ fontSize: "18px" }}>{"Copy Annotationset"}</div>
                            {/* <label className="font-color-secondary" style={{ fontSize: "14px" }}>To upload images you need to create an imageset.</label> */}
                            <input type="text" value={this.state.newAnnotationSetName} className="add-user-form-text" placeholder="eg. Annotationset Name" maxLength={AppConstants.POPUP_INPUT_TEXT_LIMIT}
                                onChange={(e) => { this.handleCopyName(e) }} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="addcustomer-button-empty-dashboard popup-btn-left"
                            onClick={() => {
                                this.setState((state) => ({
                                    ...state,
                                    showCopyImagePopup: false,
                                }));
                            }}
                        >
                            cancel
                        </button>
                        {this.state.newAnnotationSetName ?
                            <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    this.copyAnnotationSet()
                                }}
                            >
                                Ok
                            </button> :
                            <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                style={{ opacity: '0.5', cursor: 'default' }}
                            >
                                Ok
                            </button>}

                    </Modal.Footer>
                </Modal>


                {/*-------------Add annotation set popup------------------ */}
                <AnimatedModal
                    height="520px"
                    width="660px"
                    onRequestClose={this.closeAnnotationSetPopup}
                    isOpen={addAnnotationSetPopup}
                >
                    <div className='content-text' style={{ background: "#E0E0E0", height: "100%", width: "100%" }}>
                        <div style={{ background: "#FFFFFF", height: "150px" }}>
                            <div className="services-content-title" style={{ margin: "0% 2%", display: "flex", flexDirection: "row", }}>
                                <div style={{ marginLeft: "35%", marginTop: "3%" }}>Create Annotationset</div>
                                <img src={closeButton
                                } style={{ marginLeft: "33%", marginTop: "3%", height: "20px", cursor: "pointer" }} onClick={this.closeAnnotationSetPopup}></img>
                            </div>
                            <Stepper titleFontSize={12} circleFontSize={2} size={32} steps={steps} activeStep={currentStep}
                                activeColor={"#2989cf"}
                                completeColor={"#2989cf"}
                                activeTitleColor={"#2989cf"}
                                completeTitleColor={"#2989cf"}
                                completeBarColor={"#2989cf"}
                            />
                        </div>
                        <div className='content-text' style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            {currentStep == 0 ? (
                                <div style={{ margin: "5% 20%", display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                                    <div>
                                        <div>Enter name <br /></div>
                                        <input type="text" value={this.state.annotation_set_name} className="add-user-form-text" placeholder="Name" maxLength={AppConstants.POPUP_INPUT_TEXT_LIMIT}
                                            onChange={this.handleChangeCopy} style={{ width: "95%" }} />
                                        <div style={{ marginTop: "5%" }} >Enter Image set <br /></div>
                                        <div>
                                            <select
                                                onChange={(event) => this.changeImageSet(event)}
                                                className="add-user-form-text2"
                                                value={image_collection}
                                                style={{ width: "95%" }}
                                            >
                                                {annotableCollections.length > 0 ? (
                                                    annotableCollections.map((type, i) => {
                                                        return (
                                                            <option key={i} value={type.collection_name}>
                                                                {type.collection_name}
                                                            </option>
                                                        );
                                                    }
                                                    )) :
                                                    (<option selected disabled>No ImageSet available</option>)
                                                }
                                            </select>
                                        </div>
                                        <div style={{ marginTop: "10px", fontSize: "11px", }}>( A set of annotations cannot be created if the image exceeds 3000 pixels in height and width. )</div>
                                    </div>
                                </div>
                            )
                                : currentStep == 1 ? (
                                    <div style={{ margin: "5% 20%", display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                                        <div>
                                            <div>Enter class name <br /></div>
                                            <input type="text" maxLength={30} value={this.state.class} className="add-user-form-text" placeholder="class name"
                                                onChange={this.changeClass} style={{ width: "85%" }} />
                                            <img src={btnAdd} style={{ height: '25px', marginLeft: '25px', cursor: 'pointer' }}
                                                onClick={this.addClass}
                                            />
                                            <div style={{ fontSize: "11px", color: "brown" }}>{classError}</div>
                                            <div style={{ marginTop: "3%", color: "#404040" }} >Classes <br /></div>
                                            <div style={{ display: "flex" }}>
                                                <div className="rpas-model-info-primary-details-value1" style={{ marginTop: '2px', height: "150px", overflowY: 'auto' }}>{
                                                    // detectionType.toString()
                                                    classArray.map((type, i) => {
                                                        return <button className="aerocapture-detection-type-list" style={{ paddingRight: '4px', paddingTop: '2px', paddingBottom: '2px' }}>{type}<img onClick={() => { this.removeClass(i) }} style={{ height: '16px', width: '16px', cursor: 'pointer', marginLeft: '14px' }} src={closeButton} /></button>
                                                    })
                                                }</div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ margin: "2% 18%", height: "100%", display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                                        <Box>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <div className="rowItem">
                                                        <div style={{ fontSize: "12px" }} className="itemKey">Name</div>
                                                        <div style={{ fontSize: "14px", }} className="itemValue">{annotation_set_name.length > 18 ?
                                                            `${annotation_set_name.substring(0, 18)}...` :
                                                            `${annotation_set_name}`}
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1} style={{ marginTop: "3%" }}>
                                                    <div style={{ borderLeft: "2px solid #B0B0B0", height: "60px" }} />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <div className="rowItem">
                                                        <div style={{ fontSize: "12px" }} className="itemKey">ImageSet</div>
                                                        <div style={{ fontSize: "14px", }} className="itemValue">{image_collection}</div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <div style={{ fontSize: "12px", marginTop: "2%", marginLeft: "3%", color: "#404040" }} >Classes <br /></div>
                                        <div style={{ display: "flex", marginLeft: "2%", }}>
                                            <div className="rpas-model-info-primary-details-value1" style={{ marginTop: '2px', height: "150px", overflowY: 'auto' }}>{
                                                // detectionType.toString()
                                                classArray.map((type, i) => {
                                                    return <button className="aerocapture-detection-type-list" style={{ paddingRight: '4px', paddingTop: '2px', paddingBottom: '2px' }}>{type}<img onClick={() => { this.removeClass(i) }} style={{ height: '16px', width: '16px', cursor: 'pointer', marginLeft: '14px' }} src={closeButton} /></button>
                                                })
                                            }</div>
                                        </div>
                                    </div>
                                )
                            }
                            <div style={{ width: "100%", padding: "0% 5%", display: "flex", justifyContent: "space-between", bottom: "6%", position: "absolute" }}>
                                {currentStep != 0 ?
                                    <button className="addcustomer-button-empty-dashboard popup-btn-left" onClick={this.onClickPrevious}>Previous</button>
                                    : ''
                                }
                                {(currentStep == 0 && annotation_set_name && image_collection) || ((currentStep == 1 || currentStep == 2) && classArray.length > 0) ? (
                                    currentStep != 2 ?
                                        <button className="add-user-form-submit-button popup-btn-right btn-ok" onClick={this.onClickNext}>Next</button>
                                        : <button className="add-user-form-submit-button popup-btn-right btn-ok" onClick={this.addAnnotationSet}>Create</button>
                                ) : (currentStep != 2 ?
                                    <button className="add-user-form-submit-button popup-btn-right btn-ok" style={{ opacity: '0.5', cursor: 'default' }} >Next</button>
                                    : <button className="add-user-form-submit-button popup-btn-right btn-ok" style={{ opacity: '0.5', cursor: 'default' }} >Create</button>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </AnimatedModal>
                {/*---------------------------------------------------------------- */}


                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    {this.props.limit != -1 && fromPage !== 'planDashboard' ?
                        <div className="services-content-title" style={{ padding: "15px 0px 15px 15px" }}>Annotationsets
                            <span className='data-count'>({this.props.totalSets})</span>
                        </div> : ''}
                    <div style={{ display: 'flex' }}>
                        {/* <div className="add-user-form-text-wrapper" style={{ marginLeft: "auto", display: 'flex', paddingTop : "15px" }}>
                            {this.props.limit != -1 && fromPage === 'planDashboard' ?
                                <Link to={{ pathname: "/picstork/annotation_sets", state: { user, type, sidebarMode: this.state.sidebarMode, project, plan } }}>
                                    <div className="addcustomer-button-empty-dashboard" style={{ paddingLeft: '30px', paddingRight: '30px' }}>View All</div>
                                </Link> : ''
                            }
                        </div> */}
                        {this.props.limit == -1 && fromPage !== 'planDashboard' ?
                            (<div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="services-content-title">{"Annotationsets"}
                                    <span className='data-count'>({this.props.totalSets})</span>
                                </div>
                                <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }} onClick={this.lastpage}>
                                    <div className="add-user-form-submit-button" onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            addAnnotationSetPopup: true,
                                        }))
                                    }}>
                                        Add Annotationset
                                    </div>
                                </button>
                            </div>)
                            :
                            (<div style={{ marginLeft: '25px', display: "flex", paddingRight: '15px' }}>
                                {fromPage !== 'planDashboard' ?
                                    <div style={{ display: 'flex' }}>
                                        <div className="add-user-form-text-wrapper" style={{ display: "flex", alignItems: 'center', }} >
                                            <img src={btnAdd} style={{ height: '26px', width: '34px', cursor: 'pointer' }}
                                                onClick={() => {
                                                    this.setState((state) => ({
                                                        ...state,
                                                        addAnnotationSetPopup: true,
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="add-user-form-text-wrapper" style={{ display: "flex", alignItems: 'center', }} >
                                            <div
                                                onClick={() => {
                                                    this.setState((state) => ({
                                                        ...state,
                                                        gridView: !state.gridView,
                                                    }))
                                                }}>
                                                <img src={this.state.gridView ? GridIcon : ListIcon} style={{ height: '24px', width: '24px', cursor: 'pointer', margin: '0px 4px 0px 14px' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <></>}
                            </div>)}
                    </div>
                </div>
                {annotationSets.length > 0 && this.state.gridView && fromPage !== 'planDashboard' ? <>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: '25px', width: '100%' }}>
                        <div style={{ width: "99.5%", borderTop: "1px solid rgba(102, 102, 102, 0.2)" }} />
                    </div>
                    {annotationSets.length > 0 && !this.state.loading ?
                        <div style={{ height: "30px" }}>
                            <SortComponent
                                label={'Name'}

                                sortAscending={this.sortByName}
                                sortDecending={this.reverseByName}
                                sortASC={true}
                            />
                        </div>
                        : <></>}
                </> : <></>}
                {annotationSets.length > 0 ? this.state.gridView ? (
                    <div className="aerocapture-cards-tray-projects scroll-container" style={{ paddingTop: '0px' }}>
                        {annotationSets.map((collection, key) => {
                            return (
                                <div >
                                    <Link to={{
                                        pathname: `/picstork/annotation_set`,
                                        state: { user, type, project, collection, plan }
                                    }}>
                                        {<div key={key} className="customer-cards-dashboard card"
                                            style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)' }}>
                                            <div className="card-header customer-cards-dashboard-logo project-card-header"
                                                style={{
                                                    dispaly: "relative"
                                                }}>
                                                < img id='ProjectImageRPM' style={{ objectFit: 'cover' }} src={`${BLOB_URL}/${user.blob_container}/FlightImages/collection-${collection.image_collection_id}/thumb-${collection.file.file_name}?${user.sas_token}`}
                                                    onError={(e) => { e.target.onerror = null; e.target.style = { undefined }; e.target.src = ImageSetThumb }} />

                                                <div>
                                                    <DeleteComponentGrid
                                                        style={{ position: "absolute", top: "5px", right: "5px" }}
                                                        canExport={true}
                                                        canDuplicate={true}
                                                        onClickDelete={(e) => {
                                                            e.preventDefault()
                                                            this.setState((state) => ({
                                                                ...state,
                                                                deleteAnnotationSet: true,
                                                                selectedAnnotationSetId: collection.id,
                                                                imageSetId: collection.image_collection_id,
                                                                itemName: "Annotationset",
                                                                processPopupIcon: "WARNING",
                                                                processAction: "DELETE",
                                                                processMessage: null,
                                                                showProcessPopup: true,
                                                            }))
                                                        }}

                                                        onclickDuplicate={(e) => {
                                                            e.preventDefault()
                                                            this.setState((state) => ({
                                                                ...state,
                                                                showCopyImagePopup: true,
                                                                selectedAnnotationSetId: collection.id,
                                                                newAnnotationSetName: `${collection.annotation_set_name}Copy`,
                                                            }))
                                                        }}

                                                        onclickExport={(e) => {
                                                            e.preventDefault()
                                                            this.setState((state) => ({
                                                                ...state,
                                                                selectedAnnotationSetId: collection.id,
                                                                imageSetId: collection.image_collection_id,
                                                                itemName: "Annotationset",
                                                                selectedAnnotationSetName: collection.annotation_set_name
                                                            }), () => {
                                                                this.exportData()
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="card-body change-color customer-cards-dashboard-text project-card-body">
                                                <div>
                                                    <span>{
                                                        collection.annotation_set_name.length > 18 ?
                                                            `${collection.annotation_set_name.substring(0, 18)}...` :
                                                            `${collection.annotation_set_name}`
                                                    }</span>
                                                    <div style={{ display: "flex", flexDirection: "row", fontSize: "10px", justifyContent: "space-between", color: "#fff" }}>
                                                        <span>{
                                                            this.getImageCollectionName(collection.image_collection_id).length > 10 ? `${this.getImageCollectionName(collection.image_collection_id).substring(0, 10)}...` :
                                                                `${this.getImageCollectionName(collection.image_collection_id)}`
                                                        }</span>
                                                        <span>{
                                                            (() => {
                                                                let date = (new Date(collection.created_at)).toGMTString().split(' ').slice(1, 4).join(' ');
                                                                return `${date}`
                                                            })()
                                                        }</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    </Link>
                                </div>
                            )
                        })}
                    </div>) :
                    <div id='table ' className='scroll-container' style={{ marginTop: '30px' }}>
                        <table className="customers-table" style={{ marginTop: '0px' }}>
                            <col style={{ width: '5%' }} />
                            <col style={{ width: '30%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '5%' }} />
                            <tr className="customers-table-header-row" style={{ background: "#EAEAEA" }}>
                                <th></th>
                                <th>Name
                                    <SortComponent
                                        style={{ marginLeft: '5px' }}

                                        sortAscending={this.sortByName}
                                        sortDecending={this.reverseByName}
                                        sortASC={false}
                                    />
                                </th>
                                <th>Images</th>
                                <th>Date Created
                                    <SortComponent
                                        style={{ marginLeft: '5px' }}

                                        sortAscending={this.sortByDate}
                                        sortDecending={this.reverseByDate}
                                        sortASC={false}
                                    />
                                </th>
                                <th></th>
                            </tr>
                            {
                                annotationSets.map((collection, i) => {
                                    return collection && collection.annotation_set_name ?
                                        <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                            <td>
                                                {
                                                    collection && collection.file ?
                                                        <img id="ProjectTableImage" style={{ objectFit: 'cover', padding: 'unset' }} loading="true" src={`${BLOB_URL}/${user.blob_container}/FlightImages/collection-${collection.image_collection_id}/thumb-${collection.file.file_name}?${user.sas_token}`}
                                                            onError={(e) => { e.target.onerror = null; e.target.style = { undefined }; e.target.src = ImageSetThumb }} /> :
                                                        <img id="ProjectTableImage" src={ImageSetThumb} />
                                                }
                                            </td>
                                            <td>
                                                <Link
                                                    style={{ color: "#707683" }}
                                                    to={{
                                                        pathname: `/picstork/annotation_set`,
                                                        state: { user, type, project, collection, plan }
                                                    }}
                                                >
                                                    {collection.annotation_set_name.length > 18 ?
                                                        `${collection.annotation_set_name.substring(0, 18)}...` :
                                                        `${collection.annotation_set_name}`}
                                                </Link>
                                            </td>
                                            <td>{this.getImageCollectionName(collection.image_collection_id)}</td>
                                            <td>{
                                                (() => {
                                                    let date = (new Date(collection.created_at)).toGMTString().split(' ').slice(1, 4).join(' ');
                                                    return `${date}`
                                                })()
                                            }</td>
                                            <td>
                                                <DeleteComponentTable
                                                    style={{ margin: "0 20px" }}
                                                    canExport={true}
                                                    onClickDelete={(e) => {
                                                        e.preventDefault()
                                                        this.setState((state) => ({
                                                            ...state,
                                                            deleteAnnotationSet: true,
                                                            selectedAnnotationSetId: collection.id,
                                                            imageSetId: collection.image_collection_id,
                                                            itemName: "Annotationset",
                                                            processPopupIcon: "WARNING",
                                                            processAction: "DELETE",
                                                            processMessage: null,
                                                            showProcessPopup: true,
                                                        }))
                                                    }}
                                                    onclickExport={(e) => {
                                                        e.preventDefault()
                                                        this.setState((state) => ({
                                                            ...state,
                                                            selectedAnnotationSetId: collection.id,
                                                            imageSetId: collection.image_collection_id,
                                                            itemName: "Annotationset",
                                                            selectedAnnotationSetName: collection.annotation_set_name
                                                        }), () => {
                                                            this.exportData()
                                                        })
                                                    }}
                                                />
                                            </td>
                                        </tr> : ''
                                }
                                )}
                        </table>
                    </div> :
                    fromPage === 'planDashboard' ?
                        <div
                            className="rmp-dashboard-empty-data-message" style={{ marginTop: '8%' }}
                        >
                            <img style={{ width: '25%' }} src={Noannotationset} />
                            <p style={{ marginTop: '10px' }}>No Annotationsets available</p>
                        </div>
                        :
                        <div className="rmp-dashboard-empty-data-message" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                            <img style={{ height: '130px' }} src={Noannotationset} />
                            <p style={{ marginTop: '10px' }}>No Annotationsets available</p>
                        </div>
                }
            </div>
        )
    }
}