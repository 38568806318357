import React, { useEffect, useState } from 'react';
import ButtonWithIcon from './ButtonWithIcon';
import { ActivityPopup } from './ActivityPopup';
import OrthoImporter from './OrthoImporter';
import ortho from "../assets/toolbarIcons/OrthoInactive.png";
import DEM from "../assets/toolbarIcons/DSMInactive.png";
import DTM from "../assets/toolbarIcons/DTMInactive.png";
import ThreeDPointCloud from "../assets/toolbarIcons/ThreeDPointCloudInactive.png";
import { AnimatedModal } from './AnimatedModal';
let imageTypes;

const ImportOrtho = ({ userId, planId, projectId, onClick, taskId, collectionId, taskName, onClickOkButton, orthoData, pointCloud, threeD, laslaz, showUploadErrWarning }) => {

  const card1Div = { textAlign: "center", width: "100px", height: "125px", border: "1px solid rgb(234, 234, 234)", borderRadius: "5px" };
  const card2Div = { height: "80px", backgroundColor: "#eaeaea", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" };
  const imgDiv = { height: "23px", width: "23px", margin: "20px 0 6px 0" };

  const [itemName, setItemName] = useState(undefined);
  const [processPopupIcon, setProcessPopupIcon] = useState(undefined);
  const [processMessage, setProcessMessage] = useState(undefined);
  const [showProcessPopup, setShowProcessPopup] = useState(false);
  const [fileType, setFileType] = useState(undefined);
  const [extention, setExtention] = useState(undefined);
  const [orthoActive, setOrthoActive] = useState(false);
  const [demActive, setDemActive] = useState(false);
  const [dtmActive, setDtmActive] = useState(false);
  const [pointCloud1, setPointCloud] = useState(false);
  const [threeD1, setThreeD] = useState(false);
  const [laslaz1, setLasLaz] = useState(false);

  useEffect(() => {
    imageTypes = orthoData.map(e => {
      return e.image_type;
    });
    imageTypes.forEach(element => {
      if (element === "orthomosaic") setOrthoActive(true)
      if (element === "dem") setDemActive(true)
      if (element === "dtm") setDtmActive(true)
      if (element === "pointCloud") setPointCloud(true)
    });
    if (threeD) setThreeD(true);
    if (pointCloud) setPointCloud(true);
    if (laslaz) setLasLaz(true);
  }, [orthoData, pointCloud, threeD, laslaz]);

  const closeActivityPopup = () => {
    setShowProcessPopup(false);
  }

  const onSelectTypeForUpload = () => {
    setItemName(undefined);
    setShowProcessPopup(false);
    setProcessPopupIcon(undefined);
    setProcessMessage(undefined);
    document.getElementById("hiddenFileInputUpload1").click();
  }

  return <>
    {/* ---------------------- Activity PopUp ------------------*/}
    <ActivityPopup
      item={itemName}
      open={showProcessPopup}
      icon={processPopupIcon}
      // action={this.state.processAction}
      msg={processMessage}
      close={() => closeActivityPopup()}
      onClickOk={() => {
        setExtention(null);
        onSelectTypeForUpload()
      }}
    />
    {/* ------------------------------------------------------- */}
    {
      <div style={{ display: "none" }}>
        <OrthoImporter
          user={userId}
          planId={planId}
          projectId={projectId}
          collectionId={collectionId}
          taskName={taskName}
          taskId={taskId}
          extention={extention}
          fileType={fileType}
          showUploadErrWarning={() => showUploadErrWarning()}
          onImageUpload={() => {
            setExtention(null);
            onClick()
          }}
        />
      </div>
    }

    <AnimatedModal
      isOpen={true}
      height="365px"
      width="415px">

      <div>
        <div style={{ display: "flex", justifyContent: "space-between", margin: "21px", height: "100px" }}>
          <div style={card1Div} >
            <div style={card2Div} >
              <img src={ortho} alter="Orthomosaic" style={{ height: "20px", width: "25px", margin: "25px 0 6px 0" }} /> <br />
              <span style={{ fontSize: "12px", color: "#3c3c3c" }}> Ortho</span>
              <br />
              <ButtonWithIcon text="Upload" height="20px" width="75px" margin="18px 10px 10px 10px" fontSize="8px" fontWeight="400"
                isDisabled={orthoActive}
                onClick={() => {
                  setFileType("Ortho");
                  setExtention(".tif, .tiff");
                  setItemName("WARNING");
                  setProcessPopupIcon("WARNING");
                  setProcessMessage("Make sure you are uploading Cloud Optimized GeoTIFF (COG). Do you want to continue ?");
                  setShowProcessPopup(true);
                }}
              />
            </div>
          </div>
          <div style={card1Div} >
            <div style={card2Div} >
              <img src={DEM} alter="Orthomosaic" style={imgDiv} /> <br />
              <span style={{ fontSize: "12px", color: "#3c3c3c" }}> DSM </span>
              <br />
              <ButtonWithIcon text="Upload" height="20px" width="75px" margin="18px 10px 10px 10px" fontSize="8px" fontWeight="400"
                isDisabled={demActive}
                onClick={() => {
                  setFileType("DSM");
                  setExtention(".tif, .tiff");
                  setItemName("WARNING");
                  setProcessPopupIcon("WARNING");
                  setProcessMessage("Make sure you are uploading Cloud Optimized GeoTIFF (COG). Do you want to continue ?");
                  setShowProcessPopup(true);
                }}
              />
            </div>
          </div>
          <div style={card1Div} >
            <div style={card2Div} >
              <img src={DTM} alter="Orthomosaic" style={{ height: "15px", width: "23px", margin: "27px 0 6px 0" }} /> <br />
              <span style={{ fontSize: "12px", color: "#3c3c3c" }}> DTM</span>
              <br />
              <ButtonWithIcon text="Upload" height="20px" width="75px" margin="18px 10px 10px 10px" fontSize="8px" fontWeight="400"
                isDisabled={dtmActive}
                onClick={() => {
                  setFileType("DTM");
                  setExtention(".tif, .tiff");
                  setItemName("WARNING");
                  setProcessPopupIcon("WARNING");
                  setProcessMessage("Make sure you are uploading Cloud Optimized GeoTIFF (COG). Do you want to continue ?");
                  setShowProcessPopup(true);
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ display: "flex", margin: "50px 21px 21px 21px", height: "100px" }}>

          {/*----- Point Cloud import ----- */}
          <div style={card1Div} >
            <div style={card2Div} >
              <img src={ThreeDPointCloud} alter="Orthomosaic" style={imgDiv} /> <br />
              <span style={{ fontSize: "12px", color: "#3c3c3c" }}> LAS/LAZ </span>
              <br />
              <ButtonWithIcon text="Upload" height="20px" width="75px" margin="18px 10px 10px 10px" fontSize="8px" fontWeight="400"
                isDisabled={pointCloud1 || laslaz1}
                onClick={() => {
                  setFileType("PntCld");
                  setItemName("WARNING");
                  setProcessPopupIcon("WARNING");
                  setProcessMessage("Make sure you are uploading Las/Laz file. Do you want to continue ?");
                  setShowProcessPopup(true);
                }}
              />
            </div>
          </div>

          {/*----- 3D import ----- */}
          {/* <div style={{ height: "80px", backgroundColor: "#eaeaea", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", marginLeft:"36px" }} >
            <div style={card2Div} >
              <img src={ThreeD} alter="Orthomosaic" style={imgDiv} /> <br />
              <span style={{ fontSize: "12px", color:"#3c3c3c" }}> 3D </span>
              <br />
              <ButtonWithIcon text="Upload" height="20px" width="75px" margin="18px 10px 10px 10px" fontSize="8px" fontWeight="400"
                isDisabled={threeD1}
                onClick={() => {
                  setFileType("3D");
                  setItemName("WARNING");
                  setProcessPopupIcon("WARNING");
                  setProcessMessage("Make sure you are uploading ZIP file contains 3D and Point-Cloud data. Do you want to continue ?");
                  setShowProcessPopup(true);
                }}
              />
            </div>
          </div> */}
        </div>

        <hr style={{ marginTop: "45px" }} />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ButtonWithIcon
            text="Ok" width="94px" isBtnActive onClick={() => {
              onClickOkButton();
            }} margin="-7px 24px"
          />
        </div>
      </div>
    </AnimatedModal >
  </>
}
export default ImportOrtho;

