import React, { Component } from 'react';
import dashboardIcon from '../../icons/AeroCaptureInactive.png';
import projectsIcon from '../../assets/sideBarIcons/Projects.png';
import Search from "../../Icon/Search.png";
import * as API from '../../api.js';
import service from "../../sidebaricon/Services.png";
import Popup from "reactjs-popup";
import Loader from '../../icons/loader.svg';
import { Link } from 'react-router-dom';
import SideBar from '../../SideBar';
import Menu from '../assets/icons/menu.svg';
import closeButton from '../../close-button.png';
import waiting from '../../res/waiting.png';
import Noproject from '../../assets/Images/Noproject.png';
import { connect } from "react-redux";
import { addUserAnalytic, updateOutTime, checkSubscriptionAndStorage } from '../../ReusableComponents/reusableFunctions';
import ProjectThumb from '../../assets/sideBarIcons/ProjectInactive.png'
import { CreateProjectPlanModal } from '../../ReusableComponents/CreateProjectPlanModal';
import { DeleteComponentTable } from '../../ReusableComponents/Menu';
import { SortComponent } from '../../ReusableComponents/SortComponent';
import { ActiveRole } from '../../Teams';
import AppConstants, { BLOB_URL } from '../../AppConstants';
import SubscriptionExpireModal from '../../subscription/Reusable/SubscriptionExpireModal';
import DeleteProject from '../../ReusableComponents/DeleteProject';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={Menu} alt="I am alt" />
    </a>
));
class AeroCaptureAllProjects extends Component {

    state = {
        user: {},
        type: "",
        key: " ",
        progress: 0,
        showDownloadPopup: false,
        loader: false,
        services: [],
        projects: [],
        showBackIcon: true,
        cancel: false,
        userperpage: 8,
        currentpage: 1,
        counts: 0,
        loading: true,
        userdisplay: [],
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        showDeletePopup: false,
        allData: [],
        deleteItemType: "",
        deleteLoader: true,
        projectThumbURL: [],
        outId: "",
        sortType: 'Last Modified',
        sortTypeName: false,
        sortTypeDate: false,
        showSubscriptionExpirePopup: false
    }
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.projects.sort((a, b) => a.project_name.localeCompare(b.project_name))
        this.setState((state) => ({
            ...state,
            projects: sort,
        }));

    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.projects.sort((a, b) => b.project_name.localeCompare(a.project_name))
        this.setState((state) => ({
            ...state,
            projects: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.projects.sort((a, b) => a.created_date.localeCompare(b.created_date))
        this.setState((state) => ({
            ...state,
            projects: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.projects.sort((a, b) => b.created_date.localeCompare(a.created_date))
        this.setState((state) => ({
            ...state,
            projects: sort,
        }));
    }


    getProjects = () => {
        const { user, type, userdisplay, currentpage, userperpage, key, counts, requestStatus } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;

        let state = this.props.location.state
        this.setState(state => ({
            ...state,
            // logsDisplay: [],
            counts: 0,
            // loading: true
        }))
        API.getAllProjects(key == "" ? " " : key, userperpage, firstpage, undefined, undefined, AppConstants.SERVICES.PICSTORK).then(async (data) => {
            if (data.projects && data.projects.length == 0) {
                this.setState((state) => ({
                    ...state,
                    projects: [],
                    loading: false
                }))
            } else {
                let projectsData = data.projects.filter((data) => data.sharedData.isShared == false)
                this.setState((state) => ({
                    ...state,
                    projects: projectsData,
                    loading: false,
                    counts: data.count
                }))
                this.loadProjectTiff()
            }
        }, (e) => {
            console.log(e);
        })
    }

    // function to load tiff thumbnails of project
    loadProjectTiff = async () => {
        let { projects, user } = this.state
        var thumbURL = []
        for (let i = 0; i < this.state.projects.length; i++) {
            if (projects[i].collection_id && projects[i].collection_id != undefined) {
                let name = projects[i].image.toLowerCase()
                console.log((name));
                let turl = `${BLOB_URL}/${user.blob_container}/FlightImages/collection-${projects[i].collection_id}/thumb-${projects[i].image}?${user.sas_token}`
                thumbURL[i] = turl
                this.setState((state) => ({
                    ...state,
                    projectThumbURL: thumbURL,
                }))
            }
        }
    }

    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getProjects();
            });
        } else {
            console.log("Reached first page")
        }
    }

    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    this.getProjects();

                });
        }
    }
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getProjects();
            });
        } else {
            console.log("Reached next page")
        }
    }

    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                this.getProjects();
            });
        } else {
            console.log("Reached last page")
        }
    }

    changepage(num) {
        if (isNaN(num)) {

            return null;
        }
        else {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => this.getProjects());
        }

    }
    search(key) {
        this.setState((state) => ({
            ...state,
            key,
            currentpage: 1
        }),
            () => this.getProjects());
    }

    // function to Get all data under the given project
    getAllDataToDelete = () => {
        this.setState((state) => ({
            ...state,
            deleteLoader: true,
            showDeletePopup: true,
        }))
        API.getProjectData(this.state.deleteItemType, this.state.selectedProject.project_name, this.state.selectedProject.id).then((data) => {
            let keys = Object.keys(data)
            let alldata = []
            keys = keys.reverse()
            keys.forEach(element => {
                let obj = { "name": element, "count": data[element].length, "processIcon": "", data: data[element] }
                alldata.push(obj)
            });
            // let obj={ "name": "Annotation Sets", "count": 8, "processIcon": Loader, data: []}
            // alldata.push(obj)
            this.setState((state) => ({
                ...state,
                allData: data.isEmptyProject ? null : alldata,
                deleteLoader: false
            }))
        }, (e) => {
            console.log(e);
        })
    }

    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }

    componentWillMount() {
        addUserAnalytic(this.props.location.pathname)
            .then(id => {
                console.log("ID : ", id)
                this.setState((state) => ({
                    outId: id
                }))
            })

        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = '.popup-content { width: 30% !important; }';
        document.getElementsByTagName('head')[0].appendChild(style);
        document.title = "PicStork - Projects";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => {
                API.getUserSubbedServices().then(
                    (response) => {
                        let services = response[0];
                        if (services.length == 1 && services[0].title == 'AeroStream') {
                            this.setState((state) => ({
                                ...state,
                                showBackIcon: false
                            }))
                        } else {
                            this.setState((state) => ({
                                ...state,
                                showBackIcon: true
                            }), () => {
                                this.getProjects()
                                checkSubscriptionAndStorage(AppConstants.SERVICES.DRONENAKSHA).then(data => {
                                    if (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
                                    else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
                                })

                            })
                        }
                    }
                )
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.getProjects()
                    checkSubscriptionAndStorage(AppConstants.SERVICES.DRONENAKSHA).then(data => {
                        if (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
                        else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
                    })
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }
    changeColor = (e) => {
        e.preventDefault()
        const refs = ['home', 'customer', 'service']
        refs.map(ref => this.refs[ref].classList.remove('text-color'))
        e.target.classList.add('text-color')
        const { name } = Object.values(e.target)[1]
        this.setState(state => ({
            ...state,
            selected: name
        }))
    }

    render() {
        const { userdisplay, currentpage, userperpage } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;
        let { user, type, sidebarMode, showBackIcon, projects } = this.state
        let sidebarConfigurations = {
            options: showBackIcon == true ? [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: dashboardIcon,
                    text: "PicStork",
                    path: "/picstork/dashboard",
                },
                {
                    icon: projectsIcon,
                    text: "Projects",
                    path: "/picstork/projects",
                    selected: true,
                },
            ] : [
                {
                    icon: dashboardIcon,
                    text: "PicStork",
                    path: "/picstork/dashboard",
                },
                {
                    icon: projectsIcon,
                    text: "Projects",
                    path: "/picstork/projects",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode,
            picStorkHelp: true,
        }
        return (
            user.username ? <div className="wrapper">
                <SideBar config={sidebarConfigurations} showMainDashboardIcons={this.state.showBackIcon} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }                >
                    {
                        this.state.showSubscriptionExpirePopup ?
                            <SubscriptionExpireModal
                                data={this.state.showSubscriptionExpirePopup}
                            /> : <></>
                    }
                    <CreateProjectPlanModal
                        serviceName={AppConstants.SERVICES.PICSTORK}
                        user={this.state.user}
                        type={this.state.type}
                        createProjectPlanModal={this.state.createProjectPlanModal}
                        closeCreateProjectPlanModal={(newItem) => {
                            if (newItem && newItem.includes("PROJECT"))
                                this.getProjects()
                            this.setState({ createProjectPlanModal: false })
                        }}
                        createType={"project"}
                    />
                    <Popup
                        trigger={<button id="delete-task-popup-button" style={{
                            display: "none",
                            // width:"30% !important"
                        }}></button>}
                        modal
                    >
                        <img
                            id="close-popup"
                            src={closeButton} style={{
                                width: "auto",
                                height: "26px",
                                position: "absolute",
                                top: "-13px",
                                right: "-13px",
                                cursor: "pointer"

                            }} onClick={() => {

                                document.getElementById("delete-task-popup-button").click();
                                this.setState((state) => ({
                                    ...state,
                                    createIcon: waiting,
                                }))


                            }}></img>
                        <div >
                            <div style={{ width: "100%", display: "flex" }}>
                                <img src={this.state.createIcon} style={{
                                    width: "auto",
                                    height: "64px",
                                    margin: "30px auto",
                                }} />
                            </div>

                            <>
                                <div style={{ width: "100%", textAlign: "center", marginBottom: '50px' }}>{
                                    this.state.deletingTaskText
                                }</div>

                            </>

                        </div>
                    </Popup>
                    {/* ---------------Component to delete project--------------------- */}
                    <DeleteProject
                        service={AppConstants.SERVICES.PICSTORK}
                        projectId={this.state?.selectedProject?.id}
                        show={this.state.showDeletePopup}
                        onClose={(isDeleted) => {
                            this.setState({
                                showDeletePopup: false
                            }, () => {
                                if (isDeleted)
                                    this.getProjects()
                            })
                        }}
                    />

                    {/* --------------------------------------------------------------- */}

                    <div className="top-bar"><div className="top-bar-text"><span>PROJECTS</span> <ActiveRole /></div></div>
                    {/*<TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar>*/}
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> : <div className="main-content" style={{ background: 'none' }}>
                        <div style={{ display: "flex" }}>
                            <div className="services-content-title">{"Projects"}
                                <span className='data-count'>({this.state.counts})</span>
                            </div>
                        </div>
                        <div style={{ display: "flex", marginTop: "20px" }}>
                            <div className="add-user-form-row-left" style={{ marginLeft: 'auto' }}>
                                <div className="add-user-form-text-wrapper">
                                    <img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
                                    <input type="text" className="add-user-form-text" placeholder="Search Project" style={{ height: '30px !important', width: "230px", paddingLeft: '28px' }} onChange={(event) => this.search(event.target.value)} />
                                </div>
                            </div>
                        </div>
                        {projects.length === 0 ? <div className="rmp-dashboard-empty-data-message"
                            style={{ display: "flex", flexDirection: "column", height: "70vh", alignItems: "center", justifyContent: "center" }}
                        ><img style={{ height: '200px', width: '200px' }} src={Noproject} />
                            <p style={{ margin: '10px' }}>No project available</p></div> : <table className="customers-table scroll-container">
                            <col style={{ width: '5%' }} />
                            <col style={{ width: '35%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '30%' }} />
                            <col style={{ width: '10%' }} />
                            <tr className="customers-table-header-row">
                                <th></th>
                                <th>Name
                                    <SortComponent
                                        style={{ marginLeft: '5px' }}

                                        sortAscending={this.sortByName}
                                        sortDecending={this.reverseByName}
                                        sortASC={false}
                                    />
                                </th>

                                <th style={{ textAlign: 'center' }}>Tasks</th>
                                <th style={{ textAlign: 'center' }}>Date Created
                                    <SortComponent
                                        style={{ marginLeft: '5px' }}

                                        sortAscending={this.sortByDate}
                                        sortDecending={this.reverseByDate}
                                        sortASC={false}
                                    />
                                </th>
                                <th style={{ textAlign: 'center' }}>Images</th>
                                <th></th>
                            </tr>
                            {projects.length === 0 ?
                                <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                    <td colspan="4">
                                        <img style={{ height: '30%', width: '30%' }} src={Noproject} />
                                        <p style={{ fontSize: '18px' }}>No project available</p>
                                    </td>
                                </tr> :
                                projects.map((project, key) =>
                                    <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                        <td>
                                            {
                                                project && project.image ?
                                                    <img id="ProjectTableImage" style={{ objectFit: 'cover', padding: 'unset' }} loading="true" src={this.state.projectThumbURL[key]} onError={(e) => { e.target.onerror = null; e.target.style = { undefined }; e.target.src = ProjectThumb }} /> :
                                                    <img id="ProjectTableImage" src={ProjectThumb} />
                                                // <img id="ProjectImage" src={thumb} />
                                            }
                                        </td>
                                        <td>
                                            <Link
                                                style={{ color: "#707683" }}
                                                to={{
                                                    pathname: "/picstork/project",
                                                    state: {
                                                        user,
                                                        type,
                                                        sidebarMode: this.state.sidebarMode,
                                                        project
                                                    },
                                                }}
                                            >
                                                {project.project_name}
                                            </Link>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>{project.tasks}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            {
                                                (() => {
                                                    let date = (new Date(project.created_date)).toGMTString().split(' ').slice(1, 4).join(' ');
                                                    return `${date}`
                                                })()
                                            }
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            {project.images == null ? 0 : project.images
                                            }</td>
                                        <td>
                                            <DeleteComponentTable
                                                style={{ marginRight: '10px' }}
                                                onClickDelete={(e) => {
                                                    e.preventDefault()
                                                    this.setState((state) => ({
                                                        ...state,
                                                        selectedProject: project,
                                                        deleteItemType: "project"
                                                    }), () => {
                                                        this.getAllDataToDelete()
                                                    })
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )}
                        </table>}
                        <footer className="footer" style={{ visibility: (this.state.counts > 8 ? "visible" : "hidden") }} >
                            <div style={{ float: "left", marginTop: "0.5rem", display: "flex" }}>
                                <div style={{ color: "#109cf1", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                    Page : {currentpage}
                                </div>


                            </div>
                            <div style={{ float: "right", marginTop: "0.5rem", display: "flex" }}>
                                {this.state.currentpage == 1 ?
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", cursor: "default", opacity: '0.5' }}>
                                        <div className="add-user-form-submit-button" >
                                            First
                                        </div>
                                    </button> :
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", }} onClick={this.firstpage}>
                                        <div className="add-user-form-submit-button" >
                                            First
                                        </div>
                                    </button>}
                                {this.state.currentpage == 1 ?
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px", cursor: 'default', opacity: '0.5' }}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Prev
                                        </div>
                                    </button> :

                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px" }} onClick={this.prevpage}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Prev
                                        </div>
                                    </button>}
                                <form>
                                    <div style={{ display: "flex" }}>
                                        <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                            Go to page :
                                        </div>

                                        <div className="add-user-form-text-wrapper">
                                            <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                                                onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                                onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                        </div>
                                    </div>
                                </form>
                                {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px", cursor: "default", opacity: '0.5' }}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Next
                                        </div>
                                    </button> :
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px" }} onClick={this.nextpage}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Next
                                        </div>
                                    </button>
                                }
                                {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px", cursor: 'default', opacity: '0.5' }}>
                                        <div className="add-user-form-submit-button" >
                                            Last
                                        </div>
                                    </button> :
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }} onClick={this.lastpage}>
                                        <div className="add-user-form-submit-button" >
                                            Last
                                        </div>
                                    </button>}
                            </div>
                        </footer>
                    </div>}
                </div>
            </div> : <div />
        );
    }
}

const mapStateToProps = state => {
    return {
        SubscriptionData: state.subscription.subscriptionData,
    };
};

export default connect(mapStateToProps)(AeroCaptureAllProjects);

