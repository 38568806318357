
import io from 'socket.io-client';
import { SOCKET_IO_MESSAGES } from '../../AppConstants';

let SERVER =
    process.env.REACT_APP_SERVER_PROTOCOL +
    "://" +
    process.env.REACT_APP_SERVER_HOST +
    (process.env.REACT_APP_SERVER_PORT
        ? process.env.REACT_APP_SERVER_PORT
        : "")
let socket = ''

export const connectToSocketIOGroup = async (roomId, onMeasurementUpdateByAnotherMachine) => {
    if (roomId) {
        socket = io(SERVER);
        socket.on('connect', () => {
            socket.emit(SOCKET_IO_MESSAGES.JOIN_GROUP, { roomId })
            socket.on(SOCKET_IO_MESSAGES.DRONENAKSHA.MEASUREMENT_UPDATED, (measurement) => onMeasurementUpdateByAnotherMachine(measurement, SOCKET_IO_MESSAGES.DRONENAKSHA.MEASUREMENT_UPDATED))
            socket.on(SOCKET_IO_MESSAGES.DRONENAKSHA.MEASUREMENT_ADDED, (measurement) => onMeasurementUpdateByAnotherMachine(measurement, SOCKET_IO_MESSAGES.DRONENAKSHA.MEASUREMENT_ADDED))
            socket.on(SOCKET_IO_MESSAGES.DRONENAKSHA.MEASUREMENT_DELETED, (measurement) => onMeasurementUpdateByAnotherMachine(measurement, SOCKET_IO_MESSAGES.DRONENAKSHA.MEASUREMENT_DELETED))
        })
    } else console.error("cant join to room", roomId)

}

export const pushMeasurementToSocketRoom = (measurement, type) => {
    socket.emit(type, measurement)
}
