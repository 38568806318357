import React, { useEffect, useState } from 'react';
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import { isTeamOwner } from '../../Teams';

const SubscriptionExpireModal = ({ data }) => {

  const [isOwner, setIsOwner] = useState(false);

  const checkIsOwner = async () => {
    setIsOwner(await isTeamOwner())
  }

  useEffect(() => {
    checkIsOwner()
  }, []);

  return <>
    <AnimatedModal
      isOpen={true} // (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan)
      height="150px"
      width="460px">
      <div style={{
        position: "absolute",
        padding: "30px",
        height: "100%",
        width: "100%",
      }}>
        <div style={{
          borderBottom: "1px solid #dee2e6",
          height: "calc(100% - 20px)",
        }}>
          <div style={{ fontSize: "15px", color: "#3c3c3c" }}>
            {data.error ?
              `Something went wrong while getting your service plan, please contact us.`

              : !data.isValidStoragePlan ?
                `${isOwner ?
                  `${data.errorMessage}` :
                  `It appears that your ${data.service_name} subscription expired of the selected team. Please contact the team owner for assistance.`}`

                : data.isExpired ?
                  !isOwner ?
                    `Your ${data.plan_name} subscription has expired for selected team, please renew` :
                    `Your ${data.plan_name} subscription has expired, please renew it`

                  : data.storageExceed &&
                    !isOwner ?
                    `It appears that your ${data.plan_name} plan exceeds the size of the selected team. Please contact the team owner for assistance.` :
                    `You have exceeded the storage limit of your ${data.plan_name} account. The storage usage is ${(data.storageUsed / 1024).toFixed(4)} / ${(data.storageLimit / 1024).toFixed(4)} GB.  Please upgrade the plan `

            }
          </div>
        </div>
        <div style={{
          position: "absolute",
          right: "0px",
          margin: "10px 30px 0 0",
        }} >
          <ButtonWithIcon
            isBtnActive
            text={data.error || !isOwner ? "Ok" : !data.isValidStoragePlan ? "Buy" : "Renew"}
            onClick={data.error || !isOwner ? () => { window.history.back(); } : undefined}
            linkData={data.error || !isOwner ? undefined : {
              pathname: "/aeromegh_plans",
              state: {
                serviceName: data.service_name,
                user: data?.user
              }
            }}
          />
        </div>
      </div>
    </AnimatedModal>
  </>
}

export default SubscriptionExpireModal;