import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import SingleMap from '../../../ReusableComponents/map'
import * as API from "../../../api.js";
import { DetectionAreaAnnotationTool } from "./DetectionAreaAnnotationTool.js"
import AppConstants, { MAP_URLS } from "../../../AppConstants";
import { Popup } from "../../../ReusableComponents/reusableFunctions.js";
import ButtonWithIcon from "../../../ReusableComponents/ButtonWithIcon.js";
let extendBounds = 0.00075
const DetectionArea = (props, ref) => {
    const detectionAreaToolRef = useRef(null)
    const [map, setMap] = useState(undefined)
    const [mapInfo, setMapInfo] = useState(undefined)
    const [bounds, setBounds] = useState(undefined)
    const saveDetectionAreas = (calledFromOutside) => {
        if (detectionAreaToolRef.current) {
            detectionAreaToolRef.current.saveDetectionAreas(calledFromOutside)
        }
    }
    useImperativeHandle(ref, () => ({
        saveDetectionAreas
    }))
    const mapInitialized = (map) => {
        if (props.task.state == 2) {
            const collection_id = localStorage.getItem("collection-id")
            if (collection_id && collection_id !== props.collection.id) {
                let newTask = props.task
                const task_parameters = { ...props.task.task_parameters, detection_areas: [] }
                newTask.task_parameters = task_parameters
                API.updateTaskDetails(props.task.task_id, { task_parameters }).then(res => {
                    props.updateTask(newTask)
                }).catch(err => {
                    console.log(err)
                })
            }
            localStorage.setItem("collection-id", props.collection.id)
        }
        map.createPane('Areas')
        map.createPane('ortho');
        map.createPane('annotation')
        setMap(map)
        getMapInfo(map)

    }

    const getMapInfo = (map) => {
        API.getTilesBounds({
            taskId: props.selectedImageForAnnotation.collection_id,
            tiffType: props.selectedImageForAnnotation.file_name,
            sas: encodeURIComponent(props.permissions?.st),
            blobContainer: props.permissions?.container
        }).then((mapInfo) => {
            if (mapInfo.bounds) {

                setMapInfo(mapInfo)
                setMapCenter(mapInfo, map)
                loadOrtho(mapInfo, map)
                drawBoundsBorder(map, mapInfo.bounds)
            } else {
                Popup.alert("ERROR", mapInfo?.detail || "Something went wrong while getting tile bounds")
            }
        }).catch(err => {
            Popup.alert("ERROR", err?.message || "Something went wrong while getting tile bounds")
        })
    }

    const setMapCenter = async (info, map1) => {
        if (info && map1) {
            let bounds = [[info.bounds[1] - extendBounds, info.bounds[0] - extendBounds], [info.bounds[3] + extendBounds, info.bounds[2] + extendBounds]]
            map1.fitBounds(bounds);
        } else {
            let bounds = [[mapInfo.bounds[1] - extendBounds, mapInfo.bounds[0] - extendBounds], [mapInfo.bounds[3] + extendBounds, mapInfo.bounds[2] + extendBounds]]
            map.fitBounds(bounds);
        }
    }

    const drawBoundsBorder = async (map, bounds) => {
        const drawingBounds = [[bounds[1], bounds[0]], [bounds[3], bounds[2]]]
        // const orthoBackground = L.rectangle(drawingBounds, { color: '#666666', weight: 1, fillColor: "white", fillOpacity: 1, interactive: false }).addTo(map)
        const orthoBackground = L.rectangle(drawingBounds, { color: '#2989CF', weight: 2, fillColor: "white", fillOpacity: 1, interactive: false }).addTo(map)
        const boundsLayer = L.rectangle(drawingBounds, { color: 'red', weight: 1, fillColor: "none", interactive: false, }) // for blink
        setBounds({
            drawingBounds, boundsLayer
        })
    }

    const loadOrtho = (mapInfo, map) => {
        map.getPane('Areas').style.zIndex = 1001
        map.getPane('annotation').style.zIndex = 1002
        const sas = encodeURIComponent(props.permissions?.st)
        let bounds = [[mapInfo.bounds[1], mapInfo.bounds[0]], [mapInfo.bounds[3], mapInfo.bounds[2]]]
        let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${props.selectedImageForAnnotation.collection_id}/${props.selectedImageForAnnotation.file_name}/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${props.permissions?.container}`, {
            tileSize: 256,
            maxZoom: 28,
            pane: 'ortho',
            bounds: bounds
        }).addTo(map);
        if (props.onOrthoLoad) props.onOrthoLoad(map)
    }
    return (<div style={{ display: 'flex', flexDirection: 'column', width: "100%", height: "100%" }}>
        <div style={{ background: "", padding: "20px 40px", width: "100%", display: 'flex', justifyContent: 'right', gap: "20px", alignItems: "center", height: '35px', }}>
            <ButtonWithIcon text={"Back"} fontSize={"11px"} width={"92px"} onClick={props.close} />
            {props.isDisabled ? <></> :
                <ButtonWithIcon isBtnActive text={"Next"} fontSize={"11px"} width={"92px"} onClick={props.goToNextStep} />}
        </div>
        <div style={{ height: 'calc(100% - 44px)', width: '100%', marginTop: '8px',  display: "flex", justifyContent: "space-between", paddingBottom: "24px" }}>
            <div style={{ height: '100%', width: '100%',position:'relative' }}>
                <SingleMap
                    style={{ height: "100%" }}
                    initBaseLayerURL={MAP_URLS.ROAD_MAP}
                    initZoom={18}
                    maxZoom={25}
                    setMap={(map) => {
                        mapInitialized(map)
                    }}
                >
                </SingleMap>

                {map && bounds && <>
                    <DetectionAreaAnnotationTool
                        ref={detectionAreaToolRef}
                        isDisabled={props.isDisabled}
                        permissions={props.permissions}
                        showLoader={() => { }}
                        bounds={bounds}
                        task={props.task}
                        annotationSetId={props.annotationSetId}
                        imageInfo={props.selectedImageForAnnotation}
                        map={map}
                        updateTask={props.updateTask}
                        setMapCenter={setMapCenter}
                        goToNextStep={props.goToNextStep}
                    />
                </>
                }
            </div>
        </div>
    </div>

    )
}
export default forwardRef(DetectionArea)