import { AnimatedModal } from "./ReusableComponents/AnimatedModal";
import React, { Component } from "react";
import * as API from "./api.js";
import welcomeImage from "./assets/Drone_surveillance.png";
import Dot from './icons/dot.png'
/**
To use this popup you have to pass following props
  1) onRequestClose as function
  2) show as flag
  3) if You want to give height then pass width otherwise it take 50% by default

*/
export default class SurveyPopup extends Component {
  state = {
    user: this.props.user,
    industry: [],
    role: '',
    teamMembers: '',
    country: '-',
    phoneNo: '',
    countries: [],
    industries: ['Construction', 'Agriculture', 'Survey & Mapping', 'Power & Utility', 'Oil & Gas'],
    roles: ['Drone Pilot', 'Project Manager', 'Solution Architect', 'Researcher', 'Owner', 'Student', 'Drone Service Provider',],
    members: ['0-20', '21-50', '51-100', '100+'],
    code: '+91',
    other: false,
    otherIndustry: ''
  };

  onchangeIndustry = (e) => {
    let data = e.target.value
    if (e.target.name == 'other') {
      this.setState((state) => ({
        ...state,
        other: !this.state.other
      }))
    }
    else {
      let industries = this.state.industry
      let exist = industries.length > 0 ? industries.findIndex((i) => { return i == data }) : -1
      if (exist == -1)
        industries.push(data)
      else
        industries.splice(exist, 1)
      this.setState((state) => ({
        ...state,
        industry: industries
      }))
    }
  }

  onchangeHandler = (e) => {
    let t = e.target
    this.setState((state) => ({
      ...state,
      [t.name]: t.value
    }))
  }

  onchangePhoneNumber = (e) => {
    let t = e.target
    let format = t.name == 'code' ? /[qwertyuioplkjhgfdsazxcvbnmQWERTYUIOPLKJHGFDSAZXCVBNM!@#$%^&*()\=\[\]{};':"\\|,.<>\/?]+/ : /[qwertyuioplkjhgfdsazxcvbnmQWERTYUIOPLKJHGFDSAZXCVBNM!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
    let length = t.name == 'code' ? 8 : 13
    if (!format.test(t.value) && !e.target.value.includes(' ') && t.value.length < length && (t.name != 'code' || t.value.length > 0)) {
      this.setState((state) => ({
        ...state,
        [t.name]: t.value
      }))
    }
  }

  componentWillMount() {
    API.getCountries().then((data) => {
      this.setState((state) => ({
        ...state,
        countries: data.countries,
      }))
    }, (e) => {
      console.log(e);
    })
  }

  submit = () => {
    let { industry, code, other, otherIndustry, phoneNo, teamMembers, role, country } = this.state
    if (other)
      industry.push(otherIndustry)
    phoneNo = phoneNo ? code + phoneNo : phoneNo
    let data = { industry, role, teamMembers, phoneNo, country }
    this.props.submit(data)
  }

  render() {
    const mandatory = <span style={{ fontSize: '12px', color: "red", marginLeft: "3px" }}>*</span>
    const { user } = this.state;
    return (
      <div>
        <AnimatedModal
          height="535px"
          width="740px"
          isOpen={this.props.show}
          zIndex={10000}
        >
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%", padding: "30px" }}>
              <div className="content-text" style={{ width: "100%", display: 'flex', flexDirection: 'column' }} >
                <div style={{ width: "100%", display: "flex" }}>
                  <div><img src={Dot} style={{ height: "10px", width: "auto" }} /></div>
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", fontSize: "16px", fontWeight: "500", marginLeft: "10px" }}> Select the industry {mandatory}</div>
                    <div style={{ display: "flex", fontSize: "13px", fontWeight: "500", width: "94%", justifyContent: "space-between", margin: "10px 0px 0px 10px", height: "25px", alignItems: 'center' }}>
                      {this.state.industries.map(data => {
                        return (
                          <div style={{ display: "flex" }}>
                            <div style={{ marginTop: "2px", marginRight: "5px" }}>
                              <input type='checkbox' style={{ height: '14px', width: '14px', cursor: 'pointer' }}
                                //checked={this.state.industry.includes(data)}
                                name='industry'
                                value={data}
                                onChange={this.onchangeIndustry}
                              />
                            </div><span>{data}</span>
                          </div>
                        )
                      })}
                    </div>
                    <div style={{ display: "flex", fontSize: "13px", fontWeight: "500", width: "35%", justifyContent: "space-between", margin: "3px 0px 10px 10px", alignItems: 'center' }}>
                      <div style={{ display: "flex" }}>
                        <div style={{ marginTop: "2px", marginRight: "5px" }}>
                          <input type='checkbox' style={{ height: '14px', width: '14px', cursor: 'pointer' }}
                            name='other'
                            value={this.state.other}
                            onChange={this.onchangeIndustry}
                          />
                        </div><span>Other</span>
                      </div>
                      {this.state.other ? <div className="add-user-form-text-wrapper" style={{ display: "flex" }}>
                        <input name="otherIndustry" type="text" style={{ width: "160px", height: "28px" }} className="add-user-form-text2" placeholder="Please enter details" onChange={this.onchangeHandler} value={this.state.otherIndustry} />
                      </div> : <></>}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", width: "100%", marginTop: "1%" }}>
                  <div><img src={Dot} style={{ height: "10px", width: "auto" }} /></div>
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", fontSize: "16px", fontWeight: "500", marginLeft: "10px" }}> What best describes your role ? {mandatory}</div>
                    <div style={{ display: "flex", fontSize: "13px", fontWeight: "500", width: "94%", justifyContent: "space-between", margin: "10px 0px 0px 10px", height: "20px", alignItems: 'center' }}>
                      {this.state.roles.map((data, i) => {
                        return (
                          i < 5 ? <div style={{ display: "flex" }}>
                            <div style={{ marginTop: "2px", marginRight: "5px" }}>
                              <input type='radio' style={{ height: '14px', width: '14px', cursor: 'pointer' }}
                                //checked={this.state.industry.includes(data)}
                                name='role'
                                value={data}
                                onClick={this.onchangeHandler}
                                required
                              />
                            </div><span>{data}</span>
                          </div> : <></>
                        )
                      })}
                    </div>
                    <div style={{ display: "flex", fontSize: "13px", fontWeight: "500", width: "40%", justifyContent: "space-between", margin: "6px 0px 10px 10px", alignItems: 'center' }}>
                      {this.state.roles.map((data, i) => {
                        return (
                          i > 4 ? <div style={{ display: "flex" }}>
                            <div style={{ marginTop: "2px", marginRight: "5px" }}>
                              <input type='radio' style={{ height: '14px', width: '14px', cursor: 'pointer' }}
                                //checked={this.state.industry.includes(data)}
                                name='role'
                                value={data}
                                onClick={this.onchangeHandler}
                                required
                              />
                            </div><span>{data}</span>
                          </div> : <></>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "1%" }}>
                  <div><img src={Dot} style={{ height: "10px", width: "auto" }} /></div>
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", fontSize: "16px", fontWeight: "500", marginLeft: "10px" }}>How big is your team ? {mandatory}</div>
                    <div style={{ display: "flex", fontSize: "13px", fontWeight: "500", width: "42%", justifyContent: "space-between", margin: "10px 0px 10px 10px", height: "25px", alignItems: 'center' }}>
                      {this.state.members.map((data, i) => {
                        return (
                          <div style={{ display: "flex" }}>
                            <div style={{ marginTop: "2px", marginRight: "5px" }}>
                              <input type='radio' style={{ height: '14px', width: '14px', cursor: 'pointer' }}
                                //checked={this.state.industry.includes(data)}
                                name='teamMembers'
                                value={data}
                                onClick={this.onchangeHandler}
                                required
                              />
                            </div><span>{data}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                {/* Country */}
                <div style={{ display: "flex", marginTop: "1%" }}>
                  <div><img src={Dot} style={{ height: "10px", width: "auto" }} /></div>
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", fontSize: "16px", fontWeight: "500", marginLeft: "10px" }}>Select your country {mandatory}</div>
                    <div style={{ display: "flex", fontSize: "13px", fontWeight: "500", width: "42%", justifyContent: "space-between", margin: "10px 0px 10px 10px", height: "25px", alignItems: 'center' }}>
                      <div className="add-user-form-row-left">
                        <div className="add-user-form-text-wrapper">
                          <select name="country" style={{ width: "auto", height: "30px" }} className="add-user-form-text2" value={this.state.country} required
                            onChange={this.onchangeHandler}
                          >
                            <option value={'-'} disabled>
                              {this.state.countryLoader == true ?
                                `Loading Countries` : `Select country`}
                            </option>
                            {this.state.countries.length > 0 ?
                              this.state.countries.map((country) => {
                                return <option value={country.country}>{country.country}</option>
                              }) :
                              <option>no countries found</option>
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ------ */}
                {/* Phone number */}
                <div style={{ display: "flex", marginTop: "1%" }}>
                  <div><img src={Dot} style={{ height: "10px", width: "auto" }} /></div>
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", fontSize: "16px", fontWeight: "500", marginLeft: "10px" }}>Will you be sharing your phone number ? </div>
                    <div style={{ display: "flex", fontSize: "13px", fontWeight: "500", width: "35%", justifyContent: "space-between", margin: "10px 0px 10px 10px", height: "25px", alignItems: 'center' }}>
                      <div className="add-user-form-text-wrapper">
                        <input name="code" type="text" style={{ width: "75px", height: "30px", marginRight: "5px" }} className="add-user-form-text2" pattern="^(\+?\d{1,3}|\d{1,4})$" placeholder="eg. +91" onChange={this.onchangePhoneNumber} value={this.state.code} />
                      </div>
                      <div className="add-user-form-text-wrapper" style={{ display: "flex" }}>
                        <input name="phoneNo" type="text" style={{ width: "160px", height: "30px" }} className="add-user-form-text2" pattern="^\d{3}\d{3}\d{4}$" placeholder="eg. 9978645321" onChange={this.onchangePhoneNumber} value={this.state.phoneNo} />
                        <span style={{ fontSize: "8px", marginTop: "5px", marginLeft: "10px", color: "#666666" }}>(Optional)</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ------ */}
              </div>
            </div>
            <div style={{ display: "flex", position: "absolute", bottom: "40px", left: "32px", fontSize: "12px", color: "#666666" }}>
              * We promise to keep your information safe.
            </div>
            <div style={{ display: "flex", position: "absolute", bottom: "32px", right: "5%" }}>
              {(this.state.industries.length > 0 && (!this.state.other || this.state.otherIndustry)) && this.state.role && this.state.teamMembers && this.state.country != '-' && (!this.state.phoneNo || this.state.code.length > 1) ? <button
                style={{ padding: "10px 25px", fontSize: "14px", fontWeight: 500 }}
                className="add-user-form-submit-button btn-ok"
                onClick={this.submit}
              >
                Save
              </button> :
                <button
                  style={{ padding: "10px 25px", fontSize: "14px", fontWeight: 500, opacity: 0.5 }}
                  className="add-user-form-submit-button btn-ok"
                >
                  Save
                </button>}
            </div>
          </div>
        </AnimatedModal >
      </div >
    );
  }
}