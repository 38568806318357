import ShowInsideActive from './assets/ShowInsideActive.png'
import ShowInsideInActive from './assets/ShowInsideInActive.png'
import ShowOutsideActive from './assets/ShowOutsideActive.png'
import ShowOutsideInActive from './assets/ShowOutsideInActive.png'
import HighLightActive from './assets/HighLightActive.png'
import HightLightInActive from './assets/HighLightInActive.png'
import NoneActive from './assets/NoneActive.png'
import NoneInActive from './assets/NoneInActive.png'

export const ClipTask = {
	Highlight: 1,
	Inside: 2,
	Outside: 3,
	None: 0,
};

export const ClipTaskIcons = {
	Highlight: {
		active : HighLightActive,
		inActive : HightLightInActive
	},
	Inside: {
		active : ShowInsideActive,
		inActive : ShowInsideInActive
	},
	Outside: {
		active : ShowOutsideActive,
		inActive : ShowOutsideInActive
	},
	None: {
		active : NoneActive,
		inActive : NoneInActive
	}
};

