import Services from "../src/sidebaricon/Services.svg"
import ServicesInactive from "../src/sidebaricon/Services-Inactive.svg"
import AeroGCS from "../src/sidebaricon/AeroGCS.svg"
import AeroGCSInactive from "../src/sidebaricon/AeroGCS-Inactive.svg"
import Drones from "../src/sidebaricon/Drone.png"
import DronesInactive from "../src/sidebaricon/Drone-Inactive.png"
import Teams from "../src/sidebaricon/Teams.svg"
import TeamsInactive from "../src/sidebaricon/Teams-Inactive.png"
import ControlCenter from "../src/sidebaricon/ControlCenter.svg"
import ControlCenterInactive from "../src/sidebaricon/ControlCenter-Inactive.svg"
import Projects from "../src/sidebaricon/Projects.svg"
import ProjectsInactive from "../src/sidebaricon/Projects-Inactive.png"
import Project from "../src/sidebaricon/ProjectNew.png"
import ProjectInactive from "../src/sidebaricon/ProjectNew-Inactive.png"
import Plans from "../src/sidebaricon/Plans.png"
import PlansInactive from "../src/sidebaricon/Plans-Inactive.png"
import Imagesets from "../src/sidebaricon/ImageSets.png"
import ImagesetsInactive from '../src/sidebaricon/ImageSets-Inactive.png';
import Videos from "../src/sidebaricon/Video.png"
import VideosInactive from "../src/sidebaricon/Video-Inactive.png"
import FlightLogs from "../src/sidebaricon/FlightLogs.png"
import FlightLogsInactive from "../src/sidebaricon/FlightLogs-Inactive.png"
import AeroStream from "../src/sidebaricon/AeroStreams.png"
import AeroStreamInactive from "../src/sidebaricon/AeroStreams-Inactive.png"



const defaultAeroGCSSideBar = {
    tools: [{
        name: 'Services',
        path: "/",
        icon: {
            active: Services,
            inActive: ServicesInactive
        },
    },
    {
        name: 'AeroGCS',
        path: "/aerogcsenterprise/dashboard",
        icon: {
            active: AeroGCS,
            inActive: AeroGCSInactive
        },
        selected: true,
    },

    {
        name: 'Drones',
        path: "/drone_management/drones",
        icon: {
            active: Drones,
            inActive: DronesInactive
        },
    },
    {
        name: 'Control Center',
        path: "/drone_control_center/all_drones",
        icon: {
            active: ControlCenter,
            inActive: ControlCenterInactive
        },
    },
    {
        name: 'Teams',
        path: "/teams",
        icon: {
            active: Teams,
            inActive: TeamsInactive
        },
    },
    {
        name: 'Projects',
        path: "/projects",
        icon: {
            active: Projects,
            inActive: ProjectsInactive
        },
    },],
    selectedPath: '/aerogcsenterprise/dashboard',
    aeroGCSHelp: true
}

const defaultTeamsAeroGCSSideBar = {
    tools: [{
        name: 'Services',
        path: "/",
        icon: {
            active: Services,
            inActive: ServicesInactive
        },
    },
    {
        name: 'Teams',
        path: "/teams",
        icon: {
            active: Teams,
            inActive: TeamsInactive
        },
    },
    ],
    selectedPath: '/teams',
    teamsHelp: true
}

const defaultDronesSideBar = {
    tools: [{
        name: 'Services',
        path: "/",
        icon: {
            active: Services,
            inActive: ServicesInactive
        },
    },
    {
        name: 'AeroGCS',
        path: "/aerogcsenterprise/dashboard",
        icon: {
            active: AeroGCS,
            inActive: AeroGCSInactive
        },
        selected: true,
    },

    {
        name: 'Drones',
        path: "/drone_management/drones",
        icon: {
            active: Drones,
            inActive: DronesInactive
        },
    },
    {
        name: 'Control Center',
        path: "/drone_control_center/all_drones",
        icon: {
            active: ControlCenter,
            inActive: ControlCenterInactive
        },
    },
    {
        name: 'Teams',
        path: "/teams",
        icon: {
            active: Teams,
            inActive: TeamsInactive
        },
    },
    {
        name: 'Projects',
        path: "/projects",
        icon: {
            active: Projects,
            inActive: ProjectsInactive
        },
    },],
    aeroGCSHelp: true,
    selectedPath: '/drone_management/drones'
}
const aeroGCSPlans = {
    tools: [
        {
            name: 'Services',
            path: "/",
            icon: {
                active: Services,

                inActive: ServicesInactive
            },
        },
        {
            name: 'AeroGCS',
            path: "/aerogcsenterprise/dashboard",
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
            selected: true,
        },
        {
            name: 'Drones',
            path: "/drone_management/drones",
            icon: {
                active: Drones,
                inActive: DronesInactive
            },
        },
        {
            name: 'Control Center',
            path: "/drone_control_center/all_drones",
            icon: {
                active: ControlCenter,
                inActive: ControlCenterInactive
            },
        },
        {
            name: 'Teams',
            path: "/teams",
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Projects',
            path: "/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        // {
        //     name: 'Plan',
        //     path: "/remote_mission/assignDroneToPlan",
        //     icon: {
        //         active: Plans,
        //         inActive: PlansInactive
        //     },
        //     className: "sidebaricon-plan"
        // },
        {
            name: 'Imagesets',
            path: "/remote_mission/image_collection",
            icon: {
                active: Imagesets,
                inActive: ImagesetsInactive
            },
            className: "sidebaricon-plan"
        },
        {
            name: 'Videos',
            path: "/remote_mission/my_videos",
            icon: {
                active: Videos,
                inActive: VideosInactive
            },
            className: "sidebaricon-plan"
        },
        {
            name: 'Flight logs',
            path: "/remote_mission/flightLogs",
            icon: {
                active: FlightLogs,
                inActive: FlightLogsInactive
            },
            className: "sidebaricon-flightlogs"
        },
    ],
    selectedPath: '/projects'
}
const defaultProjectSideBar = {
    tools: [{
        name: 'Services',
        path: "/",
        icon: {
            active: Services,
            inActive: ServicesInactive
        },
    },
    {
        name: 'AeroGCS',
        path: "/aerogcsenterprise/dashboard",
        icon: {
            active: AeroGCS,
            inActive: AeroGCSInactive
        },
        selected: true,
    },
    {
        name: 'Drones',
        path: "/drone_management/drones",
        icon: {
            active: Drones,
            inActive: DronesInactive
        },
    },
    {
        name: 'Control Center',
        path: "/drone_control_center/all_drones",
        icon: {
            active: ControlCenter,
            inActive: ControlCenterInactive
        },
    },
    {
        name: 'Teams',
        path: "/teams",
        icon: {
            active: Teams,
            inActive: TeamsInactive
        },
    },
    {
        name: 'Projects',
        path: "/projects",
        icon: {
            active: Projects,
            inActive: ProjectsInactive
        },
    },
    // {
    //     name: 'Project',
    //     path: "/plans",
    //     icon: {
    //         active: Project,
    //         inActive: ProjectInactive
    //     },
    // }
    ],
    aeroGCSHelp: true,
    selectedPath: '/projects'
}


const defaultRequestADroneSideBar = {
    tools: [{
        name: 'Services',
        path: "/",
        icon: {
            active: Services,
            inActive: ServicesInactive
        },
    },
    {
        name: 'AeroGCS',
        path: "/aerogcsenterprise/dashboard",
        icon: {
            active: AeroGCS,
            inActive: AeroGCSInactive
        },
        selected: true,
    },

    {
        name: 'Drones',
        path: "/drone_management/drones",
        icon: {
            active: Drones,
            inActive: DronesInactive
        },
    },
    {
        name: 'Control Center',
        path: "/drone_control_center/all_drones",
        icon: {
            active: ControlCenter,
            inActive: ControlCenterInactive
        },
    },
    {
        name: 'Projects',
        path: "/projects",
        icon: {
            active: Projects,
            inActive: ProjectsInactive
        },
    },
    {
        name: 'Aerostream',
        path: "/request_a_drone/all_videos",
        icon: {
            active: AeroStream,
            inActive: AeroStreamInactive
        },
    },

    ],
    selectedPath: '/request_a_drone/all_videos',
    aeroGCSHelp: true
}
const defaultFlightPlan = {
    tools: [{
        name: 'Services',
        path: "/",
        icon: {
            active: Services,
            inActive: ServicesInactive
        },
    },
    {
        name: 'AeroGCS',
        path: "/aerogcsenterprise/dashboard",
        icon: {
            active: AeroGCS,
            inActive: AeroGCSInactive
        },
        selected: true,
    },

    {
        name: 'Drones',
        path: "/drone_management/drones",
        icon: {
            active: Drones,
            inActive: DronesInactive
        },
    },
    {
        name: 'Control Center',
        path: "/drone_control_center/all_drones",
        icon: {
            active: ControlCenter,
            inActive: ControlCenterInactive
        },
    },
    {
        name: 'Projects',
        path: "/projects",
        icon: {
            active: Projects,
            inActive: ProjectsInactive
        },
    },
    {
        name: 'Plan',
        path: "/remote_mission/assignDroneToPlan",
        icon: {
            active: Plans,
            inActive: PlansInactive
        },
        className: "sidebaricon-plan"
    },

    ],
    selectedPath: "/remote_mission/assignDroneToPlan",
    aeroGCSHelp: true
}

const defaultSurveyFlightPlan = {
    tools: [{
        name: 'Services',
        path: "/",
        icon: {
            active: Services,
            inActive: ServicesInactive
        },
    },
    {
        name: 'AeroGCS',
        path: "/aerogcsenterprise/dashboard",
        icon: {
            active: AeroGCS,
            inActive: AeroGCSInactive
        },
        selected: true,
    },
    {
        name: 'Projects',
        path: "/projects",
        icon: {
            active: Projects,
            inActive: ProjectsInactive
        },
    },

    ],
    selectedPath: "/projects",
    aeroGCSHelp: true
}

const defaultSprayingFlightPlan = {
    tools: [{
        name: 'Services',
        path: "/",
        icon: {
            active: Services,
            inActive: ServicesInactive
        },
    },
    {
        name: 'AeroGCS',
        path: "/aerogcsenterprise/dashboard",
        icon: {
            active: AeroGCS,
            inActive: AeroGCSInactive
        },
        selected: true,
    },
    {
        name: 'Projects',
        path: "/projects",
        icon: {
            active: Projects,
            inActive: ProjectsInactive
        },
    },
    {
        name: 'Plan',
        path: "/remote_mission/assignDroneToPlan",
        icon: {
            active: Plans,
            inActive: PlansInactive
        },
        className: "sidebaricon-plan"
    },

    ],
    selectedPath: "/remote_mission/assignDroneToPlan",
    aeroGCSHelp: true
}
export const sideBarPaths = {
    "/aerogcsenterprise/dashboard": {
        ...defaultAeroGCSSideBar, selectedPath: '/aerogcsenterprise/dashboard',
    },
    "/remote_mission/flights_completed_hours": defaultAeroGCSSideBar,
    "/remote_mission/flight_logs_by_droneId": defaultAeroGCSSideBar,
    "/remote_mission/flights_statistics": defaultAeroGCSSideBar,
    "/remote_mission/flight_details": defaultAeroGCSSideBar,
    '/remote_mission/flight_status_logs': defaultAeroGCSSideBar,
    '/remote_mission/detailedlog': defaultAeroGCSSideBar,
    '/remote_mission/all_flights_logs': defaultAeroGCSSideBar,
    '/allFlightPlans': defaultAeroGCSSideBar,
    '/remote_mission/assignDroneToPlan': aeroGCSPlans,
    '/remote_mission/image_collection': { ...aeroGCSPlans, selectedPath: '/remote_mission/image_collection' },
    '/remote_mission/my_videos': { ...aeroGCSPlans, selectedPath: '/remote_mission/my_videos' },
    '/drone_management/drones': defaultDronesSideBar,
    '/drone_management/drone_details': defaultDronesSideBar,
    '/projects': { ...defaultDronesSideBar, selectedPath: '/projects' },
    '/plans': defaultProjectSideBar,
    '/remote_mission/flightLogs': ({ ...aeroGCSPlans, selectedPath: '/remote_mission/flightLogs' }),
    '/remote_mission/selectLocation': aeroGCSPlans,
    '/aerogcsenterprise/flights': defaultAeroGCSSideBar,
    '/aerogcsenterprise/flights/pilots': defaultAeroGCSSideBar,
    '/aerogcsenterprise/flights/acres': defaultAeroGCSSideBar,
    '/request_a_drone/all_videos': defaultRequestADroneSideBar,
    '/remote_mission/flightPlan': defaultFlightPlan,
    "/remote_mission/surveyFlightPlan": defaultSurveyFlightPlan,
    '/remote_mission/sprayingFlightPlot': defaultSprayingFlightPlan,
    '/remote_mission/sprayingFlightPlan': defaultSprayingFlightPlan,
    '/teams': defaultTeamsAeroGCSSideBar
}
