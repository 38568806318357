import React, { useEffect, useState } from 'react'
import * as turf from '@turf/turf'
import DownArrow from "../../../assets/Icons/next_icon.png"
import StepContract from '../../../assets/vectorArrowDown.png'

import AppConstants from '../../../AppConstants'
const AreaClassSideBar = ({ parentObj, color, text, areaName, onClickAreaName, selectedAreaId, setSelectedAreaId }) => {

    const [toggle, setToggle] = useState(false);
    const [arrWithChildren, setArrWithChildren] = useState([])

    useEffect(() => {
        if (parentObj.noOfAreas) {
            let arrWithChildrenTemp = []
            parentObj.noOfAreas.map(tr => {
                let trainingArea = { boundingBox: tr, children: [] }
                const poly1 = turf.polygon([[...tr.coordinates.map(coords => [coords.lat, coords.lng]), [tr.coordinates[0].lat, tr.coordinates[0].lng]]]);

                parentObj.noOfChildren.map(annot => {
                    const poly2 = turf.polygon([[...annot.coordinates.map(coords => [coords.lat, coords.lng]), [annot.coordinates[0].lat, annot.coordinates[0].lng]]]);

                    if (turf.booleanWithin(poly2, poly1)) {
                        trainingArea.children.push(annot)
                    }
                })
                arrWithChildrenTemp.push(trainingArea)
            })
            setArrWithChildren(arrWithChildrenTemp)
        }
    }, [parentObj])
    return (
        <>
            <div className='hoverHighlightClass' style={{ display: 'flex', alignItems: "center", cursor: "pointer", listStyle: "none", padding: "3px", color: AppConstants.CLASSLABELS.DARKCLASSTEXT }}  >
                <div onClick={() => {
                    setToggle(prev => !prev)
                }
                } style={{ display: "flex", alignItems: "center", width: "110px", }}><div style={{ width: "12px", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "5px" }}><img style={{ width: "100%", transform: (toggle && arrWithChildren.length > 0) ? "rotate(90deg)" : "rotate(0deg)", transition: "0.15s all" }} src={DownArrow} /></div><span style={{ fontWeight: "500", fontSize: "15px" }}>{text} </span></div>
                <div style={{ backgroundColor: color, width: "10px", height: "10px", borderRadius: "50%", margin: " 5px 0px" }}></div>

            </div>
            {toggle && arrWithChildren.length > 0 && <div style={{ color: AppConstants.CLASSLABELS.DARKCLASSTEXT }}>
                {
                    arrWithChildren.map((tr, idx) => <div className={`hoverHighlightClass ${tr.boundingBox.id === selectedAreaId ? "classSelected" : ""}`} style={{ padding: "3px 0px", paddingLeft: "20px", display: "flex", justifyContent: "flex-start", width: "100%", alignItems: "center" }}>
                        <div style={{ backgroundColor: color, width: "10px", height: "10px", borderRadius: "50%", margin: " 5px 0px" }}></div>
                        <div onClick={() => {
                            setSelectedAreaId(tr.boundingBox.id)
                            onClickAreaName(tr.boundingBox.id, tr.boundingBox.subType)
                        }} key={tr.boundingBox.id} style={{ listStyle: "none", fontSize: "13px", cursor: "pointer", width: "fit-content", padding: "2px 5px" }} >{`${areaName}_${(idx + 1) <= 9 ? "00" + (idx + 1) : ((idx + 1) >= 10 && (idx + 1) < 100) ? "0" + (idx + 1) : idx + 1}`} {`(${tr.children.length})`}</div>
                    </div>
                    )
                }
            </div>}
        </>
    )
}

export default AreaClassSideBar
