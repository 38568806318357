import { combineReducers } from 'redux'
import cakeReducer from './cake/cakeReducer'
import userReducer from './user/userReducer'
import mapReducer from './map/mapReducer'
import projectReducer from './project/projectReducer'
import { profileReducer, subscriptionReducer, activeSubscriptionReducer } from './Profile/profileReducer'
import sidebarReducer from './sidebar/sidebarReducer'

const rootReducer = combineReducers({
    cake: cakeReducer,
    user: userReducer,
    map: mapReducer,
    project: projectReducer,
    profile: profileReducer,
    subscription: subscriptionReducer,
    activesubscription: activeSubscriptionReducer,
    sidebar: sidebarReducer
})
export default rootReducer