import Modal from 'react-modal'
import { CSSTransition } from 'react-transition-group';
import React from 'react'

/**
To use this popup you have to pass following props
  1) onRequestClose as function
  2) if You want to give width then pass width otherwise it take 41% by default
  3) if You want to give height then pass width otherwise it take 50% by default
*/
export const AnimatedModal = (props) => {

    const modalStyles = {
        content: {
            width: props.width || "max-content",
            height: props.height || "325px",
            background: props.background || "white",
            top: props.top || '50%',
            left: props.left || '50%',
            right: props.right || 'auto',
            bottom: 'auto',
            marginRight: props.marginRight || '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: "10px",
            textAlign: 'center',
            padding: props.padding||"3px",
            boxShadow: '0px 0px 17px -4px rgba(0, 0, 0, 0.27)',
            validationColor: "red",
            zIndex: props.zIndex ? props.zIndex : 1,
            overflow: props.overflow || 'unset',
        },
        overlay: {
            background: "#000000cc",
        }
    };

    return (
        <>
            <CSSTransition
                in={props.isOpen ? true : false}
                timeout={props.timeout || 300}
                classNames="alert"
                unmountOnExit
            >
                <Modal
                    isOpen={true}
                    onRequestClose={props.onRequestClose}
                    style={modalStyles}
                    ariaHideApp={false}
                >
                    {props.children}
                </Modal>
            </CSSTransition>
        </>
    )
}
