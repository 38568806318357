import React, { useEffect, useState } from 'react'
import useDrivePicker from 'react-google-drive-picker'
import { saveGoogleDriveData } from '../api';
import Loader from "../icons/loader.svg"
import AppConstants from '../AppConstants';
import { SetRestrictSesssionExpire } from '../helperComponents/OnIdleHandlerWrapper';
const GoogleDriveFilePicker = (props) => {
    const [sendData, setSendData] = useState()
    const [openPicker, authResponse] = useDrivePicker();
    // const customViewsArray = [new google.picker.DocsView()]; // custom view
    const handleOpenPicker = () => {
        openPicker({

            clientId: process.env.REACT_APP_GDRIVE_CLIENT_ID,
            developerKey: process.env.REACT_APP_GOOGLE_API_KEY,
            // viewId: "DOCS_IMAGES",
            viewMimeTypes: AppConstants.MIMETYPES.PNGJPEG,
            // token: token, // pass oauth token in case you already have one
            // showUploadView: true,
            setIncludeFolders: true,
            setSelectFolderEnabled: true,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: true,
            // customViews: customViewsArray, // custom view
            callbackFunction: async (data) => {
                if (data.action === 'cancel') {
                }
                if (data.action === "picked") {
                    SetRestrictSesssionExpire(true)
                    props.openImportPopup({
                        showProcessPopup: true,
                        processPopupIcon: "WAIT",
                        processMessage: "Importing files from google drive...",
                        keepOpenPopup: false
                    })



                    setSendData(data.docs)

                }
            },
        })
    }
    useEffect(() => {
        if (sendData?.length > 0)
            saveGoogleDriveData({ files: sendData, ...props.dataRequiredToSave, authResponse }).then(response => {
                setSendData(null)
                props.onImageUpload(100)
                SetRestrictSesssionExpire(false)
            }
            ).catch(err => {
                SetRestrictSesssionExpire(false)

                setSendData(null)
                props.openImportPopup({
                    showProcessPopup: true,
                    processPopupIcon: "ERROR",
                    processMessage: err?.message || "Something went wrong while importing images",
                    keepOpenPopup: true
                })
            }

            )
    }, [sendData?.length])
    useEffect(() => {
        if (props.callFromOutSide) {
            handleOpenPicker()

            props.clearCallFromOutSide()

        }
    }, [props.callFromOutSide])
    return <>
    </>
}

export default GoogleDriveFilePicker
