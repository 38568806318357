import * as THREE from 'three';
import { addMouseEvents, calculateIntersection, controlsEnabled, removeMouseEvents } from './mouseEvents';

const EVENTS = {
    END: "end",
    START: "start",
    VERTEX_ADDED: "vertex_added",
    GEOMETRY_ADDED: "geometry_added"
}

const TYPES = {
    POLYGON: "POLYGON",
    RECTANGLE: "RECTANGLE",
    HOTSPOT: "HOTSPOT"
}


const getVertexGeometry = () => {
    const vertexBoxSize = 0.05 // 5/100
    // Create a box geometry
    const boxGeometry = new THREE.BoxGeometry(vertexBoxSize, vertexBoxSize, vertexBoxSize / 4);

    // Create a basic material with a random color
    const boxMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });

    // Create a mesh using the geometry and material
    return (new THREE.Mesh(boxGeometry, boxMaterial))
}


class Hotspot {
    constructor({ scene, coordinates, id, objectsForRaycast, containerRef }) {
        this.id = id || Math.random();
        this.type = TYPES.HOTSPOT;
        // this.geometry = new THREE.BufferGeometry();
        this.vertices = null;
        this.material = new THREE.MeshBasicMaterial({ color: 0Xff000 });
        this.scene = scene;
        this.mesh = null;
        this.existingCoordinates = coordinates
        this.coordinates = [];
        this.editable = false;
        this.listeners = {};
        this.objects = objectsForRaycast;
        this.isDragging = false;
        this.containerRef = containerRef;

        //Bind the event handlers to the class intatces
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);

        if (coordinates?.length > 0) {
            coordinates.forEach((coord) => this.addVertex(new THREE.Vector3(coord.x, coord.y, coord.z)))
        }
    }

    // mouse events

    onMouseDown = async (event) => {
        if (this.editable) { // edit mode functionality

        } else if (this.isDrawing) { // create new functionality

            // Disable OrbitControls while drawing
            await controlsEnabled(false)

            this.mouseDown = await calculateIntersection(event.clientX, event.clientY, this.objects, this.containerRef)
            this.initCircle(this.mouseDown)
        }

    }

    onMouseMove = async (event) => {
        if (this.editable) {

        } else if (this.mouseDown) { // updateVertex 
            this.updateCircle(await calculateIntersection(event.clientX, event.clientY, this.objects, this.containerRef))
        }
    }

    onMouseUp = async (event) => {
        if (this.editable) {

        } else if (this.mouseDown) { // create new functionality
            this.endDrawing()
        }
    }


    // class level events  

    on(event, callback) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
    }

    off(event, callback) {
        if (this.listeners[event]) {
            this.listeners[event] = this.listeners[event].filter(cb => cb !== callback);
        }
    }

    emit(event, ...args) {
        if (this.listeners[event]) {
            this.listeners[event].forEach(callback => {
                callback(...args);
            });
        }
    }


    initCircle = (vertex) => {
        this.geometry = new THREE.SphereGeometry(0.1, 32, 32);
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.position.set(vertex.x, vertex.y, vertex.z);
        this.scene.add(this.mesh);


        if (!(this.existingCoordinates)) {
            this.addVertexGeometries(vertex)
            this.coordinates.push(vertex)
            this.emit(EVENTS.START, this)
        }
    }

    updateCircle = (vertex) => {
        //Logic for updating the circle is remaining

    }


    destroy() {
        // Remove the mesh from the scene
        if (this.mesh) {
            this.scene.remove(this.mesh);

            // Dispose geometry and material to release resources
            this.mesh.geometry.dispose();
            this.mesh.material.dispose();
        }

        this.disableEdit()

        // Clear any references
        this.id = null
        this.type = null
        this.geometry = null
        this.vertices = null
        this.material = null
        this.scene = null
        this.mesh = null
        this.existingCoordinates = null
        this.coordinates = null
        this.maxPoints = null
        this.listeners = null

        // Clear event listeners
        this.listeners = {};
    }

    enableEdit() {
        if (!this.editable) {
            this.editable = true
            // Bind the event handlers to the class instance
            this.onMouseDown = this.onMouseDown.bind(this);
            this.onMouseMove = this.onMouseMove.bind(this);
            this.onMouseUp = this.onMouseUp.bind(this);

            // Add mouse events
            addMouseEvents(this.onMouseDown, this.onMouseMove, this.onMouseUp);

            // adding vertices geometries
            this.coordinates.map(coord => {
                this.addVertexGeometries(coord)
            })
        }
    }

    disableEdit() {
        removeMouseEvents(this.onMouseDown, this.onMouseMove, this.onMouseUp)
    }



    //Start hotspot drawing
    startDrawing = () => {
        this.isDrawing = true
        addMouseEvents(this.onMouseDown, this.onMouseMove, this.onMouseUp, this.containerRef);
    }


    endDrawing = async () => {
        if (!(this.existingCoordinates)) this.emit(EVENTS.END, this)
        this.removeAllVertexGeometries()
        this.isDrawing = false;
        this.mouseDown = false
        controlsEnabled(true)
        removeMouseEvents(this.onMouseDown, this.onMouseMove, this.onMouseUp)
    }

    addVertex = async (vertex) => {
        if (this.coordinates.length === 0) {
            this.startDrawing(vertex);
            return
        }
        else {
            this.coordinates.push({
                x: vertex.x,
                y: vertex.y,
                z: vertex.z
            })
            // Update the vertices array by creating a new Float32Array with additional vertex data
            const newVertices = new Float32Array(this.geometry.attributes.position.array.length + 3); // 3 values per vertex
            newVertices.set(this.geometry.attributes.position.array, 0); // Copy the existing vertex data
            newVertices.set([vertex.x, vertex.y, vertex.z], this.geometry.attributes.position.array.length); // Add the new vertex data

            // Update the buffer attribute with the new vertices
            this.geometry.setAttribute('position', new THREE.BufferAttribute(newVertices, 3));

            this.geometry.attributes.position.needsUpdate = true;

            if (this.coordinates.length === this.maxPoints) {
                this.endDrawing()
            }
            if (!(this.existingCoordinates)) {
                this.emit(EVENTS.VERTEX_ADDED, this)
                this.addVertexGeometries(vertex)
            }
            return
        }
    }


    addVertexGeometries = (vertex) => {
        const vertexGeometry = getVertexGeometry()
        vertexGeometry.position.set(vertex.x, vertex.y, vertex.z);
        this.mesh.add(vertexGeometry);
    }

    removeAllVertexGeometries = async () => {
        const remove = async (m) => {
            setTimeout(async () => {
                this.mesh.remove(m)
                return
            }, 0);
        }
        for (const m of this.mesh.children) {
            await remove(m)
        }

    }
}


class Rectangle {
    constructor({ scene, coordinates, id, objectsForRaycast }) {
        this.id = id || Math.random()
        this.type = TYPES.RECTANGLE
        this.geometry = new THREE.BufferGeometry();
        this.vertices = null;
        this.material = new THREE.LineBasicMaterial({ color: 0xff0000 });
        this.scene = scene;
        this.mesh = null;
        this.existingCoordinates = coordinates
        this.coordinates = [];
        this.editable = false
        this.listeners = {}
        this.objects = objectsForRaycast

        this.isDrawing = false

        // Bind the event handlers to the class instance
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);

        if (coordinates?.length > 0) {
            coordinates.forEach((coord) => this.addVertex(new THREE.Vector3(coord.x, coord.y, coord.z)))
        }

    }

    // mouse events

    onMouseDown = async (event) => {
        if (this.editable) { // edit mode functionality

        } else if (this.isDrawing) { // create new functionality
            // Disable OrbitControls while drawing
            await controlsEnabled(false)

            this.mouseDown = await calculateIntersection(event.clientX, event.clientY, this.objects)
            this.initRect(this.mouseDown)
        }
    }

    onMouseMove = async (event) => {
        if (this.editable) {

        } else if (this.mouseDown) { // updateVertex 
            this.updateRect(2, await calculateIntersection(event.clientX, event.clientY, this.objects))
        }
    }

    onMouseUp = async (event) => {
        if (this.editable) {

        } else if (this.mouseDown) { // create new functionality
            this.endDrawing()
        }
    }

    // class level events  

    on(event, callback) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
    }

    off(event, callback) {
        if (this.listeners[event]) {
            this.listeners[event] = this.listeners[event].filter(cb => cb !== callback);
        }
    }

    emit(event, ...args) {
        if (this.listeners[event]) {
            this.listeners[event].forEach(callback => {
                callback(...args);
            });
        }
    }

    updateVertex0 = () => {
    }

    updateVertex1 = () => {
    }

    updateVertex2 = (vertex) => {
        // Update existing line's vertices
        const [vertexB, vertexD] = this.getRemainingTwoPoints(vertex, this.coordinates[0])

        this.mesh.geometry.attributes.position.array[3] = vertexB.x;
        this.mesh.geometry.attributes.position.array[4] = vertexB.y;
        this.mesh.geometry.attributes.position.array[5] = vertexB.z;
        this.coordinates[1] = {
            x: vertexB.x,
            y: vertexB.y,
            z: vertexB.z
        }

        this.mesh.children[1].position.set(vertexB.x, vertexB.y, vertexB.z)


        this.mesh.geometry.attributes.position.array[6] = vertex.x;
        this.mesh.geometry.attributes.position.array[7] = vertex.y;
        this.mesh.geometry.attributes.position.array[8] = vertex.z;
        this.coordinates[2] = {
            x: vertex.x,
            y: vertex.y,
            z: vertex.z
        }

        this.mesh.children[2].position.set(vertex.x, vertex.y, vertex.z)


        this.mesh.geometry.attributes.position.array[9] = vertexD.x;
        this.mesh.geometry.attributes.position.array[10] = vertexD.y;
        this.mesh.geometry.attributes.position.array[11] = vertexD.z;
        this.coordinates[3] = {
            x: vertexD.x,
            y: vertexD.y,
            z: vertexD.z
        }

        this.mesh.children[3].position.set(vertexD.x, vertexD.y, vertexD.z)

        this.mesh.geometry.attributes.position.needsUpdate = true;
    }
    updateVertex3 = () => {

    }

    // drawing related functions

    getRemainingTwoPoints = (pointA, pointC) => {
        const width = Math.abs(pointC.x - pointA.x);
        const height = Math.abs(pointC.y - pointA.y);
        const depth = Math.abs(pointC.z - pointA.z);

        const vector1 = width <= height
            ? { x: width, y: 0, z: 0 }
            : { x: 0, y: height, z: 0 };

        const vector2 = width <= height
            ? { x: 0, y: height, z: 0 }
            : { x: width, y: 0, z: 0 };

        const pointB = { x: pointA.x + vector1.x, y: pointA.y + vector1.y, z: pointA.z + vector1.z };
        const pointD = { x: pointC.x - vector1.x, y: pointC.y - vector1.y, z: pointC.z - vector1.z };


        // const center = { x: (pointA.x + pointC.x) / 2, y: (pointA.y + pointC.y) / 2, z: (pointA.z + pointC.z) / 2 };
        // const vectorAC = { x: pointC.x - pointA.x, y: pointC.y - pointA.y, z: pointC.z - pointA.z };
        // const vectorPerpendicular = { x: vectorAC.y, y: -vectorAC.x, z: 0 };
        // const magnitude = Math.hypot(vectorPerpendicular.x, vectorPerpendicular.y, vectorPerpendicular.z);
        // const normalizedPerpendicular = { x: vectorPerpendicular.x / magnitude, y: vectorPerpendicular.y / magnitude, z: vectorPerpendicular.z / magnitude };
        // const pointB = { x: center.x + 0.5 * normalizedPerpendicular.x, y: center.y + 0.5 * normalizedPerpendicular.y, z: center.z + 0.5 * normalizedPerpendicular.z };
        // const pointD = { x: center.x - 0.5 * normalizedPerpendicular.x, y: center.y - 0.5 * normalizedPerpendicular.y, z: center.z - 0.5 * normalizedPerpendicular.z };
        return [pointB, pointD]
    }



    initRect = (vertex) => {
        this.vertices = new Float32Array([vertex.x, vertex.y, vertex.z, vertex.x, vertex.y, vertex.z, vertex.x, vertex.y, vertex.z, vertex.x, vertex.y, vertex.z, vertex.x, vertex.y, vertex.z])
        this.geometry.setAttribute('position', new THREE.BufferAttribute(this.vertices, 3));
        this.mesh = new THREE.Line(this.geometry, this.material);

        this.coordinates = [{
            x: vertex.x,
            y: vertex.y,
            z: vertex.z
        }, {
            x: vertex.x,
            y: vertex.y,
            z: vertex.z
        }, {
            x: vertex.x,
            y: vertex.y,
            z: vertex.z
        }, {
            x: vertex.x,
            y: vertex.y,
            z: vertex.z
        }]

        this.scene.add(this.mesh);

        if (!(this.existingCoordinates)) {
            this.addVertexGeometries(vertex)
            this.addVertexGeometries(vertex)
            this.addVertexGeometries(vertex)
            this.addVertexGeometries(vertex)

            this.emit(EVENTS.START, this)
        }
    }

    updateRect = (selectedVertexPosition, vertex) => {
        switch (selectedVertexPosition) {
            case 0:
                this.updateVertex0()
                break;

            case 1:
                this.updateVertex1()
                break;

            case 2:
                this.updateVertex2(vertex)
                break;
            case 3:
                this.updateVertex3()
                break;

            default:
                console.log("no valid case.")
        }
    }

    enableEdit() {
        // if (!this.editable && this.coordinates.length === 5) {
        //     this.editable = true

        //     // Add mouse events
        //     addMouseEvents(this.onMouseDown, this.onMouseMove, this.onMouseUp);

        //     // adding vertices geometries
        //     this.coordinates.map(coord => {
        //         this.addVertexGeometries(coord)
        //     })

        // }

    }

    disableEdit() {
        // this.isDrawing = false;
        // this.mouseDown = false
        // controlsEnabled(true)
        // removeMouseEvents(this.onMouseDown, this.onMouseMove, this.onMouseUp)
    }

    destroy() {
        // Remove the mesh from the scene
        if (this.mesh) {
            this.scene.remove(this.mesh);

            // Dispose geometry and material to release resources
            this.mesh.geometry.dispose();
            this.mesh.material.dispose();
        }

        this.disableEdit()

        // Clear any references
        this.id = null
        this.type = null
        this.geometry = null
        this.vertices = null
        this.material = null
        this.scene = null
        this.mesh = null
        this.existingCoordinates = null
        this.coordinates = null
        this.maxPoints = null
        this.listeners = null

        // Clear event listeners
        this.listeners = {};
    }

    startDrawing = () => {
        // Add mouse events
        this.isDrawing = true
        addMouseEvents(this.onMouseDown, this.onMouseMove, this.onMouseUp);
    }

    endDrawing = async () => {
        if (!(this.existingCoordinates)) this.emit(EVENTS.END, this)
        this.removeAllVertexGeometries()
        this.isDrawing = false;
        this.mouseDown = false
        controlsEnabled(true)
        removeMouseEvents(this.onMouseDown, this.onMouseMove, this.onMouseUp)
    }

    addVertex = async (vertex) => {
        if (this.coordinates.length === 0) {
            this.startDrawing(vertex);
            return
        }
        else {
            this.coordinates.push({
                x: vertex.x,
                y: vertex.y,
                z: vertex.z
            })
            // Update the vertices array by creating a new Float32Array with additional vertex data
            const newVertices = new Float32Array(this.geometry.attributes.position.array.length + 3); // 3 values per vertex
            newVertices.set(this.geometry.attributes.position.array, 0); // Copy the existing vertex data
            newVertices.set([vertex.x, vertex.y, vertex.z], this.geometry.attributes.position.array.length); // Add the new vertex data

            // Update the buffer attribute with the new vertices
            this.geometry.setAttribute('position', new THREE.BufferAttribute(newVertices, 3));

            this.geometry.attributes.position.needsUpdate = true;

            if (this.coordinates.length === this.maxPoints) {
                this.endDrawing()
            }
            if (!(this.existingCoordinates)) {
                this.emit(EVENTS.VERTEX_ADDED, this)
                this.addVertexGeometries(vertex)
            }
            return
        }
    }

    // vertices geometry related functions
    addVertexGeometries = (vertex) => {
        const vertexGeometry = getVertexGeometry()
        vertexGeometry.position.set(vertex.x, vertex.y, vertex.z);
        this.mesh.add(vertexGeometry);
    }

    removeAllVertexGeometries = async () => {
        const remove = async (m) => {
            setTimeout(async () => {
                this.mesh.remove(m)
                return
            }, 0);
        }
        for (const m of this.mesh.children) {
            await remove(m)

        }
    }
}

class Polygon {

    constructor({ scene, coordinates, maxPoints, id, containerRef }) {
        this.id = id || Math.random()
        this.type = TYPES.POLYGON
        this.geometry = new THREE.BufferGeometry();
        this.vertices = null;
        this.material = new THREE.LineBasicMaterial({ color: 0xff0000 });
        this.scene = scene;
        this.mesh = null;
        this.existingCoordinates = coordinates
        this.coordinates = [];
        this.maxPoints = maxPoints || Infinity;
        this.editable = false
        this.listeners = {}
        this.containerRef = containerRef

        this.isDragging = false


        if (coordinates?.length > 0) {
            coordinates.forEach((coord) => this.addVertex(new THREE.Vector3(coord.x, coord.y, coord.z)))
        }

    }

    // mouse events

    onMouseDown = (event) => {
        this.isDragging = false;
        if (this.editable) {

        }

    }

    onMouseMove = (event) => {
        this.isDragging = true;
        if (this.editable) {
            console.log("F")
        }
    }

    onMouseUp = (event) => {
        if (this.editable) {

        }
    }

    // class level events  

    on(event, callback) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
    }

    off(event, callback) {
        if (this.listeners[event]) {
            this.listeners[event] = this.listeners[event].filter(cb => cb !== callback);
        }
    }

    emit(event, ...args) {
        if (this.listeners[event]) {
            this.listeners[event].forEach(callback => {
                callback(...args);
            });
        }
    }

    // drawing related functions

    enableEdit() {
        if (!this.editable && this.coordinates.length === 5) {
            this.editable = true
            // Bind the event handlers to the class instance
            this.onMouseDown = this.onMouseDown.bind(this);
            this.onMouseMove = this.onMouseMove.bind(this);
            this.onMouseUp = this.onMouseUp.bind(this);

            // Add mouse events
            addMouseEvents(this.onMouseDown, this.onMouseMove, this.onMouseUp);

            // adding vertices geometries
            this.coordinates.map(coord => {
                this.addVertexGeometries(coord)
            })

        }

    }

    disableEdit() {
        removeMouseEvents(this.onMouseDown, this.onMouseMove, this.onMouseUp)
    }

    destroy() {
        // Remove the mesh from the scene
        if (this.mesh) {
            this.scene.remove(this.mesh);

            // Dispose geometry and material to release resources
            this.mesh.geometry.dispose();
            this.mesh.material.dispose();
        }

        this.disableEdit()

        // Clear any references
        this.id = null
        this.type = null
        this.geometry = null
        this.vertices = null
        this.material = null
        this.scene = null
        this.mesh = null
        this.existingCoordinates = null
        this.coordinates = null
        this.maxPoints = null
        this.listeners = null

        // Clear event listeners
        this.listeners = {};
    }

    startDrawing = (vertex) => {
        this.vertices = new Float32Array([vertex.x, vertex.y, vertex.z, vertex.x, vertex.y, vertex.z])
        this.geometry.setAttribute('position', new THREE.BufferAttribute(this.vertices, 3));
        this.mesh = new THREE.Line(this.geometry, this.material);
        this.scene.add(this.mesh);
        this.coordinates.push({
            x: vertex.x,
            y: vertex.y,
            z: vertex.z
        })

        if (!(this.existingCoordinates)) {
            this.addVertexGeometries(vertex)
            this.emit(EVENTS.START, this)
        }
    }

    endDrawing = async () => {
        await this.addVertex(this.coordinates[0])
        if (!(this.existingCoordinates)) this.emit(EVENTS.END, this)
        this.removeAllVertexGeometries()
    }

    addVertex = async (vertex) => {
        if (this.coordinates.length === 0) {
            this.startDrawing(vertex);
            return
        }
        else {
            this.coordinates.push({
                x: vertex.x,
                y: vertex.y,
                z: vertex.z
            })
            // Update the vertices array by creating a new Float32Array with additional vertex data
            const newVertices = new Float32Array(this.geometry.attributes.position.array.length + 3); // 3 values per vertex
            newVertices.set(this.geometry.attributes.position.array, 0); // Copy the existing vertex data
            newVertices.set([vertex.x, vertex.y, vertex.z], this.geometry.attributes.position.array.length); // Add the new vertex data

            // Update the buffer attribute with the new vertices
            this.geometry.setAttribute('position', new THREE.BufferAttribute(newVertices, 3));

            this.geometry.attributes.position.needsUpdate = true;

            if (this.coordinates.length === this.maxPoints) {
                this.endDrawing()
            }
            if (!(this.existingCoordinates)) {
                this.emit(EVENTS.VERTEX_ADDED, this)
                this.addVertexGeometries(vertex)
            }
            return
        }
    }

    moveLastNonAddedPoint = (vertex) => {
        if (this.mesh && vertex && this.coordinates?.length > 0) {
            this.mesh.geometry.attributes.position.array[this.mesh.geometry.attributes.position.array.length - 3] = vertex.x;
            this.mesh.geometry.attributes.position.array[this.mesh.geometry.attributes.position.array.length - 2] = vertex.y;
            this.mesh.geometry.attributes.position.array[this.mesh.geometry.attributes.position.array.length - 1] = vertex.z;
            this.mesh.geometry.attributes.position.needsUpdate = true;
        }
    }


    // vertices geometry related functions

    addVertexGeometries = (vertex) => {
        if (this.coordinates.length === 1 || !(vertex.x === this.coordinates[0].x && vertex.y === this.coordinates[0].y && vertex.z === this.coordinates[0].z)) {
            const vertexGeometry = getVertexGeometry()
            vertexGeometry.position.set(vertex.x, vertex.y, vertex.z);
            this.mesh.add(vertexGeometry);
        }

    }

    removeAllVertexGeometries = async () => {
        const remove = async (m) => {
            setTimeout(async () => {
                this.mesh.remove(m)
                return
            }, 0);
        }
        for (const m of this.mesh.children) {
            await remove(m)

        }

    }


}


class ThreeJsDrawing {
    constructor(scene, camera, objectsForRaycast, continuousDrawing, containerRef) {
        this.isDragging = false
        this.mainScene = scene
        this.scene = scene;
        this.lastMouseDownEvent = null
        this.activeDrawing = null
        this.camera = camera
        this.objects = objectsForRaycast
        this.continuousDrawing = continuousDrawing
        this.geometries = []
        this.listeners = {}
        this.containerRef = containerRef;

        scene.add(this.scene)

        // Bind the event handlers to the class instance
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);

        // Add mouse events
        addMouseEvents(this.onMouseDown, this.onMouseMove, this.onMouseUp);

    }

    on(event, callback) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
    }

    off(event, callback) {
        if (this.listeners[event]) {
            this.listeners[event] = this.listeners[event].filter(cb => cb !== callback);
        }
    }

    emit(event, ...args) {
        if (this.listeners[event]) {
            this.listeners[event].forEach(callback => {
                callback(...args);
            });
        }
    }

    onDrawingEnd = (geometry) => {
        this.geometries.push(geometry)

        this.emit(EVENTS.GEOMETRY_ADDED, geometry)
        if (this.continuousDrawing) {
            if (this.activeDrawing.type === TYPES.POLYGON) this.startPolygonDrawing()
            this.activeDrawing = null
        } else this.activeDrawing = null
    }



    enableDrawing = (type) => {
        if (this.activeDrawing) {
            this.activeDrawing.destroy()
        } // if user enable drawing without ended

        if (type === TYPES.POLYGON) {
            this.activeDrawing = new Polygon({ scene: this.scene, containerRef: this.containerRef })
            this.activeDrawing.on(EVENTS.END, this.onDrawingEnd)
        }

        if (type === TYPES.RECTANGLE) {
            this.activeDrawing = new Rectangle({ scene: this.scene, objectsForRaycast: this.objects, renderer: this.renderer })
            this.activeDrawing.startDrawing()
            this.activeDrawing.on(EVENTS.END, this.onDrawingEnd)
        }

        if (type === TYPES.HOTSPOT) {
            this.activeDrawing = new Hotspot({ scene: this.scene, objectsForRaycast: this.objects, containerRef: this.containerRef })
            this.activeDrawing.startDrawing(this.containerRef);
            this.activeDrawing.on(EVENTS.END, this.onDrawingEnd)
        }


    }

    onMouseDown = (event) => {
        this.isDragging = false;
        if (this.activeDrawing?.type === TYPES.POLYGON) { //polygon
            this.lastMouseDownEvent = event;
        }

    }

    onMouseMove = async (event) => {
        if (this.activeDrawing?.type === TYPES.POLYGON) { //polygon
            this.isDragging = true
            this.activeDrawing.moveLastNonAddedPoint(await calculateIntersection(event.clientX, event.clientY, this.objects, this.containerRef))
        }
    }

    onMouseUp = async () => {
        if (this.lastMouseDownEvent && !(this.isDragging) && this.activeDrawing && this.activeDrawing?.type === TYPES.POLYGON) { //polygon
            if (this.activeDrawing?.mesh?.children.length > 2 && await calculateIntersection(this.lastMouseDownEvent.clientX, this.lastMouseDownEvent.clientY, [this.activeDrawing.mesh.children[0]], this.containerRef)) this.activeDrawing.endDrawing()
            else this.activeDrawing.addVertex(await calculateIntersection(this.lastMouseDownEvent.clientX, this.lastMouseDownEvent.clientY, this.objects, this.containerRef))
        }
    }
}




export {
    Polygon,
    ThreeJsDrawing,
    Rectangle,
    EVENTS,
    TYPES
}