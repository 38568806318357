//To user mouse events please set Three js data first

import * as THREE from 'three';


let renderer = null
let raycaster = new THREE.Raycaster()
let camera = null
let scene = null
let mouse = new THREE.Vector2()
let controls = null

const setThreeJsData = (r, s, cam, c) => {
    renderer = r
    camera = cam
    scene = s
    controls = c
}

const getRenderer = () => {
    return renderer
}

const getScene = () => {
    return scene
}

const getCamera = () => {
    return camera
}

const controlsEnabled = async (value) => {
    controls.enabled = value
    return
}

const calculateIntersection = async (clientX, clientY, objects, containerRef) => {
    const rect = containerRef.getBoundingClientRect();
    const xContainerRef = clientX - rect.left;
    const yContainerRef = clientY - rect.top;
    // Convert mouse coordinates to normalized device coordinates (-1 to 1)
    mouse.x = (xContainerRef / containerRef.clientWidth) * 2 - 1;
    mouse.y = - (yContainerRef / containerRef.clientHeight) * 2 + 1;

    // Update the picking ray with the camera and mouse position
    raycaster.setFromCamera(mouse, camera);

    // Calculate intersection with objects
    const intersects = raycaster.intersectObjects(objects);

    if (intersects.length > 0) {
        return intersects[0].point;
    } else return null;
}

const addMouseEvents = (onMouseDown, onMouseMove, onMouseUp, onRightClick) => {
    // console.log("conatinerRefffff:", containerRef)
    if (onMouseDown) renderer.domElement.addEventListener('mousedown', onMouseDown);
    if (onMouseMove) renderer.domElement.addEventListener('mousemove', onMouseMove);
    if (onMouseUp) renderer.domElement.addEventListener('mouseup', onMouseUp);
    if (onRightClick) renderer.domElement.addEventListener('contextmenu', onRightClick);
}

const removeMouseEvents = (onMouseDown, onMouseMove, onMouseUp, onRightClick) => {
    if (onMouseDown) renderer.domElement.removeEventListener('mousedown', onMouseDown);
    if (onMouseMove) renderer.domElement.removeEventListener('mousemove', onMouseMove);
    if (onMouseUp) renderer.domElement.removeEventListener('mouseup', onMouseUp);
    if (onRightClick) renderer.domElement.removeEventListener('contextmenu', onRightClick);
}


export {
    addMouseEvents,
    removeMouseEvents,
    setThreeJsData,
    calculateIntersection,
    controlsEnabled
}