import React, { useEffect, useState } from 'react'
import ButtonWithIcon from './ButtonWithIcon';
const GDriveDownload = () => {
    // this code is needed if we use link pasting in future,untill then it is commented because it gives error bcoz of reqiures things which are not used now


    // const [gdriveLink, setGdriveLink] = useState("")
    // const { handleClientLoad, isSignedIn, downloadFile, downloadFolder, setUserApiKey, setUserClientId } = useGDriveAuthentication()
    // const destinationPath = "/"
    // async function downloadLinkData(link) {
    //     let linkArr = link.split("/")
    //     let type = linkArr.find(t => t === "file") ? "file" : linkArr.find(t => t === "folders") ? "folder" : "file"
    //     if (type === "file") {
    //         let fileId = linkArr[linkArr.length - 2]
    //         console.log("fileId", fileId)
    //         await downloadFile(fileId)
    //     } else if (type == "folder") {
    //         let folderId = linkArr[linkArr.length - 1].split("?")[0]
    //         console.log("folderid", folderId)
    //         downloadFolder(folderId)
    //     }
    // }

    // useEffect(() => {
    //     downloadLinkData(gdriveLink)
    // }, [isSignedIn])
    // return (
    //     <div style={{ display: "flex", alignItems: "center" }}>
    //         <input placeholder='Paste google drive link here' value={gdriveLink} onChange={e => setGdriveLink(e.target.value)} />
    //         <ButtonWithIcon text={"Download"} onClick={() => {
    //             setUserApiKey(false)
    //             setUserClientId(false)
    //             handleClientLoad()
    //         }} />
    //     </div>
    // )
}

export default GDriveDownload