import React from 'react'
import SingleMap from '../../../ReusableComponents/map'

class Map extends React.Component {
    constructor() {
        super();
        this.state = {
            map1: undefined,
            map2: undefined,
            compare: false,
            bounds: [
                81.35318819592048,
                27.06923120610262,
                81.37569849243829,
                27.09219651327398
            ]
        };
    }

    getCurrentMapExtent = () => {
        const { map1, bounds } = this.props
        // if (map1) {
        //     return (map1.getView().calculateExtent(map1.getSize()))
        // } else {
        //     // let extendBounds = 0.00015   
        return bounds
        // }
    }
    // render component
    render() {
        return (
            <>
                <SingleMap
                    innerMapChildren={this.props.innerMapChildren}
                    style={this.props.style}
                    setBaseLayerToState={this.props.setBaseLayerToState}
                    initCenter={this.props.initCenter}
                    map={this.props.map1}
                    initZoom={this.props.initZoom}
                    bounds={this.getCurrentMapExtent()}
                    handleMapClick={this.props.handleMapClick}
                    handleMoveStart={this.props.handleMoveStart}
                    maxZoom={this.props.maxZoom}
                    setMap={this.props.setMap1}
                >{this.props.children}</SingleMap>
            </>
        )

    }
}

export default Map