import React from "react";
import GridInActive from '../../icons/gridview.png'
import ListInActive from '../../icons/listview.png'
import GridActive from '../../icons/gridViewActiveIcon.png'
import ListActive from '../../icons/listViewActiveIcon.png'
;


const Viewbuttons = ({
    globalState,
    handleStateChange,
}) => {
    return (
        <div className='viewbuttons'>
            <div
                onClick={() => handleStateChange("selectView", "list")}
                className={`listicon_wrapper ${globalState.selectView === 'list' ? 'active' : ''}`}
            >
                <div className='img'>
                    <img src={globalState.selectView === 'list' ? ListActive : ListInActive} />
                </div>
            </div>
            <div
                onClick={() => handleStateChange("selectView", "grid")}
                className={`gridicon_wrapper ${globalState.selectView === 'grid' ? 'active' : ''}`}>
                <div className='img'>
                    <img src={globalState.selectView === 'grid' ? GridActive : GridInActive} />
                </div>
            </div>
        </div>
    )

}

export default Viewbuttons;