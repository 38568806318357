import React from 'react';
import ReactTooltip from 'react-tooltip';

//toolbar icons 
import compareInactiveIcon from '../../assets/toolbarIcons/compareInactive.png'
import inspectIcon from '../../assets/toolbarIcons/inspect.png'
import inspectInactiveIcon from '../../assets/toolbarIcons/inspectInactive.png'
import reportIcon from '../../assets/toolbarIcons/report.png'
import reportActiveIcon from '../../assets/toolbarIcons/reportActive.png'
import lineActive from '../../assets/toolbarIcons/lineActive.png'
import lineInactive from '../../assets/toolbarIcons/lineInactive.png'
import showLayers from '../../assets/toolbarIcons/layersActive.png'
import hideLayers from '../../assets/toolbarIcons/layersInactive.png'
import polygonActive from '../../assets/toolbarIcons/polygonActive.png'
import polygonInactive from '../../assets/toolbarIcons/polygonInactive.png'
import markerActive from '../../assets/toolbarIcons/markerActive.png'
import markerInactive from '../../assets/toolbarIcons/marker.png'
import pointerActive from '../../assets/toolbarIcons/pointerActive.png'
import pointerInactive from '../../assets/toolbarIcons/pointerInactive.png'
import PointInfoIcon from '../../assets/Icons/point_info.png'
import VolumeBoxIcon from '../../assets/Icons/VolumeBoxIcon.png'
import VolumeBoxIconActive from '../../assets/Icons/VolumeBoxIconActive.png'
import PointInfoIconActive from '../../assets/Icons/point_infoActive.png'
import BoxAnnotationIconInactive from "../../assets/Icons/boxToolInactive.png"
import mapDetailsActive from "../../assets/toolbarIcons/mapDetails.png"
import mapDetailsInactive from "../../assets/toolbarIcons/mapDetailsInactive.png"
import BoxAnnotationIconActive from "../../assets/Icons/boxToolActive.png"

import { DRONENAKSHA_FEATURES } from "../../Teams";

import { hideMeasurements } from "../../ReusableComponents/map/leafletMeasure.js"

const activeSidebarMeasurement = "Measurements"
const activeSidebarLayers = "Layers"
const activeSidebarMapDetails = "Map Details"
const activeSidebarDownloadAssets = "Download Assets"

const DrawingToolbar = ({ state, functions, callDrawingTool, potree, updateRMAssignDroneState }) => {
    const {
        isOwner,
        sharedBy,
        isAllowToDownload,
        FloorImagesViewer,
        minimizedFloorPlanningViewer,
        showPointer,
        compare,
        sidebarPanel,
        activeSidepanel,
        activeSideBarFloor,
        panning,
        permissions,
        orthoImageData,
        ThreeDShow,
        bothShowPT,
        showOnclickPointDetails,
        drawVolumeBox,
        drawPolygon,
        drawMarker,
        drawPolyline,
        drawMeasurements,
        inspectShow,
        showMeasurement,
        images
    } = state

    const {
        handleImageAnnotationToolChange,
        managePopup,
        toggleSidepanelData,
        toggleMeasurement,
        getTiffAvailable,
    } = functions

    return (
        <div>
            {!isOwner ?
                <div className='type-badge' style={{ borderRadius: '0px 20px 20px 0px', padding: '6px 20px 6px 10px', fontSize: "10px", top: "55px", left: '0px', width: 'fit-content', zIndex: 3, flexDirection: "column", justifyContent: "left", textAlign: "left" }}>
                    <div>
                        {sharedBy == null || !(sharedBy) ? <></> : `Shared by ${sharedBy.first_name.length > 8 ? sharedBy.first_name.substring(0, 8) : sharedBy.first_name} ${sharedBy.last_name.length > 8 ? sharedBy.last_name.substring(0, 8) + "..." : sharedBy.last_name}`}
                    </div>
                    <div>{isAllowToDownload ? 'Download Access' : 'View Access'}</div>
                </div> : <></>}


            <div className='slide' style={{ fontSize: "9px", zIndex: '1', borderRadius: '7px', right: sidebarPanel ? '23.5vw' : "5px", top: "36px", position: 'absolute', padding: '5px', color: "white", fontWeight: "500" }}>
                {
                    // isOwner ? <div className='slide'
                    //   style={{ fontSize: "9px", borderRadius: '7px', backgroundColor: 'white', textAlign: 'center', width: '32px', height: "32px", padding: '5px', color: "white", fontWeight: "500" }}>
                    //   {/* className={orthoImageData && isOwner ? "hoverPointer AeroFarm" : "disableDiv"} */}
                    //   <div className={isOwner || permissions[DRONENAKSHA_FEATURES.MAPS].SHARE ? "hoverPointer AeroFarm" : "disableDiv"}
                    //     onClick={() => {
                    //       if (isOwner || permissions[DRONENAKSHA_FEATURES.MAPS].SHARE) {
                    //         this.setState({ graphData: null, showElevationProfile: false })
                    //         managePopup('shareInviteModal', true)
                    //         this.getInviteUsersList()
                    //       }
                    //     }} style={{ height: "100% ", opacity: (isOwner || permissions[DRONENAKSHA_FEATURES.MAPS].SHARE) ? 1 : 0.5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", filter: permissions[DRONENAKSHA_FEATURES.MAPS].SHARE ? "" : "grayscale(100%)", }}>
                    //     <img data-tip data-for='share' src={shareIcon} width='17px' height="17px" />
                    //     <ReactTooltip offset={{ left: 10 }} id="share" place="left" type="light" effect="solid"><span style={{ zIndex: '3', position: 'relative' }}>Share</span></ReactTooltip>
                    //   </div>
                    // </div> : <></>
                }
                <div className='slide'
                    style={{ marginTop: "5px", fontSize: "9px", borderRadius: '7px', backgroundColor: 'white', textAlign: 'center', width: '32px', height: "", padding: '5px', color: "white", fontWeight: "500", display: "flex", flexDirection: "column", alignItems: "center", gap: "7px" }}>


                    {/* className={orthoImageData ? "hoverPointer AeroFarm" : "disableDiv"} */}
                    <div className={"hoverPointer AeroFarm"}
                        onClick={() => {
                            if (FloorImagesViewer?.images?.length > 0 && !minimizedFloorPlanningViewer) {

                                handleImageAnnotationToolChange("showPointer", true)
                            } else {

                                managePopup("minimizedFloorPlanningViewer")
                                if (showPointer && !(compare || activeSidepanel == activeSideBarFloor)) toggleSidepanelData(activeSidebarMeasurement);
                                toggleMeasurement("showPointer");
                            }
                        }}
                        style={{ height: "8.1%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>

                        {showPointer || panning ? <img src={pointerActive} data-tip data-for='pointer' width='17px' height="17px" /> : <img data-tip data-for='pointer' src={pointerInactive} width='17px' height="17px" />}
                        <ReactTooltip offset={{ left: 10 }} id="pointer" place="left" type="light" effect="solid"><span style={{ zIndex: '3', position: 'relative' }}>Select Pointer</span></ReactTooltip>
                    </div>

                    {/* className={orthoImageData && !(potree.current) ? "hoverPointer AeroFarm" : "disableDiv"} */}
                    <div className={(!((FloorImagesViewer?.images?.length > 0 || FloorImagesViewer?.images360?.length > 0) && !minimizedFloorPlanningViewer) && permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) && orthoImageData && !(ThreeDShow || bothShowPT) ? "hoverPointer AeroFarm" : "disableDiv"}
                        onClick={() => {
                            if (((FloorImagesViewer?.images?.length > 0 || FloorImagesViewer?.images360?.length > 0) && !minimizedFloorPlanningViewer) && permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) {

                            }
                            else {
                                if (permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) {

                                    managePopup()
                                    if (potree.current) {
                                        toggleMeasurement("drawVolumeBox")
                                    } else {
                                        toggleMeasurement("showOnclickPointDetails")
                                    }
                                }
                            }
                        }} style={{ height: "8.1%", opacity: !((FloorImagesViewer?.images?.length > 0 || FloorImagesViewer?.images360?.length > 0) && !minimizedFloorPlanningViewer) && orthoImageData && !(ThreeDShow || bothShowPT) ? 1 : 0.5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        {showOnclickPointDetails || (potree.current && drawVolumeBox) ? <img src={potree.current ? VolumeBoxIconActive : PointInfoIconActive} data-tip data-for='pointer' width='17px' height="17px" /> : <img data-tip data-for='pointDetails' src={potree.current ? VolumeBoxIcon : PointInfoIcon} width='17px' height="17px" />}
                        {!((FloorImagesViewer?.images?.length > 0 || FloorImagesViewer?.images360?.length > 0) && !minimizedFloorPlanningViewer) && orthoImageData && !(ThreeDShow || bothShowPT) && <ReactTooltip offset={{ left: 10 }} id="pointDetails" place="left" type="light" effect="solid"><span style={{ zIndex: '3', position: 'relative' }}>{"Point Details"}</span></ReactTooltip>}
                    </div>
                    {/* Polygon */}
                    {/* className={orthoImageData ? "hoverPointer AeroFarm" : "disableDiv"} */}
                    <div className={(!(FloorImagesViewer?.images360?.length > 0 && !minimizedFloorPlanningViewer) && permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) ? "hoverPointer AeroFarm" : "disable-div"}
                        onClick={() => {
                            if (permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) {
                                if ((FloorImagesViewer?.images360?.length > 0 && !minimizedFloorPlanningViewer)) {
                                    // callDrawingTool("POLYGON")
                                }
                                else if (FloorImagesViewer?.images?.length > 0 && !minimizedFloorPlanningViewer) {
                                    handleImageAnnotationToolChange("drawPolygon", false)
                                } else {

                                    if (activeSidepanel === activeSideBarFloor) managePopup(false, true)
                                    else managePopup()
                                    toggleMeasurement("drawPolygon")
                                    if (showMeasurement == false) {
                                        updateRMAssignDroneState("polygon")
                                    }
                                }
                            }
                        }} style={{ height: "8.2%", opacity: (!(FloorImagesViewer?.images360?.length > 0 && !minimizedFloorPlanningViewer) && permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) ? 1 : 0.5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", filter: permissions[DRONENAKSHA_FEATURES.MAPS].EDIT ? "" : "grayscale(100%)" }}>
                        {drawPolygon || !panning ? <img src={((FloorImagesViewer?.images?.length > 0 || FloorImagesViewer?.images360?.length > 0) && !minimizedFloorPlanningViewer) ? BoxAnnotationIconActive : polygonActive} data-tip data-for='polygon' width='17px' height="17px" /> : <img data-tip data-for='polygon' src={(FloorImagesViewer?.images?.length > 0 && !minimizedFloorPlanningViewer) ? BoxAnnotationIconInactive : polygonInactive} width='17px' height="17px" />}
                        {(!(FloorImagesViewer?.images360?.length > 0 && !minimizedFloorPlanningViewer) && permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) && <ReactTooltip offset={{ left: 10 }} id="polygon" place="left" type="light" effect="solid"><span style={{ zIndex: '3', position: 'relative' }}>{(FloorImagesViewer?.images?.length > 0 && !minimizedFloorPlanningViewer) ? "Mark issue" : "Measure Area"}</span></ReactTooltip>}
                    </div>
                    {/* Polyline */}
                    <div className={((FloorImagesViewer?.images?.length > 0 || FloorImagesViewer?.images360?.length > 0) && !minimizedFloorPlanningViewer) || !permissions[DRONENAKSHA_FEATURES.MAPS].EDIT ? "disable-div" : "hoverPointer AeroFarm"}
                        onClick={() => {
                            if (permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) {
                                if ((FloorImagesViewer?.images?.length > 0 || FloorImagesViewer?.images360?.length > 0) && !minimizedFloorPlanningViewer) {
                                } else {

                                    if (activeSidepanel === activeSideBarFloor) managePopup(false, true)
                                    else managePopup()
                                    toggleMeasurement("drawPolyline")
                                    if (showMeasurement == false) {
                                        updateRMAssignDroneState("line")
                                    }
                                }
                            }
                        }} style={{ height: "8.2%", opacity: !((FloorImagesViewer?.images?.length > 0 || FloorImagesViewer?.images360?.length > 0) && !minimizedFloorPlanningViewer) && (permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) ? 1 : 0.5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", filter: permissions[DRONENAKSHA_FEATURES.MAPS].EDIT ? "" : "grayscale(100%)" }}  >
                        {drawPolyline ? <img src={lineActive} data-tip data-for='polyline' width='17px' height="17px" /> : <img data-tip data-for='polyline' src={lineInactive} width='17px' height="17px" />}
                        {!((FloorImagesViewer?.images?.length > 0 || FloorImagesViewer?.images360?.length > 0) && !minimizedFloorPlanningViewer) && (permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) && <ReactTooltip offset={{ left: 10 }} id="polyline" place="left" type="light" effect="solid"><span style={{ zIndex: '3', position: 'relative' }}>Measure Length</span></ReactTooltip>}
                    </div>
                    {/* Marker */}
                    {/* className={orthoImageData ? "hoverPointer AeroFarm" : "disableDiv"} */}
                    <div className={!permissions[DRONENAKSHA_FEATURES.MAPS].EDIT || (FloorImagesViewer && !((FloorImagesViewer.images?.length > 0 || FloorImagesViewer.images360?.length > 0) && !minimizedFloorPlanningViewer)) ? "disable-div" : "hoverPointer AeroFarm"}
                        onClick={() => {

                            if (permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) {
                                if ((FloorImagesViewer?.images?.length > 0 || FloorImagesViewer?.images360?.length > 0) && !minimizedFloorPlanningViewer) {
                                    callDrawingTool("HOTSPOT")
                                } else {

                                    if (activeSidepanel === activeSideBarFloor) managePopup(false, true)
                                    else managePopup()
                                    toggleMeasurement("drawMarker")
                                    if (showMeasurement == false) {
                                        updateRMAssignDroneState("marker")
                                    }
                                }
                            }
                        }} style={{ height: "8.1%", opacity: !permissions[DRONENAKSHA_FEATURES.MAPS].EDIT || (FloorImagesViewer && !((FloorImagesViewer.images?.length > 0 || FloorImagesViewer.images360?.length > 0) && !minimizedFloorPlanningViewer)) ? 0.5 : 1, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", filter: !permissions[DRONENAKSHA_FEATURES.MAPS].EDIT || (FloorImagesViewer && !((FloorImagesViewer.images?.length > 0 || FloorImagesViewer.images360?.length > 0) && !minimizedFloorPlanningViewer)) ? "grayscale(100%)" : "" }}>
                        {drawMarker ? <img src={markerActive} data-tip data-for='marker' width='17px' height="17px" /> : <img data-tip data-for='marker' src={markerInactive} width='17px' height="17px" />}
                        {(permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) && <ReactTooltip offset={{ left: 10 }} id="marker" place="left" type="light" effect="solid"><span style={{ zIndex: '3', position: 'relative' }}>Mark Location</span></ReactTooltip>}
                    </div>

                    {/* className={orthoImageData ? "hoverPointer AeroFarm" : "disableDiv"} */}
                    <div className={"hoverPointer AeroFarm"}
                        onClick={() => {
                            toggleMeasurement("showPointer")
                            if (activeSidepanel === activeSidebarLayers && sidebarPanel) {
                                managePopup(false, true)
                            } else {
                                if (!(compare)) { toggleSidepanelData(activeSidebarLayers) }
                            }
                        }} style={{ height: "8.2%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }} >
                        {activeSidepanel === activeSidebarLayers && sidebarPanel ? <img src={showLayers} data-tip data-for='measurements' width='17px' height="17px" /> : <img data-tip data-for='measurements' src={hideLayers} width='17px' height="17px" />}
                        <ReactTooltip offset={{ left: 10 }} id="measurements" place="left" type="light" effect="solid"><span style={{ zIndex: '3', position: 'relative' }}>Layers</span></ReactTooltip>
                    </div>

                    <hr style={{ width: "34px", margin: "5px -7px" }} />

                    {/* className={orthoImageData ? "hoverPointer AeroFarm" : "disableDiv"} */}
                    <div className={(getTiffAvailable()) ? "hoverPointer AeroFarm" : "disableDiv"}
                        onClick={() => {
                            if (getTiffAvailable()) {
                                toggleMeasurement("showPointer")
                                if (activeSidepanel === activeSidebarMapDetails && sidebarPanel) {
                                    managePopup(false, true)
                                } else {
                                    if (!(compare)) { toggleSidepanelData(activeSidebarMapDetails) }
                                }
                            }
                            // style={{ height: "8.7%", opacity: orthoImageData ? 1 : 0.5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        }} style={{
                            opacity: (getTiffAvailable()) ? 1 : 0.5,
                            marginBottom: "4px",
                            height: "8.1%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"
                        }}>
                        {activeSidepanel === activeSidebarMapDetails && sidebarPanel ? <img src={mapDetailsActive} data-tip data-for='mapDetails' width='17px' height="17px" /> : <img data-tip data-for='mapDetails' src={mapDetailsInactive} width='17px' height="17px" />}
                        <ReactTooltip offset={{ left: 10 }} id="mapDetails" place="left" type="light" effect="solid"><span style={{ zIndex: '3', position: 'relative' }}>Map Details</span></ReactTooltip>
                    </div>

                    <div className={!((FloorImagesViewer?.images?.length > 0 || FloorImagesViewer?.images360?.length > 0) && !minimizedFloorPlanningViewer) && orthoImageData?.id ? "hoverPointer AeroFarm" : "disableDiv"}
                        onClick={() => {
                            if ((FloorImagesViewer?.images?.length > 0 || FloorImagesViewer?.images360?.length > 0) && !minimizedFloorPlanningViewer) {

                            } else {

                                managePopup(false, true)
                                updateRMAssignDroneState("compare");
                            }
                        }
                        }
                        // style={{ height: "8.7%", opacity: orthoImageData && !(potree.current) ? 1 : 0.5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        style={{ marginBottom: "4px", height: "8.1%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <img src={compareInactiveIcon} width='17px' height="17px" data-tip data-for='compare' />
                        {!((FloorImagesViewer?.images?.length > 0 || FloorImagesViewer?.images360?.length > 0) && !minimizedFloorPlanningViewer) && <ReactTooltip offset={{ left: 10 }} id="compare" place="left" type="light" effect="solid"><span style={{ zIndex: '3', position: 'relative' }}>Compare</span></ReactTooltip>}
                    </div>

                    {/* className={orthoImageData && !(potree.current) ? "hoverPointer AeroFarm" : "disableDiv"} */}
                    <div className={!(potree.current) && (permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ) && images.length > 0 ? "hoverPointer AeroFarm" : "disableDiv"}
                        onClick={() => {
                            if (!(potree.current) && (permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ) && images.length > 0) {

                                toggleMeasurement("showPointer")
                                updateRMAssignDroneState("inspect")
                            }
                        }} style={{ marginBottom: "4px", height: "8.1%", opacity: !(potree.current) && (permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ) && images.length > 0 ? 1 : 0.5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", filter: permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ ? "" : "grayscale(100%)" }}>
                        {inspectShow ? <img src={inspectIcon} width='17px' height="17px" data-tip data-for='inspect' /> : <img data-tip data-for='inspect' src={inspectInactiveIcon} width='17px' height="17px" />}
                        <ReactTooltip offset={{ left: 10 }} id="inspect" place="left" type="light" effect="solid"><span style={{ zIndex: '3', position: 'relative' }}>Inspect</span></ReactTooltip>
                    </div>

                    {/* className={orthoImageData && (isOwner || isAllowToDownload) ? "hoverPointer AeroFarm" : "disableDiv"} */}
                    <div className={(isOwner || isAllowToDownload) && orthoImageData?.id ? "hoverPointer AeroFarm" : "disableDiv"}
                        onClick={() => {
                            toggleMeasurement("showPointer")
                            if (activeSidepanel === activeSidebarDownloadAssets && sidebarPanel) {
                                managePopup(false, true)
                            } else {
                                if (!(compare)) { toggleSidepanelData(activeSidebarDownloadAssets) }
                            }
                        }}
                        // style={{ height: "8.7%", opacity: orthoImageData ? 1 : 0.5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        style={{ marginBottom: "4px", height: "8.2%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <img src={activeSidepanel === activeSidebarDownloadAssets && sidebarPanel ? reportActiveIcon : reportIcon} width='17px' height="17px" data-tip data-for='layer' />
                        <ReactTooltip offset={{ left: 10 }} id="layer" place="left" type="light" effect="solid"><span style={{ zIndex: '1' }}>Download Assets</span></ReactTooltip>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default DrawingToolbar