import React, { Component, useEffect, useState } from "react";
import Tiff from 'tiff.js'
import { connect } from "react-redux";
import * as API from "../../api.js";
import Loader from "../../icons/loader.svg";
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs';
import { v4 as uuid } from 'uuid';
import edit from '../../icons/pen.png'
import saveImage from '../../icons/saveImage.png'
import close from '../../assets/Icons/Close.png'
import Annotation from "./Annotation"
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement
} from 'chart.js';
import { Pie } from "react-chartjs-2";
import dateFormat from "dateformat";
import { ActivityPopup } from "../../ReusableComponents/ActivityPopup.js";
import prevIcon from "../../assets/Icons/prev_icon.png"
import nextIcon from "../../assets/Icons/next_icon.png"
import { Popup, checkURLExist, getLimitedName, getSevirityNumber } from "../../ReusableComponents/reusableFunctions.js";
import AppConstants from "../../AppConstants.js";
import { ActiveRole, getFeaturesPermissions, PICSTORK_FEATURES } from "../../Teams/index.js";
import PS from '../../windMill/HP.png'
import LE from '../../windMill/LE.png'
import TE from '../../windMill/TE.png'
import SS from '../../windMill/LP.png'
import { BLOB_URL } from "../../AppConstants";
import MapDetections from "./ReusableComp/MapDetections.js";
import DropDownWithButton from "../../ReusableComponents/DropDownWithButton.js";
import ButtonWithIcon from "../../ReusableComponents/ButtonWithIcon.js";
import Button from "../../ReusableComponents/Button/Button.js";

const graphColours = [
    'rgba(198, 252, 20,1)',
    'rgba(255, 227, 15, 1)',
    'rgba(252, 161, 3, 1)',
    'rgba(247, 30, 10, 1)',
    'rgba(148, 3, 3, 1)',
    'rgba(15, 158, 219, 1)',
]

class PicStorkThermalReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addRemark: false,
            addedRemark: "",
            task: {
                project_name: "",
                plan_name: "",
                task_name: "",
                type: ["missing screws", "rusting"],
            },
            user: {},
            type: "",
            progress: 0,
            selectedObj: [],
            objectsDetected: 0,
            showDownloadPopup: false,
            loader: true,
            services: [],
            isDetectionDirty: false,
            obj: [],
            data: [],
            report: [],
            report1: [],
            objects: [],
            projects: [],
            colors: [],
            showBackIcon: true,
            cancel: false,
            loading: true,
            objectsDetected: 0,
            sidebarMode: this.props.location.state
                ? this.props.location.state.sidebarMode
                : "shrunk",
            showData: [],
            reportheader: {},
            reportTitle: '',
            reportSubTitle: '',
            company_logo: '',
            company_name: '',
            editHeader: false,
            company_logo1: undefined,
            titleEdit: false,
            subTitleEdit: false,
            reportData: [
                { title: 'Analysis Report', visibleInReport: true, reportView: 'List Small' },
                { title: 'Detailed Analysis', visibleInReport: true, reportView: 'List Small' },
                { title: 'Severity-1 Detections', visibleInReport: true, reportView: 'List Small' },
                { title: 'Severity-2 Detections', visibleInReport: true, reportView: 'List Small' },
                { title: 'Severity-3 Detections', visibleInReport: true, reportView: 'List Small' },
                { title: 'Severity-4 Detections', visibleInReport: true, reportView: 'List Small' },
                { title: 'Severity-5 Detections', visibleInReport: true, reportView: 'List Small' }
            ],
            severity1Data: [],
            severity2Data: [],
            severity3Data: [],
            severity4Data: [],
            severity5Data: [],
            noneSeverityData: [],
            objectsToDisplay: [],
            reportViews: [{ value: 'List Small' }, { value: 'List Large' }, { value: 'Grid Small' }, { value: 'Grid Large' }],
            showTags: false, // to show/hide labels of detections
            totalObjectsDetected: 0,
            pieData: null,
            imageLoad: 0,
            selectedSeverity: "All",
            severityToSelect: [{ label: AppConstants.SEVIRITY._1 }, { label: AppConstants.SEVIRITY._2 }, { label: AppConstants.SEVIRITY._3 }, { label: AppConstants.SEVIRITY._4 }, { label: AppConstants.SEVIRITY._5 }, { label: AppConstants.SEVIRITY.NONE }, { label: 'All' }],
            allData: [],
            showAnnotation: true,
            showProcessPopup: false,
            processMessage: null,
            mainData: [],
            userperpage: 8,
            currentpage: 1,
            tempPageNo: undefined,
            counts: 0,
            currentPageData: {},
            refreshCanvas: '',
            total_storage: 0,
            total_resolution: 0,
            report_generated: '',
            report_modified: '',
            downloadFlag: '',
            generate_request: '',
            minutesDiff: 0,
            generation_status: false,
            downloadWarning: false,
            downloadProgress: false,
            imagesProcessed: 0,
            firstTimeDownload: false,
            windMillTask: false,
            filetype: '',
            isEdit: false,
            filterWarningPopup: false,
            deleteAnotationPopup: false,
            selectedRegionToDelete: undefined,
            updateReportAfterSave: false,
            // hideSeverityFilter: false,
        };
    }

    // to load tiff image 
    loadTiffImageSrc1 = async () => {
        let { showData, user, task } = this.state
        for (let i = 0; i < showData.length; i++) {
            if (showData[i].image) {
                showData[i].loading = true
                let name = showData[i].image.toLowerCase()
                if ((name.indexOf(".tif")) !== -1 && !(this.props.location.state.task.linked_map)) {
                    // for thumbnails
                    var xhr = new XMLHttpRequest();
                    xhr.responseType = 'arraybuffer';
                    let url = `${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${showData[i].image}?${this.state.permissions?.st}`
                    xhr.open('GET', url);
                    xhr.onload = function (e) {
                        var arraybuffer = e.target.response
                        Tiff.initialize({
                            TOTAL_MEMORY: 16777216 * 10
                        });
                        var tiff = new Tiff({
                            buffer: arraybuffer
                        })
                        var dataUrl = tiff.toDataURL();
                        let url = e.target.responseURL
                        var index = 0
                        showData.map((detection, j) => {
                            if (detection && detection.image && url.indexOf(encodeURIComponent(detection.image)) != -1) {
                                index = j
                            }
                        })
                        showData[index].imageSrc = dataUrl   // to insert url at specific position
                        this.setState((state) => ({
                            ...state,
                            showData
                        }))
                    }.bind(this);
                    xhr.send()
                }
                else {
                    showData[i].imageSrc = `${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${showData[i].image}?${this.state.permissions?.st}`   // to insert url at specific position
                    this.setState((state) => ({
                        ...state,
                        showData
                    }))
                }
            }
        }
    }

    // to load tiff image  current not in use
    loadTiffImageSrc = async () => {
        let { showData, user, task, currentPageData } = this.state
        // for (let i = 0; i < showData.length; i++) {
        if (currentPageData.image) {
            currentPageData.loading = true
            let name = currentPageData.image.toLowerCase()
            if ((name.indexOf(".tif")) !== -1) {
                // for thumbnails
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'arraybuffer';
                let url = `${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${currentPageData.image}?${this.state.permissions?.st}`
                xhr.open('GET', url);
                xhr.onload = function (e) {
                    var arraybuffer = e.target.response
                    Tiff.initialize({
                        TOTAL_MEMORY: 16777216 * 10
                    });
                    var tiff = new Tiff({
                        buffer: arraybuffer
                    })
                    var dataUrl = tiff.toDataURL();
                    currentPageData.imageSrc = dataUrl   // to insert url at specific position
                    this.setState((state) => ({
                        ...state,
                        currentPageData
                    }))
                }.bind(this);
                xhr.send()
            }
            else {
                currentPageData.imageSrc = `${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${currentPageData.image}?${this.state.permissions?.st}`   // to insert url at specific position
                this.setState((state) => ({
                    ...state,
                    currentPageData
                }))
            }
        }
        // }
    }

    createPageDataArray = () => {
        let { user, task } = this.state
        let data = this.state.showData
        let mainData = [{}, {}]
        let tableData = []
        let totalTableData = []

        for (let i = 0; i < data.length; i++) {
            let cropRegions = []
            for (let j = 0; j < data[i].cropRegions.length; j++) {
                if (j < 4) {
                    data[i].cropRegions[j].index = j + 1
                    cropRegions.push(data[i].cropRegions[j])
                    if (j === 3 || j == data[i].cropRegions.length - 1) {
                        let pageObj = {
                            image: data[i].imgData.file_name,
                            imageSrc: `${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${data[i].imgData.file_name}?${this.state.permissions?.st}`,
                            showImage: true,
                            regions: data[i].regions,
                            imgData: data[i].imgData,
                            cropRegions: cropRegions,
                            tags: data[i].tags,
                            loading: true
                        }
                        mainData.push(pageObj)
                        cropRegions = []
                    }
                } else if (j > 1) {
                    data[i].cropRegions[j].index = j + 1
                    cropRegions.push(data[i].cropRegions[j])
                    if (j % 12 === 1 || j === data[i].cropRegions.length - 1) {
                        let pageObj = {
                            image: data[i].imgData.file_name,
                            imageSrc: `${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${data[i].imgData.file_name}?${this.state.permissions?.st}`,
                            showImage: false,
                            imgData: data[i].imgData,
                            cropRegions: cropRegions,
                            tags: data[i].tags,
                            loading: true
                        }
                        mainData.push(pageObj)
                        cropRegions = []
                    }
                }
            }
        }
        this.setState((state) => ({
            ...state,
            mainData: mainData,
        }), () => {
            this.changepage(this.state.currentpage > (this.state.mainData.length - 1) ? (this.state.mainData.length - 1) : this.state.tempPageNo || 1)
        })
    }

    calculateDateDiff = () => {
        if (this.state.generated_date == null) {
            let date = new Date(0).toISOString()
            let diff = date - this.state.generate_request
            let diff1 = Math.floor(diff / 60e3)

            this.setState({
                minutesDiff: diff1
            })
        }
    }

    firstpage = () => {

        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1,
                currentPageData: {},
                refreshCanvas: false
            }), () => {
                this.setState({ refreshCanvas: true })
            });
        } else {
            console.log("Reached first page")
        }
    }
    // Prev Page function
    prevpage = () => {
        if (this.state.currentpage === 2 && this.state.selectedSeverity === "All") {
            this.setState({ currentpage: this.state.currentpage - 1, tempPageNo: undefined }, () => {
                this.getSummaryDetails()
            })
        }
        else if (this.state.currentpage > 1) {
            let temp = this.state.mainData[this.state.currentpage - 1]
            temp.loading = false
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1,
                currentPageData: temp,
                refreshCanvas: false,
            }), () => {
                this.setState({ refreshCanvas: true })

            });
        }


    }
    // Next page Function
    nextpage = () => {
        if (this.state.currentpage < this.state.mainData.length - 1) {
            let temp = this.state.mainData[this.state.currentpage + 1]
            temp.loading = false
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage + 1,
                currentPageData: temp,
                refreshCanvas: false
            }), () => {
                this.setState({ refreshCanvas: true })
            });
        } else {
            console.log("Reached next page")
        }
    }
    // Get Last Page Function Start here----------------------
    lastpage = () => {
        if (this.state.currentpage < this.state.mainData.length - 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.mainData.length - 1,
                currentPageData: this.state.mainData[this.state.mainData.length - 1],
                refreshCanvas: false
            }), () => {
                this.setState({ refreshCanvas: true })
            });
        } else {
            console.log("Reached last page")
        }
    }

    // Get Last Page Function End here ----------------------------
    // Get Changenumber Page Function Starts here ----------------------------
    changepage(num) {
        console.log("loading in change page", this.state.currentPageData.loading)
        if (isNaN(num)) {
            return null;
        } else {
            if (num === 1) {
                this.setState((state) => ({
                    ...state,
                    currentpage: 1,
                    currentPageData: {},
                    refreshCanvas: false
                }), () => {
                    this.setState({ refreshCanvas: true })
                });
            } else if (num !== 0 && num < this.state.mainData.length) {
                let temp = this.state.mainData[num]
                temp.loading = false
                this.setState((state) => ({
                    ...state,
                    currentpage: num,
                    currentPageData: temp,
                    refreshCanvas: false,
                    tempPageNo: undefined,
                }), () => {
                    this.setState({ refreshCanvas: true })
                    console.log("loading in change page", this.state.currentPageData.loading)
                });
            }
        }
    }

    setColors = () => {
        let colors = []
        this.state.task.type.forEach((element, i) => {
            let c = "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase();
            colors.push(c)
        });
        this.setState({ colors })
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
        }));
    };
    updateReportHeader = () => {
        API.updateReportHeader(this.state.reportTitle, this.state.reportSubTitle, this.state.company_name, this.state.reportheader.id).then((data) => {
            if (data.status == 200) {
                this.setState((state) => ({
                    ...state,
                    editHeader: false,
                    generation_status: true
                }))
                this.getReportHeader();
            } else {
                this.getReportHeader();
            }
        })
    }

    getReportHeader = () => {
        let task_id = this.state.task.task_id
        let report_type = 'Thermal'
        API.getReportHeader(task_id, report_type).then((data) => {
            if (data.data == null) {
                this.addReportHeader();
            } else {

                let profileURL = ""
                if (this.state.task.sharedBy && this.state.task.sharedBy.isShared) {
                    profileURL = `/images/${this.state.task.sharedBy.sharedBy.first_name[0].toLowerCase()}.PNG`
                } else if (data.data.comapany_logo != null && data.data.company_logo.includes('pdrlImage')) {
                    profileURL = `${data.data.companyLogo}`
                } else if (data.data.company_logo != null) {
                    profileURL = data.data.company_logo
                } else if (this.state.avatar_url && this.state.avatar_url.includes('pdrlImage')) {
                    profileURL = `${this.state.avatar_url}`
                } else if (this.state.avatar_url != null && this.state.avatar_url.includes('http')) {
                    profileURL = this.state.avatar_url
                } else {
                    profileURL = `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`
                }

                this.setState((state) => ({
                    ...state,
                    reportheader: data.data,
                    reportTitle: data.data.title,
                    reportSubTitle: data.data.subtitle,
                    company_logo: profileURL,
                    company_name: this.state.task.sharedBy && this.state.task.sharedBy.isShared ? `${this.state.task.sharedBy.sharedBy.first_name} ${this.state.task.sharedBy.sharedBy.last_name}` : data.data.name,
                    report_generated: data.data.generated_date,
                    report_modified: data.data.modified_date,
                    generate_request: data.data.generate_request
                }), () => {
                    this.calculateDateDiff()
                })
            }
        }, (e) => {
            console.log(e);
        })
    }

    addReportHeader = () => {
        let task_id = this.state.task.task_id
        let report_type = 'Thermal'
        let title = this.state.task.task_name
        let subtitle = this.state.task.project_name
        let company_logo = null
        // this.state.user.avatar_url ? `${this.state.user.avatar_url}?${this.state.permissions?.st}&t${new Date().getTime()}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`
        API.addReportHeader(task_id, report_type, title, company_logo, subtitle).then((data) => {
            if (data.status == 201) {
                this.getReportHeader();
            } else {
                this.setState((state) => ({
                    ...state,
                    reportTitle: this.state.task.task_name,
                    reportSubTitle: this.state.task.project_name,
                    company_logo: this.state.avatar_url ? `${this.state.avatar_url}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`,
                    company_name: `${this.state.user.first_name} ${this.state.user.last_name}`,
                }), () => {
                    console.log("this is company logo", this.state.company_logo)
                })
            }
        })
    }

    convertRegion = (line, imgData, type) => {
        let co_ordinates = { ymin: parseInt(parseFloat(line[0]) * imgData.height), xmin: parseInt(parseFloat(line[1]) * imgData.width), ymax: parseInt(parseFloat(line[2]) * imgData.height), xmax: parseInt(parseFloat(line[3]) * imgData.width) }

        //Outer region co-ordinate calculation
        let ymin = co_ordinates.ymin - 80
        let xmin = co_ordinates.xmin - 80
        let ymax = co_ordinates.ymax + 80
        let xmax = co_ordinates.xmax + 80

        // check if coordinates is getting out of bound of image
        if (xmin < 0) {
            xmin = 0
            xmax = xmax - xmin
        }
        if (xmax > imgData.width) {
            let diff = imgData.width - xmax
            xmin = xmin - diff
            xmax = imgData.width
        }
        if (ymin < 0) {
            ymin = 0
            ymax = ymax - ymin
        }
        if (ymax > imgData.height) {
            let diff = imgData.height - ymax
            ymin = ymin - diff
            ymax = imgData.height
        }
        let h = ymax - ymin
        let w = xmax - xmin

        // if annotation is horizontal, , update the y co-ordinates such that height=width
        if (w > h) {
            let diff = w - h

            if (ymin == 0 && ymax != imgData.height) {
                ymax = parseInt(ymax + (diff))
            }
            else if (ymin != 0 && ymax == imgData.height) {
                ymin = parseInt(ymin - (diff))
            }
            else {
                ymax = parseInt(ymax + (diff / 2))
                ymin = parseInt(ymin - (diff / 2))
            }
            h = ymax - ymin

        } else {
            // if annotation is vertical , update the x co-ordinates such that height=width
            let diff = h - w

            if (xmin == 0 && xmax != imgData.width) {
                xmax = parseInt(xmax + (diff))
            }
            else if (xmin != 0 && xmax == imgData.width) {
                xmin = parseInt(xmin - (diff))
            }
            else {
                xmax = parseInt(xmax + (diff / 2))
                xmin = parseInt(xmin - (diff / 2))
            }
            w = xmax - xmin
        }
        let outerRegion = { y: ymin, x: xmin, h, w }
        let annotationRegion = { y: co_ordinates.ymin, x: co_ordinates.xmin, h: co_ordinates.ymax - co_ordinates.ymin, w: co_ordinates.xmax - co_ordinates.xmin }
        let dimensions = {}
        if (type === "box") {
            dimensions = {
                y: parseFloat(line[0]),
                x: parseFloat(line[1]),
                h: (parseFloat(line[2]) - parseFloat(line[0])),
                w: (parseFloat(line[3]) - parseFloat(line[1])),
            }
        } else if (type === "line") {
            dimensions = {
                x1: line[0],
                y1: line[1],
                x2: line[2],
                y2: line[3]
            }
        } else if (type === "point") {
            dimensions = {
                x: line[1],
                y: line[0]
            }
        }
        return {
            outerRegion,
            annotationRegion,
            innerRegion: dimensions,
        }
    }

    getSummaryDetails = async (severityLevel) => {
        this.setState({ isReportLoading: true })
        let { task, user } = this.state
        let severity1Data = []
        let severity2Data = []
        let severity3Data = []
        let severity4Data = []
        let severity5Data = []

        let noneSeverityData = []
        API.getDetections(task.task_id, task.type).then(
            (data) => {
                console.log(data)
                if (data.length == 0) {
                    this.setState((state) => ({
                        ...state,
                        loading: false,
                    }));
                } else {
                    let report = data.ret

                    let imageData = data.image_data
                    let showData = []
                    let totalObjectsDetected = 0, sev1total = 0, sev3total = 0, sev2total = 0, sev4total = 0, sev5total = 0
                    if (report.length > 0) {
                        for (let j = 0; j < report.length; j++) {

                            let imgData = imageData.find((data) => { return data.file_name == report[j].image })
                            report[j].imgData = imgData
                            report[j].tags = task.collection_tags

                            let regions = [], count = 1, cropRegions = []
                            let ele = data.ret[j]
                            let imageSrc = report[j].imageSrc || `${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${report[j].image}?${this.state.permissions?.st}`
                            let sev1 = { image: report[j].image, imgData: report[j].imgData, tags: report[j].tags, regions: [], cropRegions: [], imageSrc }
                            let sev2 = { image: report[j].image, imgData: report[j].imgData, tags: report[j].tags, regions: [], cropRegions: [], imageSrc }
                            let sev3 = { image: report[j].image, imgData: report[j].imgData, tags: report[j].tags, regions: [], cropRegions: [], imageSrc }
                            let sev4 = { image: report[j].image, imgData: report[j].imgData, tags: report[j].tags, regions: [], cropRegions: [], imageSrc }
                            let sev5 = { image: report[j].image, imgData: report[j].imgData, tags: report[j].tags, regions: [], cropRegions: [], imageSrc }

                            let nele = { image: report[j].image, imgData: report[j].imgData, tags: report[j].tags, regions: [], cropRegions: [], imageSrc }
                            let sev1count = 0, sev2count = 0, sev3count = 0, sev4count = 0, sev5count = 0, noneCount = 0;
                            let objectsDetected = 0;
                            for (let i = 0; i < this.state.task.type.length; i++) {
                                let obj = this.state.task.type[i]
                                let res = report[j].result.detections[obj] ? report[j].result.detections[obj].data : []
                                let tmp = {}
                                // totalObjectsDetected = totalObjectsDetected + (report[j].result.detections[obj] ? Number(report[j].result.detections[obj].count) : 0)
                                // objectsDetected += (report[j].result.detections[obj] ? Number(report[j].result.detections[obj].count) : 0)
                                if (res.length > 0) {
                                    for (let m = 0; m < res.length; m++) {
                                        let dt = res[m]
                                        if (dt.type === "box" || dt.type === "Box" || dt.type === "Polygon") dt.type = "box"
                                        totalObjectsDetected += 1
                                        objectsDetected += 1
                                        let reg = this.props.location.state.task.linked_map ? { innerRegion: dt } : { ...this.convertRegion(dt.poly, report[j].imgData, dt.type), regionId: dt.id, regionClass: dt.className, }
                                        tmp = this.props.location.state.task.linked_map ? { ...reg.innerRegion } : reg.innerRegion
                                        const severity = dt.severity || dt.data?.severity
                                        tmp.data = { severity: severity, note: dt.note || dt.data?.note, datatype: obj, percentage: dt.percentage, id: m + 1, }
                                        tmp.id = uuid()
                                        tmp.type = dt.type
                                        tmp.color = "#ff0000"
                                        tmp.index = count
                                        tmp.className = obj
                                        tmp.name = dt?.name
                                        tmp.calculated = true
                                        if (tmp.type == "box") tmp.boxTemperature = dt.boxTemperature
                                        if (tmp.type === "line") tmp.lineTemperature = dt.lineTemperature
                                        if (tmp.type === "point") tmp.pointTemperature = dt.pointTemperature
                                        regions.push(tmp);
                                        cropRegions.push(reg)
                                        if (severity == '5') {
                                            sev5.regions.push(tmp)
                                            sev5count++;
                                            sev5.cropRegions.push(reg)
                                        } else if (severity == '4') {
                                            sev4.regions.push(tmp)
                                            sev4count++;
                                            sev4.cropRegions.push(reg)
                                        } else if (severity == '3') {
                                            sev3.regions.push(tmp)
                                            sev3count++;
                                            sev3.cropRegions.push(reg)
                                        } else if (severity == '2') {
                                            sev2.regions.push(tmp)
                                            sev2count++
                                            sev2.cropRegions.push(reg)
                                        } else if (severity == '1') {
                                            sev1.regions.push(tmp)
                                            sev1count++
                                            sev1.cropRegions.push(reg)
                                        } else {
                                            nele.regions.push(tmp)
                                            noneCount++
                                            nele.cropRegions.push(reg)
                                        }
                                        // }
                                        count++
                                    }
                                }
                                report[j].regions = regions
                                report[j].cropRegions = cropRegions
                            }
                            if (sev1count > 0) {
                                report[j].tags = report[j].tags && report[j].tags.length > 0 ? report[j].tags + ' ,Normal' : 'Normal'
                                sev1.tags = sev1.tags && sev1.tags.length > 0 ? sev1.tags + ' ,Normal' : 'Normal'

                                sev1.count = sev1count
                                sev1total += sev1count
                                severity1Data.push(sev1)
                            }

                            if (sev2count > 0) {
                                report[j].tags = report[j].tags && report[j].tags.length > 0 ? report[j].tags + ' ,Medium' : 'Medium'
                                sev2.tags = sev2.tags && sev2.tags.length > 0 ? sev2.tags + ' ,Medium' : 'Medium'

                                sev2.count = sev2count
                                sev2total += sev2count
                                severity2Data.push(sev2)
                            }
                            if (sev3count > 0) {
                                report[j].tags = report[j].tags && report[j].tags.length > 0 ? report[j].tags + ' ,High' : 'High'
                                sev3.tags = sev3.tags && sev3.tags.length > 0 ? sev3.tags + ' ,High' : 'High'

                                sev3.count = sev3count
                                sev3total += sev3count
                                severity3Data.push(sev3);
                            }
                            if (sev4count > 0) {
                                report[j].tags = report[j].tags && report[j].tags.length > 0 ? report[j].tags + ' ,Higher' : 'Higher'
                                sev4.tags = sev4.tags && sev4.tags.length > 0 ? sev4.tags + ' ,Higher' : 'Higher'

                                sev4.count = sev4count
                                sev4total += sev4count
                                severity4Data.push(sev4);
                            }
                            if (sev5count > 0) {
                                report[j].tags = report[j].tags && report[j].tags.length > 0 ? report[j].tags + ' ,Very High' : 'Very High'
                                sev5.tags = sev5.tags && sev5.tags.length > 0 ? sev5.tags + ' ,Very High' : 'Very High'

                                sev5.count = sev5count
                                sev5total += sev5count
                                severity5Data.push(sev5);
                            }
                            if (noneCount > 0) {
                                nele.count = noneCount
                                noneSeverityData.push(nele)
                            }
                            report[j].objectsDetected = objectsDetected;
                            if (regions.length > 0)
                                showData.push(report[j])

                        }
                    }
                    let sev3count = 0
                    const pieData = {
                        labels: [`1`, `2`, `3`, `4`, `5`, `None`],
                        datasets: [
                            {
                                label: 'Detection analysis',
                                data: [sev1total, sev2total, sev3total, sev4total, sev5total, totalObjectsDetected - (sev1total + sev2total + sev3total + sev4total + sev5total)],
                                backgroundColor: graphColours,
                                borderColor: graphColours,
                                borderWidth: 2,
                            }
                        ]
                    }
                    this.setState(
                        (state) => ({
                            ...state,
                            report,
                            showData,
                            allData: showData,
                            severity3Data,
                            severity1Data,
                            severity2Data,
                            severity4Data,
                            severity5Data,
                            noneSeverityData,
                            windMillTask: data.isWindMillData,
                            pieData,
                            sev3total,
                            sev1total,
                            sev2total,
                            sev4total,
                            sev5total,
                            totalObjectsDetected,
                            report1: data.image_data.sort((a, b) => a.file_name > b.file_name ? 1 : -1),
                            imagesProcessed: showData.length
                        }), () => {
                            this.setState({ loading: false, isReportLoading: false, classes: showData[0]?.result?.objects }, () => {
                                if (severityLevel) this.setState({
                                    showAnnotation: false,
                                    report_generated: null,
                                    generation_status: true,
                                })
                                if (severityLevel === "1") this.severityLevelList(AppConstants.SEVIRITY._1, this.state.severity1Data, this.state.severity1Data.length, true)
                                else if (severityLevel === "2") this.severityLevelList(AppConstants.SEVIRITY._2, this.state.severity2Data, this.state.severity2Data.length, true)
                                else if (severityLevel === "3") this.severityLevelList(AppConstants.SEVIRITY._3, this.state.severity3Data, this.state.severity3Data.length, true)
                                else if (severityLevel === "4") this.severityLevelList(AppConstants.SEVIRITY._4, this.state.severity4Data, this.state.severity4Data.length, true)
                                else if (severityLevel === "5") this.severityLevelList(AppConstants.SEVIRITY._5, this.state.severity5Data, this.state.severity5Data.length, true)
                                else if (severityLevel === "None") this.severityLevelList("None", this.state.noneSeverityData, this.state.noneSeverityData.length, true)
                                else if (severityLevel === "All") this.severityLevelList("All", this.state.allData, this.state.allData.length, true)

                            })
                            this.loadTiffImageSrc1()
                            this.createPageDataArray()
                            this.calculateImageData()

                        });
                }
            },
            (e) => {

                this.setState((state) => ({
                    ...state,
                    report: [],
                    report1: [],
                    loading: false,
                }));
            }
        );
    };

    calculateImageData = () => {
        let data = this.state.showData
        var total_storage = 0
        var total_resolution = 0
        for (let i = 0; i < data.length; i++) {
            total_storage = total_storage + Number(data[i].imgData.file_size)
            total_resolution = total_resolution + Number(data[i].imgData.resolution)
        }
        this.setState((state) => ({
            ...state,
            total_storage: total_storage.toFixed(2),
            total_resolution: total_resolution.toFixed(2)
        }))
    }

    handleRemark = (e) => {
        this.setState({
            addedRemark: e.target.value
        })
    }
    addRegionToSeverityArr = (foundCropRegion, foundRegion, type, updatedData, regionId, innerRegionId) => {

        if (foundRegion && foundCropRegion) {
            foundCropRegion = foundCropRegion.map(cr => {
                if (cr.regionId === regionId) {
                    return { ...cr, innerRegion: { ...cr.innerRegion, data: { ...cr.innerRegion.data, [type]: updatedData } } }
                } else return cr;
            })
            foundRegion = foundRegion.map(reg => {
                if (reg?.id === innerRegionId) {
                    return { ...reg, data: { ...reg?.data, [type]: updatedData } }
                } else return reg
            })
            if (updatedData === "1") {
                this.setState({
                    severity1Data: this.state.severity1Data.map(sd => {
                        if (sd.cropRegions.find(cr => cr.regionId === regionId)) {
                            return { ...sd, cropRegions: [...sd.cropRegions, foundCropRegion], regions: [...sd.regions, foundRegion] }
                        } else return sd;
                    })
                })
            } else if (updatedData === "2") {
                this.setState({
                    severity2Data: this.state.severity2Data.map(sd => {
                        if (sd.cropRegions.find(cr => cr.regionId === regionId)) {
                            return { ...sd, cropRegions: [...sd.cropRegions, foundCropRegion], regions: [...sd.regions, foundRegion] }
                        } else return sd;
                    })
                })
            } else if (updatedData === "3") {
                this.setState({
                    severity3Data: this.state.severity3Data.map(sd => {
                        if (sd.cropRegions.find(cr => cr.regionId === regionId)) {
                            return { ...sd, cropRegions: [...sd.cropRegions, foundCropRegion], regions: [...sd.regions, foundRegion] }
                        } else return sd;
                    })
                })
            } else if (updatedData === "4") {
                this.setState({
                    severity4Data: this.state.severity4Data.map(sd => {
                        if (sd.cropRegions.find(cr => cr.regionId === regionId)) {
                            return { ...sd, cropRegions: [...sd.cropRegions, foundCropRegion], regions: [...sd.regions, foundRegion] }
                        } else return sd;
                    })
                })
            } else if (updatedData === "5") {
                this.setState({
                    severity5Data: this.state.severity5Data.map(sd => {
                        if (sd.cropRegions.find(cr => cr.regionId === regionId)) {
                            return { ...sd, cropRegions: [...sd.cropRegions, foundCropRegion], regions: [...sd.regions, foundRegion] }
                        } else return sd;
                    })
                })
            } else if (updatedData === "None") {
                this.setState({
                    noneSeverityData: this.state.noneSeverityData.map(sd => {
                        if (sd.cropRegions.find(cr => cr.regionId === regionId)) {
                            return { ...sd, cropRegions: [...sd.cropRegions, foundCropRegion], regions: [...sd.regions, foundRegion] }
                        } else return sd;
                    })
                })
            }
        }
    }

    updateRemark = async (updatedData, region, type) => {
        const newShowData = await Promise.all(this.state.showData.map(sd => {
            return new Promise(async (resolve, reject) => {
                let callAPI = false;
                let updateResult = {
                    ...sd.result, detections: {
                        ...sd.result.detections, [region.regionClass]: {
                            ...sd.result.detections[region.regionClass], data: sd.result.detections[region.regionClass]?.data.map(dt => {
                                if (dt.id === region.regionId) {
                                    callAPI = true;
                                    return { ...dt, data: { ...dt.data, [type]: updatedData }, [type]: updatedData }
                                } else return dt
                            })
                        }
                    }
                }
                callAPI && await API.saveDetectionResult(this.state.task.task_id, sd.image, updateResult).then(res => {
                    this.setState((state) => ({ ...state, tempPageNo: this.state.currentpage }), () => {
                        // this.getSummaryDetails();
                    })
                })
                let updatedRegions = sd.regions.map(reg => {
                    if (reg.id === region.innerRegion.id) {
                        return { ...reg, data: { ...reg.data, [type]: updatedData } }
                    } else return reg
                })
                let newCropRegions = sd.cropRegions.map(cr => {
                    if (cr.regionId === region.regionId) return { ...cr, innerRegion: { ...cr.innerRegion, data: { ...cr.innerRegion.data, [type]: updatedData } } }
                    else return cr;
                })
                resolve({
                    ...sd, result: updateResult, regions: updatedRegions, cropRegions: newCropRegions
                })
            })
        }))
        let updatedPageData = {
            ...this.state.currentPageData, cropRegions: this.state.currentPageData.cropRegions.map(cr => {
                if (cr.regionId === region.regionId) return { ...cr, innerRegion: { ...cr.innerRegion, data: { ...cr.innerRegion.data, [type]: updatedData }, }, }
                else return cr;
            }), regions: this.state.currentPageData?.regions?.map(reg => {
                if (reg.id === region.innerRegion.id) return { ...reg, data: { ...reg.data, [type]: updatedData } }
                else return reg;
            })
        }

        this.setState({
            showData: newShowData, currentPageData: updatedPageData,
            mainData: this.state.mainData.map((cr, i) => {
                if (i === this.state.currentpage) {
                    return updatedPageData
                } else return cr
            })
            , allData: newShowData
        }, () => {
        })
    }
    deleteAnnotation = async (region) => {
        this.setState({ deleteAnotationPopup: false, isReportLoading: true })
        let newShowData = await Promise.all(this.state.showData.map(async sd => {
            return new Promise(async (resolve, reject) => {

                let callAPI = sd.result.detections[region.regionClass]?.data.find(reg => reg.id === region.regionId) ? true : false;
                let updateResult = {
                    ...sd.result, detections: {
                        ...sd.result.detections, [region.regionClass]: {
                            ...sd.result.detections[region.regionClass], data: sd.result.detections[region.regionClass]?.data.filter(dt => dt.id !== region.regionId)
                        }
                    }
                }
                callAPI && await API.saveDetectionResult(this.state.task.task_id, sd.image, updateResult).then(res => {
                    this.setState((state) => ({ ...state, tempPageNo: this.state.currentpage, selectedRegionToDelete: undefined, isReportLoading: false }), () => {
                    })
                })
                let updatedRegions = sd.regions.filter(reg => reg.id !== region.innerRegion.id)
                let newCropRegions = sd.cropRegions.filter(cr => cr.regionId !== region.regionId)
                resolve({
                    ...sd, result: updateResult, regions: updatedRegions, cropRegions: newCropRegions
                })
            })
        }))
        let updatedPageData = {
            ...this.state.currentPageData, cropRegions: this.state.currentPageData.cropRegions.filter(cr => cr.regionId !== region.regionId), regions: this.state.currentPageData?.regions?.filter(reg => reg.id !== region.innerRegion.id)
        }

        this.setState({
            showData: newShowData, currentPageData: updatedPageData,
            mainData: this.state.mainData.map((cr, i) => {
                if (i === this.state.currentpage) {
                    return updatedPageData
                } else return cr
            })
            , allData: newShowData, tempPageNo: this.state.currentpage, selectedRegionToDelete: undefined, isReportLoading: false
        }, () => {
        })
    }


    updateClasses = async (newClassName, oldClassName, region) => {
        await this.updateRemark("None", region, 'severity')
        let newShowData = await Promise.all(this.state.showData.map(async sd => {
            return new Promise(async (resolve, reject) => {

                let oldDataArr = sd.result.detections[oldClassName]?.data.filter(det => det.id === region.regionId);
                if (oldDataArr.length > 0) {

                    const oldData = sd.result.detections[oldClassName].data?.find(det => det.id === region.regionId)
                    let updateResult = {
                        ...sd.result, detections: {
                            ...sd.result.detections, [oldClassName]: {
                                ...sd.result.detections[oldClassName], data: sd.result.detections[oldClassName]?.data.filter(det => det.id !== region.regionId), count: sd.result.detections[oldClassName]?.data.filter(det => det.id !== region.regionId).length,
                            },
                            [newClassName]: { ...sd.result.detections[newClassName], data: [...sd.result.detections[newClassName].data, { ...oldData, data: { ...oldData.data, datatype: newClassName, }, className: newClassName }], count: sd.result.detections[newClassName].count + 1 }
                        }
                    }
                    await API.saveDetectionResult(this.state.task.task_id, sd.image, updateResult).then(res => {
                        this.setState((state) => ({ ...state, tempPageNo: this.state.currentpage }), () => {
                        })

                    })
                    let updatedRegions = sd.regions.map(reg => {
                        if (reg.id === region.innerRegion.id) {
                            return { ...reg, className: newClassName, data: { ...reg.data, datatype: newClassName, } }
                        } else return reg
                    })
                    let newCropRegions = sd.cropRegions.map(cr => {
                        if (cr.regionId === region.regionId) return { ...cr, innerRegion: { ...cr.innerRegion, className: newClassName, data: { ...cr.innerRegion.data, datatype: newClassName, } }, regionClass: newClassName }
                        else return cr;
                    })
                    resolve({
                        ...sd, result: updateResult, regions: updatedRegions, cropRegions: newCropRegions
                    })
                }
                else resolve(sd)
            })
        }))
        let updatedPageData = {
            ...this.state.currentPageData, cropRegions: this.state.currentPageData.cropRegions.map(cr => {
                if (cr.regionId === region.regionId) return { ...cr, innerRegion: { ...cr.innerRegion, className: newClassName, data: { ...cr.innerRegion.data, datatype: newClassName, }, }, regionClass: newClassName }
                else return cr;
            }), regions: this.state.currentPageData?.regions?.map(reg => {
                if (reg.id === region.innerRegion.id) return { ...reg, className: newClassName, data: { ...reg.data, datatype: newClassName, } }
                else return reg;
            })
        }

        this.setState({
            showData: newShowData, currentPageData: updatedPageData,
            mainData: this.state.mainData.map((cr, i) => {
                if (i === this.state.currentpage) {
                    return updatedPageData
                } else return cr
            })
            , allData: newShowData
        }, () => {
        })
    }
    shapeComparator(a, b) {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();

        if (aName.includes("rect") && !bName.includes("rect")) {
            return -1; // 'a' comes before 'b'
        } else if (!aName.includes("rect") && bName.includes("rect")) {
            return 1; // 'b' comes before 'a'
        } else if (aName.includes("line") && !bName.includes("line")) {
            return -1;
        } else if (!aName.includes("line") && bName.includes("line")) {
            return 1;
        } else if (aName.includes("point") && !bName.includes("point")) {
            return -1;
        } else if (!aName.includes("point") && bName.includes("point")) {
            return 1;
        }

        return 0; // no sorting needed
    }
    componentWillMount() {
        ChartJS.register(BarElement, CategoryScale,
            ArcElement,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend,
            Filler
        );
        document.title = "PicStork Report";
        getFeaturesPermissions([PICSTORK_FEATURES.REPORTS])
            .then((permissions) => {
                if (this.props.location.state) {
                    let { user, type, task } = this.props.location.state;
                    this.setState((state) => ({
                        ...state,
                        permissions,
                        avatar_url: `${BLOB_URL}/${permissions?.container}/profileImage/${(permissions?.container?.split("user-"))[1]}.jpeg`,
                        user,
                        type,
                        task,
                    }),
                        () => {
                            this.getReportHeader()
                            this.getSummaryDetails()
                            API.getUserSubbedServices().then((response) => {
                                let services = response[0];
                                if (services.length == 1 && services[0].title == "AeroStream") {
                                    this.setState((state) => ({
                                        ...state,
                                        showBackIcon: false,
                                    }));
                                } else {
                                    this.setState((state) => ({
                                        ...state,
                                        showBackIcon: true,
                                    }));
                                }
                            });

                        })
                } else {
                    API.getUserType().then(
                        ([blah, task, user]) => {
                            this.setState(
                                (state) => ({
                                    ...state,
                                    user,
                                    task,
                                }),
                                () => {
                                }
                            );
                        },
                        (e) => {
                            window.location.replace("/login");
                        }
                    );
                }
            })
            .catch(e => {
                window.location.replace("/")
            })

    }

    downloadPdf = async () => {
        this.setState({ downloadProgress: true })
        API.downloadPdfFile(this.state.task.task_id, 'Thermal', this.state.task.image_collection_id, this.state.task.tasktype, this.state.filetype).then(res => {
            if (res == '202') {
                this.setState({
                    downloadProgress: false,
                    downloadWarning: false,
                    firstTimeDownload: true,
                })
            } else {
                this.setState({
                    downloadProgress: false,
                    downloadWarning: false,
                })
            }
        })
    }

    generatePdf = async () => {
        let pdfData = {
            planName: this.state.reportSubTitle,
            imageSetName: this.state.task.collection_name,
            totalImages: this.state.report.length,
            processedImages: this.state.showData.length,
            objects: this.state.totalObjectsDetected,
            pixels: this.state.total_resolution,
            total_size: this.state.total_storage,
            avatar_url: this.state.company_logo,
            username: this.state.company_name,
            task_name: this.state.reportTitle,
            task_id: this.state.task.task_id,
            collection_id: this.state.task.image_collection_id,
            selectedSeverity: this.state.selectedSeverity,
            windMillTask: this.state.windMillTask,
            totalTableData: this.state.totalTableData,
            filetype: this.state.filetype
        }
        let pieData = this.state.pieData.datasets[0].data
        API.generateThermalPdf(this.state.mainData.filter(reg => reg.image), this.state.task.task_name, pdfData, pieData).then(res => {
            if (res.status == 200) {
                this.setState((state) => ({
                    showProcessPopup: false,
                    downloadWarning: false,
                    generation_status: false,
                    processMessage: null,
                }))
            }

        })
    }

    changeCompanyLogo = (event) => {
        let targetFilesArr = [];
        targetFilesArr = Array.from(event.target.files);
        let companyLogo = targetFilesArr[0]
        this.setState((state) => ({
            ...state,
            company_logo1: companyLogo,
        }), () => {
            this.uploadCompanLogo()
        })
    }
    uploadCompanLogo = () => {
        const data = new FormData()
        API.uploadCompanyLogo(this.state.reportheader.id, data).then((uploadlogo) => {
            if (uploadlogo.status == 200) {
                this.setState((state) => ({
                    ...state,
                    companyLogo1: undefined,
                }), () => {
                    this.getReportHeader()
                })
            } else {
                console.log('error while uploading logo')
            }
        })
    }
    handleChange = (e) => {
        let t = e.target;
        this.setState((prevState) => ({
            ...prevState,
            [t.name]: t.value,
        }))
    }
    togglePanning = () => {
    }

    closeActivityPopup = () => {
        this.getReportHeader()
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            downloadWarning: true,
            generation_status: false,
            processMessage: null,
        }))
    }

    isReportEditable = () => {
        return this.state.permissions[PICSTORK_FEATURES.REPORTS].EDIT && !(this.state.task.sharedBy && this.state.task.sharedBy.isShared)
    }

    checkedTrue(index) {
        let temp = this.state.reportData
        temp[index].visibleInReport = !temp[index].visibleInReport
        this.setState((state) => ({
            ...state,
            reportData: temp
        }))
    }

    severityLevelList = (severity, severityData, dataLength, isShowAnnotation) => {
        this.setState({ selectedSeverity: severity, showData: severityData, imagesProcessed: dataLength, showAnnotation: isShowAnnotation }, () => {
            this.loadTiffImageSrc()
            this.createPageDataArray()
        })
    }

    render() {
        const regionStyle = {
            background: "rgba(255, 0, 0, 0)",
            outline: 'red solid 2px',
            border: 'none'
        };
        let {
            user,
            type,
            task,
            totalObjectsDetected,
            sev1total,
            sev2total,
            sev3total,
            sev4total,
            sev5total,
            currentpage,
            currentPageData
        } = this.state;

        let totasev1countPerImage = [];
        let defect;
        let size;
        const handleClick = (event) => {
            document.getElementById("hiddenFileInput").click();
        };
        return (
            <>

                <div className="top-bar" style={{ position: 'fixed', width: '100%', zIndex: 5 }}>
                    <ActivityPopup
                        item={"Report"}
                        open={this.state.showProcessPopup}
                        icon={this.state.downloadWarning ? this.state.processPopupIcon : "WAIT"}
                        action={this.state.downloadWarning ? this.state.processAction : "UPDATE"}
                        title={"Report progress"}
                        msg={this.state.downloadWarning ? this.state.processMessage : ' '}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => {
                            this.setState({
                                showProcessPopup: false
                            })
                            this.downloadPdf()
                        }}
                    >{!this.state.downloadWarning ? <>
                        <div id='message' className='content-text' style={{ fontSize: "13px", fontWeight: "500", textAlign: "left", margin: "0px 30px", color: '#555C67' }}>

                            Your report is being generated, we will notify you on email once your report is available for download.
                        </div>
                        <div style={{ display: "flex", position: "absolute", bottom: "22px", right: "5%" }}>
                            <Button isBtnActive text={"Ok"} onClick={() => {
                                this.closeActivityPopup()
                            }} />
                        </div>
                    </>
                        : <></>}
                    </ActivityPopup>

                    <ActivityPopup
                        item={"WARNING"}
                        open={this.state.filterWarningPopup}
                        icon="WARNING"
                        title={"WARNING"}
                        msg={"Severity filter will be removed, are you sure you want to edit the report ?"}
                        close={() => {
                            this.setState({
                                filterWarningPopup: false,
                            })
                        }}
                        onClickOk={() => {
                            this.setState({
                                filterWarningPopup: false,
                                isEdit: !this.state.isEdit,
                                // hideSeverityFilter: true,
                                selectedSeverity: "All",
                                showData: this.state.allData,
                                imagesProcessed: this.state.allData.length,
                                showAnnotation: true
                            }, () => {
                                this.loadTiffImageSrc1()
                                this.createPageDataArray()
                            })
                        }}
                    >
                    </ActivityPopup>

                    <ActivityPopup
                        item={"WARNING"}
                        open={this.state.deleteAnotationPopup}
                        icon="WARNING"
                        title={"WARNING"}
                        msg={"Are you sure to delete this Anotation. ?"}
                        close={() => {
                            this.setState({
                                deleteAnotationPopup: false,
                                selectedRegionToDelete: undefined,
                            })
                        }}
                        onClickOk={() => {
                            this.deleteAnnotation(this.state.selectedRegionToDelete)
                        }}
                    >
                    </ActivityPopup>

                    {this.state.firstTimeDownload ?
                        <ActivityPopup
                            item={"Report"}
                            open={this.state.firstTimeDownload}
                            icon={"WAIT"}
                            action={""}
                            title={"Report progress"}
                            msg={' '}
                            close={() => this.closeActivityPopup()}
                        >
                            <div id='message' className='content-text' style={{ fontSize: "13px", fontWeight: "500", textAlign: "left", margin: "0px 30px", color: '#555C67' }}>
                                Your report is being generated, we will notify you on email once your report is available for download.
                            </div>
                            <div style={{ display: "flex", position: "absolute", bottom: "22px", right: "5%" }}>
                                <Button isBtnActive text={"Ok"} onClick={() => {
                                    this.setState({ firstTimeDownload: false })
                                }} />
                            </div>
                        </ActivityPopup> : <></>}

                    {this.state.downloadProgress ? <ActivityPopup
                        item={"Report"}
                        open={this.state.downloadProgress}
                        icon={"WAIT"}
                        action={""}
                        title={"Report progress"}
                        msg={' '}
                        close={() => this.closeActivityPopup()}
                    >
                        <div id='message' className='content-text' style={{ fontSize: "13px", fontWeight: "500", textAlign: "left", margin: "0px 30px", color: '#555C67' }}>
                            Getting your file to download, please wait.
                            <img src={Loader} style={{ height: "70px", width: "100%", marginLeft: "15px" }} />
                        </div>
                    </ActivityPopup>
                        : <></>}
                    <div className="breadCrumbs">
                        {task.project_name && <BreadCrumbs data={[
                            {
                                displayName: "projects",
                                pathname: "/picstork/dashboard",
                                state: {
                                    type,
                                    user,
                                }
                            },
                            {
                                displayName: task.project_name,

                                pathname: "/picstork/project",
                                state: {
                                    type,
                                    user,
                                    project: { "project_name": task.project_name, "id": task.project_id },
                                }
                            },
                            {
                                displayName: task.task_name
                            }
                        ]} />}
                        <ActiveRole />
                    </div>
                </div>
                {
                    this.state.loading ? <div style={{ height: "100vh", width: "100vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> :
                        <div style={{ display: 'flex', justifyContent: 'center', }}>
                            <div style={{ width: '77%', margin: '3% 2% 0% 2%' }}>

                                <div style={{ width: "250mm", margin: "auto", display: "flex", justifyContent: "space-between" }} >
                                    <div style={{ marginTop: "1.0rem" }}>
                                        <div>
                                            <div className="pagination-label">
                                                {`Page : ${this.state.currentpage} / ${this.state.mainData.length - 1}`}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ float: "right", marginTop: "1.0rem", display: "flex" }}>
                                        <div style={{ display: "flex" }}>
                                            <div className="pagination-label" >
                                                Go to page
                                            </div>

                                            <div className="add-user-form-text-wrapper">
                                                <input type="text" className="pagination-text-box" onChange={(event) => {

                                                    this.changepage(parseInt(event.target.value))
                                                    this.setState({ tempPageNo: undefined })
                                                    // this.getSummaryDetails()
                                                }} name="currentpage" value={currentpage} />
                                            </div>
                                        </div>
                                        {this.state.currentpage == 1 ?
                                            <button className="pagination-button-inactive" style={{ marginLeft: "15px" }}>
                                                <div  >
                                                    <img src={prevIcon} style={{ height: "17px", height: "17px" }} />
                                                </div>
                                            </button> :

                                            <button className="pagination-button-active" style={{ marginLeft: "15px" }} onClick={this.prevpage}>
                                                <div >
                                                    <img src={prevIcon} style={{ height: "17px", height: "17px" }} />
                                                </div>
                                            </button>}

                                        {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                                            <button className="pagination-button-inactive" >
                                                <div >
                                                    <img src={nextIcon} style={{ height: "17px", height: "17px" }} />
                                                </div>
                                            </button> :
                                            <button className="pagination-button-active" onClick={this.nextpage}>
                                                <div >
                                                    <img src={nextIcon} style={{ height: "17px", height: "17px" }} />
                                                </div>
                                            </button>
                                        }
                                    </div>
                                </div>
                                <div id="defect-report">
                                    {!this.state.isReportLoading ?
                                        <div
                                            id="pageprint"
                                            style={{
                                                margin: '6px auto',
                                                padding: "50px 30px 20px 30px",
                                                width: "256mm",
                                                height: "86vh",
                                                overflowY: "auto",
                                                boxShadow: "0px 6px 18px rgba(0, 0, 0, 18%)",
                                            }}>
                                            {currentpage === 1 ? <>
                                                {/* -------------Report header ------------ */}
                                                <div>
                                                    <input
                                                        name="company_logo"
                                                        id="hiddenFileInput"
                                                        type="file"
                                                        className=""
                                                        height="100px"
                                                        required
                                                        onChange={this.changeCompanyLogo}
                                                        accept='image/*'
                                                        // multiple
                                                        style={{ display: "none" }}
                                                    />

                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '20px', }}>
                                                        <div style={{ width: '60%' }}>
                                                            {this.state.titleEdit ? <div style={{ height: '47px' }}>
                                                                {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Title</label><br /> */}
                                                                <input maxLength={'80'} type="text" value={this.state.reportTitle} name='reportTitle'
                                                                    className="add-user-form-text2" style={{ width: '300px', fontSize: '18px' }} onChange={this.handleChange} />
                                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer', }} src={saveImage} onClick={() => {
                                                                    this.setState(
                                                                        (state) => ({
                                                                            ...state,
                                                                            titleEdit: false,
                                                                            editHeader: false,
                                                                        }), () => {
                                                                            this.updateReportHeader()
                                                                        })
                                                                }} />
                                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                                                    this.setState(
                                                                        (state) => ({
                                                                            ...state,
                                                                            titleEdit: false,
                                                                            editHeader: false,
                                                                        }))
                                                                }} />
                                                            </div> : <div style={{ display: 'flex', alignItems: 'center', height: '47px', }}>
                                                                <div style={{ fontSize: '20px', fontWight: '500', marginBottom: '10px' }}>{this.state.reportTitle}</div>
                                                                {!(this.isReportEditable()) ? <></> :
                                                                    <div style={{ height: '100%', display: "flex", marginTop: '0px', alignItems: 'center' }}>
                                                                        {
                                                                            this.state.isEdit ?
                                                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', marginBottom: '10px', height: "15px", width: "15px", cursor: 'pointer' }} src={edit} onClick={() => {
                                                                                    this.setState(
                                                                                        (state) => ({
                                                                                            ...state,
                                                                                            titleEdit: true,
                                                                                            editHeader: true
                                                                                        }))
                                                                                }} /> : <></>
                                                                        }
                                                                    </div>}
                                                            </div>
                                                            }
                                                            {this.state.subTitleEdit ? <div style={{ display: 'flex', alignItems: 'center', height: '47px', marginTop: '10px' }}>
                                                                {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Sub Title</label><br /> */}
                                                                <input maxLength={'50'} type="text" value={this.state.reportSubTitle} name='reportSubTitle'
                                                                    className="add-user-form-text2" style={{ width: '300px', fontSize: '16px' }} onChange={this.handleChange} />
                                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                                                                    this.setState(
                                                                        (state) => ({
                                                                            ...state,
                                                                            subTitleEdit: false,
                                                                            editHeader: false,
                                                                        }), () => {
                                                                            this.updateReportHeader()
                                                                        })
                                                                }} />
                                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                                                    this.setState(
                                                                        (state) => ({
                                                                            ...state,
                                                                            subTitleEdit: false,
                                                                            editHeader: false,
                                                                        }))
                                                                }} />
                                                            </div> : <div style={{ display: 'flex', alignItems: 'center', height: '47px', marginTop: '10px' }}>
                                                                <div style={{ fontSize: '18px', fontWight: '500' }}>{this.state.reportSubTitle}</div>
                                                                {!(this.isReportEditable()) ? <></> :
                                                                    this.state.isEdit ?
                                                                        < img id="reportToolImage" data-html2canvas-ignore className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", cursor: 'pointer' }} src={edit} onClick={() => {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    ...state,
                                                                                    subTitleEdit: true,
                                                                                    editHeader: true,
                                                                                }))
                                                                        }} /> : <></>
                                                                }</div>}
                                                        </div>

                                                        <div style={{}}>
                                                            <div style={{ textAlign: 'end', marginRight: '45px' }}>
                                                                {!(this.isReportEditable()) ? <></> :
                                                                    this.state.isEdit ? <img id="reportToolImage" className="reportToolImage" style={{ marginRight: '-125px', height: "15px", width: "15px", cursor: 'pointer', marginTop: '-50px' }} src={edit} onClick={handleClick} /> : <></>}

                                                                <img style={{ maxHeight: "80px", minHeight: "50px", minWidth: "70px", maxWidth: "100px", borderRadius: '10px', cursor: 'pointer' }} src={`${this.state.company_logo}?${this.state.permissions?.st}`} onError={(e) => { e.target.onerror = null; e.target.src = `/images/${this.state.user.first_name[0].toLowerCase()}.PNG` }} />
                                                            </div>

                                                            {this.state.companyNameEdit ? <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>
                                                                {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Company Name</label><br /> */}


                                                                <input maxLength={'30'} style={{ width: '200px', fontSize: '16px' }} type="text" value={this.state.company_name} name='company_name'
                                                                    className="add-user-form-text2" onChange={this.handleChange} />
                                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                                                                    this.setState(
                                                                        (state) => ({
                                                                            ...state,
                                                                            companyNameEdit: false,
                                                                            editHeader: false
                                                                        }), () => {
                                                                            this.updateReportHeader()
                                                                        })
                                                                }} />
                                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                                                    this.setState(
                                                                        (state) => ({
                                                                            ...state,
                                                                            companyNameEdit: false,
                                                                            editHeader: false,
                                                                        }))
                                                                }} />
                                                            </div> :
                                                                <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>

                                                                    <div style={{ fontSize: '20px' }}>{this.state.company_name}</div>
                                                                    {!(this.isReportEditable()) ? <></> :
                                                                        this.state.isEdit ?
                                                                            < img id="reportToolImage" data-html2canvas-ignore className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", cursor: 'pointer' }} src={edit} onClick={() => {
                                                                                this.setState(
                                                                                    (state) => ({
                                                                                        ...state,
                                                                                        companyNameEdit: true,
                                                                                        editHeader: true,
                                                                                    }))
                                                                            }} /> : <></>

                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <hr style={{ width: '100%', margin: '10px 0px 20px 0px', height: '1px', padding: '0px' }} />
                                                </div>
                                                {/* ---------------------------------------*/}
                                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: '50px' }}>
                                                    <p>Pixels Processed: <span style={{ fontWeight: 'bold', fontSize: '20px' }}>{this.state.total_resolution} MP</span></p>
                                                    <p>Total Storage: <span style={{ fontWeight: 'bold', fontSize: '20px' }}>{this.state.total_storage}
                                                        {this.state.total_storage > 1024 ? ' GB' : ' MB'}  </span></p>
                                                </div>

                                                {/* Analysis Report*/}
                                                <p style={{ marginBottom: '20' }}><div className="header" style={{ fontWeight: 600, fontSize: '18px' }}>
                                                    Analysis Summary
                                                </div>
                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: "20px" }}>
                                                        <table style={{ borderRadius: '4px', width: '100%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                                                            <col style={{ width: "50%" }} />
                                                            <col style={{ width: "50%" }} />
                                                            <tbody>
                                                                <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '42px ', fontSize: '13px' }}>
                                                                    <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>Imageset</td>
                                                                    <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>{getLimitedName(this.state.task.collection_name.replace(AppConstants.IMAGE_SET_RENAME_VARIABLE, ""), 30)}</td>
                                                                </tr>
                                                                <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '42px ', fontSize: '13px' }}>
                                                                    <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>Total images</td>
                                                                    <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>{this.state.report.length}</td>
                                                                </tr>
                                                                <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '42px ', fontSize: '13px' }}>
                                                                    <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>Processed images</td>
                                                                    <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>{this.state.imagesProcessed}</td>
                                                                </tr>
                                                                <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '42px ', fontSize: '13px' }}>
                                                                    <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>Total defects/annotations </td>
                                                                    <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>{totalObjectsDetected}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div style={{ marginTop: "50px" }} >
                                                        <div className="header" style={{ fontWeight: 600, fontSize: '18px' }}>
                                                            Severity Distribution
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                                            <div style={{ margin: "50px 0px", width: '70%', height: '100%' }}>
                                                                {this.state.pieData ? <Pie
                                                                    height={'130'}
                                                                    options={{
                                                                        maintainAspectRatio: false,
                                                                        plugins: {
                                                                            legend: {
                                                                                display: false
                                                                            }
                                                                        }
                                                                    }}
                                                                    data={this.state.pieData}
                                                                /> : <></>}
                                                            </div>
                                                            <div className="summary" style={{ width: '30%', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                                                <div className="analysisTable1 itemValue" style={{ verticalAlign: 'middle' }}>
                                                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'left', height: '25px', alignItems: 'center' }}>
                                                                        <div className="graph-legend-shape" style={{ height: '12px', width: '35px', backgroundColor: graphColours[0] }} />
                                                                        <div>{`${AppConstants.SEVIRITY._1}: ${sev1total}`}</div>
                                                                    </div>
                                                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'left', height: '25px', alignItems: 'center' }}>
                                                                        <div className="graph-legend-shape" style={{ height: '12px', width: '35px', backgroundColor: graphColours[1] }} />
                                                                        <div>{`${AppConstants.SEVIRITY._2}: ${sev2total}`}</div>
                                                                    </div>
                                                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'left', height: '25px', alignItems: 'center' }}>
                                                                        <div className="graph-legend-shape" style={{ height: '12px', width: '35px', backgroundColor: graphColours[2] }} />
                                                                        <div>{`${AppConstants.SEVIRITY._3}: ${sev3total}`}</div>
                                                                    </div>
                                                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'left', height: '25px', alignItems: 'center' }}>
                                                                        <div className="graph-legend-shape" style={{ height: '12px', width: '35px', backgroundColor: graphColours[3] }} />
                                                                        <div>{`${AppConstants.SEVIRITY._4}: ${sev4total}`}</div>
                                                                    </div>
                                                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'left', height: '25px', alignItems: 'center' }}>
                                                                        <div className="graph-legend-shape" style={{ height: '12px', width: '35px', backgroundColor: graphColours[4] }} />
                                                                        <div>{`${AppConstants.SEVIRITY._5}: ${sev5total}`}</div>
                                                                    </div>
                                                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'left', height: '25px', alignItems: 'center' }}>
                                                                        <div className="graph-legend-shape" style={{ height: '12px', width: '35px', backgroundColor: graphColours[5] }} />
                                                                        <div>{`${AppConstants.SEVIRITY.NONE}: ${totalObjectsDetected - (sev1total + sev2total + sev3total + sev4total + sev5total)}`}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="page-break"></div>
                                                </p>
                                            </>
                                                : this.state.windMillTask && currentPageData.showImage == false && currentPageData.tableRecordShow ? <div>
                                                    <table style={{ border: '1px solid #C3C3C3', borderRadius: "5px 5px 0px 0px" }} className="customers-table">
                                                        <col style={{ width: '15%' }} />
                                                        <col style={{ width: '10%' }} />
                                                        <col style={{ width: '25%' }} />
                                                        <col style={{ width: '10%' }} />
                                                        <col style={{ width: '20%' }} />
                                                        <col style={{ width: '10%' }} />

                                                        <tr style={{ height: "40px", backgroundColor: "#EAEAEA", fontSize: '13px' }}>
                                                            <th style={{ paddingLeft: "20px" }}>Blade</th>
                                                            <th style={{ paddingLeft: "20px" }}>Side</th>
                                                            <th style={{ paddingLeft: "20px" }}>Issues</th>
                                                            <th style={{ textAlign: 'center' }}>Severity</th>
                                                            <th style={{ textAlign: 'center' }}>Size</th>
                                                            <th style={{ textAlign: 'center' }}>MR</th>
                                                        </tr>

                                                        {currentPageData.tableRecords.map((record) =>
                                                            <tr className="customers-table-data-row">
                                                                <td>{record.blade.length > 10 ? record.blade.substring(0, 10) : record.blade}</td>
                                                                <td>{record.side.length > 10 ? record.side.substring(0, 10) : record.side}</td>
                                                                <td>{record.issues.length > 10 ? record.issues.substring(0, 10) : record.issues}</td>
                                                                <td style={{ textAlign: 'center' }}>{record.severity}</td>
                                                                <td style={{ textAlign: 'center' }}>{record.size}</td>
                                                                <td style={{ textAlign: 'center' }}>{record.alt}</td>
                                                            </tr>
                                                        )}

                                                    </table>
                                                </div>
                                                    : <>
                                                        {currentPageData.showImage ?
                                                            <>
                                                                <div className="header" style={{ fontWeight: 600, fontSize: '18px' }}>
                                                                    {
                                                                        this.state.selectedSeverity === 'All' && this.state.currentpage > 2 ? '' : this.state.currentpage > 2 ? `Severity-${this.state.selectedSeverity} Analysis` : ''
                                                                    }
                                                                </div>
                                                                <p>
                                                                    <div>
                                                                        <table style={{ border: '1px solid #C3C3C3', borderRadius: "5px 5px 0px 0px" }} className="customers-table">
                                                                            <col style={{ width: '24%' }} />
                                                                            <col style={{ width: '20%' }} />
                                                                            <col style={{ width: '16%' }} />
                                                                            <col style={{ width: '16%' }} />
                                                                            <col style={{ width: '24%' }} />

                                                                            <tr style={{ height: "40px", backgroundColor: "#EAEAEA", fontSize: '13px' }}>
                                                                                <th style={{ paddingLeft: "20px" }}>Image Name</th>
                                                                                <th style={{ textAlign: 'center' }}>Location(lat,lng)</th>
                                                                                <th style={{ textAlign: 'center' }}>Altitude(m)</th>
                                                                                <th style={{ textAlign: 'center' }}>Captured on</th>
                                                                                <th style={{ textAlign: 'center' }}>Tags</th>
                                                                            </tr>
                                                                            <tr className="customers-table-data-row">
                                                                                <td style={{}}>{currentPageData.image.length > 18 ? getLimitedName(currentPageData.image, 18) : currentPageData.image}</td>
                                                                                <td style={{ textAlign: 'center' }}>{currentPageData.imgData.latitude ? Number(currentPageData.imgData.latitude).toFixed(4) : '-'} {currentPageData.imgData.longitude ? `, ${Number(currentPageData.imgData.longitude).toFixed(4)}` : ''}</td>
                                                                                <td style={{ textAlign: 'center' }}>{currentPageData.imgData.altitude && currentPageData.imgData.altitude.toString().toLowerCase() != 'nan' ? Number(currentPageData.imgData.altitude).toFixed(4) : '-'}</td>
                                                                                <td style={{ textAlign: 'center' }}>{dateFormat(currentPageData.imgData.created_on, 'dd mmm yyyy')}</td>
                                                                                <td style={{ textAlign: 'center' }}>{currentPageData.tags ? currentPageData.tags : '-'}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                    <div style={{ height: '604px', width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: "20px", }}>
                                                                        <div style={{ height: '604px', width: this.state.windMillTask ? '80%' : '100%' }}>
                                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                                                {this.state.showAnnotation ? <div style={{ height: "604px", width: "100%", pointerEvents: "none" }}>
                                                                                    {this.props.location.state.task?.linked_map ?
                                                                                        <MapDetections
                                                                                            collectionId={this.state.task?.image_collection_id}
                                                                                            regions={currentPageData.regions}
                                                                                            imageName={currentPageData.image}
                                                                                            permissions={this.state.permissions}
                                                                                            onLoaded={(e) => {
                                                                                                this.setState({ currentPageData: { ...currentPageData, loading: false } })
                                                                                            }}
                                                                                        />
                                                                                        :
                                                                                        <>{currentPageData.loading ? <img src={Loader} style={{ zIndex: 5, width: "10vw", marginLeft: "8%", marginTop: "10%" }} /> : <></>}
                                                                                            <Annotation
                                                                                                defectReport={true}
                                                                                                disableMaxHeight={true}
                                                                                                panning={false}
                                                                                                fillRegions={false}
                                                                                                allowZoom={false}
                                                                                                allowPanning={false}
                                                                                                togglePanning={this.togglePanning}
                                                                                                searchable={false}
                                                                                                creatable={false}
                                                                                                disabledUpdate={true}
                                                                                                showTags={this.state.showTags}
                                                                                                regionAdded={this.onRegionAdded}
                                                                                                onClassAdded={(id, object) => console.log("newclass", id, object)}
                                                                                                onClassUpdate={() => { console.log("Update"); }}
                                                                                                classList={this.state.task.type ? this.state.task.type : []}
                                                                                                regions={
                                                                                                    currentPageData.regions
                                                                                                }
                                                                                                imageSrc={currentPageData.imageSrc}
                                                                                                onImageOrVideoLoaded={(e) => {
                                                                                                    this.setState({ currentPageData: { ...currentPageData, loading: false } })
                                                                                                }}
                                                                                                isThermalImage={true}
                                                                                                isDataForReport={true}
                                                                                                currTempCalculated={true}
                                                                                            />
                                                                                        </>
                                                                                    }
                                                                                </div> : <></>}
                                                                            </div>

                                                                        </div>
                                                                        {this.state.windMillTask ?
                                                                            <> {currentPageData.imgData.windmillData.annotations ?
                                                                                <div style={{ height: '604px', width: '19%', pointerEvents: "none" }}>
                                                                                    <Annotation
                                                                                        defectReport={true}
                                                                                        disableMaxHeight={true}
                                                                                        panning={false}
                                                                                        fillRegions={false}
                                                                                        allowZoom={false}
                                                                                        allowPanning={false}
                                                                                        togglePanning={this.togglePanning}
                                                                                        searchable={false}
                                                                                        creatable={false}
                                                                                        disabledUpdate={true}
                                                                                        showTags={this.state.showTags}
                                                                                        regionAdded={this.onRegionAdded}
                                                                                        onClassAdded={(id, object) => console.log("newclass", id, object)}
                                                                                        onClassUpdate={() => { console.log("Update"); }}
                                                                                        classList={this.state.task.type ? this.state.task.type : []}
                                                                                        regions={
                                                                                            currentPageData.imgData.windmillData.annotations.map(a => {
                                                                                                return ({
                                                                                                    y: Number(a[0]),
                                                                                                    x: Number(a[1]),
                                                                                                    w: Number(a[3]) - Number(a[1]),
                                                                                                    h: Number(a[2]) - Number(a[0]),
                                                                                                    className: 'abc',
                                                                                                    type: 'box',
                                                                                                    index: 1,
                                                                                                    color: "#ff0000",
                                                                                                })
                                                                                            })
                                                                                        }
                                                                                        imageSrc={currentPageData.imgData.windmillData.side == 'PS' ? PS : currentPageData.imgData.windmillData.side == 'LE' ? LE : currentPageData.imgData.windmillData.side == 'TE' ? TE : SS}
                                                                                        onImageOrVideoLoaded={(e) => {
                                                                                            this.setState({ currentPageData: { ...currentPageData, loading: false } })
                                                                                        }}
                                                                                        currTempCalculated={true}
                                                                                    />
                                                                                </div> : <div style={{ height: '604px', width: '19%', }}>
                                                                                    <img style={{ height: '604px' }} src={currentPageData.imgData.windmillData.side == 'PS' ? PS : currentPageData.imgData.windmillData.side == 'LE' ? LE : currentPageData.imgData.windmillData.side == 'TE' ? TE : SS} />
                                                                                </div>
                                                                            }
                                                                            </> : <></>}
                                                                    </div>



                                                                </p>
                                                            </> :
                                                            <> 
                                                            </>
                                                        }
                                                    </>}
                                            {/*annotation table */}
                                            {currentPageData?.cropRegions?.length > 0 && <div>
                                                {currentPageData?.cropRegions?.length > 0 && !currentPageData.showImage &&
                                                    <table style={{ border: '1px solid #C3C3C3', borderRadius: "5px 5px 0px 0px" }} className="customers-table">
                                                        <col style={{ width: '24%' }} />
                                                        <col style={{ width: '20%' }} />
                                                        <col style={{ width: '16%' }} />
                                                        <col style={{ width: '16%' }} />
                                                        <col style={{ width: '24%' }} />

                                                        <tr style={{ height: "40px", backgroundColor: "#EAEAEA", fontSize: '13px' }}>
                                                            <th style={{ paddingLeft: "20px" }}>Image Name</th>
                                                            <th style={{ textAlign: 'center' }}>Location(lat,lng)</th>
                                                            <th style={{ textAlign: 'center' }}>Altitude(m)</th>
                                                            <th style={{ textAlign: 'center' }}>Captured on</th>
                                                            <th style={{ textAlign: 'center' }}>Tags</th>
                                                        </tr>
                                                        <tr className="customers-table-data-row">
                                                            <td style={{}}>{currentPageData.image.length > 18 ? getLimitedName(currentPageData.image, 18) : currentPageData.image}</td>
                                                            <td style={{ textAlign: 'center' }}>{currentPageData.imgData.latitude ? Number(currentPageData.imgData.latitude).toFixed(4) : '-'} {currentPageData.imgData.longitude ? `, ${Number(currentPageData.imgData.longitude).toFixed(4)}` : ''}</td>
                                                            <td style={{ textAlign: 'center' }}>{currentPageData.imgData.altitude && currentPageData.imgData.altitude.toString().toLowerCase() != 'nan' ? Number(currentPageData.imgData.altitude).toFixed(4) : '-'}</td>
                                                            <td style={{ textAlign: 'center' }}>{dateFormat(currentPageData.imgData.created_on, 'dd mmm yyyy')}</td>
                                                            <td style={{ textAlign: 'center' }}>{currentPageData.tags ? currentPageData.tags : '-'}</td>
                                                        </tr>
                                                    </table>
                                                }
                                                {/* box table */}
                                                {currentPageData?.cropRegions?.filter(reg => reg?.innerRegion?.type === "box").length > 0 &&
                                                    <>
                                                        <div style={{ marginTop: "22px" }}>Rectangles</div>
                                                        <table style={{ border: '1px solid #C3C3C3', borderRadius: "5px 5px 0px 0px", marginTop: "5px" }} className="customers-table">
                                                            <col style={{ width: '10%' }} />
                                                            <col style={{ width: '10%' }} />
                                                            <col style={{ width: '10%' }} />
                                                            <col style={{ width: '10%' }} />
                                                            <col style={{ width: '10%' }} />
                                                            <col style={{ width: '25%' }} />
                                                            <col style={{ width: '25%' }} />


                                                            <tr style={{ height: "40px", backgroundColor: "#EAEAEA", fontSize: '13px' }}>
                                                                <th style={{ paddingLeft: "20px" }}>Name</th>
                                                                <th style={{ paddingLeft: "20px", textAlign: "center", paddingRight: "20px" }}>Severity</th>
                                                                <th style={{ textAlign: 'center' }}>Min</th>
                                                                <th style={{ textAlign: 'center' }}>Avg</th>
                                                                <th style={{ textAlign: 'center' }}>Max</th>
                                                                <th style={{ paddingLeft: "20px", textAlign: "center", paddingRight: "20px" }}>Class</th>
                                                                <th style={{ textAlign: 'center' }}>Remark</th>
                                                            </tr>

                                                            {currentPageData?.cropRegions?.filter(reg => reg?.innerRegion.type === "box").map((region) => <tr className="customers-table-data-row">
                                                                <td>{region.innerRegion?.name ? region.innerRegion?.name?.length > 10 ? getLimitedName(region.innerRegion?.name, 10) : region.innerRegion?.name : "-"}</td>
                                                                <td style={{ textAlign: 'center' }}>{region.innerRegion.data?.severity?.length > 10 ? getLimitedName(region.innerRegion.data.severity, 10) : (getSevirityNumber(region.innerRegion.data?.severity) || "-")}</td>
                                                                <td style={{ textAlign: "center" }}>{((region.innerRegion.boxTemperature || region.innerRegion.lineTemperature)?.min)?.toFixed(2) ? ((region.innerRegion.boxTemperature || region.innerRegion.lineTemperature)?.min)?.toFixed(2) + "°C" : undefined || "-"}</td>
                                                                <td style={{ textAlign: "center" }}>{((region.innerRegion.boxTemperature || region.innerRegion.lineTemperature)?.Avg)?.toFixed(2) ? ((region.innerRegion.boxTemperature || region.innerRegion.lineTemperature)?.Avg)?.toFixed(2) + "°C" : undefined || "-"}</td>
                                                                <td style={{ textAlign: "center" }}>{((region.innerRegion.boxTemperature || region.innerRegion.lineTemperature)?.max)?.toFixed(2) ? ((region.innerRegion.boxTemperature || region.innerRegion.lineTemperature)?.max)?.toFixed(2) + "°C" : undefined || "-"}</td>
                                                                <td style={{ textAlign: "center" }}>{region.innerRegion.className.length > 20 ? getLimitedName(region.innerRegion.className, 20) : region.innerRegion.className}</td>
                                                                <td style={{ textAlign: region.innerRegion.data?.note?.length > 0 ? 'left' : 'center', maxWidth: "140px", wordWrap: "break-word" }}>{region.innerRegion.data?.note?.length > 30 ? getLimitedName(region.innerRegion.data?.note, 30) : region.innerRegion.data?.note || "-"}</td>
                                                            </tr>

                                                            )}
                                                        </table>
                                                    </>
                                                }
                                                {
                                                    currentPageData?.cropRegions?.filter(reg => reg?.innerRegion?.type === "line").length > 0 &&
                                                    <>
                                                        <div style={{ marginTop: "22px" }}>Lines</div>
                                                        <table style={{ border: '1px solid #C3C3C3', borderRadius: "5px 5px 0px 0px", marginTop: "5px" }} className="customers-table">
                                                            <col style={{ width: '10%' }} />
                                                            <col style={{ width: '10%' }} />
                                                            <col style={{ width: '10%' }} />
                                                            <col style={{ width: '10%' }} />
                                                            <col style={{ width: '10%' }} />
                                                            <col style={{ width: '25%' }} />
                                                            <col style={{ width: '25%' }} />


                                                            <tr style={{ height: "40px", backgroundColor: "#EAEAEA", fontSize: '13px' }}>
                                                                <th style={{ paddingLeft: "20px" }}>Name</th>
                                                                <th style={{ paddingLeft: "20px", textAlign: "center", paddingRight: "20px" }}>Severity</th>
                                                                <th style={{ textAlign: 'center' }}>Min</th>
                                                                <th style={{ textAlign: 'center' }}>Avg</th>
                                                                <th style={{ textAlign: 'center' }}>Max</th>
                                                                <th style={{ paddingLeft: "20px", textAlign: "center", paddingRight: "20px" }}>Class</th>
                                                                <th style={{ textAlign: 'center' }}>Remark</th>
                                                            </tr>

                                                            {currentPageData?.cropRegions?.filter(reg => reg?.innerRegion.type === "line").map((region) => <tr className="customers-table-data-row">
                                                                <td>{region.innerRegion?.name ? region.innerRegion?.name?.length > 10 ? getLimitedName(region.innerRegion?.name, 10) : region.innerRegion?.name : "-"}</td>
                                                                <td style={{ textAlign: 'center' }}>{region.innerRegion.data.severity?.length > 10 ? getLimitedName(region.innerRegion.data.severity, 10) : (getSevirityNumber(region.innerRegion.data.severity) || "-")}</td>
                                                                <td style={{ textAlign: "center" }}>{((region.innerRegion.boxTemperature || region.innerRegion.lineTemperature)?.min)?.toFixed(2) ? ((region.innerRegion.boxTemperature || region.innerRegion.lineTemperature)?.min)?.toFixed(2) + "°C" : undefined || "-"}</td>
                                                                <td style={{ textAlign: "center" }}>{((region.innerRegion.boxTemperature || region.innerRegion.lineTemperature)?.Avg)?.toFixed(2) ? ((region.innerRegion.boxTemperature || region.innerRegion.lineTemperature)?.Avg)?.toFixed(2) + "°C" : undefined || "-"}</td>
                                                                <td style={{ textAlign: "center" }}>{((region.innerRegion.boxTemperature || region.innerRegion.lineTemperature)?.max)?.toFixed(2) ? ((region.innerRegion.boxTemperature || region.innerRegion.lineTemperature)?.max)?.toFixed(2) + "°C" : undefined || "-"}</td>
                                                                <td style={{ textAlign: "center" }}>{region.innerRegion.className.length > 20 ? getLimitedName(region.innerRegion.className, 20) : region.innerRegion.className}</td>
                                                                <td style={{ textAlign: region.innerRegion.data.note?.length > 0 ? 'left' : 'center', maxWidth: "140px", wordWrap: "break-word" }}>{region.innerRegion.data.note?.length > 30 ? getLimitedName(region.innerRegion.data.note, 30) : region.innerRegion.data.note || "-"}</td>
                                                            </tr>
                                                            )}
                                                        </table>
                                                    </>
                                                }
                                                {currentPageData?.cropRegions?.filter(reg => reg?.innerRegion?.type === "point").length > 0 &&
                                                    <>
                                                        <div style={{ marginTop: "22px" }}>Points</div>
                                                        <table style={{ border: '1px solid #C3C3C3', borderRadius: "5px 5px 0px 0px", marginTop: "5px" }} className="customers-table">
                                                            <col style={{ width: '10%' }} />
                                                            <col style={{ width: '10%' }} />
                                                            <col style={{ width: '10%' }} />
                                                            <col style={{ width: '35%' }} />
                                                            <col style={{ width: '35%' }} />
                                                            <tr style={{ height: "40px", backgroundColor: "#EAEAEA", fontSize: '13px' }}>
                                                                <th style={{ paddingLeft: "20px" }}>Name</th>
                                                                <th style={{ paddingLeft: "20px", textAlign: "center", paddingRight: "20px" }}>Severity</th>
                                                                <th style={{ textAlign: 'center', paddingRight: "20px", paddingLeft: "20px" }}>Temperature</th>
                                                                <th style={{ paddingLeft: "", textAlign: "center", paddingRight: "20px" }}>Class</th>
                                                                <th style={{ textAlign: 'center' }}>Remark</th>
                                                            </tr>

                                                            {currentPageData?.cropRegions?.filter(reg => reg?.innerRegion.type === "point").map((region) => <tr className="customers-table-data-row">
                                                                <td>{region.innerRegion?.name ? region.innerRegion?.name?.length > 10 ? getLimitedName(region.innerRegion?.name, 10) : region.innerRegion?.name : "-"}</td>
                                                                <td style={{ textAlign: 'center' }}>{region.innerRegion.data.severity > 10 ? getLimitedName(region.innerRegion.data.severity, 10) : (getSevirityNumber(region.innerRegion?.data?.severity) || "-")}</td>
                                                                <td style={{ textAlign: "center", paddingLeft: "20px" }}>{(region.innerRegion?.pointTemperature?.temperature)?.toFixed(2) ? (region.innerRegion?.pointTemperature?.temperature)?.toFixed(2) + "°C" : undefined || "-"}</td>
                                                                <td style={{ textAlign: "center", paddingLeft: "0px" }}>{region.innerRegion.className.length > 20 ? getLimitedName(region.innerRegion.className, 20) : region.innerRegion.className}</td>
                                                                <td style={{ textAlign: region.innerRegion.data.note?.length > 0 ? 'left' : 'center', maxWidth: "140px", wordWrap: "break-word" }}>{region.innerRegion?.data?.note?.length > 30 ? getLimitedName(region.innerRegion?.data?.note, 30) : region.innerRegion?.data?.note || "-"}</td>
                                                            </tr>
                                                            )}
                                                        </table>
                                                    </>
                                                }
                                            </div>}
                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '120px' }}>
                                                <img style={{ height: "45px", borderRadius: '1px', cursor: 'pointer' }} src={`${this.state.company_logo}?${this.state.permissions?.st}`} />
                                            </div>
                                        </div> : <div style={{ height: "100vh", width: "100%", display: "flex", textAlign: "center" }}>
                                            <img src={Loader} style={{ height: "30vh", width: "100%", margin: "auto" }} />
                                        </div>
                                    }
                                </div>
                            </div >

                            <div style={{ width: '23%' }}>
                                <div style={{ height: '15vh', overflow: "auto", position: "fixed", top: "43px", fontSize: "14px", width: "23%" }}>
                                    {
                                        this.props.location.state.task.linked_map ? <></> :
                                            <>
                                                <div style={{ display: "flex", position: 'absolute', top: '15px', }}>
                                                    <ButtonWithIcon
                                                        isDisabled={!this.state.permissions?.[PICSTORK_FEATURES.REPORTS].EDIT}
                                                        text={this.state.isEdit ? "Save" : "Edit"} isBtnActive={this.state.isEdit} width="100px"
                                                        onClick={() => {
                                                            this.setState({ isEdit: this.state.isEdit ? false : false, filterWarningPopup: !this.state.isEdit ? true : false, tempPageNo: this.state.currentpage, updateReportAfterSave: this.state.isEdit ? true : false })
                                                        }
                                                        }
                                                    />
                                                    <DropDownWithButton
                                                        selectedItem="Download" isButton width="100px"
                                                        isDisabled={this.state.isEdit || !this.state.permissions?.[PICSTORK_FEATURES.REPORTS].DOWNLOAD}
                                                        list={[
                                                            {
                                                                name: "PDF", onClick: () => {
                                                                    if (this.state.permissions?.[PICSTORK_FEATURES.REPORTS].DOWNLOAD) {
                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            itemName: "Report",
                                                                            processPopupIcon: "WARNING",
                                                                            processMessage: "Your report generation request is still in progress, This will download your previously saved report. Are you sure you want to continue ? ",
                                                                            showProcessPopup: this.state.downloadWarning ? true : false,
                                                                        }), async () => {
                                                                            if (!(await checkURLExist(`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${this.state.task.image_collection_id}/reports/Thermal-${this.state.task.task_id}.pdf?${this.state.permissions.st}`))) {
                                                                                this.setState((state) => ({
                                                                                    ...state,
                                                                                    showProcessPopup: true,
                                                                                    filetype: 'pdf'
                                                                                }), () => {
                                                                                    this.generatePdf()
                                                                                    console.log("Calling generate PDF")
                                                                                })
                                                                            } else if (!this.state.downloadWarning) {
                                                                                this.setState({
                                                                                    filetype: 'pdf'
                                                                                }, () => {
                                                                                    this.downloadPdf()
                                                                                })
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            },
                                                        ]}
                                                    />

                                                    {!(this.isReportEditable()) ? <></> :
                                                        (this.state.report_generated == null && this.state.minutesDiff > 30) || (this.state.report_generated !== null && this.state.report_generated < this.state.report_modified) || (this.state.generation_status && this.state.report_generated == null) || (this.state.generation_status && this.state.report_generated != null) || (this.state.updateReportAfterSave) ?
                                                            <div style={{ position: 'fixed', top: '90px', right: '20px', color: "#3c3c3c", fontSize: '10px', fontWeight: '300' }}>
                                                                Your report data was modified,
                                                                <div clasName="reportLabel" style={{ color: "#3c3c3c" }}>click here to <span style={{ cursor: "pointer", color: "#2989cf" }} onClick={() => {
                                                                    if (this.state.showData?.length > 0) {

                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            showProcessPopup: true,
                                                                            filetype: 'pdf',
                                                                            updateReportAfterSave: false
                                                                        }), () => {
                                                                            this.generatePdf()
                                                                        }
                                                                        )
                                                                    } else {
                                                                        this.setState({
                                                                            report_generated: false,
                                                                            generation_status: false
                                                                        })
                                                                        Popup.alert("ERROR", "We are unable to generate the PDF as there is no data available for the selected filter.",)
                                                                    }
                                                                }}>Update report</span></div>
                                                            </div>
                                                            : <></>
                                                    }
                                                </div>
                                            </>}
                                </div>

                                <div style={{ height: '78vh', overflow: 'auto', position: 'fixed', top: '135px', fontSize: "14px", width: '23%' }}>
                                    <div style={{ marginTop: "20px" }}>
                                        <div style={{ margin: "5px 0px" }}>Select Severity level</div>

                                        <DropDownWithButton
                                            selectedItem={this.state.selectedSeverity} isBorder width="140px" textFontSize="13px" textFontWeight="400"
                                            isDisabled={this.state.isEdit}
                                            list={[
                                                {
                                                    name: AppConstants.SEVIRITY._1, onClick: () => {
                                                        this.getSummaryDetails("1")
                                                    }
                                                },
                                                {
                                                    name: AppConstants.SEVIRITY._2, onClick: () => {
                                                        this.getSummaryDetails("2")
                                                    }
                                                },
                                                {
                                                    name: AppConstants.SEVIRITY._3, onClick: () => {
                                                        this.getSummaryDetails("3")
                                                    }
                                                },
                                                {
                                                    name: AppConstants.SEVIRITY._4, onClick: () => {
                                                        this.getSummaryDetails("4")
                                                    }
                                                },
                                                {
                                                    name: AppConstants.SEVIRITY._5, onClick: () => {
                                                        this.getSummaryDetails("5")
                                                    }
                                                },
                                                {
                                                    name: "None", onClick: () => {
                                                        this.getSummaryDetails("None")
                                                    }
                                                },
                                                {
                                                    name: "All", onClick: () => {
                                                        this.getSummaryDetails("All")
                                                    }
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div >
                        </div >
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        SubscriptionData: state.subscription.subscriptionData,
    };
};

export default connect(mapStateToProps)(PicStorkThermalReport);