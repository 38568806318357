const images = require.context("./", true);
const AeroStream = images("./" + "icons/aeroStreamServiceActive.png");
const AeroCapture = images("./" + "icons/AeroCapture.svg" );
const AeroGCSE = images("./" + "icons/AeroGCSEnterprise.svg");

exports.services = [
    // {id: "0f722268-cf6b-4d45-ae5a-27c5d3f79a4a", title: "COVID-19", description: "Machine Learning APIs to help fight COVID-19", redirecturl: "/api_service_details", image: "/static/media/Covid.c34a836e.svg"},
    // {id: "cb3559e2-c4c5-42aa-85ce-f6a553e55581", title: "ML Tool", description: "ML Tool Description", redirecturl: "/ml_tool/tasks", image: "/static/media/MLTool.1d50d1e1.svg"},
    // {id: "5f7230bb-b6ff-40be-93ba-d5afc0759754", title: "Micro CRM", description: "Micro CRM Description", redirecturl: "/micro_crm/approved_customers", image: "/static/media/RemoteMission.b3f0e83a.svg"},
    // {id: "2d84608c-2b8b-440f-82e0-5c5b9c8261e8", title: "AeroStream", description: "On Demand Drone Flight Service", redirecturl: "/request_a_drone/dashboard", image: AeroStream},
    // {id: "0a541d9c-7778-4f6b-8513-fdbdf6c3a73e", title: "NPNT Services", description: "Manage your customers and RPA", redirecturl: "/management_services/dashboard", image: "/static/media/NPNTServices.f7c41932.svg"},
    // {id: "29f6b617-cf85-4cfd-8547-e34e11d6d1d0", title: "AI Workflows", description: "Build and deploy data analytics workflows", redirecturl: "/workflows", image: "/static/media/ArtificialIntelligence.9018e12e.svg"},
    // {id: "36006c17-8de7-4c21-9226-13dc29fcf6e3", title: "Admin Tools", description: "Manage AeroMegh settings", redirecturl: "/admin/users", image: "/static/media/AdminTools.9b2d8c3e.svg"},
    // {id: "0bdf3a2c-59fd-481b-a29c-f5683d6c6615", title: "GCS Suite Configuration", description: "Manage Your GCS Configurations", redirecturl: "/gcs_suite_configuration/view", image: "/static/media/NPNTServices.f7c41932.svg"},
    {id: "a606c8cf-0968-4bf9-87ca-2dacc3f18f3d", title: "PicStork", description: "Detect Objects and Generate Reports", redirecturl: "/aerocapture/dashboard", image: AeroCapture},
    {id: "70a37903-a4c0-4476-b1b4-ef115d0ac144", title: "AeroGCS Enterprise", description: "AeroGCS Enterprise Features", redirecturl: "/aerogcsenterprise/dashboard", image: AeroGCSE},
    // {id: "9564c0cf-4d96-41db-82c8-e33b47cbf96a", title: "AeroFarm", description: "This is for illuminous", redirecturl: "/aerofarm/dashboard", image: "/static/media/aeroStreamServiceActive.12195e18.svg"}
]