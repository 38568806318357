import { SERVICES } from "../AppConstants"

const PRIORITIES = {
    LOW: "Low",
    MEDIUM: "Medium",
    HIGH: "High"
}

const ENTITIES = {
    PROJECT: "Project",
    PLAN: "Plan",
    TASK: "Task",
    IMAGESET: "Imageset"
}

const TYPES = {
    ANNOTATION: "Annotation",
    GCSPLAN: "AeroGCS Plan",
    PTDATAUPLOAD: "Images (PICSTORK)",
    DNDATAUPLOAD: "Images (DRONENAKSHA)",
    DNMAP: "DroneNaksha Map"
}

const PLANNER_TASKS_TYPES = [
    {
        name: TYPES.ANNOTATION,
        requiredEntities: [ENTITIES.PROJECT, ENTITIES.TASK],
        route: "/picstork/trainingTask",
        service: SERVICES.PICSTORK
    },
    {
        name: TYPES.PTDATAUPLOAD,
        requiredEntities: [ENTITIES.PROJECT, ENTITIES.IMAGESET],
        route: "/picstork/images",
        service: SERVICES.PICSTORK
    },
    {
        name: TYPES.DNDATAUPLOAD,
        requiredEntities: [ENTITIES.PROJECT, ENTITIES.PLAN, ENTITIES.IMAGESET],
        route: "/dronenaksha/images",
        service: SERVICES.DRONENAKSHA
    },
    {
        name: TYPES.DNMAP,
        requiredEntities: [ENTITIES.PROJECT, ENTITIES.PLAN, ENTITIES.TASK],
        route: "/dronenaksha/view_plan",
        service: SERVICES.DRONENAKSHA
    },
]
export {
    PLANNER_TASKS_TYPES,
    TYPES,
    ENTITIES,
    PRIORITIES
}