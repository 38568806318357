import React, { useEffect, useState } from 'react';
import ButtonWithIcon from './ButtonWithIcon';
import AppConstants from '../AppConstants';
import { AnimatedModal } from './AnimatedModal';
import * as API from "../api.js";
import LoaderComponent from '../ReusableComponents/LoaderComponent';

const ImportProjectFrom = ({ currentService, callBack }) => {

  const [importProjectPopup, setImportProjectPopup] = useState(false);
  const [selectedProject, setSelectedProject] = useState(undefined);
  const [projectList, setProjectList] = useState(undefined);
  const [selectedService, setSelectedService] = useState(undefined);
  const [showLoader, setShowLoader] = useState();

  const handledSelectService = async (chosenService) => {
    let projectRes = await API.getAllProjects(" ", 10000, 0, undefined, chosenService)
    if (projectRes) {
      setSelectedService(chosenService)
      setProjectList(projectRes.projects);
    }
  }

  return (<>
    <AnimatedModal
      isOpen={importProjectPopup}
      height="345px"
      width="500px"
    >
      <div>
        <div className='services-content-title'>Import Project</div>
        <div style={{ position: "absolute", padding: "10px", width: "100%" }} >
          <div style={{ display: "flex" }} >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className='services-content-title'>Select Service : </div>
              <div style={{ marginLeft: "20px" }}>
                {currentService != AppConstants.SERVICES.AEROGCS && <ButtonWithIcon text={AppConstants.SERVICES.AEROGCS} width="145px"
                  isBtnActive={selectedService == AppConstants.SERVICES.AEROGCS}
                  onClick={async () => { handledSelectService(AppConstants.SERVICES.AEROGCS) }} />}
                {currentService != AppConstants.SERVICES.DRONENAKSHA && <ButtonWithIcon text={AppConstants.SERVICES.DRONENAKSHA} width="145px"
                  isBtnActive={selectedService == AppConstants.SERVICES.DRONENAKSHA}
                  onClick={async () => { handledSelectService(AppConstants.SERVICES.DRONENAKSHA) }} />}
                {currentService != AppConstants.SERVICES.PICSTORK && <ButtonWithIcon text={AppConstants.SERVICES.PICSTORK} width="145px"
                  isBtnActive={selectedService == AppConstants.SERVICES.PICSTORK}
                  onClick={async () => { handledSelectService(AppConstants.SERVICES.PICSTORK) }} />}
              </div>
            </div>
          </div>
          <div style={{
            display: "flex",
            margin: "5px 0 5px 0",
            alignItems: "center",
          }}>
            <div className='services-content-title'>selected Project : </div>
            <div style={{ marginLeft: "15px", fontSize: "13px", color: "#666666" }}>{selectedProject ? `${selectedProject.projectName}` : ""}</div>
          </div>
          <div style={{ height: "190px", borderBottom: "1px solid #3c3c3c47", borderTop: "1px solid #3c3c3c47", overflow: "auto", textAlign: "left" }}>
            {
              showLoader ?
                <LoaderComponent
                  height={"80%"}
                  imgWidth={"90px"}
                  imgMargin={"0 0 -20px 40px"}
                  message={"Importing project please wait..."}
                /> :
                projectList ?
                  projectList.map(sl => {
                    return <div onClick={() => {
                      setSelectedProject({ projectName: sl.project_name, projectId: sl.id })
                    }}
                      style={{
                        cursor: "pointer",
                        fontSize: "13px",
                        color: selectedProject?.projectName == sl.project_name ? "white" : "#666666",
                        height: "25px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: selectedProject?.projectName == sl.project_name && "10px",
                        backgroundColor: selectedProject?.projectName == sl.project_name && "#2989cf",
                      }}
                    >{sl.project_name}</div>
                  })
                  : <></>
            }
          </div>
        </div>
        <div style={{ position: "absolute", bottom: "6px", right: "35px", display: "flex" }}>
          <ButtonWithIcon text="cancel" onClick={() => { setImportProjectPopup(false) }} />
          <ButtonWithIcon text="Done" isBtnActive
            isDisabled={showLoader}
            onClick={async () => {
              setShowLoader(true)
              API.importProjectFromService(selectedProject.projectId, selectedService, currentService).then(res => {
                console.log("res : ", res)
                setShowLoader(false)
                setImportProjectPopup(false);
                callBack()
              });
            }}
          />
        </div>
      </div>
    </AnimatedModal >

    <ButtonWithIcon
      text="Import Project"
      onClick={() => {
        setImportProjectPopup(true);
      }}
    />
  </>);
}

export default ImportProjectFrom;