

import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./rootReducer";
// const store = createStore(rootReducer);
import thunk from "redux-thunk";
import { persistStore, persistCombineReducers, persistReducer ,createTransform} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// const persistConfig = {
//   key: "root",
//   storage: storage,
//   whitelist: ["auth"]
// };

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer,composeEnhancers(applyMiddleware(thunk)));
export const persistor = persistStore(store);

export default  store;