import React, { Component } from 'react';
import * as API from './api.js';
import HelpActive from "./sidebaricon/Help.png";
import service from "./sidebaricon/Services.png";
import Settings from './sidebaricon/Settings.png';
import Loader from './icons/loader.svg';
import { Link } from 'react-router-dom';
import CreditsInvoicesTable from './ReusableComponents/CreditsInvoicesTable';
import Pagination from './ReusableComponents/Pagination';
import dateFormat from 'dateformat';

const PAGE_SIZE = 6
export default class Invoices extends Component {
    state = {
        user: {},
        type: "",
        org: {},
        loading: true,
        pageNumber: 1,
        totalCount: 0,
    }
    getInvoices(callback: Function) {
        API.getMyInvoices(PAGE_SIZE, this.state.pageNumber).then(res => {
            let { invoices, totalCount } = res
            this.setState({
                invoices,
                totalCount
            }, () => {
                callback(null)
            })
        }).catch(e => {
            callback("Failed to get your invoices. Try again later. Error: " + e)
        })
    }

    componentDidMount() {
        this.getInvoices((e) => {
            if (e) {
                alert(e)
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    componentWillMount() {
        document.title = "My Invoices - AeroMegh";
        if (this.props) {
            let { user, type } = this.props;
            if (!user.sub) {
                user.sub = {
                    "plan": "aeromegh_plan_free"
                }
            }
            this.setState((state) => ({
                ...state,
                user,
                type,
            }), () => {
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                if (!user.sub) {
                    user.sub = {
                        "plan": "aeromegh_plan_free"
                    }
                }
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    render() {
        let { user, type, model } = this.state
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: HelpActive,
                    text: "Help",
                    path: "/feedback",
                },

                {
                    icon: Settings,
                    text: "Settings",
                    path: "/profile-settings",
                }
            ],
            user,
            type,
        }
        return (
            user.username ? <div className="wrapper">
                <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                    <div className="top-bar"><div className="top-bar-text">Invoices</div></div>
                    {this.state.loading ? <div style={{ height: "50vh", width: "65vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> : <div style={{ width: "65vw", marginTop: "2%", height: "calc(100% - 40px + 2%)", overflow: "scroll", display: "flex", flexDirection: "column" }}>
                        <div className="rpas-model-info-bottom-section" style={{ padding: "0px 70px 50px 70px", position: "relative", height: "100%" }}>
                            {/* <div className="rpas-model-info-primary-details-title-row" style={{ marginTop: "23px" }}>
                            <div className="services-content-title"> INVOICES </div>
                        </div> */}
                            <div style={{ fontSize: "15px", fontWeight: "500" }}>Services plans</div>
                            <table className="customers-table">
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '20%' }} />
                                <tr className="customers-table-header-row">
                                    <th>
                                        Invoice Number
                                    </th>
                                    <th>
                                        Product
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Date & Time
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                </tr>
                                {this.state.invoices.length === 0 ?
                                    <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                        <td colspan="5">No invoices available</td>
                                    </tr> :
                                    this.state.invoices.map((invoice, i) => <tr className="customers-table-data-row">
                                        <td>
                                            <Link to={{
                                                pathname: "/invoice/" + invoice.id,
                                                state: this.state
                                            }} style={{
                                                color: "#707683"
                                            }}>
                                                {invoice.id}
                                            </Link>
                                        </td>
                                        <td>
                                            {invoice.product ? invoice.product : "-"}
                                        </td>
                                        <td>
                                            {invoice?.payment?.currency === "inr" ? "₹" : invoice?.payment?.currency === "usd" ? "$" : "₹"}{invoice.price - (invoice.price * invoice.discount / 100)}
                                        </td>
                                        <td>
                                            {dateFormat(invoice.created_time, 'dd mmm yyyy hh:MM:ss TT')}
                                        </td>
                                        <td>
                                            {invoice.payment.payment_status == "paid" ? <div style={{ color: "green" }}>Paid</div> : <div style={{ color: "red" }}>Pending</div>}
                                        </td>
                                    </tr>)}
                            </table>
                            <div style={{ padding: "10px 0px", position: "absolute", bottom: "0", right: "65px" }}>
                                <Pagination
                                    pageSize={PAGE_SIZE}
                                    totalCount={this.state.totalCount}
                                    paginationCallback={currPageNo => {
                                        this.setState({ pageNumber: currPageNo }, () => {
                                            this.getInvoices((e) => {
                                                if (e)
                                                    alert(e)
                                            })
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        {/* CREDITS TABLE */}
                        <CreditsInvoicesTable />
                    </div>
                    }
                </div>
            </div> : <div />
        );
    }
}
