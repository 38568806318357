import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { updateProfileDetails, updateSubscriptionData } from './redux/Profile/profileActions'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import * as API from './api.js';
import SearchLight from "./Icon/GlobalSearchW.png";
import GlobalSearch from "./ReusableComponents/GlobalSearch";
import SearchDark from './Icon/search-icon.png'
import TeamsActive from './sidebaricon/Teams.png'
import {
    Box,
    Typography,
    Tooltip,
    IconButton,
    Avatar,
    Menu,
    MenuItem,
    Divider,
    ListItemIcon,
} from '@mui/material'
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { ContentPaste } from "@mui/icons-material";
import { addUserAnalytic, isServiceAeroGCS } from "./ReusableComponents/reusableFunctions";
import { ActiveRole, setActiveTeam, storeTeams } from "./Teams";
import Notifications from "./ReusableComponents/Notifications"
import DropDown from "./assets/vectorArrowDown.png"
 
export const Profile = (props) => {
    const activeProfile = useSelector((state) => state.profile)
    const activeSubscription = useSelector((state) => state.activesubscription)
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [teams, setTeams] = useState([]);
    const location = useLocation();
    const subPlans = useSelector((state) => state.subscription.subscriptionData)
    const today = new Date().toISOString()
    const currentPlan = subPlans && subPlans.plans ? subPlans.plans.find(data => data.plan_name == activeSubscription.activesubscription) : {}
    var expiryDate = currentPlan && currentPlan.expiry_date ? currentPlan.expiry_date : new Date()
    const [isSearch, setSearch] = useState(false);
    const [recentSearches, setRecentSearches] = useState([]);
    const switchTeam = async (team, noReload) => {
        await setActiveTeam(team).then(() => {
            props.teamsLoaded()
        })
        await dispatch(updateProfileDetails({
            activeTeamId: team.teamId,
            activeTeam: team.teamName,
            isLoggedInUserOwner: team.isOwner
        }))
        if (!(noReload) && (location.pathname.includes('/planner') || (location.pathname == '/') || (location.pathname.includes('dashboard') && !location.pathname.includes("aerogcsenterprise")))) window.location.reload()
        return
    }



    const toggleSearch = () => {
        setSearch(!isSearch)
    }

    const getUserSubscriptionPlan = () => {
        API.getUserSubscriptionPlan().then((sub) => {
            if (sub.plans) {
                dispatch(updateSubscriptionData({
                    subscriptionData: sub
                }))
            } else {
                dispatch(updateSubscriptionData({
                    subscriptionData: undefined
                }))
            }
        }).catch(err => {
            // dispatch(updateSubscriptionData({
            //     subscriptionData: undefined
            // }))
            console.log(err)
        })


    }

    useEffect(() => {
        getTeamsList();
    }, [activeProfile])

    const clearLocalStorage = () => {
        localStorage.removeItem("recentSearches")
        localStorage.removeItem('dateUnit')
        addUserAnalytic('/logout')
    }
    const getTeamsList = () => {
        API.getTeamsList()
            .then((data) => {
                const { teams } = data
                storeTeams(teams)
                const prevTeam = teams.find(t => t.teamId === activeProfile?.activeTeamId && t.teamName === activeProfile?.activeTeam)
                if (prevTeam) {
                    setActiveTeam(prevTeam).then(() => {
                        props.teamsLoaded()
                    })
                } else switchTeam(teams.find(team => team.isOwner), true);
                setTeams(teams.sort((a, b) => (a.teamName > b.teamName) ? 1 : ((b.teamName > a.teamName) ? -1 : 0)));
            })
    }
    useEffect(() => {
        getUserSubscriptionPlan();
        getTeamsList();
    }, []);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const getRedirectPath = () => {
        return location.pathname.includes('picstork') ? "/picstork/dashboard"
            : (
                location.pathname.includes('dronenaksha') ? "/dronenaksha/dashboard"
                    : location.pathname.includes('/planner') ? "/planner" : "/"
            )
    }
    return (
        isServiceAeroGCS(location.pathname) ?
            <>
                <div>
                    <div className="profile-container" style={{
                        zIndex: location.pathname == '/' ? 2 : 90
                    }}>
                        {activeProfile.user.username ? (
                            <div
                                className="profile-sub-container"
                            >
                                {!isSearch ? <div style={{}} className="global-search-container" onClick={toggleSearch}>
                                    <div disabled type="text" className={location.pathname == "/dronenaksha/view_plan" ? "input-transparent" : "globalSearch-input-new hoverShadow input"} style={{}}>
                                        <span className="global-search-placeholder-text" style={{ color: location.pathname == "/dronenaksha/view_plan" ? 'white' : "#25262666", }}>Search flights, plans, drones, projects...</span>
                                    </div>
                                    <img className="global-search-icon" src={location.pathname == "/dronenaksha/view_plan" ? SearchLight : SearchDark} />
                                </div> : <></>}

                                <div style={{ margin: '0px', display: "flex", alignItems: 'center' }}>
                                    <Notifications
                                        setActiveTeam={switchTeam}
                                        user={activeProfile.user}
                                        teams={teams}
                                    />
                                </div>

                                <div className="profile-seperator"></div>

                                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                    {/* <Tooltip title="Account settings"> */}
                                    <IconButton size="small" sx={{ p: 0 }}>
                                        {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
                                        <img className="sidebar-user-picture" style={{ cursor: 'default' }}
                                            src={activeProfile.user.avatar_url === null ? `/images/${activeProfile.user.first_name[0].toLowerCase()}.PNG` : `${activeProfile.user.avatar_url}?${activeProfile.user.sas_token
                                                }&t${new Date().getTime()}`}
                                        />
                                    </IconButton>
                                    {/* </Tooltip> */}
                                </Box>
                                <div className="sidebar-toggle-text-new" style={{}} >
                                    <div className="profile-username" style={{ color: location.pathname == "/dronenaksha/view_plan" ? "#ffffff" : "#3c3c3c" }}>{`${activeProfile.user.first_name.length > 12 ? activeProfile.user.first_name.substring(0, 11) : activeProfile.user.first_name}`}  {`${activeProfile.user.last_name.length > 12 ? activeProfile.user.last_name.substring(0, 11) : activeProfile.user.last_name}`}</div>

                                    {activeProfile.activeTeam ?
                                        <div className="profile-teamname" style={{ color: location.pathname == "/dronenaksha/view_plan" ? "#ffffff" : "rgb(31 56 76 / 50%)" }}> {`${activeProfile.activeTeam}`} </div> : <></>}

                                    {/* {activeSubscription.activesubscription ? <div style={{ fontSize: "10px", color: expiryDate < today ? "red" : location.pathname=="/dronenaksha/view_plan" ? "#ffffff":"#666666", textAlign: 'end' }}> {`${activeSubscription.activesubscription}`} {expiryDate < today ? "- Expired" :""}</div> : <></>} */}
                                </div>
                                <React.Fragment>

                                    <div className="profile-dropdown" style={{}}>
                                        <img src={DropDown} style={{ height: '5px', cursor: 'pointer' }} onClick={handleClick} />
                                    </div>

                                    <Menu
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        onClick={handleClose}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                mt: 1.5,
                                                width: "170px",
                                                fontSize: "10px",
                                                '& .MuiAvatar-root': {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                '&:before': {
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 5,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: 'background.paper',
                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                    zIndex: 0,
                                                },
                                            },
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        {/* <Link 
                                    style={{
                                        color: "black"
                                    }}
                                    to={{
                                        pathname: "/my_profile",
                                        state: {
                                            user: activeProfile.user,
                                            type: activeProfile.type,
                                        }
                                    }}>
                                    <MenuItem >
                                        <Avatar /><span className="profile-toggle-text">Profile</span>
                                    </MenuItem>
                                </Link> */}
                                        {activeProfile.activeTeam ?
                                            <div style={{
                                                overflow: "auto",
                                                maxHeight: "400px"
                                            }}>
                                                {
                                                    teams.map(team => {
                                                        return <>
                                                            <Link
                                                                onClick={() => {
                                                                    switchTeam(team)
                                                                }} style={{
                                                                    color: "black"
                                                                }}
                                                                to={{
                                                                    pathname: getRedirectPath(),
                                                                    state: {
                                                                        user: activeProfile.user,
                                                                        type: activeProfile.type,
                                                                    }
                                                                }}>
                                                                {team.isOwner ? <div style={{ background: activeProfile.activeTeam == team.teamName ? "#dcdcfb" : "" }}><MenuItem>
                                                                    <span style={{ color: "#2989CF" }} className="profile-toggle-text" >
                                                                        {team.teamName.length > 15 ? `${team.teamName.substring(0, 15)}...` : team.teamName}
                                                                    </span>
                                                                </MenuItem>
                                                                </div>
                                                                    : <div style={{ background: activeProfile.activeTeam == team.teamName ? "#dcdcfb" : "" }}>
                                                                        <MenuItem>
                                                                            <span className="profile-toggle-text"

                                                                            >{team.teamName.length > 15 ? `${team.teamName.substring(0, 15)}...` : team.teamName}</span>
                                                                        </MenuItem>
                                                                    </div>}
                                                            </Link>
                                                        </>
                                                    })
                                                }
                                                <Divider />
                                            </div>
                                            : <></>}

                                        <Link to={{
                                            pathname: "/profile-settings",
                                            state: {
                                                user: activeProfile.user,
                                                type: activeProfile.type,
                                            }
                                        }} style={{ color: "black" }}>
                                            <MenuItem>
                                                <ListItemIcon>
                                                    <Settings fontSize="small" />
                                                </ListItemIcon>
                                                <span className="profile-toggle-text" >Settings</span>
                                            </MenuItem>
                                        </Link>

                                        <Link to={{
                                            pathname: "/usage_statistics",
                                            state: {
                                                user: activeProfile.user,
                                                type: activeProfile.type,
                                            }
                                        }} style={{ color: "black" }}>
                                            <MenuItem>
                                                <ListItemIcon>
                                                    <ContentPaste fontSize="small" />
                                                </ListItemIcon>
                                                <span className="profile-toggle-text" >Usage statistics</span>
                                            </MenuItem>
                                        </Link>

                                        <Link onClick={() => clearLocalStorage()} to="/logout" style={{ color: "black" }}>
                                            <MenuItem>
                                                <ListItemIcon>
                                                    <Logout fontSize="small" />
                                                </ListItemIcon>
                                                <span className="profile-toggle-text" >Logout</span>
                                            </MenuItem>
                                        </Link>
                                    </Menu>

                                </React.Fragment>
                            </div>
                        ) : ("")}</div>
                    {isSearch ? <GlobalSearch user={activeProfile.user} recentSearches={(JSON.parse(localStorage.getItem("recentSearches")))} type={activeProfile.type} location={location} close={toggleSearch} /> : <></>}
                </div>
            </>
            :
            <>
                <div>
                    <div style={{ position: "fixed", zIndex: location.pathname == "/" ? 2 : "90", top: '3px', right: "15px" }}>
                        {activeProfile.user.username ? (
                            <div
                                style={{ display: 'flex' }}
                            >
                                {!isSearch ? <div style={{ marginRight: "15px", display: "flex" }} onClick={toggleSearch}>
                                    <img src={location.pathname == "/dronenaksha/view_plan" ? SearchLight : SearchDark} style={{ height: "15px", position: "relative", left: "25px", top: "8px" }} />
                                    <div disabled type="text" className={location.pathname == "/dronenaksha/view_plan" ? "input-transparent" : "globalSearch-input hoverShadow input"} style={{ fontSize: "10px", height: "26px", width: "180px", paddingLeft: "34px", paddingTop: "3px", marginTop: "3px", cursor: "pointer" }}>
                                        <span style={{ height: "15px", position: "relative", left: "-5", top: "2px", color: location.pathname == "/dronenaksha/view_plan" ? 'white' : "#666666", fontSize: '12px', opacity: 0.9 }}>Search</span>
                                    </div>
                                </div> : <></>}

                                <div style={{ margin: '0px 10px' }}>
                                    <Notifications
                                        setActiveTeam={switchTeam}
                                        user={activeProfile.user}
                                        teams={teams}
                                    />
                                </div>

                                <div className="sidebar-toggle-text" style={{ padding: '0px 10px', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'end' }} >
                                    <div style={{ fontSize: '14px', color: location.pathname == "/dronenaksha/view_plan" ? "#ffffff" : "#3c3c3c", textAlign: 'end' }}>{`${activeProfile.user.first_name.length > 9 ? activeProfile.user.first_name.substring(0, 8) : activeProfile.user.first_name}`}  {`${activeProfile.user.last_name.length > 9 ? activeProfile.user.last_name.substring(0, 8) : activeProfile.user.last_name}`}</div>

                                    {activeProfile.activeTeam ?
                                        <div style={{ fontSize: "10px", color: location.pathname == "/dronenaksha/view_plan" ? "#ffffff" : "#666666", textAlign: 'end' }}> {`${activeProfile.activeTeam}`} </div> : <></>}
                                    {/* {activeSubscription.activesubscription ? <div style={{ fontSize: "10px", color: expiryDate < today ? "red" : location.pathname=="/dronenaksha/view_plan" ? "#ffffff":"#666666", textAlign: 'end' }}> {`${activeSubscription.activesubscription}`} {expiryDate < today ? "- Expired" :""}</div> : <></>} */}
                                </div>
                                <React.Fragment>
                                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                        {/* <Tooltip title="Account settings"> */}
                                        <IconButton onClick={handleClick} size="small" sx={{ p: 0 }}>
                                            {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
                                            <img className="sidebar-user-picture"
                                                style={{ height: "32px", width: "32px", }}
                                                src={activeProfile.user.avatar_url === null ? `/images/${activeProfile.user.first_name[0].toLowerCase()}.PNG` : `${activeProfile.user.avatar_url}?${activeProfile.user.sas_token
                                                    }&t${new Date().getTime()}`}
                                            />
                                        </IconButton>
                                        {/* </Tooltip> */}
                                    </Box>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        onClick={handleClose}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                mt: 1.5,
                                                width: "170px",
                                                fontSize: "10px",
                                                '& .MuiAvatar-root': {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                '&:before': {
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: 'background.paper',
                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                    zIndex: 0,
                                                },
                                            },
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        {/* <Link 
                            style={{
                                color: "black"
                            }}
                            to={{
                                pathname: "/my_profile",
                                state: {
                                    user: activeProfile.user,
                                    type: activeProfile.type,
                                }
                            }}>
                            <MenuItem >
                                <Avatar /><span className="profile-toggle-text">Profile</span>
                            </MenuItem>
                        </Link> */}
                                        {activeProfile.activeTeam ?
                                            <div style={{
                                                overflow: "auto",
                                                maxHeight: "400px"
                                            }}>
                                                {
                                                    teams.map(team => {
                                                        return <>
                                                            <Link
                                                                onClick={() => {
                                                                    switchTeam(team)
                                                                }} style={{
                                                                    color: "black"
                                                                }}
                                                                to={{
                                                                    pathname: getRedirectPath(),
                                                                    state: {
                                                                        user: activeProfile.user,
                                                                        type: activeProfile.type,
                                                                    }
                                                                }}>
                                                                {team.isOwner ? <div style={{ background: activeProfile.activeTeam == team.teamName ? "#dcdcfb" : "" }}><MenuItem>
                                                                    <span style={{ color: "#2989CF" }} className="profile-toggle-text" >
                                                                        {team.teamName.length > 15 ? `${team.teamName.substring(0, 15)}...` : team.teamName}
                                                                    </span>
                                                                </MenuItem>
                                                                </div>
                                                                    : <div style={{ background: activeProfile.activeTeam == team.teamName ? "#dcdcfb" : "" }}>
                                                                        <MenuItem>
                                                                            <span className="profile-toggle-text"

                                                                            >{team.teamName.length > 15 ? `${team.teamName.substring(0, 15)}...` : team.teamName}</span>
                                                                        </MenuItem>
                                                                    </div>}
                                                            </Link>
                                                        </>
                                                    })
                                                }
                                                <Divider />
                                            </div>
                                            : <></>}

                                        <Link to={{
                                            pathname: "/profile-settings",
                                            state: {
                                                user: activeProfile.user,
                                                type: activeProfile.type,
                                            }
                                        }} style={{ color: "black" }}>
                                            <MenuItem>
                                                <ListItemIcon>
                                                    <Settings fontSize="small" />
                                                </ListItemIcon>
                                                <span className="profile-toggle-text" >Settings</span>
                                            </MenuItem>
                                        </Link>

                                        <Link to={{
                                            pathname: "/usage_statistics",
                                            state: {
                                                user: activeProfile.user,
                                                type: activeProfile.type,
                                            }
                                        }} style={{ color: "black" }}>
                                            <MenuItem>
                                                <ListItemIcon>
                                                    <ContentPaste fontSize="small" />
                                                </ListItemIcon>
                                                <span className="profile-toggle-text" >Usage statistics</span>
                                            </MenuItem>
                                        </Link>

                                        <Link onClick={() => clearLocalStorage()} to="/logout" style={{ color: "black" }}>
                                            <MenuItem>
                                                <ListItemIcon>
                                                    <Logout fontSize="small" />
                                                </ListItemIcon>
                                                <span className="profile-toggle-text" >Logout</span>
                                            </MenuItem>
                                        </Link>
                                    </Menu>

                                </React.Fragment>

                            </div>
                        ) : ("")}</div>
                    {isSearch ? <GlobalSearch user={activeProfile.user} recentSearches={(JSON.parse(localStorage.getItem("recentSearches")))} type={activeProfile.type} location={location} close={toggleSearch} /> : <></>}
                </div>
            </>
    )
}
