import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './TableViewPagination.css'
const TableViewPagination = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        padding,
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        lastPage > 1 && <div style={{ padding: padding || "20px 0", display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            {<div style={{ color: "#727272", fontSize: "13px" }}>Page {currentPage} of {lastPage}</div>}
            {
                <div>
                    <ul
                        className={classnames('pagination-container', "pagination-bar")}
                    >
                        {/* Left navigation arrow */}
                        <li
                            className={classnames('pagination-item', {
                                disabled: currentPage === 1
                            })}
                            onClick={onPrevious}
                        >
                            <div className="arrow left" />
                        </li>
                        {paginationRange.map(pageNumber => {

                            // If the pageItem is a DOT, render the DOTS unicode character
                            if (pageNumber === DOTS) {
                                return <li className="pagination-item dots">&#8230;</li>;
                            }

                            // Render our Page Pills
                            return (
                                <li
                                    className={classnames('pagination-item', {
                                        selected: pageNumber === currentPage
                                    })}
                                    onClick={() => {
                                        if (currentPage !== pageNumber) onPageChange(pageNumber)
                                    }}
                                >
                                    {pageNumber}
                                </li>
                            );
                        })}
                        {/*  Right Navigation arrow */}
                        <li
                            className={classnames('pagination-item', {
                                disabled: currentPage === lastPage
                            })}
                            onClick={onNext}
                        >
                            <div className="arrow right" />
                        </li>
                    </ul>
                </div>}
        </div>
    );
};

export default TableViewPagination;
