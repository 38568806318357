import React from "react"

import { Link, StaticRouter, Redirect } from "react-router-dom";
import { isServiceAeroGCS } from "./reusableFunctions";
import RightArrow from "../../src/AeroGCSEnterprise/assets/icons/arrow-right.svg"
import Loader from "../../src/icons/loader.svg";


/**
To use this BreadCrump you have to pass data array of paths in the props.
data = [
    {
        displayName: "Projects",
        pathname: "/aerofarm/projects",
        state: {
            type,
            user,
            ...........
        },
        style:{style you want,if u want to use pass color otherwise it takes blue by default },
        className : u can pass also className to each breadCrumb
    },
        {
        displayName: "Projects",
        className : u can pass also className to each breadCrumb
        style:{style you want,u want to use pass color otherwise it takes blue by default }
        }  
        above object is the active page which you dont have to pass pathname or u can use it if u dont want to navigate
    }
]

also u can pass 2nd props which is dividerStyle

*/

export const BreadCrumbs = (props) => {
    return (
        <>{props.isLoading ?
            <div className="bread-crumb-container"
                style={{
                    position: 'absolute',
                    top: 'calc(100% - 60px)',
                }}
            >
                <img src={Loader} style={{
                    height: 'calc(100px - 40px)',
                    width: '100%'
                }} />
            </div> :
            isServiceAeroGCS(window.location.pathname) ?
                <div className="bread-crumb-container">
                    {props.data.map((breadCrumb, i) => {
                        const selected = i == props.data.length - 1
                        return <div >
                            {
                                breadCrumb.pathname ?
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Link
                                            to={{
                                                pathname: breadCrumb.pathname,
                                                state: breadCrumb.state,
                                            }}
                                        >
                                            <div className={selected ? 'selected-bread-crumb' : "bread-crumb"} >{breadCrumb?.displayName?.length > 16 ? `${breadCrumb.displayName.substring(0, 15)}...` : breadCrumb.displayName}</div>
                                        </Link>
                                        <span style={props.dividerStyle ? props.dividerStyle : { fontSize: "12px" }}>
                                            <img src={RightArrow} className="bread-crumbs-next-icon" />
                                        </span>
                                    </div>
                                    : <div onClick={() => {
                                        if (breadCrumb.onClick) {
                                            breadCrumb.onClick()
                                        }
                                    }} className={selected ? 'selected-bread-crumb' : "bread-crumb"}>
                                        {breadCrumb.displayName}
                                    </div>
                            }
                        </div>
                    })}
                </div>
                :
                < div style={{ display: "inline-block" }}>
                    {props.data.map((breadCrumb) => {
                        return <>
                            {breadCrumb.pathname ?
                                <>
                                    <Link
                                        to={{
                                            pathname: breadCrumb.pathname,
                                            state: breadCrumb.state,
                                        }}
                                    >
                                        <span className={breadCrumb.className ? breadCrumb.className : "onhoverext"} style={breadCrumb.style ? breadCrumb.style : {
                                            color: "black",
                                            fontSize: "12px",
                                        }}>{breadCrumb?.displayName?.length > 16 ? `${breadCrumb.displayName.substring(0, 15)}...` : breadCrumb.displayName}</span>
                                    </Link>
                                    <span style={props.dividerStyle ? props.dividerStyle : { fontSize: "12px" }}> / </span>
                                </>
                                : <span onClick={() => {
                                    if (breadCrumb.onClick) {
                                        breadCrumb.onClick()
                                    }
                                }} className={breadCrumb.className ? breadCrumb.className : ""} style={breadCrumb.style ? breadCrumb.style : {
                                    fontSize: "15px",
                                    fontWeight: "500",
                                }}>
                                    {breadCrumb?.displayName?.length > 16 ? `${breadCrumb.displayName.substring(0, 15)}...` : breadCrumb.displayName}
                                </span>
                            }
                        </>
                    })}
                </div >
        }
        </>
    )
}
