
import React, { Component, useEffect } from "react";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import {
   Chart as ChartJS,
   LinearScale,
   PointElement,
   LineElement,
   Tooltip,
   Legend,
   Filler
} from 'chart.js';
import { Scatter } from "react-chartjs-2";
import DownIcon from '../../../icons/DownIcon.png'
import Loader from '../../../icons/loader.svg';

class ElevationProfile extends Component {
   constructor(props) {
      super(props);

      this.state = {
         chartData: '',
         yTicks: {
            padding: 7,
            fill: "black",
            display: true,
            fontSize: 12,
            fontFamily: "Poppins",
            beginAtZero: false,
            fontStyle: "normal",
            fontColor: "#666666",
            lineHeight: "14px",
            autoSkip: true,
            autoSkipPadding: 10,
            isLoader: false,
         },
      }
   }

   componentWillMount() {

      ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);
      console.log(this.props);
   }

   render() {
      return (
         <>
            <div className="slide" style={{ width: '74vw', height: '40vh', position: 'absolute', bottom: this.props.showElevationProfile ? '0px' : '-405px', background: 'white', zIndex: 2, display: "flex", opacity: this.props.showElevationProfile ? "1" : "0", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
               {this.props.elevationData ?

                  this.props.isElevationData ?
                     <>

                        <div><img src={DownIcon} style={{ cursor: 'pointer', height: '16px', width: "16px" }} onClick={() => this.props.closeElevationGraph()} /></div>
                        <div
                           // className="container-row2"
                           // id="container-row2"
                           style={{
                              textAlign: 'center',
                              //border: "1px solid #c2c2c2",
                              width: "95%",
                              height: "36vh",
                              padding: "0px 20px 15px 5px",
                              marginTop: "0px",
                              //borderRadius: "5px",
                              //backgroundColor: "#FAFAFA"
                           }}
                        >

                           <Scatter
                              width={"55%"}
                              height={"20%"}
                              options={{
                                 responsive: true,
                                 maintainAspectRatio: false,
                                 plugins: {
                                    legend: {
                                       display: false
                                    }
                                 },
                                 elements: {
                                    point: {
                                       pointStyle: 'circle',
                                       borderColor: "#2989cf",
                                       backgroundColor: "#2989cf",
                                       radius: 1
                                    }
                                 },
                                 scales: {
                                    x: {
                                       barPercentage: 0.5,
                                       grid: {
                                          color: "#E8E8E8",
                                          display: true,
                                          drawTicks: true,
                                          lineWidth: 1
                                       },
                                       ticks: {
                                          padding: 2,
                                          color: "black",
                                          display: true,
                                          fontSize: 10,
                                          font: {
                                             family: "Poppins",
                                             style: "normal",
                                             lineHeight: "auto"
                                          },
                                          autoSkip: true,
                                          autoSkipPadding: 10
                                       },
                                       title: {
                                          display: false,
                                          fontSize: 10,
                                          font: {
                                             family: "Poppins",
                                             style: "normal"
                                          },
                                          color: "#666666",
                                          text: "Distance (m)",
                                          borderColor: "#666666"
                                       }
                                    },
                                    y: {
                                       barPercentage: 0.5,
                                       grid: {
                                          color: "#E8E8E8",
                                          display: true,
                                          drawTicks: true,
                                          lineWidth: 1
                                       },
                                       ticks: this.state.yTicks,
                                       title: {
                                          fontSize: 10,
                                          font: {
                                             family: "Poppins",
                                             style: "normal"
                                          },
                                          color: "#666666",
                                          display: true,
                                          text: `Elevation (${this.props.selectedUnit})`
                                       }
                                    }
                                 }
                              }}
                              data={this.props.elevationData}
                           />
                        </div>
                     </> :

                     <div className="flex-center">No Data Available</div>

                  :

                  <div className="flex-center"><img width="100px" style={{ marginLeft: "50px" }} src={Loader} /></div>
               }
            </div>
         </>
      );
   }
}

export default ElevationProfile