import React, { useEffect, useState } from 'react'
import LoaderComponent from "../ReusableComponents/LoaderComponent"
import ButtonWithIcon from './ButtonWithIcon'
import NoPlans from "../../src/assets/Images/Noplan.png"
import NoImagesets from "../../src/assets/Images/Noimageset.png"

const Selector = (props) => {
    const isOrtho = (item) => {
        return item?.file?.file_name && (item?.file?.file_name)?.includes(".tiff") || item?.file?.file_name && (item?.file?.file_name)?.includes(".TIFF") || item?.file?.file_name && (item?.file?.file_name)?.includes(".tif") || item?.file?.file_name && (item?.file?.file_name)?.includes(".TIF")
    }
    const [data, setData] = useState(props.data || [])
    const [selectedItems, setSelectedItems] = useState([])
    const handleItemChange = (event, item) => {
        if (event.target.checked) {
            setSelectedItems(prev => [...prev, item])
            setData(prev => {
                const newList = prev.map(p => {
                    if (p.id === item.id) return { ...p, checked: true }
                    else return p
                })
                return newList
            })
        }
        else {
            setSelectedItems(prev => prev.filter(en => en.id !== item.id))
            setData(prev => {
                const newList = prev.map(p => {
                    if (p.id === item.id) return { ...p, checked: false }
                    else return p
                })
                return newList
            })
        }

    }
    const clearAll = () => {
        setSelectedItems([])
        setData(prev => prev.map(item => ({ ...item, checked: false, selected: false })))
    }
    useEffect(() => {
        setData(props.data)
    }, [props.data.length])
    useEffect(() => {
        props.callback(selectedItems)
    }, [selectedItems.length, selectedItems[0]?.id])
    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", position: "relative" }}>
            <div style={{
                zIndex: "2", textAlign: "left", fontSize: "18px", color: "#2989cf", padding: "15px 30px", background: "#FAFAFA", display: "flex", alignItems: "center", justifyContent: "space-between", boxShadow: "0px 0px 17px -4px #00000045"

            }}>
                <div style={{ fontWeight: "500" }}>{props.title}</div>

            </div>
            <>
                {props.loading.status ? <LoaderComponent width={"98%"} height="231px" imgMargin={"100px 0px -50px 100px"} message={`Loading ${props.loading.message} please wait...`} /> :
                    <div style={{ overflowY: "scroll", height: "100%", marginTop: props.title === "Projects" ? "50px" : "", padding: "0px 0px 0px 30px", marginRight: "30px" }}>
                        {
                            data.length > 0
                                ? data.map((item, key) => <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "10px",
                                        fontSize: "15px",
                                        borderBottom: "1px solid lightgray",
                                        cursor: Number(item.images_count) == 0 || isOrtho(item) ? "not-allowed" : "pointer",
                                        // width:"100%"
                                    }}
                                    key={key}
                                    className={`${item?.selected ? "selected-entity" : ""}`}
                                    onClick={() => {
                                        if (props.isSelectable && !props.allowMultipleSelect) {
                                            setData(prev => {
                                                const newData = prev.map(t => {
                                                    if (t.id === item.id) return { ...t, selected: t?.selected ? false : true }
                                                    else return { ...t, selected: false }
                                                })
                                                return newData
                                            })
                                            if (item.selected) setSelectedItems([])
                                            else setSelectedItems([item])
                                        } else {
                                            props.onClickItem(item)
                                        }
                                    }}
                                >
                                    <div
                                        className={(Number(item.images_count) == 0||isOrtho(item)) ? "" : `onhoverext`}
                                        style={{ cursor: (Number(item.images_count) == 0||isOrtho(item)) ? "not-allowed" : "pointer", color: "#3c3c3c", textAlign: "left", wordWrap: "break-word", width: "calc(100% - 20px)", opacity: Number(item.images_count) == 0 ||isOrtho(item)? "0.5" : "" }}
                                    >{item.item_name} {props.title == "Imagesets" ? <span>{` (${item.images_count}) `}</span> : ''}
                                    </div>

                                    {props.isSelectable && props.allowMultipleSelect &&
                                        <input
                                            style={{ cursor: (Number(item.images_count) == 0||isOrtho(item)) ? "not-allowed" : "pointer", width: "18px", height: "18px", opacity: Number(item.images_count) == 0 ||isOrtho(item)? "0.5" : "" }}
                                            type='checkbox'
                                            checked={(Number(item.images_count) == 0||isOrtho(item)) ? false : item?.checked}
                                            onChange={(e) => {
                                                if (Number(item.images_count) != 0 && !isOrtho(item)) handleItemChange(e, item)
                                            }} />}
                                </div>)
                                :
                                <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", color: "darkgray", fontSize: "18px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div style={{ width: "180px" }}>
                                        <img style={{ width: "100%" }} src={props.title === "Plans" ? NoPlans : props.title === "Imagesets" ? NoImagesets : ""} />
                                    </div>
                                    <div style={{ fontSize: "15px" }}>
                                        {`No ${props.title} available`}
                                    </div>
                                </div>
                        }
                        {selectedItems.length > 0 && <div style={{ position: "absolute", right: "106px", bottom: "-48px", }}> <ButtonWithIcon height={"25.39px"} text={"Clear all"} onClick={clearAll} /></div>}
                    </div>
                }
            </>
        </div>
    )
}

export default Selector