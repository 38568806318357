import React, { Component, useEffect } from 'react'
import Annotation from '../../AeroCapture/Components/Annotation'
import AppConstants from '../../AppConstants';
import * as API from "../../api"
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup';

class FloorImageAnnotation extends Component {
    constructor(props) {
        super(props);
        this.Annotator = React.createRef();
        this.WindMillAnnotator = React.createRef();
        // const { optionList } = this.state;
        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.state = {
            lineToolActive: false,
            pointToolActive: false,
            currentImgVal: 0,
            user: {},
            key: 0,
            type: "",
            progress: 0,
            showDownloadPopup: false,
            loader: false,
            services: [],
            objects: [],
            showBackIcon: true,
            cancel: false,
            imageListindex: 0,
            panning: this.props.panning,
            selected: 0,
            zoomcheck: 0,
            loading: false,
            indexLength: 10,
            outId: "",
            regions: [],
            setzoom: 0,
            editAnnotations: false,
            sidebarMode: this.props
                ? this.props.sidebarMode
                : "shrunk",
            showPopup: false,
            message: "",
            scale: 0,
            showDiv: false,
            selectedObjects: [],
            addObjectPopup: false,
            newObject: "",
            regionIndex: 0,
            popupIcon: "",
            popupMessage: "",
            imageURL: '',
            thumbURLs: [],
            image_data: [],
            itemName: "",
            showProcessPopup: false,
            processPopupIcon: "",
            processAction: "",
            processMessage: "",
            imageLoad: true,
            showFilterPopup: false,
            severityLevels: [{ value: '1' }, { value: '2' }, { value: '3' }, { value: '4' }, { value: '5' }, { value: "All" }],
            severity: 'All',
            note: '',
            objCount: [],
            selectAll: true,
            obj: [],
            regionToCopy: null,
            classes: [],
            isThermalImage: false,
            boxTemperature: "",
            thermalMeta: "",
            isThermalDataLoaded: false,
            isWindMillData: false,
            selectedWindMillImage: undefined,
            bladeSide: undefined,
            windMillRegions: undefined,
            selecteWindMillRegion: undefined,
            clickFromList: false,
            clearAnnotationPopup: false,
            // currTempCalculated: true
        };
    }
    toggleClickedFromList = (value) => {
        this.setState({ clickFromList: value })
    }
    getBladeSide() {

    }
    showFilterPopup = (i) => {
        this.showSeverity()
        this.setState(
            (state) => ({
                ...state,
                showFilterPopup: true,
                severity: this.state.selectedSeverity || "All"
            }));
    }

    addAnnotationInfo = () => {
        let { severity, regionIndex, note, regions } = this.state
        if (severity)
            regions[regionIndex].data.severity = severity
        if (note)
            regions[regionIndex].data.note = note
        this.setState({
            regions,
            showFilterPopup: false
        })
    }

    addNewObject = (className) => {

    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
        }));
    };

    convertRegion = (line, type) => {
        if (type === "line") {
            return {
                x1: parseFloat(line[0]),
                y1: parseFloat(line[1]),
                x2: parseFloat(line[2]),
                y2: parseFloat(line[3]),
            }
        } else return {
            y: parseFloat(line[0]),
            x: parseFloat(line[1]),
            h: (parseFloat(line[2]) - parseFloat(line[0])),
            w: (parseFloat(line[3]) - parseFloat(line[1])),
        };
    }

    onKeyPress(e) {
        if (e.keyCode === 187 && e.shiftKey)
            this.zoomIn()
        if (e.keyCode === 189 && e.shiftKey)
            this.zoomOut()
    }

    // to load tiff image 
    loadTiffImageSrc = () => {

    }
    makeAllDetectionObjects = () => {
        return new Promise(async (resolve, reject) => {
            let detections = {}
            let objects = []
            await this.state.regions.forEach(async (region, key) => {
                if (detections[region.className]) {
                    detections[region.className] = {
                        count: detections[region.className].count + 1,
                        data: [...detections[region.className].data, this.convertToPolyData(region)]
                    }
                } else {
                    objects.push(region.className)
                    detections[region.className] = {
                        count: 1,
                        data: [this.convertToPolyData(region)]
                    }
                }
                if (this.state.regions.length == key + 1) resolve({ detections, objects });
            })
        })
    }
    //toggle select
    toggleSelecetAll = () => {
        let objects = []
        this.state.obj.map((el, i) => {
            if (this.state.selectAll) {
                el.selected = true
                objects.push(el)
            } else {
                el.selected = false
                objects.push(el)
            }
        });
        this.setState((state) => ({
            ...state,
            obj: objects,
            showAllImages: this.state.selectAll
        }));
    }
    updateAllReportsData = async () => {
        await this.setState({
            detection: {
                ...this.state.detection,
                "result": await this.makeAllDetectionObjects()
            }
        }, () => {
            this.setState({
                imageList: this.state.imageList.map((data) => {
                    if (data._id === this.state.detection._id) return this.state.detection;
                    else return data
                }),
                report: this.state.report.map((data) => {
                    if (data._id === this.state.detection._id) return this.state.detection;
                    else return data
                })
            }, () => {
            })
        })
    }
    getImageDataOnImageChange = async () => {

    };

    getReportDetails = async () => {

    };

    convertToPolyData = (region, isPolygon, isLine) => {
        let element = [];
        if (isPolygon) {
            if (region.x) {
                element[0] = [region.x, region.y]
                element[1] = [region.x + region.w, region.y]
                element[2] = [region.x + region.w, region.y + region.h]
                element[3] = [region.x, region.y + region.h]
                return element;
            } else {
                return (region.points)
            }
        } else if (isLine) {
            element[0] = region.x1;
            element[1] = region.y1;
            element[2] = region.x2;
            element[3] = region.y2;
            return element;
        }
        else {
            element[0] = region.y //ymin
            element[1] = region.x  //xmin
            element[2] = (region.y + region.h); //ymax
            element[3] = (region.x + region.w); //xmax
            return element;
        }
    }

    checkForTemperature = async () => {

    }
    getRegions = async (dontUpdateRegions = false) => {

    }

    getTask = async () => {

    }
    // Remove single annotation
    removeAnnotation = (i) => {
        let arr = this.state.regions
        arr.splice(i, 1)
        this.setState(
            (state) => ({
                ...state,
                regions: arr,
                showSelectBox: false
            }));
    }

    onChangeListener = (e) => {
        let t = e.target;
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
        } else if (e.target.value.includes(' ')) {
        }
        else {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value
            }))
        }
    }

    nextImage = () => {
        let id = this.state.selected + 1 >= this.state.imageList.length ? this.state.selected : this.state.selected + 1
        this.setState((state) => ({
            ...state,
            showDiv: true,
            imageLoad: id === this.state.imageList.length - 1 ? false : true
        }), () => this.changeImage(id));
        if (document.getElementById('container'))
            document.getElementById('container').scrollLeft = (id * 130);
    }

    prevImage = () => {
        let id = this.state.selected - 1 < 0 ? this.state.selected : this.state.selected - 1
        this.setState((state) => ({
            ...state,
            showDiv: true,
            imageLoad: id === 0 ? false : true
        }), () => this.changeImage(id));
        if (document.getElementById('container'))
            document.getElementById('container').scrollLeft -= 120;
    }

    onDeleteRegion = (id, forWindMill) => {
        this.setState({ toBeDeletedAnnotation: id, showDeletePopup: true }, async () => {
            this.saveFile(true)
        })
    }

    showSeverity = (id, edited) => {
        if (id) { }
        else this.Annotator.current.deSelectRegion()
        if (this.state.regions && this.state.regions.length > 0) {
            this.setState({
                untillSaveRegion: edited ? this.state.untillSaveRegion : this.state.regions.find(reg => reg.id === id),
                selectedRegion: id ? this.state.selectedRegion : undefined,
                regions: this.state.regions.map((region) => {
                    if (id === region.id) {
                        return {
                            ...region,
                            color: this.state.isThermalImage ?
                                AppConstants.THERMAL.COLOR.SELECTED
                                : region.color,
                            tempColor: AppConstants.THERMAL.COLOR.SELECTED_TEMP,
                            showSeverity: true,
                            editable: this.props.isDisabled ? false : true
                        }
                    } else {
                        if (this.state.untillSaveRegion && region.id === this.state.untillSaveRegion.id) {
                            return {
                                ...region,
                                ...this.state.untillSaveRegion,
                                data: { severity: this.state.untillSaveRegion?.data?.severity, note: this.state.untillSaveRegion?.data?.note },
                                tempColor: "white",
                                color: this.state.isThermalImage ?
                                    (region.type === "point" ? AppConstants.THERMAL.COLOR.POINT
                                        : region.type === "box" ? AppConstants.THERMAL.COLOR.BOX
                                            : region.type === "line" ? AppConstants.THERMAL.COLOR.LINE
                                                : "")
                                    : region.color,
                                showSeverity: false,
                                editable: false,
                                highlight: false,
                            }
                        } else
                            return {
                                ...region,
                                tempColor: "white",
                                color: this.state.isThermalImage ?
                                    (region.type === "point" ? AppConstants.THERMAL.COLOR.POINT
                                        : region.type === "box" ? AppConstants.THERMAL.COLOR.BOX
                                            : region.type === "line"
                                                ? AppConstants.THERMAL.COLOR.LINE
                                                : "")
                                    : region.color,
                                showSeverity: false,
                                editable: false,
                                highlight: false
                            }
                    }
                })
            })
        }
    }

    fetchThermalMeta = async () => {

    }



    getTemperatureOfLine = async (region) => {

    }

    getTempratureOfPoint = async (region) => {

    }

    getTemperatureOfBox = async (region) => {

    }

    deleteFloorIssue = (id) => {
        this.showSeverity()
        this.setState({ regions: this.state.regions.filter(item => item.id !== id), untillSaveRegion: undefined, selectedRegion: undefined }, () => {

            API.deleteFloorIssue(id).then(res => {
                console.log(res)
                this.setState({ toBeDeletedAnnotation: undefined, showDeletePopup: false })
            }).catch(err => {
                console.log(err)
                this.setState({ toBeDeletedAnnotation: undefined, showDeletePopup: false })
            })
        })
    }
    updateFloorIssue = (region) => {
        API.updateFloorIssue({
            id: region.id,
            coordinates: JSON.stringify({
                w: region.w,
                x: region.x,
                y: region.y,
                h: region.h
            }),
            remark: region.data?.note || "",
            severity: region.data?.severity || "",
            image_id: this.props.selectedImage.id
        })
            .then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
    }
    addFloorIssue = (region) => {
        if (this.state.regions.filter(reg => reg.id === region.id && region.editable).length > 0) {
        } else {
            API.addFloorIssue({
                id: region.id,
                coordinates: {
                    w: region.w,
                    x: region.x,
                    y: region.y,
                    h: region.h
                },
                remark: region.data?.note,
                severity: region.data?.severity,
                image_id: this.props.selectedImage.id
            })
                .then(res => {
                    console.log(res)
                }).catch(err => {
                    console.log(err)
                })
        }
    }
    getFloorIssues = () => {
        API.getFloorIssues(this.props.selectedImage.id).then(regions => {
            this.setState({
                regions: regions.map(reg => {
                    let coords = reg.coordinates

                    return { id: reg.id, w: coords.w, h: coords.h, x: coords.x, y: coords.y, className: "test", type: "box", color: "red", name: "test box", visible: true, data: { datatype: "test", percentage: "100", severity: reg.severity, note: reg.remark } }
                })
            }, () => {
                if (this.props?.rest?.taggedIssue?.id && !this.props.rest.notificationViewed) {
                    this.showSeverity(this.props.rest.taggedIssue.id)
                }
            })
        }).catch(err => {
            console.log(err)
        })
    }
    onRegionAdded = (region, isregionCopied, forWindMill) => {

        if (forWindMill) {
            this.setState({
                selecteWindMillRegion: isregionCopied ? this.state.selecteWindMillRegion : this.state.selecteWindMillRegion ? region : undefined,
                windMillRegions: [
                    ...this.state.windMillRegions,
                    {
                        data: { datatype: region.className, percentage: "100", severity: region.severity, note: region.note },
                        className: region.className,
                        ...region,

                    }
                ]
            }, async () => {
                const newWindMillRegions = this.state.windMillRegions.map(reg => this.convertToPolyData(reg, false))
            })
        }
        // x and y values converted percentage to pixel
        else if (region.type === "box") {
            // get temperature on thermal image for rectangle
            this.setState({
                addedFromAnnotation: true,

                // isThermalImage: true,
                selectedRegion: isregionCopied ? this.state.selectedRegion : this.state.selectedRegion ? { ...region, showSeverity: true } : undefined,
                regions: [
                    ...this.state.regions,
                    {
                        data: { datatype: region.className, percentage: "100", severity: region.severity, note: region.note },
                        className: region.className,
                        ...region,
                        // color: this.state.isThermalImage?(region.type === "point" ? AppConstants.THERMAL.COLOR.POINT : region.type === "box" ? AppConstants.THERMAL.COLOR.BOX : region.type === "line" ? AppConstants.THERMAL.COLOR.LINE : ""):region.color,
                        // tempColor: region.type === "point" ? AppConstants.THERMAL.COLOR.POINT : region.type === "box" ? AppConstants.THERMAL.COLOR.BOX : region.type === "line" ? AppConstants.THERMAL.COLOR.LINE : ""
                        showSeverity: true
                    }
                ]
            }
                ,
                () => {

                    this.props.handleImageAnnotationToolChange("showPointer", true)
                    this.addFloorIssue(region)
                    // console.log(this.state.regions)
                    this.showSeverity(region.id, this.state.regions.filter(reg => reg.id === region.id && reg.editable).length > 0)

                }
            )
        }
        if (this.state.isThermalImage) { //this willl happen only if image is thermal image

            if (region.type === "point") {
                this.setState({
                    addedFromAnnotation: true,
                    // isThermalImage: true,
                    selectedRegion: isregionCopied ? this.state.selectedRegion : this.state.selectedRegion ? region : undefined,
                    regions: [
                        ...this.state.regions,
                        {
                            data: { datatype: region.className, percentage: "100", severity: region.severity, note: region.note },
                            className: region.className,
                            ...region,
                            // color: this.state.isThermalImage?(region.type === "point" ? AppConstants.THERMAL.COLOR.POINT : region.type === "box" ? AppConstants.THERMAL.COLOR.BOX : region.type === "line" ? AppConstants.THERMAL.COLOR.LINE : ""):region.color,
                            // tempColor: region.type === "point" ? AppConstants.THERMAL.COLOR.POINT : region.type === "box" ? AppConstants.THERMAL.COLOR.BOX : region.type === "line" ? AppConstants.THERMAL.COLOR.LINE : ""

                        }
                    ]
                }
                    ,
                    () => {
                        this.state.isThermalImage && this.getTempratureOfPoint(region)
                    }
                )
            }
            if (region.type === "line") {
                this.setState({
                    addedFromAnnotation: true,
                    // isThermalImage: true,
                    selectedRegion: isregionCopied ? this.state.selectedRegion : this.state.selectedRegion ? region : undefined,
                    regions: [
                        ...this.state.regions,
                        {
                            data: { datatype: region.className, percentage: "100", severity: region.severity, note: region.note },
                            className: region.className,
                            ...region,
                            // color: this.state.isThermalImage?(region.type === "point" ? AppConstants.THERMAL.COLOR.POINT : region.type === "box" ? AppConstants.THERMAL.COLOR.BOX : region.type === "line" ? AppConstants.THERMAL.COLOR.LINE : ""):region.color,
                            // tempColor: region.type === "point" ? AppConstants.THERMAL.COLOR.POINT : region.type === "box" ? AppConstants.THERMAL.COLOR.BOX : region.type === "line" ? AppConstants.THERMAL.COLOR.LINE : ""

                        }
                    ]
                }
                    ,
                    () => {
                        this.state.isThermalImage && this.getTemperatureOfLine(region)
                    }
                )
            }
        }
    }

    onEditClassColor = async (className, color) => {

    }

    onClassUpdate = (id, updatedClass, saveData) => {
        if (saveData) {
            this.setState({
                untillSaveRegion: undefined,
                selectedRegion: { ...this.state.selectedRegion, showSeverity: false },
                regions: this.state.regions.map(item => {
                    if (item.id === id) {
                        return { ...item, showSeverity: false }
                    } else {
                        return item
                    }
                }),
            }, () => {
                this.saveFile(true)
                let region = this.state.regions.find(reg => reg.id === id)
                this.showSeverity()
                this.updateFloorIssue(region)
            });
        } else {
            this.setState({
                selectedRegion: { ...this.state.selectedRegion, data: updatedClass },
                regions: this.state.regions.map(item => {
                    if (item.id === id) {
                        return { ...item, data: updatedClass }
                    } else {
                        return item
                    }
                })
            })
        }
    }

    onRegionDataUpdate = (id) => {
        if (this.state.mentionedUser) {
            let props = {
                pathname: '/picstork/annotationReportTask',
                state: {
                    user: this.state.user,
                    type: '',
                    task: { task_id: this.state.task.task_id },
                    collection: { id: this.state.task.image_collection_id },
                    project: { project_name: this.state.task.project_name, id: this.state.task.project_id },
                    plan: { plan_name: this.state.task.plan_name, id: this.state.task.plan_id, project_name: this.state.task.project_name },
                }
            }

        }

        this.setState({
            selectedRegion: { ...this.state.selectedRegion, data: { ...this.state.selectedRegion.data } },
            regions: this.state.regions.map(item => {
                if (item.id === id) {
                    return { ...item, data: { ...this.state.selectedRegion.data } }
                } else {
                    return item
                }
            }),
        }, () => {
            this.setRegionEditable()
            this.saveFile(true)
        });
    }

    saveFile = async (dontUpdateRegions) => {

    }

    isFilterEnabled = () => {
        return (this.state.task.tasktype == 4 && (this.state.selectedSeverity != "All" || !this.state.selectAll || this.state.task.type.length != this.state.type.length))
    }

    togglePanning = (value, forWindMill) => {
        if (forWindMill) {
            if (this.checkEditAccess()) {
                if (this.state.task.tasktype == 4 || this.state.editAnnotations) {
                    this.setState({
                        panning: value === false ? false : (value === true ? true : !this.state.panning), selecteWindMillRegion: undefined
                    })
                } else {
                    this.setError("Please select the 'Edit' mode to modify the detection result.")
                }
            } else {
                this.setError()
            }
        }
        else {

            // this.state.isThermalImage && this.highlightAnnotation()
            this.setState({
                panning: value === false ? false : (value === true ? true : !this.state.panning), selectedRegion: undefined
            }, () => {

            })

        }
    }

    close() {
        this.setState((prevState) => ({
            ...prevState,
            showPopup: false,
            addObjectPopup: false,
            newObject: ""
        }));
    }

    initObjects = async () => {
        let ob = this.state.task.type;
        var temp = { selected: false, type: "", id: 0 };
        var tmp = [];
        ob.forEach((type, key) => {
            let index = this.state.type.findIndex((data) => { return data == type })
            temp.selected = index > -1 ? true : false;
            temp.type = type;
            temp.id = key;
            tmp.push(temp);
            temp = {};
        });
        tmp.push({})
        this.setState((state) => ({
            ...state,
            obj: tmp,
        }), () => this.getRegions());
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.panning !== this.props.panning) {
            this.setState({ panning: this.props.panning })
        }
        if (prevProps.selectedImage.id !== this.props.selectedImage.id) {
            this.getFloorIssues()
        }
    }
    componentWillMount() {
        if (this.props.selectedImage) {
            this.getFloorIssues()
        }
    }
    zoomIn = () => {
        var myImg = document.getElementById("action-image")
        var currWidth = myImg.clientWidth;
        var currHeight = myImg.clientHeight;
        myImg.style.width = (currWidth + 100) + "px";
        this.setState((state) => ({
            ...state,
            scale: this.state.scale + 1
        }))
        myImg.style.height = (currHeight + 100) + "px";
    }
    reset = () => {
        var myImg = document.getElementById("action-image")
        var currWidth = myImg.clientWidth;
        var currHeight = myImg.clientHeight;
        myImg.style.width = "75%";
        this.setState((state) => ({
            ...state,
            scale: 0
        }))
        myImg.style.height = "84vh";
    }
    zoomOut = () => {
        if (this.state.scale != 0) {
            var myImg = document.getElementById("action-image")
            var currWidth = myImg.clientWidth;
            var currHeight = myImg.clientHeight;
            this.setState((state) => ({
                ...state,
                scale: this.state.scale - 1
            }))
            myImg.style.width = (currWidth - 100) + "px";
            myImg.style.height = (currHeight - 100) + "px";
        }
    }
    /*-------Changes Images on Click image grid------- */
    changeImage = (val) => {

    };

    componentDidMount() {
        document.addEventListener("keydown", this.onKeyPress, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyPress, false);
    }

    taskIsInCreating = () => {
        // return this.state.task.status === AppConstants.TASK.STATUS.CREATING
        return true
    }

    // pass id for highlight one region only
    highlightClassRegions = (cls, id) => {
        // this.Annotator.current.deSelectRegion()
        this.setState({
            activeClass: cls,
            regions: this.state.regions.map(region => id && region.id === id ? { ...region, highlight: true } : (region.className === cls ? { ...region, highlight: true } : { ...region, highlight: false }))
        })
    }
    setError = (e) => {
        this.showSeverity()
        this.setState((state) => ({
            ...state,
            showProcessPopup: true,
            processPopupIcon: "ERROR",
            title: this.state.task.sharedBy?.isShared ? "Modification restricted" : "Select Edit",
            processMessage: false ? "You are not allowed." : (this.state.task.sharedBy?.isShared ? `Cannot modify detections as your task is shared by ${this.state.task.sharedBy?.first_name}`
                : (e || "Please select 'Edit' mode to modify the detection result."))
        }))
    }

    closeActivityPopup = () => {
        this.setState({ showProcessPopup: false })
    }

    onCopyRegion = (region) => {

        // this.highlightAnnotation();
        this.setState({
            regionToCopy: region,
            regions: this.state.regions.map((r) => region && r.id == region.id ?
                {
                    ...r,
                    color: this.state.isThermalImage ? r.color : AppConstants.COLORS.COPYANNOTATIONCOLOR,
                    copy: true,
                    tempColor: this.state.isThermalImage ? r.tempColor : AppConstants.COLORS.COPYANNOTATIONCOLOR
                }
                : {
                    ...r,
                    color: this.state.isThermalImage ? r.id === this.state?.selectedRegion?.id ? AppConstants.THERMAL.COLOR.SELECTED : (r.type === "point" ? AppConstants.THERMAL.COLOR.POINT : r.type === "box" ? AppConstants.THERMAL.COLOR.BOX : r.type === "line" ? AppConstants.THERMAL.COLOR.LINE : this.state.classes.find(cls => cls.name === r.className).color) : this.state.classes.find(cls => cls.name === r.className).color,
                    copy: false,
                    tempColor: this.state.isThermalImage ? r.id === this.state?.selectedRegion?.id ? AppConstants.THERMAL.COLOR.SELECTED_TEMP : (r.type === "point" ? AppConstants.THERMAL.COLOR.POINT : r.type === "box" ? AppConstants.THERMAL.COLOR.BOX : r.type === "line" ? AppConstants.THERMAL.COLOR.LINE : AppConstants.COLORS.COPYANNOTATIONCOLOR) : AppConstants.COLORS.COPYANNOTATIONCOLOR
                }
            )
        });
    }

    deleteClass = (className) => {
        this.setState({
            classToDelete: className,
            classDelete: true,
            itemName: "Class",
            processAction: "DELETE",
            processPopupIcon: "WARNING",
            processMessage: `All the annotations of '${className}' will be deleted, are you sure you want to delete this class label ?`,
            showProcessPopup: true,
        })
    }

    editClassName = (oldName, newName) => {

    }

    deleteClassData = () => {

    }
    checkEditAccess = () => {
        return true
    }
    setRegionEditable = (id, forWindMill) => {
        if (this.taskIsInCreating()) this.setState({ regions: this.state.regions.map(r => r.id === id ? { ...r, editable: !r.editable } : { ...r, editable: false }), selectedRegion: { ...this.state.selectedRegion, editable: id ? !this.state.selectedRegion.editable : false } }, () => {
            if (id) {
                let region = this.state.regions.find(reg => reg.id === id)
                if (region.editable === false) {
                    this.updateFloorIssue(region)
                }
            } else {

            }
        });
        else if (id) this.setError()
    }

    onChange(regions) {
        if (this.state.editAnnotations) {
            this.setState((prevState) => ({
                ...prevState,
                regions: regions,
            }));
        }
    }

    onClickRegion = (region, forWindMill, calledFromList) => {
        this.showSeverity(region?.id);
        this.setState({
            untillSaveRegion: { ...region, editable: false },
        }, () => {
        })

    }

    changeRegionData(index, event) {
        let regions = this.state.regions
        let obj = {
            datatype: event && event.target ? event.target.value : event,
            percentage: this.state.regions[index].data.percentage ? this.state.regions[index].data.percentage : '100',
            id: this.state.regions[index].data.id ? this.state.regions[index].data.id : this.state.regions.length
        }
        regions[index].className = event.target ? event.target.value : event
        regions[index].data = obj
        this.onChange(regions)
    }
    relocateImage = (e) => {
        // this is used to relocate image
        this.setState({
            emptyImage: true
        }, () => {
            this.setState({ emptyImage: false })
        })
    }

    importRegions = (regions, newClasses) => {
        if (this.state.editAnnotations && this.checkEditAccess()) {
            let objects = [...this.state.type.map((t, i) => {
                return { id: i, type: t, selected: true }
            }), ...(newClasses.map((cls, i) => {
                return { id: this.state.type.length + i, type: cls.name, selected: true }
            }))]
            let tempType = [...this.state.type, ...newClasses.map((cls) => cls.name)]
            this.setState({
                classes: [...this.state.classes, ...newClasses],
                obj: objects,
                type: tempType,
                regions: [...this.state.regions, ...regions]
            }, () => {

            })
        }
        else {
            this.setError()
        }
    }

    showFilterRemoveWarning = () => {
        this.setState((prevState) => ({
            ...prevState,
            editAnnotations: this.state.task.tasktype == 4,
            itemName: "",
            processPopupIcon: "WARNING",
            processAction: "",
            processMessage: "All filters will be removed, are you sure you want to edit the report ? ",
            showProcessPopup: true,
        }));
    }

    highlightAnnotation = (id) => {
        this.togglePanning(true)
        this.setState({ lineToolActive: false, pointToolActive: false })
        this.onClickRegion(this.state.regions.find(reg => reg.id === id), false, true)
    }
    render() {
        return <>
            {/* delete annotation popup */}
            <ActivityPopup
                item={""}
                open={this.state.showDeletePopup}
                icon={"WARNING"}
                action={this.state.processAction}
                msg={"Are you sure, you want to delete marked issue ?"}
                close={() => {
                    this.setState({ showDeletePopup: false })
                }}
                onClickOk={() => this.deleteFloorIssue(this.state.toBeDeletedAnnotation)}
            />
            <Annotation
                ref={this.Annotator}
                disabledUpdate={!(this.taskIsInCreating()) || this.props.isDisabled}
                setRegionEditable={this.setRegionEditable}
                setActiveClass={() => { }}
                showSeverity={this.showSeverity}
                highlightClassRegions={() => { }}
                onCopyRegion={() => { }}
                regionToCopy={undefined}
                onClickRegion={this.onClickRegion}
                panning={this.state.panning}
                togglePanning={this.togglePanning}
                regionAdded={this.onRegionAdded}
                classList={["test"]}
                onDeleteRegion={this.onDeleteRegion}
                onRecenter={this.relocateImage}
                regions={
                    this.state.regions
                }
                removeRegion={(regionId) => this.setState({ regions: this.state.regions.filter(region => region.id !== regionId) })}
                classesWithColor={undefined}
                imageSrc={this.state.emptyImage || `${this.props.selectedImage.url}?${this.props.st}`}
                onImageOrVideoLoaded={(e) => this.setState({ imageLoad: false })}
                setError={(e) => this.setError(e)}
                isThermalImage={false}
                getSinglePixleTempMethod={() => { }}
                pointToolActive={this.state.pointToolActive}
                lineToolActive={this.state.lineToolActive}
                onClassUpdate={this.onClassUpdate}
                mentionObj={this.props.mentionObj}
                creatable={true}
                isDisabled={this.props.isDisabled}
                currTempCalculated={true}
            /></>
    }

}
export default FloorImageAnnotation