import React, { Component } from 'react';
import dashboardActive from '../assets/sidebarIcons/dashboardActive.svg';
import * as API from '../../api.js';
import service from "../../sidebaricon/Services.png";
import Noproject from '../../assets/Images/Noproject.png';
import nextIcon from "../../icons/scrollRight.png";
import prevIcon from "../../icons/scrollLeft.png";
import { connect } from "react-redux";
import { updateProfileDetails, activesubscription } from '../../redux'
import NoPlan from '../../assets/Images/Noplan.png';
import Loader from '../../icons/loader.svg';
import { Link } from 'react-router-dom';
import SideBar from '../../SideBar';
import Menu from '../../icons/MenuWhite.png';
import MenuDark from '../../icons/MenuDark.png';
import NoRecentActivity from '../../assets/Images/Norecentplans.png';
import { checkSubscriptionAndStorage, convertToGB, getLimitedName } from '../../ReusableComponents/reusableFunctions.js';
import ImageSetThumb from '../../icons/ImageSetThumb.png'
import PlanThumb from '../../icons/PlanThumb.png'
import ProjectThumb from '../../assets/sideBarIcons/ProjectInactive.png'
import Noimageset from '../../assets/Images/Noimageset.png';
import { CreateProjectPlanModal } from '../../ReusableComponents/CreateProjectPlanModal';
import Help from "../../sidebaricon/Help.png";
import Settings from "../../sidebaricon/Settings.png";
import OnboardPopup from './OnboardPopup';
import InfiniteScroll from 'react-infinite-scroll-component'
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import dateFormat from 'dateformat';
import AddIcon from '../../icons/addBlue.png'
import ListIcon from '../../icons/gridBlue.png'
import GridIcon from '../../icons/listBlue.png'
import SharedIcon from '../../icons/SharedIcon.png'
import { DeleteComponentGrid, DeleteComponentTable } from '../../ReusableComponents/Menu'
import { addUserAnalytic, updateOutTime } from '../../ReusableComponents/reusableFunctions';
import { SortComponent } from '../../ReusableComponents/SortComponent'
import DropDownWithButton from '../../ReusableComponents/DropDownWithButton';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import { RoleNameComponent, getActiveTeam } from '../../Teams';
import { ManageProjectAccess } from '../../Teams/ManageProjectAccess';
import LocationIcon from '../../res/locationwhite.png';
import AppConstants, { BLOB_URL, SERVICES } from '../../AppConstants';
import SubscriptionExpireModal from '../../subscription/Reusable/SubscriptionExpireModal';
import DeleteProject from '../../ReusableComponents/DeleteProject';

class AeroCaptureDashboard extends Component {
    state = {
        activeTeam: undefined,
        user: {},
        type: "",
        selectedAllItems: "projects",
        key: " ",
        progress: 0,
        pageSize: 20,
        currentpage: -1,
        firstpage: 1,
        counts: 0,
        showDownloadPopup: false,
        projectRenamePopup: false,
        loader: true,
        taskStatusCount: {},
        services: [],
        projects: [],
        collections: [],
        tasks: [],
        reports: [],
        plans: [],
        taskCount: 0,
        showBackIcon: true,
        cancel: false,
        loadingMain: true,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        showDeletePopup: false,
        allData: [],
        deleteItemType: "",
        deleteLoader: true,
        infoPopup: false,
        thumbURLs: [],
        planThumbURLs: [],
        projectThumbURL: [],
        recentMaps: [],
        mapsThumbURLs: [],
        recentData: [],
        loadingRecent: true,
        selected: 0,
        gridView: true,
        gridData: [],
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        noContentMsg: "No Projects Available",
        noContentImg: Noproject,
        hasMoreItems: true,
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        selectedPlanId: '',
        selectedMapCollectionId: '',
        selectedCollectionId: '',
        sortType: 'Last Modified',
        sortTypeName: false,
        sortTypeDate: false,
        itemsList: [{ value: "projects", label: "Projects" }, { value: "plans", label: "Plans" }, { value: "imagesets", label: "Imagesets" }, { value: "maps", label: "Maps" }],
        selectedItem: "Projects",
        outId: "",
        tableHeaders: [],
        selectedMangeAccessProject: false,
        selectedProjectData: undefined,
        showSubscriptionExpirePopup: false,
    }
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    getRecentData = async () => {
        API.getLatestImageSets("user", 10, this.state.user.userid, 0, SERVICES.DRONENAKSHA).then(async (data) => {
            let collections = await this.loadTiff(data.imageCollections)
            let { user, type } = this.state
            API.getRecentMaps("user", this.state.user.userid, 10, SERVICES.DRONENAKSHA).then(async (data) => {
                var recentData = []
                data.data.map((map) => {
                    let date = new Date(map.processing_date)
                    map.date = date
                    map.thumbURL = `${BLOB_URL}/${this.state.activeTeam.teamOwner.container}/orthomosaicImages/${map.task_id}/orthomosaicThumb.png?${this.state.activeTeam.teamOwner.st}`
                    map.type = "Map"
                    map.displayData = []
                    map.displayData.push(map.collection_name, convertToGB(map.img_size), (map?.area?.split(" ")[0] / 1000000).toFixed(3) + " sq.km", dateFormat(date, "dd-mmm-yyyy"))
                    map.link_to = {
                        pathname: `/dronenaksha/view_plan`,
                        state: {
                            user, type,
                            plan: { id: map.plan_id, plan_name: map.plan_name, project_name: map.project_name, sharedData: map.sharedData, collection_id: map.id },
                            project: { id: map.project_id, project_name: map.project_name, sharedData: map.sharedData, collection_id: map.id },
                            collection: map,
                            orthoImageCollectionId: map.id,
                            layerShow: "RGBShow", sidebarMode: this.state.sidebarMode
                        }
                    }
                })
                this.setState((state) => ({
                    ...state,
                    recentMaps: data.data,
                }))
                let maps = data.data
                let pageSize = 10;
                let pageNumber = 0;
                API.getRecentPlans(pageSize, pageNumber, SERVICES.DRONENAKSHA).then(async (data) => {
                    this.setState((state) => ({
                        ...state,
                        sasToken: data.sasToken
                    }))
                    let plans = await this.loadPlanTiff(data.plans)
                    recentData = collections.concat(maps)
                    recentData = recentData.concat(plans)
                    recentData = recentData.sort((a, b) => { return Date.parse(b.date) - Date.parse(a.date) })
                    this.setState((state) => ({
                        ...state,
                        recentData: recentData.slice(0, 10),
                        loadingRecent: false
                    }))
                }, (e) => {
                    console.log(e);
                    this.setState((state) => ({
                        ...state,
                        recentData: recentData,
                        loadingRecent: false
                    }))
                })
            }, (e) => {
                console.log(e);
                this.setState((state) => ({
                    ...state,
                    loadingMain: false
                }))
            })
        }, (e) => {
            console.log(e);
        })

    }
    // function to load tiff thumbnails
    loadTiff = async (collections) => {
        let { user, type } = this.state
        var thumbURL = []
        for (let i = 0; i < collections.length; i++) {
            let collection = collections[i]
            let date = (new Date(collections[i].created_on))
            collections[i].date = date
            collections[i].type = "Imageset"
            collections[i].displayData = [collection.collection_name, collection.images_count, collection.plan_name, dateFormat(date, "dd-mmm-yyyy")]
            collections[i].link_to = {
                pathname: `/dronenaksha/images`,
                state: { user, type, collection, project: { project_name: collection.project_name, id: collection.project_id }, plan: { plan_name: collection.plan_name, id: collection.plan_id, project_name: collection.project_name, }, sidebarMode: this.state.sidebarMode }
            }
            if (collections[i].file && collections[i].file.file_name && collections[i].file.file_name != undefined) {
                let name = collections[i].file.file_name.toLowerCase()
                let turl = `${BLOB_URL}/${this.state.activeTeam.teamOwner.container}/FlightImages/collection-${collections[i].id}/thumb-${collections[i].file.file_name}?${this.state.activeTeam.teamOwner.st}`

                thumbURL[i] = turl
                collections[i].thumbURL = turl
                this.setState((state) => ({
                    ...state,
                    thumbURLs: thumbURL,
                }))
            }
        }
        return collections
    }

    // function to load tiff thumbnails
    loadPlanTiff = async (plans) => {
        let { user, type } = this.state
        var thumbURL = []
        for (let i = 0; i < plans.length; i++) {
            let plan = plans[i]
            let date = new Date(plans[i].date_and_time)
            plans[i].date = date;
            plans[i].type = "Plan"
            plans[i].displayData = [plan.plan_name, null, plan.project_name, date.toGMTString().split(' ').slice(1, 4).join('-')]
            plans[i].link_to = {
                pathname: `/dronenaksha/plan`,
                state: { user, type, plan, project: { id: plan.project_id, project_name: plan.project_name }, sidebarMode: this.state.sidebarMode }
            }
            if (plans[i].collection_id) {
                let name = plans[i].image.toLowerCase()
                let turl = `${BLOB_URL}/${user.blob_container}/FlightImages/collection-${plans[i].collection_id}/thumb-${plans[i].image}?${this.state.sasToken}`

                thumbURL[i] = turl
                plans[i].thumbURL = turl
                this.setState((state) => ({
                    ...state,
                    planThumbURLs: thumbURL,
                }))
            }
        }
        return plans
    }

    // function to load tiff thumbnails of project
    loadProjectTiff = async (projects) => {
        let { user, type } = this.state
        var thumbURL = []
        for (let i = 0; i < projects.length; i++) {
            let project = projects[i]
            projects[i].date = new Date(project.created_date);
            projects[i].type = "Project"
            projects[i].displayData = [project.project_name, null, "Tasks : " + project.tasks, dateFormat(project.created_date, "dd-mmm-yyyy"), project.location_name]
            projects[i].link_to = {
                pathname: "/dronenaksha/project",
                state: { user, type, sidebarMode: this.state.sidebarMode, project },
            }
            if (projects[i].collection_id && projects[i].collection_id != undefined) {
                let name = projects[i].image.toLowerCase()
                let turl = this.state.activeTeam.isOwner ? `${BLOB_URL}/${this.state.activeTeam.teamOwner.container}/FlightImages/collection-${projects[i].collection_id}/thumb-${projects[i].image}?${this.state.activeTeam.teamOwner.st}` :
                    `${BLOB_URL}/${this.state.activeTeam.teamOwner.container}/FlightImages/collection-${projects[i].collection_id}/thumb-${projects[i].image}?${this.state.activeTeam.teamOwner.st}`

                thumbURL[i] = turl
                projects[i].thumbURL = turl
                this.setState((state) => ({
                    ...state,
                    projectThumbURL: thumbURL,
                }))
            }
        }
        return projects
    }

    getPlans = async (appendData) => {
        if (!appendData) {
            this.setState({ loading: true })
            if (document.getElementById('table'))
                document.getElementById('table').scrollTop = 0
        }
        setTimeout(() => {
            let pageNumber = appendData ? this.state.plans.length : 0;
            API.getRecentPlans(this.state.pageSize, pageNumber, SERVICES.DRONENAKSHA).then(async (data) => {
                if (data.plans.length == 0 || data.plans.length < this.state.pageSize) {
                    this.setState((state) => ({
                        ...state,
                        hasMoreItems: false,
                        loading: false,
                        counts: data.count,
                    }));
                }
                else {
                    this.setState((state) => ({
                        ...state,
                        hasMoreItems: true
                    }))
                }
                var plans = appendData ? this.state.plans : data.plans
                if (appendData)
                    plans = plans.concat(data.plans)
                this.setState((state) => ({
                    ...state,
                    plans: plans,
                    loading: false,
                    sasToken: data.sasToken,
                    counts: data.count,
                    noContentImg: NoPlan,
                    noContentMsg: "No Plans Available"
                }))
                let gridData = await this.loadPlanTiff(plans)
                let tableHeaders = ["", "Name", "Project name", "Date Created", ""]
                this.setState((state) => ({
                    ...state,
                    gridData: gridData,
                    tableHeaders
                }), () => {
                    if (this.state.sortType === 'A-Z') {
                        this.sortByName()
                    } else if (this.state.sortType === 'Z-A') {
                        this.reverseByName()
                    } else if (this.state.sortType === 'First Modified') {
                        this.sortByDate()
                    } else if (this.state.sortType === 'Last Modified') {
                        this.reverseByDate()
                    }
                })
            }, (e) => {
                console.log(e);
                this.setState((state) => ({
                    ...state,
                    plans: [],
                    loading: false,
                    counts: 0
                }))
            })
        }, 500)
    }

    getMaps = async (appendData) => {
        if (!appendData) {
            this.setState({ loading: true })
            if (document.getElementById('table'))
                document.getElementById('table').scrollTop = 0
        }
        setTimeout(() => {
            let { user, type, pageSize } = this.state
            API.getAllMaps(" ", appendData ? this.state.maps.length : 0, pageSize).then(async (data) => {
                this.setState({ loading: true })
                if (data.data.length == 0 || data.data.length < pageSize) {
                    this.setState((state) => ({
                        ...state,
                        hasMoreItems: false,
                        loading: false,
                        counts: data.count ? data.count : 0,
                    }));
                }
                else {
                    this.setState((state) => ({
                        ...state,
                        hasMoreItems: true
                    }))
                }

                var maps = appendData ? this.state.maps : data.data
                if (appendData)
                    maps = maps.concat(data.data)
                maps.forEach(map => {
                    let date = (new Date(map.processing_date)).toLocaleDateString('en-US')
                    map.date = date
                    map.thumbURL = `${BLOB_URL}/${this.state.activeTeam.teamOwner.container}/orthomosaicImages/${map.task_id}/orthomosaicThumb.png?${this.state.activeTeam.teamOwner.st}`
                    map.type = "Map"
                    map.displayData = [map.collection_name, convertToGB(map.img_size), `${map ? map.area ? (map.area.split(" ")[0] / 1000000).toFixed(3) + " sq.km" : `` : ``}`, dateFormat(date, "dd-mmm-yyyy")] //area converted from sq.m to sq.km
                    map.link_to = {
                        pathname: `/dronenaksha/view_plan`,
                        state: {
                            user, type,
                            plan: { id: map.plan_id, plan_name: map.plan_name, project_name: map.project_name, sharedData: map.sharedData, collection_id: map.id },
                            project: { id: map.project_id, project_name: map.project_name, sharedData: map.sharedData, collection_id: map.id },
                            collection: map,
                            orthoImageCollectionId: map.id,
                            layerShow: "RGBShow", sidebarMode: this.state.sidebarMode
                        }
                    }
                })
                let tableHeaders = ["", "Name", "Size", "Area", "Processing Date", ""]
                this.setState((state) => ({
                    ...state,
                    maps: maps,
                    gridData: maps,
                    tableHeaders,
                    loading: false,
                    counts: data.count ? data.count : 0,
                    noContentImg: NoPlan,
                    noContentMsg: "No Maps Available"
                }), () => {
                    if (this.state.sortType === 'A-Z') {
                        this.sortByName()
                    } else if (this.state.sortType === 'Z-A') {
                        this.reverseByName()
                    } else if (this.state.sortType === 'First Modified') {
                        this.sortByDate()
                    } else if (this.state.sortType === 'Last Modified') {
                        this.reverseByDate()
                    }
                })
            }, (e) => {
                console.log(e);
                this.setState((state) => ({
                    ...state,
                    maps: [],
                    loading: false
                }))
            })
        }, 500)
    }

    getImageSets = async (appendData) => {
        if (!appendData) {
            this.setState({ loading: true })
            if (document.getElementById('table'))
                document.getElementById('table').scrollTop = 0
        }
        setTimeout(() => {
            API.getLatestImageSets("user", this.state.pageSize, this.state.user.userid, appendData ? this.state.collections.length : 0, SERVICES.DRONENAKSHA).then(async (data) => {
                this.setState({ loading: true })
                if (data.imageCollections.length == 0 || data.imageCollections.length < this.state.pageSize) {
                    this.setState((state) => ({
                        ...state,
                        hasMoreItems: false,
                        loading: false,
                        counts: data.count,
                    }));
                }
                else {
                    this.setState((state) => ({
                        ...state,
                        hasMoreItems: true
                    }))
                }

                var collections = appendData ? this.state.collections : data.imageCollections
                if (appendData)
                    collections = collections.concat(data.imageCollections)
                this.setState((state) => ({
                    ...state,
                    collections: collections,
                    loading: false,
                    counts: data.count,
                    noContentImg: Noimageset,
                    noContentMsg: "No ImageSets Available"
                }))
                let collectionsLoad = await this.loadTiff(collections)
                let tableHeaders = ["", "Name", "Images", "Plan", "Date Created", ""]
                this.setState((state) => ({
                    ...state,
                    gridData: collectionsLoad,
                    tableHeaders
                }), () => {
                    if (this.state.sortType === 'A-Z') {
                        this.sortByName()
                    } else if (this.state.sortType === 'Z-A') {
                        this.reverseByName()
                    } else if (this.state.sortType === 'First Modified') {
                        this.sortByDate()
                    } else if (this.state.sortType === 'Last Modified') {
                        this.reverseByDate()
                    }
                })
            }, (e) => {
                console.log(e);
            })
        }, 500)
    }

    getProjects = async (appendData) => {
        if (!appendData) {
            this.setState({ loading: true })
            if (document.getElementById('table'))
                document.getElementById('table').scrollTop = 0
        }
        setTimeout(() => {
            const { pageSize, key } = this.state;
            this.setState(state => ({
                ...state,
                noContentImg: Noproject,
                noContentMsg: "No Projects Available",
                currentpage: this.state.currentpage + 1
            }))
            API.getAllProjects(key == "" ? " " : key, pageSize, appendData ? this.state.currentpage : 0, undefined, AppConstants.SERVICES.DRONENAKSHA).then(async (data) => {
                if (data.projects.length == 0 || data.projects.length < pageSize) {
                    this.setState((state) => ({
                        ...state,
                        hasMoreItems: false,
                        loading: false,
                        counts: data.count,
                    }));
                }
                else {
                    this.setState((state) => ({
                        ...state,
                        hasMoreItems: true
                    }))
                }
                var projects = appendData ? this.state.projects : data.projects
                if (appendData)
                    projects = projects.concat(data.projects)
                this.setState((state) => ({
                    ...state,
                    projects: projects,
                    loadingMain: false,
                    counts: data.count,
                    loading: false
                }))
                let projectsData = await this.loadProjectTiff(projects)
                let tableHeaders = ["", "Name", "Images", "Date Created", "Location", ""]
                this.setState((state) => ({
                    ...state,
                    gridData: projectsData,
                    tableHeaders
                }), () => {
                    if (this.state.sortType === 'A-Z') {
                        this.sortByName()
                    } else if (this.state.sortType === 'Z-A') {
                        this.reverseByName()
                    } else if (this.state.sortType === 'First Modified') {
                        this.sortByDate()
                    } else if (this.state.sortType === 'Last Modified') {
                        this.reverseByDate()
                    }
                })
            }, (e) => {
                console.log(e);
                this.setState({
                    loading: false,
                })
            }).catch(err => {
                console.log(err);
            })
        }, 500)
    }

    onChangeEvent = (e) => {
        let value = e;
        this.setState({
            selectedItem: e,
            selectedAllItems: value,
            allItemsLoader: true,
            plans: [],
            maps: [],
            collections: [],
            projects: [],
            sortType: 'Last Modified',
            loading: true
        }, () => {
            if (this.state.selectedAllItems == 'maps')
                this.getMaps()
            else if (this.state.selectedAllItems == 'projects')
                this.getProjects()
            else if (this.state.selectedAllItems == 'plans')
                this.getPlans()
            else if (this.state.selectedAllItems == 'imagesets')
                this.getImageSets()
        })
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
        }))
    }

    deleteOrthomosaic = () => {
        this.setState((state) => ({
            ...state,
            itemName: "Map",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.deleteOrthomosaicImage({ collection_id: this.state.selectedMapCollectionId }).then(() => {
            this.setState((state) => ({
                ...state,
                itemName: "Map",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
                selectedMapCollectionId: '',
                allItemsLoader: true,
                hasMoreItems: true,
            }), () => {
                this.getRecentData()
                this.getMaps()
            })
        }).catch(e => {
            this.setState((state) => ({
                ...state,
                showAddImagePopup: false,
                itemName: "Map",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }


    deleteImageCollection = () => {
        this.setState((state) => ({
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.deleteAerocaptureImageCollection(this.state.selectedCollectionId, AppConstants.SERVICES.DRONENAKSHA).then((data) => {
            console.log(data);
            this.setState((state) => ({
                itemName: "Imageset",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
                selectedCollectionId: '',
                allItemsLoader: true,
                hasMoreItems: true,
            }), () => {
                this.getRecentData()
                this.getImageSets()
            })
        }, (e) => {
            this.setState((state) => ({
                itemName: "Imageset",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }

    deletePlan = () => {
        this.setState((state) => ({
            itemName: "Plan",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.deleteAerocapturePlan(this.state.selectedPlanId, AppConstants.SERVICES.DRONENAKSHA).then((data) => {
            this.setState((state) => ({
                itemName: "Plan",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
                selectedPlanId: '',
                allItemsLoader: true,
                hasMoreItems: true
            }), () => {
                this.getRecentData()
                this.getPlans()
            })
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                itemName: "Project",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }
    // function to Get all data under the given project
    getAllDataToDelete = () => {
        this.setState((state) => ({
            ...state,
            deleteLoader: true,
            showDeletePopup: true,
        }))
        API.getProjectData(this.state.deleteItemType, this.state.selectedProject.project_name, this.state.selectedProject.id).then((data) => {
            let keys = Object.keys(data)
            let alldata = []
            keys = keys.reverse()
            keys.forEach(element => {
                let obj = { "name": element, "count": data[element].length, "processIcon": "", data: data[element] }
                alldata.push(obj)
            });
            this.setState((state) => ({
                ...state,
                allData: data.isEmptyProject ? null : alldata,
                deleteLoader: false
            }))
        }, (e) => {
            console.log(e);
        })
    }

    next = () => {
        let selected = this.state.selected + 2
        this.setState((state) => ({
            ...state,
            selected: selected,
        }))
        document.getElementById('scroll-div').scrollLeft += 400;
    }
    imagesets
    prev = () => {
        let selected = this.state.selected - 2 < 0 ? 0 : this.state.selected - 2
        this.setState((state) => ({
            ...state,
            selected: selected,
        }))
        document.getElementById('scroll-div').scrollLeft -= 400;
    }

    closeInfoPopup = () => {
        this.setState((state) => ({
            ...state,
            OnboardPopup: false,
        }));
        this.props.updateProfileDetails({
            firstTimeLoginDN: this.state.user.userid
        });
    };

    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        if (this.state.selectedAllItems == 'maps') {
            let sort = this.state.maps.sort((a, b) => a.collection_name.localeCompare(b.collection_name))
            this.setState((state) => ({
                ...state,
                maps: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'projects') {
            let sort = this.state.projects.sort((a, b) => a.project_name.localeCompare(b.project_name))
            this.setState((state) => ({
                ...state,
                projects: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'plans') {
            let sort = this.state.plans.sort((a, b) => a.plan_name.localeCompare(b.plan_name))
            this.setState((state) => ({
                ...state,
                plans: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'imagesets') {
            let sort = this.state.collections.sort((a, b) => a.collection_name.localeCompare(b.collection_name))
            this.setState((state) => ({
                ...state,
                collections: sort,
                gridData: sort
            }));
        }
    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        if (this.state.selectedAllItems == 'maps') {
            let sort = this.state.maps.sort((a, b) => b.collection_name.localeCompare(a.collection_name))
            this.setState((state) => ({
                ...state,
                maps: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'projects') {
            let sort = this.state.projects.sort((a, b) => b.project_name.localeCompare(a.project_name))
            this.setState((state) => ({
                ...state,
                projects: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'plans') {
            let sort = this.state.plans.sort((a, b) => b.plan_name.localeCompare(a.plan_name))
            this.setState((state) => ({
                ...state,
                plans: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'imagesets') {
            let sort = this.state.collections.sort((a, b) => b.collection_name.localeCompare(a.collection_name))
            this.setState((state) => ({
                ...state,
                collections: sort,
                gridData: sort
            }));
        }

    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        if (this.state.selectedAllItems == 'maps') {
            let sort = this.state.maps.sort((a, b) => a.processing_date.localeCompare(b.processing_date))
            this.setState((state) => ({
                ...state,
                maps: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'projects') {
            let sort = this.state.projects.sort((a, b) => a.created_date.localeCompare(b.created_date))
            this.setState((state) => ({
                ...state,
                projects: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'plans') {
            let sort = this.state.plans.sort((a, b) => a.date_and_time.localeCompare(b.date_and_time))
            this.setState((state) => ({
                ...state,
                plans: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'imagesets') {
            let sort = this.state.collections.sort((a, b) => a.created_on.localeCompare(b.created_on))
            this.setState((state) => ({
                ...state,
                collections: sort,
                gridData: sort
            }));
        }
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        if (this.state.selectedAllItems == 'maps') {
            let sort = this.state.maps.sort((a, b) => b.date.localeCompare(a.date))
            this.setState((state) => ({
                ...state,
                maps: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'projects') {
            let sort = this.state.projects.sort((a, b) => b.created_date.localeCompare(a.created_date))
            this.setState((state) => ({
                ...state,
                projects: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'plans') {
            let sort = this.state.plans.sort((a, b) => b.date_and_time.localeCompare(a.date_and_time))
            this.setState((state) => ({
                ...state,
                plans: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'imagesets') {
            let sort = this.state.collections.sort((a, b) => b.created_on.localeCompare(a.created_on))
            this.setState((state) => ({
                ...state,
                collections: sort,
                gridData: sort
            }));
        }
    }

    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }

    componentWillMount() {
        getActiveTeam().then((activeteam) => {
            this.setState({ activeTeam: activeteam }, () => {

                checkSubscriptionAndStorage(AppConstants.SERVICES.DRONENAKSHA).then(data => {
                    if (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
                    else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
                })

                addUserAnalytic(this.props.location.pathname)
                    .then(id => {
                        this.setState((state) => ({
                            outId: id
                        }))
                    })
                var style = document.createElement('style');
                style.type = 'text/css';
                style.innerHTML = '.popup-content { width: 30% !important; }';
                document.getElementsByTagName('head')[0].appendChild(style);

                document.title = "DroneNaksha - Dashboard";
                if (this.props.location.state) {
                    let { user, type, sidebarMode } = this.props.location.state;
                    this.setState((state) => ({
                        ...state,
                        user,
                        type, sidebarMode
                    }), async () => {
                        this.getRecentData();
                        this.getProjects();
                        await API.getUserSubbedServices().then(
                            (response) => {
                                let services = response[0];
                                if (services.length == 1 && services[0].title == 'AeroStream') {
                                    this.setState((state) => ({
                                        ...state,
                                        showBackIcon: false
                                    }))
                                } else {
                                    this.setState((state) => ({
                                        ...state,
                                        showBackIcon: true
                                    }))
                                }
                            }
                        )
                    })
                } else {
                    API.getUserType().then(([blah, type, user]) => {
                        this.setState((state) => ({
                            ...state,
                            user,
                            type
                        }), async () => {
                            await this.getRecentData()
                            this.getProjects()
                        })
                    }, (e) => {
                        window.location.replace("/login");
                    })
                }
            })
        })

    }



    render() {
        let { user, type, plans, maps, collections, tasks, reports, sidebarMode, showBackIcon, projects, taskStatusCount } = this.state
        const listLoader = <div style={{ height: "10vh", width: "80vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={Loader} style={{ height: "10vh", width: "50vw", margin: "auto" }} />
        </div>

        const CustomSortToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
                href=""
                ref={ref}
                onClick={e => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {children}
                <div className='sort-type-style'>{this.state.sortType}</div>
            </a>
        ));


        let sidebarConfigurations = {

            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },

                {
                    icon: dashboardActive,
                    text: "DroneNaksha",
                    path: "/dronenaksha/dashboard",
                    selected: true,
                },
                {
                    icon: Settings,
                    text: "Settings",
                    path: "/profile-settings",
                },
                {
                    icon: Help,
                    text: "Help",
                    path: "/feedback",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode,
            droneNakshaHelp: true

        }

        return (
            user.username ? <div className="wrapper">
                {/* Done */}
                <ManageProjectAccess
                    projectId={this.state.selectedMangeAccessProject}
                    closeManageAccess={() => {
                        this.setState({
                            selectedMangeAccessProject: false,
                            selectedProjectData: undefined
                        })
                    }}
                    senderData={user}
                    propsData={this.state.selectedProjectData}
                />
                <SideBar config={sidebarConfigurations} showMainDashboardIcons={this.state.showBackIcon} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    style={{ background: "#F5F6F8" }}
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >

                    {/*  subscription and info popup  */}
                    {this.state.showSubscriptionExpirePopup ?
                        <SubscriptionExpireModal
                            data={this.state.showSubscriptionExpirePopup}
                        /> : this.props.SubscriptionData && !(this.props.firstTimeLoginDN === user.userid) ? (
                            <OnboardPopup show={this.state.infoPopup} close={() => this.closeInfoPopup()} />
                        ) : <></>
                    }
                    {/* end of popup */}

                    {/* -------------- Activity Popup ----------------------- */}
                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        height={this.state.processPopupIcon == "WARNING" && this.state.selectedAllItems == 'imagesets' ? "210px" : null}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => {
                            if (this.state.selectedAllItems == 'maps') {
                                this.deleteOrthomosaic()
                            }
                            else if (this.state.selectedAllItems == 'projects') {
                                this.getAllDataToDelete()
                            }
                            else if (this.state.selectedAllItems == 'plans') {
                                this.deletePlan()
                            }
                            else if (this.state.selectedAllItems == 'imagesets') {
                                this.deleteImageCollection()
                            }
                        }}
                    />
                    {/* --------------------------------------------------------------- */}
                    {/* ---------------Component to delete project--------------------- */}
                    <DeleteProject
                        service={AppConstants.SERVICES.DRONENAKSHA}
                        projectId={this.state?.selectedProject?.id}
                        show={this.state.showDeletePopup}
                        onClose={(isDeleted) => {
                            this.setState({
                                showDeletePopup: false
                            }, () => {
                                if (isDeleted)
                                    this.getProjects()
                            })
                        }}
                    />

                    <div className="top-bar"><div className="top-bar-text">
                        DRONENAKSHA
                    </div>
                    </div>
                    {this.state.loadingMain ?
                        <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                            <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                        </div> :
                        <div className="main-content" style={{ background: 'none' }}>
                            {this.state.activeTeam.isOwner ?
                                <CreateProjectPlanModal
                                    serviceName={AppConstants.SERVICES.DRONENAKSHA}
                                    user={this.state.user}
                                    type={this.state.type}
                                    createProjectPlanModal={this.state.createProjectPlanModal}
                                    closeCreateProjectPlanModal={(newItem) => {
                                        if (newItem)
                                            this.getRecentData()
                                        if (newItem && newItem.includes("PROJECT") && this.state.selectedAllItems == 'projects')
                                            this.getProjects()
                                        else if (newItem && newItem.includes("PLAN") && this.state.selectedAllItems == 'plans')
                                            this.getPlans()
                                        else if (newItem && newItem.includes("IMAGESET") && this.state.selectedAllItems == 'imagesets')
                                            this.getImageSets()
                                        this.setState({ createProjectPlanModal: false })
                                    }}
                                    createType={"project"}
                                /> :
                                <></>
                            }

                            {this.state.activeTeam.isOwner &&
                                <>
                                    <div style={{ display: "flex", width: "100%", marginTop: '8px', justifyContent: 'space-between' }}>
                                        <div className="services-content-title">{"Recent Activity"}</div>
                                        <div style={{ display: 'flex', alignItems: "center", filter: this.state.activeTeam.isOwner ? "" : "grayscale(100%)" }}>
                                            <ButtonWithIcon text="New Map" icon={AddIcon} onClick={() => { this.setState({ createProjectPlanModal: true }) }} isDisabled={this.state.activeTeam.isOwner ? false : true} />
                                        </div>
                                    </div>

                                    {this.state.loadingRecent ?
                                        <div style={{ height: "160px", width: "81vw", display: "flex", textAlign: "center" }}>
                                            <img src={Loader} style={{ height: "10vh", width: "100vw", margin: "auto" }} />
                                        </div> :
                                        this.state.recentData.length > 0 ?
                                            <div style={{ display: "flex", width: '100%', height: "160px" }}>
                                                {this.state.selected == 0 ? <div style={{ margin: 'auto 5px auto -5px' }}>
                                                    <img src={prevIcon} style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        opacity: "0.5"
                                                    }} />
                                                </div> :
                                                    <div style={{ cursor: 'pointer', margin: 'auto 5px auto -5px' }} onClick={() => this.prev()}>
                                                        <img src={prevIcon} style={{
                                                            width: "20px",
                                                            height: "20px",
                                                        }} />
                                                    </div>}
                                                <div id="scroll-div" className='hideScroll' style={{ overflowX: 'scroll', width: "calc(100vw - 172px)", height: "155px", display: "flex", marginTop: "10px" }}>
                                                    <div className="aerocapture-cards-tray-projects" style={{ height: "153px", display: "flex", paddingTop: "12px" }}>
                                                        <>
                                                            {this.state.recentData.map((data, key) => {
                                                                return (
                                                                    <div>
                                                                        <Link to={data.link_to}>
                                                                            {<div key={key} className="customer-cards-dashboard card" style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)' }}>
                                                                                <div className="card-header customer-cards-dashboard-logo customer-cards-dashboard-text project-card-header" style={{ height: "70px" }}>
                                                                                    {

                                                                                        data && data.type == "Map" ? data.task_id ?
                                                                                            <div style={{ borderRadius: "6px", height: "111px", width: "100%", background: "black" }} >
                                                                                                <img style={{ objectFit: 'contain', height: "111px", width: "170px" }} loading="true" src={data.thumbURL} onError={(e) => { e.target.onerror = null; e.target.src = ImageSetThumb }} />
                                                                                            </div> : <img id="ProjectImage" src={ImageSetThumb} /> :
                                                                                            data.type == "Plan" ? data.image ?
                                                                                                <img id="ProjectImage" style={{ objectFit: 'cover' }} loading="true" src={data.thumbURL} /> :
                                                                                                <img id="ProjectImage" src={PlanThumb} /> :
                                                                                                data.file && data.file.file_name ?
                                                                                                    <img id="ProjectImage" style={{ objectFit: 'cover' }}
                                                                                                        src={data.thumbURL}
                                                                                                        onError={(e) => { e.target.onerror = null; e.target.src = ImageSetThumb }} /> :
                                                                                                    <img id="ProjectImage" style={{ objectFit: 'cover' }}
                                                                                                        src={ImageSetThumb} />
                                                                                    }
                                                                                </div>
                                                                                <div className='type-badge'>
                                                                                    <span style={{ fontSize: "10px" }}>{data.type}</span>
                                                                                </div>
                                                                                {data.sharedData.isShared ?
                                                                                    <div className='shared-badge'>
                                                                                        <img src={SharedIcon} style={{ height: "18px" }} />
                                                                                    </div> :
                                                                                    <></>
                                                                                }
                                                                                <div className="card-body change-color customer-cards-dashboard-text project-card-body" style={{ height: "41px" }}>
                                                                                    <div style={{ display: 'flex', height: "18px", alignItems: "center", justifyContent: 'space-between' }}>
                                                                                        <div style={{ overflow: "hidden" }}>
                                                                                            {data.type == "Map" && data.displayData[0].length > 10 ?
                                                                                                `${data.displayData[0].substring(0, 10)}...` :
                                                                                                data.displayData[0].length > 15 ?
                                                                                                    `${data.displayData[0].substring(0, 15)}...`
                                                                                                    : `${data.displayData[0]}`
                                                                                            }
                                                                                        </div>
                                                                                        <div style={{ overflow: "hidden" }}>
                                                                                            {data.displayData[1]}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ fontSize: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <div style={{ overflow: 'hidden' }}>
                                                                                            {data.displayData[2].length > 12 ?
                                                                                                `${data.displayData[2].substring(0, 12)}...` :
                                                                                                `${data.displayData[2]}`
                                                                                            }
                                                                                        </div>
                                                                                        <div>
                                                                                            {data.displayData[3]}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>}
                                                                        </Link>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </>
                                                    </div>
                                                </div>
                                                {this.state.selected == this.state.recentData.length || this.state.selected == this.state.recentData.length - 1 ? <div style={{ margin: 'auto 0px auto 5px' }}>
                                                    <img src={nextIcon} style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        opacity: "0.5"
                                                    }} />
                                                </div> :
                                                    <div style={{ cursor: 'pointer', margin: 'auto 0px auto 5px' }}>
                                                        <img onClick={() => this.next()} src={nextIcon} style={{
                                                            width: "20px",
                                                            height: "20px",
                                                        }} />
                                                    </div>}
                                            </div> :
                                            <div className="rmp-dashboard-empty-data-message" >
                                                <img style={{ height: '10%', width: '10%' }} src={NoRecentActivity} />
                                                <p style={{ margin: '10px' }}>No recent activity</p>
                                            </div>
                                    }
                                </>
                            }

                            <div style={{ display: "flex", justifyContent: 'space-between', marginBottom: !this.state.gridView && this.state.gridData.length == 0 ? "25px" : "0px" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {this.state.activeTeam.isOwner ?
                                        <DropDownWithButton
                                            selectedItem={this.state.selectedItem.toLowerCase()}
                                            width='113.5px'
                                            isDisabled={this.state.loading || this.state.loadingMain}
                                            list={
                                                [
                                                    {
                                                        name: "projects", onClick: () => {
                                                            this.onChangeEvent("projects")
                                                        }
                                                    },
                                                    {
                                                        name: "plans", onClick: () => {
                                                            this.onChangeEvent("plans")
                                                        }
                                                    },
                                                    {
                                                        name: "imagesets", onClick: () => {
                                                            this.onChangeEvent("imagesets")
                                                        }
                                                    },
                                                    {
                                                        name: "maps", onClick: () => {
                                                            this.onChangeEvent("maps")
                                                        }
                                                    }
                                                ]
                                            }
                                        /> :
                                        <div>Projects</div>
                                    }
                                    <span className='data-count' style={{ marginLeft: "8px", marginTop: "2px" }}>({this.state.counts})</span>
                                </div>
                                <div className="add-user-form-text-wrapper" style={{ display: "flex", alignItems: 'center', }} >
                                    <div
                                        onClick={() => {
                                            this.setState((state) => ({
                                                ...state,
                                                gridView: !state.gridView,
                                            }), () => {
                                                if (!this.state.gridView)
                                                    document.getElementById('table').scrollTop = 0
                                            })
                                        }}>
                                        <img src={this.state.gridView ? GridIcon : ListIcon} style={{ height: '22px', width: '22px', cursor: 'pointer', margin: '0px 5px' }} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: '25px', width: '100%' }}>
                                <div style={{ width: "99.5%", borderTop: "1px solid rgba(102, 102, 102, 0.2)" }} />
                            </div>
                            {this.state.gridView && !this.state.loading && this.state.gridData.length > 0 ?
                                <>
                                    <SortComponent
                                        fontSize={"13px"}
                                        label={'Name'}
                                        disabled={this.state.loading}
                                        sortAscending={this.sortByName}
                                        sortDecending={this.reverseByName}
                                        sortASC={true}
                                    />
                                </> :
                                <></>
                            }
                            {this.state.loading ?
                                <div style={{ position: "absolute", height: "46vh", width: "88%", display: "flex", alignItems: "center", paddingLeft: "10%", zIndex: 2 }}>
                                    <img src={Loader} style={{ height: "18vh", width: "15vw", margin: "auto" }} />
                                </div> :
                                <></>
                            }
                            <div id='table' style={{ overflow: 'auto', }}>
                                <div style={{ marginLeft: "5px" }}>
                                    {this.state.gridView ?
                                        this.state.gridData.length > 0 ?
                                            <InfiniteScroll
                                                className='scroll-container'
                                                height={"45vh"}
                                                dataLength={this.state.gridData.length} //This is important field to render the next data
                                                next={() => {
                                                    if (this.state.selectedAllItems == 'maps') {
                                                        this.getMaps(true)
                                                    }
                                                    else if (this.state.selectedAllItems == 'projects') {
                                                        this.getProjects(true)
                                                    }
                                                    else if (this.state.selectedAllItems == 'plans') {
                                                        this.getPlans(true)
                                                    }
                                                    else if (this.state.selectedAllItems == 'imagesets') {
                                                        this.getImageSets(true)
                                                    }
                                                }}
                                                hasMore={this.state.hasMoreItems}
                                                loader={listLoader}
                                            >
                                                <div className="cards-tray-projects" style={{ opacity: this.state.loading ? 0.5 : 1, paddingTop: "15px", gridGap: "30px", columnGap: "30px" }} >
                                                    {this.state.gridData.map((data, key) => {
                                                        return (
                                                            <div>
                                                                {data && data.sharedData ?
                                                                    <Link to={data.link_to}>
                                                                        {<div key={key} className="customer-cards-dashboard card" style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)' }}>
                                                                            <DeleteComponentGrid
                                                                                style={{ position: 'absolute', top: '5px', right: '5px' }}
                                                                                canManage={this.state.activeTeam.isOwner && this.state.selectedAllItems == 'projects' && !data.sharedData?.isShared}
                                                                                onClickManage={() => {
                                                                                    this.setState({ selectedMangeAccessProject: data.id, selectedProjectData: { pathname: data.link_to.pathname, state: { project: { ...data.link_to.state.project, link_to: undefined } } } })
                                                                                }}
                                                                                disableDelete={!this.state.activeTeam.isOwner}
                                                                                onClickDelete={(e) => {
                                                                                    e.preventDefault()
                                                                                    if (this.state.selectedAllItems == 'maps') {
                                                                                        this.setState((s) => ({
                                                                                            ...s,
                                                                                            selectedMapCollectionId: data.id,
                                                                                            itemName: "Map",
                                                                                            processPopupIcon: "WARNING",
                                                                                            processAction: "DELETE",
                                                                                            processMessage: "Are you sure you want to delete this Map ? ",
                                                                                            showProcessPopup: true,
                                                                                        }))
                                                                                    }
                                                                                    else if (this.state.selectedAllItems == 'projects') {
                                                                                        this.setState((state) => ({
                                                                                            ...state,
                                                                                            selectedProject: data,
                                                                                            deleteItemType: "project"
                                                                                        }), () => {
                                                                                            this.getAllDataToDelete()
                                                                                        })
                                                                                    }
                                                                                    else if (this.state.selectedAllItems == 'plans') {
                                                                                        this.setState((s) => ({
                                                                                            ...s,
                                                                                            selectedPlanId: data.id,
                                                                                            itemName: "Plan",
                                                                                            processPopupIcon: "WARNING",
                                                                                            processAction: "DELETE",
                                                                                            processMessage: null,
                                                                                            showProcessPopup: true,
                                                                                        }))
                                                                                    }
                                                                                    else if (this.state.selectedAllItems == 'imagesets') {
                                                                                        this.setState((state) => ({
                                                                                            ...state,
                                                                                            selectedCollectionId: data.id,
                                                                                            deleteItem: "IMAGESET",
                                                                                            itemName: "Imageset",
                                                                                            processPopupIcon: "WARNING",
                                                                                            processAction: "DELETE",
                                                                                            processMessage: "All data including orthomosaic images, tasks, annotations, gcp points, images will be deleted." +
                                                                                                "\n Are you sure you want to delete this Imageset ?",
                                                                                            showProcessPopup: true,
                                                                                        }))
                                                                                    }
                                                                                }}
                                                                            />

                                                                            <div className="card-header customer-cards-dashboard-logo project-card-header" style={{ height: data.type == "Project" ? "55px" : "70px" }}>
                                                                                {data && data.type == "Map" ? data.task_id ?
                                                                                    <div style={{ borderRadius: "6px", height: "111px", width: "100%", background: "black" }} >
                                                                                        <img style={{ objectFit: 'contain', height: "111px", width: "170px" }} loading="true" src={data.thumbURL} onError={(e) => { e.target.onerror = null; e.target.src = ImageSetThumb }} />
                                                                                    </div> :
                                                                                    <img id="ProjectImage" src={ImageSetThumb} /> :
                                                                                    data.type == "Plan" || data.type == "Project" ?
                                                                                        data.image ?
                                                                                            <img id="ProjectImage" style={{ objectFit: 'cover' }} loading="true" src={data.thumbURL} /> :
                                                                                            <img id="ProjectImage" src={PlanThumb} /> :
                                                                                        data.file && data.file.file_name ?
                                                                                            <img id="ProjectImage" style={{ objectFit: 'cover' }}
                                                                                                src={data.thumbURL}
                                                                                                onError={(e) => { e.target.onerror = null; e.target.src = ImageSetThumb }} /> :
                                                                                            <img id="ProjectImage" style={{ objectFit: 'cover' }}
                                                                                                src={ImageSetThumb} />
                                                                                }
                                                                            </div>
                                                                            {data.sharedData.isShared ?
                                                                                <div className='shared-badge'>
                                                                                    <img src={SharedIcon} style={{ height: "18px" }} />
                                                                                </div> :
                                                                                <></>
                                                                            }
                                                                            <div className="card-body change-color customer-cards-dashboard-text project-card-body">
                                                                                <div style={{ display: 'flex', height: "16px", alignItems: "center", justifyContent: 'space-between' }}>
                                                                                    <div style={{ overflow: "hidden" }}>
                                                                                        {this.state.selectedAllItems === "maps" && data.displayData[0] && data.displayData[0].length > 10 ?
                                                                                            `${data.displayData[0].substring(0, 10)}...` :
                                                                                            data.displayData[0] && data.displayData[0].length > 13 ?
                                                                                                `${data.displayData[0].substring(0, 13)}...` :
                                                                                                `${data.displayData[0]}`
                                                                                        }
                                                                                    </div>
                                                                                    <div style={{ overflow: "hidden" }}>
                                                                                        {data.displayData[1]}
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ fontSize: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                                                    <div style={{ overflow: 'hidden' }}>
                                                                                        {
                                                                                            data.displayData[2].length > 12 ?
                                                                                                `${data.displayData[2].substring(0, 12)}...` :
                                                                                                `${data.displayData[2]}`
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        {data.displayData[3]}
                                                                                    </div>
                                                                                </div>
                                                                                {data.type == "Project" ?
                                                                                    <div style={{ fontSize: '10px', width: "100%", marginTop: "2px" }}>
                                                                                        <img style={{ marginTop: '-2px', height: '12px', width: '12px', color: '#3c3c3c' }} src={LocationIcon} />
                                                                                        {data.displayData[4] ? data.displayData[4].length > 20 ? `${data.displayData[4].substring(0, 20)}...` : data.displayData[4] : '-'}
                                                                                    </div> : ''
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    </Link> : <></>}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </InfiniteScroll>
                                            : !this.state.loading ?
                                                <div className="rmp-dashboard-empty-data-message">
                                                    <img style={{ height: '10%', width: '10%' }} src={this.state.noContentImg} />
                                                    <p style={{ margin: '10px' }}>{this.state.noContentMsg}</p>
                                                </div> :
                                                <></>
                                        :
                                        <div>
                                            {this.state.gridData.length === 0 ?
                                                !this.state.loading ?
                                                    <div className="rmp-dashboard-empty-data-message">
                                                        <img style={{ height: '10%', width: '10%' }} src={this.state.noContentImg} />
                                                        <p style={{ margin: '10px' }}>{this.state.noContentMsg}</p>
                                                    </div> : <></> :
                                                <div style={{ marginTop: '10px' }}>
                                                    <InfiniteScroll
                                                        className='scroll-container'
                                                        height={"48vh"}
                                                        dataLength={this.state.gridData.length} //This is important field to render the next data
                                                        next={() => {
                                                            if (this.state.selectedAllItems == 'maps') {
                                                                this.getMaps(true)
                                                            }
                                                            else if (this.state.selectedAllItems == 'projects') {
                                                                this.getProjects(true)
                                                            }
                                                            else if (this.state.selectedAllItems == 'plans') {
                                                                this.getPlans(true)
                                                            } else if (this.state.selectedAllItems == 'imagesets') {
                                                                this.getImageSets(true)
                                                            }
                                                        }}
                                                        hasMore={this.state.hasMoreItems}
                                                        loader={listLoader}
                                                    >
                                                        <table className="customers-table scroll-container" style={{ opacity: this.state.loading ? 0.5 : 1, marginTop: '0px' }}>
                                                            {this.state.gridData[0].type == 'Project' ? <>
                                                                <col style={{ width: '5%' }} />
                                                                <col style={{ width: '30%' }} />
                                                                <col style={{ width: '15%' }} />
                                                                <col style={{ width: '20%' }} />
                                                                <col style={{ width: '25%' }} />
                                                                <col style={{ width: '5%' }} /></>
                                                                : this.state.gridData[0].type == 'Plan' ?
                                                                    <>
                                                                        <col style={{ width: "5%" }} />
                                                                        <col style={{ width: '40%' }} />
                                                                        <col style={{ width: '25%' }} />
                                                                        <col style={{ width: '30%' }} />
                                                                        <col style={{ width: '5%' }} />
                                                                    </>
                                                                    : this.state.gridData[0].type == 'Imageset' ?
                                                                        <>
                                                                            <col style={{ width: '5%' }} />
                                                                            <col style={{ width: '30%' }} />
                                                                            <col style={{ width: '12%' }} />
                                                                            <col style={{ width: '30%' }} />
                                                                            <col style={{ width: '18%' }} />
                                                                            <col style={{ width: '5%' }} />
                                                                        </> : this.state.gridData[0].type == 'Map' ?
                                                                            <>
                                                                                <col style={{ width: '5%' }} />
                                                                                <col style={{ width: '35%' }} />
                                                                                <col style={{ width: '20%' }} />
                                                                                <col style={{ width: '20%' }} />
                                                                                <col style={{ width: '25%' }} /></> :
                                                                            <></>}
                                                            <tr className="customers-table-header-row">
                                                                {this.state.tableHeaders.map((hd) => {
                                                                    return <th>
                                                                        {hd}
                                                                        {hd == "Name" ?
                                                                            <SortComponent
                                                                                style={{ marginLeft: '5px' }}
                                                                                sortAscending={this.sortByName}
                                                                                sortDecending={this.reverseByName}
                                                                                sortASC={false}
                                                                            /> : hd == 'Date Created' || hd == 'Processing Date' ?
                                                                                <SortComponent
                                                                                    style={{ marginLeft: '5px' }}
                                                                                    sortAscending={this.sortByDate}
                                                                                    sortDecending={this.reverseByDate}
                                                                                    sortASC={false}
                                                                                /> : ''
                                                                        }
                                                                    </th>
                                                                })}
                                                            </tr>
                                                            {
                                                                this.state.gridData.map((data, key) =>
                                                                    <>
                                                                        <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                                            <td>
                                                                                {data && data.thumbURL ?
                                                                                    <img id="ProjectTableImage" style={{ objectFit: 'cover', padding: 'unset' }} loading="true" src={data.thumbURL} onError={(e) => { e.target.onerror = null; e.target.style = { undefined }; e.target.src = ProjectThumb }} /> :
                                                                                    <img id="ProjectTableImage" src={ProjectThumb} />
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <Link
                                                                                    style={{ color: "#707683" }}
                                                                                    to={data.link_to}
                                                                                >
                                                                                    {getLimitedName(data.displayData[0], 30)}
                                                                                    <span style={{ color: "#2989cf" }}>
                                                                                        {data.sharedData && data.sharedData.isShared ? ' (Shared)' : ''}
                                                                                    </span>
                                                                                    {data.role_name && <RoleNameComponent roleName={data.role_name} />}
                                                                                </Link>

                                                                            </td>
                                                                            {data.displayData[1] ? <td>{data.displayData[1]}</td> : ''}
                                                                            {data.displayData[2] ? <td>{this.state.gridData[0].type == 'Project' ? data.images : data.displayData[2]}</td> : <td>-</td>}
                                                                            {data.displayData[3] ? <td>{data.displayData[3]}</td> : ''}
                                                                            {data.type == 'Project' ? <td>{data.displayData[4] ? data.displayData[4].length > 20 ? `${data.displayData[4].substring(0, 20)}..` : data.displayData[4] : ''}</td> : ''}
                                                                            {this.state.gridData[0].type != 'Task' && this.state.gridData[0].type != 'Annotation Set' ?
                                                                                <td>
                                                                                    <DeleteComponentTable
                                                                                        style={{ marginRight: '10px' }}
                                                                                        canManage={this.state.activeTeam.isOwner && this.state.selectedAllItems == 'projects' && !data.sharedData?.isShared}
                                                                                        disableDelete={!this.state.activeTeam.isOwner}
                                                                                        onClickManage={() => {
                                                                                            this.setState({ selectedMangeAccessProject: data.id, selectedProjectData: { pathname: data.link_to.pathname, state: { project: { ...data.link_to.state.project, link_to: undefined } } } })
                                                                                        }}
                                                                                        onClickDelete={(e) => {
                                                                                            e.preventDefault()
                                                                                            if (this.state.selectedAllItems == 'maps') {
                                                                                                this.setState((s) => ({
                                                                                                    ...s,
                                                                                                    selectedMapCollectionId: data.id,
                                                                                                    itemName: "Map",
                                                                                                    processPopupIcon: "WARNING",
                                                                                                    processAction: "DELETE",
                                                                                                    processMessage: "Are you sure you want to delete this Map ? ",
                                                                                                    showProcessPopup: true,
                                                                                                }))
                                                                                            }
                                                                                            else if (this.state.selectedAllItems == 'projects') {
                                                                                                this.setState((state) => ({
                                                                                                    ...state,
                                                                                                    selectedProject: data,
                                                                                                    deleteItemType: "project"
                                                                                                }), () => {
                                                                                                    this.getAllDataToDelete()
                                                                                                })
                                                                                            }
                                                                                            else if (this.state.selectedAllItems == 'plans') {
                                                                                                this.setState((s) => ({
                                                                                                    ...s,
                                                                                                    selectedPlanId: data.id,
                                                                                                    itemName: "Plan",
                                                                                                    processPopupIcon: "WARNING",
                                                                                                    processAction: "DELETE",
                                                                                                    processMessage: null,
                                                                                                    showProcessPopup: true,
                                                                                                }))
                                                                                            }
                                                                                            else if (this.state.selectedAllItems == 'imagesets') {
                                                                                                this.setState((state) => ({
                                                                                                    ...state,
                                                                                                    selectedCollectionId: data.id,
                                                                                                    deleteItem: "IMAGESET",
                                                                                                    itemName: "Imageset",
                                                                                                    processPopupIcon: "WARNING",
                                                                                                    processAction: "DELETE",
                                                                                                    processMessage: "All data including orthomosaic images, tasks, annotations, gcp points, images will be deleted." +
                                                                                                        "\n Are you sure you want to delete this Imageset ?",
                                                                                                    showProcessPopup: true,
                                                                                                }))
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </td> : ''}
                                                                        </tr>
                                                                    </>
                                                                )}
                                                        </table>
                                                    </InfiniteScroll>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>}
                </div>
            </div > : <div />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload)),
        activesubscription: (payload) => dispatch(activesubscription(payload))
    };
};

const mapStateToProps = state => {
    return {
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        SubscriptionData: state.subscription.subscriptionData,
        activesubscription: state.activesubscription.activesubscription,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
        firstTimeLoginDN: state.profile.firstTimeLoginDN,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AeroCaptureDashboard);
