import React, { useEffect, useState } from 'react'
import * as API from "../api"
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Pagination from './Pagination'
import dateFormat from 'dateformat';

const CreditsInvoicesTable = (props) => {
    const PAGE_SIZE = 6
    const [creditsInvoices, setCreditsInvoices] = useState([])
    const [linkData, setLinkData] = useState({})
    const [totalCount, setTotalCount] = useState(7)
    const [pageNumber, setPageNumber] = useState(1)
    const getUserCreditInvoices = (pageNumber) => {
        API.getUserCreditInvoices(PAGE_SIZE, pageNumber).then(creditInvoices => {
            setCreditsInvoices(creditInvoices.invoices)
            setTotalCount(creditInvoices.totalCount)
        }).catch(err => {
            console.log(err)
        })
    }
    useEffect(() => {
        API.getUserType().then(([blah, type, user]) => {
            if (!user.sub) {
                user.sub = {
                    "plan": "aeromegh_plan_free"
                }
            }
            setLinkData({ user, type })
        })
        if (creditsInvoices.length > 0)
            getUserCreditInvoices(pageNumber)
    }, [])
    return (

        <div className="rpas-model-info-bottom-section" style={{ padding: "0px 70px 50px 70px", position: "relative", height: "100%" }}>
            <div style={{ fontSize: "15px", fontWeight: "500" }}>Tasks credits</div>
            <table className="customers-table">
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <tr className="customers-table-header-row">
                    <th>
                        Invoice Number
                    </th>
                    <th>
                        Product
                    </th>
                    <th>
                        Amount
                    </th>
                    <th>
                        Date & Time
                    </th>
                    <th>
                        Status
                    </th>
                </tr>
                {creditsInvoices.length === 0 ?
                    <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                        <td colspan="5">No invoices available</td>
                    </tr> :
                    creditsInvoices.map((invoice, i) => <tr className="customers-table-data-row">
                        <td>
                            <Link to={{
                                pathname: "/invoice/" + invoice.invoice_id,
                                state: { ...linkData, invoice }
                            }} style={{
                                color: "#707683"
                            }}>
                                {invoice.invoice_id}
                            </Link>
                        </td>
                        <td>
                            {invoice.name}
                        </td>
                        <td>
                            {invoice?.currency === "inr" ? "₹" : invoice?.currency === "usd" ? "$" : "₹"}{(invoice.price - ((invoice.price) * invoice.discount / 100)) * Number(invoice.units)}
                        </td>
                        <td>
                            {dateFormat(invoice.created_time, 'dd mmm yyyy hh:MM:ss TT')}
                        </td>
                        <td>
                            {invoice.payment_status == "paid" ? <div style={{ color: "green" }}>Paid</div> : <div style={{ color: "red" }}>Pending</div>}
                        </td>
                    </tr>)}
            </table>
            <div style={{ padding: "10px 0px", position: "absolute", bottom: "0", right: "65px" }}>

                <Pagination
                    totalCount={totalCount}
                    paginationCallback={(currPageNo) => {
                        setPageNumber(currPageNo)
                        getUserCreditInvoices(currPageNo)

                    }}
                />
            </div>
        </div>
    )
}

export default CreditsInvoicesTable