import React, { useEffect, useState } from 'react'
import DropDownWithButton from '../../ReusableComponents/DropDownWithButton'
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon'
import { checkSpecialCharacterInclude } from '../../ReusableComponents/reusableFunctions'

const FloorAdder = (props) => {
  const [allFloorsUsed, setAllFloorsused] = useState(false)
  const [newFloor, setNewFloor] = useState({ name: "", number: "" })
  const [floorsList, setFloorsList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
  const [selectedFloor, setSelectedFloor] = useState()
  const [wrongFloorNameError, setWrongFloorNameError] = useState(false)
  const [isInputFieldDisabled, setIsInputFieldDisabled] = useState(false);

  useEffect(() => {
    setFloorsList(floorsList.filter((flr) => props?.floors?.filter(floor => floor.number == flr).length === 0))
  }, [])
  useEffect(() => {
    if (floorsList.length == 0) setAllFloorsused(true)
    setSelectedFloor(floorsList[0])
    setNewFloor({ ...newFloor, number: floorsList[0] })
  }, [floorsList])
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", position: "relative" }}>
      <div style={{ color: "#2989cf", textAlign: "left", backgroundColor: "#D9D9D9", padding: "10px 20px", fontWeight: "500", fontSize: "17px" }}>Add floor</div>
      {allFloorsUsed ?
        <div style={{ position: "absolute", transform: "translate(-50%,-50%)", left: "50%", top: "50%", color: "#2989cf" }}>Maximum floors added.</div> :
        <>
          <div style={{ height: "100%", display: "flex", justifyContent: "center", color: "#666666", fontSize: "13px", padding: "20px 60px", columnGap: "90px" }}>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "start", width: "100%" }}>
              <div style={{ marginBottom: "10px" }}>Name</div>
              <input disabled={isInputFieldDisabled} maxLength={20} style={{ height: "27px", borderRadius: "5px", padding: "0px 10px", width: "100%", border: "1px solid #b4b4bb", outline: "none" }} type="text" value={newFloor.name} placeholder="Floor name" onChange={(e) => {
                checkSpecialCharacterInclude(e.target.value).then(correctName => {
                  setWrongFloorNameError(false)
                  setNewFloor({ ...newFloor, name: correctName })
                }).catch(err => {
                  setWrongFloorNameError(true)
                })
              }

              } />
              {wrongFloorNameError && <div style={{ color: "red", fontSize: "10px", textAlign: "left" }}>Special characters are not allowed.</div>}
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "start", width: "100%" }}>
              <div style={{ marginBottom: "10px" }}>Number</div>
              <DropDownWithButton textColor={"rgb(102, 102, 102)"} maxHeight={"100px"} padding={"5px 10px"} isBorder height={"25px"} width={"100%"} selectedItem={selectedFloor} list={floorsList.map((floor) => {

                return {
                  name: floor, onClick: () => {
                    setSelectedFloor(floor)
                    setNewFloor(prev => {
                      return { ...prev, number: floor }
                    })
                  }
                }
              })} />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "right", padding: "20px" }}>

            <div style={{ marginRight: "10px" }}><ButtonWithIcon text={"Cancel"} onClick={props.closePopUp} /></div>
            <ButtonWithIcon isDisabled={!(newFloor.name && newFloor.number) || wrongFloorNameError} padding={"4px 22px"} text={"Save"} onClick={() => {
              setIsInputFieldDisabled(true);
              if(!isInputFieldDisabled){
                props.closePopUp();
                props.addFloor(newFloor)
              }
            }} isBtnActive />
          </div>
        </>}


    </div>
  )
}

export default FloorAdder