import React, { useEffect, useState } from "react";
import * as API from '../../../api.js';
import { BLOB_URL } from "../../../AppConstants.js";
import PlanThumb from '../../../icons/PlanThumb.png'
import dateFormat from "dateformat";
import LoaderComponent from "../../../ReusableComponents/LoaderComponent.js";
import Noimageset from '../../../assets/Images/Noimageset.png';

const DroneNakshaMapsImporter = (props) => {
    const [maps, setMaps] = useState([])
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        getAllMaps()
    }, [])

    const getAllMaps = () => {
        API.getAllMaps(" ", 0, 100000000, "user").then(async (data) => {
            console.log(data.data)
            setLoader(false)
            setMaps(data.data)
        }, (e) => {
            console.log(e);
        })
    }

    return <>
        {loader ? <LoaderComponent
            width="98%"
            height="85%"
            message="Loading DroneNaksha maps, please wait..."
        /> :
            <>
                {/* {props.showBackButton ? <div style={{ padding: "10px 15px", height: "46px" }}>
                    <button
                        className="add-user-form-submit-button"
                        onClick={() => props.onClickBack()}
                    >
                        Back
                    </button>
                </div> : <></>} */}
                <div style={{ height: props.height || (props.showBackButton ? "calc(100% - 46px)" : "100%"), width: props.width || "100%", overflow: "auto" }}>
                    {(maps && maps.length > 0) ?
                        <div style={{ width: "100%", padding: props.padding || "10px", display: "grid", gridTemplateColumns: `repeat(auto-fill, minmax(${props.cardHeight}, ${props.cardWidth}))`, rowGap: props.cardGap || "25px", columnGap: props.cardGap || "25px" }}>
                            {maps.map((map, key) => {
                                return (
                                    <div
                                        onClick={() => props.onSelectMaps([map])}
                                        key={key}
                                        style={{
                                            height: props.cardHeight || "150px", width: props.cardWidth || "225px", borderRadius: "10px",
                                            boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)',
                                            cursor: "pointer"
                                        }}>
                                        <div style={{ borderRadius: "6px", height: props.cardHeight || "150px", width: props.cardWidth || "225px" }}>
                                            <img style={{ objectFit: 'contain', height: props.cardHeight || "150px", width: props.cardWidth || "225px" }} loading="true" src={`${BLOB_URL}/${props.permissions.container}/orthomosaicImages/${map.task_id}/orthomosaicThumb.png?${props.permissions.st}`} onError={(e) => { e.currentTarget.src = PlanThumb; }} />
                                        </div>
                                        <div style={{ position: "relative", bottom: "47px", width: props.cardWidth || "225x" }} className="card-body change-color customer-cards-dashboard-text-map project-card-body">
                                            {/* {map.sharedData.isShared ? <div className='shared-badge' style={{ position: "absolute", top: "-136px" }}>
                                <img src={SharedIcon} style={{ height: "18px" }} />
                            </div> : <></>} */}
                                            <div style={{ fontSize: '13px', display: 'flex', height: "20px", alignItems: "center", justifyContent: 'space-between' }}>
                                                <div style={{ overflow: "hidden", }}>
                                                    {
                                                        map.collection_name.length > 18 ?
                                                            `${map.collection_name.substring(0, 17)}...` :
                                                            `${map.collection_name}`
                                                    }
                                                </div>
                                                {/* <div style={{ overflow: "hidden" }}>{convertToGB(map.img_size)}</div> */}
                                            </div>
                                            {/* <div style={{ fontSize: '10px', display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                <div style={{ overflow: "hidden" }}>{`${map.gsd.indexOf('NaN') > -1 ? '-' : map.gsd}`}</div>
                                <div style={{ overflow: "hidden" }}>{`${map.images_used} Images`}</div>
                            </div> */}
                                            <div style={{ marginTop: "2px", fontSize: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                {/* <div style={{ overflow: 'hidden' }}>{map.area}</div> */}
                                                <div> {
                                                    (() => {
                                                        let date = (new Date(map.processing_date)).toLocaleDateString('en-US')
                                                        date = dateFormat(date, "dd-mmm-yyyy")
                                                        return date
                                                    })()
                                                }</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                        : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                            <div className="rmp-dashboard-empty-data-message" style={{ width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ height: '200px', width: '200px' }} src={Noimageset} />
                                <p style={{ marginTop: '10px' }}>No maps available</p>
                            </div>
                        </div>
                    }

                </div>
                {/* <div style={{ padding: "10px 15px", height: "45px", width:'100%', display:'flex', justifyContent:'space-between' }}>
                    <button
                        className="add-user-form-submit-button"
                        onClick={() => props.onClickBack()}
                    >
                        Back
                    </button>
                    <button
                        className="add-user-form-submit-button"
                        onClick={() => props.onClickBack()}
                    >
                        Next
                    </button>
                </div> */}
            </>
        }
    </>

}

export default DroneNakshaMapsImporter