import React, { useEffect, useState } from "react";
import { getRandomHexColour, triggerBlobDownload } from "../../../ReusableComponents/reusableFunctions";
import { FILETYPES, DEFAULTCLASSNAME } from "../MapAnnotation/Constants"
import Papa from "papaparse";
import { v4 as uuid } from "uuid";
import DropDownWithButton from "../../../ReusableComponents/DropDownWithButton";
import { ActivityPopup } from "../../../ReusableComponents/ActivityPopup";
const { convertXML, createAST } = require("simple-xml-to-json"); //npm i simple-xml-to-json

const ImportExportImgRegion = ({ imageData, regions, width, exportDisable, importDisable, importRegions, classes, addSeverityData }) => {
    {/* --------------- All States -----------------------------------*/ }
    const [showProcessPopup, setShowProcessPopup] = useState(false);
    const [processMessage, setProcessMessage] = useState("");
    const [processPopupIcon, setProcessPopupIcon] = useState("");
    const [fileType, setFileType] = useState(undefined);
    const [disableImportExport, setDisableImportExport] = useState(false)
    {/*------------------------------------------------------*/ }



    {/* ------------------- Loader Function ---------------- */ }
    let showLoader = (event, msg, icon) => {
        if (event) {
            setProcessMessage(msg);
            setProcessPopupIcon(icon);
            setShowProcessPopup(true);
        }
        else {
            setShowProcessPopup(false);
        }
    }

    // function to download file 
    const exportAnnotations = async (file, filename) => {
        if (regions.length) {
            // create file in browser
            if (typeof file === 'string') {
                const blob = new Blob([file], { type: "application/json" });
                triggerBlobDownload(blob, filename)
                showLoader(false);
            }
            else {
                const strFile = JSON.stringify(file, null, 2);
                const blob = new Blob([strFile], { type: "application/json" });
                triggerBlobDownload(blob, filename)
                showLoader(false);
            }
        } else {
            showLoader(false)
        }
    }

    // Files : JSON, VGG, Labelme, XML, CSV, TXT
    {/*------ import Functions and there region functions -------------*/ }
    const importTXT = () => {
        setFileType("txt");
        const fileImporter = document.getElementById("fileImporter")
        fileImporter.setAttribute('accept', FILETYPES.TXT);
        fileImporter.click()
    }

    const txtRegion = (textformat) => {
        let jsonRegions = [];
        let jsonClasses = [];
        textformat = textformat.split("\n");
        textformat = [...textformat.slice(0, textformat.length - 1)];
        textformat.map((el) => {
            el = el.split(" ");
            const className = `class_${Number(el[0])}` || DEFAULTCLASSNAME
            let existingClass = classes.find(oldCls => oldCls.name === className)
                || jsonClasses.find(oldCls => oldCls.name === className)
            if (existingClass) {
                existingClass.totalCount=(existingClass?.totalCount||0)+1;
            }
            const classWithColor = existingClass || {
                name: className,
                color: getRandomHexColour(),
                totalCount: 1
            }
            if (!(existingClass)) jsonClasses.push(classWithColor)
            jsonRegions.push({
                data: addSeverityData ? {
                    datatype: classWithColor.name,
                    percentage: 100
                } : undefined,
                id: uuid(),
                className: classWithColor.name,
                color: classWithColor.color,
                type: 'box',
                name: `Rect ${(parseInt(regions.filter(reg => reg.type === 'box').sort((a, b) => {
                    var numA = parseInt(a.name.match(/\d+$/)[0]);
                    var numB = parseInt(b.name.match(/\d+$/)[0]);
                    return numB - numA;
                })[0]?.name.split(" ")[1]) || 0) + 1}`,
                x: Number(el[1]) - Number(el[3]) / 2,
                y: Number(el[2]) - Number(el[4]) / 2,
                w: Number(el[3]),
                h: Number(el[4]),
            });
        });
        showLoader(false);
        importRegions(jsonRegions, jsonClasses)
    };

    const importCSV = () => {
        setFileType("csv");
        const fileImporter = document.getElementById("fileImporter")
        fileImporter.setAttribute('accept', FILETYPES.CVS);
        fileImporter.click()
    }

    const csvRegion = (jsonformat) => {
        const jsonData = Papa.parse(jsonformat, { header: true }).data;
        let jsonRegions = [];
        let jsonClasses = [];
        jsonData.map((el, i, rows) => {
            if (el.region_shape_attributes) {
                const className = JSON.parse(el.region_attributes).objects || DEFAULTCLASSNAME
                const existingClass = classes.find(oldCls => oldCls.name === className)
                    || jsonClasses.find(oldCls => oldCls.name === className)
                if (existingClass) {
                    existingClass.totalCount=(existingClass?.totalCount||0)+1;
                }
                const classWithColor = existingClass || {
                    totalCount: 1,
                    name: className,
                    color: getRandomHexColour()
                }
                if (!(existingClass)) jsonClasses.push(classWithColor)
                const region = JSON.parse(el.region_shape_attributes)

                jsonRegions.push({
                    data: addSeverityData ? {
                        datatype: classWithColor.name,
                        percentage: 100
                    } : undefined,
                    id: uuid(),
                    type: 'box',
                    name: `Rect ${(parseInt(regions.filter(reg => reg.type === 'box').sort((a, b) => {
                        var numA = parseInt(a.name.match(/\d+$/)[0]);
                        var numB = parseInt(b.name.match(/\d+$/)[0]);
                        return numB - numA;
                    })[0]?.name.split(" ")[1]) || 0) + 1}`,
                    color: classWithColor.color,
                    className: classWithColor.name,
                    x: region.x / imageData.width,
                    y: region.y / imageData.height,
                    h: region.height / imageData.height,
                    w: region.width / imageData.width
                });
            }
        });
        showLoader(false);
        importRegions(jsonRegions, jsonClasses)
    }

    const importXML = () => {
        setFileType("xml");
        const fileImporter = document.getElementById("fileImporter")
        fileImporter.setAttribute('accept', FILETYPES.XML);
        fileImporter.click()
    }

    const xmlRegion = (xmlString) => {
        let jsonRegions = [];
        let jsonClasses = [];
        const myJson = convertXML(xmlString);
        myJson.annotation.children.map((child) => {
            if (Object.keys(child)[0] === "object") {
                const className = child.object.children[0].name.content || DEFAULTCLASSNAME
                const existingClass = classes.find(oldCls => oldCls.name === className)
                    || jsonClasses.find(oldCls => oldCls.name === className)
                    if(existingClass){
                        existingClass.totalCount=(existingClass?.totalCount||0)+1;
                    }
                const classWithColor = existingClass || {
                    totalCount:1,
                    name: className,
                    color: getRandomHexColour()
                }
                if (!(existingClass)) jsonClasses.push(classWithColor)

                jsonRegions.push({
                    data: addSeverityData ? {
                        datatype: classWithColor.name,
                        percentage: 100
                    } : undefined,
                    id: uuid(),
                    color: classWithColor.color,
                    type: "box",
                    name: `Rect ${(parseInt(regions.filter(reg => reg.type === 'box').sort((a, b) => {
                        var numA = parseInt(a.name.match(/\d+$/)[0]);
                        var numB = parseInt(b.name.match(/\d+$/)[0]);
                        return numB - numA;
                    })[0]?.name.split(" ")[1]) || 0) + 1}`,
                    className: classWithColor.name,
                    x: Number(child.object.children[4].bndbox.children[0].xmin.content) / imageData.width,
                    y: Number(child.object.children[4].bndbox.children[1].ymin.content) / imageData.height,
                    h:
                        (Number(child.object.children[4].bndbox.children[3].ymax.content) -
                            Number(child.object.children[4].bndbox.children[1].ymin.content)) / imageData.height,
                    w:
                        (Number(child.object.children[4].bndbox.children[2].xmax.content) -
                            Number(child.object.children[4].bndbox.children[0].xmin.content)) / imageData.width,
                });
            }
        });
        showLoader(false);
        importRegions(jsonRegions, jsonClasses)
    };

    const importVGG = () => {
        setFileType("vgg");
        const fileImporter = document.getElementById("fileImporter")
        fileImporter.setAttribute('accept', FILETYPES.VGG);
        fileImporter.click()
    }

    const vggRegion = (vggformat) => {
        vggformat = JSON.parse(vggformat);
        let jsonRegions = [];
        let jsonClasses = [];
        const region = Object.values(Object.values(vggformat)[0].regions);
        region.map((reg) => {
            const existingClass = classes.find(oldCls => oldCls.name === reg.region_attributes.objects)
                || jsonClasses.find(oldCls => oldCls.name === reg.region_attributes.objects)
                if(existingClass){
                    existingClass.totalCount=(existingClass?.totalCount||0)+1;
                }
                const classWithColor = existingClass || {
                name: reg.region_attributes.objects,
                color: getRandomHexColour(),
                totalCount:1

            }
            if (!(existingClass)) jsonClasses.push(classWithColor)

            jsonRegions.push({
                data: addSeverityData ? {
                    datatype: classWithColor.name,
                    percentage: 100
                } : undefined,
                id: uuid(),
                color: classWithColor.color,
                type: 'box',
                name: `Rect ${(parseInt(regions.filter(reg => reg.type === 'box').sort((a, b) => {
                    var numA = parseInt(a.name.match(/\d+$/)[0]);
                    var numB = parseInt(b.name.match(/\d+$/)[0]);
                    return numB - numA;
                })[0]?.name.split(" ")[1]) || 0) + 1}`,
                className: classWithColor.name,
                x: Number(reg.shape_attributes.x) / imageData.width,
                y: Number(reg.shape_attributes.y) / imageData.height,
                h: Number(reg.shape_attributes.height) / imageData.height,
                w: Number(reg.shape_attributes.width) / imageData.width,
            });
        });
        showLoader(false);
        importRegions(jsonRegions, jsonClasses)
    };

    {/*------ Export Functions ---------------------------*/ }
    const exportTXT = () => {
        showLoader(true, "Exporting TXT File ...", "WAIT");
        let text = "";
        const arrOfRegionClassName = [];
        regions.forEach((ele) => {
            arrOfRegionClassName.push(ele.className);
        });
        const sortedArr = Array.from(new Set(arrOfRegionClassName));
        regions.forEach((el) => {
            text += `${sortedArr.indexOf(el.className)} ${(
                (el.x + el.w / 2)
            ).toFixed(6)} ${((el.y + el.h / 2)).toFixed(6)} ${(
                el.w
            ).toFixed(6)} ${(el.h).toFixed(6)} \n`;
        });
        exportAnnotations(text, `${(imageData.imageName.replace(/.png|.jpg/gi, ''))}.txt`);
    };

    const exportCSV = () => {
        showLoader(true, "Exporting CSV File ...", "WAIT");
        let csv = '';
        csv += `#filename,file_size,file_attributes,region_count,region_id,region_shape_attributes,region_attributes\n`;
        regions.map((element, idx, rows) => {
            csv += `${imageData.imageName},`;
            csv += `${(imageData.imageSize) * 1024 * 1024},`;
            csv += `"{}",`;
            csv += regions.length + ",";
            csv += idx + ",";
            csv += `"{"`;
            // csv += `"name"":""${element.className}_${idx}"","`;
            csv += `"name"":""rect"","`;
            csv += `"x"":${element.x * imageData.width},"`;
            csv += `"y"":${element.y * imageData.height},"`;
            csv += `"width"":${element.w * imageData.width},"`;
            csv += `"height"":${element.h * imageData.height}`;
            csv += `}",`;
            csv += `"{"`;
            csv += `"objects"":""${element.className}"`;
            csv += `"}"`;
            if (!(idx + 1 === rows.length)) {
                csv += '\n';
            }
        });
        exportAnnotations(csv, `${imageData.imageName.replace(/.png|.jpg/gi, '')}.csv`);
    };

    const exportXML = () => {
        showLoader(true, "Exporting XML File ...", "WAIT");
        let xml = "";
        xml += `<annotation>
                      <folder>${imageData.imageName}</folder>
                      <filename>${imageData.imageName}</filename>
                      <path>/${imageData.imageName}</path>
                      <source>
                       <database>Unknown</database>
                     </source>
                      <size>
                       <width>${imageData.width}</width>
                        <height>${imageData.height}</height>
                        <depth>3</depth>
                      </size>
                      <segmented>0</segmented>
                    `;
        regions.forEach((element) => {
            xml += `<object>
                            <name>${element.className}</name>
                            <pose>Unspecified</pose>
                            <truncated>0</truncated>
                            <difficult>0</difficult>
                            <bndbox>
                              <xmin>${element.x * imageData.width}</xmin>
                              <ymin>${element.y * imageData.height}</ymin>
                              <xmax>${(element.x + element.w) * imageData.width}</xmax>
                              <ymax>${(element.y + element.h) * imageData.height}</ymax>
                            </bndbox>
                        </object>`;
        });
        xml += `</annotation >`;
        exportAnnotations(xml, `${(imageData.imageName.replace(/.png|.jpg/gi, ''))}.xml`);
    };

    const exportLabelme = () => {
        showLoader(true, "Exporting Labelme File ...", "WAIT");
        const labelMe = {
            version: "4.5.9",
            flags: {},
            shapes: [
                ...regions.map((element) => {
                    return {
                        label: element.className,
                        points: [
                            [element.x * imageData.width, element.y * imageData.height],
                            [(element.x + element.w) * imageData.width, element.y * imageData.height],
                            [(element.x + element.w) * imageData.width, (element.y + element.h) * imageData.height],
                            [element.x * imageData.width, (element.y + element.h) * imageData.height],
                        ],
                        group_id: null,
                        shape_type: "polygon",
                        flags: {},
                    };
                }),
            ],
        };
        exportAnnotations(labelMe, `${(imageData.imageName.replace(/.png|.jpg/gi, ''))}.json`);
    };

    const exportVGG = () => {
        showLoader(true, "Exporting VGG File ...", "WAIT");
        let vggData = {
            [imageData.imageName]: {
                fileRef: "",
                size: imageData.imageSize,
                filename: imageData.imageName,
                base64_img_data: "",
                file_attributes: {},
                regions: {
                    ...regions.map((reg, key) => {
                        return {
                            shape_attributes: {
                                name: `${reg.className}_${key}`,
                                x: reg.x * imageData.width,
                                y: reg.y * imageData.height,
                                width: reg.w * imageData.width,
                                height: reg.h * imageData.height,
                            },
                            region_attributes: {
                                objects: reg.className,
                            },
                        };
                    }),
                },
            },
        };
        exportAnnotations(vggData, `${(imageData.imageName.replace(/.png|.jpg/gi, ''))}.json`);
    };

    {/* ---------- List of Import Files----------------------*/ }
    const ImportFileList = [
        {
            name: "VGG",
            onClick: importVGG
        },
        {
            name: "XML",
            onClick: importXML
        },
        {
            name: "CSV",
            onClick: importCSV
        },
        {
            name: "TXT",
            onClick: importTXT
        }
    ]
    {/*------------------------------------------------------*/ }

    {/* ----------- List of Export files --------------------*/ }
    const ExportFileList = [
        {
            name: "VGG",
            onClick: exportVGG
        },
        {
            name: "Labelme",
            onClick: exportLabelme
        },
        {
            name: "XML",
            onClick: exportXML
        },
        {
            name: "CSV",
            onClick: exportCSV
        },
        {
            name: "TXT",
            onClick: exportTXT
        }
    ]
    {/*------------------------------------------------------*/ }

    const handleFileUpload = (event) => {
        if (event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader()
                reader.onload = (event) => {
                    const data = event.target.result;
                    if (file.type === FILETYPES.CVS && fileType === 'csv') {
                        showLoader(true, "Importing CSV File ...", "WAIT");
                        csvRegion(data);
                    }
                    else if (file.type === FILETYPES.JSON && fileType === 'vgg') {
                        if ((Object.keys(JSON.parse(data))[0]).match(/\.(jpg|jpeg|png|gif)$/i)) {
                            showLoader(true, "Importing VGG File ...", "WAIT");
                            vggRegion(data);
                        }
                        else showLoader(true, "Invalid File Format !", "ERROR")
                    }
                    else if (file.type === FILETYPES.XML && fileType === 'xml') {
                        showLoader(true, "Importing XML File ...", "WAIT");
                        xmlRegion(data);
                    }
                    else if (file.type === FILETYPES.TXT && fileType === 'txt') {
                        showLoader(true, "Importing TXT File ...", "WAIT");
                        txtRegion(data);
                    }
                    else showLoader(true, "Invalid File Format !", "ERROR")
                };
                reader.readAsText(file)
            }
        }
        event.target.value = ""
    };

    return <div style={{ display: "flex", justifyContent: "space-between" }} >
        <input
            id='fileImporter'
            style={{ display: 'none' }}
            type="file"
            onChange={handleFileUpload}
        />

        {/* -------------- Activity Popup ----------------------- */}
        <ActivityPopup
            open={showProcessPopup}
            icon={processPopupIcon}
            msg={processMessage}
            close={() => showLoader(false)}
        />
        {/* --------------------------------------------------------------- */}

        <DropDownWithButton
            selectedItem="Import" isButton width={width ? width : "90px"} isDisabled={importDisable}
            list={ImportFileList}
        />
        <DropDownWithButton
            selectedItem="Export" isButton width={width ? width : "90px"} isDisabled={exportDisable || regions?.some(reg => reg.type === "polygon" || reg.type === "circle" || reg.editable)}
            list={ExportFileList}
        />
    </div >
}

export default ImportExportImgRegion