import React, { useEffect, useState } from 'react';
import { tilesServer } from '../../AppConstants';

const CropOrthoImage = ({ polygon, imageName, collectionId, permissions }) => {
  return (
    <div>
      <img
        height={350}
        width={350}
        src={`${tilesServer}/getDetectionPng/${collectionId}/${imageName}?blobContainer=${permissions?.container}&sas=${encodeURIComponent(permissions?.st)}&polygon=${JSON.stringify(polygon.map(p => [p[1], p[0]]))}`}></img>
    </div>
  );
};

export default CropOrthoImage;
