import React, { Component } from 'react';
import * as API from './api.js';

import CustomersInactive from './customers.png';
import Loader from './icons/loader.svg';
import UsersActive from './usersActive.png';
import UsersStats from './res/stats.png'
import Search from "./Icon/Search.png";
import service from "./sidebaricon/Services.png";
import dateFormat from 'dateformat';
import { Link, StaticRouter, Redirect } from 'react-router-dom';
import SideBar from './SideBar';
import install from './icons/AeroGCSInactive.png'
import TaskIcon from './icons/servers.png'
import SupportIcon from './icons/support.png'
import logList from './icons/logList.png'
import StatsInactive from './sidebaricon/StatsInactive.svg'
import detectors from './icons/detector.png'
import { SortComponent } from './ReusableComponents/SortComponent.js';

export default class AdminUsers extends Component {

    state = {
        user: {},
        type: "",
        userdisplay: [],
        currentpage: 1,
        userperpage: 20,
        key: " ",
        showDownloadCsvPopup: false,
        cancel: false,
        fromDate: '',
        toDate: '',
        successParams: {
            title: "ADD USER",
            message1: "User has been added successfully",
            message2: "To go back to users, click",
            hereLink: "/admin/users",
            buttonText: "Add new user",
            buttonLink: "/admin/add_npnt_user"
        },
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        userAnalytic: false,
        sortBy: 'created_on',
        sort: 'desc',
        uStatusType: 'all',
        searchByDate: { fromDate: '', toDate: new Date() },
        analytics: null
    }

    initDate = () => {
        let todaysDate = new Date()
        let currentDate = dateFormat(todaysDate, "yyyy-mm-dd")
        function getLastWeek() {
            var today = new Date();
            var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
            return lastWeek;
        }

        var lastWeek = getLastWeek();
        var lastWeekMonth = lastWeek.getMonth() + 1;
        var lastWeekDay = lastWeek.getDate();
        var lastWeekYear = lastWeek.getFullYear();
        var lastWeekDisplayPadded = ("0000" + lastWeekYear.toString()).slice(-4) + "-" + ("00" + lastWeekMonth.toString()).slice(-2) + "-" + ("00" + lastWeekDay.toString()).slice(-2);

        this.setState((state) => ({
            fromDate: lastWeekDisplayPadded,
            toDate: currentDate
        }))
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    onChangeListener = (e) => {
        let t = e.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.value
        }))
    }

    componentWillMount() {
        document.title = "Users - Admin";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.initDate()
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => {
                this.getSubbedServices()
                this.getAnalytics()
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.getSubbedServices()
                    this.getAnalytics()
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    successRedirectComponent = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/success",
                state: {
                    ...this.state.successParams,
                    user: this.state.user,
                    type: this.state.type,
                    sidebarMode: this.state.sidebarMode
                }
            }} />
        }
    }

    sort = (sortBy, sort) => {
        this.setState({ sortBy, sort }, () => {
            this.getUsers()
        })
    }

    getAnalytics = async () => {
        API.getAnalytics().then(result => {
            this.setState({
                analytics: result.allCounts
            })
        })
    }

    getUsers = () => {
        const { userdisplay, currentpage, userperpage, key, sortBy, sort, uStatusType, searchByDate } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;

        let fd = searchByDate.fromDate ? new Date(searchByDate.fromDate).toISOString() : null
        let td = new Date(searchByDate.toDate);
        td.setHours(td.getHours() + 23);
        td.setMinutes(td.getMinutes() + 59);
        td.setSeconds(td.getSeconds() + 59);

        this.setState({ loading: true }, () => {
            API.users(key == "" ? " " : key, firstpage, userperpage, sortBy, sort, uStatusType, fd, td.toISOString()).then((result) => {
                let users = result.users;
                let count = result.count;
                this.setState(state => ({
                    ...state,
                    userdisplay: users,
                    counts: count,
                    loading: false
                }))
            })
        })
    }

    componentDidMount() { this.getUsers(); }

    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => { this.getUsers(); });
        } else console.log("Reached last page")
    }

    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            }), () => { this.getUsers() });
        }
    }

    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => { this.getUsers() });
        } else console.log("Reached last page")
    }

    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => { this.getUsers() });
        } else console.log("Reached last page")
    }

    getSubbedServices = () => {
        API.getUserSubbedServices().then((services) => {
            let names = services[0].map((service) => { return service.title })
            if (names.findIndex(data => data == "Analytic Tool") != -1) {
                this.setState((state) => ({
                    ...state,
                    userAnalytic: true
                }));
            }
        }).catch(err => { this.setState({ userAnalytic: false }) })
    };

    changepage(num) {
        if (isNaN(num)) return null;
        else {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => this.getUsers());
        }
    }

    getAllSurveyRecord() {
        const { key, sortBy, sort, uStatusType, searchByDate } = this.state;;

        let fd = searchByDate.fromDate ? new Date(searchByDate.fromDate).toISOString() : null
        let td = new Date(searchByDate.toDate);
        td.setHours(td.getHours() + 23);
        td.setMinutes(td.getMinutes() + 59);
        td.setSeconds(td.getSeconds() + 59);

        API.downloadSurveyRecords(key == "" ? " " : key, sortBy, sort, uStatusType, fd, td.toISOString())
            .then(data => {
                this.setState((state) => ({
                    showDownloadCsvPopup: false
                }))
            })
    }

    search(key) {
        this.setState((state) => ({
            ...state,
            key,
        }), () => this.getUsers());
        console.log(key)
    }

    render() {
        const { user, type, userdisplay, currentpage, userperpage, counts } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },


                {
                    icon: UsersStats,
                    text: "Statistics",
                    path: "/admin/users_stats",
                },

                {
                    icon: CustomersInactive,
                    text: "Add NPNT Customer",
                    path: "/admin/add_npnt_customer"
                },

                {
                    icon: UsersActive,
                    text: "Users",
                    path: "/admin/users",
                    selected: true,
                },

                {
                    icon: install,
                    text: "Installations",
                    path: "/admin/gcs-installation",
                },
                {
                    icon: TaskIcon,
                    text: "Tasks",
                    path: "/admin/tasks",
                },
                {
                    icon: logList,
                    text: "Task Logs",
                    path: "/admin/taskLogs",
                },
                {
                    icon: detectors,
                    text: "Detectors",
                    path: "/admin/detectors"
                },
                {
                    icon: SupportIcon,
                    text: "Support",
                    path: "/admin/supportEmails",
                },
                {
                    icon: StatsInactive,
                    text: "Stats",
                    path: "/admin/system_monitoring",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode
        }

        return (
            this.state.user && this.state.user.first_name ? <div className="wrapper">
                <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    onClick={() => {
                        this.setState((state) => ({
                            ...state, sidebarMode: 'shrunk'
                        }))
                    }} >
                    <div className="top-bar" style={{ display: "flex" }}><div className="top-bar-text">USERS</div>
                    </div>

                    <div className="main-content" style={{ paddingTop: "16px" }}>
                        <div style={{ display: "flex", justifyContent: 'space-between' }}>

                            <div className="services-content-title" style={{ fontFamily: "nunito" }}>
                                {"Users"}<span className='data-count' style={{ fontSize: "13px !important" }}>({this.state.analytics?.activeUsersCount + this.state.analytics?.inactiveUsersCount})</span>
                                {"Active Users"}<span className='data-count'>({this.state.analytics?.activeUsersCount})</span>
                                {"Inactive Users"}<span className='data-count'>({this.state.analytics?.inactiveUsersCount})</span>
                                {"Flights"}<span className='data-count'>({this.state.analytics?.flightsCount})</span>
                                {"Drones"}<span className='data-count'>({this.state.analytics?.registerDronesCount})</span>
                                {"Streams"}<span className='data-count'>({this.state.analytics?.streamsCount})</span>
                            </div>
                            <div className="add-user-form-text-wrapper" style={{ marginLeft: "auto" }}>
                                <div className="addcustomer-button-empty-dashboard" style={{ paddingLeft: '30px', paddingRight: '30px', cursor: "pointer" }} onClick={() => {
                                    this.getAllSurveyRecord()
                                }}>Download data</div>
                            </div>

                            <div className="add-user-form-text-wrapper" style={{ marginLeft: "10px" }}>
                                <Link to={{ pathname: "/admin/allsubscription", state: { user, type, sidebarMode: this.state.sidebarMode } }}>
                                    <div className="addcustomer-button-empty-dashboard" style={{ paddingLeft: '30px', paddingRight: '30px' }}>Subscription</div>
                                </Link>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", margin: "16px 0" }}>
                            <div className="add-user-form-text-wrapper" style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }} >
                                <div className="imageViewButton" style={{ display: 'flex', height: '24px' }}  >
                                    <div
                                        // className={this.state.listview ? 'imageViewButtonListActive' : 'imageViewButtonList'}
                                        className={this.state.uStatusType == 'active' ? 'imageViewButtonListActive' : 'imageViewButtonList'}
                                        style={{ cursor: 'pointer', width: "66px", borderRadius: '2px 0px 0px 2px', borderRight: 'solid 1px #2989cf' }}
                                        onClick={() => {
                                            this.setState({
                                                uStatusType: 'active'
                                            }, () => {
                                                this.getUsers()
                                            })
                                        }}>
                                        <div>
                                            {/* <img src={TaskIcon} style={{ height: '18px', cursor: 'pointer', width: '18px', margin: '0px 5px' }} /> */}
                                            Active
                                        </div>
                                    </div>

                                    <div
                                        // className={this.state.gridView ? 'imageViewButtonListActive' : 'imageViewButtonList'}
                                        className={this.state.uStatusType == 'all' ? 'imageViewButtonListActive' : 'imageViewButtonList'}
                                        style={{ cursor: 'pointer', width: "66px", borderRight: 'solid 1px #2989cf', borderLeft: 'solid 1px #2989cf' }}
                                        onClick={() => {
                                            this.setState({
                                                uStatusType: 'all'
                                            }, () => {
                                                this.getUsers()
                                            })
                                        }}
                                    >
                                        <div>
                                            {/* <img src={TaskIcon} style={{ cursor: 'pointer', height: '18px', width: '18px', margin: '0px 5px' }} /> */}
                                            All
                                        </div>
                                    </div>

                                    <div
                                        // className={this.state.mapView ? 'imageViewButtonListActive' : 'imageViewButtonList'}
                                        className={this.state.uStatusType == 'inActive' ? 'imageViewButtonListActive' : 'imageViewButtonList'}
                                        style={{
                                            width: "74px", borderRadius: '0px 2px 2px 0px', borderLeft: 'solid 1px #2989cf',
                                            // filter: this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ ? "" : "grayscale(100%)",
                                            // opacity: this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ ? "1" : "0.5"
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                uStatusType: 'inActive'
                                            }, () => {
                                                this.getUsers()
                                            })
                                        }}>
                                        <div>
                                            {/* <img src={TaskIcon} style={{ height: '20px', width: '20px', cursor: 'pointer', margin: '0px 5px' }} /> */}
                                            Inactive
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: "flex" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                                    <div className="add-user-form-row-left" >
                                        <div className="add-user-form-text-wrapper" style={{ display: "flex" }} >
                                            <label style={{ color: "#666666", fontFamily: "Poppins", fontSize: "12px", paddingTop: "7px" }}>From :</label>
                                            <input
                                                className="add-user-form-text"
                                                type="date"
                                                max='9000-01-01'
                                                defaultValue={this.state.searchByDate.fromDate}
                                                onChange={(event) => this.setState({
                                                    searchByDate: { ...this.state.searchByDate, fromDate: event.target.value }
                                                }, () => { this.getUsers() })}
                                                style={{
                                                    borderRadius: '10px',
                                                    marginLeft: "8px",
                                                    height: "30px",
                                                    width: "125px",
                                                    cursor: "pointer"
                                                }} />
                                        </div>
                                    </div>
                                    <div className="add-user-form-row-left" style={{ marginLeft: "20px" }}>
                                        <div className="add-user-form-text-wrapper" style={{ display: "flex" }} >
                                            <label style={{ color: "#666666", fontFamily: "Poppins", fontSize: "12px", paddingTop: "7px" }}>To :</label>
                                            <input
                                                className="add-user-form-text"
                                                type="date"
                                                max='9000-01-01'
                                                onChange={(event) => this.setState({
                                                    searchByDate: { ...this.state.searchByDate, toDate: event.target.value }
                                                }, () => { this.getUsers() })}
                                                defaultValue={this.state.searchByDate.toDate}
                                                style={{
                                                    backgroundColor: "white",
                                                    marginLeft: "8px",
                                                    borderRadius: '10px',
                                                    outline: "none",
                                                    height: "30px",
                                                    cursor: "pointer",
                                                    width: "125px",
                                                }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="add-user-form-row-left" >
                                    <div className="add-user-form-text-wrapper">
                                        <img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
                                        <input type="text" className="add-user-form-text" placeholder="Search User" style={{ height: '30px !important', width: "235px", paddingLeft: '28px' }} onChange={(event) => this.search(event.target.value)} />
                                    </div>

                                </div>
                            </div>
                        </div >

                        <div style={{ height: 'calc(100% - 225px)', position: "absolute", width: 'calc(100% - 57px)', overflow: 'auto' }}>
                            <table className="customers-table" style={{ marginTop: "0px" }}>
                                <tr className="customers-table-header-row">
                                    <th>Name
                                        <SortComponent
                                            style={{ marginLeft: '5px' }}
                                            sortAscending={() => this.sort('first_name', 'asc')}
                                            sortDecending={() => this.sort('first_name', 'desc')}
                                            sortASC={false}
                                        />
                                    </th>
                                    <th>Email
                                        <SortComponent
                                            style={{ marginLeft: '5px' }}
                                            sortAscending={() => this.sort('username', 'asc')}
                                            sortDecending={() => this.sort('username', 'desc')}
                                            sortASC={false}
                                        />
                                    </th>
                                    <th>Phone</th>
                                    <th>Registered date
                                        <SortComponent
                                            style={{ marginLeft: '5px' }}
                                            sortAscending={() => this.sort('created_on', 'asc')}
                                            sortDecending={() => this.sort('created_on', 'desc')}
                                            sortASC={false}
                                        />
                                    </th>
                                    <th>Status</th>
                                </tr>
                                {this.state.loading ?
                                    <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                        <td colspan="5">
                                            <div style={{
                                                height: "7vh",
                                                display: "flex",
                                                textAlign: "center",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}>
                                                <img src={Loader} style={{ position: "relative", height: "10vh" }} />
                                            </div>
                                        </td>
                                    </tr> : userdisplay.length === 0 ?
                                        <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                            <td colspan="5">No Users Available</td>
                                        </tr> : userdisplay.map((user_info) => <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                            <td><Link style={{ color: "#707683" }} to={{
                                                pathname: `/admin/userdetails`,
                                                state: { user, type, user_info, sidebarMode: this.state.sidebarMode }
                                            }}>{user_info.first_name + " " + user_info.last_name} </Link></td>
                                            <td>{user_info.username}</td>
                                            <td>{user_info.phone}</td>
                                            <td>{user_info.created_on ? dateFormat(user_info.created_on, 'dd mmm yyyy hh:MM:ss TT') : "-"}</td>
                                            <td>{user_info.activated ? 'Active' : 'Inactive'}</td>
                                        </tr>)}
                            </table>
                        </div>
                        {
                            !this.state.loading && <footer className="footer" style={{
                                visibility: (this.state.counts > 15 ? "visible" : "hidden"),
                                position: "absolute",
                                bottom: "20px",
                                width: "calc(100% - 57px)"
                            }}>
                                <div style={{ float: "left", marginTop: "2rem", display: "flex" }}>
                                    <div style={{ color: "#109cf1", marginTop: "11px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                        Page : {currentpage}
                                    </div>


                                </div>
                                <div style={{ float: "right", marginTop: "2rem", display: "flex" }}>
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", }} onClick={this.firstpage}>
                                        <div className="add-user-form-submit-button" >
                                            First
                                        </div>
                                    </button>
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px" }} onClick={this.prevpage}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Prev
                                        </div>
                                    </button>
                                    <form>
                                        <div style={{ display: "flex" }}>
                                            <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                                Go to page :
                                            </div>
                                            {/* <input type="text" style={{height:"40px",width:"40px",textAlign:"center",color: "#109cf1",fontSize:"14px"}} value={currentpage} onChange={(event)=>this.changepage(parseInt(event.target.value))} name="currentpage"/> */}
                                            <div className="add-user-form-text-wrapper">
                                                <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                                                    onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                                    onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                            </div>
                                        </div>
                                    </form>

                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px" }} onClick={this.nextpage}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Next
                                        </div>
                                    </button>
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }} onClick={this.lastpage}>
                                        <div className="add-user-form-submit-button" >
                                            Last
                                        </div>
                                    </button>

                                </div>


                            </footer>
                        }
                        {/* <footer>
                                    <div style={{float:"right",marginTop:"2rem",padding: "0px 10px 0px 10px"}} className="addcustomer-button-empty-dashboard">
                                    <InputGroup>
                                    <InputGroup.Append>
                                    <button style={{padding: "4px", outline: "none", border: "none", background: "#F5F6F8",color: "#109cf1"}}  onClick={this.prevpage}>
                                        Prev
                                    </button>
                                    <FormControl style={{width:"45px", color:"#109cf1",  border: "none",textAlign:"center",backgroundColor:"#F5F6F8" ,outline:"none",boxShadow:"none",fontSize:"12px"}} onChange={this.changepage} value={currentpage} name="currentpage"/>
                                    <button style={{padding: "4px", outline: "none", border: "none", background: "#F5F6F8",color: "#109cf1"}} onClick={this.nextpage}>
                                        Next
                                     </button>
                                    </InputGroup.Append>
                                    </InputGroup>
                                    </div>
                                </footer> */}
                    </div >
                </div >
            </div > : <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
            </div>
        );
    }
}