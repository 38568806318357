import React, { Component } from 'react';
import * as API from './api.js';

import CustomersInactive from './customers.png';
import Loader from './icons/loader.svg';
import Users from './users.png';
import UsersStats from './res/stats.png'
import Search from "./Icon/Search.png";
import service from "./sidebaricon/Services.png";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import install from './icons/AeroGCSInactive.png'
import TaskIcon from './icons/serversActive.png'
import logList from './icons/logList.png'
import SupportIcon from './icons/support.png'
import CopyIcon from './icons/copy.png'
import Edit from './icons/pen.png'
import CopyActiveIcon from './icons/tick.png'
import fail from './assets/error.png';
import { Link, StaticRouter, Redirect } from 'react-router-dom';
import SideBar from './SideBar';
import moment from 'moment';
import { Switch, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import btnAdd from './AeroCapture/assets/icons/btnAdd.svg';
import { AnimatedModal } from './ReusableComponents/AnimatedModal'
import closeButton from './close-button.png';
import dateFormat from 'dateformat';
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import detectors from './icons/detector.png'

export default class TaskAndVmList extends Component {

    state = {
        user: {},
        type: "",
        userdisplay: [],
        tasks: [],
        currentpage: 1,
        userperpage: 10,
        counts: 0,
        key: " ",
        successParams: {
            title: "ADD USER",
            message1: "User has been added successfully",
            message2: "To go back to users, click",
            hereLink: "/admin/users",
            buttonText: "Add new user",
            buttonLink: "/admin/add_npnt_user",
            machineCounts: 0
        },
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        today: new Date(),
        createIcon: "",
        popupMessage: "",
        vms: [],
        copied: [],
        addVMPopup: false,
        vm_name: null,
        ip_address: null,
        status: false,
        az_subscription_id: null,
        resource_group: null,
        available: false,
        tasktype: 1,
        showAddVMPopup: false,
        statusFilter: "all",
        editTaskPopup: false,
        taskStatus: "",
        task_name: "",
        task_id: "",
        task_vm: "",
        task_type: 1,
        cancel: false,
        fromDate: '-30610245208',
        toDate: Date.now(),
        taskData: {
            "date_created": null,
            "images": 0,
            "classes": null,
            "task_name": "",
            "status": "",
            "assigned_vm": null,
            "video_task": false,
            "video_id": null,
            "fps": 0,
            "tasktype": 3,
            "first_name": "",
            "last_name": "",
            "username": "",
            "project_name": "",
            "plan_name": "",
            "collection_name": "",
            "file_name": ""
        },
        createdBy: "",

        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        task_parameters: "",
        accuracy: "",
        updateVMpopup: false,
        vmDetailsPopup: false,
        singleVmData: "",
        updateFlag: false,
        cancelFlag: false,
        vmid: ""
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    openAddVMPopup = () => {
        this.setState(state => ({
            ...state,
            vm_name: null,
            ip_address: null,
            status: false,
            available: false,
            tasktype: 1,
            resource_group: null,
            az_subscription_id: null,
            addVMPopup: true
        }))
    }
    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }
    onChangeListener = (e) => {
        let t = e.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.value
        }), () => {
            if (t.name == "statusFilter") {
                this.setState((state) => ({
                    ...state,
                    currentpage: 1
                }), () => this.getTasks())
            }
        })
    }

    handleSwitchChange = (event) => {
        let t = event.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.checked,
        }));
    };

    changeVMStatus = (id) => {
        API.changeVMStatus(id).then(result => {
            this.getAllVMS()
        }).catch(error => {
            console.log(error)
        })
    }
    fromDate = (date) => {
        if (date && date.length > 0) {
            let d = new Date(date + " 00:00:00+00")
            let date1 = d.getTime() / 1000
            this.setState((state) => ({
                ...state,
                fromDate: date1,
                currentpage: 1
            }),
                () => this.getTasks());
        }
        else {
            this.setState((state) => ({
                ...state,
                fromDate: '-30610245208',
                currentpage: 1
            }),
                () => this.getTasks());
        }
    }
    toDate = (date) => {
        if (date && date.length > 0) {
            let d = new Date(date + " 23:59:00+00")
            let date1 = d.getTime() / 1000
            this.setState((state) => ({
                ...state,
                toDate: date1,
                currentpage: 1
            }),
                () => this.getTasks());
        } else {
            this.setState((state) => ({
                ...state,
                toDate: Date.now(),
                currentpage: 1
            }),
                () => this.getTasks());
        }
    }
    componentWillMount() {
        document.title = "Task List - Admin";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type
            }), async () => {
                await this.getAllVMS();
                this.getTasks();
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), async () => {
                    await this.getAllVMS();
                    this.getTasks();
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    setVmValues = (vm) => {
        this.setState((state) => ({
            ...state,
            vmid: vm.id,
            vm_name: vm.az_vm_name,
            ip_address: vm.ip_address,
            status: vm.status === 'on' ? true : false,
            available: vm.available,
            tasktype: vm.tasktype,
            resource_group: vm.az_resource_group,
            az_subscription_id: vm.az_subscription_id,
            updateFlag: true,
            addVMPopup: true,
        }))
    }

    successRedirectComponent = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/success",
                state: {
                    ...this.state.successParams,
                    user: this.state.user,
                    type: this.state.type,
                    sidebarMode: this.state.sidebarMode
                }
            }} />
        }
    }

    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getTasks();
            });
        } else {
            console.log("Reached last page")
        }
    }

    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    this.getTasks();

                });
        }
    }
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getTasks();
            });
        } else {
            console.log("Reached last page")
        }
    }

    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                this.getTasks();
            });
        } else {
            console.log("Reached last page")
        }
    }

    changepage(num) {
        if (isNaN(num)) {
            return null;
        }
        else {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => this.getTasks());
        }
    }

    search(key) {
        this.setState((state) => ({
            ...state,
            key,
        }),
            () => this.getTasks());
    }

    // API to get task details
    getTask = () => {
        API.getAeroCaptureTask(this.state.task_id).then((data) => {
            data.plan_name = data.plan_name ? data.plan_name : "-"
            data.project_name = data.project_name ? data.project_name : "-"
            data.collection_name = data.collection_name ? data.collection_name : "-"
            data.file_name = data.file_name ? data.file_name : "-"
            data.first_name = data.first_name ? data.first_name : "-"
            data.last_name = data.last_name ? data.last_name : "-"
            this.setState((state) => ({
                ...state,
                taskData: data,
                taskDetailsPopup: true,
                createdBy: data.first_name + " " + data.last_name
            }))
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                popupMessage: e.message,
                createIcon: fail,
                showAddVMPopup: true
            }))
        })
    }

    addNewVM = (event) => {
        event.preventDefault(); // to prevent default behaviour of form component
        if (!this.state.cancelFlag) {
            let { vm_name, ip_address, resource_group, az_subscription_id, status, available, tasktype } = this.state
            if (!vm_name || !ip_address || !resource_group || !az_subscription_id) {
                this.setState((state) => ({
                    ...state,
                    addVMPopup: false,
                }))
            }
            else {
                this.setState((state) => ({
                    ...state,
                    addVMPopup: false,
                    itemName: "VM",
                    processPopupIcon: "WAIT",
                    processAction: "ADD",
                    processMessage: null,
                    showProcessPopup: true,
                }))

                let newVM = {
                    "vm_name": vm_name,
                    "ip_address": ip_address,
                    "status": status ? "on" : "off",
                    "az_subscription_id": az_subscription_id,
                    "resource_group": resource_group,
                    "tasktype": tasktype,
                    "available": available
                }
                API.addNewVM(newVM).then((result) => {
                    this.setState((state) => ({
                        ...state,
                        itemName: "VM",
                        processPopupIcon: "COMPLETE",
                        processAction: "ADD",
                        processMessage: null,
                        showProcessPopup: true,
                    }), () => this.getAllVMS())
                }).catch((error) => {
                    this.setState((state) => ({
                        ...state,
                        itemName: "VM",
                        processPopupIcon: "ERROR",
                        processMessage: error.message,
                        showProcessPopup: true,
                    }))
                })
            }
        }

    }

    updateVM = (event) => {
        event.preventDefault(); // to prevent default behaviour of form component
        let { vm_name, ip_address, resource_group, az_subscription_id, status, available, tasktype } = this.state

        this.setState((state) => ({
            ...state,
            addVMPopup: false,
            itemName: "VM",
            processPopupIcon: "WAIT",
            processAction: "UPDATE",
            processMessage: null,
            showProcessPopup: true,
        }))

        let updateVM = {
            "az_vm_name": vm_name,
            "ip_address": ip_address,
            "status": status ? "on" : "off",
            "az_sub_id": az_subscription_id,
            "az_res_group": resource_group,
            "tasktype": tasktype,
            "available": available
        }
        API.updateVM(updateVM, this.state.vmid).then((result) => {
            this.setState((state) => ({
                ...state,
                itemName: "VM",
                processPopupIcon: "COMPLETE",
                processAction: "UPDATE",
                processMessage: null,
                showProcessPopup: true,
                updateFlag: false,
            }), () => this.getAllVMS())
        }).catch((error) => {
            this.setState((state) => ({
                ...state,
                itemName: "VM",
                processPopupIcon: "ERROR",
                processMessage: error.message,
                showProcessPopup: true,
                updateFlag: false,
            }))
        })
    }

    updateStatus = (event) => {
        event.preventDefault(); // to prevent default behaviour of form component
        let { cancel, task_id, task_type, taskStatus } = this.state
        if (cancel) {
            this.setState((state) => ({
                ...state,
                editTaskPopup: false,
            }))
        }
        else {
            this.setState((state) => ({
                ...state,
                editTaskPopup: false,
                itemName: "Task",
                processPopupIcon: "WAIT",
                processAction: "UPDATE",
                processMessage: null,
                showProcessPopup: true,
            }))

            let task = {
                task_parameters: this.state.task_parameters,
                accuracy: this.state.accuracy
            }
            API.updateTask(task_id, taskStatus, this.state.task_type == 3 ? undefined : "PicStork", task).then((result) => {
                this.setState((state) => ({
                    ...state,
                    itemName: "Task",
                    processPopupIcon: "COMPLETE",
                    processAction: "UPDATE",
                    processMessage: null,
                    showProcessPopup: true,
                }), () => this.getTasks())
            }).catch((error) => {
                this.setState((state) => ({
                    ...state,
                    itemName: "Task",
                    processPopupIcon: "ERROR",
                    processMessage: error.message,
                    showProcessPopup: true,
                }))
            })
        }
    }

    // getAllTask function starts here---------- 
    getTasks = () => {
        const { user, type, userdisplay, currentpage, userperpage, key, counts, statusFilter } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;

        let state = this.props.location.state
        this.setState(state => ({
            ...state,
            counts: 0,
        }))
        API.getAllTasksForAdmin(key === "" ? " " : key, this.state.statusFilter === "all" ? " " : this.state.statusFilter, this.state.fromDate, this.state.toDate, firstpage, userperpage).then(async (data) => {
            if (data.tasks && data.tasks.length == 0) {
                this.setState((state) => ({
                    ...state,
                    tasks: [],
                    loading: false
                }))
            } else {
                try {

                    let allTasks = data.tasks
                    var copied = []
                    allTasks.forEach(task => {
                        let vmId = task.assigned_vm
                        if (vmId != null && vmId != undefined) {
                            let vm = this.state.vms.find((vm) => { return vm.id == task.assigned_vm })
                            task.ip_address = vm ? vm.ip_address : '-'
                        }
                        else
                            task.ip_address = '-'
                        copied.push(false)
                    })
                    this.setState((state) => ({
                        ...state,
                        tasks: allTasks,
                        loading: false,
                        counts: data.count,
                        copied: copied
                    }))
                } catch (e) {
                    console.log(e);
                }
            }
        }, (e) => {
            console.log(e);
        })
    }

    copyById = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
    }
    // getAll VM function starts here---------- 
    getAllVMS = async () => {
        this.setState(state => ({
            ...state,
            machineCounts: 0,
        }))
        await API.getAllVms().then(async (data) => {
            this.setState((state) => ({
                ...state,
                vms: data.vm_data,
                loading: false,
                machineCounts: data.vm_data.length
            }))
        }, (e) => {
            console.log(e);
        })
    }

    render() {
        const { user, type, currentpage, userperpage, counts, vm_name, fromDate, toDate, status, ip_address, resource_group, az_subscription_id, available, tasktype } = this.state;
        // const lastIndex = currentpage * userperpage;
        // const firstpage = lastIndex - userperpage;
        // const currentuser = userdisplay.slice(firstpage, lastIndex);
        // const totalpages = userdisplay.length / userperpage;
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: UsersStats,
                    text: "Statistics",
                    path: "/admin/users_stats",
                },
                {
                    icon: CustomersInactive,
                    text: "Add NPNT Customer",
                    path: "/admin/add_npnt_customer"
                },
                {
                    icon: Users,
                    text: "Users",
                    path: "/admin/users",
                },
                {
                    icon: install,
                    text: "Installations",
                    path: "/admin/gcs-installation",
                },
                {
                    icon: TaskIcon,
                    text: "Tasks",
                    path: "/admin/tasks",
                    selected: true,
                },
                {
                    icon: logList,
                    text: "Task Logs",
                    path: "/admin/taskLogs",
                },
                {
                    icon: detectors,
                    text: "Detectors",
                    path: "/admin/detectors"
                },
                {
                    icon: SupportIcon,
                    text: "Support",
                    path: "/admin/supportEmails",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode
        }
        return (
            this.state.user && this.state.user.first_name ? <div className="wrapper">
                <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} />

                {/* ------------------ Task Details Popup--------------------- */}
                <AnimatedModal
                    isOpen={this.state.taskDetailsPopup}
                    onRequestClose={() => {
                        this.setState((state) => ({
                            ...state,
                            taskDetailsPopup: false,
                        }))
                    }}
                    height="540px"
                    width="530px"
                >
                    <div style={{ textAlign: "center", margin: '10px 0px', height: '30px', fontSize: '22px', fontWeight: '500' }}>Task Details</div>
                    <img
                        src={closeButton} style={{ width: "22px", height: "22px", position: "absolute", top: "15px", right: "20px", cursor: "pointer" }} onClick={() => {
                            this.setState((state) => ({
                                ...state,
                                taskDetailsPopup: false
                            }))
                        }}></img>
                    <div style={{ height: '5px', width: '100%' }}><hr /></div>
                    <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column", width: "95%", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                            <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                <div className='objectKey' >Task Name</div>
                                <div className='objectValue'>{this.state.taskData.task_name.length > 30 ?
                                    this.state.taskData.task_name.substring(0, 30) + "..." : this.state.taskData.task_name}</div>
                            </div>
                            <div style={{ height: "60px", margin: "0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                            <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                <div className='objectKey'>Status</div>
                                <div className='objectValue'>{this.state.taskData.status}</div>
                            </div>
                        </div>
                        <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                            <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                <div className='objectKey' >Plan</div>
                                <div className='objectValue'>{this.state.taskData.plan_name.length > 25 ?
                                    this.state.taskData.plan_name.substring(0, 30) + "..." : this.state.taskData.plan_name}</div>
                            </div>
                            <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                            <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                <div className='objectKey'>Project</div>
                                <div className='objectValue'>{this.state.taskData.project_name.length > 20 ?
                                    this.state.taskData.project_name.substring(0, 20) + "..." : this.state.taskData.project_name}</div>
                            </div>
                        </div>
                        <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                            <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                <div className='objectKey'>Task type</div>
                                <div className='objectValue'>{this.state.taskData.tasktype == 1 ? "Training" :
                                    this.state.taskData.tasktype == 2 ? "Detection" :
                                        this.state.taskData.tasktype == 3 ? "Orthomosaic" : "Annotation Report"}</div>
                            </div>
                            <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                            <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                <div className='objectKey'>Assigned VM</div>
                                <div className='objectValue'>{this.state.task_vm ? this.state.task_vm : "-"}</div>
                            </div>
                        </div>
                        {this.state.taskData.video_task ? (
                            <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey'>Video name</div>
                                    <div className='objectValue'>{this.state.taskData.file_name.length > 25 ?
                                        this.state.taskData.file_name.substring(0, 25) + "..." : this.state.taskData.file_name}</div>
                                </div>
                                <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey'>Frames Per Second</div>
                                    <div className='objectValue'>{this.state.taskData.fps}</div>
                                </div>
                            </div>) : (
                            <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey'>Imageset</div>
                                    <div className='objectValue'>{this.state.taskData.collection_name.length > 30 ?
                                        this.state.taskData.collection_name.substring(0, 30) + "..." : this.state.taskData.collection_name}</div>
                                </div>
                                <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey'>No. of Images</div>
                                    <div className='objectValue'>{this.state.taskData.images}</div>
                                </div>
                            </div>
                        )}
                        <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                            <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                <div className='objectKey'>Created By</div>
                                <div className='objectValue'>{this.state.createdBy.length > 25 ?
                                    this.state.createdBy.substring(0, 25) + "..." : this.state.createdBy}</div>
                            </div>
                            <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                            <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                <div className='objectKey'>Created Date</div>
                                <div className='objectValue'>{dateFormat(this.state.taskData.date_created, "dd mmm yyyy")}</div>
                            </div>
                        </div>

                    </div>
                </AnimatedModal>
                {/* ------------------------------------------------ */}

                <AnimatedModal
                    isOpen={this.state.editTaskPopup}
                    onRequestClose={() => {
                        this.setState((state) => ({
                            ...state,
                            editTaskPopup: false,
                        }))
                    }}
                    height="320px"
                    width="400px"
                >
                    <div style={{ textAlign: "center", margin: '10px 0px', height: '30px', fontSize: '22px', fontWeight: '500' }}>Edit task status</div>
                    <div style={{ height: '5px', width: '100%' }}><hr /></div>
                    <form onSubmit={this.updateStatus} >
                        <div style={{ margin: '10px 50px' }}>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "1%" }}>Task Name</div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    disabled
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="vm_name"
                                    value={this.state.task_name}
                                />
                            </div>

                            <div style={{ display: "flex", marginTop: "4%", justifyContent: "space-between" }}>
                                <div>
                                    <div className='sub-add-user-form-label' style={{ display: "flex" }}>Task Status</div>
                                    <select
                                        className="sub-add-user-form-text"
                                        style={{ width: "298px", borderRadius: "5px" }}
                                        name='taskStatus'
                                        value={this.state.taskStatus}
                                        onChange={this.onChangeListener}
                                    >
                                        {/* <option value={"Created"}>Created</option> */}
                                        <option value={"Pending"}>Pending</option>
                                        {/*<option value={"In Progress"}>In Progress</option>
                                             <option value={"Completed"}>Completed</option> */}
                                        <option value={"Failed"}>Failed</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div style={{ margin: '50px 50px 10px 50px', display: 'flex', justifyContent: 'space-between' }}>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        editTaskPopup: false,
                                        cancel: true
                                    }));
                                }}
                            >
                                Cancel
                            </button>
                            {this.state.taskStatus ?
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    type='submit'
                                >
                                    Save
                                </button>
                                : <button disabled style={{ opacity: '0.5' }}
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                >
                                    Save
                                </button>}
                        </div>
                    </form>
                </AnimatedModal>

                {/* ------------------ Vm Details Popup--------------------- */}
                <AnimatedModal
                    isOpen={this.state.vmDetailsPopup}
                    onRequestClose={() => {
                        this.setState((state) => ({
                            ...state,
                            vmDetailsPopup: false,
                        }))
                    }}
                    height="440px"
                    width="530px"
                >
                    <div style={{ textAlign: "center", margin: '10px 0px', height: '30px', fontSize: '22px', fontWeight: '500' }}>VM Details</div>
                    <img
                        src={closeButton} style={{ width: "22px", height: "22px", position: "absolute", top: "15px", right: "20px", cursor: "pointer" }} onClick={() => {
                            this.setState((state) => ({
                                ...state,
                                vmDetailsPopup: false
                            }))
                        }}></img>
                    {this.state.singleVmData ?
                        <div>
                            <div style={{ height: '5px', width: '100%' }}><hr /></div>
                            <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column", width: "95%", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                    <div style={{ margin: '10px 0px', width: "35%", display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey' >VM Name</div>
                                        <div className='objectValue'>{this.state.singleVmData.az_vm_name}</div>
                                    </div>
                                    <div style={{ height: "60px", margin: "0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                    <div style={{ margin: '10px 0px', display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey'>Id</div>
                                        <div style={{ fontSize: "12px", margin: "5px 0px" }}>{this.state.singleVmData.id}</div>
                                    </div>
                                </div>
                                <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                    <div style={{ margin: '10px 0px', width: "35%", display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey'>Resource group</div>
                                        <div className='objectValue'>{this.state.singleVmData.az_resource_group}</div>
                                    </div>
                                    <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                    <div style={{ margin: '10px 0px', display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey' >Subscription Id</div>
                                        <div style={{ fontSize: "12px", margin: "5px 0px" }}>{this.state.singleVmData.az_subscription_id}</div>
                                    </div>
                                </div>

                                <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                    <div style={{ margin: '10px 0px', width: "35%", display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey'>IP address</div>
                                        <div className='objectValue'>{this.state.singleVmData.ip_address}</div>
                                    </div>
                                    <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                    <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey'>Status</div>
                                        <div className='objectValue'>{this.state.singleVmData.status}</div>
                                    </div>
                                </div>

                                <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                    <div style={{ margin: '10px 0px', width: "35%", display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey'>Task type</div>
                                        <div className='objectValue'>{this.state.singleVmData.tasktype == 1 ? "Training" :
                                            this.state.singleVmData.tasktype == 2 ? "Detection" :
                                                this.state.singleVmData.tasktype == 3 ? "Orthomosaic" : "-"}</div>
                                    </div>
                                    <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                    <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey'>Available</div>
                                        <div className='objectValue'>{this.state.singleVmData.available ? "Yes" : "No"}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <></>
                    }

                </AnimatedModal>

                <AnimatedModal
                    isOpen={this.state.addVMPopup}
                    onRequestClose={() => {
                        this.setState((state) => ({
                            ...state,
                            addVMPopup: false,
                            updateFlag: false
                        }))
                    }}
                    height="550px"
                    width="550px"
                >
                    <div className='content-text' style={{ textAlign: "center", margin: '10px 0px', height: '30px', fontSize: '20px', fontWeight: '500' }}>{this.state.updateFlag ? "Update Virtual Machine" : "Add New Virtual Machine"}</div>
                    <div style={{ height: '5px', width: '100%' }}><hr /></div>
                    <form onSubmit={this.state.updateFlag ? this.updateVM : this.addNewVM} >
                        <div style={{ margin: '10px 50px' }}>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "1%" }}>VM Name</div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="vm_name"
                                    placeholder="Enter VM Name"
                                    onChange={this.onChangeListener}
                                    value={vm_name}
                                />
                            </div>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>IP address</div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="ip_address"
                                    placeholder="e.g. 0.0.0.0 to 255.255.255.255"
                                    onChange={this.onChangeListener}
                                    value={ip_address}
                                    pattern='\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b'
                                />
                            </div>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>Resource group</div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="resource_group"
                                    placeholder="Enter Resource Group"
                                    onChange={this.onChangeListener}
                                    value={resource_group}
                                />
                            </div>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>Azure Subscription ID</div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="az_subscription_id"
                                    placeholder="Enter Azure Subscription Id"
                                    onChange={this.onChangeListener}
                                    value={az_subscription_id}
                                />
                            </div>
                            <div style={{ display: "flex", marginTop: "4%", justifyContent: "space-between" }}>
                                <div>
                                    <div className='sub-add-user-form-label' style={{ display: "flex" }}>Task Type</div>
                                    <select
                                        className="sub-add-user-form-text"
                                        style={{ width: "200px", borderRadius: "5px", fontSize: "12px" }}
                                        name='tasktype'
                                        value={tasktype}
                                        onChange={this.onChangeListener}
                                    >
                                        <option value={1}>Training</option>
                                        <option value={2}>Detection</option>
                                        <option value={3}>Orthomosaic</option>
                                    </select>
                                </div>
                                <div>
                                    <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>Status</div>
                                    <Switch label="Status" onChange={(e) => this.handleSwitchChange(e)} name="status" checked={this.state.status} />
                                </div>
                                <div>
                                    <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>Available</div>
                                    <Switch label="Status" onChange={(e) => this.handleSwitchChange(e)} name="available" checked={this.state.available} />
                                </div>
                            </div>
                        </div>
                        <div style={{ margin: '50px 50px 10px 50px', display: 'flex', justifyContent: 'space-between' }}>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        addVMPopup: false,
                                        updateFlag: false,
                                        cancelFlag: true
                                    }));
                                }}
                            >
                                Cancel
                            </button>
                            {this.state.updateFlag ?
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    type='submit'
                                >
                                    Update
                                </button>
                                :
                                vm_name && ip_address && resource_group && az_subscription_id ?
                                    <button
                                        className="add-user-form-submit-button popup-btn-right btn-ok"
                                        type='submit'
                                    >
                                        Add
                                    </button>
                                    : <button disabled style={{ opacity: '0.5' }}
                                        className="add-user-form-submit-button popup-btn-right btn-ok"
                                    >
                                        Add
                                    </button>
                            }

                        </div>
                    </form>
                </AnimatedModal>

                {/* --------------Activity Popup ----------------------- */}

                <ActivityPopup item={this.state.itemName} open={this.state.showProcessPopup} icon={this.state.processPopupIcon} action={this.state.processAction} msg={this.state.processMessage} close={() => this.closeActivityPopup()} />

                {/* --------------------------------------------------------------- */}

                <div className="right-content"
                    style={{ zIndex: 0 }}
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar" style={{ display: "flex" }}><div className="top-bar-text">Tasks</div>
                    </div>
                    <div className="main-content">
                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                            <div className="services-content-title"> Tasks : {counts}</div>
                        </div>
                        {this.state.loading ? (
                            <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                                <img src={Loader} style={{ height: "20vh", width: "100vw", margin: "auto" }} />
                            </div>) : (
                            <div>
                                <div style={{ marginTop: "1%", display: "flex", width: "100%", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex" }}>
                                        <div className="add-user-form-row-left" >
                                            <div className="add-user-form-text-wrapper" style={{ display: "flex", width: "max-content" }} >
                                                <label style={{ color: "#666666", fontFamily: "Poppins", fontSize: "12px", paddingTop: "7px" }}>From :</label>
                                                <input
                                                    className="add-user-form-text"
                                                    type="date"
                                                    max='9000-01-01'
                                                    defaultValue={new Date("2/1/2000").toISOString().substring(0, 10)}
                                                    onChange={(event) => this.fromDate(event.target.value)}
                                                    style={{
                                                        borderRadius: '5px',
                                                        marginLeft: "8px",
                                                        height: "30px",
                                                        width: "auto",
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="add-user-form-row-left" style={{ marginLeft: "3%" }}>
                                            <div className="add-user-form-text-wrapper" style={{ display: "flex", width: "max-content" }} >
                                                <label style={{ color: "#666666", fontFamily: "Poppins", fontSize: "12px", paddingTop: "7px" }}>To :</label>
                                                <input
                                                    className="add-user-form-text"
                                                    type="date"
                                                    max='9000-01-01'
                                                    onChange={(event) => this.toDate(event.target.value)}
                                                    defaultValue={new Date().toISOString().substring(0, 10)}
                                                    style={{
                                                        backgroundColor: "white",
                                                        marginLeft: "8px",
                                                        borderRadius: '5px',
                                                        outline: "none",
                                                        height: "30px",
                                                        width: "auto",
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <select
                                            className="sub-add-user-form-text"
                                            style={{ width: "180px", borderRadius: "5px", fontSize: "12px" }}
                                            name='statusFilter'
                                            value={this.state.statusFilter}
                                            onChange={this.onChangeListener}
                                        >
                                            <option value={"all"}>All</option>
                                            <option value={"Created"}>Created</option>
                                            <option value={"Pending"}>Pending</option>
                                            <option value={"In Progress"}>In Progress</option>
                                            <option value={"Completed"}>Completed</option>
                                            <option value={"Failed"}>Failed</option>
                                        </select>
                                        <div className="add-user-form-text-wrapper">
                                            <img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
                                            <input type="text" className="add-user-form-text" placeholder="Search task by task name" style={{ height: '30px !important', width: "235px", paddingLeft: '28px' }} onChange={(event) => this.search(event.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <table className="customers-table">
                                    <col style={{ width: '18%' }} />
                                    <col style={{ width: '15%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '12%' }} />
                                    <col style={{ width: '13%' }} />
                                    <col style={{ width: '12%' }} />
                                    <col style={{ width: '13%' }} />
                                    <col style={{ width: '12%' }} />

                                    <tr className="customers-table-header-row" style={{ position: 'inherit' }}>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>No. of images</th>
                                        <th>Assigned VM IP</th>
                                        <th>Status</th>
                                        <th>Task type</th>
                                        <th>Last updated date</th>
                                        <th>Processing Time</th>
                                    </tr>
                                    {this.state.tasks.length === 0 ?
                                        <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                            <td colspan="7">No Tasks Available</td>
                                        </tr> :
                                        this.state.tasks.map((task, i) =>
                                            <tr className="customers-table-data-row" style={{ height: "45px" }} >
                                                <td onClick={() => {
                                                    this.setState((state) => ({
                                                        ...state,
                                                        task_id: task.task_id,
                                                        task_vm: task.ip_address
                                                    }), () => this.getTask());
                                                }} style={{ cursor: "pointer" }}>{task.task_id}</td>
                                                <td onClick={() => {
                                                    this.setState((state) => ({
                                                        ...state,
                                                        task_id: task.task_id,
                                                        task_vm: task.ip_address
                                                    }), () => this.getTask());
                                                }} style={{ cursor: "pointer" }}>{task.task_name}</td>
                                                <td>{task.images}</td>
                                                <td>{task.ip_address} {task.ip_address != '-' ? <CopyToClipboard text={task.ip_address}
                                                    onCopy={() => {
                                                        let copied = this.state.copied
                                                        copied[i] = true
                                                        this.setState({ copied: copied })
                                                        setTimeout(() => {
                                                            copied[i] = false
                                                            this.setState({ copied: copied })
                                                        }, 500);
                                                    }}>
                                                    <img src={this.state.copied[i] ? CopyActiveIcon : CopyIcon} style={{ height: '16px', width: "16px", cursor: "pointer" }} />
                                                </CopyToClipboard> : ''} </td>
                                                <td>{task.status} <img src={Edit} onClick={() => {
                                                    console.log("TASK EDIT ")
                                                    this.setState((state) => ({
                                                        ...state,
                                                        task_name: task.task_name,
                                                        taskStatus: task.status,
                                                        task_id: task.task_id,
                                                        task_type: task.tasktype,
                                                        task_parameters: task.task_parameters,
                                                        accuracy: task.accuracy,
                                                        editTaskPopup: true
                                                    }))
                                                }} style={{ marginLeft: "5px", height: '14px', width: "14px", cursor: "pointer" }} />
                                                </td>
                                                <td>{task.tasktype == 1 ? 'Training' : task.tasktype == 2 ? 'Detection' : task.tasktype == 3 ? 'Orthomosaic' : 'Annotation Report'}</td>
                                                <td>{dateFormat(task.status_update_timestamp, 'dd mmm yyyy')}</td>
                                                <td>
                                                    {
                                                        (() => {
                                                            if (task.taskStartTime && task.taskEndTime) {
                                                                const startDate = moment(task.taskStartTime);
                                                                const timeEnd = moment(task.taskEndTime);
                                                                const diff = timeEnd.diff(startDate);
                                                                const diffDuration = moment.duration(diff);
                                                                console.log(task.task_name, "Difference : ", diffDuration);
                                                                let count = `${diffDuration.days() > 0 ? `${diffDuration.days()} Days : ` : ''} ${diffDuration.hours() > 0 ? `${diffDuration.hours()} Hrs : ` : ''}${diffDuration.minutes() > 0 ? `${diffDuration.minutes()} Min : ` : ''}${diffDuration.seconds() > 0 ? `${diffDuration.seconds()} Sec` : ''}`
                                                                return count
                                                            } else return '-'
                                                        })()
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                </table>
                            </div>
                        )
                        }
                        {this.state.counts > 10 ? (
                            <footer className="footer">
                                <div style={{ float: "left", marginTop: "1rem", display: "flex" }}>
                                    <div style={{ color: "#109cf1", marginTop: "5px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                        Page : {currentpage}
                                    </div>
                                </div>
                                <div style={{ float: "right", marginTop: "1rem", display: "flex" }}>
                                    {currentpage == 1 ? <><button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", opacity: '0.5' }} >
                                        <div className="add-user-form-submit-button" >
                                            First
                                        </div>
                                    </button>
                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px", opacity: '0.5' }} >
                                            <div className="addcustomer-button-empty-dashboard" >
                                                Prev
                                            </div>
                                        </button></> :
                                        <><button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", }} onClick={this.firstpage}>
                                            <div className="add-user-form-submit-button" >
                                                First
                                            </div>
                                        </button>
                                            <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px" }} onClick={this.prevpage}>
                                                <div className="addcustomer-button-empty-dashboard" >
                                                    Prev
                                                </div>
                                            </button></>}
                                    <form>
                                        <div style={{ display: "flex" }}>
                                            <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                                Go to page :
                                            </div>
                                            <div className="add-user-form-text-wrapper">
                                                <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                                                    onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                                    onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                            </div>
                                        </div>
                                    </form>

                                    {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ? <><button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px", opacity: '0.5' }}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Next
                                        </div>
                                    </button>
                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px", opacity: '0.5' }}>
                                            <div className="add-user-form-submit-button" >
                                                Last
                                            </div>
                                        </button> </> :
                                        <><button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px" }} onClick={this.nextpage}>
                                            <div className="addcustomer-button-empty-dashboard" >
                                                Next
                                            </div>
                                        </button>
                                            <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }} onClick={this.lastpage}>
                                                <div className="add-user-form-submit-button" >
                                                    Last
                                                </div>
                                            </button> </>}
                                </div>
                            </footer>
                        ) : ''}


                        <div style={{ marginTop: "7%", display: "flex", width: "100%", justifyContent: "space-between" }}>
                            <div className="services-content-title"> Virtual Machines : {this.state.machineCounts}</div>
                            <div className="add-user-form-text-wrapper" style={{ marginLeft: "25px", marginRight: '5px' }}>
                                <img src={btnAdd} style={{ height: '30px', width: '34px', cursor: 'pointer' }} onClick={() => this.openAddVMPopup()} />
                            </div>
                        </div>
                        {this.state.loading ? (
                            <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                                <img src={Loader} style={{ height: "20vh", width: "100vw", margin: "auto" }} />
                            </div>) : (
                            <table className="customers-table">
                                <tr className="customers-table-header-row" style={{ position: "inherit" }}>
                                    <th>IP address</th>
                                    <th>Edit</th>
                                    <th>Name</th>
                                    <th>Resource Group</th>
                                    <th>Status</th>
                                    <th>Available</th>
                                    <th>Task Type</th>
                                </tr>
                                {this.state.vms.length === 0 ?
                                    <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                        <td colspan="6">No Machines Available</td>
                                    </tr> :
                                    this.state.vms.map((vm, i) =>
                                        <tr className="customers-table-data-row" style={{ height: "45px" }} >
                                            <td onClick={() => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    singleVmData: vm,
                                                    vmDetailsPopup: true
                                                }))
                                            }} style={{ cursor: "pointer" }}>{vm.ip_address} </td>
                                            <td><img src={Edit} onClick={() => {
                                                this.setVmValues(vm)
                                            }} style={{ marginLeft: "5px", height: '14px', width: "14px", cursor: "pointer" }} /></td>
                                            <td onClick={() => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    singleVmData: vm,
                                                    vmDetailsPopup: true
                                                }))
                                            }} style={{ cursor: "pointer" }}>{vm.az_vm_name}</td>
                                            <td onClick={() => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    singleVmData: vm,
                                                    vmDetailsPopup: true
                                                }))
                                            }} style={{ cursor: "pointer" }}>{vm.az_resource_group}</td>
                                            <td>{vm.status}</td>
                                            <td>
                                                <Switch onClick={() => { this.changeVMStatus(vm.id) }} checked={vm.available} />
                                            </td>
                                            <td>
                                                {
                                                    (() => {
                                                        return `${vm.tasktype == 3 ? 'Orthomosaic' : vm.tasktype == 2 ? 'Detection' : 'Training'}`
                                                    })()
                                                }
                                            </td>
                                        </tr>
                                    )}
                            </table>
                        )
                        }
                    </div>
                </div>
            </div > : <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
            </div>
        );
    }
}
