import React, { Component } from 'react';
import dashboardIcon from '../../assets/sideBarIcons/dashboard.png';
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs';
import projectActive from '../../assets/sideBarIcons/Project.png';
import btnAdd from "../assets/icons/btnAdd.svg";
import * as API from '../../api.js';
import service from "../../sidebaricon/Services.png";
import Popup from "reactjs-popup";
import NoPlan from '../../assets/Images/Noplan.png';
import Loader from '../../icons/loader.svg';
import { Link } from 'react-router-dom';
import SideBar from '../../SideBar';
import Menu from '../assets/icons/menu.svg';
import ViewImagesOnMap from '../../ReusableComponents/inspectOnMap';
import CloseBtn from '../../assets/Icons/Close.png'
import { CreateProjectPlanModal } from '../../ReusableComponents/CreateProjectPlanModal';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import { DeleteComponentGrid, DeleteComponentTable } from '../../ReusableComponents/Menu'
import AppConstants from '../../AppConstants';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={Menu} alt="I am alt" />
    </a>
));

class AeroCaptureAllPlans extends Component {

    state = {
        sharedBy: null,
        isOwner: false,
        user: {},
        type: "",
        key: " ",
        progress: 0,
        showDownloadPopup: false,
        loader: false,
        plans: [],
        showBackIcon: true,
        cancel: false,
        userperpage: 8,
        currentpage: 1,
        counts: 0,
        loading: true,
        userdisplay: [],
        mapView: false,
        listview: true,
        plansonMap: [],
        planstat: {},
        selectMapPlan: {},
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: ""
    }
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }
    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }

    isUserOwnerOfCollection = () => {
        const { id } = this.state.project
        API.isUserProjectOwner(id)
            .then((result) => {
                this.setState({
                    isOwner: result.isShared ? false : true,
                    isAllowToDownload: result.access_level == "DOWNLOAD" ? true : false,
                    sharedBy: result.sharedBy
                })
            })
            .catch((err) => {
                this.setState((state) => ({
                    ...state,
                    itemName: "",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Something went wrong while getting owner information",
                    showProcessPopup: true,
                    loading: false,
                    isOwner: false,
                    isAllowToDownload: false
                }))
            })
    }

    getPlansOnMap = () => {
        API.getPlansOnMap(this.state.project.project_name, this.props.location.state.project.id).then((projectplans) => {
            console.log("PLANS DATA ON MAP", projectplans.plans)
            this.setState((state) => ({
                ...state,
                plansonMap: projectplans.plans
            }))
        }, (e) => {
            console.log(e);
            alert(e)
            // this.setState((state) => ({
            //     deletingTaskText: e,
            //     createIcon: fail
            // }))
        })

    }

    openPlanstat = (idx) => {
        this.setState((state) => ({
            ...state,
            loadingData: true,
            selectMapPlan: this.state.plansonMap[idx],
        }), () => {
            var style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = '.popup-content { width: 300px !important; border-radius:15px;  padding: 0px !important; }';
            document.getElementsByTagName('head')[0].appendChild(style);
            document.getElementById("view-image-popup-button").click()

            API.getPlanOnMapDetails(this.state.plansonMap[idx].plan_id).then((plandata) => {
                console.log("PLANS DATA ON MAP", plandata.statistics)
                this.setState((state) => ({
                    ...state,
                    planstat: plandata.statistics
                }), () => {
                    this.setState((state) => ({
                        ...state,
                        loadingData: false
                    }))
                    this.state.plans.map((plan) => {
                        if (plan.id == this.state.plansonMap[idx].plan_id) {
                            this.setState((state) => ({
                                ...state,
                                planFromMap: plan
                            }))
                        } else {
                            console.log("plans not match with selected plan from map")
                        }
                    })
                })
            }, (e) => {
                console.log(e);
                alert(e)
                // this.setState((state) => ({
                //     deletingTaskText: e,
                //     createIcon: fail
                // }))
            })
        })
    }

    getPlans = () => {
        const { user, type, userdisplay, currentpage, userperpage, key, counts, project } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;

        let state = this.props.location.state
        this.setState(state => ({
            ...state,
            // logsDisplay: [],
            counts: 0,
            // loading: true
        }))
        API.getPlans(project.project_name, key == "" ? " " : key, userperpage, firstpage, 3, this.props.location.state.project.id).then(async (data) => {
            console.log(data);
            if (data.plans && data.plans.length == 0) {
                this.setState((state) => ({
                    ...state,
                    plans: [],
                    loading: false
                }))
            } else {
                this.setState((state) => ({
                    ...state,
                    plans: data.plans,
                    loading: false,
                    counts: data.count
                }))
            }
        }, (e) => {
            console.log(e);
        })
    }

    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getPlans();
            });
        } else {
            console.log("Reached first page")
        }
    }

    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    this.getPlans();

                });
        }
    }
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getPlans();
            });
        } else {
            console.log("Reached next page")
        }
    }

    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                this.getPlans();
            });
        } else {
            console.log("Reached last page")
        }
    }


    deletePlan = () => {
        this.setState((state) => ({
            itemName: "Plan",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.deleteAerocapturePlan(this.state.selectedPlanId, AppConstants.SERVICES.DRONENAKSHA).then((data) => {
            console.log(data);
            this.setState((state) => ({
                itemName: "Plan",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
            }), () => {
                this.getPlans()
            })
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                itemName: "Project",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }

    changepage(num) {
        console.log(isNaN(num))
        if (isNaN(num)) {

            return null;
        }
        else {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => this.getPlans());
        }

    }
    search(key) {
        this.setState((state) => ({
            ...state,
            key,
            currentpage: 1
        }),
            () => this.getPlans());
        console.log(key)
    }

    componentWillMount() {
        document.title = "DroneNaksha - Plans";
        if (this.props.location.state) {
            let { user, type, project } = this.props.location.state;
            console.log(this.props);
            this.setState((state) => ({
                ...state,
                user,
                type,
                project
            }), () => {
                this.isUserOwnerOfCollection()
                API.getUserSubbedServices().then(
                    (response) => {
                        let services = response[0];
                        console.log(services);
                        if (services.length == 1 && services[0].title == 'AeroStream') {
                            this.setState((state) => ({
                                ...state,
                                showBackIcon: false
                            }))
                        } else {
                            this.setState((state) => ({
                                ...state,
                                showBackIcon: true
                            }), () => {
                                this.getPlans();
                                this.getPlansOnMap();
                            })
                        }
                    }
                )
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.getPlans();
                    this.getPlansOnMap();
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    render() {
        const { userdisplay, currentpage, userperpage } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;
        let { user, type, sidebarMode, showBackIcon, plans, project } = this.state
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: dashboardIcon,
                    text: "DroneNaksha",
                    path: "/dronenaksha/dashboard",
                },
                {
                    icon: projectActive,
                    text: "Project",
                    path: "/dronenaksha/plans",
                    selected: true,
                }
            ],
            user,
            type,
            mode: this.state.sidebarMode,
            droneNakshaHelp: true
        }
        return (
            user.username ? <div className="wrapper">
                <SideBar config={sidebarConfigurations} showMainDashboardIcons={this.state.showBackIcon} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    {/* --------------Activity Popup ----------------------- */}
                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => this.deletePlan()}
                    />
                    {/* --------------------------------------------------------------- */}
                    <Popup
                        style={{ width: "min-content" }}

                        trigger={<button id="view-image-popup-button" style={{
                            display: "none",
                        }}
                        ></button>}
                        modal
                    >
                        <img
                            id="close-popup"
                            src={CloseBtn} style={{
                                width: "auto",
                                height: "20px",
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                cursor: "pointer"

                            }} onClick={() => {
                                var style = document.createElement('style');
                                style.type = 'text/css';
                                style.innerHTML = '.popup-content { width: 770px !important;}';
                                document.getElementsByTagName('head')[0].appendChild(style);
                                document.getElementById("view-image-popup-button").click();
                                this.setState((state) => ({
                                    ...state,
                                    regions: [],
                                    scale: 0
                                }));

                            }}></img>
                        {this.state.loadingData ? <div style={{ width: '290px', height: '425px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}> */}
                            <img src={Loader} style={{ marginLeft: '100px' }} />
                            {/* </div>  */}
                        </div>
                            : <div>
                                <div className="services-content-title1" style={{ textAlign: 'center', margin: '15px 0px' }}>{this.state.selectMapPlan.plan_name}</div>
                                <div style={{ backgroundColor: '#EAEAEA', borderRadius: '0px 0px 15px 15px' }}>
                                    <div style={{ padding: '5px 15px' }}>
                                        <div className='content-title2'>
                                            <span className='content-title'>Imagesets</span>
                                            <span className='content-title1'>{this.state.planstat.image_sets}</span>
                                        </div>
                                        <hr style={{ height: '2px', margin: '0px' }} />
                                        <div className='content-title2'>
                                            <span className='content-title'>Images</span>
                                            <span className='content-title1'>{this.state.planstat.images}</span>
                                        </div>
                                        <hr style={{ height: '2px', margin: '0px' }} />
                                        <div className='content-title2'>
                                            <span className='content-title'>Tasks</span>
                                            <span className='content-title1'>{this.state.planstat.tasks}</span>
                                        </div>
                                        <hr style={{ height: '2px', margin: '0px' }} />
                                        <div className='content-title2'>
                                            <span className='content-title'>Detections</span>
                                            <span className='content-title1'>{this.state.planstat.detections_count}</span>
                                        </div>
                                        <hr style={{ height: '2px', margin: '0px' }} />
                                        <div className='content-title2'>
                                            <span className='content-title'>Orthomosaics</span>
                                            <span className='content-title1'>{this.state.planstat.orthomosaic_maps}</span>
                                        </div>
                                        <hr style={{ height: '2px', margin: '0px' }} />
                                        <div className='content-title2'>
                                            <span className='content-title'>Videos</span>
                                            <span className='content-title1'>{this.state.planstat.videos}</span>
                                        </div>
                                        <hr style={{ height: '2px', margin: '0px' }} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', padding: '15px' }}>
                                        <Link
                                            style={{ color: "#707683", }}
                                            to={{
                                                pathname: "/dronenaksha/view_plan",
                                                state: {
                                                    user,
                                                    type,
                                                    sidebarMode: this.state.sidebarMode,
                                                    plan: this.state.planFromMap,
                                                    project
                                                },
                                            }}
                                        >
                                            <button className="add-user-form-submit-button btn-ok" >
                                                View
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>}
                    </Popup>

                    <div className="top-bar">
                        <div className="breadCrumbs">
                            <BreadCrumbs data={[
                                {
                                    displayName: "projects",
                                    pathname: "/dronenaksha/projects",
                                    state: {
                                        type,
                                        user,
                                    },
                                    style: {
                                        color: "black",
                                        fontSize: "12px"
                                    }
                                },
                                {
                                    displayName: this.state.project ? this.state.project.project_name : "Plans"
                                }
                            ]} />
                            {this.state.sharedBy == null || !(this.state.sharedBy) ? <></> : <span className='dn-shared-by'>- Shared by {this.state.sharedBy.first_name} {this.state.sharedBy.last_name}</span>}
                        </div></div>
                    {/*<TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar>*/}
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> : <div className="main-content" style={{ background: 'none' }}>
                        <CreateProjectPlanModal
                            serviceName={AppConstants.SERVICES.DRONENAKSHA}
                            user={this.state.user}
                            type={this.state.type}
                            createProjectPlanModal={this.state.createProjectPlanModal}
                            closeCreateProjectPlanModal={(newItem) => {
                                if (newItem && newItem.includes("PLAN")) {
                                    this.getPlans();
                                    this.getPlansOnMap();
                                }
                                this.setState({ createProjectPlanModal: false })
                            }}
                            project={this.state.project}
                            createType={"plan"}
                        />
                        <div style={{ display: "flex", marginBottom: '10px' }}>
                            <div className="services-content-title">{"Plans"}</div>
                            <div className="add-user-form-text-wrapper" style={{ marginLeft: "auto", display: 'flex' }}>
                                <div className="imageViewButton" style={{ display: 'flex', height: '32px', marginRight: '20px' }}  >
                                    <div className={this.state.listview ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ borderRadius: '4px 0px 0px 4px', borderRight: 'solid 1px #2989cf' }}
                                        onClick={() => {
                                            this.setState((state) => ({
                                                ...state,
                                                listview: true,
                                                mapView: false,
                                                // gridView: false,
                                            }))

                                        }}>
                                        <div>List</div>
                                    </div>



                                    {/* <div className={this.state.gridView ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ borderRight: 'solid 1px #2989cf', borderLeft: 'solid 1px #2989cf' }}
                                        onClick={
                                            () => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    gridView: true,
                                                    listview: false,
                                                    mapView: false,
                                                }))
                                            }}
                                    >
                                        <div>Grid</div>
                                    </div> */}

                                    <div className={this.state.mapView ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ borderRadius: '0px 4px 4px 0px', borderLeft: 'solid 1px #2989cf' }}
                                        onClick={
                                            () => {

                                                this.setState((state) => ({
                                                    ...state,
                                                    mapView: true,
                                                    listview: false,
                                                    // gridView: false,
                                                    // showCheckboxes: false,
                                                }))
                                            }}>
                                        <div>Map</div>
                                    </div>
                                </div>
                                {this.state.isOwner ? <img src={btnAdd} onClick={() => {
                                    this.setState({ createProjectPlanModal: true })
                                }} style={{ height: '26px', width: '30px', cursor: 'pointer' }} /> : <></>}
                            </div>
                        </div>
                        {this.state.mapView ? <div>
                            <ViewImagesOnMap
                                service={AppConstants.SERVICES.DRONENAKSHA}
                                height={'82vh'}
                                width={'85vw'}
                                inspect={this.state.plansonMap}
                                icon={'plan'}
                                onClickOnPoint={idx => this.openPlanstat(idx)} />
                        </div> : <><div style={{ display: "flex", marginTop: "20px" }}>
                            <div className="services-content-title" style={{ color: '#3c3c3c' }}>{`Total Count : ${this.state.counts}`}</div>
                        </div>
                            {plans.length === 0 ?
                                <div className="rmp-dashboard-empty-data-message" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                                    <img style={{ height: '180px' }} src={NoPlan} />
                                    <p style={{ marginTop: '10px' }}>No plan available</p>
                                </div> :
                                <table className="customers-table scroll-container">
                                    <col style={{ width: '40%' }} />
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '30%' }} />
                                    <col style={{ width: '10%' }} />
                                    <tr className="customers-table-header-row">
                                        <th>Plan Name</th>
                                        <th>Tasks</th>
                                        <th>Created Date</th>
                                        <th>Images</th>
                                        <th></th>
                                    </tr>
                                    {
                                        plans.map((plan) =>
                                            <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                <td>
                                                    <Link
                                                        style={{ color: "#707683" }}
                                                        to={{
                                                            pathname: `/dronenaksha/plan`,
                                                            state: { user, type, plan, sidebarMode: this.state.sidebarMode, project }
                                                        }}
                                                    >
                                                        {plan.plan_name}<span style={{ color: "#2989cf" }}>{!this.state.isOwner ? ' (Shared)' : ''}</span>
                                                    </Link>
                                                </td>
                                                <td>{plan.tasks}</td>
                                                <td>
                                                    {
                                                        (() => {
                                                            let date = (new Date(plan.date_and_time)).toGMTString().split(' ').slice(1, 4).join(' ');
                                                            return `${date}`
                                                        })()
                                                    }
                                                </td>
                                                <td>
                                                    {plan.images == null ? 0 : plan.images
                                                    }</td>
                                                <td>
                                                    <DeleteComponentTable
                                                        style={{ margin: "0 20px" }}
                                                        onClickDelete={(e) => {
                                                            e.preventDefault()
                                                            this.setState((s) => ({
                                                                ...s,
                                                                selectedPlanId: plan.id,
                                                                itemName: "Plan",
                                                                processPopupIcon: "WARNING",
                                                                processAction: "DELETE",
                                                                processMessage: null,
                                                                showProcessPopup: true,
                                                            }))
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                </table>}
                            <footer className="footer" style={{ visibility: (this.state.counts > 8 ? "visible" : "hidden") }} >
                                <div style={{ float: "left", marginTop: "0.5rem", display: "flex" }}>
                                    <div style={{ color: "#109cf1", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                        Page : {currentpage}
                                    </div>


                                </div>
                                <div style={{ float: "right", marginTop: "0.5rem", display: "flex" }}>
                                    {this.state.currentpage == 1 ?
                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", cursor: "default", opacity: '0.5' }}>
                                            <div className="add-user-form-submit-button" >
                                                First
                                            </div>
                                        </button> :
                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", }} onClick={this.firstpage}>
                                            <div className="add-user-form-submit-button" >
                                                First
                                            </div>
                                        </button>}
                                    {this.state.currentpage == 1 ?
                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px", cursor: 'default', opacity: '0.5' }}>
                                            <div className="addcustomer-button-empty-dashboard" >
                                                Prev
                                            </div>
                                        </button> :

                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px" }} onClick={this.prevpage}>
                                            <div className="addcustomer-button-empty-dashboard" >
                                                Prev
                                            </div>
                                        </button>}
                                    <form>
                                        <div style={{ display: "flex" }}>
                                            <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                                Go to page :
                                            </div>

                                            <div className="add-user-form-text-wrapper">
                                                <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                                                    onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                                    onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                            </div>
                                        </div>
                                    </form>
                                    {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px", cursor: "default", opacity: '0.5' }}>
                                            <div className="addcustomer-button-empty-dashboard" >
                                                Next
                                            </div>
                                        </button> :
                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px" }} onClick={this.nextpage}>
                                            <div className="addcustomer-button-empty-dashboard" >
                                                Next
                                            </div>
                                        </button>
                                    }
                                    {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px", cursor: 'default', opacity: '0.5' }}>
                                            <div className="add-user-form-submit-button" >
                                                Last
                                            </div>
                                        </button> :
                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }} onClick={this.lastpage}>
                                            <div className="add-user-form-submit-button" >
                                                Last
                                            </div>
                                        </button>}
                                </div>
                            </footer></>}
                    </div>}
                </div>
            </div> : <div />
        );
    }
}

export default AeroCaptureAllPlans;

