
import React, { useState, useEffect } from 'react'
// import { StepperModal } from './StepperModal'
import Loader from '../../../icons/loader.svg'
import * as API from '../../../api.js';
import { StaticRouter, Redirect } from "react-router-dom";

// styles

const nextButton = {
    position: "absolute",
    bottom: "25px",
    right: "40px"
}

const mainContent = {
    textAlign: "initial",
    margin: "35px 0 0 15%"
}

const ImageSetOrthoSelection = (props) => {
    const [loader, setLoader] = useState(false);
    const [errorMessage, setError] = useState(undefined);
    const [collectionName, setCollectionName] = useState("");
    const [allCollections, setAllCollection] = useState(undefined);
    const [selectedImageset, setSelectedImageset] = useState(undefined);

    useEffect(() => {
        getAllCollections()
    }, [])

    const getAllCollections = () => {
        setLoader(true)
        API.getLatestImageSets("project", 1000000, props.project.id, 0).then((data) => {
            setLoader(false)
            setAllCollection(data.imageCollections)
        })
    }

    const createPlan = () => {
        return new Promise((resolve) => {
            API.checkPlan(props.project.project_name, collectionName).then(async (res) => {
                if (res.message == "Plan doesn't already exist") {
                    let dt = new Date();
                    let date = dt.getDate().toString().padStart(2, "0");
                    let month = (dt.getMonth() + 1).toString().padStart(2, "0");
                    let year = dt.getFullYear();
                    let hour = dt.getHours();
                    let minute = dt.getMinutes();
                    let seconds = dt.getSeconds();
                    let dateTime = `${year}-${month}-${date} ${hour}:${minute}:${seconds}`;
                    let Plan = {};
                    let planConfiguration = {}
                    planConfiguration.ProjectDetails = await {
                        "Date and Time": dateTime,
                        Client_Name: "dsf",
                        PlanCompletedStatus: 0,
                        Plan_Name: collectionName,
                        Project_Name: props.project.project_name,
                        projectId: props.project.id

                    };
                    Plan.planConfiguration = await planConfiguration;
                    Plan.planAddress = {};
                    Plan.missionStatistics = {
                        "missionStatistics": [{
                            "missionType": 'notGCSPlan',
                        }]
                    };
                    Plan.DGCAfence = [{}];
                    Plan.RallyPointData = [];
                    Plan.corridor = [];
                    Plan.Mission = [
                        {
                            waypoints: {

                            }
                        }

                    ];
                    Plan.Geofence = [
                        {
                            geofence: {
                                "geofence X": 0,
                                "geofence Y": 0,
                                "geofence Z": 0
                            }
                        }
                    ];
                    Plan.Assets = {
                        "planMap.png": ""
                    }
                    API.createPlan(
                        Plan
                    ).then(
                        async (data) => {
                            console.log("INSIDE DATA", data);
                            if (data.message == "OK") {
                                props.setPlan(data.plan)
                                resolve(data.plan)
                            } else if (data.message == "Plan already exists") {
                                setLoader(false)
                                setError("The given name is already exist.");
                            }
                            else if (data.error) {
                                setLoader(false)
                                setError(data.error);
                            }
                        },
                        (e) => {
                            setLoader(false)
                            setError("Something went wrong");
                        }
                    );
                }
                else {
                    setLoader(false)
                    setError("The given name is already exist.");
                }
            })
        })
    }

    //imageSet 
    const collectionNameHandler = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            setError("Spacial characters are not allowed.")
        } else if (e.target.value.includes(' ')) {
            setError("Spaces are not allowed.")
        } else {
            setError(undefined)
            setCollectionName(e.target.value)
        }
    }

    const addAnnotationSet = (collection, plan) => {
        API.addAnnotationSet(collection.id, collection.collection_name, ['test1', "test2"].toString(), plan.id).then((data) => {
            console.log(data, "anno")
            if (data.status == 201) {
                props.setAnnotationSet(data.annotationSet)
                props.setCollection(collection)
                setSelectedImageset(collection)
                setLoader(false)
            } else {
                setLoader(false)
                setError("Something Went Wrong.")
            }
        }, (e) => {

        })
    }

    const saveImageSet = async (plan) => {
        return new Promise(resolve => {
            setError(undefined)
            setLoader(true)
            API.createImageCollection(props.project.id, plan.id, collectionName).then(async (data) => {
                console.log(data);
                if (data.collection) {
                    resolve(data.collection)
                } else if (data.status == 409) {
                    setError("Imageset already exist with same name.")
                    setLoader(false);
                } else {
                    setError("Something went wrong.")
                    setLoader(false)
                }
            }, (e) => {
                setError("Something went wrong.")
                setLoader(false)
            })

        })
    }

    const createImageSet = async () => {
        if (collectionName != "" && collectionName != " " && collectionName) {
            createPlan().then(plan => {
                saveImageSet(plan).then(collection => {
                    addAnnotationSet(collection, plan)
                })
            })
        } else {
            setError("Imageset name is necessary.")
        }
    }

    const ErrorMessageComponent = () => {
        return (<div style={{
            color: "red",
            fontSize: "12px",
            padding: "5px 0 0 3px"
        }}>{errorMessage}</div>)
    }

    const LoaderComponent = () => {
        return (<div style={{ height: "65%", width: "100%", display: "flex", textAlign: "center" }}>
            <img src={Loader} style={{ width: "20%", margin: "-32px 0 0 3px" }} />
        </div>)
    }



    const onChangeCollection = (e) => {
        e.preventDefault();
        e.target.blur()
        setSelectedImageset(allCollections.find((collection) => collection.id === e.target.value))
    }

    const submitForm = (e) => {
        e.preventDefault()
        if (selectedImageset) {
            props.setCollection(selectedImageset)
        } else {
            createImageSet()
        }
    }

    return (
        <>
            <form onSubmit={submitForm}>
                <div style={mainContent}>
                    <div className="add-user-form-label" style={{ textTransform: "none" }}>
                        What would you like to call this imageset ?
                    </div>
                    <div className="add-user-form-text-wrapper">
                        <input
                            type="text"
                            maxLength={50}
                            className="add-user-form-text textbox-shadow"
                            onChange={collectionNameHandler}
                            name="project_name"
                            value={collectionName}
                            style={{ backgroundColor: selectedImageset ? 'rgba(102, 102, 102, 0.1)' : undefined }}
                            disabled={selectedImageset}
                        />
                    </div>
                    {allCollections && allCollections.length > 0 ? <>
                        <div className="add-user-form-label" style={{ textTransform: "none", margin: '20px 0px 10px 0px' }}>Select from existing imagesets</div>
                        <select
                            name="collection_id"
                            onChange={onChangeCollection}
                            value={selectedImageset ? selectedImageset.id : null}
                            className="add-user-form-text textbox-shadow"
                        >
                            <option value={0}>Select Imageset</option>
                            {
                                allCollections ?
                                    allCollections.map((collection) => {
                                        return <option value={collection.id}>{collection.collection_name}</option>
                                    })
                                    : <></>}
                        </select>
                    </> : <></>}
                    {selectedImageset || (!selectedImageset && collectionName) ? <div style={nextButton} onClick={submitForm}>Next</div> : <></>}
                    {errorMessage ? <ErrorMessageComponent /> : <></>}
                    {loader ? <LoaderComponent /> : <></>}
                </div>
            </form>
        </>
    )
}


export default ImageSetOrthoSelection;