import React, { useEffect, useState } from 'react'
import ButtonWithIcon from "./ButtonWithIcon"
const Pagination = (props) => {

    const PAGE_SIZE = props.pageSize || 6
    const [pageNumber, setPageNumber] = useState(1)
    const changePage = (pageNo) => {
        props.paginationFunction(pageNo).then(result => {
            props.paginationCallback(result, pageNo)
        })
    }
    useEffect(() => {
        props.paginationCallback(1)
    }, [])

    return (
        <>
            {props.totalCount > 6 && <div id="invoice-table-actions"
                style={{
                    marginTop: "0px",
                }}>
                <div id="invoice-table-actions-content" style={{ display: "flex", alignItems: "center", }}>
                    <ButtonWithIcon
                        text={"First"}
                        onClick={() => {
                            setPageNumber(1)
                            props.paginationCallback(1)
                        }}
                        isDisabled={pageNumber == 1}
                    />
                    <ButtonWithIcon
                        text={"Prev"}
                        onClick={() => {
                            setPageNumber(prev => {
                                props.paginationCallback(Math.max(prev - 1, 1))
                                return Math.max(prev - 1, 1)
                            })


                        }}
                        isDisabled={pageNumber == 1}
                    />
                    <span id="invoices-page-number" style={{ margin: "0px 10px" }}>{pageNumber}</span>
                    <ButtonWithIcon
                        text={"Next"}
                        onClick={() => {
                            setPageNumber(prev => {
                                let newPageNo = Math.min(prev + 1, Math.ceil(props.totalCount / PAGE_SIZE))
                                props.paginationCallback(newPageNo)
                                return newPageNo
                            })
                        }}
                        isDisabled={pageNumber == Math.ceil(props.totalCount / PAGE_SIZE)}
                    />
                    <ButtonWithIcon
                        text={"Last"}
                        onClick={() => {
                            let newPageNo = Math.ceil(props.totalCount / PAGE_SIZE)
                            setPageNumber(newPageNo)
                            props.paginationCallback(newPageNo)
                        }}
                        isDisabled={pageNumber == Math.ceil(props.totalCount / PAGE_SIZE)}
                    />
                </div>
            </div>}
        </>
    )
}

export default Pagination