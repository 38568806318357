import React, { Component } from 'react';
import * as API from '../../api'
import secureShare from '../../assets/Images/secureShare.png'

export default class AcceptInvite extends Component {
    state = {
        loading: true,
    }
    componentDidMount() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        this.setState({
            service: params.service
        })
        if (params.inv) {
            API.acceptInvite(params.inv).then((res) => {
                setTimeout(() => {
                    this.setState({ verified: true, loading: false, message: res.message })
                }, 5000)
            })
                .catch((err) => {
                    this.setState({ verified: false, loading: false })
                })
        } else {
            this.setState({ verified: false, loading: false })
        }
    }
    render() {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "95vh", flexDirection: "column", lineHeight: "35px" }}>
                <img width="230px" src={secureShare} />
                <span style={{ color: '#3C3C3C', fontSize: "20px" }}>{this.state.service ? this.state.service : "DroneNaksha"}</span>
                <span style={{ color: '#666666', fontSize: "14px" }}>
                    {this.state.loading ?
                        <>
                            Please wait while we setup secure share for you.....
                        </> :
                        <>
                            {this.state.verified ?
                                <>{this.state.message}
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <span
                                            style={{ border: "1px #2989CF solid", margin: "15px", color: "#2989CF", padding: "0 15px", cursor: "pointer", borderRadius: "20px" }}
                                            onClick={() => {
                                                window.location.replace("/login");
                                            }}
                                        >Take me there !</span>
                                    </div></> :
                                <>Something went wrong !</>
                            }
                        </>}
                </span>
            </div>
        );
    }
}
