import React, { Component } from 'react';
import * as API from './api.js';

import CustomersInactive from './customers.png';
import Loader from './icons/loader.svg';
import Users from './users.png';
import UsersStats from './res/stats.png'
import Search from "./Icon/Search.png";
import service from "./sidebaricon/Services.png";
import install from './icons/AeroGCSInactive.png'
import TaskIcon from './icons/servers.png'
import SupportIcon from './icons/support.png'
import SideBar from './SideBar';
import logList from './icons/logsActive.png'
import InfiniteScroll from 'react-infinite-scroll-component'
import dateFormat from 'dateformat';
import Refresh from './assets/Icons/refresh.png'
import detectors from './icons/detector.png'

export default class TaskLogs extends Component {

    state = {
        user: {},
        type: "",
        userperpage: 17,
        counts: 0,
        key: " ",
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        logs: [],
        interval: null,
        hasMoreItems: true,
        isLoading: true,
        changeKey: false,
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    componentWillMount() {
        document.title = "Task logs - Admin";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type
            }), async () => {
                this.getAllTaskLogs();
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), async () => {
                    this.getAllTaskLogs();
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    search(key) {
        this.setState((state) => ({
            ...state,
            key,
            changeKey: true,
            hasMoreItems: key === "" ? true : false
        }),
            () => this.refresh());
    }

    refresh = () => {
        let limit = this.state.key === "" || this.state.changeKey ? this.state.userperpage : this.state.logs.length
        if (this.state.key === "") {
            this.setState((state) => ({
                ...state,
                hasMoreItems: true
            }));
        }
        API.getAllTaskLogs(this.state.key === "" ? " " : this.state.key, 0, limit).then(async (data) => {
            if (data.logs.length == 0 || data.logs.length < limit) {
                this.setState((state) => ({
                    ...state,
                    hasMoreItems: false,
                    logs: [],
                    loading: false,
                    counts: 0,
                    changeKey: false
                }));
            }
            // data.logs = data.logs.reverse()
            this.setState((state) => ({
                ...state,
                logs: data.logs,
                loading: false,
                counts: data.logs.length,
                changeKey: false
            }))
        }, (e) => {
            console.log(e);
        })
    }

    getAllTaskLogs = async () => {
        const { userperpage } = this.state;
        setTimeout(() => {
            API.getAllTaskLogs(this.state.key === "" ? " " : this.state.key, this.state.logs.length, userperpage).then(async (data) => {
                if (data.logs.length == 0 || data.logs.length < userperpage) {
                    this.setState((state) => ({
                        ...state,
                        hasMoreItems: false
                    }));
                }
                var logs = this.state.logs
                // data.logs = data.logs.reverse()
                // logs = data.logs.concat(logs)
                logs = logs.concat(data.logs)
                this.setState((state) => ({
                    ...state,
                    logs: logs,
                    loading: false,
                    counts: logs.length,
                }))
            }, (e) => {
                console.log(e);
            })
        }, 500);
    }

    getAllTaskLogsAPICall() {

    }
    //For auto refresh
    // componentDidMount() {
    //     const interval = setInterval(this.refresh, 2000);
    //     this.setState((state) => ({
    //         ...state,
    //         interval : interval
    //     }))
    // }
    // componentWillUnmount() {
    //     clearInterval(this.state.interval)
    // }
    render() {
        const { id, user, type, logs } = this.state;
        const loader = <div style={{ height: "10vh", width: "85vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={Loader} style={{ height: "10vh", width: "50vw", margin: "auto" }} />
        </div>
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: UsersStats,
                    text: "Statistics",
                    path: "/admin/users_stats",
                },
                {
                    icon: CustomersInactive,
                    text: "Add NPNT Customer",
                    path: "/admin/add_npnt_customer"
                },
                {
                    icon: Users,
                    text: "Users",
                    path: "/admin/users",
                },
                {
                    icon: install,
                    text: "Installations",
                    path: "/admin/gcs-installation",
                },
                {
                    icon: TaskIcon,
                    text: "Tasks",
                    path: "/admin/tasks",
                },
                {
                    icon: logList,
                    text: "Task Logs",
                    path: "/admin/taskLogs",
                    selected: true,
                },
                {
                    icon: detectors,
                    text: "Detectors",
                    path: "/admin/detectors"
                },
                {
                    icon: SupportIcon,
                    text: "Support",
                    path: "/admin/supportEmails",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode
        }
        return (
            this.state.user && this.state.user.first_name ? <div className="wrapper">
                <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    style={{ zIndex: 0 }}
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar" style={{ display: "flex" }}><div className="top-bar-text">Task Logs</div></div>
                    <div className="main-content" style={{ paddingBottom: "0px" }}>
                        <div className="add-user-form-text-wrapper" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div className="services-content-title"> Task Logs : {this.state.counts}</div>
                            <div>
                                <img style={{ width: '20px', height: "20px", marginLeft: '8px', cursor: 'pointer' }} src={Refresh}
                                    onClick={() => {
                                        this.refresh();
                                    }} />
                                <img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
                                <input type="text" className="add-user-form-text" placeholder="Search logs by task name, action or VM IP" style={{ height: '30px !important', width: "330px", paddingLeft: '28px' }} onChange={(event) => this.search(event.target.value)} />
                            </div>
                        </div>
                        <div style={{ marginTop: "1%", height: "525px", width: "100%", background: "#FFFFFF", overflowY: "auto", overflowX: "hidden" }}>
                            <InfiniteScroll
                                className='scroll-container'
                                height={"525px"}
                                dataLength={this.state.logs.length} //This is important field to render the next data
                                next={() => {
                                    this.getAllTaskLogs()
                                }}
                                style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
                                // inverse={this.state.logs.length < 17 ? false : true}
                                hasMore={this.state.hasMoreItems}
                                loader={loader}
                                refreshFunction={this.refresh}
                                // pullDownToRefresh
                                endMessage={
                                    <p style={this.state.logs.length == 0 ?
                                        { display: "flex", height: "100%", alignItems: "center", justifyContent: "center" }
                                        : { display: "flex", justifyContent: "center" }
                                    }>
                                        <b>{this.state.logs.length == 0 ? "No logs found" : "You have seen it all"}</b>
                                    </p>
                                }>
                                <div>
                                    {this.state.logs.map((log, i) => {
                                        return (<div style={{ margin: "10px", display: "flex", justifyContent: "left", fontSize: "13px" }} key={i}>
                                            <div>{dateFormat(log.log_timestamp, 'dd-mm-yyyy hh:mm:ss')} : {log.task_id} - [{log.task_name ? log.task_name : "-"}]</div>
                                            <div style={{ color: log.severity_level == 5 ? 'red' : log.severity_level == 2 ? "green" : '#2989cf', margin: "0px 1%" }}>{log.event_details}</div>
                                            <div> {`[updated by - ${log.first_name ? log.first_name : 'VM'} ${log.last_name ? log.last_name : ''}]`} </div>
                                            <div style={{ color: 'brown', margin: "0px 1%" }}>{`{ Assigned VM : ${log.ip_address} }`}</div>
                                        </div>)
                                    })}
                                </div>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </div> : <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
            </div>
        );
    }
}
