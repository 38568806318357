import React, { useEffect, useState } from 'react'
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal'
import * as API from "../../api"
import AppConstants from '../../AppConstants'
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup'
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon'
import Loader from "../../../src/icons/loader.svg"
import { BUY_MORE_DRONES, CANCEL_DRONE_ADDON, CANCEL_SUBSCRIPTION } from '../../Profile/ProfileSettings'
const AdjustQuantityPopup = (props) => {
    const [userDroneQuantity, setUserDroneQuantity] = useState(0)
    const [quantity, setQuantity] = useState(0)
    const [loading, setLoading] = useState(true)
    const [note, setNote] = useState({ type: "", msg: "" })
    const handleCancelAddon = () => {
        props.setActivityPopup({
            showCancelSubsPopup: true,
            cancelOkAction: CANCEL_DRONE_ADDON,
            cancelSubsText: "Are you sure you want to cancel Drone add-on?",
            cancellingSubText: "Cancelling drone add-on please wait...",
        })
    }
    const handleDroneSubPayment = () => {
        props.setActivityPopup({
            showProcessPopup: true,
            processMsg: "Please wait while we update your subscription...",
            processIcon: "WAIT"
        })
        fetch(`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_PORT}/updateDroneSub/${props.dronePlan.id}/${props.dronePlan?.price_id}?email=${props.user.username}&quantity=${quantity - userDroneQuantity}`, { method: "POST" }).then(async res => {

            const data = await res.json()
            props.onRequestClose()
            if (data.success) {
                if ((Number(quantity) - Number(userDroneQuantity)) > 0) {

                    props.setActivityPopup({
                        processTitle: "Attention",
                        processMsg: "If you have credit balance available,it will be applied for this payment otherwise you will be redirected for the payment.",
                        processOkAction: BUY_MORE_DRONES,
                        processIcon: ""
                    })

                }
                else {

                    props.setActivityPopup({
                        processIcon: "COMPLETE",
                        processMsg: "Subscription updated successfully.",

                    })
                    props.callback();

                }

            }

            else
                props.setActivityPopup({
                    processMsg: "Something went wrong while updating your subscription!",
                    processIcon: "ERROR"
                })


        })

    }
    useEffect(() => {

        API.getUserDroneLimits(AppConstants.SERVICES.DRONE_CONTROL_CENTER).then(res => {
            setQuantity(res.addOnDronesLimits)
            setUserDroneQuantity(res.addOnDronesLimits)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })

    }, [])
    useEffect(() => {
        if (props.pendingPayments.length > 0)
            setNote({ type: "Attention", msg: "Payments are due,you cannot modify quantity." })
        else
            setNote({ type: "", msg: "" })
    }, [props.pendingPayments.length])
    return (
        <>
            <AnimatedModal
                isOpen={props.show}
                onRequestClose={props.onRequestClose}
                height={"auto"}
            >
                {/* <div>
                    <button onClick={() => setQuantity(prev => Math.max(prev - 1, 0))}>-</button>
                    <div>{quantity}</div>
                    <button onClick={() => setQuantity(prev => prev + 1)}>+</button>
                    <button onClick={handleDroneSubPayment}>Apply</button>
                </div> */}


                <div style={{
                    boxShadow: "0px 0px 0px 1px rgb(235,238,241)",
                    background: "white",
                    height: "",
                    width: "700px",
                    marginTop: "",
                    overflow: "hidden",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <div style={{ backgroundColor: "#EAEAEA", height: "40px", display: "flex", justifyContent: "center", alignItems: "center", font: "15px", color: "#3c3c3c", fontWeight: "500" }}> AEROGCS ADD ON DRONES </div>

                    {loading ?
                        <div style={{ height: "100%", minWidth: "200px", width: "100%", display: "flex", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={Loader} style={{ height: "100%", width: "200px", margin: "auto", paddingLeft: "50px" }} />
                        </div> :
                        <>
                            <div style={{ padding: "20px 100px 5px 100px", borderBottom: "", textAlign: "left", position: "relative", display: "flex", flexDirection: "column", gap: "10px", height: "100%" }}>

                                <table>
                                    <col style={{ width: '50%' }} />
                                    <col style={{ width: '50%' }} />
                                    <tr style={{ borderTop: "1px solid lightgray", borderBottom: "1px solid lightgray" }} >
                                        <th style={{ color: "rgb(65,69,82)", fontWeight: "700", padding: "10px 0px", textAlign: "left" }}>Product</th>
                                        <th style={{ color: "rgb(65,69,82)", fontWeight: "700", padding: "10px 65px", textAlign: "right" }}>Qty</th>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                                        <td style={{ color: "rgb(65,69,82)", fontWeight: "500", padding: "10px 0px", textAlign: "left" }}><div>
                                            <div style={{ fontSize: "13px", fontWeight: "500" }}>{(!loading && props.dronePlan.plan_name) || "--"}</div>
                                            <div style={{ fontSize: "11px", fontWeight: "500", color: "#2989cf" }}>{(!loading && props.dronePlan.plan_type) || "--"}</div>
                                        </div></td>
                                        <td style={{ color: "rgb(65,69,82)", fontWeight: "500", padding: "10px 0px", textAlign: "right", display: "flex", justifyContent: "right" }}>
                                            <div style={{ marginLeft: "", fontWeight: "400", fontSize: "14px", display: "flex", alignItems: "center", columnGap: "5px" }}>
                                                <ButtonWithIcon isDisabled={props.pendingPayments.length > 0} text={"-"} onClick={() => setQuantity(prev => Math.max(prev - 1, 0))} />
                                                <div style={{ transition: "0.5s all", width: "45px", padding: "5px", borderRadius: "5px", outline: "none", border: "1px solid lightgray", textAlign: "center" }}>{quantity}</div>
                                                <ButtonWithIcon isDisabled={props.pendingPayments.length > 0} text={"+"} onClick={() => setQuantity(prev => prev + 1)} />
                                            </div>
                                        </td>
                                    </tr>
                                </table>

                                {/* {note.type && <div style={{ fontSize: "10px", color: "red" }}>
                                    <span style={{ fontWeight: "500", color: note.type === "Attention" ? "#f44336" : "#2989cf", }}>{note.type}: </span>
                                    <span style={{ color: "#687385" }}>{note.msg}</span>
                                </div>} */}
                                {note.type && <div style={{ fontSize: "10px", color: "red" }}>
                                    <span style={{ fontWeight: "500", color: note.type === "Attention" ? "#f44336" : "#2989cf", }}>{note.type}: </span>
                                    <span style={{ color: "#687385" }}>{note.msg}</span>
                                </div>}
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: " 20px 100px" }}>
                                <ButtonWithIcon text={"Back"} height={"30px"} padding={"6px 20px 10px"} onClick={props.onRequestClose} />
                                <ButtonWithIcon isBtnActive height={"30px"} padding={"6px 20px 10px"} isDisabled={props.pendingPayments.length > 0 || ((Number(quantity) - Number(userDroneQuantity)) === 0)} text={quantity > 0 ? "Apply" : "Cancel Add-on"} onClick={quantity > 0 ? handleDroneSubPayment : handleCancelAddon} />
                            </div>
                        </>
                    }
                </div>
            </AnimatedModal>
        </>
    )
}

export default AdjustQuantityPopup