import React, { Component } from 'react';
import * as API from './api.js';
import backIcon from "./back.svg";

import CustomersInactive from './customers.png';
import Users from './users.png';
import UsersStats from './res/stats.png'
import service from "./sidebaricon/Services.png";
import { Link, StaticRouter, Redirect } from 'react-router-dom';
import SideBar from './SideBar';
import install from './icons/AeroGCSInactive.png'
import TaskIcon from './icons/servers.png'
import SupportIcon from './icons/support.png'
import logList from './icons/logList.png'

export default class AdminEditUsers extends Component {

    state = {
        user: {},
        type: "",
        users: {
            first_name: "",
            last_name: "",
            username: "",
            address: "",
            password: "",
            phone: "",
            password: "-"
        },
        redirect: false,
        successParams: {
            title: "EDIT USER",
            message1: "User has been edited successfully",
            message2: "To go back to dashboard, click",
            hereLink: "/",
            buttonText: "View Users",
            buttonLink: "/admin/users"
        },
        config: {},
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk")
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    submitEditUser = (e) => {
        e.preventDefault()
        API.Useredit(this.state.users).then((message) => {
            console.log("success")
            this.setState((state, users) => ({
                ...state,
                users,
                redirect: true
            }))
        }, (e) => {
            console.log(e)
        })
    }

    onChangeListener = (e) => {
        let t = e.target;
        this.setState((state) => ({
            ...state,
            users: {
                ...state.users,
                [t.name]: t.name === 'email' ? t.value.toLowerCase() : t.value
            }
        }))
    }




    componentWillMount() {
        document.title = "Edit Users - Management Services";
        if (this.props.location.state) {
            let { user, type, first_name, last_name, email, address, phone, password, userid, config } = this.props.location.state;
            
            this.setState((state) => ({
                ...state,
                user,
                type,
                users: {
                    first_name,
                    last_name,
                    username: email,
                    address,
                    phone,
                    password,
                    userid
                },
                config:
                {
                    options:
                        [
                            {
                                icon: backIcon,
                                text: "Back",
                                path: "/"
                            },
                            {
                                icon: null,
                                text: "hr",
                                path: null
                            },

                            {
                                icon: UsersStats,
                                text: "Statistics",
                                path: "/admin/users_stats",
                            },

                            {
                                icon: CustomersInactive,
                                text: "Add NPNT Customer",
                                path: "/admin/add_npnt_customer"
                            },

                            {
                                icon: Users,
                                text: "Users",
                                path: "/admin/users"
                            },
                            {
                                icon: install,
                                text: "Installations",
                                path: "/admin/gcs-installation",
                            },
                        ],
                    user,
                    type,
                    mode: this.state.sidebarMode

                },

            }), () => {
            })
        } else {
            window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : "") + "/management_services/users")
        }
    }

    successRedirectComponent = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/success",
                state: {
                    ...this.state.successParams,
                    user: this.state.user,
                    type: this.state.type,
                    sidebarMode: this.state.sidebarMode,
                    config: this.state.config
                }
            }} />
        }
    }
    render() {
        let { user, type, customerDisplay, organizations } = this.state
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
               
                {
                    icon: UsersStats,
                    text: "Statistics",
                    path: "/admin/users_stats",
                },
                {
                    icon: CustomersInactive,
                    text: "Add NPNT Customer",
                    path: "/admin/add_npnt_customer"
                },

                {
                    icon: Users,
                    text: "Users",
                    path: "/admin/users",
                    selected:true,
                },
                {
                    icon: install,
                    text: "Installations",
                    path: "/admin/gcs-installation",
                },
                {
                    icon: TaskIcon,
                    text: "Tasks",
                    path: "/admin/tasks",
                },
                {
                    icon: logList,
                    text: "Task Logs",
                    path: "/admin/taskLogs",
                },
                {
                    icon: SupportIcon,
                    text: "Support",
                    path: "/admin/supportEmails",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode
        }

        return (
            user.username ? <div className="wrapper">
                <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar"><div className="top-bar-text">EDIT USER</div></div>
                    <div className="main-content">
                        <div className="page" style={{ height: "562px" }}>
                            <div className="services-content-title">{"User Information"}</div>
                            {this.successRedirectComponent()}
                            <form onSubmit={this.submitEditUser} action={this.submitEditUser}>
                                <div className="add-user-form-row">
                                    <div className="add-user-form-row-left">
                                        <div className="add-user-form-label">
                                            First Name
                                        </div>
                                        <div className="add-user-form-text-wrapper">
                                            <input name="first_name" type="text" className="add-user-form-text" placeholder="eg. Scott" required onChange={this.onChangeListener} value={this.state.users.first_name} />
                                        </div>
                                    </div>
                                    <div className="add-user-form-row-right">
                                        <div className="add-user-form-label">
                                            Last Name
                                        </div>
                                        <div className="add-user-form-text-wrapper">
                                            <input name="last_name" type="text" className="add-user-form-text" placeholder="eg. Smith" onChange={this.onChangeListener} value={this.state.users.last_name} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="add-user-form-row">
                                    <div className="add-user-form-row-left">
                                        <div className="add-user-form-label">
                                            Email
                                        </div>
                                        <div className="add-user-form-text-wrapper">
                                            <input name="username" type="email" className="add-user-form-text" placeholder="eg. scott.smith@pdrl.in" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" onChange={this.onChangeListener} value={this.state.users.username} />
                                        </div>
                                    </div>
                                    <div className="add-user-form-row-right">
                                        <div className="add-user-form-label">
                                            Phone Number
                                        </div>
                                        <div className="add-user-form-text-wrapper">
                                            <input name="phone" type="text" className="add-user-form-text" pattern="^\d{3}\d{3}\d{4}$" placeholder="eg. 9978645321" onChange={this.onChangeListener} value={this.state.users.phone} required />
                                        </div>
                                    </div>
                                </div>

                                <div className="add-user-form-row">
                                    <div className="add-user-form-row-left">
                                        <div className="add-user-form-label">
                                            Address
                                        </div>
                                        <div className="add-user-form-text-wrapper">
                                            <input name="address" type="text" className="add-user-form-text" placeholder="eg. Mountain View, California, United States Zip: 1600" required onChange={this.onChangeListener} value={this.state.users.address} />
                                        </div>
                                    </div>
                                </div>

                                <div className="add-user-form-action-row">
                                    <button type="submit" className="add-user-form-submit-button">
                                        Save
                                    </button>
                                    <Link className="addcustomer-button-empty-dashboard" to={{
                                        pathname: "/admin/users",
                                        state: {
                                            user,
                                            type,
                                            sidebarMode: this.state.sidebarMode
                                        }
                                    }} style={{marginLeft: "10px", textDecoration: "none"}}>
                                        Cancel
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> : <div />
        );
    }
}
