import React, { useState } from 'react'
import { AnimatedModal } from './AnimatedModal'
import ButtonWithIcon from './ButtonWithIcon'
import closeBtn from "../../src/icons/closeButton.png"
const ModeSelector = (props) => {
    return (
        <>
            <AnimatedModal
                width={'440px'}
                height={"150px"}
                zIndex={5}
                onRequesClose={props.closeModeSelector}
                isOpen={props.showModeSelector}
            >
                <div style={{ position: "relative", padding: "30px", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", width: "100%" }}>
                    <div style={{ position: "absolute", right: "20px", top: "20px", display: "flex", alignItems: "center", justifyContent: "center", width: "15px", height: "15px" }}>
                        <img src={closeBtn} style={{ width: "100%", height: "100%", cursor: "pointer",opacity:"0.8" }} onClick={props.closeModeSelector} />
                    </div>
                    <div style={{ color: "#666666", fontWeight: "500", fontSize: "18px" }}>{props.heading}</div>
                    <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                        {props.modes.map(mode => <ButtonWithIcon
                            isIconLeft
                            fontSize={"12px"}
                            text={mode.text}
                            icon={mode.icon}
                            iconWidth={"15px"}
                            onClick={() => {
                                mode.onClick()
                               props.closeModeSelector()
                            }} />)}
                    </div>
                </div>
            </AnimatedModal>
        </>
    )
}

export default ModeSelector