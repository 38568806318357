import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import ReactTooltip from "react-tooltip";
import { TRAININGTOOL, TESTINGTOOL, ACCURACYTOOL, DETECTIONTOOL, POLYGON, POINTER, BOX, CIRCLE, UPDATEREGION, UPDATECLASS, FIRENOTALLOWEDERROR, OUTSIDEORTHOREGIONERROR, AREAOVERLAP, UPDATEREGIONSANDCLASSES, FILETYPES, DEFAULTCLASSNAME, LINEINTERSECTITSELF, CLEARDETECTIONS, EDITDETECTION, DELETEDETECTION, DETECTIONCOLOR, CLEARAREAS } from "./Constants"
import Annotation from "./Annotation";
import AppConstants, { SOCKET_IO_MESSAGES } from "../../../AppConstants";
import { Popup, calculateCenterOfRegion, getLimitedName, getSingleGeoJSON, importShapeFile, isTaskEditable } from "../../../ReusableComponents/reusableFunctions";
import pointerActive from '../../../icons/cursorActive.png';
import pointerInactive from "../../../icons/cursorWhite.png"
import * as API from "../../../api"
import DETECTION_TOOL_ACTIVE from "../../../icons/accuracyActive.png"
import DETECTION_TOOL_INACTIVE from "../../../icons/accuracy.png"
import locateWhiteIcon from "../../../assets/Icons/locateMeWhite.png"
import { PICSTORK_FEATURES } from "../../../Teams";
import ButtonWithIcon from "../../../ReusableComponents/ButtonWithIcon";
import { v4 as uuid } from 'uuid'
var utmObj = require('utm-latlng');
var utm = new utmObj();

const SingleToolView = (props) => {
    return <div className={props.disabled ? "AeroFarm" : "hoverPointer AeroFarm"}
        onClick={(e) => {
            if (props.disabled) {

            } else props.onClick(e)

        }}
        style={{ padding: "8px 1px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
        <img src={props.icon} data-tip data-for={props.tittle} width={props.width ? props.width : '22px'} style={{ opacity: props.disabled ? 0.5 : 1 }} />
        <ReactTooltip offset={{ left: 10 }} id={props.tittle} place="left" type="light" effect="solid"><span>{props.tittle}</span></ReactTooltip>
    </div>
}
export const DetectionAreaAnnotationTool = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        saveDetectionAreas
    }))
    const [message, setMessage] = useState("Draw the area")
    const [activeAnnotationTool, setActiveAnnotationTool] = useState(POINTER)
    const [leafletDraw, setLeafletDraw] = useState(undefined)
    const onClickTool = (tool) => {
        setActiveAnnotationTool(activeAnnotationTool === tool ? POINTER : tool)
        leafletDraw.enableTool(activeAnnotationTool === tool ? POINTER : tool)
    }
    const isTaskHasEditAccess = () => {
        return (props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].EDIT && !props.isDisabled && isTaskEditable(props.task.tasktype == 2 ? AppConstants.TASK.STATUS.CREATING : props.task.status, props.task.sharedBy))
    }
    const clearAllDetectionAreas = () => {
        const task_parameters = { ...props.task.task_parameters, detection_areas: [] }
        API.updateTaskDetails(props.task.task_id, { task_parameters }).then(res => {
            const newTask = props.task;
            newTask.task_parameters = task_parameters
            props.updateTask(newTask)
        }).catch(err => {
            Popup.alert("ERROR", err?.errorMessage || err?.message || err)
        })
    }
    const saveDetectionAreas = () => {
        return new Promise((resolve) => {

            const detection_areas = leafletDraw.detectionArr.map(d => d.coordinates)
            const task_parameters = {
                ...props.task.task_parameters,
                detection_areas
            }
            Popup.alert("WAIT", "Saving detection areas, please wait")
            API.updateTaskDetails(props.task.task_id,
                {
                    task_parameters
                }).then(async res => {
                    let newTask = props.task;
                    newTask.task_parameters = task_parameters
                    props.updateTask(newTask)
                    Popup.close()
                    resolve()
                }).catch(err => {
                    resolve()
                    Popup.alert("ERROR", err?.errorMessage || err?.message || err)
                })
        })


    }

    const showMessages = (dets) => {
        if (dets) {
            if (dets.length > 0) {
                setMessage(`You have drawn ${dets.length} polygon${dets.length > 1 ? "s" : ""} for detection`)
            } else {
                setMessage(`Draw the area`)
            }
        }
        if (props.task.state > 3) {
            setMessage(`Detection areas cannot be edited.`)
        }
    }
    const getDetectionAreas = async (leafletDraw) => {
        let detection_areas = props.task.task_parameters.detection_areas
        if (detection_areas) {
            showMessages(detection_areas)
            detection_areas = await Promise.all(detection_areas.map(d => {
                return new Promise(async (resolve) => {
                    resolve({ id: uuid(), subType: DETECTIONTOOL, color: DETECTIONCOLOR, coordinates: d, type: POLYGON })
                })
            }))
            leafletDraw.drawAreas(detection_areas)
        }
    }
    useEffect(() => {
        showMessages(leafletDraw?.detectionArr)
    }, [leafletDraw?.detectionArr?.length])
    useEffect(() => {
        let leafletDraw
        if (props.map && props.bounds) {
            leafletDraw = new Annotation(() => { }, () => { }, { map: props.map, imageBounds: props.bounds, mapPanes: props.mapPanes }, activeAnnotationTool, [], (updatedLeafletClass, action) => {
                if (action === CLEARAREAS) {
                    clearAllDetectionAreas()
                }
                setLeafletDraw(updatedLeafletClass)
            })
            leafletDraw.setUpdating(isTaskHasEditAccess())
            leafletDraw.task = props.task
            setLeafletDraw(leafletDraw)
            getDetectionAreas(leafletDraw)
        }
        return () => {
            if (leafletDraw)
                leafletDraw.destroy()
        }
    }, [props.map])
    return <>
        {leafletDraw ? <div style={{ width: '100%', height: '0px' }}>

            <div id="copySelectedAnnotation" className="" onClick={() => leafletDraw.enableSelectedLayerCopy(!(leafletDraw.selectedRegion && leafletDraw.selectedRegion.copying))} />
            <div id="editSelectedAnnotation" className="" onClick={() => leafletDraw.setSelectedRegionEditable(!(leafletDraw.selectedRegion && leafletDraw.selectedRegion.editable))} />
            <div id="deleteSelectedAnnotation" className="" onClick={() => leafletDraw.deleteSelectedRegion()} />
            <div id="editSelectedAnnotationArea" className="" onClick={() => leafletDraw.setSelectedAreaEditable(!(leafletDraw.selectedArea && leafletDraw.selectedArea.editable))} />
            <div id="deleteSelectedAnnotationArea" className="" onClick={() => leafletDraw.deleteSelectedArea()} />
            <div id="draw-tool" style={{
                right: '20px',
                top: "-20px",
                fontSize: "9px",
                height: 'fit-content',
                // width: '35px',
                zIndex: '1',
                borderRadius: '7px',
                // background: 'rgba(0,0,0,0.8)',
                backgroundColor: "black",
                padding: '5px 10px',
                // margin: props.task.tasktype == 2 ? "40px 0px 0px -40px" : "5px 0px 0px -40px",
                margin: (props.task.tasktype == 2 || props.task.tasktype == 4) ? "40px 0px 0px -50px" : "5px 0px 0px -50px",
                position: 'absolute'
            }}>
                {/* Pointer */}
                <SingleToolView
                    onClick={() => {
                        onClickTool(POINTER)
                    }}
                    tittle={POINTER}
                    icon={activeAnnotationTool === POINTER ? pointerActive : pointerInactive}
                />
                {/* detection area */}
                <SingleToolView
                    onClick={() => {
                        onClickTool(DETECTIONTOOL)
                    }}
                    // disabled={true}
                    disabled={leafletDraw && (!isTaskHasEditAccess())}
                    tittle={"Detection Tool"}
                    icon={activeAnnotationTool === DETECTIONTOOL ? DETECTION_TOOL_ACTIVE : DETECTION_TOOL_INACTIVE}
                />
                {/* Recenter */}
                <SingleToolView
                    onClick={props.setMapCenter}
                    tittle={"Recenter"}
                    icon={locateWhiteIcon}
                />
            </div>
            {
                // props.isDisabled ?
                //     <></> :
                //     <div style={{ display: 'flex', gap: "20px", position: "absolute", bottom: "20px", right: "20px", zIndex: 999 }}>

                //         <ButtonWithIcon isBtnActive text={"Next"} fontSize={"11px"} width={"92px"} onClick={saveDetectionAreas} />
                //     </div>
            }
            <div style={{ background: "white", borderRadius: "10px", padding: "10px 20px", color: "#3c3c3c", fontSize: "12px", position: 'absolute', left: "20px", top: "20px", boxShadow: '0px 0px 8px 2px rgba(0,0,0,0.15)', fontWeight: 500, minWidth: "200px" }}>
                {message}
            </div>

        </div> : <></>}
    </>
})