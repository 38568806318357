import React from 'react'

const ImageCard = (props) => {
    return (
        <div onClick={e=>{
            if(e.target.type !== 'checkbox') {
                props.onClick(e)
            }
        }} key={props.key} className="hover-image-container" style={{
            // boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)',
            width: "100%",
            margin: "",
            cursor: "pointer",
            borderRadius: "6px",
            overflow: "hidden",
            position: "relative"

        }}>
            {/* top part of imagecard */}
            <div style={{ position: "absolute", top: "0", left: "0", width: "100%", display: "flex", justifyContent: "space-between", zIndex: "2", padding: "4px" }}>
                {props.TL || <div style={{ width: "10px" }}></div>}
                {props.TR || <div style={{ width: "10px" }}></div>}
            </div>
            {/* this is img */}
            <div className={'hover-image'} style={{
                width: "100%",
                height: "100%",
                backgroundImage: `url(${props.img})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
            }}></div>
            {/* this is information about img */}
            <div style={{ position: "absolute", bottom: "0", width: "100%", left: "0", backgroundColor: "rgba(0,0,0,0.5)", padding: "3px 10px" }}>
                {/* top info div */}
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "", fontSize: "11px", textAlign: "left" }}>
                    {props.infoTL}
                    {props.infoTR}
                </div>
                {/* bottom info div */}
                <div style={{ display: "flex", justifyContent: "space-between", fontSize: "8px", textAlign: "" }}>
                    {props.infoBL}
                    {props.infoBR}
                </div>
                <div></div>
            </div>
        </div>
    )
}

export default ImageCard