import { FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE } from "./userTypes";
import { fetchUser } from "./userActions";
import axios from 'axios'

const initialState = {
  loading: false,
  users: ['vijay'],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fetchUser:
       return {
         ...state,
         users:['vijay','diapk']
       }
              
          
        default :
        return state
        

  }
  //   case FETCH_USERS_REQUEST:
  //     return {
  //       ...state,
  //       loading:true,
  //     };
  //     case FETCH_USERS_SUCCESS:
  //       return {
  //         ...state,
  //         loading:false,
  //         users:action.payload,
  //         error:''
  //       };
  //       case FETCH_USERS_FAILURE:
  //           return {
  //             ...state,
  //             loading:false,
  //             users:[],
  //             error:action.payload
  //           };
  //   default:
  //     return state;
  // }
};
export default reducer;
