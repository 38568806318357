import React, { useEffect, useState } from 'react';
import * as API from "../../../api.js";
import MapAnnotation from '../MapAnnotation';
import L from "leaflet";
import "leaflet.pattern"
import { POLYGON, BOX, CIRCLE, TRAININGCOLOR, TESTINGCOLOR, ACCURACYCOLOR } from "../../Components/MapAnnotation/Constants"
import LoaderComponent from '../../../ReusableComponents/LoaderComponent.js';
import AppConstants, { TASK } from '../../../AppConstants.js';
let darkLayer;
const TrainingDetectionResult = (props) => {
    const [map, setMap] = useState(null)
    const [imageArr, setImageArr] = useState([]);
    const [selectedImageForAnnotation, setSelectedImageForAnnotation] = useState()
    const [loading, setLoading] = useState(true)
    const mapInitialized = (map) => {
        darkLayer = L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: 'abcd',
            maxZoom: 20,
            opacity: 0.7
        }).addTo(map);

    }
    const getImages = () => { //this function get images and set them in an array
        API.getImageCollectionImages(props.task.image_collection_id, " ", 10000, 0).then(async data => {
            let resImages = []
            if (data.images.length > 0) {
                for (const image of data.images) {
                    const { resImage, isValidImage } = await getAnnotation(image)
                    if ((props.task.status == TASK.STATUS.COMPLETED ? resImage.detectorAccuracy !== "N/A" : true) && isValidImage) {
                        resImages.push(resImage)
                    }
                }
                setImageArr(resImages)
                setSelectedImageForAnnotation({ ...resImages[0], key: 0 })
            }
            setLoading(false)
        })

    }

    useEffect(() => {
        getImages()
    }, [])

    const getAnnotation = (image) => {
        return new Promise((resolve, reject) => {

            API.getAnnotation(image.file_name, image.collection_id, props.task.annotation_set.id).then(data => {
                let imgData = { ...image }

                let regionsData = []
                if (data.data) {
                    regionsData = data.data.annotationData.features.map((obj) => {
                        return ({
                            id: obj.properties.id,
                            className: obj.properties.className,
                            coordinates: obj.geometry.coordinates[0].map(latLng => {
                                return {
                                    lat: latLng[0],
                                    lng: latLng[1]
                                }
                            }),
                            type: obj.properties.type || obj.geometry.type,
                            color: props.task.classes.find((cls) => cls.name === obj.properties.className).color
                        })
                    })
                }
                imgData.drawnRegions = regionsData

                let detectedRegions = []
                if (data?.data?.annotationData?.accuracyAreaDetections) {
                    detectedRegions = data.data.annotationData.accuracyAreaDetections
                }
                imgData.detectedRegions = detectedRegions

                let areas = []
                if (data.data) {
                    areas = [...(data.data.annotationData.trainingAreas || []), ...(data.data.annotationData.testingAreas || []), ...(data.data.annotationData.accuracyAreas || [])]
                }
                imgData.areas = areas;

                let detectorAccuracy = "N/A"
                if (data.data) {
                    detectorAccuracy = (props.task.status == AppConstants.TASK.STATUS.COMPLETED && typeof (data.data.annotationData.detectorAccuracy) === "number") ? data.data.annotationData.detectorAccuracy + "%" : "N/A"
                }
                imgData.detectorAccuracy = detectorAccuracy

                imgData.totalAnnotations = regionsData.length


                let classLabels = regionsData.map(region => {
                    return { type: region.className, color: region.color }
                })

                props.setLegendDetails(prev => {
                    let trainingAreaColor = TRAININGCOLOR;
                    let testingAreaColor = TESTINGCOLOR;
                    let accuracyAreaColor = ACCURACYCOLOR
                    return { ...prev, trainingAreaColor, testingAreaColor, accuracyAreaColor, classLabels, }
                })
                let isValidImage = true;
                if (props.task?.task_parameters?.valid_images) {
                    isValidImage = props.task.task_parameters.valid_images.find(file => file.file_name == image.file_name) ? true : false
                }
                resolve({ resImage: imgData, isValidImage })

            })
        })

    }
    const drawRegions = async (drawingRegions, m) => {
        return new Promise(async (resolve) => {
            let grp = new L.layerGroup()
            await Promise.all(drawingRegions.map(async (region, index) => {
                return new Promise((resolve) => {

                    if (region.type === POLYGON) {
                        let polygon = new L.Polygon(region.coordinates, {
                            name: region.id,
                            color: region.color,
                            fillColor: region.color,
                            pane: "annotation",
                            fillOpacity: 0.1,
                            weight: 3,
                        }).addTo(grp)
                    }
                    if (region.type === BOX) {
                        let box = new L.Rectangle(region.coordinates, {
                            name: region.id,
                            pane: "annotation",
                            color: region.color,
                            fillColor: region.color,
                            fillOpacity: 0.1,
                            weight: 3,
                        }).addTo(grp)
                    }
                    if (region.type === CIRCLE) {
                        let circle = new L.Circle(region.coordinates.latLng, region.coordinates.radius, {
                            name: region.id,
                            color: region.color,
                            pane: "annotation",
                            fillColor: region.color,
                            fillOpacity: 0.1,
                            weight: 3,
                        }).addTo(grp)
                    }
                    resolve()
                })

            }))
            grp.addTo(m)
            resolve(grp)
        })
    }
    const drawAreas = async (drawingRegions, m) => {
        return new Promise(async (resolve) => {
            let grp = new L.layerGroup()
            await Promise.all(drawingRegions.map(async (region, index) => {
                return new Promise((resolve) => {

                    let polygon = new L.Polygon(region.coordinates, {
                        name: region.id,
                        color: region.color,
                        fillColor: region.color,
                        pane: "Areas",
                        fillOpacity: 0.1,
                        weight: 3,
                        dashArray: '5,5',
                        dashOffset: '20'
                    })

                    polygon.addTo(grp)
                    resolve()
                })
            }))
            grp.addTo(m)
            resolve(grp)
        })

    }


    const drawDetectedRegions = async (drawingRegions, m) => {
        var pattern = new L.StripePattern({ opacity: 1, color: "red", weight: 2, spaceWeight: 1 });
        pattern.addTo(m)
        let grp = new L.layerGroup()
        return new Promise(async (resolve) => {
            await Promise.all(drawingRegions.map(async (region, index) => {
                if (region.type === POLYGON) {
                    let polygon = new L.Polygon(region.coordinates, {
                        name: region.id,
                        color: "red",
                        fillPattern: pattern,
                        pane: "annotation",
                        fillOpacity: 1,
                        weight: 3,
                    }).addTo(grp)
                }
                if (region.type === BOX) {
                    let box = new L.Rectangle(region.coordinates, {
                        name: region.id,
                        pane: "annotation",
                        color: "red",
                        fillPattern: pattern,
                        fillOpacity: 1,
                        weight: 3,
                    }).addTo(grp)
                }
                if (region.type === CIRCLE) {
                    let circle = new L.Circle(region.coordinates.latLng, region.coordinates.radius, {
                        name: region.id,
                        color: "red",
                        fillPattern: pattern,
                        pane: "annotation",
                        fillOpacity: 1,
                        weight: 3,
                    }).addTo(grp)
                }

            }))
            grp.addTo(m);
            resolve(grp)
        })

    }


    const startDrawing = async (selectedImg, m) => {
        if (selectedImg) {
            const { drawnRegions, detectedRegions, areas, totalAnnotations, detectorAccuracy, drawnRegionsLayer, areasLayer, detectedRegionsLayer } = selectedImg
            if (drawnRegions && drawnRegions?.length > 0) {
                if (drawnRegionsLayer && m.hasLayer(drawnRegionsLayer)) {
                } else {

                    const leafletLayer = await drawRegions(drawnRegions, m);
                    setImageArr(prev => prev.map(img => {
                        if (img.file_name == selectedImg.file_name) {
                            return { ...selectedImg, drawnRegionsLayer: leafletLayer }
                        } else return img
                    }))
                }
            }
            if (areas && areas?.length > 0) {
                if (areasLayer && m.hasLayer(areasLayer)) {
                }
                else {
                    const leafletLayer = await drawAreas(areas, m)
                    setImageArr(prev => prev.map(img => {
                        if (img.file_name == selectedImg.file_name) {
                            return { ...selectedImg, areasLayer: leafletLayer }
                        } else return img
                    }))
                }
            }
            if (detectedRegions && detectedRegions?.length > 0 && (props.task.status == AppConstants.TASK.STATUS.COMPLETED)) {
                if (detectedRegionsLayer && m.hasLayer(detectedRegionsLayer)) {

                } else {
                    const leafletLayer = await drawDetectedRegions(detectedRegions, m)
                    setImageArr(prev => prev.map(img => {
                        if (img.file_name == selectedImg.file_name) {
                            return { ...selectedImg, detectedRegionsLayer: leafletLayer }
                        } else return img
                    }))
                }
            }
            props.setLegendDetails(prev => ({ ...prev, totalAnnotations, detectorAccuracy }))
        }
    }
    return (
        <div style={{ width: "100%", height: "100%", padding: "10px", background: "white", position: 'relative' }}>
            {(!loading && imageArr.length > 0) && selectedImageForAnnotation ?
                <MapAnnotation
                    showImageSliderDefault={true}
                    orthoList={{ report: imageArr.map((img, index) => ({ ...img, image: img.file_name })), key: 0 }}
                    showSlider
                    user={props.user}
                    permissions={props.permissions}
                    selectedImageForAnnotation={selectedImageForAnnotation}
                    setSelectedImageForAnnotation={(data, callback) => {
                        setSelectedImageForAnnotation({ ...data, validTiff: true })
                        callback()
                    }}
                    task={props.task}
                    onOrthoLoad={(m) => {
                        setMap(m)
                        mapInitialized(m)
                        startDrawing(selectedImageForAnnotation, m)
                    }} /> : <LoaderComponent />}
        </div>
    )
}

export default TrainingDetectionResult