import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import axios from 'axios';
import * as API from '../../../src/api.js';
import qs from 'qs';
import { Line, Circle } from 'rc-progress';
import service from "../../sidebaricon/Services.png";
import Loader from '../.././icons/loader.svg';
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs';
import { BLOB_URL } from '../../AppConstants.js';

class AeroStreamDownloadProgress extends Component {
    state = {
        user: {},
        type: "",
        progress: 0,
        cancel: false,
        loading: true,
    }
    download = (url) => {
        const documentStyles = document.documentElement.style;
        let progress = 0;
        this.setState((state) => ({
            ...state,
            cancel: false,
            progress: 0
        }))
        const setProgress = (progress) => {
            this.setState((state) => ({
                ...state,
                progress: progress
            }))
        }
        const method = ' GET';
        const responseType = 'blob';
        axios(
            {
                url,
                responseType: "blob",
                onDownloadProgress(progressEvent) {
                    progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setProgress(progress)
                }
            }).then((response) => {
                if (this.state.cancel == false) {
                    this.setState((state) => ({
                        ...state,
                    }))
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.state.file_name} ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}.mp4`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }

            });
    };
    componentWillMount() {
        document.title = "PicStork - Download Progress";
        this.setState((state) => ({
            ...state,
            loading: false,
        }), () => {
            // window.open(`${process.env.REACT_APP_CLIENT_PROTOCOL}://${process.env.REACT_APP_CLIENT_HOST}${process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : ""}/aerocapture/download/video-detection-result?prid=${this.state.task.project_id}&plid=${this.state.task.plan_id}&fname=${videourl.result.file_name}&st${user.sas_token}`);
            let plan_id = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).plid;
            let project_id = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).prid;
            let file_name = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).fname;
            let sas_token = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).sas;
            let blob_container = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).container;
            //    `${BLOB_URL}/${user.blob_container}/DetectionVideos/${this.state.task.project_id}/${this.state.task.plan_id}/${videourl.result.file_name}?${user.sas_token}`
            let videoURL = `${BLOB_URL}/${blob_container}/DetectionVideos/${project_id}/${plan_id}/${file_name}?${sas_token}`
            this.setState((state) => ({
                ...state,
                videoURL,
                file_name
            }), () => {
                this.download(this.state.videoURL)
            })
        })
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type
            }))
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }))
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }
    render() {
        let { user, type, request } = this.state
        return (
            user.username ? <div className="wrapper">
                <div className="right-content">
                    <Modal
                        style={{ marginTop: '150px' }}
                        show={this.state.showDownloadPopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                cancel: true

                            }));
                        }}
                    >
                        <Modal.Body >
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        cancel: true
                                    }));
                                }}
                            >
                                cancel
                            </button>
                        </Modal.Footer>
                    </Modal>
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> : <div className="main-content" style={{ background: 'none' }}>
                        <p>{`Downloading ${this.state.file_name} ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}.mp4 file ${this.state.progress}%`}</p>
                        <div >
                            <Line percent={this.state.progress} strokeWidth="1" strokeColor="#2989cf" />
                            {
                                this.state.progress == 100 ? <p style={{ color: 'green' }}>Done </p> : ''
                            }
                        </div>
                    </div>}
                </div>
            </div> : <div />
        );
    }
}
export default AeroStreamDownloadProgress;