import React, { useState } from 'react';
import closeButton from '../close-button.png';
import Loader from '../icons/loader.svg';
import circleLeft from '../assets/Icons/prev.png';
import circleRight from '../assets/Icons/next.png';
import Popup from 'reactjs-popup';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
const ImageViewer = (props) => {
    const [imageLoad, setImageLoad] = useState()
    return (
        <Popup
            style={{ width: "min-content" }}
            open={false}
            modal
        ><img
                id="close-popup"
                src={closeButton} style={{
                    width: "auto",
                    height: "25px",
                    position: "absolute",
                    top: "-12px",
                    right: "-12px",
                    cursor: "pointer"

                }} onClick={() => {
                    var style = document.createElement('style');
                    style.type = 'text/css';
                    style.innerHTML = '.popup-content { width: 800px !important; border-radius:15px;}';
                    document.getElementsByTagName('head')[0].appendChild(style);
                    document.getElementById("view-image-popup-button").click();
                    // this.setState((state) => ({
                    //     ...state,
                    //     regions: [],
                    //     scale: 0,
                    //     imageLoad: false,
                    // }));

                }} />
            <div style={{ display: 'flex' }} >
                <div className='flex-center' style={{ alignItems: 'end', background: "#666666", width: "790px", height: '567px', borderRadius: '15px', overflow: "hidden" }}>
                    {imageLoad ?
                        <img className='image-popup' src={Loader} style={{ position: "absolute", zIndex: 5, height: "100%", width: "8vw", marginLeft: "10%" }} /> : ''
                    }
                    <TransformWrapper>
                        {({ zoomIn, zoomOut, centerView, resetTransform, ...rest }) => (
                            <TransformComponent>
                                <img id="action-image" style={{ zIndex: 0, opacity: props.imageLoad ? 0 : 1 }} className='image-popup' loading='lazy'
                                    src={props.images && props.images.length ? props.images[props.Idx].url : ''}
                                    onLoad={((load) => {
                                        centerView()
                                        resetTransform()

                                    })} />
                            </TransformComponent>
                        )}
                    </TransformWrapper>

                    {props.images && props.images.length ?
                        <div className='imageinfo'>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="imagePopupTitle">{props.images[props.Idx].name.length > 50 ? props.images[props.Idx].name.substring(0, 50) : props.images[props.Idx].name}</div>
                                <div className="imagePopupSubTitleTile">
                                    <div className="imagePopupSubTitle">{props.images[props.Idx].resolution} MP</div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="imagePopupSubTitle">
                                    {/* {props.images[props.Idx]?.latitude == null || props.images[props.Idx]?.latitude == "" ? "" : `${props.images[props.Idx]?.latitude?.length > 11 ? `${props.images[props.Idx]?.latitude?.substring(0, 11)}` : `${props.images[props.Idx].latitude}`}`} {props.images[props.Idx].longitude == null || props.images[props.Idx].longitude == "" ? `` : `${props.images[props.Idx].longitude.length > 11 ? `, ${props.images[props.Idx].longitude.substring(0, 11)}` : `, ${props.images[props.Idx].longitude}`}  ${props.images[props.Idx].altitude ? `, ${props.images[props.Idx].altitude}` : ``}`} */}
                                </div>
                                <div className="imagePopupSubTitleTile">
                                    <div className="imagePopupSubTitle">{props.images[props.Idx].size} MB</div>
                                </div>
                            </div>
                        </div> : ''}
                    {props.images && props.images.length > 1 ? < div className='nextprevfunction'>
                        {
                            props.Idx == 0 ?
                                <div className='prevDiv'>
                                    <img className='prev' src={circleLeft} style={{ opacity: '0.5' }} />
                                </div> :
                                <div className='prevDiv' onClick={this.prevImg}>
                                    <img className='prev' src={circleLeft} style={{ cursor: 'pointer' }} />
                                </div>

                        }
                        {
                            props.Idx == props.images.length - 1 ?
                                <div className='nextDiv'> <img className='next' src={circleRight} style={{ opacity: '0.5' }}
                                /> </div> :
                                <div className='nextDiv' onClick={this.nextImg} >
                                    <img className='next' src={circleRight} style={{ cursor: 'pointer' }} />
                                </div>
                        }

                    </div> : ''
                    }
                </div>
            </div>
        </Popup>
    )
}

export default ImageViewer