import React, { useState, useEffect } from 'react'
import AppConstants from '../AppConstants'
import { getLimitedName } from '../ReusableComponents/reusableFunctions'
const CalenderTile = ({ weekDay, displayDay, onClick, currDay, activeDay, dayNo, currMonth, currYear, tasks, atProjectLevel,vacant }) => {
  const [recentTasks, setRecentTasks] = useState([])
  useEffect(() => {
    setRecentTasks(tasks?.filter(t => {
      const taskDate = new Date(t.due_date)
      const taskDay = taskDate.getDate();
      const taskMonth = taskDate.getMonth();
      const taskYear = taskDate.getFullYear();
      return taskDay == dayNo && taskMonth == currMonth && taskYear == currYear
    }).sort((a, b) => new Date(a.due_date).getTime() > new Date(b.due_date).getTime()).slice(0, 3))
  }, [tasks, currMonth, currYear,dayNo])

  return (
    <div className="hoverHighlightClass" style={{ cursor: vacant?"default":"pointer", outline: "1px solid rgb(218,220,224)", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "", padding: "0px 5px", backgroundColor:vacant?"rgb(102 102 102 / 6%)":activeDay?.currDay === dayNo && currMonth == activeDay?.currMonth && currYear == activeDay?.currYear ? "#2989CF26" : "" }} onClick={() =>!vacant && onClick()} >
      <div style={{ fontWeight: "500", fontSize: atProjectLevel ? "10px" : "15px", color: "rgb(128 128 128 / 67%)", display: "flex", alignItems: "center", justifyContent: "end", width: "100%" }}>{weekDay}</div>
      <div style={{ fontWeight: "400", fontSize: atProjectLevel ? "15px" : "15px", color: currDay == dayNo && currMonth == new Date().getMonth() && currYear == new Date().getFullYear() ? "#2989cf" : "grey", display: "flex", alignItems: "center", justifyContent: "end", width: "100%" }}>{displayDay}</div>
      {<div style={{ display: "flex", flexDirection: atProjectLevel?"":"column", width: "100%", alignItems: "center",justifyContent:atProjectLevel?"center":"",marginTop:atProjectLevel?"auto":"",marginBottom:atProjectLevel?"10px":"" }}>
        {
         !vacant && recentTasks?.map(task => { return atProjectLevel ? <div style={{ width: "10px", height: "10px",marginRight:"5px", backgroundColor: task.status === "Completed" ? AppConstants.PLANNER.STATUS.COMPLETED : task.status === "In Progress" ? AppConstants.PLANNER.STATUS.IN_PROGRESS : task.status === "To do" ? AppConstants.PLANNER.STATUS.TO_DO : "", borderRadius: "50%" }}></div> : <div style={{ marginRight: "30px", backgroundColor: task.status === "Completed" ? AppConstants.PLANNER.STATUS.COMPLETED : task.status === "In Progress" ? AppConstants.PLANNER.STATUS.IN_PROGRESS : task.status === "To do" ? AppConstants.PLANNER.STATUS.TO_DO : "", borderRadius: "4px", fontWeight: "500", margin: "4px", padding: "2px 10px", color: "white", width: "100%",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap" }} className='calender-tile'>{task.description}</div> })
        }
      </div>}
    </div>
  )
}

export default CalenderTile