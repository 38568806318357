import Modal from 'react-modal'
import { CSSTransition } from 'react-transition-group';
import React from 'react'

/**
To use this popup you have to pass following props
  1) onRequestClose as function
  2) if You want to give width then pass width otherwise it take 41% by default
  3) if You want to give height then pass width otherwise it take 50% by default

*/
export const AnimatedModal = (props) => {

    const modalStyles = {
        content: {
            width: props.width ? props.width : "41%" ,
            height:props.height ?  props.height : "50%",
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: "10px",
            // textAlign: 'center',
            padding:"0px",
            boxShadow: '0px 0px 17px -4px rgba(0, 0, 0, 0.27)',
            validationColor: "red"
        },
    };
    
    return (
        <>
            <CSSTransition
                in={props.isOpen}
                timeout={300}
                classNames="alert"
                unmountOnExit
            >
                <Modal
                    isOpen={true}
                    onRequestClose={props.onRequestClose}
                    style={modalStyles}
                >
                    {props.children}
                </Modal>
            </CSSTransition>
        </>
    )
}
