import React, { useEffect, useState } from 'react';
import TopArrow from "../icons/sortUpActive.png"
const DirectScrollComponent = ({ containerRef, direction, left, right, top, bottom,width,height,backgroundColor,icon,padding,showInitial }) => {
    const [takeToTopVisible, setTakeToTopVisible] = useState(false)
    const scrollToTop = () => {
        containerRef.current.scrollTo({ top: direction === "top" ? 0 : containerRef.current.scrollHeight, behavior: "smooth" })
    }
    const toggleVisible = () => {
        if (direction === "top") {

            const scrolled = containerRef.current.scrollTop;
            if (scrolled > 20) {
                setTakeToTopVisible(true)
                console.log("on")
            }
            else if (scrolled <= 20) {
                setTakeToTopVisible(false)
            }
        } else {
            if (
                containerRef.current.scrollHeight - containerRef.current.scrollTop ===
                containerRef.current.clientHeight
            ) {
                setTakeToTopVisible(false)
            } else {
                setTakeToTopVisible(true)
            }
        }
    };
    const handleContainerScroll = () => {
        if (containerRef.current) {
            containerRef.current.addEventListener("scroll", toggleVisible)
        }
    }
    useEffect(() => {
       showInitial && toggleVisible()
        handleContainerScroll()
        return ()=>{
            if(containerRef?.current){
                
                containerRef.current.removeEventListener("scroll", toggleVisible)
            }
        }
    }, [])
    return (
        <>
            {takeToTopVisible && <div style={{ position: "absolute", left: left || "", top: top || "", bottom: bottom || "", right: right || "", width:width|| "25px", height:height|| "25px", border: "1px solid grey", padding: padding||"5px", backgroundColor:backgroundColor|| "white", zIndex: "99", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={scrollToTop}><img style={{ width: "100%", height: "100%", transform: direction === "bottom" ? "rotate(180deg)" : "" }} src={icon||TopArrow} /></div>}
        </>
    )
}

export default DirectScrollComponent