import React from 'react'
import Loader from '../icons/loader.svg'

// you can change following things using props
// loader imgWidth
// div heigh width 
// and loader message 

const LoaderComponent = (props) => {
    return (<div style={{ position: props.position, zIndex: props.zIndex, background: props.background, justifyContent: 'center', height: props.height || "100%", width: props.width || "100%", display: "flex", textAlign: "center", alignItems: 'center', flexDirection: 'column' }}>
        <img src={Loader} style={{ width: props.imgWidth || "175px", margin: props.imgMargin || "0px 0 -50px 80px" }} />
        <div className="content-text" >{props.message || "Loading, please wait..."}</div>
    </div>)
}

export default LoaderComponent