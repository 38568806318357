import React, { useState } from "react";
import Plus from '../../../assets/Icons/plus.png'
import { AnimatedModal } from "../../../ReusableComponents/AnimatedModal";
import GoogleDriveIcon from "../../../../src/icons/drive.png"
import SelectImageSet from '../../../../src/assets/SelectImageset.png'
import SelectTiff from '../../../../src/assets/SelectTiff.png';
import ImportTiff from '../../../../src/assets/ImportTiff.png';
import ImportZip from "../../../../src/assets/zip-import.png"
import DroneNakshaMapsImporter from "./DroneNakshaMapsImporter";
import CloseButton from '../../../icons/closeButton.png';
import LoaderComponent from "../../../ReusableComponents/LoaderComponent";
import AppConstants from "../../../AppConstants";
import ButtonWithIcon from "../../../ReusableComponents/ButtonWithIcon";
import { ActivityPopup } from "../../../ReusableComponents/ActivityPopup";
import ZipImporter from "../../../ReusableComponents/ZipImporter";
import ModeSelector from "../../../ReusableComponents/ModeSelector";
import LOCAL from "../../../../src/icons/local.png"
import GDRIVE from "../../../../src/icons/gdrive.png"

const ImageTypeCard = ({ onClick, src, header, description, styles }) => {
    return <div className='taskSelectionCard' style={{ width: "190px", height: "", margin: 0 }}
        onClick={onClick}
    >
        <div style={{ display: "flex", justifyContent: "center" }}>
            <img
                src={src}
                style={{
                    margin: "0px 10px 10px 10px",
                    height: "100px",
                    width: "100%",
                    ...styles?.imgStyle
                }}
            />
        </div>
        <div style={{ margin: '5px 0px' }} className='taskSelectionTitle'>
            {header}
        </div>
        <div style={{ textAlign: "center", fontSize: "12px" }}>
            {description}
        </div>

    </div>
}
const ImagesTypeSelection = (props) => {
    const [showDronNakshaMaps, setShowDronNakshaMaps] = useState(false)
    const [selectImagePopup, setSelectImagePopup] = useState(false)
    const [loaderMsg, setLoaderMsg] = useState(null)
    const [showProcessPopup, setShowProcessPopup] = useState(false)
    const [selectedApplicationType, setSelectedApplicationType] = useState(undefined);
    const [fileTypeForInput, setFileTypeForInput] = useState(undefined);
    const [showModeSelector, setShowModeSelector] = useState(false)
    const [modeSelectorData, setModeSelectorData] = useState({ heading: "", modes: [] })
    const applicationTypeList = [
        {
            name: AppConstants.PICSTORK_APPLICATION_TYPES.WINDMILL.NAME,
            onClick: () => {
                setSelectedApplicationType(AppConstants.PICSTORK_APPLICATION_TYPES.WINDMILL.NAME);
                setFileTypeForInput(".zip");
                setShowProcessPopup(true);
                setSelectImagePopup(false);
            }
        }
    ]
    const TypeSelection = <div style={{ padding: '10px 10px', display: 'flex', flexDirection: 'column', gap: '20px' }}>

        <div style={{ display: 'flex', color: "#212529", justifyContent: "center", width: '100%', columnGap: '30px' }}>

            {/* normal images */}
            {props.cards?.upload_images && <ImageTypeCard
                onClick={() => {
                    if (props.cards.DN) {
                        setSelectImagePopup(false);
                        props.onSelectTypeForUpload(AppConstants.FILETYPES.PNGJPEG);
                    } else {

                        setShowModeSelector(true)
                        setModeSelectorData({
                            heading: "Select mode of upload",
                            modes: [{
                                text: "My computer", icon: LOCAL, onClick: () => {
                                    props.onSelectTypeForUpload(AppConstants.FILETYPES.PNGJPEG);
                                    setFileTypeForInput(".jpg, .jpeg, .png");
                                    setSelectImagePopup(false);
                                }
                            },
                            {
                                text: "Google drive", icon: GDRIVE, onClick: () => {
                                    props.onSelectTypeForUpload(AppConstants.FILETYPES.PNGJPEG, "GDRIVE");
                                    setFileTypeForInput(".jpg, .jpeg, .png");
                                    setSelectImagePopup(false);
                                }
                            }]
                        })
                    }
                }}
                src={SelectImageSet}
                header={'Upload Images'}
                description={'JPEG or PNG image'}

            />}

            {props.cards?.import_from_googledrive && <ImageTypeCard
                onClick={() => {
                    props.onSelectTypeForUpload(AppConstants.FILETYPES.PNGJPEG, "GDRIVE")
                    setFileTypeForInput(".jpg, .jpeg, .png");
                    setSelectImagePopup(false);
                }}
                styles={{ imgStyle: { height: '31%', width: '67%' } }}
                src={GoogleDriveIcon}
                header={'Import from Google Drive'}
                description={'JPEG or PNG image'}

            />}

            {/* Upload Orthomosaic */}
            {props.cards?.upload_tiff && <ImageTypeCard
                onClick={() => {
                    props.onSelectTypeForUpload(AppConstants.FILETYPES.TIF)
                    setSelectImagePopup(false)
                }}
                src={SelectTiff}
                header={'Upload orthomosaic tiff'}
                description={'COG compatible orthomosaic tiff'}

            />}

            {/* Import Orthomosaic from DN */}
            {props.cards?.import_from_dn && <ImageTypeCard
                onClick={() => setShowDronNakshaMaps(true)}
                src={ImportTiff}
                header={'Import from DroneNaksha'}
                description={'Import orthomosaic map from DroneNaksha'}

            />}


            {/* Windmill */}
            {props.cards?.import_zip && <ImageTypeCard
                onClick={() => {
                    setShowModeSelector(true)
                    setModeSelectorData({
                        heading: "Select application type",
                        modes: [{
                            text: "Windmill", onClick: () => {
                                setSelectedApplicationType(AppConstants.PICSTORK_APPLICATION_TYPES.WINDMILL.NAME);
                                setFileTypeForInput(".zip");
                                setShowProcessPopup(true);
                                setSelectImagePopup(false);
                            }
                        }]
                    })
                }
                }
                src={ImportZip}
                header={'Import ZIP'}
                description={<>ZIP Containing < br /> Images</>}

            />}
        </div>

        <div style={{ width: '100%', display: 'flex', justifyContent: 'end', margin: !(props.disableOrthomosaic) ? "-3px 0 0 -13px" : "5px 0 0 -10px" }}>
            <ButtonWithIcon
                text="cancel"
                width="80px"
                isBtnActive
                onClick={() => setSelectImagePopup(false)}
            />
        </div>
    </div >

    const onSelectMaps = async (maps) => {
        if (maps) {
            setLoaderMsg("Importing maps from DroneNaksha...")
            await props.importSelectedOrtho(maps)
            setSelectImagePopup(false)
        } else setShowDronNakshaMaps(false)
    }

    const closeActivityPopup = () => {
        setSelectedApplicationType(undefined)
        setShowProcessPopup(false);
    }

    const onSelectTypeForUploadZip = () => {
        setShowProcessPopup(false);
        document.getElementById("hiddenFileInputUpload").click();
    }


    return <>
        <ZipImporter
            uploadedImageCount={props.uploadedImageCount}
            fileType={props.uploadedImageCount > 0 ? "jpg, .jpeg, .png" : fileTypeForInput}
            collectionId={props.collectionId}
            selectedApplicationType={selectedApplicationType}
            onImageUpload={() => {
                // alert("file Uploaded 100 %");
                props.uploadOk()
            }}
        />

        <ActivityPopup
            width="940px"
            item={"WARNING"}
            open={showProcessPopup}
            icon={"WARNING"}
            // action={this.state.processAction}
            msg={"Make sure you are uploading .ZIP File. Do you want to continue ?"}
            close={() => closeActivityPopup()}
            onClickOk={() => {
                onSelectTypeForUploadZip()
            }}
        />
        {/* mode of upload selection animated modal */}

        <ModeSelector
            showModeSelector={showModeSelector}
            closeModeSelector={() => {
                setShowModeSelector(false)
            }}
            heading={modeSelectorData?.heading}
            modes={modeSelectorData.modes} />
        <AnimatedModal
            height={showDronNakshaMaps ? '530px' : "max-content"}
            width={(showDronNakshaMaps ? '730px' : props?.AnimatedModalStyle?.width || "900px")}
            zIndex={5}
            onRequestClose={() => {
                setSelectImagePopup(false)
                setShowDronNakshaMaps(false)
            }}
            isOpen={selectImagePopup}
        >
            {loaderMsg ? <LoaderComponent
                width="98%"
                height="85%"
                message={loaderMsg}
            /> : <>
                {showDronNakshaMaps ?
                    <>
                        <div style={{ position: 'absolute', margin: '12px 0px 0px 95%' }}>
                            <img onClick={() => setShowDronNakshaMaps(false)} src={CloseButton} style={{ cursor: 'pointer', height: '20px' }} />
                        </div>
                        <div className="services-content-title" style={{ width: '100%', height: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#EAEAEA', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                            Select DroneNaksha Map
                        </div>
                        <div style={{ padding: '10px 20px', height: '86%', width: '100%' }}>
                            <DroneNakshaMapsImporter
                                cardHeight="130px"
                                padding="10px 30px"
                                cardWidth="180px"
                                user={props.user}
                                cardGap="30px"
                                onClickBack={() => onSelectMaps(false)}
                                onSelectMaps={onSelectMaps}
                                permissions={props.permissions}
                            />
                        </div>
                    </> : TypeSelection}
            </>}

        </AnimatedModal>

        <ButtonWithIcon
            text="Images"
            icon={Plus}
            onClick={() => {
                setLoaderMsg('')
                setShowDronNakshaMaps(false)
                if (props?.fromTrainingTask) {
                    if (props?.fromTrainingTask.isTiff) props.onSelectTypeForUpload(AppConstants.FILETYPES.TIF)
                    else props.onSelectTypeForUpload(AppConstants.FILETYPES.PNGJPEG)
                }
                else setSelectImagePopup(true)
            }}
            isDisabled={props.isDisabled}
        />
    </>

}

export default ImagesTypeSelection