import React, { useEffect, useState } from 'react'
import * as API from "../api"
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon'
import { AnimatedModal } from './Reusable/AnimatedModal'
import { ActivityPopup } from '../ReusableComponents/ActivityPopup'
import { Dropdown } from 'react-bootstrap'
import Menu from '../AeroCapture/assets/icons/menu.svg';
import Loader from '../icons/loader.svg';
const DiscountCouponTable = (props) => {

    const [coupons, setCoupons] = useState([])
    const [showAddCouponPopup, setShowAddCouponPopup] = useState(false)
    const [showEditCouponPopup, setShowEditCouponPopup] = useState(false)
    const [discountCoupon, setDiscountCoupon] = useState({})
    const [activityPopup, setActivitypopup] = useState({})
    const [componentData, setComponentData] = useState({ user: "", type: "" })
    const [componentLoading, setComponentLoading] = useState(false)

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <img src={Menu} alt="I am alt" />
        </a>
    ));

    const addDiscountCoupon = () => {
        setActivitypopup({
            showProcessPopup: true,
            processPopupIcon: "WAIT",
            processMessage: "Adding discount coupon,please wait...",
        })
        API.addDiscountCoupon(componentData.user.userid, discountCoupon).then(res => {
            setCoupons([...coupons, res.coupon[0]])
            setActivitypopup({
                showProcessPopup: true,
                processPopupIcon: "COMPLETE",
                processMessage: "Discount coupon created successfully!",
            })
            setShowAddCouponPopup(false)

        }).catch(err => {
            setActivitypopup({
                showProcessPopup: true,
                processMessage: "Something went wrong while adding discount coupon",
                processPopupIcon: "ERROR"
            })
        })
    }
    const editDiscountCoupon = () => {
        setActivitypopup({
            showProcessPopup: true,
            processMessage: "Saving changes...",
            processPopupIcon: "WAIT"
        })
        API.editDiscountCoupon(discountCoupon).then(res => {
            const updatedCoupon = res.updatedCoupon[0]
            setCoupons(prev => {
                const newCouponlist = prev.map(c => {
                    if (c.id === updatedCoupon.id) {
                        return { ...c, ...updatedCoupon }
                    } else return c
                })
                return newCouponlist
            })
            setActivitypopup({
                processMessage: "Coupon updated successfully!",
                processPopupIcon: "COMPLETE"
            })
            setShowEditCouponPopup(false)
        }).catch(err => {
            console.log(err);
            setActivitypopup({
                processMessage: "Something went wrong while updating coupon!",
                processPopupIcon: "ERROR"
            })
        })
    }
    const deleteDiscountCoupon = (coupon_id) => {
        setActivitypopup({
            showProcessPopup: true,
            processMessage: "Deleting coupon please wait...",
            processPopupIcon: "WAIT"
        })
        API.deleteDiscountCoupon(coupon_id).then(res => {
            setActivitypopup({ showProcessPopup: true, processMessage: "Coupon deleted successfully", processPopupIcon: "COMPLETE" })
            setCoupons(coupons.filter(coupon => coupon.id !== res.deletedCoupon[0].id))
        }).catch(err => {
            console.log(err)
            setActivitypopup({ showProcessPopup: true, processMessage: "Something went wrong while deleting discount coupon", processPopupIcon: "ERROR" })
        })
    }
    const disableDiscount = () => {
        const inputVal = discountCoupon.discount
        if (inputVal) {
            if (inputVal.includes(".")) {
                const realNum = inputVal.split(".")[0]
                const decimal = inputVal.split(".")[1]
                if (Number(realNum) >= 0 && Number(realNum) <= 9) {
                    if (decimal.length > 2) return true
                } else if (Number(realNum) > 9) {
                    if (decimal.length > 2) return true
                }
            }
            if (inputVal.length <= 0) return true
            if (Number(inputVal) < 0.01) return true
            if (Number(inputVal) > 100) return true
            return false
        }
        return true
    }
    useEffect(() => {
        setComponentLoading(true)
        API.getUserType().then(([blah, type, user]) => {
            setComponentData({
                ...componentData,
                user,
                type
            })
        }, (e) => {
            window.location.replace("/login");
        })
        API.getAllDiscountCoupons().then(res => {
            setCoupons(res.coupons)
            setComponentLoading(false)

        })
    }, [])
    return (
        <div className='main-content'>
            {componentLoading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
            </div> :
                <>
                    <ActivityPopup
                        item={activityPopup.itemName}
                        open={activityPopup.showProcessPopup}
                        icon={activityPopup.processPopupIcon}
                        action={activityPopup.processAction}
                        msg={activityPopup.processMessage}
                        onClickOk={activityPopup.onClickOk ? activityPopup.onClickOk : () => setActivitypopup({ showProcessPopup: false })}
                        close={() => setActivitypopup({ showProcessPopup: false })}
                    />


                    {/* this is popup for add coupon */}
                    <AnimatedModal
                        width={"500px"}
                        height={"max-content"}
                        isOpen={showAddCouponPopup}
                        onRequestClose={() => {
                            setShowAddCouponPopup(false)
                        }}
                    >
                        <div style={{}}>

                            <div style={{ textAlign: "center", padding: "20px 30px 20px 30px", height: '', fontSize: '20px', fontWeight: '500', borderBottom: "1px solid rgba(0,0,0,.1)" }}>Add Coupons</div>
                            <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "20px 30px 30px 30px" }}>
                                <div style={{}}>
                                    <div className='add-user-form-label'>Coupon Name</div>
                                    <input
                                        className="sub-add-user-form-text"
                                        type='text' placeholder='Optional'
                                        value={discountCoupon.coupon_name}
                                        onChange={(e) => {
                                            setDiscountCoupon({ ...discountCoupon, coupon_name: e.target.value })
                                        }} />
                                </div>
                                <div>
                                    <div style={{}}>
                                        <div className='add-user-form-label'>Percent Off</div>
                                        <input
                                            className="sub-add-user-form-text"
                                            type="number" id="percentageInput" name="percentageInput" step="0.01" min="0.01" max="100"
                                            placeholder='%'
                                            value={discountCoupon?.discount}
                                            onChange={(e) => {
                                                const val = e.target.value
                                                setDiscountCoupon({ ...discountCoupon, discount: val })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "20px" }}>
                                    <ButtonWithIcon
                                        padding={"5px 20px"}
                                        text={"Cancel"}
                                        onClick={() => {
                                            setShowAddCouponPopup(false)
                                        }}
                                    />
                                    <ButtonWithIcon
                                        padding={"5px 20px"}
                                        isBtnActive
                                        text={"Add Coupon"}
                                        onClick={() => {
                                            addDiscountCoupon()
                                        }}
                                        isDisabled={disableDiscount()}
                                    />
                                </div>
                            </div>
                        </div>
                    </AnimatedModal>



                    {/* this is popup for edit coupon */}
                    <AnimatedModal
                        width={"500px"}
                        height={"max-content"}
                        isOpen={showEditCouponPopup}
                        onRequestClose={() => {
                            setShowEditCouponPopup(false)
                        }}
                    >
                        <div style={{}}>

                            <div style={{ textAlign: "center", margin: '0px 0px', height: '', fontSize: '20px', fontWeight: '500', padding: "20px 30px 20px 30px", borderBottom: "1px solid rgba(0,0,0,.1)" }}>Edit Coupon</div>
                            <div style={{ padding: "20px 30px 30px 30px" }}>
                                {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px 0px" }}> */}
                                <div style={{}}>
                                    <div className='add-user-form-label'>Coupon Name</div>
                                    <input className="sub-add-user-form-text" type='text' placeholder='' value={discountCoupon.coupon_name} onChange={(e) => {
                                        setDiscountCoupon({ ...discountCoupon, coupon_name: e.target.value })
                                    }} />
                                </div>
                                {/* </div> */}
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "20px" }}>
                                    <ButtonWithIcon
                                        padding={"5px 20px"}
                                        text={"Cancel"}
                                        onClick={() => {
                                            setShowEditCouponPopup(false)
                                        }}
                                    />
                                    <ButtonWithIcon
                                        padding={"5px 20px"}
                                        text={"Save"}
                                        isBtnActive
                                        onClick={() => {

                                            editDiscountCoupon()
                                        }}
                                        isDisabled={Number(discountCoupon.discount) <= 0 || Number(discountCoupon.discount) > 100 || discountCoupon.discount == "e"}
                                    />
                                </div>
                            </div>
                        </div>
                    </AnimatedModal>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}><ButtonWithIcon text={"Add Coupon"} onClick={() => {
                        setDiscountCoupon({})
                        setShowAddCouponPopup(true)

                    }} /></div>
                    <table className="customers-table">
                        <col style={{ width: '22.5%' }} />
                        <col style={{ width: '22.5%' }} />
                        <col style={{ width: '22.5%' }} />
                        <col style={{ width: '22.5%' }} />
                        <col style={{ width: '10%' }} />
                        <tr className="customers-table-header-row">
                            <th >Id</th>
                            <th>Name</th>
                            <th >Discount</th>
                            <th >Coupon id</th>
                            <th></th>
                        </tr>
                        {coupons.map(coupon => <tr className="customers-table-data-row">
                            <td>{coupon.id}</td>
                            <td>{coupon.coupon_name || "-"}</td>
                            <td>{coupon.discount}%</td>
                            <td>{coupon.coupon_id}</td>
                            <td>
                                {/* <div style={{ color: "#2989cf", cursor: "pointer" }} onClick={() => {
                            const toBeEditedCoupon = coupons.filter(c => c.id == coupon.id)[0]
                            setDiscountCoupon({ ...discountCoupon, ...toBeEditedCoupon, discount: toBeEditedCoupon.discount })
                            setShowEditCouponPopup(true)
                        }
                    }>Edit
                    </div>
                    <div style={{ color: "#2989cf", cursor: "pointer" }} onClick={() => deleteDiscountCoupon(coupon.id)}>Delete
                </div> */}
                                <Dropdown style={{ margin: "0 20px" }}>

                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu size="sm" title=""
                                        className="custom-dropdown-settings"
                                    >
                                        {/* Edit */}
                                        <Dropdown.Item eventKey="delete"
                                            className="dropdown-set-padding"
                                        >
                                            <div style={{ display: "flex", cursor: 'pointer' }}
                                                onClick={(e) => {
                                                    const toBeEditedCoupon = coupons.filter(c => c.id == coupon.id)[0]
                                                    setDiscountCoupon({ ...discountCoupon, ...toBeEditedCoupon, discount: toBeEditedCoupon.discount })
                                                    setShowEditCouponPopup(true)
                                                }}
                                            >
                                                {/* <img src={Delete} /> */}
                                                <div style={{ marginLeft: '10px' }}>Edit</div>
                                            </div>
                                        </Dropdown.Item>

                                        {/* Delete */}
                                        <Dropdown.Item eventKey="delete"
                                            className="dropdown-set-padding"
                                        >
                                            <div style={{ display: "flex", cursor: 'pointer' }}
                                                onClick={(e) => {
                                                    setActivitypopup({
                                                        showProcessPopup: true,
                                                        processPopupIcon: "WARNING",
                                                        processMessage: "Are u sure u want to delete this coupon,it will be removed from related plans!",
                                                        onClickOk: () => deleteDiscountCoupon(coupon.id),
                                                        close: () => setActivitypopup({ showProcessPopup: false })
                                                    })
                                                }}
                                            >
                                                {/* <img src={Delete} /> */}
                                                <div style={{ marginLeft: '10px' }}>Delete</div>
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>)}
                    </table>
                </>
            }
        </div >
    )
}

export default DiscountCouponTable