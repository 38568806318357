
import maximizeIcon from "../icons/maximizeIcon.svg";
import React from 'react';
const Maximize = ({onClick,style}) => {
    return <div
        onClick={onClick}
        style={{
            cursor: 'pointer',
            right: "0px",
            position: 'absolute',
            zIndex: 2,
            ...style
        }}
    >
        <img src={maximizeIcon} />
    </div>
}

export {
    Maximize
}