import React, { Component } from "react";
import * as API from "../api";
import { Modal } from "react-bootstrap";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AnimatedModal } from "./AnimatedModal";
import { ActivityPopup } from "./ActivityPopup";
import { SetRestrictSesssionExpire } from "../helperComponents/OnIdleHandlerWrapper";
import ButtonWithIcon from "./ButtonWithIcon";
import { convertInMbGB } from "./reusableFunctions";

let prevLoadedData = 0
let chunkSize = 10 * 1024 * 1024;

export default class OrthoImporter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      targetFilesArr: [],
      uploaded_data: [],
      imageLimitCount: "0",
      progress: 0,
      _progressInfos: [],
      toShowProgressOf: 0,
      show: false,
      uploadedCount: 0,
      totalImages: 0,
      isCancel: false,
      showCancelPopup: false,
      cancelledAt: 0,
      action: 0,
      currentFileIndex: null,
      lastUploadedFileIndex: null,
      currentChunkIndex: null,
      item: undefined,
      activityPopup: false,
      metadata: null,
      uploadedData: 0,
      uploadFileSize: 0,
    };
  }


  cancelUpload = () => {
    prevLoadedData = 0;
    this.setState({
      isCancel: true,
      showCancelPopup: false,
      show: false,
      targetFilesArr: [],
      toShowProgressOf: 0,
      lastUploadedFileIndex: null,
      uploadedCount: 0,
      currentChunkIndex: null,
      currentFileIndex: null,
      uploadCheckPopup: false
    })
  }

  uploadChunk = (readerEvent, fileName, fileSize, currentChunkIndex, currentFileIndex) => {
    const data = new FormData()
    data.append('file', readerEvent)
    data.append('name', fileName);
    data.append('size', fileSize);
    data.append('currentChunkIndex', currentChunkIndex);
    data.append('totalChunks', Math.ceil(fileSize / chunkSize));
    data.append('planId', this.props.planId);
    data.append('projectId', this.props.projectId);
    data.append('taskId', this.props.taskId)
    data.append('collectionId', this.props.collectionId)
    data.append('taskName', this.props.taskName)

    if (this.props.fileType === "Ortho") {
      if (fileName.toLowerCase().includes(".tif") || fileName.toLowerCase().includes(".tiff")) {
        API.importOrthomosaic(data).then(response => {
          response.json().then((res) => {
            console.log('res.message', res);
            const chunks = fileSize / chunkSize;
            if (response.status == 200) {
              if (currentChunkIndex === Math.ceil(chunks - 1)) {
                prevLoadedData = 0
                this.setState({
                  uploadedData: fileSize,
                  uploadFileSize: fileSize,
                  lastUploadedFileIndex: currentFileIndex,
                  currentChunkIndex: null,
                  toShowProgressOf: ((currentFileIndex + 1) * 100) / this.state.targetFilesArr.length,
                  uploadedCount: (currentFileIndex + 1)
                }, () => {
                  console.log("Uplaod %", this.state.toShowProgressOf);
                  if (this.state.uploadedCount === this.state.targetFilesArr.length) {
                    SetRestrictSesssionExpire(false);
                    this.props.onImageUpload(100);
                  }
                })
              } else {
                let fileUploadedPercentage = currentChunkIndex / chunks * 100
                this.setState({
                  uploadedData: this.state.uploadedData + chunkSize,
                  uploadFileSize: fileSize,
                  toShowProgressOf: this.state.toShowProgressOf < 100 ? this.state.toShowProgressOf + 100 / ((this.state.targetFilesArr.length * 100) / (fileUploadedPercentage - prevLoadedData)) : 100//this.state.toShowProgressOf + (100 / ((this.state.targetFilesArr.length * 100) / fileUploadedPercentage))
                }, () => {
                  this.setState({ currentChunkIndex: currentChunkIndex + 1 })
                  prevLoadedData = fileUploadedPercentage;
                  console.log("Uplaod %", this.state.toShowProgressOf);
                  if (this.state.toShowProgressOf < 0 || this.state.toShowProgressOf === 100) {
                    console.log("this is minus value");
                    prevLoadedData = 0
                    this.setState({ toShowProgressOf: 0 });
                  }
                })
              }
            } else if (response.status == 500) {
              this.readAndUploadCurrentChunk();
            } else if (response.status == 402) {
              this.props.showUploadErrWarning()
            } else if (response.status == 400) {
              this.setUploadFailedError(res.message)
            }
          })
        }).catch(error => {
          SetRestrictSesssionExpire(false)
          console.log("catch error : ", error);
          this.setState({
            activityPopup: {
              icon: "ERROR",
              message: `${error}`,
            }
          });
          this.cancelUpload();
        });
      } else {
        this.setState({
          activityPopup: {
            icon: "ERROR",
            message: "Incorrect File, Please choose valid extention file !",
          }
        });
        this.cancelUpload();
        SetRestrictSesssionExpire(false)
      }
    } else if (this.props.fileType === "DSM") {
      if (fileName.toLowerCase().includes(".tif") || fileName.toLowerCase().includes(".tiff")) {
        API.importDSM(data).then(response => {
          response.json().then((res) => {
            console.log('res.message', res);
            const chunks = fileSize / chunkSize;
            if (response.status == 200) {
              if (currentChunkIndex === Math.ceil(chunks - 1)) {
                prevLoadedData = 0
                this.setState({
                  uploadedData: fileSize,
                  uploadFileSize: fileSize,
                  lastUploadedFileIndex: currentFileIndex,
                  currentChunkIndex: null,
                  toShowProgressOf: ((currentFileIndex + 1) * 100) / this.state.targetFilesArr.length,
                  uploadedCount: (currentFileIndex + 1)
                }, () => {
                  if (this.state.uploadedCount === this.state.targetFilesArr.length) {
                    SetRestrictSesssionExpire(false);
                    this.props.onImageUpload(100);
                  }
                })
              } else {
                let fileUploadedPercentage = currentChunkIndex / chunks * 100
                this.setState({
                  uploadedData: this.state.uploadedData + chunkSize,
                  uploadFileSize: fileSize,
                  toShowProgressOf: this.state.toShowProgressOf < 100 ? this.state.toShowProgressOf + 100 / ((this.state.targetFilesArr.length * 100) / (fileUploadedPercentage - prevLoadedData)) : 100//this.state.toShowProgressOf + (100 / ((this.state.targetFilesArr.length * 100) / fileUploadedPercentage))
                }, () => {
                  this.setState({ currentChunkIndex: currentChunkIndex + 1 })
                  prevLoadedData = fileUploadedPercentage;
                  console.log("Uplaod %", this.state.toShowProgressOf);
                  if (this.state.toShowProgressOf < 0 || this.state.toShowProgressOf === 100) {
                    console.log("this is minus value");
                    prevLoadedData = 0
                    this.setState({ toShowProgressOf: 0 });
                  }
                })
              }
            } else if (response.status == 402) {
              this.props.showUploadErrWarning()
            } else if (response.status == 500) {
              this.readAndUploadCurrentChunk();
            } else if (response.status == 400) {
              this.setUploadFailedError(res.message)
            }
          })
        }).catch(error => {
          SetRestrictSesssionExpire(false)
          console.log("catch error : ", error);
          this.setState({
            activityPopup: {
              icon: "ERROR",
              message: error,
            }
          });
          this.cancelUpload();
        });
      } else {
        this.setState({
          activityPopup: {
            icon: "ERROR",
            message: "Incorrect File, Please choose valid extention file !",
          }
        });
        this.cancelUpload();
      }
    } else if (this.props.fileType === "DTM") {
      if (fileName.toLowerCase().includes(".tif") || fileName.toLowerCase().includes(".tiff")) {
        API.importDTM(data).then(response => {
          response.json().then((res) => {
            console.log('res.message', res);
            const chunks = fileSize / chunkSize;
            if (response.status == 200) {
              if (currentChunkIndex === Math.ceil(chunks - 1)) {
                prevLoadedData = 0
                this.setState({
                  uploadedData: fileSize,
                  uploadFileSize: fileSize,
                  lastUploadedFileIndex: currentFileIndex,
                  currentChunkIndex: null,
                  toShowProgressOf: ((currentFileIndex + 1) * 100) / this.state.targetFilesArr.length,
                  uploadedCount: (currentFileIndex + 1)
                }, () => {
                  if (this.state.uploadedCount === this.state.targetFilesArr.length) {
                    SetRestrictSesssionExpire(false);
                    this.props.onImageUpload(100);
                  }
                })
              } else {
                let fileUploadedPercentage = currentChunkIndex / chunks * 100
                this.setState({
                  uploadedData: this.state.uploadedData + chunkSize,
                  uploadFileSize: fileSize,
                  toShowProgressOf: this.state.toShowProgressOf < 100 ? this.state.toShowProgressOf + 100 / ((this.state.targetFilesArr.length * 100) / (fileUploadedPercentage - prevLoadedData)) : 100//this.state.toShowProgressOf + (100 / ((this.state.targetFilesArr.length * 100) / fileUploadedPercentage))
                }, () => {
                  this.setState({ currentChunkIndex: currentChunkIndex + 1 })
                  prevLoadedData = fileUploadedPercentage;
                  console.log("Uplaod %", this.state.toShowProgressOf);
                  if (this.state.toShowProgressOf < 0 || this.state.toShowProgressOf === 100) {
                    console.log("this is minus value");
                    prevLoadedData = 0
                    this.setState({ toShowProgressOf: 0 });
                  }
                })
              }
            } else if (response.status == 402) {
              this.props.showUploadErrWarning()
            } else if (response.status == 500) {
              this.readAndUploadCurrentChunk();
            } else if (response.status == 400) {
              this.setUploadFailedError(res.message)
            }
          })
        }).catch(error => {
          SetRestrictSesssionExpire(false)
          console.log("catch error : ", error);
          this.setState({
            activityPopup: {
              icon: "ERROR",
              message: error,
            }
          });
          this.cancelUpload();
        });
      } else {
        this.setState({
          activityPopup: {
            icon: "ERROR",
            message: "Incorrect File, Please choose valid extention file !",
          }
        });
        this.cancelUpload();
      }
    } else if (this.props.fileType === "PntCld") {
      if (fileName.toLowerCase().includes(".las") || fileName.toLowerCase().includes(".laz")) {
        console.log("Uploading Las/Laz ...");
        API.importLazLas(data).then(response => {
          response.json().then((res) => {
            console.log('res.message', res);
            const chunks = fileSize / chunkSize;
            if (response.status == 200) {
              if (currentChunkIndex === Math.ceil(chunks - 1)) {
                prevLoadedData = 0
                this.setState({
                  uploadedData: fileSize,
                  uploadFileSize: fileSize,
                  lastUploadedFileIndex: currentFileIndex,
                  currentChunkIndex: null,
                  toShowProgressOf: ((currentFileIndex + 1) * 100) / this.state.targetFilesArr.length,
                  uploadedCount: (currentFileIndex + 1)
                }, () => {
                  if (this.state.uploadedCount === this.state.targetFilesArr.length) {
                    SetRestrictSesssionExpire(false);
                    this.props.onImageUpload(100);
                  }
                })
              } else {
                let fileUploadedPercentage = currentChunkIndex / chunks * 100
                this.setState({
                  uploadedData: this.state.uploadedData + chunkSize,
                  uploadFileSize: fileSize,
                  toShowProgressOf: this.state.toShowProgressOf < 100 ? this.state.toShowProgressOf + 100 / ((this.state.targetFilesArr.length * 100) / (fileUploadedPercentage - prevLoadedData)) : 100//this.state.toShowProgressOf + (100 / ((this.state.targetFilesArr.length * 100) / fileUploadedPercentage))
                }, () => {
                  this.setState({ currentChunkIndex: currentChunkIndex + 1 })
                  prevLoadedData = fileUploadedPercentage;
                  console.log("Uplaod %", this.state.toShowProgressOf);
                  if (this.state.toShowProgressOf < 0 || this.state.toShowProgressOf === 100) {
                    console.log("this is minus value");
                    prevLoadedData = 0
                    this.setState({ toShowProgressOf: 0 });
                  }
                })
              }
            } else if (response.status == 402) {
              this.props.showUploadErrWarning()
            } else if (response.status == 500) {
              this.readAndUploadCurrentChunk();
            } else {
              this.setUploadFailedError(res.message || "something went wrong while uploading image.")
            }
          })
        }).catch(error => {
          SetRestrictSesssionExpire(false)
          console.log("catch error : ", error);
          this.setState({
            activityPopup: {
              icon: "ERROR",
              message: error,
            }
          });
          this.cancelUpload();
        });
      } else {
        this.cancelUpload();
        this.setState({
          activityPopup: {
            icon: "ERROR",
            message: "Incorrect File, Please choose .LAS/.LAZ File to import point-cloud !",
          }
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentChunkIndex !== this.state.currentChunkIndex && this.state.currentChunkIndex !== null && !this.state.isCancel) {
      this.readAndUploadCurrentChunk();
    }

    if (prevState.currentFileIndex !== this.state.currentFileIndex) {
      if (this.state.currentFileIndex !== null) {
        this.setState({ currentChunkIndex: 0 })
      }
    }

    if (prevState.lastUploadedFileIndex !== this.state.lastUploadedFileIndex) {
      if (this.state.lastUploadedFileIndex === null) return;
      const isLastFile = this.state.lastUploadedFileIndex === this.state.targetFilesArr.length - 1;
      const nextFileIndex = isLastFile ? null : this.state.currentFileIndex + 1;
      this.setState({ currentFileIndex: nextFileIndex })
    }
  }

  readAndUploadCurrentChunk = () => {
    const file = this.state.targetFilesArr[this.state.currentFileIndex];
    if (!file) return;
    else {
      chunkSize = chunkSize > file.size ? file.size : chunkSize
      const from = this.state.currentChunkIndex * chunkSize;
      const to = from + chunkSize;
      const blob = file.slice(from, to);
      this.uploadChunk(blob, file.name, file.size, this.state.currentChunkIndex, this.state.currentFileIndex);
    }
  }

  // Function to execute when overwrite option is selected
  setUploadFailedError = (msg) => {
    this.setState({
      activityPopup: {
        item: "Upload Failed !",
        action: "COMPLETE",
        icon: "ERROR",
        message: msg,
        isCancel: true,
        show: false
      }
    }, () => {
      this.cancelUpload();
    });
  }

  uploadFiles = async () => {
    if (this.state.targetFilesArr.length > 0) {
      if (this.state.currentFileIndex === null) {
        SetRestrictSesssionExpire(true)
        this.setState({
          currentFileIndex: this.state.lastUploadedFileIndex === null ? 0 : this.state.lastUploadedFileIndex + 1,
          toShowProgressOf: 0,
          uploadFileSize: this.state.targetFilesArr[0]?.size,
          show: true,
          uploadCheckPopup: false,
          activityPopup: undefined,
          isCancel: false,
          uploadedCount: 0
        });
      }
    }
  }

  getFilteredFiles = (files) => {
    return (
      files.filter((file) => (file.name.toLowerCase()).includes(".tiff") || (file.name.toLowerCase()).includes(".tif") ||
        (file.name.toLowerCase()).includes(".las") || (file.name.toLowerCase()).includes(".laz") || (file.name.toLowerCase()).includes(".zip"))
    )
  }

  onChangeListenerFile = async (event) => {
    let targetFilesArr = this.getFilteredFiles(Array.from(event.target.files))
    if (targetFilesArr.length > 0) {
      this.setState((state) => ({
        ...state,
        targetFilesArr,
        uploadedData: 0,
      }), () => {
        chunkSize = Math.round(navigator.connection.downlink * 1024 * 1024)
        this.uploadFiles()
      });
    }
    else {
      this.setState({
        activityPopup: {
          icon: "ERROR",
          message: "Incorrect File, Please choose valid extention file !",
        }
      });
    }
  }

  onClickListenerFile = (event) => {
    event.target.value = ''
    this.setState({
      uploadedData: 0,
      uploadFileSize: 0,
    })
  }

  close() {
    if (this.state.uploadedCount !== this.state.targetFilesArr.length) {
      this.setState((prevState) => ({
        ...prevState,
        showCancelPopup: true
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        show: this.state.toShowProgressOf >= 100 ? false : true,
        targetFilesArr: [],
        toShowProgressOf: 100,
        lastUploadedFileIndex: null,
        uploadedCount: 0,
        currentChunkIndex: null,
        currentFileIndex: null,
      }));
    }

  }

  hide() {
    this.setState((prevState) => ({
      ...prevState,
      show: this.state.toShowProgressOf >= 100 ? false : true,
    }));
  }

  componentWillUnmount() {
    SetRestrictSesssionExpire(false)
  }

  componentWillMount() {
    var style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = ".popup-content { width: 30% !important; }";
    document.getElementsByTagName("head")[0].appendChild(style);
    let { urls, progress } = this.state;

    this.setState((state) => ({
      ...state,
      urls,
      progress,
    }));
  }
  render() {

    return (
      <>
        <ActivityPopup
          item={this.state.activityPopup?.item}
          open={this.state.activityPopup}
          icon={this.state.activityPopup?.icon}
          action={this.state.activityPopup?.action}
          msg={this.state.activityPopup?.message}
          close={() => this.setState({
            activityPopup: false
          })}
          onClickOk={() => {
            this.setState({
              activityPopup: false
            });
          }}
        />
        <React.Fragment>
          <div
            className="add-user-form-text-wrapper"
            style={{ marginTop: "0px" }}
          >
            <input
              name="company_logo"
              id="hiddenFileInputUpload1"
              type="file"
              className="add-user-form-file"
              required
              onClick={(event) => this.onClickListenerFile(event)}
              onChange={(e) => {
                this.onChangeListenerFile(e);
              }}
              accept={(this.props.fileType === "DSM" || this.props.fileType === "DTM" || this.props.fileType === "Ortho") ? ".tif , .tiff," : this.props.fileType === 'PntCld' ? ".las, .laz" : ".zip"}
              multiple={false}
              style={{ display: "none" }}
            />
          </div>

          <AnimatedModal
            isOpen={this.state.show}
            onRequestClose={() => this.hide()}
            height="300px"
            width="450px"
          >
            <div>
              <div className="services-content-title" style={{ textAlign: 'center', marginTop: '15px' }}>{this.state.toShowProgressOf == 100 ? "Upload Completed !" : this.props.fileType == "PntCld" ? "Uploading File..." : "Uploading Image..."}</div>
              <div style={{}}>
                <div style={{ textAlign: 'center', marginTop: "4%", marginBottom: "2%" }}>
                  <div style={{ width: 175, height: 175, margin: 'auto', fontSize: "10px" }}>
                    <CircularProgressbar
                      value={this.state.toShowProgressOf}
                      text={`${convertInMbGB(this.state.uploadedData)}/${convertInMbGB(this.state.uploadFileSize)}`}
                      styles={{
                        text: {
                          fill: '#2989cf',
                          fontSize: '9px',
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ paddingLeft: "250px" }}>
              <ButtonWithIcon
                text={this.state.uploadedCount === this.state.targetFilesArr.length ? "Close" : "Cancel"}
                width="94px" isBtnActive
                onClick={() => this.close()}
              />
            </div>

          </AnimatedModal>

          <Modal
            style={{ marginTop: '150px' }}
            show={this.state.showCancelPopup}
            onHide={() => {
              this.setState((state) => ({
                ...state,
                showCancelPopup: false,
              }));
            }}
          >
            <Modal.Body >
              <p>Your file upload is still in progress, Are you sure you want to cancel it?</p>
            </Modal.Body>
            <Modal.Footer>
              <div>
                <button
                  className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                  onClick={() => {
                    this.setState((state) => ({
                      ...state,
                      showCancelPopup: false,
                      show: true
                    }));
                  }}
                >
                  No
                </button>
              </div>
              <div>
                <button
                  className="add-user-form-submit-button popup-btn-right btn-ok"
                  onClick={() => {
                    this.cancelUpload()
                  }}
                >
                  Yes
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      </>

    );
  }
}
