import React from 'react'
import { Link } from 'react-router-dom'
import DropDownWithButton from './DropDownWithButton'

const DownloadAssetCard = ({ linkData, isDisabled, text, logo, onClick, width,multipleDownloadOptions,options }) => {
  return (
    <div style={{ justifySelf: "center", width: width || "40%", height: "125px", backgroundColor: '#EAEAEA', textAlign: 'center', border: 'solid 1px #EAEAEA', borderRadius: '5px', opacity: !isDisabled ? "1" : "0.5", filter: isDisabled ? "grayscale(100%)" : "" }}>
      <div style={{ width: '100%', height: "38%", padding: '17px 0px' }}>
        <img style={{ width: '23px', height: '23px' }} src={logo} />
      </div>
      <div className='content-text' style={{ color: '#3C3C3C', fontSize: '12px', fontWeight: '400', height: "24%", lineHeight: "13px" }}>{text}</div>
      <div style={{ backgroundColor: 'white', textAlign: 'center', padding: '13px', borderRadius: "0px 0px 5px 5px", height: "38%", display:'flex',justifyContent:'center'}}>
        {!isDisabled && linkData ? linkData.blankPage ? <a target="_blank" href={linkData.blankPage}>
          <div className="addcustomer-button-empty-dashboard hoverPointer" style={{ cursor: isDisabled ? "default" : "pointer", margin: 'auto', padding: '4px 16px', fontWeight: "400", borderWidth: "1px", fontSize: "8px" }} onClick={() => {
          }}>Download</div>
        </a>
          : <Link style={{ color: '#3c3c3c' }} to={linkData}
          >     
            <div className="addcustomer-button-empty-dashboard hoverPointer" style={{ cursor: isDisabled ? "default" : "pointer", margin: 'auto', padding: '4px 16px', fontWeight: "400", borderWidth: "1px", fontSize: "8px" }} onClick={() => {
            }}>Download</div></Link>
          :
          multipleDownloadOptions?
          <DropDownWithButton padding={'0px 8px'} selectedItem={"Download"} textFontSize={'8px'} textFontWeight={400} height={'21px'} isButton list={options}/>
          : 
          <div className="addcustomer-button-empty-dashboard hoverPointer" style={{ cursor: isDisabled ? "default" : "pointer", margin: 'auto', padding: '4px 16px', fontWeight: "400", borderWidth: "1px", fontSize: "8px" }} onClick={() => {
            !isDisabled && !linkData && onClick()
          }}>Download</div>}
      </div>
    </div >
  )
}

export default DownloadAssetCard