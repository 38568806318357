// Compulsory props : selectedItem, itemsList, onChangeHandler

import React from 'react'
import sortDown from '../icons/sortDown.png'
import sortUp from '../icons/sortUp.png'


export const SortComponent = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sortTypeASC, setSortType] = React.useState(props.sortASC != undefined ? props.sortASC : true);
  const open = Boolean(anchorEl);
  const onchangeHandler = () => {
    setSortType(!sortTypeASC)
    if (sortTypeASC)
      props.sortDecending()
    else
      props.sortAscending()
  }
  return (
    props.label ?
      <span style={props.style}>
        <div className='services-content-title' style={{ fontSize: props.fontSize ? props.fontSize : "13px", marginLeft: props.marginLeft || "5px", fontWeight: props.fontWeight ? props.fontWeight : 500, opacity: props.disabled ? 0.5 : 1 }}>{props.label}
          <span style={{ marginLeft: props.marginLeft || '5px' }}>
            <img src={sortTypeASC ? sortDown : sortUp} style={{ opacity: props.disabled ? 0.5 : 1, width: props.width ? props.width : '10px', height: props.height ? props.height : '10px', cursor: 'pointer', margin: '0px 3px' }}
              onClick={() => props.disabled ? '' : onchangeHandler()} />
          </span>
        </div>
      </span> :
      <span style={props.style}>
        <img src={sortTypeASC ? sortDown : sortUp} style={{ opacity: props.disabled ? 0.5 : 1, width: props.width ? props.width : '10px', height: props.height ? props.height : '10px', cursor: 'pointer', margin: '0px 3px' }}
          onClick={() => props.disabled ? '' : onchangeHandler()} />
      </span>
  )
}
