import React from 'react';
import { SetRestrictSesssionExpire } from "../helperComponents/OnIdleHandlerWrapper";
import * as API from "../api";
import { StorageLimitExceed } from '../subscription/UserSubscription';


let prevLoadedData = 0
let chunkSize = 10 * 1024 * 1024;

class SaveShapeFile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      targetFilesArr: [],
      currentFileIndex: null,
      uploadFileSize: 0,
      toShowProgressOf: 0,
      lastUploadedFileIndex: null,
      uploadedData: 0,
      isCancel: false,
      showStorageExceedPopup: false,
    }
  }

  uploadChunk = (readerEvent, fileName, fileSize, currentChunkIndex, currentFileIndex, file) => {

    const data = new FormData()
    data.append('planId', this.props.taskObj.planId);
    console.log("planid", this.props.taskObj.planId)
    data.append('taskId', this.props.taskObj.taskId);
    data.append('name', fileName);
    data.append('size', fileSize);
    data.append('currentChunkIndex', currentChunkIndex);
    data.append('totalChunks', Math.ceil(fileSize / chunkSize));
    data.append('file', file);
    data.append('layerName', this.props.taskObj.layerName);
    data.append("layerType", this.props.fileEvent.type)
    data.append('zoneLetter', this.props.taskObj.zoneLetter)
    data.append('zoneNumber', this.props.taskObj.zoneNumber)
    data.append('properties', JSON.stringify(this.props.taskObj.properties))
    if (this.props.taskObj.floorId) {
      data.append('floorId', this.props.taskObj.floorId)
    }

    API.saveMapAssets(data).then(response => {
      response.json().then((res) => {
        const chunks = fileSize / chunkSize;
        if (response.status == 200) {
          let fileUploadedPercentage = currentChunkIndex / chunks * 100
          this.setState({
            uploadedData: this.state.uploadedData + chunkSize,
            uploadFileSize: fileSize,
            toShowProgressOf: this.state.toShowProgressOf < 100 ? this.state.toShowProgressOf + 100 / ((this.state.targetFilesArr.length * 100) / (fileUploadedPercentage - prevLoadedData)) : 100//this.state.toShowProgressOf + (100 / ((this.state.targetFilesArr.length * 100) / fileUploadedPercentage))
          }, () => {
            this.setState({ currentChunkIndex: currentChunkIndex + 1 })
            prevLoadedData = fileUploadedPercentage;
            console.log("Uplaod %", this.state.toShowProgressOf);
            if (this.state.toShowProgressOf < 0 || this.state.toShowProgressOf === 100) {
              console.log("this is minus value");
              prevLoadedData = 0
              this.setState({ toShowProgressOf: 0 });
            }
          })
        } else if (response.status == 201) {
          prevLoadedData = 0
          this.setState({
            uploadedData: fileSize,
            uploadFileSize: fileSize,
            lastUploadedFileIndex: currentFileIndex,
            currentChunkIndex: null,
            toShowProgressOf: ((currentFileIndex + 1) * 100) / this.state.targetFilesArr.length,
            uploadedCount: (currentFileIndex + 1)
          })
          SetRestrictSesssionExpire(false);
          console.log("res", res)
          this.props.onFileUpload(res.geoJSON, res.savedAsset);
        } else if (response.status == 500) {
          this.readAndUploadCurrentChunk();
        }
        else if (response.status == 402) {
          this.setState({ showStorageExceedPopup: true })
        }
        else {
          this.props.onFileUpload(undefined)
        }
      })
    }).catch(error => {
      SetRestrictSesssionExpire(false)
      console.log("catch error : ", error);
    })
  }

  readAndUploadCurrentChunk = () => {
    console.log("readAndUploadCurrentChunk called!")
    const file = this.state.targetFilesArr[this.state.currentFileIndex];
    if (!file) {
      return;
    } else {
      chunkSize = chunkSize > file.size ? file.size : chunkSize
      const from = this.state.currentChunkIndex * chunkSize;
      const to = from + chunkSize;
      const blob = file.slice(from, to);

      this.uploadChunk(blob, file.name, file.size, this.state.currentChunkIndex, this.state.currentFileIndex, file)
    }
  }

  uploadFiles = async () => {
    console.log("uploadFiles Called!", this.state.targetFilesArr);
    if (this.state.targetFilesArr.length > 0) {
      if (this.state.currentFileIndex === null) {
        SetRestrictSesssionExpire(true)
        this.setState({
          currentFileIndex: this.state.lastUploadedFileIndex === null ? 0 : this.state.lastUploadedFileIndex + 1,
          toShowProgressOf: 0,
          uploadFileSize: this.state.targetFilesArr[0]?.size,
          uploadedCount: 0
        }, () => {
          console.log('file state:\n',
            this.state.currentFileIndex, "\n",
            this.state.toShowProgressOf, "\n",
            this.state.uploadFileSize, "\n",
            this.state.uploadedCount);
        });
      }
    }
  }

  onChangeListenerFile = async () => {

    let targetFilesArr = [this.props.fileEvent.file]
    if (targetFilesArr.length > 0) {
      this.setState((state) => ({
        ...state,
        targetFilesArr,
        uploadedData: 0,
      }), () => {
        chunkSize = Math.round(navigator.connection.downlink * 1024 * 1024)
        this.uploadFiles()
      });
    }
    else {
      console.log("something went wrong");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentChunkIndex !== this.state.currentChunkIndex && this.state.currentChunkIndex !== null && !this.state.isCancel) {
      this.readAndUploadCurrentChunk();
    }

    if (prevState.currentFileIndex !== this.state.currentFileIndex) {
      if (this.state.currentFileIndex !== null) {
        this.setState({ currentChunkIndex: 0 })
      }
    }

    if (prevState.lastUploadedFileIndex !== this.state.lastUploadedFileIndex) {
      if (this.state.lastUploadedFileIndex === null) {
        return;
      }
      const isLastFile = this.state.lastUploadedFileIndex === this.state.targetFilesArr.length - 1;
      const nextFileIndex = isLastFile ? null : this.state.currentFileIndex + 1;
      this.setState({ currentFileIndex: nextFileIndex })
    }
  }

  componentDidMount() {
    this.onChangeListenerFile()
    console.log('Props Data : ', this.props);
  }

  render() {
    return (
      <div>{
        this.state.showStorageExceedPopup ?
          <StorageLimitExceed
            seletedDataSize={null} totalStorage={null}
            onClickOk={() => {
              this.setState({ showStorageExceedPopup: false }, () => {
                this.props.onFileUpload(undefined, undefined, false)
              })
            }}

          /> : <></>}
      </div>
    );
  }
}

export default SaveShapeFile;
