import React, { useState, useEffect } from "react";
import * as API from "../../../api.js";
import Loader from '../../../icons/loader.svg';
import { getArea } from "../../../ReusableComponents/map/leafletMeasure.js";

const YieldAnalysis = (props) => {
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    var [yeildAnalysis, setData] = useState(null);

    useEffect(() => {
        setData(props.measurement.stats)
        setError(false)
        setLoader(false)
    }, [props.measurement])

    const getGeoJSON = () => {
        return {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    props.measurement.coordinates.map((coord) => [coord.lng, coord.lat])
                ]
            }
        }
    }
    const getYieldAnalysis = () => {
        API.getYieldAnalysis(props.taskId || props.task_id, props.permissions?.container, props.permissions?.st, getGeoJSON()).then((yeildAnalysis) => {
            API.saveYieldAnalysis(yeildAnalysis.stats, props.measurement.id)
            props.saveStats(yeildAnalysis.stats)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
            setError(true)
        })
    }
    return (
        <>{props.orthoAvailable ?
            <>
                <div className='content-text' style={{ height: "40px", display: "flex", alignItems: "center", backgroundColor: 'white', justifyContent: "space-between", padding: '10px 30px', borderBottom: '1px solid #e5e5e5' }}>
                    <div style={{ backgroundColor: 'white', fontSize: "14px", fontWeight: "500", color: "#2989cf" }}>Plant Health Analysis</div>
                </div>
                {props.orthoAvailable == false ? <div style={{ backgroundColor: 'white', color: "#3C3C3C", fontWeight: "400", fontSize: "10px", padding: "20px 30px" }}>
                    Not available.
                </div> :
                    props.measurement.coordinates.length < 4 ?
                        <div style={{ backgroundColor: 'white', color: "#3C3C3C", fontWeight: "400", fontSize: "10px", padding: "20px 30px" }}>
                            A polygon with minimum 4 coordinates is required to calculate yield estimation.
                        </div>
                        : <>
                            {error ? <div style={{ backgroundColor: 'white', color: "#3C3C3C", fontWeight: "400", fontSize: "10px", padding: "20px 30px" }}>
                                Something went wrong or the polygon is outside the orthomosaic.
                            </div> :
                                <>
                                    {Number(getArea(props.measurement.coordinates, 'acre')) > 25 ?
                                        <div style={{ backgroundColor: 'white', color: "#3C3C3C", fontWeight: "400", fontSize: "10px", padding: "20px 30px" }}>
                                            Sorry, we currently support area only up to 25 acres.
                                        </div>
                                        :
                                        <>
                                            {!yeildAnalysis ?
                                                <div style={{ marginTop: '8px', marginLeft: '32px', color: "#3C3C3C" }}>An approximate yield estimation will appear here</div>
                                                : <></>
                                            }
                                            <div style={{ color: "black", margin: "25px 0", marginLeft: "5px" }}>
                                                {
                                                    yeildAnalysis ?
                                                        <>
                                                            <ul>
                                                                {yeildAnalysis.map((stat, key) =>
                                                                    <li style={{ fontSize: '16px', color: "#666666", marginLeft: "5px" }}>
                                                                        <div style={{ marginBottom: "10px", display: 'flex', fontSize: "12px", color: "#666666" }}>
                                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                <div style={{ width: "140px" }}>{stat[1]}</div>
                                                                                <div style={{ width: "90px", fontSize: '8px', }}>({stat[0]})</div>
                                                                            </div>
                                                                            <div style={{ color: "#3C3C3C", width: "35%" }}><span style={{ marginRight: '24%' }}>-</span> {stat[2]}%</div>
                                                                        </div>
                                                                    </li>
                                                                )}
                                                            </ul>

                                                        </>
                                                        :
                                                        <div>
                                                            {loader ? <>
                                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "20px 0px 0px 40px", }}>
                                                                    <img width="100px" src={Loader} />
                                                                </div>
                                                                <div style={{ textAlign: "center", color: "#3C3C3C" }}>Getting analysis may take couple of minutes, please wait...</div>
                                                            </> :
                                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", height: "16vh" }}>
                                                                    <div className="addcustomer-button-empty-dashboard" style={{ padding: '5px 13px', marginBottom: "30px", cursor: 'pointer', fontSize: "10px" }}
                                                                        onClick={() => {
                                                                            getYieldAnalysis()
                                                                            setLoader(true)
                                                                        }}
                                                                    >
                                                                        Get analysis
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                }
                                            </div>
                                        </>}
                                </>
                            }
                        </>}
            </> : <></>}
        </>
    )
}


export default YieldAnalysis;
