// @flow

import React, { memo } from "react"
import colorAlpha from "color-alpha"
import useMouse from "../use-mouse"
function clamp(num, min, max) {
  return num <= min ? min : num >= max ? max : num
}

let selectedCursor = null
const RegionComponents = {
  point: memo(({ region, iw, ih, onClickRegion, regions, isThermalImage, drawingRegion, mouseDownOnPoint, isDataForReport }) => {
    const getXPosOfTemp = () => {
      if (region.x > 0.8) return -30;
      if (region.x < 0.1) return 30;
      return 0
    }
    const getYPosOfTemp = () => {
      if (region.x > 0.8) return 5;
      if ((region.x < 0.1) && (region.y < 0.1)) return 5;
      if (region.x > 0.1 && region.x < 0.8 && region.y < 0.1) return 20;
      if (region.x < 0.1) return 5;
      return -10
    }
    return <g transform={`translate(${region.x * iw} ${region.y * ih})`}>
      <circle cx={region.x} cy={region.y} r="5" stroke="rgba(0,0,0,0.7)" strokeWidth="2" fill={colorAlpha(region.color, 1)}
        onClick={() => onClickRegion(region)}
        onMouseDown={(e) => { if (region.editable && e.button === 0) mouseDownOnPoint(region); }}
      />
      {isThermalImage ?
        <>
          {isDataForReport ?
            <text x={0} y={-10} textAnchor="middle" fill={region.tempColor || "white"} strokeWidth={0.5} stroke={region.tempColor || "white"} fontSize={"15px"}>
              {region.name}
            </text> :
            region.disableTemp ? <></> : <text x={getXPosOfTemp()} y={getYPosOfTemp()} textAnchor="middle" fill={region.tempColor || "white"} strokeWidth={0.5} stroke={region.tempColor || "white"} fontSize={"15px"}>
              {!region.calculated ? "Calculating..." : (region?.pointTemperature?.temperature && Math.round(region?.pointTemperature?.temperature) + "°C")}
            </text>
          }
        </>
        : <></>
      }
    </g >
  }),

  circle: memo(({ region, iw, ih, onClickRegion, isThermalImage, mouseDownOnPolygon, isDataForReport, drawingRegion, mouseDownOnVertex }) => {
    const getXPosOfTemp = () => {
      if (region.x > 0.8) return -30;
      if (region.x < 0.1) return 30;
      return 0
    }
    const getYPosOfTemp = () => {
      if (region.x > 0.8) return 5;
      if ((region.x < 0.1) && (region.y < 0.1)) return 5;
      if (region.x > 0.1 && region.x < 0.8 && region.y < 0.1) return 20;
      if (region.x < 0.1) return 5;
      return -10
    }
    return <g transform={`translate(${region.x * iw} ${region.y * ih})`}>
      <circle
        cx={region.x}
        cy={region.y}
        r={region.radius * iw} // You can adjust this based on your needs
        stroke={isThermalImage ? region.color : region.showSeverity || region.highlight ? colorAlpha("#FFFFFF", 0.75) : colorAlpha(region.color, 0.75)} 
        strokeWidth="2" 
        fill={colorAlpha(region.color, 0.1)}
        onClick={() => onClickRegion(region)}
        onMouseDown={(e) => { if (region.editable && e.button === 0) mouseDownOnPolygon(region); }}
      />
      {
        region.editable || drawingRegion?.id === region.id ? <>
          <circle cx={region.x + (region.radius * iw)} cy={region.y} r="5" stroke="rgba(0,0,0,0.7)" strokeWidth="2" fill={colorAlpha(region.color, 1)}
            onMouseDown={(e) => { if (region.editable && e.button === 0) mouseDownOnVertex(region, 0); }}
          />
          <circle cx={region.x - (region.radius * iw)} cy={region.y} r="5" stroke="rgba(0,0,0,0.7)" strokeWidth="2" fill={colorAlpha(region.color, 1)}
            onMouseDown={(e) => { if (region.editable && e.button === 0) mouseDownOnVertex(region, 1); }}
          />
        </> : <></>
      }
      {isThermalImage ?
        <>
          {isDataForReport ?
            <text x={0} y={-10} textAnchor="middle" fill={region.tempColor || "white"} strokeWidth={0.5} stroke={region.tempColor || "white"} fontSize={"15px"}>
              {region.name}
            </text> :
            region.disableTemp ? <></> : <text x={getXPosOfTemp()} y={getYPosOfTemp()} textAnchor="middle" fill={region.tempColor || "white"} strokeWidth={0.5} stroke={region.tempColor || "white"} fontSize={"15px"}>
              {!region.calculated ? "Calculating..." : (region?.pointTemperature?.temperature && Math.round(region?.pointTemperature?.temperature) + "°C")}
            </text>
          }
        </>
        : <></>
      }
    </g >
  }),


  line: memo(({ region, iw, ih, isThermalImage, onClickRegion, mouseDownOnLine, mouseDownOnVertex, drawingRegion, onClickRegionPoint, isDataForReport }) => {
    const getXPosOfMaxTemp = () => {
      if (region.x1 < 0.1 || region.x2 < 0.1) {
        return (40);
      }
      if (region.x1 > 0.8 || region.x2 > 0.8) {
        return -40
      }
      if (((region.y2 > region.y1 && region.x1 > region.x2) || (region.y2 < region.y1 && region.x1 < region.x2))) return -40
      return 40
    }
    const getXPosOfMinTemp = () => {
      if (region.x1 < 0.1 || region.x2 < 0.1) {
        return ((region.x2 - region.x1) * iw) + (40);
      }
      if (region.x1 > 0.8 || region.x2 > 0.8) {
        return ((region.x2 - region.x1) * iw) - (40);
      }
      if (0 + isNaN((region.x2 - region.x1) * iw)) return 0;
      return ((region.x2 - region.x1) * iw) + (((region.y2 > region.y1 && region.x1 > region.x2) || (region.y2 < region.y1 && region.x1 < region.x2)) ? -40 : 40)

    }
    const getXPosOfAvgTemp = () => {
      if (region.x1 < 0.1 || region.x2 < 0.1) {
        return ((region.x2 - region.x1) / 2 * iw) + 40;
      }
      if (region.x1 > 0.8 || region.x2 > 0.8) {
        return ((region.x2 - region.x1) / 2 * iw) - (40);
      }
      return isNaN((region.x2 - region.x1) / 2 * iw) ? 0 : ((region.x2 - region.x1) / 2 * iw) + (((region.y2 > region.y1 && region.x1 > region.x2) || (region.y2 < region.y1 && region.x1 < region.x2)) ? -40 : 40)
    }
    const getYPosOfMaxTemp = () => {
      if (region.y1 < 0.1 || region.y2 < 0.1) return 15
      if (region.y1 > 0.8 || region.y2 > 0.8) return -15
      return -15
    }
    const getYPosOfAvgTemp = () => {
      if (0 + isNaN((region.y2 - region.y1) / 2 * ih)) return 0;
      if (region.y1 < 0.1 || region.y2 < 0.1) return ((region.y2 - region.y1) / 2 * ih) + 15
      if (region.y1 > 0.8 || region.y2 > 0.8) return ((region.y2 - region.y1) / 2 * ih) - 15
      return ((region.y2 - region.y1) / 2 * ih) - 15

    }
    const getYPosOfMinTemp = () => {
      if (0 + isNaN((region.y2 - region.y1) * ih)) return 0;
      if (region.y1 < 0.1 || region.y2 < 0.1) return ((region.y2 - region.y1) * ih) + 15
      if (region.y1 > 0.8 || region.y2 > 0.8) return ((region.y2 - region.y1) * ih) - 15
      return ((region.y2 - region.y1) * ih) - 15
    }
    return <g transform={`translate(${region.x1 * iw} ${region.y1 * ih})`}>
      {isThermalImage && <><line //this is for border of line
        x1={-1}
        y1={0}
        x2={((region.x2 - region.x1) * iw + 1)}
        y2={(region.y2 - region.y1) * ih}
        stroke="black"
        strokeWidth="4"
        fill={colorAlpha(region.color, 1)}
        onClick={() => onClickRegion(region)}
        onMouseDown={(e) => { if (region.editable && e.button === 0) mouseDownOnLine(region); }}
      /></>}
      <line //this is actual line
        x1={0}
        y1={0}
        x2={(region.x2 - region.x1) * iw}
        y2={(region.y2 - region.y1) * ih}
        stroke={region.color}
        strokeWidth="3"
        fill={colorAlpha(region.color, 1)}
        onClick={() => onClickRegion(region)}
        onMouseDown={(e) => { if (region.editable && e.button === 0) mouseDownOnLine(region); }}
      />
      {isThermalImage ?
        <>
          {isDataForReport ?
            <text x={isNaN((region.x2 - region.x1) / 2 * iw) ? 0 : ((region.x2 - region.x1) / 2 * iw) + (((region.y2 > region.y1 && region.x1 > region.x2) || (region.y2 < region.y1 && region.x1 < region.x2)) ? -40 : 40)} y={0 + isNaN((region.y2 - region.y1) / 2 * ih) ? 0 : ((region.y2 - region.y1) / 2 * ih) - 15} textAnchor="middle" fill={region.tempColor || "white"} strokeWidth={0.5} stroke={region.tempColor || "white"} fontSize={"15px"} >
              {region.name}
            </text> :
            region.disableTemp ? <></>
              :
              <>
                <text x={getXPosOfMaxTemp()} y={getYPosOfMaxTemp()} textAnchor="middle" fill={region.tempColor || "white"} strokeWidth={0.5} stroke={region.tempColor || "white"} fontSize={"15px"}>
                  {!region.calculated ? "" : (region?.lineTemperature?.max && "Max" + Math.round(region?.lineTemperature?.max) + "°C")}
                </text>
                <text x={getXPosOfAvgTemp()} y={getYPosOfAvgTemp()} textAnchor="middle" fill={region.tempColor || "white"} strokeWidth={0.5} stroke={region.tempColor || "white"} fontSize={"15px"}>
                  {!region.calculated ? "Calculating..." : (region?.lineTemperature?.Avg && "Avg" + Math.round(region?.lineTemperature?.Avg) + "°C")}
                </text>
                <text x={getXPosOfMinTemp()} y={getYPosOfMinTemp()} textAnchor="middle" fill={region.tempColor || "white"} strokeWidth={0.5} stroke={region.tempColor || "white"} fontSize={"15px"} >
                  {!region.calculated ? "" : (region?.lineTemperature?.min && "Min" + Math.round(region?.lineTemperature?.min) + "°C")}
                </text>
              </>
          }
        </>


        : <></>}
      {drawingRegion && drawingRegion.id == region.id ?
        <>
          {<circle cx={region.x1} cy={region.y1} r="3" stroke="white" strokeWidth="2" fill="rgba(0,0,0,0.5)" onMouseUp={onClickRegionPoint} />}
          {<circle cx={(region.x2 - region.x1) * iw} cy={(region.y2 - region.y1) * ih} r="3" stroke="white" strokeWidth="2" fill="rgba(0,0,0,0.5)" onMouseUp={onClickRegionPoint} />}
        </> : <></>}
      {
        region.editable ? <>
          <circle cx={region.x1} cy={region.y1} r="5" stroke="white" strokeWidth="2" fill="rgba(0,0,0,0.5)" cursor={"nwse-resize"} onMouseDown={() => { mouseDownOnVertex(region, 0) }} onMouseUp={onClickRegionPoint} />
          <circle cx={(region.x2 - region.x1) * iw} cy={(region.y2 - region.y1) * ih} r="5" stroke="white" strokeWidth="2" cursor={"ne-resize"} fill="rgba(0,0,0,0.5)" onMouseDown={() => { mouseDownOnVertex(region, 1) }} onMouseUp={onClickRegionPoint} />
        </> : <>
        </>
      }
    </g>
  }),

  box: memo(({ region, iw, ih, onClickRegion, fillRegions, strokeWidth, drawingRegion, onClickRegionPoint, dragWithPrimary, mouseDownOnVertex, mouseDownOnBox, regions, isThermalImage, isDataForReport }) => {

    const getXPosOfMinTemp = () => {
      if (region.x < 0.1) return ((region.w * iw) + 40);
      return (-region.w - 40)
    }
    const getYPosOfMinTemp = () => {
      if (region.x < 0.1) return (region.h * ih)
      return 10
    }
    const getXPosOfMaxTemp = () => {
      if (region.x > 0.8 || region.x + region.w > 0.8) {
        return (-region.w - 40);
      }
      if (0 + isNaN(Math.max(region.w * iw, 0))) return 0;
      return Math.max(region.w * iw, 0) + region.w + 40
    }
    const getYPosOfMaxTemp = () => {
      if (region.x > 0.8 || (region.x + region.w > 0.8)) return (region.h * ih);
      return 10;
    }
    return <g transform={`translate(${region.x * iw} ${region.y * ih})`}>
      {isThermalImage && <><rect
        x={0}
        y={0}
        width={(isNaN(Math.max(region.w * iw, 0)) ? 0 : Math.max(region.w * iw, 0))}
        height={(isNaN(Math.max(region.h * ih, 0)) ? 0 : Math.max(region.h * ih, 0))}
        stroke={"black"}
        strokeWidth="5"
        fill={colorAlpha(region.color, 0)}
        onClick={() => onClickRegion(region)}
        onMouseDown={(e) => { if (region.editable && e.button === 0) mouseDownOnBox(region); }}
      // cursor={dragWithPrimary ? "pointer" : undefined}
      // onMouseEnter={() => { selectedCursor = 'pointer' }}
      // onMouseLeave={() => { selectedCursor = null }}
      /></>}
      <rect
        strokeWidth={isThermalImage ? 3 : strokeWidth ? (region.showSeverity ? 3 : strokeWidth) : 2}
        x={0}
        y={0}
        width={isNaN(Math.max(region.w * iw, 0)) ? 0 : Math.max(region.w * iw, 0)}
        height={isNaN(Math.max(region.h * ih, 0)) ? 0 : Math.max(region.h * ih, 0)}
        stroke={isThermalImage ? region.color : region.showSeverity || region.highlight ? colorAlpha("#FFFFFF", 0.75) : colorAlpha(region.color, 0.75)}
        fill={fillRegions ? colorAlpha(region.color, 0.25) : colorAlpha(region.color, 0)}
        onClick={() => onClickRegion(region)}
        onMouseDown={(e) => { if (region.editable && e.button === 0) mouseDownOnBox(region); }}
      // cursor={dragWithPrimary ? "pointer" : undefined}
      // onMouseEnter={() => { selectedCursor = 'pointer' }}
      // onMouseLeave={() => { selectedCursor = null }}
      />

      {/* Show temprature for thermal image */}
      {isThermalImage ?
        <>
          {isDataForReport ?
            <text x={0 + isNaN(Math.max(region.w * iw, 0)) ? 0 : Math.max(region.w * iw, 0) / 2} y={0 + isNaN(Math.max(region.h * ih, 0)) ? 0 : (Math.max(region.h * ih, 0) / 2)} textAnchor="middle" fill={region.tempColor || "white"} strokeWidth={0.5} stroke={region.tempColor || "white"} fontSize={"15px"} >
              {region.name}
            </text> :
            region.disableTemp ?
              <></>
              :
              <>
                <text x={getXPosOfMaxTemp()} y={getYPosOfMaxTemp()} textAnchor="middle" fill={region.tempColor || "white"} strokeWidth={0.5} stroke={region.tempColor || "white"} fontSize={"15px"}>
                  {!region.calculated ? "" : (region?.boxTemperature?.max && "Max" + Math.round(region?.boxTemperature?.max) + "°C")}
                </text>
                <text x={0 + isNaN(Math.max(region.w * iw, 0)) ? 0 : Math.max(region.w * iw, 0) / 2} y={0 + isNaN(Math.max(region.h * ih, 0)) ? 0 : (Math.max(region.h * ih, 0) / 2)} textAnchor="middle" fill={region.tempColor || "white"} strokeWidth={0.5} stroke={region.tempColor || "white"} fontSize={"15px"}>
                  {!region.calculated ? "Calculating..." : (region?.boxTemperature?.Avg && "Avg" + Math.round(region?.boxTemperature?.Avg) + "°C")}
                </text>
                <text x={getXPosOfMinTemp()} y={getYPosOfMinTemp()} textAnchor="middle" fill={region.tempColor || "white"} strokeWidth={0.5} stroke={region.tempColor || "white"} fontSize={"15px"} >
                  {!region.calculated ? "" : (region?.boxTemperature?.min && "Min" + Math.round(region?.boxTemperature?.min) + "°C")}
                </text>
              </>

          }
        </>
        : <></>
      }
      {drawingRegion && drawingRegion.id == region.id ?
        <>
          {drawingRegion.startPoint != 5 ? <circle cx={region.x} cy={region.y} r="3" stroke="white" strokeWidth="2" fill="rgba(0,0,0,0.5)" onMouseUp={onClickRegionPoint} /> : <></>}
          {drawingRegion.startPoint != 5 ? <circle cx={region.x} cy={Math.max(region.h * ih, 0)} r="3" stroke="white" strokeWidth="2" fill="rgba(0,0,0,0.5)" onMouseUp={onClickRegionPoint} /> : <></>}
          {drawingRegion.startPoint != 5 ? <circle cx={Math.max(region.w * iw, 0)} cy={region.y} r="3" stroke="white" strokeWidth="2" fill="rgba(0,0,0,0.5)" onMouseUp={onClickRegionPoint} /> : <></>}
          {drawingRegion.startPoint != 5 ? <circle cx={Math.max(region.w * iw, 0)} cy={Math.max(region.h * ih, 0)} r="3" stroke="white" strokeWidth="2" fill="rgba(0,0,0,0.5)" onMouseUp={onClickRegionPoint} /> : <></>}
        </> : <></>}

      {
        region.editable ? <>
          <circle cx={region.x} cy={region.y} r="5" stroke="white" strokeWidth="2" fill="rgba(0,0,0,0.5)" cursor={"nwse-resize"} onMouseDown={() => { mouseDownOnVertex(region, 0) }} onMouseUp={onClickRegionPoint} />
          <circle cx={Math.max(region.w * iw, 0)} cy={region.y} r="5" stroke="white" strokeWidth="2" cursor={"ne-resize"} fill="rgba(0,0,0,0.5)" onMouseDown={() => { mouseDownOnVertex(region, 1) }} onMouseUp={onClickRegionPoint} />
          <circle cx={Math.max(region.w * iw, 0)} cy={Math.max(region.h * ih, 0)} r="5" stroke="white" strokeWidth="2" cursor={"nwse-resize"} fill="rgba(0,0,0,0.5)" onMouseDown={() => { mouseDownOnVertex(region, 2) }} onMouseUp={onClickRegionPoint} />
          <circle cx={region.x} cy={Math.max(region.h * ih, 0)} r="5" stroke="white" strokeWidth="2" cursor={"ne-resize"} fill="rgba(0,0,0,0.5)" onMouseDown={() => { mouseDownOnVertex(region, 3) }} onMouseUp={onClickRegionPoint} />
        </> : <>
        </>
      }
    </g>
  }),

  polygon: memo(({ region, iw, ih, fullSegmentationMode, onClickRegion, fillRegions, strokeWidth, onClickRegionPoint, drawingRegion, mouseDownOnVertex, mouseDownOnPolygon, onClickRegionAddPoint }) => {
    const Component = region.open ? "polyline" : "polygon"
    const alphaBase = fullSegmentationMode ? 0.5 : 1
    let tempMidPointArray = []
    const getMidpoint = (p1, p2) => {

      const x = (p1[0] + p2[0]) / 2;
      const y = (p1[1] + p2[1]) / 2;

      return ([x, y])
    }
    let point = [...region.points]
    for (let i = 0; i < region.points.length - 1; i++) {
      tempMidPointArray.push(getMidpoint(point[i], point[i + 1]))

    }
    tempMidPointArray.push(getMidpoint(point[region.points.length - 1], point[0]))

    return (
      <>

        {region.color && <Component
          points={region.points
            .map(([x, y]) => [x * iw, y * ih])
            .map((a) => a.join(" "))
            .join(" ")}
          strokeWidth={strokeWidth ? (region.showSeverity ? 3 : strokeWidth) : 2}
          stroke={region.showSeverity || region.highlight ? colorAlpha("#FFFFFF", 0.75) : colorAlpha(region.color, 0.75)}
          fill={fillRegions ? colorAlpha(region.color, 0.25) : colorAlpha(region.color, 0)}
          onClick={() => onClickRegion(region)}
          onMouseEnter={() => { selectedCursor = '' }}
          onMouseLeave={() => { selectedCursor = null }}
          cursor={selectedCursor || 'default'}
          onMouseDown={(e) => { if (region.editable && e.button === 0) mouseDownOnPolygon(region); }}
        />}
        {/* vertex when drawing polygon */}
        <g>{drawingRegion && drawingRegion.id == region.id ?
          <>
            {region.points
              .map(([x, y]) => <circle cx={x * iw} cy={y * ih} r="3" stroke="white" strokeWidth="2" fill="rgba(0,0,0,0.5)" onMouseUp={onClickRegionPoint} />
              )}
          </> : <></>}</g>
        {/* vertex when editing polygon */}
        <g>{region.editable ?
          <>
            {region.points
              .map(([x, y], i) => <circle cx={x * iw} cy={y * ih} r="5" stroke="white" strokeWidth="2" fill="rgba(0,0,0,0.5)" cursor={"nwse-resize"} onMouseDown={() => { mouseDownOnVertex(region, i) }} onMouseUp={onClickRegionPoint} />
              )}
          </> : <></>}</g>
        {/* points to add another vertex to polygon */}
        <g>{region.editable ?
          <>
            {
              tempMidPointArray.map(([x, y], i) => <circle cx={x * iw} cy={y * ih} r="4" stroke="white" strokeWidth="1" fill="rgba(255,255,255,0.5)" cursor={"pointer"} onMouseUp={() => onClickRegionAddPoint(region, { id: i, x, y })} />
              )}
          </> : <></>}</g>
      </>
    )
  }),
  keypoints: ({ region, iw, ih, keypointDefinitions }) => {
    const { points, keypointsDefinitionId } = region
    if (!keypointDefinitions[keypointsDefinitionId]) {
      throw new Error(
        `No definition for keypoint configuration "${keypointsDefinitionId}"`
      )
    }
    const { landmarks, connections } =
      keypointDefinitions[keypointsDefinitionId]
    return (
      <g>
        {Object.entries(points).map(([keypointId, { x, y }], i) => (
          <g key={i} transform={`translate(${x * iw} ${y * ih})`}>
            <path
              d={"M0 8L8 0L0 -8L-8 0Z"}
              strokeWidth={2}
              stroke={landmarks[keypointId].color}
              fill="transparent"
            />
          </g>
        ))}
        {connections.map(([kp1Id, kp2Id]) => {
          const kp1 = points[kp1Id]
          const kp2 = points[kp2Id]
          const midPoint = { x: (kp1.x + kp2.x) / 2, y: (kp1.y + kp2.y) / 2 }

          return (
            <g key={`${kp1.x},${kp1.y}.${kp2.x},${kp2.y}`}>
              <line
                x1={kp1.x * iw}
                y1={kp1.y * ih}
                x2={midPoint.x * iw}
                y2={midPoint.y * ih}
                strokeWidth={2}
                stroke={landmarks[kp1Id].color}
              />
              <line
                x1={kp2.x * iw}
                y1={kp2.y * ih}
                x2={midPoint.x * iw}
                y2={midPoint.y * ih}
                strokeWidth={2}
                stroke={landmarks[kp2Id].color}
              />
            </g>
          )
        })}
      </g>
    )
  },
  "expanding-line": memo(({ region, iw, ih }) => {
    let { expandingWidth = 0.005, points } = region
    expandingWidth = points.slice(-1)[0].width || expandingWidth
    const pointPairs = points.map(({ x, y, angle, width }, i) => {
      if (!angle) {
        const n = points[clamp(i + 1, 0, points.length - 1)]
        const p = points[clamp(i - 1, 0, points.length - 1)]
        angle = Math.atan2(p.x - n.x, p.y - n.y) + Math.PI / 2
      }
      const dx = (Math.sin(angle) * (width || expandingWidth)) / 2
      const dy = (Math.cos(angle) * (width || expandingWidth)) / 2
      return [
        { x: x + dx, y: y + dy },
        { x: x - dx, y: y - dy },
      ]
    })
    const firstSection = pointPairs.map(([p1, p2]) => p1)
    const secondSection = pointPairs.map(([p1, p2]) => p2).asMutable()
    secondSection.reverse()
    const lastPoint = points.slice(-1)[0]
    return (
      <>
        <polygon
          points={firstSection
            .concat(region.candidatePoint ? [region.candidatePoint] : [])
            .concat(secondSection)
            .map((p) => `${p.x * iw} ${p.y * ih}`)
            .join(" ")}
          strokeWidth={2}
          stroke={colorAlpha(region.color, 0.75)}
          fill={colorAlpha(region.color, 0.25)}
        />
        {points.map(({ x, y, angle }, i) => (
          <g
            key={i}
            transform={`translate(${x * iw} ${y * ih}) rotate(${(-(angle || 0) * 180) / Math.PI
              })`}
          >
            <g>
              <rect
                x={-5}
                y={-5}
                width={10}
                height={10}
                strokeWidth={2}
                stroke={colorAlpha(region.color, 0.75)}
                fill={colorAlpha(region.color, 0.25)}
              />
            </g>
          </g>
        ))}
        <rect
          x={lastPoint.x * iw - 8}
          y={lastPoint.y * ih - 8}
          width={16}
          height={16}
          strokeWidth={4}
          stroke={colorAlpha(region.color, 0.5)}
          fill={"transparent"}
        />
      </>
    )
  }),
  pixel: () => null,
}

export const WrappedRegionList = memo(
  ({ regions, keypointDefinitions, iw, ih, fullSegmentationMode, onClickRegion, fillRegions, strokeWidth, drawingRegion, onClickRegionPoint, dragWithPrimary, mouseDownOnVertex, mouseDownOnBox, mouseDownOnPolygon, mouseDownOnLine, mouseDownOnPoint, isThermalImage, isDataForReport, onClickRegionAddPoint }) => {

    return regions
      .filter((r) => r.visible !== false)
      .map((r, key) => {
        const Component = RegionComponents[r.type]
        return (
          <Component
            key={key}
            region={r}
            iw={iw}
            ih={ih}
            fillRegions={fillRegions}
            strokeWidth={strokeWidth}
            keypointDefinitions={keypointDefinitions}
            fullSegmentationMode={fullSegmentationMode}
            onClickRegion={onClickRegion}
            drawingRegion={drawingRegion}
            onClickRegionPoint={onClickRegionPoint}
            dragWithPrimary={dragWithPrimary}
            mouseDownOnVertex={mouseDownOnVertex}
            mouseDownOnBox={mouseDownOnBox}
            mouseDownOnPolygon={mouseDownOnPolygon}
            onClickRegionAddPoint={onClickRegionAddPoint}
            mouseDownOnLine={mouseDownOnLine}
            mouseDownOnPoint={mouseDownOnPoint}
            regions={regions}
            isThermalImage={isThermalImage}
            isDataForReport={isDataForReport}
          />
        )
      })
  },
  (n, p) => n.regions === p.regions && n.iw === p.iw && n.ih === p.ih
)

export const RegionShapes = ({
  mat,
  imagePosition,
  regions = [],
  fillRegions,
  strokeWidth,
  keypointDefinitions,
  fullSegmentationMode,
  defectReport,
  cursor,
  onClickRegion,
  drawingRegion,
  onClickRegionPoint,
  dragWithPrimary,
  mouseDownOnVertex,
  mouseDownOnBox,
  mouseDownOnPolygon,
  onClickRegionAddPoint,
  mouseDownOnLine,
  mouseDownOnPoint,
  isThermalImage,
  isDataForReport
}) => {
  const iw = imagePosition.bottomRight.x - imagePosition.topLeft.x
  const ih = imagePosition.bottomRight.y - imagePosition.topLeft.y
  if (isNaN(iw) || isNaN(ih)) return null
  return (
    <svg
      width={iw}
      height={ih}
      style={{
        position: "absolute",
        zIndex: 1,
        left: defectReport ? 0 : imagePosition.topLeft.x,
        top: defectReport ? 0 : imagePosition.topLeft.y,
        pointerEvents: "all",
        width: iw,
        height: ih,
      }}
    >
      <WrappedRegionList
        key="wrapped-region-list"
        regions={regions}
        iw={iw}
        ih={ih}
        fillRegions={fillRegions}
        strokeWidth={strokeWidth}
        keypointDefinitions={keypointDefinitions}
        fullSegmentationMode={fullSegmentationMode}
        cursor={cursor}
        onClickRegion={onClickRegion}
        drawingRegion={drawingRegion}
        onClickRegionPoint={onClickRegionPoint}
        dragWithPrimary={dragWithPrimary}
        mouseDownOnVertex={mouseDownOnVertex}
        mouseDownOnBox={mouseDownOnBox}
        mouseDownOnPolygon={mouseDownOnPolygon}
        onClickRegionAddPoint={onClickRegionAddPoint}
        mouseDownOnLine={mouseDownOnLine}
        mouseDownOnPoint={mouseDownOnPoint}
        isThermalImage={isThermalImage}
        isDataForReport={isDataForReport}
      />
    </svg>
  )
}

