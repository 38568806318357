import React, { useEffect, useState } from 'react';
import service from '../../sidebaricon/Services.png';
import settingsActive from '../../sidebaricon/SettingsActive.png';
import Help from '../../sidebaricon/Help.png';
import * as API from "../../../src/api.js";
import SideBar from '../../SideBar';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import DropDownWithButton from '../../ReusableComponents/DropDownWithButton';
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal';
import LoaderComponent from '../../ReusableComponents/LoaderComponent';

const BuyCredit = (props) => {
  const [user, setUser] = useState({})
  const [sidebarMode, setSideBarMode] = useState("shrunk");
  const [data, setData] = useState([]);
  const [creditType, setCreditType] = useState("");
  const [cnfrmPopup, setCnfrmPopup] = useState(false);
  const [selectedItem, setSelectedItems] = useState(undefined);
  const [showLoader, setShowLoader] = useState(true)
  const [note, setNote] = useState(false)

  let sidebarConfigurations = {
    options: [
      {
        icon: service,
        text: "Services",
        path: "/",
      },
      {
        icon: settingsActive,
        text: "Settings",
        path: "/profile-settings",
        selected: true,
      },
      {
        icon: Help,
        text: "Help",
        path: "/feedback",
      },
    ],
    user,
    // type,
    mode: sidebarMode
  }

  const parentSidebarSwitch = () => {
    setSideBarMode(sidebarMode == "shrunk" ? "expanded" : "shrunk")
  }

  const handleQtyChange = (changedQty, item) => {
    if (Number(selectedItem?.price) * Number(changedQty) > 999999.99) {
      setNote({ type: "Attention", msg: <span>You cannot buy credits worth of ₹<span style={{ color: "rgb(244, 67, 54)", fontWeight: "500" }}>999,999.99</span> in a single transaction</span> })
    } else {
      setNote(false)
    }
    setSelectedItems({ ...item, qty: Number(changedQty) })
  }

  useEffect(() => {
    setNote(false)
    const query = new URLSearchParams(window.location.search);
    if (query && query.get("creditType")) {
      setCreditType(query.get("creditType"))
    }
    else {
      setCreditType(props.location?.state?.creditType)
    }
    if (props?.location?.state?.user) {
      setUser(props?.location?.state?.user)
    }
    else {
      API.getUserType().then(([blah, type, user]) => {
        setUser(user)
      })
    }
  }, []);

  useEffect(() => {
    if (creditType) {
      creditPlans();
    }
  }, [creditType])

  useEffect(() => {
    if (data.length != 0) setShowLoader(false)
  }, [data])

  //Function to fetch data from API
  const creditPlans = async () => {
    try {
      const response = await API.fetchCreditPlans(creditType);
      setData(response.creditsPlans);
    } catch (error) {
      console.error("Error fetching Credit Plans!", error);
    }
  }

  return (
    <div className="wrapper">
      <SideBar
        config={sidebarConfigurations}
        parentSidebarSwitch={parentSidebarSwitch}
      />

      {/* Confirm Credit Popup */}
      <AnimatedModal
        isOpen={cnfrmPopup}
        width="400px"
        height={"auto"}
      >
        <div>
          <div style={{ backgroundColor: "#EAEAEA", height: "40px", display: "flex", justifyContent: "center", alignItems: "center", font: "15px", color: "#3c3c3c", fontWeight: "500" }}>BUY {creditType.toUpperCase()} CREDITS</div>

          <div style={{ padding: "15px", height: "", borderBottom: "1px solid grey", textAlign: "left" }}>
            <div style={{ marginLeft: "20px", display: "flex", alignItems: "center" }}>
              <div style={{ width: "95px", fontSize: "14px", color: "#3c3c3c", fontWeight: "500" }} >Quantity :</div>
              <div style={{ marginLeft: "50px", fontWeight: "400", fontSize: "14px", }}>
                <DropDownWithButton
                  fontSize={"14px"}
                  textFontWeight={"400"}
                  textFontSize={"14px"}
                  list={
                    Array.from({ length: 10 }, (_, index) => ({
                      name: (index + 1).toString(),
                      onClick: () => handleQtyChange(index + 1, selectedItem)
                    }))
                  }
                  selectedItem={selectedItem?.qty ? selectedItem?.qty : 1}
                // width={'99%'}
                // textAlign={'left'}
                />
              </div>
            </div>

            <div style={{ marginLeft: "20px", display: "flex" }} >
              <div style={{ width: "95px", fontSize: "14px", color: "#3c3c3c", fontWeight: "500" }} >Amount :</div>
              <div style={{ marginLeft: "53px", fontWeight: "400", fontSize: "14px", }}>
                ₹ {selectedItem?.qty ? Number(selectedItem?.price) * selectedItem?.qty : selectedItem?.price}
              </div>
            </div>

            <div style={{ marginLeft: "20px", display: "flex" }} >
              <div style={{ width: "95px", fontSize: "14px", color: "#3c3c3c", fontWeight: "500" }} >Credits :</div>
              <div style={{ marginLeft: "53px", fontWeight: "400", fontSize: "14px", }} >
                {selectedItem?.qty ? Number(selectedItem?.credits) / 1024 * selectedItem?.qty : Number(selectedItem?.credits) / 1024} GB
              </div>
            </div>

            {/* Note */}
            {note && <div style={{ display: "flex", alignItems: "start", fontSize: "11px", paddingTop: "10px" }}>
              <span style={{ fontWeight: "500", color: note.type === "Attention" ? 'rgb(244, 67, 54)' : "#2989cf", }}>{note?.type}:</span>
              <span style={{ color: "#687385" }}>{note?.msg}</span>
            </div>}
          </div>

          <div style={{ display: "flex", justifyContent: "right", position: "", bottom: "10px", right: "12px", width: "", padding: "10px" }}>
            <ButtonWithIcon text="Cancel" width={"85px"} onClick={() => { setCnfrmPopup(false); setSelectedItems(undefined); setNote(false) }} />
            {/* <ButtonWithIcon text="Ok" width="70px" isBtnActive /> */}
            <form
              action={`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_PORT}/makepayment/addons/task_credits/${selectedItem?.price_id}/${selectedItem?.qty ? selectedItem?.qty : 1}?email=${user.username}&creditType=${creditType}`}
              method="POST">
              <button className='remove-botton-styling' style={{
                fontSize: "11px",
                margin: "auto",
                height: "30px",
                color: "white",
                backgroundColor: "#2989cf",
                borderRadius: "20px",
                padding: "6px 20px 10px",
                fontWeight: "600",
                opacity: note?.type === "Attention" ? "0.5" : "1",
                cursor: note?.type === "Attention" ? "default" : "pointer"
              }} type={note?.type === "Attention" ? "button" : "submit"} >Proceed</button>
            </form>
          </div>
        </div>
      </AnimatedModal >
      {/* End Popup */}

      < div className="right-content" style={{ overflowY: "hidden", height: "100%" }} >
        <div className="top-bar"><div className="top-bar-text"> BUY {creditType.toUpperCase()} CREDIT </div></div>
        <div style={{ width: '100%', height: 'calc(100% - 150px)', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2%' }}>
          {
            showLoader ?
              <LoaderComponent
                height={"80%"}
                message="Loading data please wait..."
              />
              :
              <div style={{ border: '1px solid white', backgroundColor: "white", padding: '80px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '80px' }}>
                {
                  data.map((item) => (

                    <div key={item.id} style={{ border: '1px solid #cfd1d0', backgroundColor: "white", display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
                      <div style={{ backgroundColor: '#cfd1d0', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px', paddingTop: '20px', paddingBottom: '20px' }}>
                        {item.name}
                      </div>
                      <div style={{ fontSize: '25px', color: '#2989cf', fontWeight: 'bold', marginTop: '15px', marginBottom: '15px' }}>
                        ₹ {(Number(item.price))}<span style={{ fontSize: '15px' }}></span>
                      </div>
                      <div style={{ backgroundColor: '#cfd1d0', textAlign: 'center', width: '100%', paddingTop: '20px', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '250px', textAlign: 'center', fontSize: '14px' }}>
                          <span> for {Number(item.credits) / 1024} GB {creditType} credits</span>
                        </div>
                      </div>
                      <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                        <ButtonWithIcon
                          isBtnActive
                          text="Buy Now"
                          height="30px" width="95"
                          fontSize="13px" fontWeight="500"
                          borderRadius="35px"

                          onClick={() => {
                            setCnfrmPopup(true)
                            setSelectedItems(item)
                          }}
                        />
                      </div>
                    </div>
                  ))
                }
              </div>
          }
        </div>
      </div >
    </div >
  )



}

export default BuyCredit;