import React, { useEffect, useState } from 'react';
import CalendarIcon from '../icons/calendar.png';
import BlueArrow from '../assets/Blue-arrow-right.svg';
import DatePicker from 'react-datepicker';
import dateFormat from 'dateformat';
import 'react-datepicker/dist/react-datepicker.css';

const FromDateToDatePicker = ({ handleDate }) => {
    const [frmDate, setFrmDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [isFrmDate, setIsFrmDate] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);

    useEffect(() => {
        handleDate({ fromDate: frmDate, toDate: toDate })
    }, [frmDate, toDate])

    return (<>
        <div style={{
            width: "225px",
            height: "35px",
            border: "1px solid #4f4f4f4f",
            borderRadius: "7px",
            padding: "3px 5px",
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
            position: "relative"
        }}>
            <div style={{ position: "absolute", zIndex: "99" }}>
                <DatePicker
                
                    open={showDatePicker}
                    selected={new Date()}
                    maxDate={new Date()}
                    className="hidden react-datepicker-wrapper"
                    onClickOutside={() => { setShowDatePicker(false) }}
                    onChange={(date) => { if (isFrmDate) setFrmDate(date); else setToDate(date) }}
                    dateFormat="yyyy-MM-dd"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    portalId={isFrmDate ? "wd-frm-date" : "wd-to-date"}
                />
            </div>

            <div style={{ display: "flex", width: "45%", justifyContent: "center", cursor: "pointer", alignItems: "center", gap: "5px", fontSize: "15px" }}
                onClick={() => { setIsFrmDate(true); setShowDatePicker(true) }}
            >
                <div id="wd-frm-date"
                    style={{ position: "relative", zIndex: "99" }}
                ><img src={CalendarIcon} style={{ height: "15px" }} name="frmDate" />
                </div>
                <div style={{ fontSize: "11px", color: "#3c3c3c" }}>{`${dateFormat(frmDate, 'dd mmm yyyy')}`}</div>
            </div>
            <div style={{ display: "flex", width: "10%", justifyContent: "center", alignItems: "center" }}>
                <img src={BlueArrow} style={{ height: "9px" }} />
            </div>
            <div style={{ display: "flex", width: "45%", justifyContent: "center", cursor: "pointer", alignItems: "center", gap: "5px", fontSize: "15px" }}
                onClick={(e) => { setIsFrmDate(false); setShowDatePicker(true) }}
            >
                <div id="wd-to-date"
                    style={{ position: "relative", zIndex: "99" }}
                ><img src={CalendarIcon}
                    style={{ height: "15px" }}
                    name="toDate" /></div>
                <div style={{ fontSize: "11px", color: "#3c3c3c" }}>
                    {`${dateFormat(toDate, 'dd mmm yyyy')}`}
                </div>
            </div>
        </div>
    </>)
}

export default FromDateToDatePicker;