import React from 'react'
import { useIdleTimer } from 'react-idle-timer'
import Cookies from "universal-cookie";
import * as API from '../api';
import { useLocation } from 'react-router-dom';
const cookie = new Cookies()

const logoutUser = () => {
    cookie.set('session_IAMAero', "", { path: '/' })
    sessionStorage.clear();
    window.location.replace("/login")
    API.logMeOut()
}

const OnIdleHandlerWrapper = (props) => {
    const timeout = 1000 * 60 * 30
    const location = useLocation()
    // const timeout = 1000 * 30
    const handleOnIdle = () => {
        if (!(location.pathname === "/login" || location.pathname === "/signup")) {
            if (cookie.get("restrictLogout")=="true") {
                reset()
            }
            else logoutUser()
        }
    }

    const {
        reset,
    } = useIdleTimer({
        crossTab:true,
        timeout,
        startOnMount: true,
        onIdle: handleOnIdle,
        syncTimers:200
    })
    return (
        props.children
    )
}

const SetRestrictSesssionExpire = (value) => {
    cookie.set('restrictLogout', value, { path: '/' })
}

export {
    OnIdleHandlerWrapper,
    SetRestrictSesssionExpire,
    logoutUser
}