import React, { Component } from 'react';
import * as API from '../api.js';
import service from "../sidebaricon/Services.png";
import SettingsActive from '../sidebaricon/SettingsActive.png';
import { Link, Redirect } from 'react-router-dom';
import eye from '../Icon/eye.svg'
import eyeOff from '../Icon/eye-off.svg'
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';

export default class MgmtSettings extends Component {

    state = {
        user: {},
        type: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        match: false,
        successParams: {
            title: "SETTINGS",
            message1: "Password has been changed successfully",
            message2: "To go back to dashboard, click",
            hereLink: "/",
            buttonText: "Back to Settings",
            buttonLink: "/profile-settings"
        },
        showOldP: false,
        showNewP: false,
        showConfirmP: false,
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: ""
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }

    componentWillMount() {
        document.title = "Settings - Change password";
        if (this.props) {
            let { user, type } = this.props;
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => {
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    submitChangePassword = (e) => {
        e.preventDefault()
        if (this.state.user.password === '' && this.state.user.aeromegh_login_dirty === false) {
            this.setState((state) => ({
                ...state,
                itemName: "Password",
                processPopupIcon: "WARNING",
                processAction: "UPDATE",
                processMessage: "Are you sure you want to set this password ?",
                showProcessPopup: true,

            }))
        } else {
            this.setState((state) => ({
                ...state,
                address: "-",
                email: state.username,
                itemName: "Password",
                processPopupIcon: "WARNING",
                processAction: "UPDATE",
                processMessage: "Are you sure you want to change the password ?",
                showProcessPopup: true,
            }))
        }
    }

    editPassword = () => {
        if (this.state.user.password === '' && this.state.user.aeromegh_login_dirty === false) {
            this.setState((state) => ({
                ...state,
                oldPassword: '',
            }))
        }
        API.editPassword(this.state.oldPassword, this.state.newPassword).then(() => {
            this.setState((state) => ({
                ...state,
                itemName: "Password",
                processPopupIcon: "COMPLETE",
                processAction: "UPDATE",
                processMessage: '',
                showProcessPopup: true,
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
                match: false
            }), () => {
                document.getElementsByClassName("match-prompt")[0].style.visibility = "hidden"
                document.getElementsByClassName("no-match-prompt")[0].style.visibility = "hidden"
                this.props.save()
            })
        }, (e) => {
            this.setState((state) => ({
                itemName: "Password",
                processPopupIcon: "ERROR",
                processAction: "UPDATE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }
    onChangeListener = (e) => {
        let t = e.target;
        if (t.value.length <= 40) {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value
            }), () => {
                if (t.name == "newPassword" || t.name == "confirmPassword") {
                    if (this.state.newPassword == this.state.confirmPassword) {
                        if (this.state.newPassword == "" && this.state.confirmPassword == "") {
                            document.getElementsByClassName("match-prompt")[0].style.visibility = "hidden"
                            document.getElementsByClassName("no-match-prompt")[0].style.visibility = "hidden"
                            if (this.state.match) {
                                document.getElementsByClassName("add-user-form-submit-button-disabled")[0].disabled = true
                                document.getElementsByClassName("add-user-form-submit-button-disabled")[0].className = "add-user-form-submit-button-disabled"
                                this.setState((state) => ({
                                    ...state,
                                    match: false
                                }))
                            }
                        } else {
                            document.getElementsByClassName("match-prompt")[0].style.visibility = "visible"
                            document.getElementsByClassName("no-match-prompt")[0].style.visibility = "hidden"
                            if (!this.state.match) {
                                document.getElementsByClassName("add-user-form-submit-button-disabled")[0].disabled = false
                                document.getElementsByClassName("add-user-form-submit-button-disabled")[0].className = "add-user-form-submit-button"
                                this.setState((state) => ({
                                    ...state,
                                    match: true
                                }))
                            }
                        }
                    } else {
                        document.getElementsByClassName("match-prompt")[0].style.visibility = "hidden"
                        document.getElementsByClassName("no-match-prompt")[0].style.visibility = "visible"
                        if (this.state.match) {
                            document.getElementsByClassName("add-user-form-submit-button")[0].disabled = true
                            document.getElementsByClassName("add-user-form-submit-button")[0].className = "add-user-form-submit-button-disabled"
                            this.setState((state) => ({
                                ...state,
                                match: false
                            }))
                        }
                    }
                }
            })
        }
    }

    toggleShowPassword = (type) => {
        if (type == 1)
            this.setState((state) => ({
                ...state,
                showOldP: !this.state.showOldP
            }))
        else if (type == 2)
            this.setState((state) => ({
                ...state,
                showNewP: !this.state.showNewP
            }))
        else if (type == 3)
            this.setState((state) => ({
                ...state,
                showConfirmP: !this.state.showConfirmP
            }))
    }
    successRedirectComponent = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/success",
                state: {
                    ...this.state.successParams,
                    user: this.state.user,
                    type: this.state.type,
                    sidebarMode: this.state.sidebarMode
                }
            }} />
        }
    }

    render() {
        let { user, type, customerDisplay, organizations } = this.state
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: SettingsActive,
                    text: "Settings",
                    path: "/profile-settings",
                    selected: true,
                }
            ],
            user,
            type,
            mode: this.state.sidebarMode
        }
        return (
            user.username ? <div className="wrapper">
                <div>
                    {/* --------------Activity Popup ----------------------- */}
                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => this.editPassword()}
                    />
                    {/* --------------------------------------------------------------- */}
                    {this.state.user.password === '' && this.state.user.aeromegh_login_dirty === false ?
                        <div>

                            <div className="top-bar"><div className="top-bar-text">Set Password</div></div>
                            <div style={{ width: "65vw" }}>
                                <div style={{ margin: "3%" }}>
                                    {this.successRedirectComponent()}
                                    <form onSubmit={this.submitChangePassword} action={this.submitChangePassword}>
                                        <div style={{ color: '#666666', fontSize: '12px', marginLeft: '10%' }}>You have not signed up using email and password. Please set a password for your AeroMegh account.</div>
                                        <div style={{ marginLeft: "10%", marginTop: "3%" }}>
                                            <div className="add-user-form-row">
                                                <div className="add-user-form-row-left">
                                                    <div className="add-user-form-label">
                                                        New Password
                                                    </div>
                                                    <div className="add-user-form-text-wrapper">
                                                        <input minLength={8}
                                                            title="Password must contain at least 1 upper case character, at least 1 lower case character, at least 1 digit and 8 total characters"
                                                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}" type={this.state.showNewP ? "text" : "password"} name="newPassword" className="add-user-form-text" value={this.state.newPassword} placeholder="*********" required onChange={this.onChangeListener} />
                                                        <img style={{ cursor: "pointer", position: "relative", right: "28px", height: "20px", width: "auto" }} onClick={() => this.toggleShowPassword(2)} src={this.state.showNewP ? eyeOff : eye}></img>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="add-user-form-row" style={{ marginTop: "20px" }}>
                                                <div className="add-user-form-row-left">
                                                    <div className="add-user-form-label">
                                                        Confirm Password
                                                    </div>
                                                    <div className="add-user-form-text-wrapper">
                                                        <input type={this.state.showConfirmP ? "text" : "password"} name="confirmPassword" className="add-user-form-text" placeholder="*********" value={this.state.confirmPassword} required onChange={this.onChangeListener} />
                                                        <img style={{ cursor: "pointer", position: "relative", right: "28px", height: "20px", width: "auto" }} onClick={() => this.toggleShowPassword(3)} src={this.state.showConfirmP ? eyeOff : eye}></img>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="add-user-form-row match-prompt" style={{ visibility: "hidden" }}>
                                                <div className="add-user-form-row-left">
                                                    <div className="add-user-form-label" style={{ color: "green" }}>
                                                        Passwords Match !
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="add-user-form-row no-match-prompt" style={{ marginTop: "-22px", visibility: "hidden" }}>
                                                <div className="add-user-form-row-left">
                                                    <div className="add-user-form-label" style={{ color: "red" }}>
                                                        Passwords Don't Match
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', width: "90%", justifyContent: "right", marginTop: "5%" }}>
                                            <button className="addcustomer-button-empty-dashboard" onClick={() => this.props.cancel()} style={{ color: "#2B98E9" }}>
                                                Cancel
                                            </button>
                                            <button type="submit" className="add-user-form-submit-button-disabled" style={{ marginLeft: '40px' }} disabled>
                                                Submit
                                            </button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="top-bar"><div className="top-bar-text">Change Password</div></div>
                            <div style={{ width: "65vw" }}>
                                <div style={{ margin: "3%" }}>
                                    {this.successRedirectComponent()}
                                    <form onSubmit={this.submitChangePassword} action={this.submitChangePassword}>
                                        <div style={{ marginLeft: "10%", marginTop: "5%" }}>
                                            <div className="add-user-form-row">
                                                <div className="add-user-form-row-left">
                                                    <div className="add-user-form-label">
                                                        Current Password
                                                    </div>
                                                    <div className="add-user-form-text-wrapper">
                                                        <input type={this.state.showOldP ? "text" : "password"} name="oldPassword" className="add-user-form-text" placeholder="*********" value={this.state.oldPassword} required onChange={this.onChangeListener} />
                                                        <img style={{ cursor: "pointer", position: "relative", right: "28px", height: "20px", width: "auto" }} onClick={() => this.toggleShowPassword(1)} src={this.state.showOldP ? eyeOff : eye}></img>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="add-user-form-row" style={{ marginTop: "20px" }}>
                                                <div className="add-user-form-row-left">
                                                    <div className="add-user-form-label">
                                                        New Password
                                                    </div>
                                                    <div className="add-user-form-text-wrapper">
                                                        <input minLength={8}
                                                            title="Password must contain at least 1 upper case character, at least 1 lower case character, at least 1 digit and 8 total characters"
                                                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}" type={this.state.showNewP ? "text" : "password"} name="newPassword" className="add-user-form-text" value={this.state.newPassword} placeholder="*********" required onChange={this.onChangeListener} />
                                                        <img style={{ cursor: "pointer", position: "relative", right: "28px", height: "20px", width: "auto" }} onClick={() => this.toggleShowPassword(2)} src={this.state.showNewP ? eyeOff : eye}></img>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="add-user-form-row" style={{ marginTop: "20px" }}>
                                                <div className="add-user-form-row-left">
                                                    <div className="add-user-form-label">
                                                        Confirm Password
                                                    </div>
                                                    <div className="add-user-form-text-wrapper">
                                                        <input type={this.state.showConfirmP ? "text" : "password"} name="confirmPassword" className="add-user-form-text" placeholder="*********" required value={this.state.confirmPassword} onChange={this.onChangeListener} />
                                                        <img style={{ cursor: "pointer", position: "relative", right: "28px", height: "20px", width: "auto" }} onClick={() => this.toggleShowPassword(3)} src={this.state.showConfirmP ? eyeOff : eye}></img>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="add-user-form-row match-prompt" style={{ visibility: "hidden" }}>
                                                <div className="add-user-form-row-left">
                                                    <div className="add-user-form-label" style={{ color: "green" }}>
                                                        Passwords Match !
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="add-user-form-row no-match-prompt" style={{ marginTop: "-22px", visibility: "hidden" }}>
                                                <div className="add-user-form-row-left">
                                                    <div className="add-user-form-label" style={{ color: "red" }}>
                                                        Passwords Don't Match
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', width: "90%", justifyContent: "right", marginTop: "5%" }}>
                                            <button className="addcustomer-button-empty-dashboard" onClick={() => this.props.cancel()} style={{ color: "#2B98E9" }}>
                                                Cancel
                                            </button>
                                            <button type="submit" className="add-user-form-submit-button-disabled" style={{ marginLeft: '40px' }} disabled>
                                                Submit
                                            </button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }



                </div>
            </div> : <div />
        );
    }
}
