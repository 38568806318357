
import React, { Component, useEffect } from "react";
import BlackBox from '../../../icons/black_box.png'

class PlantHealthIndexLayer extends Component {
   constructor(props) {
      super(props);
      this.state = {

      }
   }

   handleIncrementDecrement = (valueType, action) => {
      if (valueType === "max") {
         let maxVal = Number(this.props.rangeMax);
         if (action === "+") {
            this.props.changeMinMax("rangeMax", ++maxVal);
         } else {
            this.props.changeMinMax("rangeMax", --maxVal);
         }
      }
      if (valueType === "min") {
         let minVal = Number(this.props.rangeMin);
         if (action === "+") {
            this.props.changeMinMax("rangeMin", ++minVal);
         } else {
            this.props.changeMinMax("rangeMin", --minVal);
         }
      }
   };


   render() {
      return (
         <>
            <hr style={{ marginBottom: "10px", marginTop: "0px", width: "23vw", borderColor: "#EAEAEA" }} />
            <div style={{ marginBottom: "10px" }}>
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 30px', marginBottom: "4px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}
                     className="hoverPointer"
                     onClick={() => this.props.loadTiles(false)}
                  >
                     <input type='checkbox' style={{ height: '15px', width: '15px', cursor: 'pointer' }}
                        checked={this.props.showRangeData}
                     />
                     <span style={{ fontSize: '12px', marginLeft: '10px', color: '#3C3C3C' }}>Plant Health Index Layer</span>
                  </div>
                  <div style={{ fontSize: '10px', color: '#2989cf', marginLeft: '10%', cursor: 'pointer' }} onClick={() => this.props.loadTiles(true)} > Refresh</div>
               </div>
               <div style={{ display: 'flex', alignItems: 'center', padding: '0px 30px', marginBottom: "8px", color: "#3c3c3c", fontSize: "9px" }}>
                  <img src={BlackBox} style={{ height: "8px", width: "8px", marginRight: "5px" }}
                  /> Indicate pixels between the selected range
               </div>
               {this.props.showRangeData ? <>
                  <div style={{ display: "flex", alignItems: 'center', marginBottom: "15px", padding: '0px 30px' }}>
                     <div style={{ color: "#666666", fontSize: "12px", marginRight: '20px', width: '70px', }}>Min:</div>
                     <div style={{ display: "flex", border: '1px solid #6666668f', borderRadius: "5px", border: "1px solid rgb(206, 212, 218)" }}>
                        <div>
                           <input type='number' className="textbox" style={{ fontSize: "10px", padding: "5px 8px", width: "120px", border: "none", borderRadius: "5px", color: "#3c3c3c" }}
                              value={this.props.rangeMin}
                              onInput={(e) => {
                                 if (e.target.value.length <= 10) {
                                    this.props.changeMinMax("rangeMin", Number(e.target.value));
                                 }
                              }}
                           />
                        </div>
                        <div>
                           <div style={{ color: "black", marginRight: '8px' }}><img src="https://cdn-icons-png.flaticon.com/512/60/60995.png" alt="" style={{ transform: 'rotate(180deg)', marginLeft: '10px', width: '10px', height: '10px', borderRadius: '50%', cursor: 'pointer' }}
                              onClick={() => this.handleIncrementDecrement("min", "+")}
                           /></div>
                           <div><img src="https://cdn-icons-png.flaticon.com/512/60/60995.png" alt="" style={{ marginLeft: '10px', width: '10px', height: '10px', borderRadius: '50%', cursor: 'pointer' }}
                              onClick={() => this.handleIncrementDecrement("min", "-")}
                           /></div>
                        </div>
                     </div>
                  </div>
                  <div style={{ display: "flex", alignItems: 'center', marginBottom: "15px", padding: '0px 30px' }}>
                     <div style={{ color: "#666666", fontSize: "12px", marginRight: '20px', width: '70px', }}>Max:</div>
                     <div style={{ display: "flex", border: '1px solid #6666668f', borderRadius: "5px", border: "1px solid rgb(206, 212, 218)", }}>
                        <div>
                           <input type='number' className="textbox" style={{ fontSize: "10px", padding: "5px 8px", width: "120px", border: "none", borderRadius: "5px", color: "#3c3c3c" }}
                              value={this.props.rangeMax}
                              onInput={(e) => {
                              if (e.target.value.length <= 10) {
                                 this.props.changeMinMax("rangeMax", Number(e.target.value));
                              }
                          }}
                           />
                        </div>
                        <div>
                           <div style={{ color: "black", marginRight: '8px' }}>
                              <img src="https://cdn-icons-png.flaticon.com/512/60/60995.png" alt="" style={{ transform: 'rotate(180deg)', marginLeft: '10px', width: '10px', height: '10px', borderRadius: '50%', cursor: 'pointer', color: "rgb(60, 60, 60)" }}
                                 onClick={() => this.handleIncrementDecrement("max", "+")}
                              />
                           </div>
                           <div><img src="https://cdn-icons-png.flaticon.com/512/60/60995.png" alt="" style={{ marginLeft: '10px', width: '10px', height: '10px', borderRadius: '50%', cursor: 'pointer' }}
                              onClick={() => this.handleIncrementDecrement("max", "-")}
                           /></div>
                        </div>
                     </div>
                  </div>

               </> : <></>}
            </div>
         </>
      );
   }
}

export default PlantHealthIndexLayer




