import React, { useEffect, useState } from "react"
import { addProjectAccessAPI, getFeaturePermissionsAPI, getProjectAccessListAPI, getProjectRoleAPI, getRolesAPI, getTeamUsers, removeProjectAccessAPI, updateProjectAccessAPI } from "../api"

const activeTeamKey = "activeTeam"
const activeRoleKey = "activeRole"
let activeRole
let activeTeam
let teams = []

const getOwnTeamMembers = () => {
    return new Promise(async (resolve, reject) => {
        const ownerTeam = teams.find(t => t.isOwner)
        try {
            resolve(await getTeamUsers(ownerTeam.teamId))
        } catch {
            reject([])
        }
    })
}

const getActiveTeamMembers = () => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await getTeamUsers(activeTeam.teamId))?.teamMembers)
        } catch (e) {
            reject([])
        }
    })
}

const getActiveTeamActiveMembers = () => {
    return new Promise(async (resolve, reject) => {
        getActiveTeamMembers().then(teamMembers => {
            resolve(teamMembers.filter(tm => tm.status === "Active"))
        })
            .catch(e => {
                reject(e)
            })
    })
}

const getOwnerTeamId = async () => {
    return ((teams.find(t => t.isOwner)).teamId)
}
const storeTeams = (t) => {
    teams = t
}

const setActiveTeam = async (team) => {
    if (team.isOwner) {
        // if selected team is owner team we are setting role admin by default
        setActiveRole(team.teamOwner.role)
    }
    localStorage.setItem(activeTeamKey, JSON.stringify(team));
    activeTeam = team
    return
}

const isTeamOwner = () => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await getActiveTeam()).isOwner)
        }
        catch (e) {
            reject(e)
        }
    })
}

const getActiveTeam = async () => {
    return new Promise((resolve, reject) => {
        try {
            if (activeTeam) resolve(activeTeam)
            else {
                const storedActiveTeam = JSON.parse(localStorage.getItem(activeTeamKey))
                activeTeam = storedActiveTeam
                resolve(storedActiveTeam)
            }
        }
        catch (e) {
            reject(e)
        }
    })
}

const getActiveTeamId = async () => {
    const teamId = activeTeam ? activeTeam.teamId : (await getActiveTeam()).teamId
    return teamId
}

const setActiveRole = (role) => {
    localStorage.setItem(activeRoleKey, JSON.stringify(role));
    activeRole = role
}

const getActiveRole = () => {
    return new Promise((resolve, reject) => {
        if (activeRole) resolve(activeRole)
        else {
            const storedActiveRole = JSON.parse(localStorage.getItem(activeRoleKey))
            activeRole = storedActiveRole
            resolve(activeRole)
        }
    })
}



const ActiveRole = ({ style }) => {
    const [roleName, setRoleName] = useState("")
    const setActiveRoleName = async () => {
        setRoleName((await getActiveRole()).role)
    }
    useEffect(() => {
        setActiveRoleName()
    }, [activeRole?.role])

    return <RoleNameComponent roleName={roleName} style={{ ...style, fontSize: "14px" }} />
}

const RoleNameComponent = ({ roleName, style }) => {
    const [isOwner, setIsOwner] = useState(true)
    useEffect(() => {
        setTeamOwner()
    }, [style, roleName])
    const setTeamOwner = async () => {
        setIsOwner((await getActiveTeam()).isOwner)
    }

    return <span style={{
        backgroundColor: "#f0f6f4",
        color: "#2989cf",
        padding: "3px 8px",
        borderRadius: "50px",
        fontWeight: "600", fontSize: "inherit",
        marginLeft: "5px",
        ...style,
        display: isOwner ? "none" : undefined
    }}>
        {roleName}
    </span>
}

const getActiveRoleId = async () => {
    return (await getActiveRole()).id
}

const setActiveRoleForProject = (projectId) => {
    return new Promise(async (resolve, reject) => {
        getProjectRoleAPI(await getActiveTeamId(), projectId)
            .then(data => {
                setActiveRole(data.role)
                resolve(data.role)
            })
            .catch(e => {
                reject(e)
            })
    })
}

const getFeaturesPermissions = (featuresList) => {
    return new Promise(async (resolve, reject) => {
        const activeTeam = await getActiveTeam()
        getFeaturePermissionsAPI(activeTeam.teamId, await getActiveRoleId(), featuresList)
            .then(data => {
                resolve({
                    ...data.featuresPermissions,
                    container: activeTeam.teamOwner.container,
                    st: activeTeam.teamOwner.st
                })
            })
            .catch(e => {
                reject(e)
            })
        // resolve({
        //     PT_IMAGE_COLLECTIONS: {
        //         CREATE: true,
        //         DELETE: true,
        //         EDIT: true,
        //         READ: true,
        //         DOWNLOAD: false
        //     },
        //     PT_ANNOTATIONS: {
        //         CREATE: false,
        //         DELETE: false,
        //         EDIT: false,
        //         READ: true,
        //         DOWNLOAD: false
        //     },
        //     PT_TASKS: {
        //         CREATE: true,
        //         DELETE: false,
        //         EDIT: true,
        //         READ: true,
        //         DOWNLOAD: true,
        //         SHARE: true
        //     },
        //     PT_REPORTS: {
        //         CREATE: true,
        //         DELETE: false,
        //         EDIT: true,
        //         READ: true,
        //         DOWNLOAD: true,
        //         SHARE: true
        //     },
        //     DN_PLANS: {
        //         CREATE: false,
        //         DELETE: false,
        //         EDIT: false,
        //         READ: true
        //     },
        //     DN_IMAGE_COLLECTIONS: {
        //         CREATE: false,
        //         DELETE: false,
        //         EDIT: false,
        //         READ: false
        //     },
        //     DN_REPORTS: {
        //         CREATE: true,
        //         DELETE: true,
        //         EDIT: true,
        //         READ: true,
        //         DOWNLOAD: true,
        //         SHARE: true
        //     },
        //     DN_MAPS: {
        //         CREATE: true,
        //         DELETE: false,
        //         EDIT: true,
        //         READ: false,
        //         DOWNLOAD: false,
        //         SHARE: false,
        //     },
        //     GCS_PLANS: {
        //         CREATE: true,
        //         DELETE: true,
        //         EDIT: true,
        //         READ: true
        //     },
        //     GCS_IMAGE_COLLECTIONS: {
        //         CREATE: true,
        //         DELETE: true,
        //         EDIT: true,
        //         READ: true
        //     },
        //     CCS_DRONES: {
        //         CREATE: true,
        //         DELETE: true,
        //         EDIT: true,
        //         READ: true
        //     },
        //     GCS_DRONE_CONTROL_CENTER: {
        //         CREATE: true,
        //         DELETE: true,
        //         EDIT: true,
        //         READ: true
        //     },
        //     container: activeTeam.teamOwner.container,
        //     st: activeTeam.teamOwner.st
        // })
    })
}

const PICSTORK_FEATURES = {
    IMAGECOLLECTIONS: "PT_IMAGE_COLLECTIONS",
    ANNOTATIONS: "PT_ANNOTATIONS",
    TASKS: "PT_TASKS",
    REPORTS: "PT_REPORTS"
}

const DRONENAKSHA_FEATURES = {
    PLANS: "DN_PLANS",
    IMAGECOLLECTIONS: "DN_IMAGE_COLLECTIONS",
    REPORTS: "DN_REPORTS",
    MAPS: "DN_MAPS"
}

const GCS_FEATURES = {
    PLANS: "GCS_PLANS",
    IMAGECOLLECTIONS: "GCS_IMAGE_COLLECTIONS",
    DRONES: "GCS_DRONES",
    DRONE_CONTROL_CENTER: "GCS_DRONE_CONTROL_CENTER"
}

const ROLES = {
    MANAGER: "MANAGER",
    VIEWER: "VIEWER",
    OPERATOR: "OPERATOR"
}

const getProjectAccessList = (projectId) => {
    return new Promise(async (resolve, reject) => {
        getProjectAccessListAPI(await getActiveTeamId(), projectId)
            .then(list => {
                resolve(list.data)
            })
            .catch(e => {
                reject(e)
            })

    })
}

const addAccessToProject = async (projectId, username, roleId, senderId, receiverId, propsData) => {

    let notificationObj = {
        senderId: senderId,
        receiverId: receiverId,
        teamsId: await getActiveTeamId(),
        content: undefined,
        props: JSON.stringify(propsData),
        type: "4",
    }
    return new Promise(async (resolve, reject) => {
        addProjectAccessAPI(projectId, await getActiveTeamId(), roleId, username, notificationObj)
            .then(data => { resolve(data.projectData) })
            .catch(e => { reject(e) })
    })
}

const removeProjectAccess = async (projectId, username, roleId, senderId, receiverId, propsData) => {
    let notificationObj = {
        senderId: senderId,
        receiverId: receiverId,
        teamsId: await getActiveTeamId(),
        content: undefined,
        props: JSON.stringify(propsData),
        type: "4",
    }
    return new Promise(async (resolve, reject) => {
        removeProjectAccessAPI(projectId, await getActiveTeamId(), roleId, username, notificationObj)
            .then(() => {
                resolve()
            })
            .catch(e => {
                reject(e)
            })
    })
}

const updateProjectAccess = async (projectId, username, roleId, senderId, receiverId, propsData) => {

    let notificationObj = {
        senderId: senderId,
        receiverId: receiverId,
        teamsId: await getActiveTeamId(),
        content: undefined,
        props: JSON.stringify(propsData),
        type: "4",
    }
    return new Promise(async (resolve, reject) => {
        updateProjectAccessAPI(projectId, await getActiveTeamId(), username, roleId, notificationObj)
            .then(() => {
                resolve()
            })
            .catch(e => {
                reject(e)
            })

    })
}

//admin member
const getRoles = () => {
    return new Promise((resolve, reject) => {
        getRolesAPI()
            .then(data => {
                resolve(data.roles)
            })
            .catch(e => {
                reject(e)
            })

    })

}

const getRolePermissions = (roleId) => {
    // getRolePermissionsAPI #TODO
}
//Admin
const updateRole = (roleId, updatedFeature) => {
    // updateRoleAPI #TODO
}

export {
    PICSTORK_FEATURES,
    DRONENAKSHA_FEATURES,
    GCS_FEATURES,
    ROLES,
    ActiveRole,
    getFeaturesPermissions,
    setActiveTeam,
    getActiveTeam,
    getActiveRole,
    getRoles,
    getProjectAccessList,
    setActiveRoleForProject,
    getRolePermissions,
    updateRole,
    addAccessToProject,
    removeProjectAccess,
    updateProjectAccess,
    getActiveTeamId,
    RoleNameComponent,
    storeTeams,
    getOwnTeamMembers,
    getOwnerTeamId,
    getActiveTeamMembers,
    getActiveTeamActiveMembers,
    setActiveRole,
    isTeamOwner
}

