import React, { Component } from "react";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import aeromeghLogo from './assets/aeromeghLogo.png'
class accountActivetionmsg extends Component {
  state = {
    username: "",
    password: "",
    showPassword: false
  }

  componentWillMount() {
    document.title = "AeroMegh-Account_Activeted";
  }

  render() {
    return (
      <div className="authPage">
        <div style={{ height: '32rem' }} className="cardLogin login-card">
          <img src={aeromeghLogo} className="login-logo" />
          <div className="greet login-greet">Sign Up to AeroMegh</div>
          <div>
            <div className="content-text" style={{ padding: '20px', fontSize: "16px", lineHeight: '32px', color: 'black' }}>
              Your account has been successfully activated.
            </div>
            <div class="spacer" style={{ height: '4.9vh' }}> </div>
            <Link to={{
              pathname: "/login",
            }}><button style={{ cursor: 'pointer' }} className="loginButton">LOGIN</button></Link>
            <div class="spacer" style={{ height: '4.9vh' }}> </div>
          </div>
        </div>
      </div>
    );
  }
}

export default accountActivetionmsg
