'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  background: rgba(0, 0, 0, .4);\n  border-radius: 5px;\n  bottom: 4px;\n  color: white;\n  font-size: 12px;\n  font-weight: bold;\n  opacity: 0;\n  padding: 10px;\n  pointer-events: none;\n  position: absolute;\n  right: 4px;\n  transition: opacity 0.21s ease-in-out;\n  user-select: none;\n'], ['\n  background: rgba(0, 0, 0, .4);\n  border-radius: 5px;\n  bottom: 4px;\n  color: white;\n  font-size: 12px;\n  font-weight: bold;\n  opacity: 0;\n  padding: 10px;\n  pointer-events: none;\n  position: absolute;\n  right: 4px;\n  transition: opacity 0.21s ease-in-out;\n  user-select: none;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

exports.default = _styledComponents2.default.div(_templateObject);
module.exports = exports['default'];