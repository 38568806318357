import React, { useEffect, useState } from 'react'
import LayerColor from './LayerColor'
import LayerMenu from "../../AeroFarm/Components/LayerMenu"
import * as API from "../../api"
import { Popup } from '../../ReusableComponents/reusableFunctions'
import { useDebouncedCallback } from 'use-debounce';
const Layers = (props) => {
  const handleColorOnChange = useDebouncedCallback(async (layer) => {
    try {
      props.updatelayer(layer,layer.properties.color)
      const updateLayer = await API.updateLayer({id:layer.id,properties:layer.properties})
    } catch (err) {
      Popup.alert("ERROR", err?.message || err)
    }
  }, 300)
  return (
    <>
      {props.layers.map((layer, i) => {
        return <div style={{ borderBottom: "1px solid rgb(218,220,224)", color: "#3c3c3c", fontSize: "11px", padding: "10px 0px", display: "flex", alignItems: "center", justifyContent: "start" }}>
          <input type='checkbox' disabled={props.disableLayers} style={{ cursor: 'pointer', height: "15px", width: "15px" }} checked={layer.showLayer} onChange={() => props.handleLayerToggle(layer)} />
          <div style={{ marginLeft: "10px", minWidth: "55px", backgroundColor: 'white', fontSize: "10px", width: "100%", fontWeight: "400", wordWrap: "break-word" }}>{layer.task_name}</div>
          <div style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
            <LayerColor color={layer?.properties?.color||layer.color} onChangeColor={(color) => handleColorOnChange({...layer, properties: { ...layer.properties, color } })} />
            <LayerMenu
              onClickDownload={() => { props.permissions.DOWNLOAD && props.handleLayerDownload(layer.image_url, layer.image_type, layer.task_name) }}
              onClickDelete={() => { props.permissions.DELETE && props.handleDeleteLayer(layer) }}
              permissions={props.permissions}
            />
          </div>
        </div>
      })}
    </>
  )
}

export default Layers