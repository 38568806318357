import React, { useEffect } from 'react'
import ButtonWithIcon from './ButtonWithIcon'
import transClose from "../AeroFarm/assets/icons/Close.svg"
const Tip = ({ top, left, right, bottom, style, text, tipIcon, btnText, onView, closeTip }) => {

    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            fontSize: "12px",
            gap: "10px",
            color: "#3c3c3c",
            alignItems: "center",
            position: "absolute",
            background: "white",
            borderRadius: "5px",
            top: top || 0,
            left: left || 0,
            right: right || "",
            bottom: bottom || "",
            ...style,
        }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center"
                }}>{text}
                {tipIcon &&
                    <img src={tipIcon} style={{ height: "16px", marginLeft: "5px" }} />
                }
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

                {btnText && <ButtonWithIcon
                    isBtnActive
                    text={btnText}
                    padding={"2px 10px"}
                    onClick={onView}
                />}

                <img
                    style={{
                        height: "16px",
                        cursor: "pointer"
                    }}
                    src={transClose}
                    onClick={closeTip} />
            </div>
        </div>
    )
}

export default Tip