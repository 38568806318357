import React, { useEffect, useState, useRef } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import SingleMap from '../../ReusableComponents/map'
import * as API from "../../api.js";
import { getLocalStorageValue, getLimitedName } from "../../ReusableComponents/reusableFunctions";
import AppConstants, { MAP_URLS } from "../../AppConstants";
import { ActivityPopup } from "../../ReusableComponents/ActivityPopup";
import { AnnotationTool } from "./MapAnnotation/MapAnnotationTool";
import LoaderComponent from "../../ReusableComponents/LoaderComponent";
import ImportExportRegions from "./MapAnnotation/ImportExportRegions";
import { PICSTORK_FEATURES } from "../../Teams";
import ButtonWithIcon from "../../ReusableComponents/ButtonWithIcon";
import Tippy from "@tippyjs/react";

let extendBounds = 0.00075

// pass annotationTool as true if you want annotation feature, otherwise only orthomosaic will be loaded omn map
const OrthoDetectionReport = (props) => {
  const [map, setMap] = useState(undefined)
  const [mapInfo, setMapInfo] = useState(undefined)
  const [bounds, setBounds] = useState(undefined)
  const [joyrideTrigger, setJoyrideTrigger] = useState(undefined)
  const [errorMessage, setError] = useState(undefined)
  const [showProcessPopup, setShowProcessPopup] = useState(false);
  const [processPopupIcon, setPopupIcon] = useState(undefined)
  const [processMessage, setProcessMessage] = useState(undefined)
  const [detections, setDetections] = useState(0)
  const [selectedImageForAnnotation, setSelectedImageForAnnotation] = useState({})
  const [orthoLoaded, setOrthoLoaded] = useState(false)
  const [annotationToolLoaded, setAnnotationToolLoaded] = useState(false)
  const [enableCalculateMesBtn, setEnableCalulateMesBtn] = useState(false)
  const annotationTool = useRef();
  useEffect(() => {
    if (map) getMapInfo(map)
  }, [])

  useEffect(() => {
    if (annotationTool.current && !annotationToolLoaded) {
      setAnnotationToolLoaded(true)
    }
  }, [annotationTool.current])

  const mapInitialized = (map) => {
    map.createPane('Areas')
    map.createPane('ortho');
    map.createPane('annotation')
    setMap(map)
    getMapInfo(map)
  }

  const getMapInfo = (map) => {
    // get detection task result for orthomosaic
    let { task } = props
    let objects = task.type
    API.getDetections(task.task_id, objects).then((data) => {
      setSelectedImageForAnnotation({ collection_id: task.image_collection_id, file_name: data.image_data[0].file_name })
      API.getTilesBounds({
        taskId: task.image_collection_id,
        tiffType: data.image_data[0].file_name,
        sas: encodeURIComponent(props.permissions?.st),
        blobContainer: props.permissions?.container
      }).then((mapInfo) => {
        setMapInfo(mapInfo)
        setMapCenter(mapInfo, map)
        loadOrtho(mapInfo, map)
        drawBoundsBorder(map, mapInfo.bounds)
      })
    }, (e) => {
      console.log(e);
    })
  }

  const setMapCenter = async (info, map1) => {
    if (info && map1) {
      let bounds = [[info.bounds[1] - extendBounds, info.bounds[0] - extendBounds], [info.bounds[3] + extendBounds, info.bounds[2] + extendBounds]]
      map1.fitBounds(bounds);
    } else {
      let bounds = [[mapInfo.bounds[1] - extendBounds, mapInfo.bounds[0] - extendBounds], [mapInfo.bounds[3] + extendBounds, mapInfo.bounds[2] + extendBounds]]
      map.fitBounds(bounds);
    }
  }

  const closeActivityPopup = () => {
    setShowProcessPopup(false)
  }

  const drawBoundsBorder = async (map, bounds) => {
    const drawingBounds = [[bounds[1], bounds[0]], [bounds[3], bounds[2]]]
    const orthoBackground = L.rectangle(drawingBounds, { color: '#3C3C3C', weight: 1, fillColor: "white", fillOpacity: 1, interactive: false }).addTo(map)
    const boundsLayer = L.rectangle(drawingBounds, { color: 'red', weight: 1, fillColor: "none", interactive: false }) // for blink
    setBounds({
      drawingBounds, boundsLayer
    })
    setJoyrideTrigger(getLocalStorageValue('mapAnnotationTour') == 1 && props.showAnnotationTool)
  }

  const loadOrtho = (mapInfo, map) => {
    map.getPane('annotation').style.zIndex = 1002
    map.createPane('ortho');
    const sas = encodeURIComponent(props.permissions?.st)
    let bounds = [[mapInfo.bounds[1], mapInfo.bounds[0]], [mapInfo.bounds[3], mapInfo.bounds[2]]]
    let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${selectedImageForAnnotation.collection_id}/${selectedImageForAnnotation.file_name}/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${props.permissions?.container}`, {
      tileSize: 256,
      maxZoom: 28,
      pane: 'ortho',
      bounds: bounds
    }).addTo(map);
    orthoLayer.on("load", async () => {
      setOrthoLoaded(true)
    });
    if (props.onOrthoLoad) props.onOrthoLoad()
  }

  return <>
    {/* -------------- Activity Popup ----------------------- */}
    <ActivityPopup
      open={showProcessPopup}
      icon={processPopupIcon}
      msg={processMessage}
      close={() => closeActivityPopup()}
      onClickOk={() => {
        closeActivityPopup()
      }}
    />
    {/* --------------------------------------------------------------- */}

    {bounds || !props.showAnnotationTool ? <></> : <div style={{ width: "85%", height: "94%", position: "absolute", backgroundColor: "#eaedf0", zIndex: 2 }}><LoaderComponent height={"90%"} message={"Loading map please wait..."} /></div>}
    {props.showAnnotationTool && map && bounds ?
      <>

        <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", height: '38px' }}>
          <div>
            <span className={"onhoverext hoverPointer"}
              onClick={() => props.changeView(1)}
              style={{
                color: "#2989cf",
                fontSize: "12px",
              }}>Task</span><span style={{
                fontSize: "12px",
              }}> / </span><span className={"onhoverext hoverPointer"}
                onClick={() => props.changeView(2)}
                style={{
                  color: "#2989cf",
                  fontSize: "12px",
                }}>Report</span><span style={{
                  fontSize: "12px",
                }}> / </span> Image
          </div>
          <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>
            {enableCalculateMesBtn &&
              <Tippy
                className="tippy-menu tippy-warning"
                content={<span>Calculate measurements of all annotations at once</span>}>
                <div>
                  <ButtonWithIcon
                    text={"Calculate Measurements"}
                    onClick={() => {
                      annotationTool.current.calculateAllMeasurements()
                    }}
                  />
                </div>
              </Tippy>
            }
            <ButtonWithIcon
              text={"View report"}
              // isDisabled={this.state.objectsDetected === 0 || !(props.task?.task_purpose)}
              linkData={
                {
                  pathname: props.task?.task_purpose === AppConstants.TASKPURPOSE.COUNTING ? "/picstork/Pdf_Download" : "/picstork/defect_report",
                  state: {
                    user: props.user,
                    type: props.task.type,
                    task: props.task,
                  }
                }
              } /></div>
          {annotationToolLoaded ? <ImportExportRegions
            permissions={
              { [PICSTORK_FEATURES.ANNOTATIONS]: props.permissions[PICSTORK_FEATURES.REPORTS] }
            }
            regions={detections}
            task={props.task}
            importRegions={annotationTool.current.importAnnotations}
            exportRegions={annotationTool.current.exportAnnotations}
          /> : <></>}

        </div>

        <div style={{ height: 'calc(100% - 44px)', width: '100%', marginTop: '8px', background: 'white', padding: '10px', display: "flex", justifyContent: "space-between", }}>
          <div style={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column' }}>
            {/* Image header */}
            <div
              style={{
                height: '38px',
                display: "flex",
                width: "100%",
                zIndex: 3,
                borderRadius: "3px 3px 0 0",
                padding: "0px 10px",
                alignItems: "center",
                color: "#FFFFFF",
                backgroundColor: 'rgb(0 0 0 / 0.6)',
              }}>
              <div style={{ fontFamily: "Poppins", fontSize: '13px' }}>{selectedImageForAnnotation ? getLimitedName(selectedImageForAnnotation.file_name, 60) : "Orthomosaic"}</div>
            </div>


            <div style={{ height: '100%', width: '100%', }}>
              <SingleMap
                initBaseLayerURL={MAP_URLS.ROAD_MAP}
                initZoom={18}
                maxZoom={28}
                setMap={(map) => {
                  mapInitialized(map)
                }}
              >
                {/* {renderinsidemap} */}
              </SingleMap>
            </div>

          </div>
          {orthoLoaded ? <div style={{ height: 'calc(100%-38px)', width: '20%', }}>
            <AnnotationTool
              enableCalculateMesBtnFunc={(val) => setEnableCalulateMesBtn(val)}
              permissions={
                { [PICSTORK_FEATURES.ANNOTATIONS]: props.permissions[PICSTORK_FEATURES.REPORTS] }
              }
              ref={annotationTool}
              setAnnotationsCount={setDetections}
              bounds={bounds}
              task={props.task}
              imageInfo={selectedImageForAnnotation}
              map={map}
              orthoLoaded={orthoLoaded}
              updateTask={props.updateTask}
              onClassAdded={props.onClassAdded}
              setMapCenter={setMapCenter}
              showLoader={(event, msg, icon) => {
                if (event) {
                  setPopupIcon(icon)
                  setProcessMessage(msg)
                  setShowProcessPopup(true)
                }
                else {
                  setShowProcessPopup(false)
                }
              }}
            />
          </div> : <></>}
        </div>
      </> : <>
        <SingleMap
          initBaseLayerURL={MAP_URLS.ROAD_MAP}
          initZoom={18}
          maxZoom={28}
          setMap={(map) => {
            mapInitialized(map)
          }}
        >
          {/* {renderinsidemap} */}
        </SingleMap>
      </>
    }
  </>
}

export default OrthoDetectionReport;