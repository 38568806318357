// creatable === true props represents this component mouted from image report page.

import React, { useState, useEffect, useRef, createRef } from "react"
import Select, { StylesConfig, components, DropdownIndicatorProps } from 'react-select';
import close from '../../../assets/icons/circle-close.svg'
import penIcon from '../../../assets/icons/pen.svg'
import selectIcon1 from './../../../../icons/selectIcon1.png'
import copyIcon from './../../../../icons/copy.png'
import deleteIcon from './../../../../icons/deleteIcon.svg'
import tick from './../../../../icons/tick.png'
import ButtonWithIcon from "../../../../ReusableComponents/ButtonWithIcon";
import { MentionsInput, Mention } from 'react-mentions';
import AppConstants from "../../../../AppConstants";
import { getSevirityNumber } from "../../../../ReusableComponents/reusableFunctions";
import { FLOORIMAGES } from "../../../../AeroFarm/Components/FloorComponent";


export const RegionTags = ({
  regions,
  classList,
  projectRegionBox,
  disabledUpdate,
  mouseEvents,
  showSeverity,
  layoutParams,
  onDeleteRegion,
  onClassUpdate,
  onCopyRegion,
  copyRegion,
  searchable,
  creatable,
  setRegionEditable,
  newAdded,
  setError,
  mentionObj,
  isDisabled,
}) => {
  const inputRef = createRef()
  const [mentionValue, setMentionValue] = useState()
  const [mentionUser, setMentionUser] = useState()
  const [mentionMessage, setMentionMessage] = useState()
  const [data, setData] = useState({
    severity: undefined,
    note: undefined
  })
  const [saveData, setSaveData] = useState(false)
  useEffect(() => {
    setData({
      severity: undefined,
      note: undefined
    })
  }, [newAdded])

  const DropdownIndicator = (
    props
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <img style={{ marginLeft: "10px", height: props.iconSize ? props.iconSize : "14px" }} src={selectIcon1} />
      </components.DropdownIndicator>
    );
  };

  const selectStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', color: "#3c3c3c", width: "90px", minHeight: "20px", height: "25px", fontSize: "12px", borderStyle: undefined, borderColor: "none", border: "1px solid #C2C2C2" }),
    valueContainer: (styles) => ({ ...styles, height: "20px", position: "initial", padding: "0 6px" }),
    indicatorsContainer: (styles) => ({ ...styles, height: "20px", marginLeft: "10px" }), //    this is added in style.less public

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        padding: "4px 12px",
        fontSize: "10px",
        // ':active': {
        //   ...styles[':active']
        // },
      };
    },
    // input: (styles) => ({ ...styles, height: "20px",marginRight:"10px" }),
    // placeholder: (styles) => ({ ...styles, height: "20px" }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    singleValue: (styles) => ({ ...styles, maxWidth: "calc(100% - 40px)", color: "#3c3c3c" }),
  };

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData('text');

    const { current } = this.inputRef;
    if (current) {
      const { selectionStart, selectionEnd } = current;
      const updatedValue =
        mentionMessage.slice(0, selectionStart) +
        pastedText + mentionMessage.slice(selectionEnd);
      setMentionMessage(updatedValue)
      // Move the cursor to the end of the pasted text
      current.selectionStart = selectionStart + pastedText.length;
      current.selectionEnd = selectionStart + pastedText.length;
    }
    event.preventDefault(); // Prevent default paste behavior
  };

  const onChangeTeamMember = (e, value, message, mentions, region) => {
    const count = (e.target.value.match(new RegExp('@', 'g')) || []).length;
    if (message.length <= 100) {
      if (!(count > 1)) {

        setMentionValue(value)
        setMentionMessage(message)
        setMentionUser(mentions?.[0]?.id)
        setData({ ...data, value: region.className, note: value, severity: region.data.severity })
        onClassUpdate(region.id, { ...data, value: region.className, note: value, severity: region.data.severity })
      }
    }
  }

  return regions
    .filter((r) => r.visible || r.visible === undefined)
    .map((region, key) => {
      const pbox = projectRegionBox(region)
      const { iw, ih } = layoutParams.current
      let margin = 8
      if (region.highlighted && region.type === "box") margin += 6
      const labelBoxHeight =
        region.editingLabels && !region.locked ? 170 : region.tags ? 60 : 50
      const displayOnTop = region.y >= 0.7
      const displayOnLeft = region.x + region.w >= 0.9

      let coords = displayOnTop
        ? {
          left: pbox.x,
          top: pbox.y - margin / 2,
        }
        : { left: pbox.x, top: pbox.y + pbox.h + margin / 2 }

      return (
        region.className ?
          <>
            {/* <img
              onClick={() => onDeleteRegion(region.id)}
              src={deleteIcon}
              style={{
                position: "absolute",
                left: pbox.x,
                top: pbox.y - margin / 2,
                zIndex:20,
                cursor: "pointer",
                width: "18px",
                borderRadius: "30px",
                marginLeft: "2px",
                backgroundColor: 'rgba(0,0,0,.8)',
              }} /> */}
            <div
              key={key}
              style={{
                position: "absolute",
                ...coords,
                zIndex: 2,
              }}
            >

              <div
                style={{
                  position: "absolute",
                  zIndex: 2,
                  left: 0,
                  display: "flex",
                  alignItems: "center",
                  // right: creatable && (region.x >= 0.9) ? "-121px" : "",
                  // bottom: creatable && (region.y >= 0.8) ? "121px" : "",
                  // top: creatable && (region.y >= 0.8) ? "" : 0,
                  ...(displayOnTop ? { bottom: 0 } : { top: 0 }),
                  ...(displayOnLeft && creatable ? { right: 0, left: "" } : ""),

                }}
              >
                {
                  region.showSeverity ? <>
                    {
                      creatable ?
                        <div
                          style={{
                            backgroundColor: 'white',
                            color: "black",
                            minHeight: "20px",
                            height: region.showSeverity ? "auto" : "29px",
                            borderWidth: "1px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontSize: "12px",
                            padding: region.showSeverity ? "15px 8px" : '2px 10px 0px 2px',
                          }}
                        >
                          <div
                            style={{
                              display: region.showSeverity ? undefined : "flex",
                              alignItems: "center",
                            }}
                          >
                            <div style={{
                              position: "absolute",
                              right: "15px",
                              top: "15px",
                              display: "flex",
                              justifyContent: "right",
                              alignItems: "center"
                            }}>

                              <div
                                style={{
                                  cursor: "pointer",

                                }}
                                onClick={() => {
                                  setData({
                                    severity: undefined,
                                    note: undefined
                                  })
                                  showSeverity()
                                }}
                              >
                                <img
                                  src={close}
                                  style={{ margin: creatable ? "0px 5px" : "6px" }}
                                  width={creatable ? "18px" : "13px"} />
                              </div>
                            </div>
                            {/* <>
                              <div
                                style={{ fontSize: creatable ? "12px" : "10px", fontWeight: "400", color: "#3c3c3c", padding: creatable ? "3px 3px 0px 1px" : "0px 5px 3px 1px", margin: creatable ? undefined : "-2px 0px 5px 0px" }}
                              >
                                Object
                              </div>
                              <CreatableSelect
                                components={{ DropdownIndicator }}
                                onChange={(clicked) => {
                                  let value = clicked.value.trim().toLowerCase().replaceAll(' ', '_')
                                  setData({
                                    ...data, value
                                  })
                                  onClassUpdate(region.id, { ...clicked, value: value })
                                }
                                }
                                isDisabled={disabledUpdate}
                                isSearchable={searchable}
                                styles={{
                                  ...selectStyles,
                                  control: (styles) => ({ ...styles, backgroundColor: 'white', fontWeight: region.showSeverity ? "13px" : "12px", width: region.showSeverity ? "130px" : "100px", minHeight: "2px", height: "25px", border: "1px solid #C2C2C2", paddingLeft: "4px", borderStyle: undefined, outline: "none", boxShadow: "none" }),
                                  valueContainer: (styles) => ({ ...styles, height: "20px", position: "initial", padding: region.showSeverity ? "0 6px 0 0" : "0 6px" }),
                                }}
                                value={region.className ? [{ value: region.className, label: region.className }] : []}
                                options={classList.map((item, i) => { return { value: item, label: item } })}
                              />
                            </> */}
                            <div className='content-text' style={{ fontSize: "12px", fontWeight: "400", textAlign: "left", margin: "6% 0% 4% 0%", padding: "20px 10px 10px 10px" }}>

                              <div style={{ marginBottom: '8px' }}>
                                <div style={{ color: "#666666" }}>
                                  Severity level
                                </div>
                                <div>
                                  <Select
                                    isDisabled={isDisabled}
                                    components={{ DropdownIndicator }}

                                    isSearchable={false}
                                    onChange={(clicked) => {
                                      onClassUpdate(region.id, { ...data, value: region.className, severity: clicked.value, note: region.data.note })
                                      setData({ ...data, value: region.className, severity: clicked.value, note: region.data.note })
                                    }
                                    }
                                    styles={{
                                      ...selectStyles,
                                      valueContainer: (styles) => ({ ...styles, height: "20px", position: "initial", padding: "0 6px 0 0" }),
                                      control: (styles) => ({ ...styles, paddingLeft: "3px", color: "black", width: "100%", minHeight: "20px", height: "25px", border: "1px solid #C2C2C2", borderStyle: undefined, outline: "none", boxShadow: "none" })
                                    }}
                                    value={data.severity ? [{ value: data.severity, label: getSevirityNumber(data.severity) }] : region.data.severity ? [{ value: region.data.severity, label: getSevirityNumber(region.data.severity) }] : []}
                                    options={[{ value: "1", label: AppConstants.SEVIRITY._1 }, { value: "2", label: AppConstants.SEVIRITY._2 }, { value: "3", label: AppConstants.SEVIRITY._3 }, { value: "4", label: AppConstants.SEVIRITY._4 }, { value: "5", label: AppConstants.SEVIRITY._5 }]}
                                  />
                                </div>
                              </div>

                              <div>
                                <div style={{ color: "#666666" }}>
                                  Remark
                                </div>
                                <div
                                  id="addNoteDiv"
                                  style={{
                                    border: "1px solid #C2C2C2",
                                    borderRadius: "4px",
                                    padding: "1px 1px 1px 4px",
                                    display: "flex",
                                    alignItems: 'center',
                                    minWidth: "150px",
                                    maxWidth: "150px"
                                  }}>
                                  {mentionObj ?
                                    <MentionsInput
                                      className="mention-input"
                                      singleLine
                                      maxLength={100}
                                      value={mentionValue || region?.data?.note || ""}
                                      style={{

                                        width: "100%",
                                        color: "#3c3c3c",
                                        fontSize: "12px",
                                        outline: "none",
                                        suggestions: {
                                          list: {
                                            backgroundColor: 'white',
                                            border: '1px solid rgba(0,0,0,0.15)',
                                            fontSize: 14,
                                            maxHeight: "100px",
                                            overflowY: "auto"
                                          },
                                          item: {
                                            padding: '5px 15px',
                                            borderBottom: '1px solid rgba(0,0,0,0.15)',
                                            '&focused': {
                                              backgroundColor: '#cee4e5',
                                            },
                                          },
                                        },
                                      }}
                                      placeholder={"Mention people using '@'"}
                                      onChange={(e, value, message, mentions) => !isDisabled && onChangeTeamMember(e, value, message, mentions, region)}
                                      onPaste={handlePaste}
                                      inputRef={inputRef}
                                    >
                                      <Mention
                                        trigger="@"
                                        data={mentionObj.teamMembers}
                                        appendSpaceOnAdd={true}
                                        style={{
                                          backgroundColor: '#cee4e5',
                                          border: "none",
                                          outline: "none"
                                        }}
                                      />
                                    </MentionsInput> :
                                    <input
                                      disabled={isDisabled}
                                      maxLength={100}
                                      type="text"
                                      name="note"
                                      placeholder="Enter note"
                                      onChange={(e) => {
                                        onClassUpdate(region.id, { ...data, value: region.className, note: e.target.value })
                                        setData({ ...data, value: region.className, note: e.target.value })
                                      }}
                                      value={data.note !== undefined ? data.note : region.data.note}
                                      id="addNote"
                                      style={{ width: '80%', border: "none", color: "#3c3c3c", outlineColor: "#2898CF", outlineWidth: '1px !important' }}
                                    />}
                                  {/* <img style={{ position: "absolute", margin: "5px 0px", right: "10%" }} src={penIcon} /> */}
                                </div>
                              </div>
                            </div>
                            <div>
                              <>

                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "5px 1px 2px 0px", padding: "0px 5px" }}>
                                  {/* <div>
                                    <img src={region.editable ? tick : penIcon} style={{ height: '18px', marginRight: '10px' }} onClick={() => {
                                      if (!disabledUpdate) {
                                        if (region.editable && !region.copy) {
                                          setRegionEditable(region.id)
                                        } else if (!region.copy) {
                                          setRegionEditable(region.id)
                                        }
                                      } else
                                        setError(null, 'Modification restricted')
                                    }} />
                                  </div> */}
                                  <div style={{ cursor: "pointer" }}>
                                    <ButtonWithIcon isDisabled={isDisabled} text={"Delete"} padding={"1px 9px"} onClick={() => onDeleteRegion(region.id)} />
                                  </div>
                                  <div>
                                    <ButtonWithIcon isDisabled={isDisabled} text={"Save"} isBtnActive padding={"1px 9px"} onClick={() => {
                                      // if (data.severity || data.note) {
                                      onClassUpdate(region.id, data, true)
                                      mentionObj.sendAnnotationNotification(mentionUser, mentionMessage, FLOORIMAGES, region)
                                      // }
                                    }} />
                                    {/* <img src={saveIcon} style={{ height: '16px' }} onClick={() => {
                                      // if (data.severity || data.note) {
                                      console.log(data)
                                      onClassUpdate(region.id, data)
                                      // }
                                    }} /> */}
                                  </div>

                                </div>
                              </>

                            </div>
                          </div>
                        </div> : <>
                          {<div style={{ display: "flex", background: "rgb(255 255 255 / 80%)", padding: "3px 0", justifyContent: "space-evenly", alignItems: "center", width: "65px", borderRadius: "5px" }}>

                            <img src={region.copy ? tick : copyIcon} style={{ height: '14px', cursor: region.editable || disabledUpdate ? "not-allowed" : "pointer", opacity: region.editable || disabledUpdate ? 0.5 : 1 }} onClick={() => {
                              if (!disabledUpdate) {
                                if (!region.editable && !region.copy) onCopyRegion(region)
                                else if (!region.editable) onCopyRegion()
                              } else
                                setError(null, 'Modification restricted')
                            }
                            } />
                            <img src={deleteIcon} style={{ height: '16px', cursor: region.editable || region.copy || disabledUpdate ? "not-allowed" : "pointer", opacity: region.editable || region.copy || disabledUpdate ? 0.5 : 1 }} onClick={() => {
                              if (!disabledUpdate) {
                                if (!(region.copy || region.editable)) onDeleteRegion(region.id)
                              } else
                                setError(null, 'Modification restricted')
                            }
                            } />
                            <img src={region.editable ? tick : penIcon} style={{ height: '16px', cursor: region.copy || disabledUpdate ? "not-allowed" : "pointer", opacity: region.copy || disabledUpdate ? 0.5 : 1 }} onClick={() => {
                              if (!disabledUpdate) {
                                if (region.editable && !region.copy) {
                                  setRegionEditable()
                                } else if (!region.copy) {
                                  setRegionEditable(region.id)
                                }
                              } else
                                setError(null, 'Modification restricted')
                            }
                            } />
                          </div>}
                        </>
                    }
                  </> : <></>
                }
              </div>
            </div></> : <></>

      )
    })
}

export default RegionTags
