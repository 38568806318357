import React from 'react';
import emptyTransparent from '../../../icons/emptyTransparent.png'
class ImportAeroPlannerVideo extends React.Component {
    constructor(props) {
        super(props);
    }
    handleFileselect = (flight) => {
        this.props.handleFileselect(flight);
    }
    render() {
        return (
            <div>
                {/*-------------Modal for upoload aeroStream-----------*/}
                <div style={{ marginTop: '20px' }}>
                    {this.props.flights.length == 0 ? <div
                        className="rmp-dashboard-empty-data-message"
                    >
                        No Recent Activities Found
                    </div> :
                        <div>
                            {this.props.flights && this.props.flights.length > 0 ? <div className="flight-graph-container" style={{ paddingRight: '10px', paddingLeft: '0px', width: "100%", marginTop: '12px' }}>
                                {/* <p className="flight-detail-chart-title1">{"Videos"}</p> */}
                                <div className="cardstray2" id="cardstray2" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(230px, 230px))' }}>
                                    {this.props.flights.filter(f => f.stream_uploaded == true || f.end_time == null).sort((a, b) => {
                                        return new Date(b.start_time) - new Date(a.start_time);
                                    }).map((flight, f) => {
                                        return <div className="cardscon"
                                            style={{
                                                height: '190px', width: '220px', cursor: 'pointer',
                                                borderRadius: '3px', padding: 'unset'
                                            }}
                                            onClick={() => this.handleFileselect(flight)}
                                        ><div className="container" style={{ background: 'transparent' }}>
                                                <div className="row">
                                                    <img
                                                        onError={(e) => {
                                                            e.target.src = emptyTransparent
                                                            document.getElementById(f).style.display = ''
                                                        }}
                                                        className="cardimg"
                                                        style={{ width: '220px', height: '136px', position: 'absolute', borderRadius: "0px" }}
                                                        src={flight.thumbURL} alt="Video preview unavailable"
                                                    />
                                                    <div id={f} style={{ marginTop: '90px', fontSize: '16px', width: '243px', height: '136px', position: 'absolute', display: 'none', fontFamily: 'Poppins', fontWeight: 'normal' }}>Video preview unavailable</div>
                                                </div>
                                                <div className="row" style={{ height: '14px' }}>
                                                    <div className="para" style={{ marginTop: '138px', textAlign: 'left', padding: '5px' }}>{`${flight.address.length > 35 ? flight.address.substring(0, 34) + '...' : flight.address}`}</div><br />
                                                </div>
                                                <div className="row" style={{ height: '14px', display: 'flex' }}>
                                                    <div className="para" style={{ marginTop: '142px', marginRight: 'auto', textAlign: 'left', padding: '5px', fontSize: '11px' }}>
                                                        {new Date(flight.start_time).toLocaleString().split(',')[1]}
                                                    </div><br />
                                                </div>

                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div> : <div
                                className="rmp-dashboard-empty-data-message"
                                style={{ marginTop: "50px" }}
                            >
                                No Videos Found
                            </div>}
                        </div>
                    }
                </div>

            </div>
        )
    }
}
export default ImportAeroPlannerVideo;