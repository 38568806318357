import React, { useEffect, useState } from "react";
import edit from "../../../icons/pen.png"
import saveImage from '../../../icons/saveImage.png'
import close from '../../../assets/Icons/Close.png'
const InputWithSaveAndEdit = ({ onSave, isEditable, onValueChange, value, }) => {
    const [editing, setEditing] = useState(false)
    const [tempValue, setTempValue] = useState(value || "");
    useEffect(() => {
        setTempValue(value)
    }, [value])
    return <>{editing ?
        <div style={{ display: 'flex', height: '', marginTop: '', width: "100%", alignItems: "start", marginLeft: "10px" }}>
            {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Sub Title</label><br /> */}
            <input maxLength={'50'} type="text" value={tempValue} name='reportSubTitle'
                className="add-user-form-text2" style={{ width: '100%', fontSize: '12px', }} onChange={(e) => { setTempValue(e.target.value) }} />
            <div style={{ display: "flex", justifyContent: "center", alignItems: "start", marginTop: "8px" }}>
                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                    setEditing(false)
                    onSave(tempValue);
                }} />
                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", cursor: 'pointer' }} src={close} onClick={() => {
                    setEditing(false)
                }} />
            </div>
        </div> :
        <div style={{ display: 'flex', height: '', marginTop: '', alignItems: "start", marginLeft: "10px", marginTop: editing ? "" : "8px" }}>
            <div style={{
                fontSize: '12px',
                wordWrap: "break-word",
                width: "max-content",
                fontWight: '500',
                width: "230px",
                marginRight: "10px"
            }}>
                {tempValue ? tempValue : <span style={{ color: "#666666" }}>Not available</span>}
            </div>
            {!isEditable ? <></> :
                <img id="reportToolImage" data-html2canvas-ignore className="reportToolImage" style={{ marginLeft: '', height: "15px", width: "15px", cursor: 'pointer', alignSelf: "start", marginTop: "" }} src={edit} onClick={() => {
                    setEditing(true)
                }} />}
        </div>
    }
    </>
}
export default InputWithSaveAndEdit