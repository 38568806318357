
import React, { useEffect, useState } from 'react'
import AppConstants, { BTNACTIVE, TASKPURPOSE } from '../../../AppConstants'
import { getLimitedName, getSevirityNumber } from '../../../ReusableComponents/reusableFunctions'
import { SketchPicker } from 'react-color'
import { ACCURACYCOLOR, TESTINGCOLOR, TRAININGCOLOR } from '../MapAnnotation/Constants'
import AreaClassSideBar from './AreaClassSideBar'
import SingleClassWithColor from './SingleClassWithColor'
import { MentionsInput, Mention } from 'react-mentions';
import { getActiveTeamActiveMembers } from '../../../Teams'
import DropDownWithButton from '../../../ReusableComponents/DropDownWithButton'
import ButtonWithIcon from '../../../ReusableComponents/ButtonWithIcon'
import { BOTH, ELEVATION, VOLUME } from './TaskProcessView'

const ClassListWithColor = (props) => {
    const [classListChanged, setClassListChanged] = useState(false)
    const [className, setClassName] = useState("")
    const [selectedClass, setSelectedClass] = useState(undefined)
    const [newClassName, setNewClassName] = useState(undefined)
    const [toggleColorPicker, setToggleColorPicker] = useState(false)
    const [colorPickerClass, setColorPickerClass] = useState()
    const [currColor, setCurrColor] = useState("#ffffff");
    const [currColorClass, setCurrColorClass] = useState(undefined);
    const [currentTab, setCurrentTab] = useState(1)
    const [annotationLabel, setAnnotableLabel] = useState([])
    const [showTemp, setShowTemp] = useState(true)
    const [taskType, setTaskType] = useState()
    //for temperature const for rectangle
    let rectCount = 1;
    let pointCount = 1;
    let lineCount = 1;
    // style for temperature table
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            fontSize: "10px",


        },
        header: {
            display: "flex",
            justifyContent: "start",
            margin: "2px 0px",
            alignItems: "center"
        },
        header1: {
            display: "flex",
            justifyContent: "start",
            margin: "5px 0px",
            alignItems: "center"
        },
        headName: {
            fontSize: "14px",
            color: "#2989cf",
            fontWeight: "500"
        }
        ,
        cell: {
            marginRight: "",
            color: "#666666"
        },
        cell2: {
            // fontSize: "12px"
        },
        annoContainer: {
            margin: "2px 0px",
            padding: "2px 10px",
            cursor: "pointer"
        }
    }

    const [mentionValue, setMentionValue] = useState(props.selectedRegion?.data?.note || "")
    const [mentionMessage, setMentionMessage] = useState('')
    const [mentionedUser, setMentionedUser] = useState('')
    const [teamMembers, setTeamMembers] = useState('')
    const [selectedSeverity, setSelectedSeverity] = useState('None')

    useEffect(() => {
        if (props.selectedRegion?.data) setMentionValue(props.selectedRegion.data.note)
    }, [props.selectedRegion])

    useEffect(() => {
        const selectedItem = document.getElementById(props.selectedRegion?.id)
        if (selectedItem && !props.clickFromList) selectedItem.scrollIntoView({
            behavior: "smooth", block: 'center',
            inline: 'nearest'
        })
    })

    const onClickClass = (cls) => {
        if (!(cls.name === selectedClass)) {
            setSelectedClass(undefined)
            setNewClassName(undefined)
            props.onClickClass(cls)
        }
    }
    const addNewObject = (e) => {
        if (e) e.preventDefault();
        if (!props.disabledUpdate && className && className != "" && className != undefined) {
            let existingClass = annotationLabel.find(cls => cls.name.toLowerCase() === className.toLowerCase())
            if (existingClass) {
                onClickClass(existingClass)
            } else {
                props.addNewObject(className)
            }
            setClassName("")
            setClassListChanged(prev => !prev)
        }
    }
    const checkIfCalculateAvailable = () => {
        if (!props.task?.linked_map) return false
        if (props.task?.task_parameters?.measurement_type == VOLUME || (props.task?.task_parameters?.dsm_url && taskType == 4)) {
            if (props.selectedRegion.volume == undefined || props.selectedRegion.volume == null) return true;
        }
        else if (props.task?.task_parameters?.measurement_type == ELEVATION || (props.task?.task_parameters?.dsm_url && taskType == 4)) {
            if (props.selectedRegion.elevation == undefined || props.selectedRegion.elevation == null) return true;
        }
        else if (props.task?.task_parameters?.measurement_type == BOTH || (props.task?.task_parameters?.dsm_url && taskType == 4)) {
            if (props.selectedRegion.elevation == undefined || props.selectedRegion.elevation == null || props.selectedRegion.volume == undefined || props.selectedRegion.volume == null) return true;
        } else if ((props.selectedRegion.area == null || props.selectedRegion.area == undefined) && props.task?.linked_map)
            return true;
        return false
    }
    useEffect(() => {
        getActiveTeamActiveMembers().then(members => {
            setTeamMembers(members.map(m => ({ id: m.id, display: m.userFullName }))
            )
        })
    }, [])

    useEffect(() => {
        let newClassArr = annotationLabel.map(cls => {
            if (cls.name == currColorClass.name) return { ...cls, color: currColor }
            else return cls
        })
        setAnnotableLabel(newClassArr)
    }, [currColor])


    useEffect(() => {
        const laberArr = []
        for (let i = 0; i < props.classListWithColor.length; i++) {
            let annotationCount = 0;
            let regions = [];
            for (let j = 0; j < props.regions?.length; j++) {
                if (props.classListWithColor[i].name === props.regions[j].className) {
                    annotationCount += 1
                    regions.push(props.regions[j])
                }
            }
            laberArr[i] = {
                ...props.classListWithColor[i],
                count: annotationCount,
                regions
            }
        }
        setAnnotableLabel(laberArr.sort((a, b) => {
            const countA = a.count
            const countB = b.count
            return countB - countA
        }))
    }, [props.regions?.length, props.classListWithColor, classListChanged])

    const classNameHandler = (e) => {
        e.preventDefault()
        if (!(AppConstants.specialCharReg.test(e.target.value))) {
            setClassName(e.target.value.replaceAll(" ", "_"))
        }
    }
    const onChangeTeamMember = (e, value, message, mentions) => {
        const count = (e.target.value.match(new RegExp('@', 'g')) || []).length;
        if (!(count > 1)) setMentionValue(e.target.value)
        if (mentions?.[0]?.id) {
            props.updateData("userName", mentions?.[0]?.id)
            setMentionedUser(mentions?.[0]?.id)
            props.updateData("note", `${message}`)
        } else {
            props.updateData("note", message)
        }
    }
    const getRegionInfo = () => {
        if (props.selectedRegion.type === "box") {
            return <div style={{ padding: "10px 0px 0px 0px" }}>
                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>X </div><div>: {props.selectedRegion.x ? Math.round((props.selectedRegion.x * 100) * props.imageWidth / 100) : "-"} </div></div >
                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>Y </div><div>: {props.selectedRegion.y ? Math.round((props.selectedRegion.y * 100) * props.imageHeight / 100) : "-"} </div></div >
                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>Height</div><div> : {props.selectedRegion.h ? Math.round(((props.selectedRegion.h) * 100) * props.imageHeight / 100) : "-"} </div></div >
                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>Width</div><div> : {props.selectedRegion.w ? Math.round(((props.selectedRegion.w) * 100) * props.imageWidth / 100) : "-"}</div></div >
                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>Center</div> <div>:{props.selectedRegion?.point ?
                    <>{`${props.selectedRegion.point[0]},${props.selectedRegion.point[1]}`}</>
                    : " NA"
                }</div></div>
            </div>
        } else if (props.selectedRegion.type === "circle") {
            return <div style={{ padding: "10px 0px 0px 0px" }}>
                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>X </div><div>: {props.selectedRegion.x ? Math.round((props.selectedRegion.x * 100) * props.imageWidth / 100) : "-"} </div></div >
                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>Y </div><div>: {props.selectedRegion.y ? Math.round((props.selectedRegion.y * 100) * props.imageHeight / 100) : "-"} </div></div >
                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>Radius</div> <div>:{props.selectedRegion.radius ? Math.round((props.selectedRegion.radius * 100) * props.imageWidth / 100) : "-"}</div></div>
            </div>
        } else if (props.selectedRegion.type === "point") {
            return <div style={{ padding: "10px 0px 0px 0px" }}>
                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>X</div> <div>: {props.selectedRegion.x ? Math.round((props.selectedRegion.x * 100) * props.imageWidth / 100) : "-"} </div></div>
                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>Y</div> <div>: {props.selectedRegion.y ? Math.round((props.selectedRegion.y * 100) * props.imageHeight / 100) : "-"} </div></div>
            </div>
        } else if (props.selectedRegion.type === "line") {
            return <div style={{ padding: "10px 0px 0px 0px" }}>
                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>X1</div><div> : {props.selectedRegion.x1 ? Math.round((props.selectedRegion.x1 * 100) * props.imageWidth / 100) : "-"} </div></div >
                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>Y1</div><div> : {props.selectedRegion.y1 ? Math.round((props.selectedRegion.y1 * 100) * props.imageHeight / 100) : "-"} </div></div >
                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>X2</div><div> : {props.selectedRegion.x2 ? Math.round((props.selectedRegion.x2 * 100) * props.imageWidth / 100) : "-"} </div></div >
                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>Y2</div><div> : {props.selectedRegion.y2 ? Math.round((props.selectedRegion.y2 * 100) * props.imageHeight / 100) : "-"} </div></div >
            </div>
        } else return <></>
    }
    useEffect(() => {
        if (props.isThermalImage) setTaskType("THERMAL")
        else if (props.isThermalImage === undefined) setTaskType("NORMAL")
    }, [props.isThermalImage])
    return <>
        {taskType === "THERMAL" ?
            // THERMAL
            <div style={{ height: "100%", display: "flex", flexDirection: "column", cursor: "default" }}>
                <div style={{ display: "flex", justifyContent: "start", paddingRight: '10px', padding: "0px 20px" }} >
                    <div style={{ color: currentTab === 1 ? "#2989cf" : "#666666", transition: "0.25s all", borderBottom: currentTab === 1 ? "3px solid white" : "3px solid white", marginRight: "8%", cursor: "pointer" }} onClick={() => setCurrentTab(1)}><h6 style={{ margin: "0px", padding: "0px", fontSize: "14px" }}>Annotations</h6></div>
                    <div style={{ color: currentTab === 1 ? "#666666" : "#2989cf", transition: "0.25s all", borderBottom: currentTab === 1 ? "3px solid white" : "3px solid white", cursor: "pointer" }} onClick={() => setCurrentTab(2)}><h6 style={{ margin: "0px", padding: "0px", fontSize: "14px" }}>Temperature</h6></div>
                </div>
                <hr style={{ margin: '5px 10px 5px 0px', background: 'rgb(229, 229, 229)' }} />
                {currentTab === 1 ? <>

                    {props.selectedRegion ? <div className='task-value' style={{ zIndex: 3, height: '35px', color: "#3c3c3c", height: "max-content", fontSize: "11px", padding: "0px 10px" }} >
                        {props.selectedRegion.editable ?
                            <>
                                <div style={{ padding: "0px 0px 10px 0px" }} >
                                    <DropDownWithButton
                                        selectedItem={props.selectedRegion.className}
                                        isBorder width="100%" textFontSize="13px"
                                        list={
                                            annotationLabel.length > 0 ?
                                                annotationLabel.map((cls) => {
                                                    return {
                                                        name: cls.name, onClick: () => {
                                                            props.onClassUpdate(props.selectedRegion.id, cls.name, props.selectedRegion.className)
                                                        }
                                                    }
                                                }) :
                                                [{ name: "No classes", onClick: () => { } }]
                                        }
                                        maxHeight={"400px"}
                                    />
                                </div>
                            </> : <div style={{ overflowWrap: 'break-word', color: '#2989cf', fontSize: '13px', alignItems: "center", padding: "10px 0px" }}>
                                {props.selectedRegion.className}
                            </div>}
                        {props.imageHeight ?
                            // imageWiseInfo
                            getRegionInfo()
                            :
                            // mapWiseInfo
                            <></>}
                        {props.taskType == 2 || props.taskType == 4 ? <>
                            <div style={{ display: 'flex', alignItems: "center", padding: "0px 0px", marginTop: props.imageHeight || !props.selectedRegion.editable ? "" : "15px", gap: "10px" }}>
                                <div style={{ width: "45px" }}>Severity</div> {props.selectedRegion.editable ? <>
                                    {/* <div style={{ }}> */}
                                    <div className='task-value' style={{ zIndex: 3, width: "100%", height: '35px', color: "#3c3c3c", height: "max-content", fontSize: "11px", padding: "" }} >
                                        <DropDownWithButton
                                            selectedItem={getSevirityNumber(props.selectedRegion.data?.severity) || 'None'}
                                            width="100%"
                                            textFontSize="13px"
                                            // blueDropDownIcon
                                            textColor=""
                                            list={[
                                                {
                                                    name: AppConstants.SEVIRITY._1, onClick: () => {
                                                        setSelectedSeverity(AppConstants.SEVIRITY._1)
                                                        props.updateData("severity", 1)
                                                    }
                                                },
                                                {
                                                    name: AppConstants.SEVIRITY._2, onClick: () => {
                                                        setSelectedSeverity(AppConstants.SEVIRITY._2)
                                                        props.updateData("severity", 2)
                                                    }
                                                },
                                                {
                                                    name: AppConstants.SEVIRITY._3, onClick: () => {
                                                        setSelectedSeverity(AppConstants.SEVIRITY._3)
                                                        props.updateData("severity", 3)
                                                    }
                                                },
                                                {
                                                    name: AppConstants.SEVIRITY._4, onClick: () => {
                                                        setSelectedSeverity(AppConstants.SEVIRITY._4)
                                                        props.updateData("severity", 4)
                                                    }
                                                },
                                                {
                                                    name: AppConstants.SEVIRITY._5, onClick: () => {
                                                        setSelectedSeverity(AppConstants.SEVIRITY._5)
                                                        props.updateData("severity", 5)
                                                    }
                                                },
                                                {
                                                    name: AppConstants.SEVIRITY.NONE, onClick: () => {
                                                        setSelectedSeverity(AppConstants.SEVIRITY.NONE)
                                                        props.updateData("severity", AppConstants.SEVIRITY.NONE)
                                                    }
                                                },
                                            ]}
                                            isBorder
                                        />
                                    </div>

                                </> : <div>
                                    : {getSevirityNumber(props.selectedRegion.data?.severity) || 'None'}<br />
                                </div>}
                            </div>
                            <div style={{ display: 'flex', alignItems: "center", marginTop: props.selectedRegion.editable ? '8px' : '0px', alignItems: "", padding: "0px 0px", gap: "10px", width: "100%" }}>
                                <div style={{ width: "45px" }}> Note</div>{props.selectedRegion.editable ?
                                    <div
                                        id="addNoteDiv"
                                        style={{
                                            display: "flex",
                                            alignItems: 'center',
                                            height: '30px',
                                            marginLeft: '',
                                            width: 'calc(100% - 55px)',
                                            border: "1px solid rgb(180, 180, 187)",
                                            borderRadius: "4px",
                                            padding: "0px 3px",

                                        }}>

                                        <MentionsInput
                                            className="mention-input"
                                            singleLine
                                            value={mentionValue || props.selectedRegion?.data?.note || ""}
                                            style={{
                                                width: "100%",
                                                height: "25px",
                                                color: "#3c3c3c",
                                                fontSize: "12px",
                                                suggestions: {
                                                    list: {
                                                        backgroundColor: 'white',
                                                        border: '1px solid rgba(0,0,0,0.15)',
                                                        fontSize: 14,
                                                        maxHeight: "100px",
                                                        overflowY: "auto"
                                                    },
                                                    item: {
                                                        padding: '5px 15px',
                                                        borderBottom: '1px solid rgba(0,0,0,0.15)',
                                                        '&focused': {
                                                            backgroundColor: '#cee4e5',
                                                        },
                                                    },
                                                },
                                            }}
                                            placeholder={"Mention people using '@'"}
                                            onChange={onChangeTeamMember}
                                            maxLength={100}
                                        >
                                            <Mention
                                                trigger="@"
                                                data={teamMembers}
                                                appendSpaceOnAdd={true}
                                                style={{
                                                    backgroundColor: '#cee4e5'
                                                }}
                                            />
                                        </MentionsInput>
                                        {/* <input
                                type="text"
                                name="note"
                                maxLength={150}
                                placeholder="Enter note"
                                onChange={(e) => props.updateData("note", e.target.value)}
                                value={props.selectedRegion.data?.note}
                                id="addNote"
                                style={{ height: '26px', width: '80%', border: "none", color: "#3c3c3c", outlineColor: "#2898CF", outlineWidth: '1px !important' }}
                            /> */}
                                    </div> : <div style={{ wordBreak: "break-word", maxHeight: "65px", overflowY: "scroll", marginLeft: "" }}>
                                        : {props.selectedRegion.data?.note ? props.selectedRegion.data?.note : 'NA'}</div>}
                            </div>
                            {props.selectedRegion.editable && (props.selectedRegion.data?.note || props.selectedRegion.data?.severity) ?
                                <div style={{ width: '100%', marginTop: '10px', display: 'flex', justifyContent: 'right' }}>
                                    <div className='task-button' style={{ height: '22px', marginRight: '20px' }} onClick={() => {
                                        setMentionValue("")
                                        props.onRegionDataUpdate(props.selectedRegion.id)
                                    }}>Save
                                    </div>
                                </div>
                                : <></>
                            }
                        </>
                            : <></>
                        }
                        <hr style={{ margin: '5px 10px 5px 0px', background: 'rgb(229, 229, 229)' }} />
                    </div> : <></>}

                    {/* ---------------------------------------------------------------------------------------------------------------- */}

                    <div>
                        <div style={{ filter: props.disabledUpdate ? "grayscale(100%)" : "", opacity: props.disabledUpdate ? "0.5" : "1" }}>
                            <form id="semi-step1" style={{ margin: "10px 0px 10px 0px", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "15px", padding: "5px 10px" }} onSubmit={addNewObject}>
                                <input type="text" value={className} maxLength={30} disabled={props.disabledUpdate} autoFocus={true} onFocus={(e) => {
                                    setSelectedClass(undefined)
                                    setNewClassName(undefined)
                                }} className="add-user-form-text2" placeholder="Add new class label" style={{ width: "82%", height: '28px' }} onChange={classNameHandler}></input>
                                <div
                                    className="add-user-form-text-wrapper"
                                    style={{ marginTop: "0px" }}
                                >
                                    <button disabled={props.disabledUpdate} onClick={addNewObject} className='hoverHighlightClass' style={{ color: AppConstants.BTNACTIVE.BTNTEXT, border: AppConstants.BTNACTIVE.BTNBORDER, borderRadius: "4px", backgroundColor: AppConstants.BTNACTIVE.BTNBG, fontSize: "12px", padding: "3px 20px", fontWeight: "400" }}>Add</button>
                                </div>
                            </form>
                        </div>

                        {/* -------------------------------------------------------------------------------------------------------------------------------- */}



                        <div id='color-picker' style={{ position: "absolute", zIndex: "99", display: toggleColorPicker ? "block" : "none", right: "10px", bottom: "10px" }}>
                            <SketchPicker color={currColor}
                                onChangeComplete={(clr) => {
                                    props.onEditClassColor(colorPickerClass?.name, clr.hex)
                                    setCurrColor(clr.hex)
                                }} />
                        </div>
                    </div>
                    { /*Annotations classes  */}
                    <hr style={{ margin: '5px 10px 5px 0px', background: 'rgb(229, 229, 229)' }} />
                    <div style={{ height: "100%", display: "flex", flexDirection: "column", minHeight: "50%", padding: "0px 10px 0px 0px" }}>
                        <h6 style={{ color: "#2989cf", margin: 0, padding: "10px", fontSize: "15px" }}>Classes</h6>
                        <div className='scroll-container' style={{ height: '100%', overflow: "auto", marginBottom: "10px", borderTop: `1px solid #00000040` }}>

                            {annotationLabel.map((cls, key) => <SingleClassWithColor
                                showTotalCount={props.showTotalCount}
                                {...props}
                                setClassListChanged={() => setClassListChanged(prev => !prev)}
                                annotationLabel={annotationLabel}
                                cls={cls}
                                key={key}
                                setToggleColorPicker={(val) => { setToggleColorPicker(val) }}
                                setColorPickerClass={(val) => { setColorPickerClass(val) }}
                                setCurrColor={(val) => {
                                    setCurrColorClass(cls);
                                    setCurrColor(val)
                                }}>
                            </SingleClassWithColor>
                            )}
                        </div>
                    </div>
                    {/* Areas count*/}

                    {props.areaVisible && <div style={{ boxShadow: AppConstants.BOXSHADOW, height: "100%", maxHeight: "50%", overflow: "hidden", display: "flex", flexDirection: "column" }}><h6 style={{ fontSize: "15px", color: "#2989cf", marginBottom: 0, padding: "10px", }}>Annotation Areas</h6>
                        <div className='removeScrollBar' style={{ borderTop: "1px solid #3c3c3c", height: "100%", overflowY: "scroll", padding: "5px 0px", paddingLeft: "5px" }}>
                            <AreaClassSideBar color={TRAININGCOLOR} parentObj={props.trainingArrWithChildren} text="Training" areaName="Training_Area" onClickAreaName={props.onClickAreaName} selectedAreaId={props.selectedAreaId} setSelectedAreaId={props.setSelectedAreaId} />
                            <AreaClassSideBar color={TESTINGCOLOR} parentObj={props.testingArrWithChildren} text="Testing" areaName="Testing_Area" onClickAreaName={props.onClickAreaName} selectedAreaId={props.selectedAreaId} setSelectedAreaId={props.setSelectedAreaId} />
                            <AreaClassSideBar color={ACCURACYCOLOR} parentObj={props.accuracyArrWithChildren} text="Accuracy" areaName="Accuracy_Area" onClickAreaName={props.onClickAreaName} selectedAreaId={props.selectedAreaId} setSelectedAreaId={props.setSelectedAreaId} />
                        </div></div>}


                </> : <>
                    {/* temperature data -----------------------------------------------------*/}
                    {props.isThermalImage &&
                        <div style={{ overflow: "scroll", height: "100%", paddingRight: '10px' }}>

                            {/* Table for thermal meta parameters*/}

                            {/* <div className='scrollBar' style={{  height:'30%',overflow: "scroll", marginBottom: "10px", borderTop: "1px solid #3c3c3c" }}> */}
                            {/* <h6 style={{ color: "#2989cf", margin: 0, padding: "10px", fontSize: "15px" }}>Temperature</h6> */}
                            <div style={{ display: "flex", alignItems: "center", fontSize: "10px", color: "#666666", padding: "0px 20px", margin: "10px 0px", gap: "10px" }}><label htmlFor='show-temp' style={{ margin: "0px", padding: "0px" }}>Show Temperature</label><input id='show-temp' type='checkbox' checked={showTemp}
                                onChange={() => {
                                    props.toggleTemperature(!showTemp)
                                    setShowTemp(prev => !prev)
                                }
                                } /></div>
                            <h6 style={{ color: "#2989cf", margin: 0, fontSize: "14px", }} className="thermal-padding-header">Parameters</h6>
                            <hr style={{ margin: '5px 0px 5px 0px', background: 'rgb(229, 229, 229)' }} />
                            <div style={{ ...styles.container, padding: "0px 20px", marginBottom: "10px" }}>

                                {typeof props.thermalMeta === 'object' &&
                                    Object.entries(props.thermalMeta?.parameters).map(([param, value]) => (
                                        <div key={param} style={{ ...styles.header1, justifyContent: "start" }}>
                                            <div style={{ ...styles.cell, width: "99px", wordWrap: "break-word", marginRight: "", paddingRight: "30px" }}>{param}</div>
                                            <div className='thermal-font' style={styles.cell2}>{value}</div>
                                        </div>
                                    ))
                                }
                            </div>
                            {/* Table for thermal meta image info*/}

                            {/* <div className='scrollBar' style={{  height:'30%',overflow: "scroll", marginBottom: "10px", borderTop: "1px solid #3c3c3c" }}> */}
                            <h6 style={{ color: "#2989cf", margin: 0, fontSize: "14px" }} className="thermal-padding-header">Image Info</h6>
                            <hr style={{ margin: '5px 0px 5px 0px', background: 'rgb(229, 229, 229)' }} />
                            <div style={{ ...styles.container, padding: "0px 20px", marginBottom: "10px" }} >

                                {typeof props.thermalMeta === 'object' &&
                                    Object.entries(props.thermalMeta?.ImageInfo).map(([property, value]) => (
                                        <div key={property} style={{ ...styles.header1, justifyContent: "start" }}>
                                            <div style={{ ...styles.cell, width: "99px", wordWrap: "break-word", marginRight: property === "Model" ? "" : styles.cell.marginRight }} className={property === "Model" ? "camera-model" : ""}>{property}</div>
                                            <div className='thermal-font' style={styles.cell2}>{value}</div>
                                        </div>
                                    ))
                                }
                            </div>
                            {/* {annotationLabel.map((cls, key) => <SingleClassWithColor {...props} cls={cls} key={key} setToggleColorPicker={setToggleColorPicker} setColorPickerClass={setColorPickerClass} setCurrColor={setCurrColor}></SingleClassWithColor>)} */}
                            {/* </div> */}

                            {/* Table for temprature for rectangle */}

                            {/* <div className='scrollBar' style={{  height:'30%',overflow: "scroll", marginBottom: "10px", marginTop:"10px", borderTop: "1px solid #3c3c3c"  }}> */}
                            {props.isThermalDataLoaded && <><h6 style={{ color: "#2989cf", margin: 0, fontSize: "14px", }} className="thermal-padding-header">Temperature</h6>
                                <hr style={{ margin: '5px 0px 5px 0px', background: 'rgb(229, 229, 229)' }} />
                                <div style={styles.container} className="thermal-padding">
                                    <>
                                        {Array.isArray(props.regions) && props.regions.filter(reg => reg.type === "box").sort((a, b) => {
                                            var numA = parseInt(a.name.match(/\d+$/)[0]);
                                            var numB = parseInt(b.name.match(/\d+$/)[0]);
                                            return numA - numB;
                                        }).map((item, index) => {
                                            return item.hasOwnProperty("boxTemperature") &&
                                                <div style={styles.annoContainer} id={item.id} onClick={() => {
                                                    props.toggleClickedFromList(true)
                                                    props.highlightAnnotation(item.id)
                                                }} className={`tempContainerHover ${(props?.selectedRegion?.id === item.id) ? "selectedTemp" : ""}`}>
                                                    <div style={styles.headName}  >{item?.name}</div>
                                                    <div style={styles.header}>
                                                        <div style={{ ...styles.cell, width: "99px", marginRight: "" }}>Min</div>
                                                        <div className='thermal-font' style={styles.cell2}>{item?.boxTemperature.min ? (item?.boxTemperature.min).toFixed(2) + "°C" : "-"} </div>
                                                    </div>
                                                    <div style={styles.header}>
                                                        <div style={{ ...styles.cell, width: "99px", marginRight: "" }}>Avg</div>
                                                        <div className='thermal-font' style={styles.cell2}>{item?.boxTemperature.Avg ? (item?.boxTemperature.Avg).toFixed(2) + "°C" : "-"} </div>
                                                    </div>
                                                    <div style={styles.header}>
                                                        <div style={{ ...styles.cell, width: "99px", marginRight: "" }}>Max</div>
                                                        <div className='thermal-font' style={styles.cell2}>{item?.boxTemperature.max ? (item?.boxTemperature.max).toFixed(2) + "°C" : "-"} </div>
                                                    </div>
                                                </div>

                                        })}
                                        {Array.isArray(props.regions) && props.regions.filter(reg => reg.type === "line").sort((a, b) => {
                                            var numA = parseInt(a.name.match(/\d+$/)[0]);
                                            var numB = parseInt(b.name.match(/\d+$/)[0]);
                                            return numA - numB;
                                        }).map((item, index) => {
                                            return item.hasOwnProperty("lineTemperature") &&
                                                <div style={styles.annoContainer} id={item.id} onClick={() => {
                                                    props.toggleClickedFromList(true)
                                                    props.highlightAnnotation(item.id)
                                                }} className={`tempContainerHover ${(props?.selectedRegion?.id === item.id) ? "selectedTemp" : ""}`}>
                                                    <div style={styles.headName} >
                                                        {item?.name}
                                                    </div>
                                                    <div style={styles.header}>
                                                        <div style={{ ...styles.cell, width: "99px", marginRight: "" }}>Min</div>
                                                        <div className='thermal-font' style={styles.cell2}>{item?.lineTemperature.min ? (item?.lineTemperature.min).toFixed(2) + "°C" : "-"} </div>
                                                    </div>
                                                    <div style={styles.header}>
                                                        <div style={{ ...styles.cell, width: "99px", marginRight: "" }}>Avg</div>
                                                        <div className='thermal-font' style={styles.cell2}>{item?.lineTemperature.Avg ? (item?.lineTemperature.Avg).toFixed(2) + "°C" : "-"} </div>
                                                    </div>
                                                    <div style={styles.header}>
                                                        <div style={{ ...styles.cell, width: "99px", marginRight: "" }}>Max</div>
                                                        <div className='thermal-font' style={styles.cell2}>{item?.lineTemperature.max ? (item?.lineTemperature.max).toFixed(2) + "°C" : "-"} </div>
                                                    </div>
                                                </div>

                                        })}
                                        {Array.isArray(props.regions) && props.regions.filter(reg => reg.type === "point").sort((a, b) => {
                                            var numA = parseInt(a.name.match(/\d+$/)[0]);
                                            var numB = parseInt(b.name.match(/\d+$/)[0]);
                                            return numA - numB;
                                        }).map((item, index) => {
                                            return item.hasOwnProperty("pointTemperature") &&
                                                <div style={styles.annoContainer} id={item.id} onClick={() => {
                                                    props.toggleClickedFromList(true)
                                                    props.highlightAnnotation(item.id)
                                                }} className={`tempContainerHover ${(props?.selectedRegion?.id === item.id) ? "selectedTemp" : ""}`}>
                                                    <div style={styles.headName} >{item?.name}</div>
                                                    <div style={styles.header}>
                                                        <div style={{ ...styles.cell, width: "99px", marginRight: "" }}>Temperature</div>
                                                        <div className='thermal-font' style={styles.cell2}>{item?.pointTemperature.temperature ? (item?.pointTemperature.temperature).toFixed(2) + "°C" : "-"} </div>
                                                    </div>
                                                </div>
                                        })}


                                    </>
                                </div></>}

                        </div>}
                </>
                }
            </div > :









            // REGULAR 
            <div style={{ height: "100%", display: "flex", flexDirection: "column", width: "100%" }}>
                {props.selectedRegion ? <div className='task-value' style={{ zIndex: 3, height: '35px', color: "#3c3c3c", height: "max-content", fontSize: "11px", padding: "0px 10px" }} >
                    {props.selectedRegion.editable ?
                        <>
                            <div style={{ padding: "0px 0px 10px 0px" }} >
                                <DropDownWithButton
                                    selectedItem={props.selectedRegion.className}
                                    isBorder width="100%" textFontSize="13px"
                                    list={
                                        annotationLabel.length > 0 ?
                                            annotationLabel.map((cls) => {
                                                return { name: cls.name, onClick: () => { props.onClassUpdate(props.selectedRegion.id, cls.name, props.selectedRegion.className) } }
                                            }) :
                                            [{ name: "No classes", onClick: () => { } }]
                                    }
                                    maxHeight={"400px"}
                                />
                            </div>
                        </> : <div style={{ overflowWrap: 'break-word', color: '#2989cf', fontSize: '13px', alignItems: "center", padding: "10px 0px" }}>
                            {props.selectedRegion.className}
                        </div>}
                    {props.imageHeight && props.selectedRegion.x ?
                        // imageWiseInfo
                        (props.selectedRegion.type === "circle" ? <>
                            <div style={{ padding: "10px 0px 0px 0px" }}>
                                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>X </div><div>: {props.selectedRegion.x ? Math.round((props.selectedRegion.x * 100) * props.imageWidth / 100) : "-"} </div></div >
                                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>Y </div><div>: {props.selectedRegion.y ? Math.round((props.selectedRegion.y * 100) * props.imageHeight / 100) : "-"} </div></div >
                                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>Radius</div> <div>:{props.selectedRegion.radius ? Math.round((props.selectedRegion.radius * 100) * props.imageWidth / 100) : "-"}</div></div>
                            </div>
                        </> :
                            <div style={{ padding: "0px 0px 0px 0px" }}>
                                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>X</div> <div>: {Math.round((props.selectedRegion.x * 100) * props.imageWidth / 100)}</div></div>
                                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>Y</div> <div>: {Math.round((props.selectedRegion.y * 100) * props.imageHeight / 100)}</div></div>
                                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>Height</div> <div>: {Math.round(((props.selectedRegion.h) * 100) * props.imageHeight / 100)}</div></div>
                                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>Width</div> <div>: {Math.round(((props.selectedRegion.w) * 100) * props.imageWidth / 100)}</div></div>
                                <div style={{ display: "flex", gap: "10px" }}><div style={{ width: "45px" }}>Center</div> <div>:{props.selectedRegion?.point ?
                                    <>{`${props.selectedRegion.point[0]},${props.selectedRegion.point[1]}`}</>
                                    : " NA"
                                }</div></div>
                            </div>) :
                        // mapWiseInfo
                        <></>}
                    {(props.taskType == 2 || props.taskType == 4) ?
                        <>
                            {props.task_purpose !== "Counting" && <><div style={{ display: 'flex', alignItems: "center", padding: "0px 0px", marginTop: props.imageHeight || !props.selectedRegion.editable ? "" : "15px", gap: "10px" }}>
                                <div style={{ width: "45px" }}>Severity</div> {props.selectedRegion.editable ? <>
                                    {/* <div style={{ }}> */}
                                    <div className='task-value' style={{ zIndex: 3, width: "100%", height: '35px', color: "#3c3c3c", height: "max-content", fontSize: "11px", padding: "" }} >
                                        <DropDownWithButton
                                            selectedItem={getSevirityNumber(props.selectedRegion.data?.severity) || 'None'}
                                            width="100%"
                                            textFontSize="13px"
                                            // blueDropDownIcon
                                            textColor=""
                                            list={[
                                                {
                                                    name: AppConstants.SEVIRITY._1, onClick: () => {
                                                        setSelectedSeverity(AppConstants.SEVIRITY._1)
                                                        props.updateData("severity", 1)
                                                    }
                                                },
                                                {
                                                    name: AppConstants.SEVIRITY._2, onClick: () => {
                                                        setSelectedSeverity(AppConstants.SEVIRITY._2)
                                                        props.updateData("severity", 2)
                                                    }
                                                },
                                                {
                                                    name: AppConstants.SEVIRITY._3, onClick: () => {
                                                        setSelectedSeverity(AppConstants.SEVIRITY._3)
                                                        props.updateData("severity", 3)
                                                    }
                                                },
                                                {
                                                    name: AppConstants.SEVIRITY._4, onClick: () => {
                                                        setSelectedSeverity(AppConstants.SEVIRITY._4)
                                                        props.updateData("severity", 4)
                                                    }
                                                },
                                                {
                                                    name: AppConstants.SEVIRITY._5, onClick: () => {
                                                        setSelectedSeverity(AppConstants.SEVIRITY._5)
                                                        props.updateData("severity", 5)
                                                    }
                                                },
                                                {
                                                    name: AppConstants.SEVIRITY.NONE, onClick: () => {
                                                        setSelectedSeverity(AppConstants.SEVIRITY.NONE)
                                                        props.updateData("severity", AppConstants.SEVIRITY.NONE)
                                                    }
                                                },
                                            ]}
                                            isBorder
                                        />
                                    </div>

                                </> : <div>
                                    : {getSevirityNumber(props.selectedRegion.data?.severity) || 'None'}<br />
                                </div>}
                            </div>
                                <div style={{ display: 'flex', alignItems: "center", marginTop: props.selectedRegion.editable ? '8px' : '0px', padding: "0px 0px", gap: "10px", width: "100%" }}>
                                    <div style={{ width: "45px" }}>Note</div>{props.selectedRegion.editable ?
                                        <div
                                            id="addNoteDiv"
                                            style={{
                                                display: "flex",
                                                alignItems: 'center',
                                                height: '30px',
                                                marginLeft: '',
                                                width: 'calc(100% - 55px)',
                                                border: "1px solid rgb(180, 180, 187)",
                                                borderRadius: "4px",
                                                padding: "0px 3px",

                                            }}>

                                            <MentionsInput
                                                className="mention-input"
                                                singleLine
                                                maxLength={100}
                                                value={mentionValue || props.selectedRegion?.data?.note || ""}
                                                style={{
                                                    width: "100%",
                                                    height: "25px",
                                                    color: "#3c3c3c",
                                                    fontSize: "12px",
                                                    suggestions: {
                                                        list: {
                                                            backgroundColor: 'white',
                                                            border: '1px solid rgba(0,0,0,0.15)',
                                                            fontSize: 14,
                                                            maxHeight: "100px",
                                                            overflowY: "auto"
                                                        },
                                                        item: {
                                                            padding: '5px 15px',
                                                            borderBottom: '1px solid rgba(0,0,0,0.15)',
                                                            '&focused': {
                                                                backgroundColor: '#cee4e5',
                                                            },
                                                        },
                                                    },
                                                }}
                                                placeholder={"Mention people using '@'"}
                                                onChange={onChangeTeamMember}
                                            >
                                                <Mention
                                                    trigger="@"
                                                    data={teamMembers}
                                                    appendSpaceOnAdd={true}
                                                    style={{
                                                        backgroundColor: '#cee4e5'
                                                    }}
                                                />
                                            </MentionsInput>
                                            {/* <input
                                type="text"
                                name="note"
                                maxLength={150}
                                placeholder="Enter note"
                                onChange={(e) => props.updateData("note", e.target.value)}
                                value={props.selectedRegion.data?.note}
                                id="addNote"
                                style={{ height: '26px', width: '80%', border: "none", color: "#3c3c3c", outlineColor: "#2898CF", outlineWidth: '1px !important' }}
                            /> */}
                                        </div> : <div style={{ wordBreak: "break-word", maxHeight: "65px", overflowY: "scroll", marginLeft: "" }}>
                                            : {props.selectedRegion.data?.note ? props.selectedRegion.data?.note : 'NA'}</div>}
                                </div>
                            </>
                            }
                            {((props.taskType == 2 && (props.task?.task_parameters?.measurement_type == ELEVATION || props.task?.task_parameters?.measurement_type == BOTH)) || (props.taskType == 4 && props.task?.task_parameters?.dsm_url)) &&
                                <div style={{ display: 'flex', alignItems: "center", marginTop: props.selectedRegion.editable ? '8px' : '0px', alignItems: "", padding: "0px 0px", gap: "10px", width: "100%" }}>
                                    <div style={{ width: "45px" }}> Elevation</div>
                                    <div style={{ wordBreak: "break-word", maxHeight: "65px", overflowY: "scroll", marginLeft: "" }}>
                                        : {props.selectedRegion?.elevation !== null && props.selectedRegion?.elevation !== undefined ? props.selectedRegion?.elevation : 'NA'}
                                    </div>
                                </div>
                            }
                            {((props.taskType == 2 && (props.task?.task_parameters?.measurement_type == VOLUME || props.task?.task_parameters?.measurement_type == BOTH)) || (props.taskType == 4 && props.task?.task_parameters?.dsm_url)) &&
                                <div style={{ display: 'flex', alignItems: "center", marginTop: props.selectedRegion.editable ? '8px' : '0px', alignItems: "", padding: "0px 0px", gap: "10px", width: "100%" }}>
                                    <div style={{ width: "45px" }}> Volume</div>
                                    <div style={{ wordBreak: "break-word", maxHeight: "65px", overflowY: "scroll", marginLeft: "" }}>
                                        : {props.selectedRegion?.volume !== null && props.selectedRegion?.volume !== undefined ? props.selectedRegion?.volume : 'NA'}
                                    </div>
                                </div>
                            }
                            {((props.taskType == 2 || props.taskType == 4) && props.task?.linked_map) &&
                                <div style={{ display: 'flex', alignItems: "center", marginTop: props.selectedRegion.editable ? '8px' : '0px', alignItems: "", padding: "0px 0px", gap: "10px", width: "100%" }}>
                                    <div style={{ width: "45px" }}> Area</div>
                                    <div style={{ wordBreak: "break-word", maxHeight: "65px", overflowY: "scroll", marginLeft: "" }}>
                                        : {props.selectedRegion?.area !== null && props.selectedRegion?.area !== undefined ? props.selectedRegion?.area : 'NA'}
                                    </div>
                                </div>
                            }
                            <div style={{ display: 'flex' }}>
                                {(props.taskType == 2 || props.taskType == 4) && checkIfCalculateAvailable() &&
                                    <div style={{ display: 'flex', justifyContent: 'start', marginTop: '10px', width: "100%" }}>
                                        <ButtonWithIcon
                                            margin={"0px 0px"}
                                            text={"Calculate "}
                                            onClick={() => props.calculateMeasurements(props.selectedRegion.id)}
                                        />
                                    </div>
                                }
                                {props.selectedRegion.editable && (props.selectedRegion.data?.note || props.selectedRegion.data?.severity) ?
                                    <div style={{ width: '100%', marginTop: '10px', display: 'flex', justifyContent: 'right' }}>
                                        <ButtonWithIcon
                                            text={"Save"}
                                            onClick={() => {
                                                setSelectedSeverity("None");
                                                setMentionValue(undefined);
                                                props.onRegionDataUpdate(props.selectedRegion.id)

                                            }}
                                        />
                                    </div>
                                    : <></>
                                }
                            </div>
                        </>
                        : <></>
                    }
                    <hr style={{ width: '100%', color: 'grey', margin: '15px 0px' }} />
                </div> : <></>}

                {/* ---------------------------------------------------------------------------------------------------------------- */}

                <div>
                    <div style={{ filter: props.disabledUpdate ? "grayscale(100%)" : "", opacity: props.disabledUpdate ? "0.5" : "1" }}>
                        <form id="semi-step1" style={{ margin: "0px 0px 10px 0px", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "15px", padding: "5px 10px" }} onSubmit={addNewObject}>
                            <input type="text" value={className} maxLength={30} disabled={props.disabledUpdate} autoFocus={true} onFocus={(e) => {
                                setSelectedClass(undefined)
                                setNewClassName(undefined)
                            }} className="add-user-form-text2" placeholder="Add new class label" style={{ width: "82%", height: '28px' }} onChange={classNameHandler}></input>
                            <div
                                className="add-user-form-text-wrapper"
                                style={{ marginTop: "0px" }}
                            >
                                <button disabled={props.disabledUpdate} onClick={addNewObject} className='hoverHighlightClass' style={{ color: AppConstants.BTNACTIVE.BTNTEXT, border: AppConstants.BTNACTIVE.BTNBORDER, borderRadius: "4px", backgroundColor: AppConstants.BTNACTIVE.BTNBG, fontSize: "12px", padding: "3px 20px", fontWeight: "400" }}>Add</button>
                            </div>
                        </form>
                    </div>

                    {/* -------------------------------------------------------------------------------------------------------------------------------- */}



                    <div id='color-picker' style={{ position: "absolute", zIndex: "99", display: toggleColorPicker ? "block" : "none", right: "10px", bottom: "10px" }}>
                        <SketchPicker color={currColor}
                            onChangeComplete={(clr) => {
                                props.onEditClassColor(colorPickerClass?.name, clr.hex)
                                setCurrColor(clr.hex)
                            }} />
                    </div>
                </div>
                { /*Annotations classes  */}
                <div style={{ boxShadow: AppConstants.BOXSHADOW, height: "100%", display: "flex", flexDirection: "column", minHeight: "50%" }}>

                    <h6 style={{ color: "#2989cf", margin: 0, padding: "10px", fontSize: "15px" }}>Classes</h6>
                    <div className='scrollBar' style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: "scroll", marginBottom: "10px", borderTop: "1px solid #3c3c3c" }}>

                        {annotationLabel.map((cls, key) => <SingleClassWithColor
                            {...props}
                            setClassListChanged={() => setClassListChanged(prev => !prev)}
                            annotationLabel={annotationLabel}
                            cls={cls}
                            key={key}
                            setToggleColorPicker={setToggleColorPicker}
                            setColorPickerClass={setColorPickerClass}
                            setCurrColor={(val) => { setCurrColorClass(cls); setCurrColor(val) }}
                            enableColorPicker={taskType === "NORMAL"}
                            toggleClassRegions={(cls_name) => {
                                const newAnn = annotationLabel.map(cls => {
                                    if (cls.name == cls_name) {
                                        return { ...cls, showRegions: !cls.showRegions }
                                    } else return { ...cls, showRegions: false }
                                })
                                setAnnotableLabel(newAnn)
                            }}
                        >
                        </SingleClassWithColor>


                        )}
                    </div>
                </div>
                {/* Areas count*/}

                {props.areaVisible && <div style={{ boxShadow: AppConstants.BOXSHADOW, height: "100%", maxHeight: "50%", overflow: "hidden", display: "flex", flexDirection: "column" }}><h6 style={{ fontSize: "15px", color: "#2989cf", marginBottom: 0, padding: "10px", }}>Annotation Areas</h6>
                    <div className='removeScrollBar' style={{ borderTop: "1px solid #3c3c3c", height: "100%", overflowY: "scroll", padding: "5px 0px", paddingLeft: "5px" }}>
                        <AreaClassSideBar color={TRAININGCOLOR} parentObj={props.trainingArrWithChildren} text="Training" areaName="Training_Area" onClickAreaName={props.onClickAreaName} selectedAreaId={props.selectedAreaId} setSelectedAreaId={props.setSelectedAreaId} />
                        <AreaClassSideBar color={TESTINGCOLOR} parentObj={props.testingArrWithChildren} text="Testing" areaName="Testing_Area" onClickAreaName={props.onClickAreaName} selectedAreaId={props.selectedAreaId} setSelectedAreaId={props.setSelectedAreaId} />
                        <AreaClassSideBar color={ACCURACYCOLOR} parentObj={props.accuracyArrWithChildren} text="Accuracy" areaName="Accuracy_Area" onClickAreaName={props.onClickAreaName} selectedAreaId={props.selectedAreaId} setSelectedAreaId={props.setSelectedAreaId} />
                    </div></div>}
            </div>
        }</>

}

export default ClassListWithColor;