import React, { useEffect, useRef, useState } from "react";
import ButtonWithIcon from "../../ReusableComponents/ButtonWithIcon";
import Plus from "../../icons/addBlue.png";
import DsmIcon from "../../icons/DsmIcon.svg";
import DtmIcon from "../../icons/dtmIcon.svg";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AnimatedModal } from "../../ReusableComponents/AnimatedModal";
import { Popup } from "../../ReusableComponents/reusableFunctions";
import Axios from "axios";
import { SERVER, sessionCheck } from "../../api";

var SESSION_ID_CHANGED = false;
const MapDSMToTaskImage = ({ image, isDisabled, onUpload, fileType, uploadEndPoint }) => {
    const fileRef = useRef(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadProgressChanged, setUploadProgressChanged] = useState(0)
    const [isUploading, setIsUploading] = useState(false);
    const [uploadStatus, setuploadStatus] = useState(null);

    const handleClick = () => {
        if (fileRef.current) {
            fileRef.current.click();
        }
    }
    const onfileUpload = (data) => {
        onUpload(data)
    }
    const resetFileRef = () => {
        if (fileRef.current) {
            fileRef.current.value = '';
        }
    }
    const handlefileChange = async (e) => {
        const file = e.target.files[0];
        const allowedExtensions = ['.tiff', '.tif'];
        const fileExt = file.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(`.${fileExt}`)) {
            Popup.alert("ERROR", "Invalid file type. Please upload TIF file.")
            resetFileRef();
            return;
        }

        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("image", JSON.stringify(image));
            formData.append("fileType", fileType);

            const uploadFile = async () => {
                try {
                    const response = await sessionCheck();
                    const config = {
                        headers: {
                            'Authorization': `Bearer ${response?.access_token}`,
                            'Access-Control-Allow-Origin': '*',
                        },
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            const percentage = Math.round((loaded * 100) / total);
                            setUploadProgress(percentage);
                            if (percentage >= 100) {
                                setuploadStatus(`Linking ${fileType.toUpperCase()} ...`)
                            }
                        }
                    }
                    setIsUploading(true);
                    const res = await Axios.post(`${SERVER}/${uploadEndPoint}`, formData, config);
                    if (res.status == 200) {

                        setIsUploading(false)
                        onfileUpload(res.data?.mappedDSM);
                        Popup.alert("COMPLETE", `${fileType.toUpperCase()} file uploaded successfully !`)
                    } else if (res.status == 404) {
                        Popup.alert("ERROR", res.data?.message)
                    }
                } catch (err) {
                    if (err?.response?.status == 404) {
                        console.error("error", err.response);
                        Popup.alert("ERROR", err.response.data.message || "Something went wrong while uploading file")
                    } else {
                        Popup.alert("ERROR", err?.message || "Something went wrong while uploading file")
                    }
                    setIsUploading(false);
                } finally {
                    SESSION_ID_CHANGED = false;
                    resetFileRef();
                    setUploadProgress(0);
                }
            };

            if (!SESSION_ID_CHANGED) {
                SESSION_ID_CHANGED = true;
                uploadFile();
            }

        }
    }
    useEffect(() => {
        setUploadProgressChanged(uploadProgress)
    }, [uploadProgress])
    return (
        <div>
            <input style={{
                display: "none"
            }} type="file" accept=".tiff,.tif" ref={fileRef} onChange={handlefileChange} />

            <div style={{
                width: "160px",
                height: "102px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "#FAFAFA",
                borderRadius: "5px",
                gap: "0.4rem",
                boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)',
                cursor: "pointer"
            }} onClick={() => {
                Popup.alert("CUSTOM", `Please make sure that uploaded ${fileType} file belongs to respective orthomosaic`, () => {
                    setTimeout(() => {
                        handleClick();
                    }, 100)
                }, () => { }, "180px", "", "Ok")
            }}>
                <img src={fileType === "dsm" ? DsmIcon : fileType == "dtm" ? DtmIcon : ""} alt={""} width={"50px"} height={"50px"} />
                <div style={{ fontSize: "14px", color: "#2989CF" }}>
                    <span  >Upload {fileType.toUpperCase()} file</span>
                </div>
            </div>
            {isUploading && (
                <AnimatedModal
                    isOpen={true}
                    onRequestClose={() => { }}
                    height="300px"
                    width="450px"
                >
                    <div className="services-content-title" style={{ textAlign: 'center', marginTop: '15px' }}>{uploadProgress == 100 ? uploadStatus : `Uploading ${fileType.toUpperCase()}...`}</div>
                    <div style={{}}>
                        <div style={{ textAlign: 'center', marginTop: "4%", marginBottom: "2%" }}>
                            <div style={{ width: 175, height: 175, margin: 'auto', fontSize: "10px" }}>
                                <CircularProgressbar
                                    value={uploadProgressChanged}
                                    text={`${uploadProgressChanged}%`}
                                    styles={{
                                        text: {
                                            fontSize: "9px",
                                            color: "#2989cf"
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </AnimatedModal>
            )}


        </div>
    )
}


export default MapDSMToTaskImage