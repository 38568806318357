import {
    UPDATE_PROFILE,
    UPDATE_SUBSCRIPTION_DATA,
    ACTIVE_SUBSCRIPTION
} from './profileTypes'


export const updateProfileDetails = (payload)=>{
    return{
        type:UPDATE_PROFILE,
        payload:payload
    }
}

export const updateSubscriptionData = (payload)=>{
    return{
        type:UPDATE_SUBSCRIPTION_DATA,
        payload:payload
    }
}

export const activesubscription = (payload)=>{
    return{
        type:ACTIVE_SUBSCRIPTION,
        payload:payload
    }
}