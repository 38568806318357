import React, { useState, useEffect } from "react";
import * as API from '../../../api'
import AppConstants from "../../../AppConstants";
import ReactJoyride, { BeaconRenderProps, TooltipRenderProps, ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Multiselect } from "multiselect-react-dropdown";
import closeButton from '../../../close-button.png';
import { getLocalStorageValue, skipAnnotationTaskTour, skipDetectionTaskTour, skipTrainingTaskTour } from "../../../ReusableComponents/reusableFunctions";
import btnAdd from '../../assets/icons/btnAdd.svg'
import { PICSTORK_FEATURES } from "../../../Teams";
import DropDownWithButton from "../../../ReusableComponents/DropDownWithButton";
import ImportClassesFromTask from "../../../ReusableComponents/ImportClassesFromTask";
import ButtonWithIcon from "../../../ReusableComponents/ButtonWithIcon";

const COUNTING = AppConstants.TASKPURPOSE.COUNTING
const DEFECTDETECTION = AppConstants.TASKPURPOSE.DEFECT
const SEGMENTATION = AppConstants.TASKPURPOSE.SEGMENTATION
const POLYGON = "POLYGON"
const BOX = 'BOX'
export const ELEVATION = "ELEVATION"
export const VOLUME = "VOLUME"
export const AREA = "AREA"
export const BOTH = "BOTH"
const TaskProcessView = (props) => {
    const [joyrideTrigger, setJoyrideTrigger] = useState("")
    const [models, setModels] = useState([])
    const [filteredModels, setFilteredModels] = useState([])
    const [unFilteredModels, setUnFilteredModels] = useState([])
    const [selectedModels, setSelectedModels] = useState(props.models || props.task.models || [])
    const [accuracy, setAccuracy] = useState(props.task.accuracy ? props.task.accuracy : 'low')
    const [type, setType] = useState(props.task.training_type ? props.task.training_type : 'Defect')
    const [taskPurpose, setTaskPurpose] = useState(props.task.task_purpose ? props.task.task_purpose : '')
    const [taskOutputType, setTaskOutputType] = useState(props.task.task_parameters?.output_type || null)
    const [elevation, setElevation] = useState(props.task.task_parameters?.measurement_type == ELEVATION || props.task.task_parameters?.measurement_type == BOTH)
    const [volume, setVolume] = useState(props.task.task_parameters?.measurement_type == VOLUME || props.task.task_parameters?.measurement_type == BOTH)
    const [classes, setClasses] = useState(props.task?.type || [])
    const [className, setClass] = useState('')
    const [classError, setClassError] = useState('')
    const [importClasses, setImportClassses] = useState(false)
    const [guideSteps, setGuideSteps] = useState(props.taskType === 1 ? [
        {
            disableBeacon: true,
            target: '#inner-step1',
            content: (<div style={AppConstants.TEXTSTYLING}><p>Setup various parameters of task</p></div>),
            title: (<div style={AppConstants.TITLESTYLING}>Setup Parameters</div>),
            placement: 'left',
            styles: {
                options: {
                    width: 250,
                },
            },
        }

    ] : props.taskType === 2 ?
        [
            {
                disableBeacon: true,
                target: '#inner-step1',
                content: (<div style={AppConstants.TEXTSTYLING}><p>Setup various parameters of task</p></div>),
                title: (<div style={AppConstants.TITLESTYLING}>Setup Parameters</div>),
                placement: 'left',
                styles: {
                    options: {
                        width: 250,
                    },
                },
            },
        ] :
        [
            {
                disableBeacon: true,
                target: '#inner-step1',
                content: (<div style={AppConstants.TEXTSTYLING}><p>Add classes for the images annotation</p></div>),
                title: (<div style={AppConstants.TITLESTYLING}>Setup Parameters</div>),
                placement: 'left',
                styles: {
                    options: {
                        width: 250,
                    },
                },
            },
        ]

    )
    const isTaskEditAccess = () => {
        return (props.permissions?.[PICSTORK_FEATURES.REPORTS].EDIT && !(props.task.status === AppConstants.TASK.STATUS.PENDING || props.task.status === AppConstants.TASK.STATUS.COMPLETED || props.task.status === AppConstants.TASK.STATUS.IN_PROGRESS || props.task.status === AppConstants.TASK.STATUS.STARTED) && (props.task.tasktype == 2 ? (props.task.state <= 3) : true))
    }

    const addClass = (e) => {
        if (e) e.preventDefault();
        let arr = classes ? classes : []
        let temp = className
        temp = temp.trim()
        let isExist = arr.findIndex(data => data == temp)
        if (arr.length == 50) {
            setClassError("Maximum 50 classes can be added.")
            setClass("");
        }
        else if (isExist != -1) {
            setClassError("Class label already added.")
            setClass("");
        }
        else if (className && temp.length > 0) {
            arr.push(temp)
            setClasses(arr)
            props.onChangeDetails({ type: arr })
            setClass("");
            setClassError()
        }
    }

    const onObjectChange = (e) => {
        if (!(AppConstants.specialCharReg.test(e.target.value))) {
            setClass(e.target.value)
        }
    }

    const handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED].includes(status)) {
            if (props.taskType === 1) localStorage.setItem('processTour1', 1)
            if (props.taskType === 2) localStorage.setItem('processTour2', 1)
            if (props.taskType === 4) localStorage.setItem('processTour3', 1)
        } else if ([STATUS.SKIPPED].includes(status)) {
            if (props.taskType === 1) skipTrainingTaskTour()
            else if (props.taskType === 2) skipDetectionTaskTour()
            else if (props.taskType === 4) skipAnnotationTaskTour()
            setJoyrideTrigger(false)
        }
    };

    useEffect(() => {
        if (props.task) {
            setTimeout(() => {
                if ((props.taskType === 1 && getLocalStorageValue('processTour1') == null) ||
                    (props.taskType === 2 && getLocalStorageValue('processTour2') == null) ||
                    (props.taskType === 4 && getLocalStorageValue('processTour3') == null)) {
                    setJoyrideTrigger(true)
                }
            }, AppConstants.TRIGGER_DELAY)
            getModels()
        }
    }, [props.task.linked_map])

    const removeClassFromSelectedModel = (modelNo, classNo) => {
        let arr = selectedModels
        arr[modelNo].objects.splice(classNo, 1)
        if (arr[modelNo].objects.length == 0)
            arr.splice(modelNo, 1)
        setSelectedModels(arr)
        props.onChangeDetails({ models: arr, task_purpose: taskPurpose, task_parameters: { ...props.task.task_parameters, output_type: taskOutputType, taskOutputType, measurement_type: calculateMeasurementType(elevation, volume) } })
        getModels()
    }

    const onSelectObject = (selectedList, selectedItem) => {
        let arr = models.filter(data => {
            let item = selectedList.find(i => i.id == data.id)
            return item != undefined
        })
        setSelectedModels(arr)
        props.onChangeDetails({ models: arr, task_purpose: taskPurpose, task_parameters: { ...props.task.task_parameters, output_type: taskOutputType, measurement_type: calculateMeasurementType(elevation, volume) } })
    };

    const getModels = () => {
        API.getDetectionModels().then(
            async (data) => {
                if (data.data) {
                    let models = data.data.filter(model => props.task.linked_map == null ? !(model.name.includes(AppConstants.ORTHODETECTORTYPE)) : model.name.includes(AppConstants.ORTHODETECTORTYPE))
                    let filteredModels = models.filter((m) => {
                        return !m.name.includes('maskrcnn')
                    })
                    setModels(models)
                    setUnFilteredModels(models)
                    setFilteredModels(filteredModels)
                }
            },
        );
    };

    const onChangeAccuracy = (event) => {
        if (isTaskEditAccess()) {
            setAccuracy(event.target.value);
            props.onChangeDetails({ accuracy: event.target.value, training_type: type })
        }
    };
    const onChangeType = (event) => {
        if (isTaskEditAccess()) {
            setType(event.target.value);
            setTaskPurpose(event.target.value)
            props.onChangeDetails({ accuracy: accuracy, training_type: event.target.value, task_purpose: event.target.value, models: selectedModels, task_parameters: { ...props.task.task_parameters, measurement_type: calculateMeasurementType(elevation, volume), output_type: taskOutputType } })
        }
    };
    const onChangeOutputType = (event) => {
        if (isTaskEditAccess()) {
            setTaskOutputType(event.target.value);
            props.onChangeDetails({ models: selectedModels, task_purpose: taskPurpose, task_parameters: { ...props.task.task_parameters, output_type: event.target.value, measurement_type: calculateMeasurementType(elevation, volume) } })
        }
    };
    const calculateMeasurementType = (elev, vol) => {
        if (elev && vol) return BOTH;
        if (elev) return ELEVATION;
        if (vol) return VOLUME
        return null
    }
    const onChangeVolume = (event) => {
        if (isTaskEditAccess() && event) {
            const vol = event.target.checked
            setVolume(vol)
            props.onChangeDetails({ models: selectedModels, task_purpose: taskPurpose, task_parameters: { ...props.task.task_parameters, output_type: taskOutputType, measurement_type: calculateMeasurementType(elevation, vol) } })
        }
    }
    const onChangeElevation = (event) => {
        if (isTaskEditAccess() && event) {
            const elev = event.target.checked
            setElevation(elev)
            props.onChangeDetails({ models: selectedModels, task_purpose: taskPurpose, task_parameters: { ...props.task.task_parameters, output_type: taskOutputType, measurement_type: calculateMeasurementType(elev, volume) } })
        }
    }
    const getUniqueDetectorName = (arr) => {
        const displayNameCounts = {};

        // Iterate through the array to count occurrences of each display name
        arr.forEach((obj) => {
            const displayName = obj.display_name;

            // If the display name is encountered for the first time, initialize count to 1
            if (!displayNameCounts[displayName]) {
                displayNameCounts[displayName] = 1;
            } else {
                // If the display name is encountered again, update it and increment the count
                const updatedName = `${displayName}_${displayNameCounts[displayName]}`;
                obj.display_name = updatedName;
                displayNameCounts[displayName]++;
            }
        });

        return arr;
    }
    useEffect(() => {
        if (props.taskType === 2) {
            props.onChangeDetails({ task_purpose: props.task.task_purpose || COUNTING, models: props.task.selectedModels || selectedModels, task_parameters: props.task.task_parameters })

        }
        else props.onChangeDetails({ type: classes, accuracy: props.task.accuracy || "low", training_type: props.task.training_type || "Defect", })
    }, [])
    return <>

        {joyrideTrigger ?
            <ReactJoyride
                callback={(e) => handleJoyrideCallback(e)}
                continuous={true}
                run={true}
                hideBackButton={true}
                hideCloseButton={true}
                showSkipButton={true}
                steps={guideSteps}
                disableOverlayClose={true}
                floaterProps={AppConstants.FLOATER_PROPS}
                locale={AppConstants.LOCALE_BUTTONS}
                styles={{
                    buttonNext: AppConstants.NEXT_BUTTON,
                    buttonSkip: AppConstants.SKIP_BUTTON,
                    spotlight: AppConstants.SPOTLIGHT,
                    options: AppConstants.MAIN_OPTIONS
                }}
            />
            : <></>}
        <div
            className="services-content-title"

            style={{
                margin: "1%",
                display: "flex",
                height: "30px"
            }}
        >
            Select Processing Parameters
        </div>

        <div
            style={{
                margin: "1% 1%",
                textAlign: "left",
                justifyContent: "left",
                background: "#fff",
                boxShadow: "0px 6px 18px 6px rgb(0 0 0 / 18%)",
                height: '90%',
                width: '98%',
                padding: '2% 3%',
                overflowY: 'auto'
            }}
        >
            {props.task.tasktype == 2 ?
                <div id='inner-step1' style={{ display: 'flex', flexDirection: 'column', height: "100%", gap: "20px" }}>


                    {/* Task purpose selection */}
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <div className="itemValue" style={{ fontWeight: 500, color: "#3c3c3c", fontSize: "13px", marginBottom: "10px" }}>Select Detection Purpose</div>
                        <div style={{ display: 'flex', gap: "70px" }}>

                            {/* 1. Object Counting */}
                            <div
                                className="add-user-form-row-left"
                                style={{ display: "flex", alignItems: "start", maxWidth: "340px", width: "100%" }}
                            >
                                <div className="add-user-form-text-wrapper"
                                    style={{ display: "flex", alignItems: "start" }}>
                                    <input
                                        checked={taskPurpose == COUNTING}
                                        disabled={!(isTaskEditAccess())}
                                        name="taskPurpose"
                                        className="add-user-form-text setup-radio-btn"
                                        type="radio"
                                        value={COUNTING}
                                        style={{
                                            width: "18px",

                                        }}
                                        onChange={onChangeType}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginLeft: "2%",
                                        fontSize: "12px",
                                        marginBottom: "5px",
                                        display: "flex",
                                        flexDirection: 'column',
                                        textAlign: 'left',
                                        alignItems: "start",
                                        minWidth: 'fit-content',
                                        color: "#3c3c3c"
                                    }}
                                >
                                    <div>Object Counting</div>
                                    <div style={{ color: "#838383", fontSize: '10px', minWidth: "fit-content" }}>(Count the detected objects)</div>
                                </div>
                            </div>


                            {/* 2. Defect Detection */}
                            <div
                                className="add-user-form-row-left"
                                style={{ display: "flex", alignItems: "start", maxWidth: "340px", width: "100%" }}
                            >
                                <div
                                    className="add-user-form-text-wrapper"
                                    style={{ display: "flex", alignItems: "start" }}
                                >
                                    <input
                                        checked={taskPurpose == DEFECTDETECTION}
                                        disabled={!(isTaskEditAccess())}
                                        name="taskPurpose"
                                        className="add-user-form-text setup-radio-btn"
                                        type="radio"
                                        value={DEFECTDETECTION}
                                        style={{
                                            width: "18px",

                                        }}
                                        onChange={onChangeType}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginLeft: "2%",
                                        fontSize: "12px",
                                        marginBottom: "5px",
                                        display: "flex",
                                        flexDirection: 'column',
                                        textAlign: 'left',
                                        alignItems: "start",
                                        minWidth: 'fit-content',
                                        color: "#3c3c3c"
                                    }}
                                >
                                    <span>Defect Detection</span>
                                    <span style={{ color: "#838383", fontSize: '10px', minWidth: "fit-content" }}>(Identify defects in the image)</span>
                                </div>
                            </div>

                        </div>
                    </div>


                    {/* Select Detection Output Shape */}
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <div className="itemValue" style={{ fontWeight: 500, color: "#3c3c3c", fontSize: "13px", marginBottom: "10px", marginTop: "2%", }}>Select Detection Output Shape</div>
                        <div style={{ display: 'flex', gap: "70px" }}>

                            {/* 1. Polygon */}
                            <div
                                className="add-user-form-row-left"
                                style={{ display: "flex", alignItems: "start", maxWidth: "340px", width: "100%" }}
                            >
                                <div className="add-user-form-text-wrapper"
                                    style={{ display: "flex", alignItems: "start", }}
                                >
                                    <input
                                        checked={taskOutputType == POLYGON}
                                        disabled={!(isTaskEditAccess())}
                                        name="taskOutput"
                                        className="add-user-form-text setup-radio-btn"
                                        type="radio"
                                        value={POLYGON}
                                        style={{
                                            width: "18px",

                                        }}
                                        onChange={onChangeOutputType}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginLeft: "2%",
                                        fontSize: "12px",
                                        marginBottom: "5px",
                                        display: "flex",
                                        flexDirection: 'column',
                                        textAlign: 'left',
                                        alignItems: "start",
                                        minWidth: 'fit-content',
                                        color: "#3c3c3c"
                                    }}
                                >
                                    <span>Polygon</span>
                                    <span style={{ color: "#838383", fontSize: '10px', minWidth: "fit-content" }}>(Detected objects will be highlighted with a polygon shape)</span>
                                </div>
                            </div>

                            {/* 2. Box */}
                            <div
                                className="add-user-form-row-left"
                                style={{ display: "flex", alignItems: "start", maxWidth: "340px", width: "100%" }}
                            >
                                <div className="add-user-form-text-wrapper"
                                    style={{ display: "flex", alignItems: "start" }}
                                >
                                    <input
                                        checked={taskOutputType == BOX}
                                        disabled={!(isTaskEditAccess())}
                                        name="taskOutput"
                                        className="add-user-form-text setup-radio-btn"
                                        type="radio"
                                        value={BOX}
                                        style={{
                                            width: "18px",

                                        }}
                                        onChange={onChangeOutputType}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginLeft: "2%",
                                        fontSize: "12px",
                                        marginBottom: "5px",
                                        display: "flex",
                                        flexDirection: 'column',
                                        textAlign: 'left',
                                        alignItems: "start",
                                        minWidth: 'fit-content',
                                        color: "#3c3c3c"
                                    }}
                                >
                                    <span>Bounding Box</span>
                                    <span style={{ color: "#838383", fontSize: '10px', minWidth: "fit-content" }}>(Detected objects will be highlighted with a bounding box)</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Select Measurement */}
                    {
                        props.task.task_parameters?.dsm_url
                        && <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="itemValue" style={{ fontWeight: 500, color: "#3c3c3c", fontSize: "13px", marginBottom: "10px", marginTop: "2%", }}>Select Measurement <span style={{ color: "#838383", fontSize: '10px', minWidth: 'fit-content', marginLeft: "5px" }}>(Optional)</span></div>
                            <div style={{ display: "flex", gap: "70px" }}>
                                {/*1. Elevation */}
                                <div
                                    className="add-user-form-row-left"
                                    style={{ display: "flex", alignItems: "start", maxWidth: "340px", width: "100%" }}
                                >
                                    <div className="add-user-form-text-wrapper"
                                        style={{ display: "flex", alignItems: "start" }}
                                    >
                                        <input
                                            checked={elevation}
                                            disabled={!(isTaskEditAccess())}
                                            name="elevation"
                                            className="add-user-form-text setup-radio-btn"
                                            type="checkbox"
                                            style={{
                                                width: "18px",

                                            }}
                                            onChange={onChangeElevation}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: "2%",
                                            fontSize: "12px",
                                            marginBottom: "5px",
                                            display: "flex",
                                            flexDirection: 'column',
                                            textAlign: 'left',
                                            alignItems: "start",
                                            minWidth: 'fit-content'
                                        }}
                                    >
                                        <div>Elevation</div>
                                        <div style={{ color: "#838383", fontSize: '10px', minWidth: 'fit-content' }}>(Measure the height of detected objects)</div>
                                        {props.task.task_parameters.dtm_url ? <></> : <div style={{ color: "red", fontSize: '10px', minWidth: 'fit-content' }}>Elevation will be calculated from sea level due to absence of DTM</div>}
                                    </div>
                                </div>


                                {/*2. VOLUME */}
                                <div
                                    className="add-user-form-row-left"
                                    style={{ display: "flex", alignItems: "start", maxWidth: "340px", width: "100%" }}
                                >
                                    <div className="add-user-form-text-wrapper"
                                        style={{ display: "flex", alignItems: "start" }}
                                    >
                                        <input
                                            checked={volume}
                                            disabled={!(isTaskEditAccess())}
                                            name="volume"
                                            className="add-user-form-text setup-radio-btn"
                                            type="checkbox"
                                            style={{
                                                width: "18px",

                                            }}
                                            onChange={onChangeVolume}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: "2%",
                                            fontSize: "12px",
                                            marginBottom: "5px",
                                            display: "flex",
                                            flexDirection: 'column',
                                            textAlign: 'left',
                                            alignItems: "start",
                                            minWidth: 'fit-content'
                                        }}
                                    >
                                        <span>Volume</span>
                                        <span style={{ color: "#838383", fontSize: '10px', minWidth: 'fit-content' }}>(Measure the volume of detected objects)</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }

                    {/*  Select and configure the detectors*/}
                    <div style={{ marginTop: "2%", display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left", height: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className="itemValue"> Detectors <span style={{ color: "#838383", fontSize: '10px', marginLeft: "2px" }}>(Select and configure the detectors)</span>  <br /></div>
                            <Multiselect
                                closeOnSelect={false}
                                placeholder="Select Detectors"
                                options={taskPurpose == DEFECTDETECTION ? getUniqueDetectorName(filteredModels) : getUniqueDetectorName(models)}
                                displayValue="display_name"
                                onSelect={onSelectObject}
                                onRemove={onSelectObject}
                                showCheckbox={true}
                                //selectionLimit={2}
                                closeIcon="cancel"
                                disable={!isTaskEditAccess()}
                                selectedValues={selectedModels}
                                style={{
                                    searchBox: { // To change search box element look
                                        background: "white",
                                        fontSize: "10px",
                                        borderRadius: "6px",
                                        minWidth: "100%",
                                        height: "100px",
                                        overflowY: "auto"
                                    },
                                    option: { // To change css for option 
                                        fontSize: "12px",
                                    },
                                    chips: { // To change css chips(Selected options)
                                        background: "#2989cf",
                                        fontSize: "12px"
                                    },
                                }}
                            />
                        </div>
                        <div style={{ fontWeight: 500, color: "#3c3c3c", fontSize: "13px", marginBottom: "10px", marginTop: "2%", display: "flex", flexDirection: "column", height: 'calc(100% - 100px)' }}>
                            <div>Objects to be detected : </div>
                            <div style={{ overflowY: "auto", maxHeight: "22vh", height: "100%", marginTop: "1%", display: "flex", flexDirection: "column", width: "100%" }}>
                                {
                                    selectedModels.map((model, m) => {
                                        return <div>
                                            <div className="itemKey" style={{ padding: "2px 0px", fontSize: '11px', margin: "2px 3px 0px 3px" }}>{model.display_name}</div>
                                            {model.objects.map((type, i) => {
                                                return <button className="aerocapture-detection-type-list" style={{ marginTop: "7px", fontSize: '11px', padding: '3px 10px' }}>{type}
                                                    {isTaskEditAccess() && model.objects.length > 1 ? <img onClick={() => { removeClassFromSelectedModel(m, i) }} style={{ height: '16px', width: '16px', cursor: 'pointer', marginLeft: '14px' }} src={closeButton} /> : <></>}
                                                </button>
                                            })
                                            }
                                            <div style={{ width: "98%", margin: "7px 5px 5px 5px", borderTop: "1px solid #A0A0A0" }} />
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>

                </div> :
                props.task.tasktype == 1 ?
                    <>
                        {/* Accuracy selection */}
                        <div id='inner-step1' style={{ width: '30%' }}>
                            <div className="itemValue" style={{ fontWeight: 500, color: "#3c3c3c", fontSize: "13px", marginBottom: "10px" }}>Select the accuracy level for training </div>
                            <div
                                className="add-user-form-row-left"
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <div
                                    className="add-user-form-text-wrapper"
                                    style={{ marginTop: "5px" }}
                                >
                                    <input
                                        checked={accuracy == "low"}
                                        disabled={!(isTaskEditAccess())}
                                        name="accuracy"
                                        className="add-user-form-text"
                                        type="radio"
                                        value="low"
                                        style={{
                                            width: "18px",

                                        }}
                                        onChange={onChangeAccuracy}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginLeft: "2%",
                                        fontSize: "12px",
                                    }}
                                >
                                    Low (Faster)
                                </div>
                            </div>

                            <div
                                className="add-user-form-row-left"
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <div
                                    className="add-user-form-text-wrapper"
                                    style={{ marginTop: "5px" }}
                                >
                                    <input
                                        checked={accuracy == "medium"}
                                        disabled={!(isTaskEditAccess())}
                                        name="accuracy"
                                        className="add-user-form-text"
                                        type="radio"
                                        value="medium"
                                        style={{ width: "18px" }}
                                        onChange={onChangeAccuracy}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginLeft: "2%",
                                        fontSize: "12px",
                                    }}
                                >
                                    Medium (Slower)
                                </div>
                            </div>

                            <div
                                className="add-user-form-row-left"
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <div
                                    className="add-user-form-text-wrapper"
                                    style={{ marginTop: "5px" }}
                                >
                                    <input
                                        checked={accuracy == "high"}
                                        name="accuracy"
                                        className="add-user-form-text"
                                        disabled={!(isTaskEditAccess())}
                                        type="radio"
                                        value="high"
                                        style={{ width: "18px" }}
                                        onChange={onChangeAccuracy}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginLeft: "2%",
                                        fontSize: "12px"
                                    }}
                                >
                                    High (Slowest)
                                </div>
                            </div>

                            {/* Type selection */}
                            {/* <div className="itemValue" style={{ fontWeight:600, fontSize: "12px", marginTop: "2%" }}>Select training purpose</div>
                            <div
                                className="add-user-form-row-left"
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <div
                                    className="add-user-form-text-wrapper"
                                    style={{ marginTop: "5px" }}
                                >
                                    <input
                                        checked={type == COUNTING}
                                        disabled={!(isTaskEditAccess())}
                                        name="type"
                                        className="add-user-form-text"
                                        type="radio"
                                        value={COUNTING}
                                        style={{
                                            width: "18px",
                                           
                                        }}
                                        onChange={onChangeType}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginLeft: "2%",
                                        fontSize: "12px",
                                    }}
                                >
                                    {COUNTING}
                                </div>
                            </div> */}
                            {/* <div
                                className="add-user-form-row-left"
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <div
                                    className="add-user-form-text-wrapper"
                                    style={{ marginTop: "5px" }}
                                >
                                    <input
                                        checked={type == SEGMENTATION}
                                        name="type"
                                        className="add-user-form-text"
                                        type="radio"
                                        value={SEGMENTATION}
                                        style={{ width: "18px" }}
                                        onChange={onChangeType}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginLeft: "2%",
                                        fontSize: "12px",
                                    }}
                                >
                                    {SEGMENTATION}
                                </div>
                            </div> */}
                            {/* <div
                                className="add-user-form-row-left"
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <div
                                    className="add-user-form-text-wrapper"
                                    style={{ marginTop: "5px" }}
                                >
                                    <input
                                        checked={type == DEFECTDETECTION}
                                        disabled={!(isTaskEditAccess())}
                                        name="type"
                                        className="add-user-form-text"
                                        type="radio"
                                        value={DEFECTDETECTION}
                                        style={{ width: "18px" }}
                                        onChange={onChangeType}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginLeft: "2%",
                                        fontSize: "12px",
                                    }}
                                >
                                    {DEFECTDETECTION}
                                </div>
                            </div> */}
                        </div>
                        {props.showTotalCount ?
                            <>
                                <div className="itemValue" style={{ fontWeight: 500, color: "#3c3c3c", fontSize: "13px", marginBottom: "10px", marginTop: '2%' }}>Total Annotations </div>
                                <div className="">
                                    {props.task.classes.length > 0 ?
                                        <div style={{ margin: "0px", display: "flex", height: '', overflow: 'auto' }}>
                                            <div
                                                className="rpas-model-info-primary-details-value1"
                                            >
                                                {props.task.classes.reduce((total, curr) => Number(total) + Number(curr.totalCount), 0)}
                                            </div>
                                        </div> :
                                        <div className="content-text" style={{ color: 'brown', padding: "0px 20px" }}>No Annotations added</div>}
                                </div>
                            </> : <></>}

                        {/* Classes */}
                        <div className="itemValue" style={{ fontWeight: 500, color: "#3c3c3c", fontSize: "13px", marginBottom: "10px", marginTop: '2%' }}>Class labels </div>
                        <div className="itemKey">

                            {props.task.classes.length > 0 ?
                                <div style={{ margin: "0px", display: "flex", height: '14vh', overflow: 'auto' }}>
                                    <div
                                        className="rpas-model-info-primary-details-value1"
                                    >
                                        {props.task.classes.map((cls, i) => {
                                            return (
                                                <span>
                                                    {`${cls.name}`}
                                                    {props.showTotalCount && <span style={{ fontWeight: "500", margin: "0px 5px", fontSize: "12px" }}>{`(${cls.totalCount})`}</span>}
                                                    <span style={{ margin: "0px 5px", fontWeight: 500 }}>
                                                        {`${i == props.task.classes.length - 1 ? "" : ", "}`}
                                                    </span>
                                                </span>
                                            );
                                        })}
                                    </div>
                                </div> :
                                <div className="content-text" style={{ color: 'brown', padding: "0px 20px" }}>No class label added</div>}



                        </div>


                        <div>

                        </div>
                    </> :
                    props.task.tasktype == 4 ?
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: '1%' }}>
                            <div id='inner-step1' style={{ width: '77%', }}>
                                {/* Classes */}
                                <div className="itemValue" style={{ fontWeight: 500, color: "#3c3c3c", fontSize: "13px", marginBottom: "10px" }}>Class labels </div>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div style={{ display: "flex", alignItems: "center", width: "100%", gap: "" }}>
                                        <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
                                            <form onSubmit={addClass} style={{ width: '90%' }}>
                                                <input
                                                    maxLength={30}
                                                    autoFocus={true}
                                                    disabled={!isTaskEditAccess()}
                                                    className="sub-add-user-form-text"
                                                    type="text"
                                                    name="className"
                                                    placeholder="Enter class label"
                                                    onChange={onObjectChange}
                                                    value={className}
                                                />
                                            </form>
                                            <img src={btnAdd} style={{ height: '25px', marginLeft: '12px', opacity: isTaskEditAccess() ? '1' : "0.5", cursor: isTaskEditAccess() ? 'pointer' : "default" }}
                                                onClick={addClass}
                                            />
                                            <div style={{ margin: "0 0 0 5px", width: "160px" }} >
                                                <ButtonWithIcon text="Import classes"
                                                    isDisabled={!isTaskEditAccess()}
                                                    onClick={() => setImportClassses(true)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ margin: '5px 0px 0px 5px', fontSize: "11px", color: "brown" }}>{classError}</div>

                                <div className="itemKey" style={{ marginTop: '10px', width: "90%" }}>
                                    {classes && classes.length > 0 ?
                                        <div style={{ margin: "0px", display: "flex", height: '60vh', overflow: 'auto' }}>
                                            <div className="rpas-model-info-primary-details-value1">
                                                {classes.map((type, i) => {
                                                    return (
                                                        <>
                                                            {type && type.length > 0 ? <button
                                                                className="aerocapture-detection-type-list"
                                                                style={{ fontSize: "12px", margin: "2px 4px" }}
                                                            >
                                                                {type}
                                                                {isTaskEditAccess() ?
                                                                    <img onClick={() => {
                                                                        let data = classes.filter((cl => cl != type))
                                                                        setClasses(data)
                                                                        props.onChangeDetails({ type: data })
                                                                    }} style={{ height: '16px', cursor: 'pointer', marginLeft: '14px' }} src={closeButton} />
                                                                    : <></>}
                                                            </button> : <></>}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div> :
                                        <>
                                            {/* <div className="content-text flex-center" style={{ color: 'brown', justifyContent: 'left' }}>Add at least one class</div> */}
                                        </>
                                    }
                                </div>
                            </div>
                            {importClasses ?
                                <ImportClassesFromTask
                                    projectId={props.project.id}
                                    planId={props.plan.id}
                                    service="PicStork"
                                    dropDownHeight="126px"
                                    dropDownWidth="100%"
                                    open={importClasses}
                                    onClose={() => { setImportClassses(false) }}
                                    importedClasses={(importedClasses) => {
                                        setImportClassses(false)
                                        setClasses(Array.from(new Set([...classes, ...importedClasses])))
                                        props.onChangeDetails({ type: Array.from(new Set([...classes, ...importedClasses])) })
                                    }}
                                /> : <></>}
                        </div>
                        :
                        <></>
            }
        </div >
    </>
}

export default TaskProcessView; 