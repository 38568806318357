import React, { Component } from "react";
import * as API from "../../api.js";
import edit from '../../icons/pen.png'
import saveImage from '../../icons/saveImage.png'
import close from '../../assets/Icons/Close.png'
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs.js';
import Loader from '../../icons/loader.svg';
import { checkURLExist, addUserAnalytic, updateOutTime } from "../../ReusableComponents/reusableFunctions.js";
import { DRONENAKSHA_FEATURES, getFeaturesPermissions } from "../../Teams/index.js";
import AppConstants, { BLOB_URL, tilesServer } from "../../AppConstants.js";


const NotSelected = "Not Selected";

const colors = [
    "#6898f7",
    "#7de315",
    "#76ca60",
    "#b4d05b",
    "#6fb1ac",
    "#cde327",
    "#4f3be3",
    "#7bdd28",
    "#6a9de7",
    "#7cde25",
    "#6ba3d7",
    "#7ad73b",
    "#6dabbf",
    "#d8d1e3",
    "#a0b5ed",
];

class aeroFarmReport extends Component {
    state = {
        isOwner: false,
        sharedBy: null,
        user: {},
        type: "",
        orthoUrl: '',
        demUrl: '',
        dtmUrl: '',
        pathDetailUrl: this.props.location.state.orthoImageData.image_url.replace("orthomosaic.", "topview."),
        reportData: [],
        showProcessPopup: false,
        loading: true,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        deleteReport_id: '',
        reportheader: {},
        reportTitle: '',
        reportSubTitle: '',
        company_logo: '',
        company_name: '',
        editHeader: false,
        company_logo1: undefined,
        titleEdit: false,
        subTitleEdit: false,
        showDTM: true,
        gcpError: [],
        gcpRMSErrors: [],
        reportCheck: [],
        outId: "",
    };
    isUserOwnerOfCollection = () => {
        const { collection_id } = this.state.orthoImageData
        const { id } = this.state.project
        API.isUserProjectOwner(id, collection_id)
            .then((result) => {
                this.setState({
                    isOwner: result.isShared ? false : true,
                    isAllowToDownload: result.access_level == "DOWNLOAD" ? true : false,
                    sharedBy: result.sharedBy
                })
                console.log(result)
            })
            .catch((err) => {
                window.alert("Something Went Wrong")
            })
    }
    handleChange = (e) => {
        let t = e.target;
        if (t.value === ' ') {
            console.log('space not allow');
        } else {
            this.setState((prevState) => ({
                ...prevState,
                [t.name]: t.value,
            }))
        }

    }
    checkedTrue(index) {
        let temp = this.state.reportData
        temp[index].visibleInReport = !temp[index].visibleInReport
        this.setState((state) => ({
            ...state,
            reportData: temp
        }))
    }

    areaInKm = () => {
        let area = this.props.location.state.orthoImageData.orthomosaicDetails ? this.props.location.state.orthoImageData.orthomosaicDetails.area : 0
        area = Number(area.split(' ')[0])
        return (`${(area * 1 / 1000000).toFixed(4)} sq.km`)
    }

    addDNReport = () => {
        let importedFiles = [];
        let { orthoImageData } = this.props.location.state
        let report_type = 'MAIN'

        if (this.props.location.state.OrthomosaicAvailable && this.props.location.state.OrthomosaicAvailable != NotSelected) importedFiles.push("ORTHO")
        if (this.props.location.state.DSMAvailable && this.props.location.state.DSMAvailable != NotSelected) importedFiles.push("DSM")
        if (this.props.location.state.DTMAvailable && this.props.location.state.DTMAvailable != NotSelected) importedFiles.push("DTM")

        this.setState((state) => ({
            ...state,
            reportLoader: true
        }))

        API.getAllDNReport(this.props.location.state.orthoImageData.task_id, "MAIN").then((reportData) => {
            if (importedFiles.length > 0) importedFiles.map(el => {
                if (reportData.reportData.some(data => data.algorithm_type === el && data.task_id === orthoImageData.task_id)) {
                    console.log("CONDTION ", importedFiles.includes(el.algorithm_type), el.algorithm_type)
                    console.log("already present")
                    this.getAllDNReport()
                } else {
                    console.log("INSIDE ELSE PART ")
                    API.addDNReport(orthoImageData.task_id, el, null, null, null, report_type).then((data) => {
                        console.log("Data inserted into database !");
                    })
                    this.getAllDNReport()
                }
            })
            else {
                importedFiles.forEach(impF => {
                    API.addDNReport(orthoImageData.task_id, impF, null, null, null, report_type).then((data) => {
                        console.log("Data inserted into database !");
                    })
                })
                this.getAllDNReport()
            }
        });
    }

    getCsvGCP = async () => {
        API.getCSV_GCP(this.state.orthoImageData.collection_id).then((data) => {
            this.setState((state) => ({
                ...state,
                csvGCP: data.gcp_points,
            }))
        })
    }

    GetGCPError = () => {
        API.GetGCPError(this.state.orthoImageData.task_id).then((gcpdata) => {
            console.log(gcpdata);
            this.setState((state) => ({
                ...state,
                gcpError: gcpdata.gcp_errors,
                gcpRMSErrors: gcpdata.gcp_rms_errors
            }))
        }, (e) => {
            console.log(e);
        })
    }

    getAllDNReport = () => {
        let { orthoImageData } = this.state
        let task_id = orthoImageData.task_id


        API.getAllDNReport(task_id, "MAIN").then((reportData) => {
            console.log("ReportData Response : ", reportData.reportData);
            let saveToReports = []
            let data = reportData.reportData.filter(async (report, i) => {
                report.visibleInReport = true
                let type = report.algorithm_type
                saveToReports.push(type == "DSM" ? await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${task_id}/demThumb.png?${this.state.permissions?.st}`) ? true : false
                    : type == "DTM" ? await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${task_id}/dtmThumb.png?${this.state.permissions?.st}`) ? true : false
                        : type == "ORTHO" ? await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${task_id}/orthomosaicThumb.png?${this.state.permissions?.st}`) ? true : false : false)

                return saveToReports
            })
            this.setState(async (state) => ({
                ...state,
                reportData: data,
                loading: false,
                orthoUrl: await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${task_id}/orthomosaicThumb.png?${this.state.permissions?.st}`) ?
                    `${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${task_id}/orthomosaicThumb.png?${this.state.permissions?.st}`
                    : `${tilesServer}/getPng/${this.state.orthoImageData.task_id}/orthomosaic.png?sas=${encodeURIComponent(this.state.permissions?.st)}&blobContainer=${this.state.permissions?.container}`,
                demUrl: `${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${task_id}/demThumb.png?${this.state.permissions?.st}`,
                dtmUrl: `${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${task_id}/dtmThumb.png?${this.state.permissions?.st}`,
            }))
        }, () => {
            this.addDNReport()
        }).catch(err => {
            console.log("error : ", err);
        })
    }

    changeCompanyLogo = (event) => {
        // let companyLogo = event.target.value
        let targetFilesArr = [];
        targetFilesArr = Array.from(event.target.files);
        let companyLogo = targetFilesArr[0]
        this.setState((state) => ({
            ...state,
            company_logo1: companyLogo,
        }), () => {
            this.uploadCompanLogo()
        })
    }
    uploadCompanLogo = () => {
        const data = new FormData()
        data.append("file", this.state.company_logo1)
        API.uploadCompanyLogo(this.state.reportheader.id, data).then((uploadlogo) => {
            console.log(uploadlogo.status)
            if (uploadlogo.status == 200) {
                this.setState((state) => ({
                    ...state,
                    companyLogo1: undefined,
                }), () => {
                    this.getReportHeader()
                })
            } else {
                console.log('error while uploading logo')
            }
        })
    }

    updateReportHeader = () => {
        API.updateReportHeader(this.state.reportTitle, this.state.reportSubTitle, this.state.company_name, this.state.reportheader.id).then((data) => {
            console.log(data);
            if (data.status == 200) {
                this.setState((state) => ({
                    ...state,
                    editHeader: false
                }))
                this.getReportHeader();
            } else {
                this.getReportHeader();
            }
        })
    }

    getReportHeader = () => {
        let task_id = this.state.orthoImageData.task_id
        let report_type = 'main'
        API.getReportHeader(task_id, report_type).then((data) => {
            if (data.data == null) {
                this.addReportHeader();
            } else {
                this.setState((state) => ({
                    ...state,
                    reportheader: data.data,
                    reportTitle: data.data.title,
                    reportSubTitle: data.data.subtitle,
                    company_logo: data.data.company_logo ? `${data.data.company_logo}?${this.state.permissions?.st}` : this.state.avatar_url ? `${this.state.avatar_url}?${this.state.permissions?.st}&t${new Date().getTime()}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`,
                    company_name: data.data.name,
                }))
            }
        }, (e) => {
            console.log(e);
        })
    }

    addReportHeader = () => {
        let task_id = this.state.orthoImageData.task_id
        let report_type = 'main'
        let title = this.state.orthoImageData.task_name
        let subtitle = this.state.plan.plan_name
        let company_logo = null
        console.log("Report type : " + report_type);
        // this.state.avatar_url ? `${this.state.avatar_url}?${this.state.permissions?.st}&t${new Date().getTime()}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`
        API.addReportHeader(task_id, report_type, title, company_logo, subtitle).then((data) => {
            console.log("inside get report data", data)
            if (data.status == 201) {
                this.getReportHeader();
            } else {
                this.setState((state) => ({
                    ...state,
                    reportTitle: this.state.orthoImageData.task_name,
                    reportSubTitle: this.state.plan.plan_name,
                    company_logo: this.state.avatar_url ? `${this.state.avatar_url}?${this.state.permissions?.st}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`,
                    company_name: `${this.state.user.first_name} ${this.state.user.last_name}`,
                }))
            }
        })
    }

    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }

    componentWillMount() {
        document.title = "DroneNaksha Report";
        getFeaturesPermissions([DRONENAKSHA_FEATURES.REPORTS]).then(permissions => {
            this.setState({
                permissions,
                avatar_url: `${BLOB_URL}/${permissions?.container}/profileImage/${(permissions?.container?.split("user-"))[1]}.jpeg`
            }, () => {
                addUserAnalytic(this.props.location.pathname)
                    .then(id => {
                        console.log("ID : ", id)
                        this.setState((state) => ({
                            outId: id
                        }))
                    })

                if (this.props.location.state) {
                    let { user, type, orthoImageData, task, plan, project } = this.props.location.state;
                    this.setState((state) => ({
                        ...state,
                        user,
                        type,
                        task,
                        orthoImageData,
                        project,
                        plan
                    }), () => {
                        this.isUserOwnerOfCollection()
                        this.addDNReport()
                        this.getReportHeader()
                        this.getAllDNReport()
                        this.GetGCPError()
                        this.getCsvGCP()
                    })
                } else {
                    API.getUserType().then(
                        ([blah, type, user]) => {
                            this.setState(
                                (state) => ({
                                    ...state,
                                    user,
                                    type,
                                })
                            );
                        },
                        (e) => {
                            window.location.replace("/login");
                        }
                    );
                }
            })
        })
            .catch(e => window.location.replace())
    }
    render() {
        let { user, type, task, orthoUrl, demUrl, dtmUrl } = this.state;
        const handleClick = (event) => {
            document.getElementById("hiddenFileInput").click();
        }; return (
            <>
                <div className="top-bar" style={{ position: 'fixed', width: '100%' }}>
                    <div className="breadCrumbs">
                        <BreadCrumbs data={[
                            {
                                displayName: "projects",
                                pathname: "/dronenaksha/projects",
                                state: {
                                    type,
                                    user,
                                },
                                style: {
                                    color: "black",
                                    fontSize: "12px"
                                }
                            },
                            {
                                displayName: this.props.location.state.project.project_name,

                                pathname: "/dronenaksha/project",
                                state: {
                                    type,
                                    user,
                                    project: this.props.location.state.project,
                                },
                                style: {
                                    color: "black",
                                    fontSize: "12px"
                                }

                            },
                            {
                                displayName: this.props.location.state.plan.plan_name,

                                pathname: "/dronenaksha/plan",
                                state: {
                                    type,
                                    user,
                                    plan: this.props.location.state.plan,
                                    project: this.props.location.state.project
                                },
                                style: {
                                    color: "black",
                                    fontSize: "12px"
                                }
                            },
                            {
                                displayName: this.props.location.state.orthoImageData.task_name
                            }
                        ]}
                        />
                        {this.state.sharedBy == null || !(this.state.sharedBy) ? <></> : <span className='dn-shared-by'>- Shared by {this.state.sharedBy.first_name} {this.state.sharedBy.last_name}</span>}
                    </div>
                </div>

                {this.state.loading ? <div style={{ height: "100vh", width: "100vw", display: "flex", textAlign: "center" }}>
                    <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                </div> : <div style={{ display: 'flex', margin: '60px 15px', justifyContent: 'center' }}>
                    <div style={{ width: '77%' }}>
                        <div
                            id="pageprint1"
                            style={{
                                padding: "20px 30px 20px 30px",
                                width: "945px",
                                margin: 'auto',
                                boxShadow: "0px 6px 18px rgba(0, 0, 0, 18%)",
                            }}
                        >
                            <div className='page_Header'>
                                <input
                                    name="company_logo"
                                    id="hiddenFileInput"
                                    type="file"
                                    className=""
                                    required
                                    onChange={this.changeCompanyLogo}
                                    accept='image/*'
                                    // multiple
                                    style={{ display: "none" }}
                                />
                                <div style={{ width: '885px', display: 'flex', justifyContent: 'space-between', marginTop: '20px', }}>
                                    <div style={{}}>
                                        {this.state.titleEdit ? <div style={{ height: '47px', }}>
                                            {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Title</label><br /> */}
                                            <input maxLength={AppConstants.IMAGESET_NAME_LIMIT} type="text" value={this.state.reportTitle} name='reportTitle'
                                                className="edit-report-header-text" style={{}} onChange={this.handleChange} />
                                            <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                                                this.setState(
                                                    (state) => ({
                                                        ...state,
                                                        titleEdit: false,
                                                        editHeader: false,
                                                    }), () => {
                                                        this.updateReportHeader()
                                                    })
                                            }} />
                                            <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                                this.setState(
                                                    (state) => ({
                                                        ...state,
                                                        titleEdit: false,
                                                        editHeader: false,
                                                    }), () => {
                                                        this.getReportHeader()
                                                    })
                                            }} />
                                        </div> : <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>
                                            <div style={{ fontSize: '30px', fontWight: '500', maxWidth: "610px", overflow: "hidden", wordWrap: "break-word", lineHeight: 0.8 }}>{this.state.reportTitle}</div>
                                            <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", display: this.state.isOwner ? undefined : "none", cursor: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? 'pointer' : "default", opacity: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? "1" : "0.2" }} src={edit} onClick={() => {
                                                this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT && this.setState(
                                                    (state) => ({
                                                        ...state,
                                                        titleEdit: true,
                                                        editHeader: true
                                                    }))
                                            }} />
                                        </div>
                                        }


                                        {this.state.subTitleEdit ? <div style={{ display: 'flex', alignItems: 'center', height: '47px', marginTop: '10px' }}>
                                            {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Sub Title</label><br /> */}
                                            <input maxLength={'20'} type="text" value={this.state.reportSubTitle} name='reportSubTitle'
                                                className="edit-report-header-text" style={{}} onChange={this.handleChange} />
                                            <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                                                this.setState(
                                                    (state) => ({
                                                        ...state,
                                                        subTitleEdit: false,
                                                        editHeader: false,
                                                    }), () => {
                                                        this.updateReportHeader()
                                                    })
                                            }} />
                                            <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                                this.setState(
                                                    (state) => ({
                                                        ...state,
                                                        subTitleEdit: false,
                                                        editHeader: false,
                                                    }), () => {
                                                        this.getReportHeader()
                                                    })
                                            }} />
                                        </div> : <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>
                                            <div style={{ fontSize: '20px', fontWight: '500', maxWidth: "580px", overflow: "hidden", wordWrap: "break-word", lineHeight: 1 }}>{this.state.reportSubTitle}</div>
                                            <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", display: this.state.isOwner ? undefined : "none", cursor: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? 'pointer' : "default", opacity: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? "1" : "0.2" }} src={edit} onClick={() => {
                                                this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT && this.setState(
                                                    (state) => ({
                                                        ...state,
                                                        subTitleEdit: true,
                                                        editHeader: true,
                                                    }))
                                            }} />
                                        </div>}
                                    </div>

                                    <div style={{}}>
                                        <div style={{ textAlign: 'end', }}>
                                            <img id="reportToolImage" className="reportToolImage" style={{ position: 'absolute', height: "15px", width: "15px", display: this.state.isOwner ? undefined : "none", cursor: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? 'pointer' : "default", opacity: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? "1" : "0.2" }} src={edit} onClick={this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT && handleClick} />
                                            <img style={{ maxHeight: "80px", minHeight: "50px", minWidth: "70px", maxWidth: "100px", borderRadius: '10px', cursor: 'pointer' }} src={this.state.company_logo} />
                                        </div>

                                        {this.state.companyNameEdit ? <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>
                                            {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Company Name</label><br /> */}


                                            <input maxLength={'20'} style={{ width: '200px' }} type="text" value={this.state.company_name} name='company_name'
                                                className="edit-report-header-text" onChange={this.handleChange} />
                                            <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                                                this.setState(
                                                    (state) => ({
                                                        ...state,
                                                        companyNameEdit: false,
                                                        editHeader: false
                                                    }), () => {
                                                        this.updateReportHeader()
                                                    })
                                            }} />
                                            <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                                this.setState(
                                                    (state) => ({
                                                        ...state,
                                                        companyNameEdit: false,
                                                        editHeader: false,
                                                    }), () => {
                                                        this.getReportHeader()
                                                    })
                                            }} />
                                        </div> :
                                            <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>

                                                <div style={{ fontSize: '20px' }}>{this.state.company_name}</div>
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", display: this.state.isOwner ? undefined : "none", cursor: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? 'pointer' : "default", opacity: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? "1" : "0.2" }} src={edit} onClick={() => {
                                                    this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT && this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            companyNameEdit: true,
                                                            editHeader: true,
                                                        }))
                                                }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <hr style={{ width: '100%', margin: '10px 0px 20px 0px', height: '1px', padding: '0px' }} />
                            </div>
                            {/* <div style={{ textAlign: "center", marginTop: "10px" }}>
                                <h2>{this.props.location.state.plan.plan_name}</h2>
                                <h5>{this.props.location.state.project.project_name}</h5>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <span className="itemKey">Processing Date: </span>
                                        <span className="itemValue">{this.props.location.state.orthoImageData.orthomosaicDetails ? (this.props.location.state.orthoImageData.orthomosaicDetails.processing_date ? this.props.location.state.orthoImageData.orthomosaicDetails.processing_date : "NA") : "NA"}</span>
                                    </div>
                                    <div>
                                        <span className="itemKey">Processing Time: </span>
                                        <span className="itemValue">{this.props.location.state.orthoImageData.orthomosaicDetails ? (this.props.location.state.orthoImageData.orthomosaicDetails.processing_time ? this.props.location.state.orthoImageData.orthomosaicDetails.processing_time : "NA") : "NA"}</span>
                                    </div>
                                </div>
                            </div>
                            <hr style={{ marginTop: '50px', marginBottom: '70px' }}></hr> */}
                            {/* <div className="header" style={{ fontWeight: "bold", margin: '5px 0px 10px 0px' }}>Map Detailes Table</div> */}
                            <div className="reportTable1" style={{ display: 'flex', justifyContent: "center", padding: '20px 0px' }}>
                                <table style={{ borderRadius: '4px', width: '80%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                                    <col style={{ width: "50%" }} />
                                    <col style={{ width: "50%" }} />

                                    <tr style={{ border: '#EBEFF2', padding: '5px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                        <th colspan="2" style={{ paddingLeft: '20px' }}>Map Details</th>
                                    </tr>

                                    <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                        <td style={{ paddingLeft: '20px', backgroundColor: '#ebebeb' }}>Area</td>
                                        <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>{this.props.location.state.orthoImageData.orthomosaicDetails ? (this.props.location.state.orthoImageData.orthomosaicDetails.area ? this.areaInKm() : "NA") : "NA"}</td>
                                    </tr>
                                    {/* <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                        <td style={{ paddingLeft: '20px', backgroundColor: '#ebebeb' }}>Images used</td>
                                        <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>{this.props.location.state.orthoImageData.orthomosaicDetails ? (this.props.location.state.orthoImageData.orthomosaicDetails.images_used ? this.props.location.state.orthoImageData.orthomosaicDetails.images_used : "NA") : "NA"}</td>
                                    </tr> */}
                                    <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                        <td style={{ paddingLeft: '20px', backgroundColor: '#ebebeb' }}>GSD</td>
                                        <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>{this.props.location.state.orthoImageData.orthomosaicDetails ? (this.props.location.state.orthoImageData.orthomosaicDetails.gsd ? this.props.location.state.orthoImageData.orthomosaicDetails.gsd : "NA") : "NA"}</td>
                                    </tr>

                                    <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                        <td style={{ paddingLeft: '20px', backgroundColor: '#ebebeb' }}>Processing time</td>
                                        <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>{this.props.location.state.orthoImageData.orthomosaicDetails ? (this.props.location.state.orthoImageData.orthomosaicDetails.processing_time ? this.props.location.state.orthoImageData.orthomosaicDetails.processing_time : "NA") : "NA"}</td>
                                    </tr>
                                    <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                        <td style={{ paddingLeft: '20px', backgroundColor: '#ebebeb' }}>Processing date</td>
                                        <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>{this.props.location.state.orthoImageData.orthomosaicDetails ? (this.props.location.state.orthoImageData.orthomosaicDetails.processing_date ? this.props.location.state.orthoImageData.orthomosaicDetails.processing_date : "NA") : "NA"}</td>
                                    </tr>
                                </table>
                            </div>
                            {this.state.gcpRMSErrors.length > 0 ? <div className="" style={{ display: 'flex', justifyContent: "center", padding: '20px 0px' }}>
                                <table style={{ borderRadius: '4px', width: '80%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                                    <col style={{ width: "25%" }} />
                                    <col style={{ width: "25%" }} />
                                    <col style={{ width: "25%" }} />
                                    <col style={{ width: "25%" }} />

                                    <tr style={{ border: '#EBEFF2', padding: '5px', backgroundColor: '#ebebeb', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '50px ', fontSize: '14px', }}>
                                        <th colSpan='4' style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'center', fontSize: '16px', fontWeight: '500' }}>Ground Control Point RMS Error</th>
                                    </tr>
                                    <tr style={{ border: '#EBEFF2', padding: '5px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                        <th style={{ paddingRight: '20px', paddingLeft: '20px', }}>GCP</th>
                                        <td style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'center' }}>{this.state.gcpRMSErrors[0].name} (meters)</td>
                                        <td style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'center' }}>{this.state.gcpRMSErrors[1].name} (meters)</td>
                                        <td style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'center' }}>{this.state.gcpRMSErrors[2].name} (meters)</td>
                                    </tr>

                                    <tr style={{ border: '#EBEFF2', padding: '5px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                        <th style={{ paddingRight: '20px', paddingLeft: '20px', }}>RMS Error</th>
                                        <td style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'center' }}>{this.state.gcpRMSErrors[0].rms}</td>
                                        <td style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'center' }}>{this.state.gcpRMSErrors[1].rms}</td>
                                        <td style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'center' }}>{this.state.gcpRMSErrors[2].rms}</td>
                                    </tr>
                                </table>
                            </div> : <></>}

                            {this.state.gcpError.length > 0 ? <div className="" style={{ display: 'flex', justifyContent: "center", padding: '20px 0px' }}>
                                <table style={{ borderRadius: '4px', width: '80%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                                    <col style={{ width: "25%" }} />
                                    <col style={{ width: "25%" }} />
                                    <col style={{ width: "25%" }} />
                                    <col style={{ width: "25%" }} />

                                    <tr style={{ border: '#EBEFF2', padding: '5px', backgroundColor: '#ebebeb', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '50px ', fontSize: '14px', }}>
                                        <th colSpan='4' style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'center', fontSize: '16px', fontWeight: '500' }}>Ground Control Point Error</th>
                                    </tr>
                                    <tr style={{ border: '#EBEFF2', padding: '5px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                        <th style={{ paddingRight: '20px', paddingLeft: '20px' }}>ID</th>
                                        <th style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'center' }}>X Error (meters)</th>
                                        <th style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'center' }}>Y Error (meters)</th>
                                        <th style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'center' }}>Z Error (meters)</th>
                                    </tr>
                                    {this.state.gcpError.map((gcpError, key) => {
                                        return <>
                                            <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                                <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>{gcpError.gcp_name}</td>
                                                <td style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'center' }}>{gcpError.x_error}</td>
                                                <td style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'center' }}>{gcpError.y_error}</td>
                                                <td style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'center' }}>{gcpError.z_error}</td>
                                            </tr>
                                        </>
                                    })}
                                </table>
                            </div> : <></>}

                            {this.state.csvGCP && this.state.csvGCP.length > 0 ? <div className="" style={{ display: 'flex', justifyContent: "center", padding: '20px 0px' }}>

                                <table style={{ borderRadius: '4px', width: '80%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                                    <col style={{ width: '25%' }} />
                                    <col style={{ width: '30%' }} />
                                    <col style={{ width: '30%' }} />
                                    <col style={{ width: '15%' }} />

                                    <tr style={{ border: '#EBEFF2', padding: '5px', backgroundColor: '#ebebeb', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '50px ', fontSize: '14px', }}>
                                        <th colSpan='4' style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'center', fontSize: '16px', fontWeight: '500' }}>Ground Control Point List</th>
                                    </tr>

                                    <tr style={{ border: '#EBEFF2', padding: '5px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                        <th style={{ paddingRight: '20px', paddingLeft: '20px' }}>GCP name</th>
                                        <th style={{ paddingRight: '20px', paddingLeft: '20px' }}><div>Latitude</div>{this.state.csvGCP.length > 0 && this.state.csvGCP[0].latitude != this.state.csvGCP[0].original_lat ? <div style={{ fontSize: "10px", color: "#666666" }}>(Northing)</div> : <></>}</th>
                                        <th style={{ paddingRight: '20px', paddingLeft: '20px' }}><div>Longitude</div>{this.state.csvGCP.length > 0 && this.state.csvGCP[0].latitude != this.state.csvGCP[0].original_lat ? <div style={{ fontSize: "10px", color: "#666666" }}>(Easting)</div> : <></>}</th>
                                        <th style={{ paddingRight: '20px', paddingLeft: '20px' }}>Altitude</th>
                                    </tr>

                                    {
                                        this.state.csvGCP.map((gcp) => {
                                            return (
                                                <tr style={{ border: '#EBEFF2', padding: '5px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                                    <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>
                                                        {gcp.gcp_name}
                                                    </td>
                                                    <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>
                                                        {
                                                            gcp.latitude === gcp.original_lat || !gcp.original_lat ?
                                                                <div>
                                                                    {gcp.latitude.substring(0, 15)}
                                                                </div> :
                                                                <div>
                                                                    <div>{gcp.latitude.substring(0, 15)}</div>
                                                                    <div style={{ fontSize: "10px", color: "#666666" }}>({gcp.original_lat})</div>
                                                                </div>
                                                        }
                                                    </td>
                                                    <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>
                                                        {
                                                            gcp.longitude === gcp.original_lon || !gcp.original_lon ?
                                                                <div>
                                                                    {gcp.longitude.substring(0, 15)}
                                                                </div> :
                                                                <div>
                                                                    <div>{gcp.longitude.substring(0, 15)}</div>
                                                                    <div style={{ fontSize: "10px", color: "#666666" }}>({gcp.original_lon})</div>
                                                                </div>
                                                        }
                                                    </td>
                                                    <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>
                                                        {gcp.altitude}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </table>
                            </div>
                                : <></>
                            }

                            {this.state.reportData.map((report, key) => {
                                if (report.visibleInReport == true) {
                                    return <>
                                        <div className="imagesPage" style={{ width: '100%', padding: '50px 0px', pageBreakBefore: "always", pageBreakInside: 'avoid' }}>
                                            <label className="header" style={{ fontWeight: "bold", margin: '10px 0px 10px 0px' }}><h5>{report.algorithm_type} : {report.algorithmDes ? report.algorithmDes.fullForm : ''}</h5></label>
                                            <p style={{ marginBottom: '10px', fontSize: "14px" }}>{report.algorithmDes ? report.algorithmDes.description : ''}</p>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <div className="reportImage" style={{ width: '70%', border: 'solid 1px #EBEFF2', padding: '50px 15px', textAlign: 'center' }}>
                                                    <img style={{ maxWidth: '100%', maxHeight: '800px', margin: 'auto' }}
                                                        src={report.algorithm_type == "ORTHO" ? orthoUrl : report.algorithm_type == "DSM" ? demUrl : report.algorithm_type == "DTM" ? dtmUrl : ''} onError={(e) => { e.target.onerror = null; e.target.alt = 'Image Not Available' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            })
                            }
                        </div>
                    </div>
                    <div style={{ width: '23%', }}>
                        {this.state.editHeader || !this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].DOWNLOAD ? <button style={{ position: 'fixed', opacity: '0.5', top: '80px', right: '15px', cursor: "default" }} className="add-user-form-submit-button">
                            Download
                        </button> : <button style={{ position: 'fixed', top: '80px', right: '15px' }} className="add-user-form-submit-button" onClick={window.print}>
                            Download
                        </button>}
                        <div style={{ height: '78vh', overflow: 'auto', position: 'fixed', top: '135px', width: '23%' }}>
                            {this.state.reportData.length == 0 ? <div style={{ width: '100%', textAlign: 'center' }}>No records found</div> :
                                <table style={{ borderRadius: '4px', width: '100%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                                    <col style={{ width: "5%" }} />
                                    <col style={{ width: "80%" }} />
                                    {/* <col style={{ width: "15%" }} /> */}

                                    {/* <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                        <th style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}></th>
                                        <th style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>Algorithm</th>
                                        <th style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>Action</th>
                                    </tr> */}
                                    {this.state.reportData.map((report, key) => {
                                        return <>
                                            <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                                <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>
                                                    <input type='checkbox' style={{ height: '15px', width: '15px', cursor: 'pointer' }}
                                                        checked={report.visibleInReport}
                                                        onClick={() => {
                                                            this.checkedTrue(key)
                                                            // this.state.reportData[key].visibleInReport = false
                                                        }}
                                                    />
                                                </td>
                                                <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>{report.algorithm_type}</td>
                                                {/* {this.state.isOwner ? <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px', textAlign: 'center' }}>
                                                    <img src={DeleteIcon} style={{ width: '20px', height: '20px', cursor: this.props.location.state.permissions[DRONENAKSHA_FEATURES.REPORTS].DELETE ? 'pointer' : "default", opacity: this.props.location.state.permissions[DRONENAKSHA_FEATURES.REPORTS].DELETE ? "1" : "0.2" }}
                                                        onClick={() => {
                                                            // this.props.location.state.permissions[DRONENAKSHA_FEATURES.REPORTS].DELETE && this.setState((state) => ({
                                                            //     ...state,
                                                            //     deleteReport_id: report.report_id,
                                                            //     itemName: "Customise report item",
                                                            //     processPopupIcon: "WARNING",
                                                            //     processAction: "DELETE",
                                                            //     processMessage: null,
                                                            //     showProcessPopup: true,
                                                            // }))
                                                        }}
                                                    />
                                                </td> : <></>} */}
                                            </tr>
                                        </>
                                    })}

                                </table>}
                        </div>
                    </div>
                </div>}
            </>
        );
    }
}

export default aeroFarmReport;
