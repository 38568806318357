import React, { useState, useRef, useEffect, Component } from 'react'

export default function ImageWithAnnotation(props) {
  const [imgSrc, setImgSrc] = useState(
    props.imgSrc,
  )
  const [idx, setIdx] = useState(props.id)
  const ptage = 0.5
  function onImageLoad() {
    let { imgSrc, regions, imgData } = props
    const dynamicCanvas = document.createElement("canvas");
    const dynamicContext = dynamicCanvas.getContext("2d");
    var image = new Image();
    image.src = imgSrc
    image.crossOrigin = "anonymous"
    image.height = imgData.height
    image.width = imgData.width
    //Draw image on the canvas
    const imageCanvas = document.getElementById(idx);
    const imageContext = imageCanvas.getContext("2d");

    image.onload = function () {
      dynamicCanvas.height = this.height;
      dynamicCanvas.width = this.width;

      dynamicContext.drawImage(image, 0, 0);
      //Add annotation on the canvas
      console.log(imgData.file_name);

      for (let i = 0; i < regions.length; i++) {
        let annotationRegion = regions[i].annotationRegion
        let lineWidth = 4
        if (annotationRegion.w > annotationRegion.h) {
          let lw = parseInt(annotationRegion.w * ptage / 80) < 8 ? 8 : parseInt(annotationRegion.w * ptage / 80)
          lineWidth = lw % 2 == 0 ? lw : lw + 1
        } else {
          let lw = parseInt(annotationRegion.h * ptage / 80) < 8 ? 8 : parseInt(annotationRegion.h * ptage / 80);
          lineWidth = lw % 2 == 0 ? lw : lw + 1
        }
        console.log("width : ", lineWidth);
        dynamicContext.beginPath();
        dynamicContext.rect(annotationRegion.x, annotationRegion.y, annotationRegion.w, annotationRegion.h);
        dynamicContext.lineWidth = lineWidth
        dynamicContext.strokeStyle = 'red';
        dynamicContext.stroke();
      }

      imageContext.drawImage(dynamicCanvas, 0, 0, this.width, this.height);
      props.onImageLoaded()
      // Crop image with annotation with required offset
      //context.drawImage(imageCanvas, outerRegion.x, outerRegion.y, outerRegion.w, outerRegion.h, 0, 0, 350, 350);
    }
  }
  useEffect(() => {
    onImageLoad()
  }, [])

  return (
    <div style={{ height: "100%", width: "100%", objectFit: 'contain' }}>
      <canvas id={idx} height={props.imgData.height} width={props.imgData.width}  ></canvas>
    </div>
  )
}
