import React, { Component } from 'react';
import * as API from './api.js';

import CustomersInactive from './customers.png';
import Loader from './icons/loader.svg';
import Users from './users.png';
import UsersStats from './res/stats.png'
import Search from "./Icon/Search.png";
import service from "./sidebaricon/Services.png";
import install from './icons/AeroGCSInactive.png'
import TaskIcon from './icons/servers.png'
import logList from './icons/logList.png'
import SupportIcon from './icons/supportActive.png'
import waiting from './res/waiting.png';
import success from './assets/check_circle_24px.png';
import fail from './assets/error.png';
import SideBar from './SideBar';
import { Modal } from "react-bootstrap";
import { Switch } from '@mui/material';
import btnAdd from './AeroCapture/assets/icons/btnAdd.svg';
import { AnimatedModal } from './ReusableComponents/AnimatedModal'
import closeButton from './close-button.png';
import DeleteIcon from './icons/deleteIcon.svg';
import detectors from './icons/detector.png'

export default class SupportEmails extends Component {

    state = {
        user: {},
        type: "",
        currentpage: 1,
        userperpage: 10,
        counts: 0,
        key: " ",
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        createIcon: "",
        popupMessage: "",
        addemailPopup: false,
        email: null,
        status: true,
        emails: [],
        showAddemailPopup: false,
        statusFilter: "all",
        editTaskPopup: false,
        cancel: false,
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    onChangeListener = (e) => {
        let t = e.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.value
        }))
    }

    openAddemailPopup = () => {
        this.setState(state => ({
            ...state,
            email: null,
            status: true,
            addemailPopup: true
        }))
    }

    handleSwitchChange = (event) => {
        let t = event.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.checked,
        }));
    };

    changeEmailstatus = (id) => {
        API.changeEmailStatus(id).then(result => {
            this.getAllSupportEmails()
        }).catch(error => {
            console.log(error)
        })
    }

    componentWillMount() {
        document.title = "Support Emails - Admin";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type
            }), async () => {
                this.getAllSupportEmails();
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), async () => {
                    this.getAllSupportEmails();
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getAllSupportEmails();
            });
        } else {
            console.log("Reached last page")
        }
    }

    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    this.getAllSupportEmails();

                });
        }
    }
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getAllSupportEmails();
            });
        } else {
            console.log("Reached last page")
        }
    }

    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                this.getAllSupportEmails();
            });
        } else {
            console.log("Reached last page")
        }
    }

    changepage(num) {
        if (isNaN(num)) {
            return null;
        }
        else {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => this.getAllSupportEmails());
        }
    }

    search(key) {
        this.setState((state) => ({
            ...state,
            key,
        }),
            () => this.getAllSupportEmails());
    }

    addNewEmail = (event) => {
        event.preventDefault(); // to prevent default behaviour of form component
        let { email, cancel, status } = this.state
        if (cancel) {
            this.setState((state) => ({
                ...state,
                addemailPopup: false,
            }))
        }
        else {
            this.setState((state) => ({
                ...state,
                addemailPopup: false,
                popupMessage: "Adding new email , please wait",
                createIcon: waiting,
                showAddemailPopup: true
            }))

            let newemail = {
                "email": email,
                "enable": status,
            }
            API.addNewSupportMail(newemail).then((result) => {
                this.setState((state) => ({
                    ...state,
                    popupMessage: result.message,
                    createIcon: success
                }), () => this.getAllSupportEmails())
            }).catch((error) => {
                this.setState((state) => ({
                    ...state,
                    popupMessage: error.message,
                    createIcon: fail
                }))
            })
        }
    }

    deleteEmail = () => {
        this.setState((state) => ({
            ...state,
            editTaskPopup: false,
            popupMessage: "Deleting mail from support , please wait",
            createIcon: waiting,
            showAddemailPopup: true
        }))
        API.deleteSupportEmail(this.state.id).then((result) => {
            this.setState((state) => ({
                ...state,
                popupMessage: result.message,
                createIcon: success
            }), () => this.getAllSupportEmails())
        }).catch((error) => {
            this.setState((state) => ({
                ...state,
                popupMessage: error.message,
                createIcon: fail
            }))
        })
    }

    // getAll Emails function starts here---------- 
    getAllSupportEmails = async () => {
        const { currentpage, userperpage, key } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        this.setState(state => ({
            ...state,
            counts: 0,
        }))
        await API.getAllSupportEmails(key === "" ? " " : key, firstpage, userperpage).then(async (data) => {
            this.setState((state) => ({
                ...state,
                emails: data.data,
                loading: false,
                counts: data.data.length
            }),)
        }, (e) => {
            console.log(e);
        })
    }

    render() {
        const { id, user, type, currentpage, userperpage, counts, email, status, } = this.state;
        // const lastIndex = currentpage * userperpage;
        // const firstpage = lastIndex - userperpage;
        // const currentuser = userdisplay.slice(firstpage, lastIndex);
        // const totalpages = userdisplay.length / userperpage;
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: UsersStats,
                    text: "Statistics",
                    path: "/admin/users_stats",
                },
                {
                    icon: CustomersInactive,
                    text: "Add NPNT Customer",
                    path: "/admin/add_npnt_customer"
                },
                {
                    icon: Users,
                    text: "Users",
                    path: "/admin/users",
                },
                {
                    icon: install,
                    text: "Installations",
                    path: "/admin/gcs-installation",
                },
                {
                    icon: TaskIcon,
                    text: "Tasks",
                    path: "/admin/tasks",
                },
                {
                    icon: logList,
                    text: "Task Logs",
                    path: "/admin/taskLogs",
                },
                {
                    icon: detectors,
                    text: "Detectors",
                    path: "/admin/detectors"
                },
                {
                    icon: SupportIcon,
                    text: "Support",
                    path: "/admin/supportEmails",
                    selected: true,
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode
        }
        return (
            this.state.user && this.state.user.first_name ? <div className="wrapper">
                <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} />
                <AnimatedModal
                    isOpen={this.state.addemailPopup}
                    onRequestClose={() => {
                        this.setState((state) => ({
                            ...state,
                            addemailPopup: false,
                        }))
                    }}
                    height="300px"
                    width="400px"
                >
                    <div style={{ textAlign: "center", margin: '10px 0px', height: '30px', fontSize: '22px', fontWeight: '500' }}>Add New Support Email</div>
                    <div style={{ height: '5px', width: '100%' }}><hr /></div>
                    <form onSubmit={this.addNewEmail} >
                        <div style={{ margin: '10px 50px' }}>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "1%" }}>Email</div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="email"
                                    placeholder="eg. scott.smith@pdrl.in"
                                    required
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                    onChange={this.onChangeListener}
                                    value={email}
                                />
                            </div>
                            <div>
                                <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "3%" }}>Enable email notifications</div>
                                <div style={{ display: "flex" }}> <Switch label="Status" onChange={(e) => this.handleSwitchChange(e)} name="status" checked={status} /></div>
                            </div>
                        </div>
                        <div style={{ margin: '30px 50px 10px 50px', display: 'flex', justifyContent: 'space-between' }}>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        addemailPopup: false,
                                        cancel: true
                                    }));
                                }}
                            >
                                Cancel
                            </button>
                            {email ?
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    type='submit'
                                >
                                    Add
                                </button>
                                : <button disabled style={{ opacity: '0.5' }}
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                >
                                    Add
                                </button>}
                        </div>
                    </form>
                </AnimatedModal>

                {/* Dleete Popup */}
                <Modal
                    style={{ marginTop: '150px' }}
                    show={this.state.showDeletePopup}
                    onHide={() => {
                        this.setState((state) => ({
                            ...state,
                            showDeletePopup: false,

                        }));
                    }}
                >
                    <Modal.Body >
                        <p>Are you sure you want to delete this email ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="addcustomer-button-empty-dashboard"
                            onClick={() => {
                                this.setState((state) => ({
                                    ...state,
                                    showDeletePopup: false,
                                }));
                            }}
                        >
                            cancel
                        </button>
                        <button
                            className="add-user-form-submit-button btn-ok"
                            onClick={() => {
                                this.setState((state) => ({
                                    ...state,
                                    showDeletePopup: false,
                                    createIcon: waiting,
                                    popupMessage: 'Deleting Email Please Wait...',
                                    showAddemailPopup: true
                                }), () => this.deleteEmail())
                            }}
                        >
                            Ok
                        </button>

                    </Modal.Footer>
                </Modal>

                {/* Info Modal */}
                <Modal
                    style={{ marginTop: '150px' }}
                    show={this.state.showAddemailPopup}
                >
                    <img
                        id="close-popup"
                        src={closeButton} style={{
                            width: "auto",
                            height: "26px",
                            position: "absolute",
                            top: "-13px",
                            right: "-13px",
                            cursor: "pointer"

                        }} onClick={() => {
                            this.setState((state) => ({
                                ...state,
                                showAddemailPopup: false
                            }))
                        }}></img>
                    <div >
                        <div style={{ width: "100%", display: "flex" }}>
                            <img src={this.state.createIcon} style={{
                                width: "auto",
                                height: "64px",
                                margin: "30px auto",
                            }} />
                        </div>

                        <>
                            <div style={{ width: "100%", textAlign: "center", marginBottom: '50px' }}>{
                                this.state.popupMessage
                            }</div>
                        </>
                    </div>
                </Modal>

                <div className="right-content"
                    style={{ zIndex: 0 }}
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar" style={{ display: "flex" }}><div className="top-bar-text">Support Emails</div>
                    </div>
                    <div className="main-content">
                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                            <div className="services-content-title"> Support Emails : {counts}</div>
                            <div className="add-user-form-text-wrapper" style={{ marginLeft: "25px", marginRight: '5px' }}>
                                <img src={btnAdd} style={{ height: '30px', width: '34px', cursor: 'pointer' }} onClick={() => this.openAddemailPopup()} />
                            </div>
                        </div>
                        {this.state.loading ? (
                            <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                                <img src={Loader} style={{ height: "20vh", width: "100vw", margin: "auto" }} />
                            </div>) : (
                            <div>
                                <div style={{ marginTop: "2%", display: "flex", width: "100%", justifyContent: "right" }}>
                                    <div className="add-user-form-text-wrapper">
                                        <img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
                                        <input type="text" className="add-user-form-text" placeholder="Search by email" style={{ height: '30px !important', width: "235px", paddingLeft: '28px' }} onChange={(event) => this.search(event.target.value)} />
                                    </div>
                                </div>
                                <table className="customers-table">
                                    <tr className="customers-table-header-row">
                                        <th>Email</th>
                                        <th>Enable Status</th>
                                        <th>Action</th>
                                    </tr>
                                    {this.state.emails.length === 0 ?
                                        <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                            <td colspan="3">No Support Emails Available</td>
                                        </tr> :
                                        this.state.emails.map((email, i) =>
                                            <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                <td>{email.email}</td>
                                                <td>
                                                    <Switch onClick={() => { this.changeEmailstatus(email.id) }} checked={email.enable} />
                                                </td>
                                                <td style={{ alignContent: 'center' }}>
                                                    <div style={{ display: "flex", cursor: 'pointer' }}
                                                        onClick={(e) => {

                                                            this.setState((state) => ({
                                                                ...state,
                                                                id: email.id,
                                                                showDeletePopup: true
                                                            }))
                                                        }}
                                                    >
                                                        <img src={DeleteIcon} style={{ height: "22px", width: "18px" }} /></div>
                                                </td>
                                            </tr>
                                        )}
                                </table>
                            </div>
                        )
                        }
                        {this.state.counts > 10 ? (
                            <footer className="footer">
                                <div style={{ float: "left", marginTop: "1rem", display: "flex" }}>
                                    <div style={{ color: "#109cf1", marginTop: "5px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                        Page : {currentpage}
                                    </div>
                                </div>
                                <div style={{ float: "right", marginTop: "1rem", display: "flex" }}>
                                    {currentpage == 1 ? <><button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", opacity: '0.5' }} >
                                        <div className="add-user-form-submit-button" >
                                            First
                                        </div>
                                    </button>
                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px", opacity: '0.5' }} >
                                            <div className="addcustomer-button-empty-dashboard" >
                                                Prev
                                            </div>
                                        </button></> :
                                        <><button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", }} onClick={this.firstpage}>
                                            <div className="add-user-form-submit-button" >
                                                First
                                            </div>
                                        </button>
                                            <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px" }} onClick={this.prevpage}>
                                                <div className="addcustomer-button-empty-dashboard" >
                                                    Prev
                                                </div>
                                            </button></>}
                                    <form>
                                        <div style={{ display: "flex" }}>
                                            <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                                Go to page :
                                            </div>
                                            <div className="add-user-form-text-wrapper">
                                                <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                                                    onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                                    onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                            </div>
                                        </div>
                                    </form>

                                    {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ? <><button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px", opacity: '0.5' }}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Next
                                        </div>
                                    </button>
                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px", opacity: '0.5' }}>
                                            <div className="add-user-form-submit-button" >
                                                Last
                                            </div>
                                        </button> </> :
                                        <><button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px" }} onClick={this.nextpage}>
                                            <div className="addcustomer-button-empty-dashboard" >
                                                Next
                                            </div>
                                        </button>
                                            <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }} onClick={this.lastpage}>
                                                <div className="add-user-form-submit-button" >
                                                    Last
                                                </div>
                                            </button> </>}
                                </div>
                            </footer>
                        ) : ''}
                    </div>
                </div>
            </div> : <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
            </div>
        );
    }
}
